import { BaseClient } from '../BaseClient';
import { Int32, String$ } from '../compiled-protos/common';
import { OAUTHRequest } from '../compiled-protos/google';
import { GoogleServiceClient as GoogleAuthServiceClient } from '../compiled-protos/google.client';

class GoogleAuthClient extends BaseClient {
  self: GoogleAuthServiceClient;

  constructor(host: string) {
    super(host);
    this.self = new GoogleAuthServiceClient(this.transport);
  }

  public isRunningOnLocalhost(): boolean {
    return window && window.location.host.includes('localhost');
  }

  public async GetAuthURL() {
    const isLocalhost = this.isRunningOnLocalhost();
    console.log({ isLocalhost })
    const req = OAUTHRequest.create({
      isLocalhost,
      redirectURL: window.location.origin,
    });
    let res = String$.create();
    try {
      res = await this.self.getOAUTHURL(req, this.getMetaDataWithoutCache())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async GetAuthToken(code: string, userId = 0) {
    const req = OAUTHRequest.create({
      isLocalhost: this.isRunningOnLocalhost(),
      authCode: code,
      redirectURL: window.location.origin,
      userId,
    });
    let res = String$.create();
    try {
      res = await this.self.getOAUTHToken(req, this.getMetaDataWithoutCache())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res.value;
  }

  public async RefreshToken(id: number) {
    const req = Int32.create({ value: id });
    const res = String$.create();
    const md = this.getMetaData();
    try {
      return await this.self.refreshCredentialsWithUserId(
        req,
        this.getMetaDataWithoutCache()
      ).response;
    } catch (err) {
      console.log(err);
      return;
      return res;
    }
  }
}

export { GoogleAuthClient };
