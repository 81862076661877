
import { BaseClient } from '../BaseClient';
import { getMimeType } from '../Common';
import { Empty, IDInt32 } from '../compiled-protos/common';
import { type LodgingRequest, type LodgingRequestAssignment, LodgingRequestDocument } from '../compiled-protos/lodging';
import { LodgingRequestServiceClient } from '../compiled-protos/lodging.client';
import { File as InternalFile, FileClient } from '../File';
import { S3Client, URLObject } from '../S3File';

export class LodgingRequestClient extends BaseClient {
  self: LodgingRequestServiceClient;
  private S3: S3Client;
  private FileClient: FileClient;
  private lodgingS3BucketName = 'kalos-lodging-documents'

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new LodgingRequestServiceClient(this.transport);
    this.S3 = new S3Client(host, userID);
    this.FileClient = new FileClient(host, userID);
  }

  public async GetAllLodgingStatuses() {
    const res = await this.self.getAllLodgingRequestStatus(Empty.create(), this.getMetaData()).response;
    return res
  }

  public async BatchGetLodgingRequests(input: LodgingRequest) {
    const res = await this.self.batchGetLodgingRequest(input, this.getMetaDataWithoutCache()).response
    return res
  }
  public async GetLodgingRequests(input: LodgingRequest) {
    const res = await this.self.getLodgingRequest(input, this.getMetaDataWithoutCache()).response
    return res
  }

  public async BatchGetLodgingDocuments(input: LodgingRequestDocument) {
    const res = await this.self.batchGetLodgingDocument(input, this.getMetaDataWithoutCache()).response
    return res
  }

  public async BatchGetLodgingAssignments(input: LodgingRequestAssignment) {
    const res = await this.self.batchGetLodgingAssignment(input, this.getMetaDataWithoutCache()).response
    return res
  }

  public async CreateLodgingRequest(input: LodgingRequest) {
    const res = await this.self.createLodgingRequest(input, this.getMetaData()).response
    return res
  }

  public async CreateLodgingRequestAssignmentDocument({ file, lodgingRequestAssignmentId, fileName }: { file: File } & Pick<LodgingRequestDocument, | 'lodgingRequestAssignmentId' | 'fileName'>) {
    const fileKey = this.getLodgingDocumentFileName(`${lodgingRequestAssignmentId}-assignment`, fileName);
    const fileObject = await this.FileClient.uploadFileToS3AndCreateFile({ file, fileKey: fileName, bucket: this.lodgingS3BucketName });
    const createdDoc = await this._createLodgingDocument(LodgingRequestDocument.create({
      fileId: fileObject.id,
      lodgingRequestAssignmentId
    }));
    return createdDoc
  }

  private getLodgingDocumentFileName(lodgingRequestId: number | string, fileName: string) {
    return `${lodgingRequestId}-${Date.now()}-${fileName}`;
  }

  public async CreateLodgingDocument({ lodgingRequestId, fileName, fileData }: { lodgingRequestId: number, fileName: string, fileData: Uint8Array }) {
    const contentType = getMimeType(fileName);
    const fileKey = this.getLodgingDocumentFileName(lodgingRequestId, fileName);
    const urlObj = URLObject.create({
      bucket: this.lodgingS3BucketName,
      key: fileKey,
      contentType: contentType,
    });
    const urlRes = await this.S3.GetUploadURL(urlObj);

    if (!urlRes) {
      throw new Error('Failed to get upload URL');
    }

    const uploadRes = await fetch(urlRes!.url, {
      body: fileData.buffer,
      method: 'PUT',
    });

    if (uploadRes.status !== 200) {
      throw new Error('Failed to upload file');
    }

    const fileObj = InternalFile.create({
      bucket: this.lodgingS3BucketName,
      name: fileKey,
      mimeType: contentType,
    });
    const fileRes = await this.FileClient.Create(fileObj);

    const req = LodgingRequestDocument.create({
      fileId: fileRes.id,
      lodgingRequestId,
    });

    const docResult = await this._createLodgingDocument(req);
    return docResult;
  }

  public async GetLodgingDocumentDownloadUrl({ filename, mimeType }: { filename: string, mimeType: string }) {
    const res = await this.S3.GetDownloadURL(URLObject.create({
      bucket: this.lodgingS3BucketName,
      key: filename,
      contentType: mimeType,
    }));
    return res;
  }

  public async _createLodgingDocument(input: LodgingRequestDocument) {
    const res = await this.self.createLodgingDocument(input, this.getMetaData()).response
    return res
  }
  public async CreateLodgingAssignment(input: LodgingRequestAssignment) {
    const res = await this.self.createLodgingAssignment(input, this.getMetaData()).response
    return res
  }
  public async DeleteLodgingRequest(input: LodgingRequest) {
    const res = await this.self.deleteLodgingRequest(input, this.getMetaData()).response
    return res
  }
  public async DeleteLodgingDocument(input: LodgingRequestDocument) {
    const res = await this.self.deleteLodgingDocument(input, this.getMetaData()).response
    return res
  }
  public async DeleteLodgingAssignment(input: LodgingRequestAssignment) {
    const res = await this.self.deleteLodgingAssignment(input, this.getMetaData()).response
    return res
  }
  public async UpdateLodgingRequest(input: LodgingRequest) {
    const res = await this.self.updateLodgingRequest(input, this.getMetaData()).response
    return res
  }
  public async UpdateLodgingDocument(input: LodgingRequestDocument) {
    const res = await this.self.updateLodgingDocument(input, this.getMetaData()).response
    return res
  }
  public async UpdateLodgingAssignment(input: LodgingRequestAssignment) {
    const res = await this.self.updateLodgingAssignment(input, this.getMetaData()).response
    return res
  }
  public async GetOverlapAssignments(input: LodgingRequest) {
    const res = await this.self.getOverlapAssignments(input, this.getMetaDataWithoutCache()).response
    return res
  }

  public async GetLodgingAssignment(input: LodgingRequestAssignment) {
    const res = await this.self.getLodgingAssignment(input, this.getMetaDataWithoutCache()).response
    return res
  }

  public async AddOrUpdatePerDiems(input: number) {
    const req = IDInt32.create({ id: input })
    const res = await this.self.addOrUpdatePerdiems(req, this.getMetaData()).response
    return res
  }
}
