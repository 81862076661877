import { BaseClient } from '../BaseClient';
import { QuoteUsed, QuoteUsedList } from '../compiled-protos/quote';
import { QuoteServiceClient as QuoteUsedServiceClient } from '../compiled-protos/quote.client';

class QuoteUsedClient extends BaseClient {
  self: QuoteUsedServiceClient;

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new QuoteUsedServiceClient(this.transport);
  }

  public async Create(req: QuoteUsed) {
    let res = QuoteUsed.create();
    try {
      res = await this.self.createQuoteUsed(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Get(req: QuoteUsed) {
    let res = QuoteUsed.create();
    try {
      res = await this.self.getQuoteUsed(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Update(req: QuoteUsed) {
    let res = QuoteUsed.create();
    try {
      res = await this.self.updateQuoteUsed(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Delete(req: QuoteUsed) {
    let res = QuoteUsed.create();
    try {
      res = await this.self.deleteQuoteUsed(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async BatchGet(req: QuoteUsed) {
    let res = QuoteUsedList.create();
    try {
      res = await this.self.batchGetQuoteUsed(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }
}

export { QuoteUsed, QuoteUsedList, QuoteUsedClient };
