// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "service_item.proto" (syntax proto3)
// tslint:disable
import { ServiceItemService } from "./service_item";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service ServiceItemService
 */
export class ServiceItemServiceClient {
    constructor(_transport) {
        this._transport = _transport;
        this.typeName = ServiceItemService.typeName;
        this.methods = ServiceItemService.methods;
        this.options = ServiceItemService.options;
    }
    /**
     * @generated from protobuf rpc: Create(ServiceItem) returns (ServiceItem);
     */
    create(input, options) {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Get(ServiceItem) returns (ServiceItem);
     */
    get(input, options) {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGet(ServiceItem) returns (ServiceItemList);
     */
    batchGet(input, options) {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Update(ServiceItem) returns (ServiceItem);
     */
    update(input, options) {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Delete(ServiceItem) returns (ServiceItem);
     */
    delete(input, options) {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateServiceItemUnit(ServiceItemUnit) returns (ServiceItemUnit);
     */
    createServiceItemUnit(input, options) {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetServiceItemUnit(ServiceItemUnit) returns (ServiceItemUnit);
     */
    getServiceItemUnit(input, options) {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetServiceItemUnit(ServiceItemUnit) returns (ServiceItemUnitList);
     */
    batchGetServiceItemUnit(input, options) {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateServiceItemUnit(ServiceItemUnit) returns (ServiceItemUnit);
     */
    updateServiceItemUnit(input, options) {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteServiceItemUnit(ServiceItemUnit) returns (ServiceItemUnit);
     */
    deleteServiceItemUnit(input, options) {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateServiceItemMaterial(ServiceItemMaterial) returns (ServiceItemMaterial);
     */
    createServiceItemMaterial(input, options) {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetServiceItemMaterial(ServiceItemMaterial) returns (ServiceItemMaterial);
     */
    getServiceItemMaterial(input, options) {
        const method = this.methods[11], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetServiceItemMaterial(ServiceItemMaterial) returns (ServiceItemMaterialList);
     */
    batchGetServiceItemMaterial(input, options) {
        const method = this.methods[12], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateServiceItemMaterial(ServiceItemMaterial) returns (ServiceItemMaterial);
     */
    updateServiceItemMaterial(input, options) {
        const method = this.methods[13], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteServiceItemMaterial(ServiceItemMaterial) returns (ServiceItemMaterial);
     */
    deleteServiceItemMaterial(input, options) {
        const method = this.methods[14], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateServiceItemImage(ServiceItemImage) returns (ServiceItemImage);
     */
    createServiceItemImage(input, options) {
        const method = this.methods[15], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetServiceItemImage(ServiceItemImage) returns (ServiceItemImage);
     */
    getServiceItemImage(input, options) {
        const method = this.methods[16], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetServiceItemImage(ServiceItemImage) returns (ServiceItemImageList);
     */
    batchGetServiceItemImage(input, options) {
        const method = this.methods[17], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateServiceItemImage(ServiceItemImage) returns (ServiceItemImage);
     */
    updateServiceItemImage(input, options) {
        const method = this.methods[18], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteServiceItemImage(ServiceItemImage) returns (ServiceItemImage);
     */
    deleteServiceItemImage(input, options) {
        const method = this.methods[19], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateServiceReadingLine(ServiceReadingLine) returns (ServiceReadingLine);
     */
    createServiceReadingLine(input, options) {
        const method = this.methods[20], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetServiceReadingLine(ServiceReadingLine) returns (ServiceReadingLine);
     */
    getServiceReadingLine(input, options) {
        const method = this.methods[21], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetServiceReadingLine(ServiceReadingLine) returns (ServiceReadingLineList);
     */
    batchGetServiceReadingLine(input, options) {
        const method = this.methods[22], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateServiceReadingLine(ServiceReadingLine) returns (ServiceReadingLine);
     */
    updateServiceReadingLine(input, options) {
        const method = this.methods[23], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteServiceReadingLine(ServiceReadingLine) returns (ServiceReadingLine);
     */
    deleteServiceReadingLine(input, options) {
        const method = this.methods[24], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateReading(Reading) returns (Reading);
     */
    createReading(input, options) {
        const method = this.methods[25], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetReading(Reading) returns (Reading);
     */
    getReading(input, options) {
        const method = this.methods[26], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetReading(Reading) returns (ReadingList);
     */
    batchGetReading(input, options) {
        const method = this.methods[27], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateReading(Reading) returns (Reading);
     */
    updateReading(input, options) {
        const method = this.methods[28], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteReading(Reading) returns (Reading);
     */
    deleteReading(input, options) {
        const method = this.methods[29], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateBaseMaterial(Material) returns (Material);
     */
    createBaseMaterial(input, options) {
        const method = this.methods[30], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetBaseMaterial(Material) returns (Material);
     */
    getBaseMaterial(input, options) {
        const method = this.methods[31], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetBaseMaterial(Material) returns (MaterialList);
     */
    batchGetBaseMaterial(input, options) {
        const method = this.methods[32], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateBaseMaterial(Material) returns (Material);
     */
    updateBaseMaterial(input, options) {
        const method = this.methods[33], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteBaseMaterial(Material) returns (Material);
     */
    deleteBaseMaterial(input, options) {
        const method = this.methods[34], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateReadingImage(ReadingImage) returns (ReadingImage);
     */
    createReadingImage(input, options) {
        const method = this.methods[35], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetReadingImage(ReadingImage) returns (ReadingImage);
     */
    getReadingImage(input, options) {
        const method = this.methods[36], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetReadingImage(ReadingImage) returns (ReadingImageList);
     */
    batchGetReadingImage(input, options) {
        const method = this.methods[37], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateReadingImage(ReadingImage) returns (ReadingImage);
     */
    updateReadingImage(input, options) {
        const method = this.methods[38], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteReadingImage(ReadingImage) returns (Empty);
     */
    deleteReadingImage(input, options) {
        const method = this.methods[39], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
}
