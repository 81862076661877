import { type PermissionGroup, PermissionGroupUser, User } from '@kalos/kalos-rpc';
import Delete from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { type FC, useCallback, useEffect, useReducer } from 'react';

import { UserClientService } from '../../../tools/helpers';
import { Loader } from '../../Loader/main';
import { AddPermission } from '../AddPermission';
import { Button } from '../Button';
import { Confirm } from '../Confirm';
import { InfoTable } from '../InfoTable';
import { Modal } from '../Modal';
import { SectionBar } from '../SectionBar';
import { VerticalTabs } from '../VerticalTabs';
import { reducer } from './reducer';

interface Props {
  userId: number;
  loggedUserId: number;
  user?: User;
  loggedUser?: User;
  onClose?: () => void;
}

export const EmployeePermissions: FC<Props> = ({
  userId,
  loggedUserId,
  loggedUser,
  user,
  onClose,
}) => {
  const [state, dispatch] = useReducer(reducer, {
    userData: user ? user : undefined,
    loggedUserData: loggedUser ? loggedUser : undefined,
    init: true,
    loaded: false,
    privileges: undefined,
    roles: undefined,
    departments: undefined,
    isSU: false,
    openAddPermission: false,
    openRemovePermission: false,
    pendingRemovePermission: undefined,
    isOwnerSU: false,
    activeTab: 'Role',
  });
  const {
    userData,
    loggedUserData,
    init,
    loaded,
    roles,
    privileges,
    departments,
    openRemovePermission,
    openAddPermission,
    pendingRemovePermission,
    activeTab,
    isOwnerSU,
    isSU,
  } = state;
  const RemovePermissionFromUser = async (permissionGroup: PermissionGroup) => {
    const req = PermissionGroupUser.create();
    req.userId = userId;
    req.permissionGroupId = permissionGroup.id;
    console.log(req);
    await UserClientService.RemoveUserFromPermissionGroup(req);
    dispatch({
      type: 'setOpenRemovePermission',
      flag: false,
      pendingPermissionGroup: undefined,
    });
    refreshPermissions();
  };
  const refreshPermissions = () => {
    dispatch({ type: 'setOpenAddPermission', data: false });
    dispatch({ type: 'setUser', data: undefined });
    dispatch({ type: 'setInit', data: true });
  };
  const load = useCallback(async () => {
    if (loggedUserData == undefined) {
      const req = User.create();
      req.id = loggedUserId;
      const result = await UserClientService.Get(req);
      const loggedPrivileges = result.permissionGroups.find((p) => p.name === 'SU');
      dispatch({ type: 'setLoggedUser', data: result });
      dispatch({
        type: 'setIsSU',
        data: loggedPrivileges === undefined ? false : true,
      });
    }
    if (userData == undefined) {
      console.log('our user is undefined');
      const req = User.create();
      req.id = userId;
      console.log('we are here');
      const result = await UserClientService.Get(req);
      dispatch({ type: 'setUser', data: result });
      const roles = result.permissionGroups.filter((p) => p.type === 'role');
      const departments = result.permissionGroups.filter((p) => p.type === 'department');
      const privileges = result.permissionGroups.filter((p) => p.type === 'privilege');
      const isSU = privileges.find((p) => p.name == 'SU');
      dispatch({ type: 'setRoles', data: roles });
      dispatch({ type: 'setPrivileges', data: privileges });
      dispatch({ type: 'setDepartments', data: departments });
      dispatch({
        type: 'setOwnerIsSU',
        data: isSU === undefined ? false : true,
      });
    }
    dispatch({ type: 'setLoaded', data: true });
  }, [loggedUserId, userId, loggedUserData, userData]);
  useEffect(() => {
    if (init) {
      load();
      dispatch({ type: 'setInit', data: false });
    }
  }, [load, init]);
  const tabs = [
    {
      label: 'Roles',
      content: (
        <InfoTable
          styles={{ width: '100%', padding: 10 }}
          columns={[{ name: 'Role Name' }, { name: 'Description' }]}
          data={
            roles
              ? roles.map((role) => {
                  return [
                    {
                      value: role.name,
                    },
                    {
                      value: role.description,
                      actions: [
                        <Tooltip
                          key="roleDelete"
                          title={isSU ? 'Delete Role' : 'You lack Permission to Edit Roles'}
                        >
                          <IconButton
                            key="view"
                            onClick={() =>
                              dispatch({
                                type: 'setOpenRemovePermission',
                                flag: true,
                                pendingPermissionGroup: role,
                              })
                            }
                            disabled={!isSU}
                            size="small"
                          >
                            <Delete />
                          </IconButton>
                        </Tooltip>,
                      ],
                    },
                  ];
                })
              : []
          }
        />
      ),
    },

    {
      label: 'Departments',
      content: (
        <InfoTable
          styles={{ width: '100%', padding: 10 }}
          columns={[{ name: 'Department' }, { name: 'Description' }]}
          data={
            departments
              ? departments.map((department) => {
                  return [
                    {
                      value: department.name,
                    },
                    {
                      value: department.description,
                      actions: [
                        <IconButton
                          key={'view' + department.id}
                          onClick={() =>
                            dispatch({
                              type: 'setOpenRemovePermission',
                              flag: true,
                              pendingPermissionGroup: department,
                            })
                          }
                          size="small"
                        >
                          <Delete />
                        </IconButton>,
                      ],
                    },
                  ];
                })
              : []
          }
        />
      ),
    },
    {
      label: 'Privileges',
      content: (
        <InfoTable
          styles={{ width: '100%', padding: 10 }}
          columns={[{ name: 'Name' }, { name: 'Description' }]}
          data={
            privileges
              ? privileges.map((privilege) => {
                  return [
                    {
                      value: privilege.name,
                    },
                    {
                      value: privilege.description,
                      actions: [
                        <IconButton
                          key="view"
                          onClick={() =>
                            dispatch({
                              type: 'setOpenRemovePermission',
                              flag: true,
                              pendingPermissionGroup: privilege,
                            })
                          }
                          size="small"
                          disabled={privilege.name == 'SU' && !isOwnerSU}
                        >
                          <Delete />
                        </IconButton>,
                      ],
                    },
                  ];
                })
              : []
          }
        />
      ),
    },
  ];
  return loaded && userData ? (
    <SectionBar
      title={`Permissions for ${userData.firstname} ${userData.lastname}`}
      uncollapsable={true}
    >
      <Button
        className="!mx-2 !mt-3"
        label={
          activeTab.includes('Role') && roles && roles.length > 0
            ? 'Permission Limit Reached'
            : `Add ${activeTab}`
        }
        onClick={() =>
          dispatch({
            type: 'setOpenAddPermission',
            data: true,
          })
        }
        disabled={activeTab.includes('Role') && roles && roles.length > 0}
      ></Button>
      <VerticalTabs
        onChange={(e) =>
          dispatch({
            type: 'setActiveTab',
            data: tabs[e].label,
          })
        }
        tabs={tabs}
      ></VerticalTabs>
      <Modal
        className="Modal-Remove-Scroll"
        open={openAddPermission}
        onClose={() => dispatch({ type: 'setOpenAddPermission', data: false })}
      >
        <AddPermission
          userId={userId}
          loggedUserPermissions={loggedUserData!.permissionGroups}
          permissionType={activeTab}
          limitMultiSelect={activeTab.includes('Role')}
          userPermissions={userData.permissionGroups}
          onClose={refreshPermissions}
        ></AddPermission>
      </Modal>
      <Modal
        open={openRemovePermission}
        onClose={() =>
          dispatch({
            type: 'setOpenRemovePermission',
            flag: false,
            pendingPermissionGroup: undefined,
          })
        }
      >
        <Confirm
          title="Remove Permission"
          open={openRemovePermission}
          onClose={() =>
            dispatch({
              type: 'setOpenRemovePermission',
              flag: false,
              pendingPermissionGroup: undefined,
            })
          }
          onConfirm={() => RemovePermissionFromUser(pendingRemovePermission!)}
          submitLabel="Confirm"
        >
          Are you sure you want to remove this Permission?
        </Confirm>
      </Modal>
      {onClose && <Button label="Close" className="!mx-2 !mt-3" onClick={onClose}></Button>}
    </SectionBar>
  ) : (
    <Loader></Loader>
  );
};
