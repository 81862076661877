import { BaseClient } from '../../BaseClient';
import { Expended, ExpendedList } from '../../compiled-protos/projects_core';
import { ProjectsCoreServiceClient } from '../../compiled-protos/projects_core.client';

class ExpendedClient extends BaseClient {
  self: ProjectsCoreServiceClient;

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new ProjectsCoreServiceClient(this.transport);
  }

  public async Create(req: Expended) {
    let res = Expended.create();
    try {
      res = await this.self.createExpended(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
    }
    return res;
  }

  public async Get(req: Expended) {
    let res = Expended.create();
    try {
      res = await this.self.getExpended(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
    }
    return res;
  }

  public async Update(req: Expended) {
    let res = Expended.create();
    try {
      res = await this.self.updateExpended(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
    }
    return res;
  }

  public async BatchGet(req: Expended) {
    let res = ExpendedList.create();
    try {
      res = await this.self.batchGetExpended(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
    }
    return res;
  }
}

export { Expended, ExpendedList, ExpendedClient };
