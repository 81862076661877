import { Contract } from '@kalos/kalos-rpc';
import {
  Button,
  DataTable,
  DataTableColumnHeader,
  Form,
  Skeleton,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@kalos/ui';
import { MagnifyingGlassIcon, PersonIcon } from '@radix-ui/react-icons';
import {
  type ColumnDef,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  type OnChangeFn,
  type SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { format } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { FORM_AUTOMATIC_SEARCH_DEBOUNCE_TIME, SEARCH_DATE_FORMAT } from '../../../../constants';
import { useContractsQuery } from '../../../../hooks/react-query/useContractsQuery';
import {
  type ContractsFilter,
  type ContractsSort,
  debounce,
  formatDate,
  type LoadContractsByFilter,
} from '../../../../tools/helpers';
import { useAdvancedSearchPaginationStore } from '../paginationStore';
import ContractsSearchForm from './ContractsSearchForm';
import { type ContractsSearchScheme, useContractsSearchForm } from './utils';

const loadingContracts: Contract[] = Array.from({ length: 5 }, (_) => Contract.create());

export const ContractsSearch = () => {
  const page = useAdvancedSearchPaginationStore((state) => state.page);
  const setPage = useAdvancedSearchPaginationStore((state) => state.setPage);
  const setTotalEntriesCount = useAdvancedSearchPaginationStore(
    (state) => state.setTotalEntriesCount,
  );

  const contractSearchForm = useContractsSearchForm();

  const [contractsFilter, setContractsFilter] = useState<ContractsFilter>(() => {
    const defaultValues = contractSearchForm.getValues();
    return {
      ...defaultValues,
      dateEnded: defaultValues.dateEnded ? format(defaultValues.dateEnded, SEARCH_DATE_FORMAT) : '',
      dateStarted: defaultValues.dateStarted
        ? format(defaultValues.dateStarted, SEARCH_DATE_FORMAT)
        : '',
    };
  });

  useEffect(() => {
    const debouncedUpdate = debounce((data: ContractsSearchScheme) => {
      setPage(0);
      setContractsFilter({
        ...data,
        dateEnded: data.dateEnded ? format(data.dateEnded, SEARCH_DATE_FORMAT) : '',
        dateStarted: data.dateStarted ? format(data.dateStarted, SEARCH_DATE_FORMAT) : '',
      });
    }, FORM_AUTOMATIC_SEARCH_DEBOUNCE_TIME);
    const updateSubscription = contractSearchForm.watch(debouncedUpdate);
    return () => updateSubscription.unsubscribe();
  }, [setPage, contractSearchForm]);

  const [contractsSort, setContractsSort] = useState<ContractsSort>({
    orderByField: 'number',
    orderBy: 'contract_number',
    orderDir: 'ASC',
  });

  const [sorting, setSorting] = useState<SortingState>([]);

  const onSortChange: OnChangeFn<SortingState> = useCallback(
    (updater) => {
      setSorting((previousState) => {
        const newState = typeof updater === 'function' ? updater(previousState) : updater;
        let newStateId = newState[0].id;
        if (newStateId === 'dateStarted') {
          newStateId = 'date_Started';
        }
        if (newStateId === 'dateEnded') {
          newStateId = 'date_Ended';
        }
        if (newStateId === 'businessName') {
          newStateId = 'business_name';
        }
        if (newStateId === 'lastName') {
          newStateId = 'last_name';
        }
        setContractsSort({
          orderByField: newState[0].id as keyof Contract,
          orderDir: newState[0].desc ? 'DESC' : 'ASC',
          orderBy: `contract_${newStateId}`,
        });
        return newState;
      });
      setPage(0);
    },
    [setPage],
  );

  const contractsFilterCriteria = useMemo<LoadContractsByFilter>(() => {
    const criteria: LoadContractsByFilter = {
      page,
      filter: contractsFilter,
      sort: contractsSort,
      req: Contract.create(),
    };
    return criteria;
  }, [page, contractsFilter, contractsSort]);

  const contractsQuery = useContractsQuery({
    filters: contractsFilterCriteria,
  });

  useEffect(() => {
    if (contractsQuery.isSuccess) {
      setTotalEntriesCount(contractsQuery.data?.totalCount || 0);
    }
  }, [contractsQuery.data?.totalCount, contractsQuery.isSuccess, setTotalEntriesCount]);

  const contractTableColumnsDefs = useMemo<ColumnDef<Contract>[]>(() => {
    const columns: ColumnDef<Contract>[] = [
      {
        accessorKey: 'number',
        header: ({ column }) => (
          <DataTableColumnHeader hideVisibilityToggle title="Contract #" column={column} />
        ),
        cell({ row }) {
          const value = row.getValue('number');
          return <div className="w-32">{String(value)}</div>;
        },
        sortingFn: () => 0,
      },
      {
        accessorKey: 'dateStarted',
        header: ({ column }) => (
          <DataTableColumnHeader hideVisibilityToggle title="Contract Start Date" column={column} />
        ),
        cell({ row }) {
          const value = row.getValue('dateStarted');
          return <div className="w-32">{formatDate(String(value))}</div>;
        },
        sortingFn: () => 0,
      },
      {
        accessorKey: 'dateEnded',
        header: ({ column }) => (
          <DataTableColumnHeader hideVisibilityToggle title="Contract End Date" column={column} />
        ),
        cell({ row }) {
          const value = row.getValue('dateEnded');
          return <div className="w-32">{formatDate(String(value))}</div>;
        },
        sortingFn: () => 0,
      },
      {
        accessorKey: 'businessName',
        header: ({ column }) => (
          <DataTableColumnHeader
            hideVisibilityToggle
            title="Contract Business Name"
            column={column}
          />
        ),
        cell({ row }) {
          const phone = row.getValue('businessName');
          return <div className="w-32">{String(phone)}</div>;
        },
        sortingFn: () => 0,
      },
      {
        accessorKey: 'lastName',
        header: ({ column }) => (
          <DataTableColumnHeader hideVisibilityToggle title="Contract Last Name" column={column} />
        ),
        cell({ row }) {
          const email = row.getValue('lastName');
          return <div className="w-32 break-all">{String(email)}</div>;
        },
        sortingFn: () => 0,
      },
      {
        id: 'actions',
        header: ({ column }) => (
          <DataTableColumnHeader column={column} hideVisibilityToggle title="Actions" />
        ),
        cell({ row }) {
          // SHould it be possible?
          if (!row.original.userId) return null;

          const customerUrl = `/customers/${row.original.userId}`;
          return (
            <div className="flex items-center space-x-2">
              <TooltipProvider delayDuration={150}>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      asChild
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      variant="outline"
                    >
                      <Link to={customerUrl}>
                        <MagnifyingGlassIcon />
                      </Link>
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent className="px-2 py-1">View Contract</TooltipContent>
                </Tooltip>

                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      variant="outline"
                      asChild
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <Link to={customerUrl}>
                        <PersonIcon />
                      </Link>
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent className="px-2 py-1">View Customer</TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
          );
        },
      },
    ];
    return columns;
  }, []);

  const skeletonColumns = useMemo<ColumnDef<Contract>[]>(() => {
    return contractTableColumnsDefs.map((column) => ({
      ...column,
      cell: () => <Skeleton className="bg-foreground/30 h-4 w-32" />,
    }));
  }, [contractTableColumnsDefs]);

  const table = useReactTable({
    data: contractsQuery.isPending ? loadingContracts : contractsQuery.data?.results ?? [],
    columns: contractsQuery.isPending ? skeletonColumns : contractTableColumnsDefs,
    state: {
      sorting,
      pagination: {
        pageIndex: 0,
        pageSize: 25,
      },
    },
    onSortingChange: onSortChange,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });
  const navigate = useNavigate();

  const onRowClick = useCallback(
    (entry: Contract) => {
      if (!entry.userId) return;
      navigate(`/customers/${entry.userId}`);
    },
    [navigate],
  );

  return (
    <>
      <div className="p-4">
        <Form {...contractSearchForm}>
          <ContractsSearchForm />
        </Form>
      </div>

      <DataTable table={table} onRowClick={onRowClick} />
    </>
  );
};
