import { type PermitDocument } from '@kalos/kalos-rpc';
import { Button, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@kalos/ui';
import { DownloadIcon, TrashIcon } from '@radix-ui/react-icons';
import { useState } from 'react';

import { useDeletePermitDocumentMutation } from '../../../../../../hooks/react-query/usePermits';
import { PermitClientService } from '../../../../../../tools/helpers';

export const PermitDocuments = ({ documents }: { documents: PermitDocument[] }) => {
  return (
    <ul className="max-h-48 overflow-auto pr-4">
      {documents.map((document) => (
        <li
          className="border-foreground/30 flex gap-2 py-1 [&:not(:last-child)]:border-b"
          key={document.id}
        >
          <PermitDocumentEntry document={document} />
        </li>
      ))}
    </ul>
  );
};

export const PermitDocumentEntry = ({ document }: { document: PermitDocument }) => {
  const deleteMutation = useDeletePermitDocumentMutation();
  const [isSaving, setIsSaving] = useState(false);

  const onDownloadClick = async () => {
    try {
      setIsSaving(true);
      const url = await PermitClientService.GetDocumentDownloadURL({
        filename: document.fileName,
        mimeType: document.mimeType,
      });

      if (!url) {
        throw new Error('Failed to get download URL');
      }

      window.open(url.url, '_blank');
    } catch (err) {
      console.error(err);
      alert('Failed to download document');
    } finally {
      setIsSaving(false);
    }
  };

  const onDeleteClick = () => {
    if (confirm('Are you sure you want to delete this document?')) {
      deleteMutation.mutate(document);
    }
  };

  return (
    <TooltipProvider delayDuration={150}>
      <Button
        disabled={isSaving}
        onClick={onDownloadClick}
        className="max-w-[1/2] truncate"
        variant="link"
      >
        {document.fileName}
      </Button>

      <div className="ml-auto min-w-max space-x-2">
        <Tooltip>
          <TooltipContent>Download</TooltipContent>
          <TooltipTrigger asChild>
            <Button
              disabled={isSaving}
              onClick={onDownloadClick}
              isLoading={isSaving}
              variant="outline"
              size="icon"
            >
              <DownloadIcon />
            </Button>
          </TooltipTrigger>
        </Tooltip>

        <Tooltip>
          <TooltipContent>Delete</TooltipContent>
          <TooltipTrigger asChild>
            <Button
              disabled={deleteMutation.isPending}
              isLoading={deleteMutation.isPending}
              onClick={onDeleteClick}
              size="icon"
              variant="outline"
            >
              <TrashIcon />
            </Button>
          </TooltipTrigger>
        </Tooltip>
      </div>
    </TooltipProvider>
  );
};
