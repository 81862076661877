import { Parser } from '@json2csv/plainjs';
import { Button } from '@kalos/ui';
import CircularProgress from '@mui/material/CircularProgress';
import { type FC, useCallback, useEffect } from 'react';

import { downloadCSV } from '../../../tools/helpers';
export type Status = 'idle' | 'loading' | 'loaded';
export interface Column {
  label: string;
  value: string;
}

interface Props {
  filename: string;
  json: Array<unknown>;
  onExport?: () => void;
  onExported?: () => void;
  columns?: Column[]; // Modify this line
  status?: Status;
}

export const ExportJSON: FC<Props> = ({
  filename,
  json,
  columns,

  onExport,
  onExported,
  status,
}) => {
  const handleExport = useCallback(() => {
    try {
      // If columns prop is provided, use it. Otherwise, derive it from the JSON object keys
      const fields =
        columns ||
        (typeof json[0] === 'object'
          ? Object.keys(json[0] as Record<string, unknown>).map((key) => ({
              label: key,
              value: key,
            }))
          : []);
      const parser = new Parser({ fields });
      const csv = parser.parse(json);
      downloadCSV(filename, csv);
    } catch (err) {
      console.log('parsing failed, file will not download', err);
    }
  }, [json, filename, columns]);

  useEffect(() => {
    if (status === 'loaded') {
      handleExport();
      if (onExported) {
        onExported();
      }
    }
  }, [status, handleExport, onExported]);

  return (
    <Button size="sm" onClick={onExport || handleExport} disabled={status === 'loading'}>
      {status === 'loading' && (
        <CircularProgress style={{ color: '#FFF', marginRight: 8 }} size={16} />
      )}
      Export to Excel
    </Button>
  );
};
