import { BaseClient } from '../BaseClient';
import { JobSubtype, JobSubtypeList } from '../compiled-protos/job_type';
import { JobTypeServiceClient as JobSubtypeServiceClient } from '../compiled-protos/job_type.client';

class JobSubtypeClient extends BaseClient {
  self: JobSubtypeServiceClient;

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new JobSubtypeServiceClient(this.transport);
  }

  public async Get(req: JobSubtype) {
    let res = JobSubtype.create();
    try {
      res = await this.self.getJobSubtype(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async BatchGet(req: JobSubtype) {
    let res = JobSubtypeList.create();
    try {
      res = await this.self.batchGetJobSubtype(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  /**
   * loadJobSubtypes is a convenient wrapper for fetching all jobSubTypes
   * @returns JobSubtype[]
   */
  public async loadJobSubtypes() {
    const res = await this.BatchGet(JobSubtype.create());
    if (res) {
      return res.results;
    }
    return undefined
  }
}

export { JobSubtype, JobSubtypeList, JobSubtypeClient };
