import { BaseClient } from '../BaseClient';
import {
  Reimbursement,
  ReimbursementDocument,
  ReimbursementDocumentList,
  ReimbursementList,
} from '../compiled-protos/reimbursement';
import { ReimbursementServiceClient } from '../compiled-protos/reimbursement.client';

class ReimbursementClient extends BaseClient {
  self: ReimbursementServiceClient;

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new ReimbursementServiceClient(this.transport);
  }

  public async Create(req: Reimbursement) {
    const res = await this.self.create(req, this.getMetaData()).response;
    return res;
  }

  public async Get(req: Reimbursement) {
    let res = Reimbursement.create();
    try {
      res = await this.self.get(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Update(req: Reimbursement) {
    const res = await this.self.update(req, this.getMetaData()).response;
    return res
  }

  public async Delete(req: Reimbursement) {
    let res = Reimbursement.create();
    try {
      res = await this.self.delete(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async BatchGet(req: Reimbursement) {
    const res = await this.self.batchGet(req, this.getMetaDataWithoutCache()).response;
    return res;
  }

  public async CreateDocument(req: ReimbursementDocument) {
    let res = ReimbursementDocument.create();
    try {
      res = await this.self.createDocument(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async GetDocument(req: ReimbursementDocument) {
    let res = ReimbursementDocument.create();
    try {
      res = await this.self.getDocument(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async UpdateDocument(req: ReimbursementDocument) {
    let res = ReimbursementDocument.create();
    try {
      res = await this.self.updateDocument(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async DeleteDocument(req: ReimbursementDocument) {
    let res = ReimbursementDocument.create();
    try {
      res = await this.self.deleteDocument(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async BatchGetDocument(req: ReimbursementDocument) {
    const res = await this.self.batchGetDocument(req, this.getMetaData()).response;
    return res;
  }
}

export {
  Reimbursement,
  ReimbursementClient,
  ReimbursementList,
  ReimbursementDocument,
  ReimbursementDocumentList,
};
