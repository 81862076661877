import { zodResolver } from '@hookform/resolvers/zod';
import { type EmployeeFunction } from '@kalos/kalos-rpc';
import { useForm, useFormContext } from 'react-hook-form';
import { z } from 'zod';

import { type ZodObjectWithModelKeys } from '../../../tools/typeguargs';

type EmployeeFunctionSchema = Pick<EmployeeFunction, 'status' | 'name' | 'color'>;

const employeeFunctionSchema = z.object({
  status: z.coerce.number(),
  name: z.string(),
  color: z.string(),
}) satisfies ZodObjectWithModelKeys<EmployeeFunctionSchema>;

export type EmployeeFunctionFormType = z.infer<typeof employeeFunctionSchema>;
export const defaultEmployeeFunctionFormValues: EmployeeFunctionFormType = {
  status: 1,
  name: '',
  color: '#000000',
};

export const useEmployeeFunctionForm = ({
  defaultValues,
}: {
  defaultValues: EmployeeFunctionFormType;
}) => {
  return useForm<EmployeeFunctionFormType>({
    resolver: zodResolver(employeeFunctionSchema),
    defaultValues,
  });
};

export const useEmployeeFunctionFormContext = () => {
  return useFormContext<EmployeeFunctionFormType>();
};
