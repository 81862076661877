import { QuoteLine, type QuoteLineList } from '@kalos/kalos-rpc';
import { useQuery } from '@tanstack/react-query';

import { QuoteLineClientService } from '../../tools/helpers';
import { queryKeys } from './constants';

export type QuoteLineFilters = { [K in keyof QuoteLine]?: QuoteLine[K] };

export const useQuoteLineQuery = <TSelectData = QuoteLineList,>({
  enabled = true,
  filters = {},
  select,
}: {
  enabled?: boolean;
  filters?: QuoteLineFilters;
  select?: (data: QuoteLineList) => TSelectData;
} = {}) => {
  return useQuery({
    queryFn: () => {
      const request = QuoteLine.create(filters);
      return QuoteLineClientService.BatchGet(request);
    },
    queryKey: [
      queryKeys.quoteLine.root,
      queryKeys.quoteLine.list,
      queryKeys.quoteLine.getQuoteLineHash(filters),
    ],
    enabled,
    select,
  });
};
