import { type Contract } from '@kalos/kalos-rpc';
import { useFormContext } from 'react-hook-form';
import { z } from 'zod';

import {
  useGetDefaultSearchFormValues,
  useSearchParamsForm,
} from '../../../../hooks/useFormWithSearchParams';
import { type ZodObjectWithModelKeys } from '../../../../tools/typeguargs';

type ContractsSearchFields = Pick<
  Contract,
  'number' | 'lastName' | 'businessName' | 'dateStarted' | 'dateEnded'
>;

const contractsSearchScheme = z.object({
  number: z.string(),
  lastName: z.string(),
  businessName: z.string(),
  dateStarted: z.date().optional(),
  dateEnded: z.date().optional(),
}) satisfies ZodObjectWithModelKeys<ContractsSearchFields>;

export type ContractsSearchScheme = z.infer<typeof contractsSearchScheme>;
export const defaultContractsSearchValues: ContractsSearchScheme = {
  businessName: '',
  dateEnded: undefined,
  dateStarted: undefined,
  lastName: '',
  number: '',
};

export const useContractsSearchForm = () => {
  return useSearchParamsForm({
    defaultValues: useGetDefaultSearchFormValues(
      contractsSearchScheme,
      defaultContractsSearchValues,
    ),
    schema: contractsSearchScheme,
    defaultResetValues: defaultContractsSearchValues,
  });
};

export const useContractsSearchFormContext = () => {
  return useFormContext<ContractsSearchScheme>();
};
