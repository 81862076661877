import { type TransactionLineItem } from '@kalos/kalos-rpc';
import { Label, Textarea } from '@kalos/ui';
import { CheckCircledIcon, CrossCircledIcon } from '@radix-ui/react-icons';
import { useMemo, useState } from 'react';
import { useStore } from 'zustand';

import {
  LineItemValidator,
  useTransactionLineItemsStore,
} from '../../../../../context/TransactionLIneItems';
import { debounce } from '../../../../../tools/helpers';

export const AdditionalAmountNotesEditHeader = () => {
  const store = useTransactionLineItemsStore();
  const isSecondaryAmountNoteValid = useStore(store, (s) => s.isSecondaryAmountNoteValid());

  return isSecondaryAmountNoteValid ? (
    <CheckCircledIcon className="size-6 text-emerald-500" />
  ) : (
    <CrossCircledIcon className="size-6 text-red-500" />
  );
};

export const SecondaryAmountNote = ({
  transactionLineItem,
}: {
  transactionLineItem: TransactionLineItem;
}) => {
  const store = useTransactionLineItemsStore();
  const isEditing = useStore(store, (s) => s.isEditing);

  if (!isEditing)
    return transactionLineItem.secondaryAmountNote || <span className="text-red-500">N/A</span>;

  return <EditableSecondaryAmountNoteField transactionLineItem={transactionLineItem} />;
};

const EditableSecondaryAmountNoteField = ({
  transactionLineItem,
}: {
  transactionLineItem: TransactionLineItem;
}) => {
  const store = useTransactionLineItemsStore();
  const setLineItems = useStore(store, (s) => s.setLineItems);
  const [value, setValue] = useState(transactionLineItem.secondaryAmountNote);

  const debouncedUpdateLineItem = useMemo(() => {
    return debounce((secondaryAmountNote: string) => {
      setLineItems((prev) =>
        prev.map((lineItem) =>
          lineItem.id === transactionLineItem.id ? { ...lineItem, secondaryAmountNote } : lineItem,
        ),
      );
    }, 350);
  }, [setLineItems, transactionLineItem.id]);

  const onChange: React.ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    setValue(e.target.value);
    debouncedUpdateLineItem(e.target.value);
  };

  const { error } = LineItemValidator.isSecondaryAmountNoteValid(
    value,
    transactionLineItem.secondaryAmount,
  );

  return (
    <div className="flex flex-col gap-0.5">
      {error && (
        <Label className="text-xs text-red-500" htmlFor={transactionLineItem.id.toString()}>
          {error}
        </Label>
      )}
      <Textarea
        id={transactionLineItem.id.toString()}
        className={error ? 'border-2 border-red-500' : ''}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};
