import { TimesheetDepartment, type TimesheetDepartmentList } from '@kalos/kalos-rpc';
import { useQuery } from '@tanstack/react-query';

import { TimesheetDepartmentClientService } from '../../tools/helpers';
import { queryKeys } from './constants';
import { type EntityFilter } from './utils';

export type TimesheetDepartmentFilters = EntityFilter<TimesheetDepartment>;

export const useTimesheetDepartmentListQuery = <TSelectData = TimesheetDepartmentList,>({
  enabled = true,
  filter = {},
  select,
}: {
  enabled?: boolean;
  filter?: TimesheetDepartmentFilters;
  select?: (
    data: Awaited<ReturnType<typeof TimesheetDepartmentClientService.BatchGet>>,
  ) => TSelectData;
} = {}) => {
  return useQuery({
    queryKey: [
      queryKeys.timesheetDepartments.root,
      queryKeys.timesheetDepartments.list,
      queryKeys.timesheetDepartments.getFiltersHash(filter),
    ],
    queryFn: async () => {
      const departmentRequest = TimesheetDepartment.create(filter);
      return TimesheetDepartmentClientService.BatchGet(departmentRequest);
    },
    enabled,
    select,
  });
};

export const useTimesheetDepartmentQuery = <TSelectData = TimesheetDepartment,>({
  enabled = true,
  filter = {},
  select,
}: {
  enabled?: boolean;
  filter?: TimesheetDepartmentFilters;
  select?: (data: Awaited<ReturnType<typeof TimesheetDepartmentClientService.Get>>) => TSelectData;
} = {}) => {
  return useQuery({
    queryKey: [
      queryKeys.timesheetDepartment.root,
      queryKeys.timesheetDepartment.getFiltersHash(filter),
    ],
    queryFn: async () => {
      const departmentRequest = TimesheetDepartment.create(filter);
      return TimesheetDepartmentClientService.Get(departmentRequest);
    },
    enabled,
    select,
  });
};
