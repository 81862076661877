import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect } from 'react';

import { S3ClientService } from '../../tools/helpers';
import { queryKeys } from './constants';

export const getS3FileURLQueryConfig = ({
  bucket,
  fileName,
}: {
  fileName: string;
  bucket: string;
}) => ({
  queryFn: async () => {
    return await S3ClientService.getFileS3BucketUrl(fileName, bucket);
  },
  queryKey: [queryKeys.s3.root, bucket, fileName],
});

export const useS3FileUrlInlineQuery = () => {
  const queryClient = useQueryClient();
  return useCallback(
    async (arg: { fileName: string; bucket: string }) => {
      const query = getS3FileURLQueryConfig(arg);
      const cache = queryClient.getQueryData<string>(query.queryKey);
      if (!cache) {
        const url = await query.queryFn();
        queryClient.setQueryData(query.queryKey, url);
        return url;
      }
      return cache;
    },
    [queryClient],
  );
};

export const useS3FileUrlQuery = ({
  filter,
  enabled,
}: {
  filter: { fileName: string; bucket: string };
  enabled?: boolean;
}) => {
  return useQuery({ ...getS3FileURLQueryConfig(filter), enabled });
};

export const useS3FileObjectUrlQUery = ({
  filter,
  enabled,
}: {
  filter: { bucket: string; fileName: string; mimeType: string };
  enabled?: boolean;
}) => {
  const urlQuery = useS3FileUrlQuery({ filter, enabled });

  const result = useQuery({
    queryKey: [queryKeys.s3.root, 'objectUrl', filter.mimeType, urlQuery.data],
    queryFn: async () => {
      const response = await fetch(urlQuery.data ?? '');
      const data = await response.arrayBuffer();
      const blob = new Blob([data], { type: filter.mimeType || 'application/octet-stream' });
      const objectUrl = URL.createObjectURL(blob);
      return objectUrl;
    },
    enabled: urlQuery.isSuccess && urlQuery.data.length > 0 && enabled,
  });

  useEffect(() => {
    return () => {
      if (result.isSuccess) {
        URL.revokeObjectURL(result.data);
      }
    };
  }, [result.data, result.isSuccess]);

  return result;
};

export const getS3FileQueryConfig = ({
  bucket,
  fileName,
}: {
  bucket: string;
  fileName: string;
}) => ({
  queryFn: async () => {
    return await S3ClientService.downloadFile({ bucket, fileName });
  },
  queryKey: [queryKeys.s3.root, queryKeys.s3.file, bucket, fileName],
});
