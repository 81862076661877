import { type TransactionLineItem } from '@kalos/kalos-rpc';
import { cn, Label, Textarea } from '@kalos/ui';
import { CheckCircledIcon, CrossCircledIcon } from '@radix-ui/react-icons';
import { debounce } from 'lodash';
import { useMemo, useState } from 'react';
import { useStore } from 'zustand';

import {
  LineItemValidator,
  useTransactionLineItemsStore,
} from '../../../../../context/TransactionLIneItems';

export const DescriptionEditHeader = () => {
  const store = useTransactionLineItemsStore();
  const isDescriptionValid = useStore(store, (s) => s.isDescriptionsValid());

  return isDescriptionValid ? (
    <CheckCircledIcon className="size-6 text-emerald-500" />
  ) : (
    <CrossCircledIcon className="size-6 text-red-500" />
  );
};

export const DescriptionField = ({
  transactionLineItem,
}: {
  transactionLineItem: TransactionLineItem;
}) => {
  const store = useTransactionLineItemsStore();
  const isEditing = useStore(store, (s) => s.isEditing);

  if (!isEditing)
    return transactionLineItem.description || <span className="text-red-500">N/A</span>;

  return <EditableDescriptionField transactionLineItem={transactionLineItem} />;
};

const EditableDescriptionField = ({
  transactionLineItem,
}: {
  transactionLineItem: TransactionLineItem;
}) => {
  const store = useTransactionLineItemsStore();
  const setLineItems = useStore(store, (s) => s.setLineItems);
  const [value, setValue] = useState(transactionLineItem.description);

  const debouncedUpdateLineItem = useMemo(() => {
    return debounce((description: string) => {
      setLineItems((prev) =>
        prev.map((lineItem) =>
          lineItem.id === transactionLineItem.id ? { ...lineItem, description } : lineItem,
        ),
      );
    }, 350);
  }, [setLineItems, transactionLineItem.id]);

  const onChange: React.ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    setValue(e.target.value);
    debouncedUpdateLineItem(e.target.value);
  };
  const errorMessage = LineItemValidator.isDescriptionValid(value).error;

  return (
    <div className="flex flex-col gap-0.5">
      {errorMessage && (
        <Label className="text-xs text-red-500" htmlFor={transactionLineItem.id.toString()}>
          {errorMessage}
        </Label>
      )}

      <Textarea
        id={transactionLineItem.id.toString()}
        className={cn(!!errorMessage && 'border-2 border-red-500')}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};
