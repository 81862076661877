import { zodResolver } from '@hookform/resolvers/zod';
import { type InvoicePayment } from '@kalos/kalos-rpc';
import {
  Button,
  DateInput,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  SimpleSelect,
} from '@kalos/ui';
import { useCallback } from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';

import { PAYMENT_TYPE_LIST } from '../../../../../constants';
import { useEvent } from '../../../../../hooks/useEvent';
import { type DirtyModelFields, isMoneyValue } from '../../../../../tools/helpers';
import { type ZodObjectWithModelKeys } from '../../../../../tools/typeguargs';
import { getFieldMaskFromDirtyField } from '../../../EmployeeDepartments/EditEmployeeFunction';

type InvoicePaymentFormFields = Pick<InvoicePayment, 'amount' | 'paymentDate' | 'paymentTypeId'>;
export const permitFormSchema = z.object({
  amount: z.coerce.number().positive().refine(isMoneyValue, '2 digits at max'),
  paymentDate: z.date(),
  paymentTypeId: z.enum(PAYMENT_TYPE_LIST),
}) satisfies ZodObjectWithModelKeys<InvoicePaymentFormFields>;

export type InvoicePaymentFormSchema = z.infer<typeof permitFormSchema>;

export const getDefaultInvoiceValues = (arg?: Partial<InvoicePaymentFormSchema>) => {
  return {
    amount: 0,
    paymentDate: new Date(),
    paymentTypeId: 'Cash',
    ...arg,
  } satisfies InvoicePaymentFormSchema;
};

export const InvoicePaymentForm = ({
  isLoading,
  disabled,
  onSave,
  defaultValues,
}: {
  onSave: (arg: {
    data: InvoicePaymentFormSchema;
    dirtyFields: DirtyModelFields<InvoicePaymentFormSchema>;
  }) => void;
  disabled?: boolean;
  isLoading?: boolean;
  defaultValues: InvoicePaymentFormSchema;
}) => {
  const form = useForm<InvoicePaymentFormSchema>({
    resolver: zodResolver(permitFormSchema),
    defaultValues,
  });

  const onSaveEvent = useEvent(onSave);

  const _onSave: SubmitHandler<InvoicePaymentFormSchema> = useCallback(
    (data, e) => {
      onSaveEvent({ data, dirtyFields: form.formState.dirtyFields });
      e?.stopPropagation();
    },
    [onSaveEvent, form.formState.dirtyFields],
  );

  return (
    <form className="@md:grid-cols-2 grid gap-4" onSubmit={form.handleSubmit(_onSave)}>
      <Form {...form}>
        <FormField
          control={form.control}
          name="amount"
          render={({ field }) => (
            <FormItem className="flex flex-col justify-between">
              <FormLabel>Amount</FormLabel>
              <FormMessage />
              <FormControl>
                <Input {...field} type="number" step={0.01} />
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="paymentDate"
          render={({ field }) => (
            <FormItem className="flex flex-col justify-between">
              <FormLabel>Payment Date</FormLabel>
              <FormMessage />
              <FormControl>
                <DateInput
                  onChange={field.onChange}
                  value={field.value}
                  disabled={field.disabled}
                />
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="paymentTypeId"
          render={({ field }) => (
            <FormItem className="@md:col-span-2 flex flex-col justify-between">
              <FormLabel>Payment Type</FormLabel>
              <FormMessage />
              <FormControl>
                <SimpleSelect
                  values={PAYMENT_TYPE_LIST}
                  onChange={field.onChange}
                  disabled={field.disabled}
                  selectedValue={field.value}
                />
              </FormControl>
            </FormItem>
          )}
        />

        <Button
          type="submit"
          className="@md:col-span-2"
          isLoading={isLoading}
          disabled={disabled || !getFieldMaskFromDirtyField(form.formState.dirtyFields).length}
        >
          Save
        </Button>
      </Form>
    </form>
  );
};
