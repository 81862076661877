import { BaseClient } from '../BaseClient';
import { Bool } from '../compiled-protos/common';
import {
  type InvoiceBodyRequest
  ,
  InvoiceBodyReturn,
  type PropertyDocumentRequest,
} from '../compiled-protos/email';
import { Invoice, InvoiceList, type SQSEmailAndDocument } from '../compiled-protos/event';
import { EventServiceClient as InvoiceServiceClient } from '../compiled-protos/event.client';

class InvoiceClient extends BaseClient {
  self: InvoiceServiceClient;

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new InvoiceServiceClient(this.transport);
  }

  public async Create(req: Invoice) {
    let res = Invoice.create();
    try {
      res = await this.self.createInvoice(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public Customer_Get({ req, authToken }: { req: Invoice, authToken: string }) {
    return this.self.getInvoice(req, this.getMetaData(authToken)).response;
  }

  public async Get(req: Invoice) {
    let res = Invoice.create();
    try {
      res = await this.self.getInvoice(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Update(req: Invoice) {
    let res = Invoice.create();
    try {
      res = await this.self.updateInvoice(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Delete(req: Invoice) {
    let res = Invoice.create();
    try {
      res = await this.self.deleteInvoice(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async BatchGet(req: Invoice) {
    let res = InvoiceList.create();
    try {
      res = await this.self.batchGetInvoice(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }
  public async SendSQSInvoiceMail(req: SQSEmailAndDocument) {
    let res = Bool.create();
    try {
      res = await this.self.sendSQSInvoiceEmail(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async GetInvoiceBody(req: InvoiceBodyRequest) {
    let res = InvoiceBodyReturn.create();
    try {
      res = await this.self.getInvoiceBody(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async SendSQSInvoiceEmail(req: SQSEmailAndDocument) {
    let res = Bool.create();
    try {
      res = await this.self.sendSQSInvoiceEmail(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }
  public async SendInvoiceDocument(req:PropertyDocumentRequest){
    let res = Bool.create();
    try {
      res = await this.self.sendInvoiceDocumentLinkWithStripe(req, this.getMetaData())
        .response;
    } catch (err) {
console.log(err);
  }
}
}

export { Invoice, InvoiceList, InvoiceClient };
