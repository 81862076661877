import { MaintenanceQuestion, Reading, ReadingImage } from '@kalos/kalos-rpc';
import {
  Button,
  DataTable,
  DataTablePagination,
  Dialog,
  DialogContent,
  ScrollArea,
  toast,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@kalos/ui';
import { GearIcon, ImageIcon, Pencil1Icon, TrashIcon } from '@radix-ui/react-icons';
import { type ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { getLoadingColumns } from '../../../components/utils';
import { ROWS_PER_PAGE } from '../../../constants';
import { useAuth } from '../../../context/AuthContext';
import {
  useMaintenanceQuestionCreateMutation,
  useMaintenanceQuestionDeleteMutation,
  useMaintenanceQuestionUpdateMutation,
  useReadingBatchGetQuery,
  useReadingCreateMutation,
  useReadingDeleteMutation,
  useReadingImageCreateMutation,
  useReadingUpdateMutation,
} from '../../../hooks/react-query/useReadingQuery';
import { useBatchUserQuery } from '../../../hooks/react-query/useUserQuery';
import { formatDate, MaintenanceQuestionClientService, timestamp } from '../../../tools/helpers';
import { getFieldMaskFromDirtyField } from '../EmployeeDepartments/EditEmployeeFunction';
import { ReadingImagesGallery } from './components/ReadingImageGallery/ReadingImageGallery';
import MaintenanceQuestionForm from './MaintenanceQuestionForm';
import ReadingForm from './ReadingForm';
import { type MaintenanceQuestionSchemeType } from './utils';

const staticData = Array.from({ length: 3 }).map((_, i) => Reading.create({ id: i }));

const Readings = ({ serviceItemId, eventId }: { serviceItemId: number; eventId?: number }) => {
  const auth = useAuth();
  const loggedUserId = auth.user.id;

  const [editedMaintenanceQuestion, setEditedMaintenanceQuestion] = useState<MaintenanceQuestion>();
  const [editedReading, setEditedReading] = useState<Reading>();

  const [maintenanceQuestions, setMaintenanceQuestions] = useState<{
    [key: number]: MaintenanceQuestion;
  }>({});

  const readingDeleteMutation = useReadingDeleteMutation();
  const readingCreateMutation = useReadingCreateMutation();
  const readingUpdateMutation = useReadingUpdateMutation();
  const maintenanceQuestionDeleteMutation = useMaintenanceQuestionDeleteMutation();
  const [page, setPage] = useState(0);

  const readingsQuery = useReadingBatchGetQuery({
    filter: {
      serviceItemId,
      pageNumber: page,

      orderBy: 'reading_date',
      orderDir: 'desc',
    },
  });
  const totalCount = readingsQuery.data?.totalCount || 0;

  const pageCount = useMemo(() => {
    return Math.ceil(totalCount / ROWS_PER_PAGE);
  }, [totalCount]);
  const maintenanceCreate = useMaintenanceQuestionCreateMutation();
  const maintenanceUpdate = useMaintenanceQuestionUpdateMutation();

  const usersQuery = useBatchUserQuery({
    filters: { overrideLimit: true, isEmployee: 1 },
  });

  const loadMaintenanceQuestions = async (readingIds: number[]) => {
    const maintenanceQuestions = await Promise.all(
      readingIds.map(async (id) => {
        const entry = MaintenanceQuestion.create();
        entry.readingId = id;

        try {
          return await MaintenanceQuestionClientService.Get(entry);
        } catch (e) {
          console.log('FAIL');
          return null;
        }
      }),
    );
    return maintenanceQuestions.reduce(
      (aggr, entry) => ({
        ...aggr,
        ...(entry === null
          ? {}
          : {
              [entry.readingId]: entry,
            }),
      }),
      {},
    ) as {
      [key: number]: MaintenanceQuestion;
    };
  };

  const onDeleteClick = useCallback(
    (id: number): React.MouseEventHandler<HTMLButtonElement> =>
      async (e) => {
        e.stopPropagation();
        if (confirm('Are you sure you want to delete this reading?')) {
          maintenanceQuestionDeleteMutation.mutate(
            MaintenanceQuestion.create({
              readingId: id,
            }),
          );
          await readingDeleteMutation.mutateAsync(
            Reading.create({
              id,
            }),
          );
        }
      },
    [maintenanceQuestionDeleteMutation, readingDeleteMutation],
  );

  const handleOpenMaintenance = useCallback(async (readingId: number) => {
    const newQuestion = MaintenanceQuestion.create();
    newQuestion.readingId = readingId;
    const res = await MaintenanceQuestionClientService.Get(newQuestion);
    res.readingId = readingId;
    setEditedMaintenanceQuestion(res || newQuestion);
  }, []);

  const [viewReadingImagesId, setViewReadingImagesId] = useState<Reading['id'] | null>(null);

  const readingsTableCols: ColumnDef<Reading>[] = useMemo(
    () => [
      {
        id: 'date',
        header: 'Date',

        cell({ row }) {
          const user = usersQuery.data?.results.find((user) => user.id === row.original.userId);
          return [
            formatDate(row.original.date),
            '-',
            maintenanceQuestions[row.original.id] ? 'Maintenance' : 'Service',
            row.original.userId === 0 || (usersQuery.data?.results || []).length !== 0 || !user
              ? ''
              : ` - ${user.firstname} ${user.lastname}`,
          ].join(' ');
        },
      },
      {
        id: 'actions',
        header: () => <div className="text-right">Actions</div>,
        cell({ row }) {
          return (
            <div className="flex justify-end gap-1">
              <TooltipProvider delayDuration={150}>
                <Tooltip>
                  <TooltipContent>Maintenance question</TooltipContent>
                  <TooltipTrigger asChild>
                    <Button
                      size="icon"
                      variant="outline"
                      onClick={() => {
                        handleOpenMaintenance(row.original.id);
                      }}
                    >
                      <GearIcon />
                    </Button>
                  </TooltipTrigger>
                </Tooltip>

                {row.original.readingImages && (
                  <Tooltip>
                    <TooltipContent>Images</TooltipContent>
                    <TooltipTrigger asChild>
                      <Button
                        onClick={() => setViewReadingImagesId(row.original.id)}
                        size="icon"
                        variant="outline"
                      >
                        <ImageIcon />
                      </Button>
                    </TooltipTrigger>
                  </Tooltip>
                )}

                {!!eventId && (
                  <Tooltip>
                    <TooltipContent>Edit reading</TooltipContent>
                    <TooltipTrigger asChild>
                      <Button
                        size="icon"
                        variant="outline"
                        onClick={() => setEditedReading(row.original)}
                      >
                        <Pencil1Icon />
                      </Button>
                    </TooltipTrigger>
                  </Tooltip>
                )}

                <Tooltip>
                  <TooltipContent side="left">Delete reading</TooltipContent>
                  <TooltipTrigger asChild>
                    <Button variant="outline" size="icon" onClick={onDeleteClick(row.original.id)}>
                      <TrashIcon />
                    </Button>
                  </TooltipTrigger>
                </Tooltip>
              </TooltipProvider>
            </div>
          );
        },
      },
    ],
    [eventId, handleOpenMaintenance, maintenanceQuestions, onDeleteClick, usersQuery.data?.results],
  );

  const skeletonColumns = useMemo(() => getLoadingColumns(readingsTableCols), [readingsTableCols]);

  const table = useReactTable({
    data: !readingsQuery.isPending ? readingsQuery.data?.results || [] : staticData,
    columns: !readingsQuery.isFetching ? readingsTableCols : skeletonColumns,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => row.id.toString(),
  });

  const handleGetQuestion = useCallback(async (data: Reading[]) => {
    const maintenanceQuestions = await loadMaintenanceQuestions(data.map((id) => id.id));
    setMaintenanceQuestions(maintenanceQuestions);
  }, []);

  useEffect(() => {
    handleGetQuestion(readingsQuery.data?.results || []);
  }, [readingsQuery.isSuccess, readingsQuery.data?.results, handleGetQuestion]);

  const createReadingImageMutation = useReadingImageCreateMutation();

  const [isSaving, setIsSaving] = useState(false);

  const handleOnReadingSave = useCallback<
    Required<React.ComponentProps<typeof ReadingForm>>['onSave']
  >(
    async ({ data, dirtyFields }) => {
      if (editedReading && eventId) {
        setIsSaving(true);
        const req = Reading.create(data);
        const isNew = !editedReading.id;
        if (!isNew) {
          req.id = editedReading.id;
        }
        req.fieldMask = ['RefrigerantType'];

        req.eventId = eventId;
        req.serviceItemId = serviceItemId;
        req.date = timestamp(true);
        req.userId = loggedUserId;
        try {
          if (isNew) {
            const reading = await readingCreateMutation.mutateAsync(req);
            await Promise.allSettled(
              data.images.map((file) =>
                createReadingImageMutation.mutateAsync({
                  file,
                  req: ReadingImage.create({ readingId: reading.id }),
                }),
              ),
            );
          } else {
            const fieldMask = getFieldMaskFromDirtyField(dirtyFields);
            req.fieldMask = fieldMask;
            await readingUpdateMutation.mutateAsync(req);
          }
        } finally {
          toast({
            variant: 'success',
            title: isNew ? 'Reading created' : 'Reading updated',
          });
          setIsSaving(false);
          setEditedReading(undefined);
        }
      }
    },
    [
      editedReading,
      eventId,
      serviceItemId,
      loggedUserId,
      readingCreateMutation,
      createReadingImageMutation,
      readingUpdateMutation,
    ],
  );

  const handleSaveMaintenance = useCallback(
    async (data: MaintenanceQuestionSchemeType) => {
      const req = MaintenanceQuestion.create(data);
      req.readingId = editedMaintenanceQuestion?.readingId || 0;

      if (req.id) {
        const fieldMask = Object.keys(req).filter(
          (key: string) =>
            (req as Record<string, any>)[key] !==
            (editedMaintenanceQuestion as Record<string, any>)![key],
        );
        req.fieldMask = fieldMask;
        await maintenanceUpdate.mutateAsync(req);
      } else {
        await maintenanceCreate.mutateAsync(req);
      }
      setEditedMaintenanceQuestion(undefined);
    },
    [maintenanceCreate, maintenanceUpdate, editedMaintenanceQuestion],
  );

  const viewReadingImages = useMemo(() => {
    return readingsQuery.data?.results.find((reading) => reading.id === viewReadingImagesId)
      ?.readingImages?.results;
  }, [readingsQuery.data?.results, viewReadingImagesId]);

  return (
    <>
      <div className="h-full max-w-full overflow-auto">
        <div className="flex items-center justify-between px-3">
          <h3 className="pb-4 pt-3 text-xl font-semibold tracking-tight">Readings</h3>
          {!!eventId && (
            <Button
              className="h-max"
              onClick={() => setEditedReading(!editedReading ? Reading.create() : undefined)}
            >
              {!editedReading ? 'Add Reading' : 'Close'}
            </Button>
          )}
        </div>
        {editedReading && (
          <div className="px-2 pb-4 md:px-4">
            <ReadingForm
              isLoading={isSaving}
              onSave={handleOnReadingSave}
              disabled={isSaving}
              showImages={!editedReading.id}
              data={editedReading}
            />
          </div>
        )}
        <div className="flex items-center justify-between px-4 pb-4">
          <div className="flex items-center gap-2 overflow-auto">
            {readingsQuery.data && readingsQuery.data.totalCount > 25 && (
              <DataTablePagination
                key={readingsQuery.data?.totalCount}
                currentPage={page}
                pageCount={pageCount}
                setPage={setPage}
              />
            )}
          </div>
        </div>
        <div className="overflow-auto">
          <DataTable table={table} />
        </div>
      </div>

      {viewReadingImages && viewReadingImagesId && (
        <Dialog
          open={!!viewReadingImagesId}
          onOpenChange={(isOpen) => {
            if (!isOpen) setViewReadingImagesId(null);
          }}
        >
          <DialogContent className="h-[80vh] max-w-2xl overflow-auto p-0">
            <ScrollArea className="px-3 pt-6 lg:px-6">
              <ReadingImagesGallery
                readingId={viewReadingImagesId}
                readingImages={viewReadingImages}
              />
            </ScrollArea>
          </DialogContent>
        </Dialog>
      )}
      {editedMaintenanceQuestion && (
        <Dialog open onOpenChange={(isOpen) => !isOpen && setEditedMaintenanceQuestion(undefined)}>
          <DialogContent>
            <div className="px-1 py-3 md:px-3">
              <MaintenanceQuestionForm
                onSave={handleSaveMaintenance}
                onClose={() => setEditedMaintenanceQuestion(undefined)}
                data={editedMaintenanceQuestion}
              />
            </div>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default Readings;
