import { UserGroupLink, type UserGroupLinkList } from '@kalos/kalos-rpc';
import { useQuery } from '@tanstack/react-query';

import { UserGroupLinkClientService } from '../../tools/helpers';
import { queryKeys } from './constants';
import { type EntityFilter } from './utils';

export type UserGroupLinkFilter = EntityFilter<UserGroupLink>;

export const useUserGroupLinksQuery = <TSelectData = UserGroupLinkList,>({
  enabled = true,
  filter = {},
  select,
}: {
  filter?: UserGroupLinkFilter;
  enabled?: boolean;
  select?: (data?: UserGroupLinkList) => TSelectData;
}) => {
  return useQuery({
    queryFn: () => {
      const req = UserGroupLink.create(filter);
      return UserGroupLinkClientService.BatchGet(req);
    },
    queryKey: [
      queryKeys.userGroupLinks.root,
      queryKeys.userGroupLinks.list,
      queryKeys.userGroupLinks.getHash(filter),
    ],
    enabled,
    select,
  });
};
