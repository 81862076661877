// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "credit_card.proto" (syntax proto3)
// tslint:disable
// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "credit_card.proto" (syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { WireType } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
// @generated message type with reflection information, may provide speed optimized methods
class CreditCard$Type extends MessageType {
    constructor() {
        super("CreditCard", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "owner", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "card", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "account", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "is_compromised", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "is_active", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "without_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, owner: 0, card: "", account: "", isCompromised: 0, isActive: 0, fieldMask: [], pageNumber: 0, withoutLimit: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 owner */ 2:
                    message.owner = reader.int32();
                    break;
                case /* string card */ 3:
                    message.card = reader.string();
                    break;
                case /* string account */ 4:
                    message.account = reader.string();
                    break;
                case /* int32 is_compromised */ 5:
                    message.isCompromised = reader.int32();
                    break;
                case /* int32 is_active */ 6:
                    message.isActive = reader.int32();
                    break;
                case /* repeated string field_mask */ 7:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 8:
                    message.pageNumber = reader.int32();
                    break;
                case /* bool without_limit */ 9:
                    message.withoutLimit = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 owner = 2; */
        if (message.owner !== 0)
            writer.tag(2, WireType.Varint).int32(message.owner);
        /* string card = 3; */
        if (message.card !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.card);
        /* string account = 4; */
        if (message.account !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.account);
        /* int32 is_compromised = 5; */
        if (message.isCompromised !== 0)
            writer.tag(5, WireType.Varint).int32(message.isCompromised);
        /* int32 is_active = 6; */
        if (message.isActive !== 0)
            writer.tag(6, WireType.Varint).int32(message.isActive);
        /* repeated string field_mask = 7; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(7, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 8; */
        if (message.pageNumber !== 0)
            writer.tag(8, WireType.Varint).int32(message.pageNumber);
        /* bool without_limit = 9; */
        if (message.withoutLimit !== false)
            writer.tag(9, WireType.Varint).bool(message.withoutLimit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CreditCard
 */
export const CreditCard = new CreditCard$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreditCardList$Type extends MessageType {
    constructor() {
        super("CreditCardList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CreditCard },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated CreditCard results */ 1:
                    message.results.push(CreditCard.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated CreditCard results = 1; */
        for (let i = 0; i < message.results.length; i++)
            CreditCard.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CreditCardList
 */
export const CreditCardList = new CreditCardList$Type();
/**
 * @generated ServiceType for protobuf service CreditCardService
 */
export const CreditCardService = new ServiceType("CreditCardService", [
    { name: "Create", options: {}, I: CreditCard, O: CreditCard },
    { name: "Get", options: {}, I: CreditCard, O: CreditCard },
    { name: "BatchGet", options: {}, I: CreditCard, O: CreditCardList },
    { name: "Update", options: {}, I: CreditCard, O: CreditCard },
    { name: "Delete", options: {}, I: CreditCard, O: CreditCard }
]);
