import {
  ActivityLog,
  Document,
  Event,
  EventAssignment,
  Int32,
  Invoice as InvoiceType,
  JobAdmin,
  JobNumberRequest,
  type JobTypeSubtype,
  NULL_TIME,
  Payment,
  Property,
  type Quotable,
  QuotableRead,
  QuoteLinePart,
  ServicesRendered,
  SQSEmail,
  SQSEmailAndDocument,
  User,
} from '@kalos/kalos-rpc';
import {
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@kalos/ui';
import { CheckIcon, CopyIcon } from '@radix-ui/react-icons';
import { addHours, format } from 'date-fns';
import { type FC, useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useStore } from 'zustand';

import { OPTION_BLANK } from '../../../constants';
import { useAuth } from '../../../context/AuthContext';
import { useSpiffOptionBatchGetQuery } from '../../../hooks/react-query/useTaskClientServiceQuery';
import { useUserQuery } from '../../../hooks/react-query/useUserQuery';
import { useCopyToClipboard } from '../../../hooks/useCopyToClipboard';
import { useNavigate, useParams } from '../../../react-router';
import {
  ActivityLogClientService,
  EventAssignmentClientService,
  EventClientService,
  InvoiceClientService,
  JobSubtypeClientService,
  JobTypeClientService,
  JobTypeSubtypeClientService,
  makeFakeRows,
  PaymentClientService,
  PropertyClientService,
  timestamp,
  UserClientService,
} from '../../../tools/helpers';
import { Loader } from '../../Loader/main';
import { CallbackReview } from '../CallbackReview';
import { CustomerNotificationDialog } from '../CustomerInformation/NotificationDialog';
import { type Option } from '../Field';
import { Form, type Schema } from '../Form';
import { type Data, type Href, InfoTable } from '../InfoTable';
import { InvoicePaymentModule } from '../InvoicePayment/InvoicePayment';
import { JobAdminComponent } from '../JobAdmin';
import { Link as LinkComponent } from '../Link';
import { Modal } from '../Modal';
import { SectionBar } from '../SectionBar';
import { ServiceCallLogs } from '../ServiceCallLogs';
import { SpiffApplyComponent } from '../SpiffApplyComponent';
import { PieceWorkApplyForm } from '../SpiffApplyComponent/SpiffPieceWorkApply';
import { Equipment } from './components/Equipment';
import { Invoice } from './components/Invoice';
import { createSpiffDialogStore } from './components/PieceWork/CreateSpiffOptionDialog';
import { PieceWorkOptionsTable } from './components/PieceWork/PieceWorkOptionsTable';
import { Proposal } from './components/Proposal';
import { Request } from './components/Request';
import {
  Services,
  SERVICES_RENDERED_PAYMENT_INITIAL,
  type ServicesRenderedPaymentType,
} from './components/Services';
import { Spiffs } from './components/Spiffs';
import { reducer, type State } from './reducer';
export type EventType = Event;
export type JobTypeSubtypeType = JobTypeSubtype;
export type ServicesRenderedType = ServicesRendered;

export interface Props {
  userID?: number;
  propertyId?: number;
  serviceCallId?: number;

  onClose?: () => void;
  onSave?: (jobId?: number) => void;
  asProject?: boolean;
  projectParentId?: number;
}

type EmailInvoice = {
  emailInvoice: boolean;
};

const SCHEMA_EMAIL_INVOICE: Schema<EmailInvoice> = [
  [
    {
      label: 'Email Invoice to Customer?',
      name: 'emailInvoice',
      type: 'checkbox',
    },
  ],
];

export const returnCorrectTimeField = (time: string) => {
  const splitString = time.split(' ');
  const timeValue = splitString[1].split(':');
  const hoursString = timeValue[0];
  const minutesString = timeValue[1];
  return `${hoursString}:${minutesString}`;
};
export const returnLegactyDate = (time: string) => {
  const splitString = time.split(' ');

  return `${splitString[0]} 00:00:00`;
};

const requestValidateFields = [
  'jobTypeId',
  'logPaymentType',
  'logJobStatus',
  'name',
  'departmentId',
  'description',
  'logTechnicianAssigned',
  'logPo',
  'servicesperformedrow1',
  'servicesperformedrow2',
  'servicesperformedrow3',
  'servicesperformedrow4',
];

const CopyDataAction: FC<{ data: string; href?: boolean; hrefTag?: Href }> = ({
  data,
  href,
  hrefTag,
}) => {
  const [, copy] = useCopyToClipboard();
  const [isSuccess, setIsSuccess] = useState(false);

  const onCopy = async (event: React.MouseEvent<HTMLButtonElement>) => {
    try {
      event.preventDefault();
      const isSuccess = await copy(data);
      if (isSuccess) {
        setIsSuccess(isSuccess);

        setTimeout(() => {
          setIsSuccess(false);
        }, 2000);
      }
    } catch {
      console.error('Failed to copy transaction data');
    }
  };

  return (
    data && (
      <div className="flex w-full items-center justify-between gap-1">
        {href ? (
          <LinkComponent href={`${hrefTag}:${data}`}>{data}</LinkComponent>
        ) : (
          <span>{data}</span>
        )}
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button variant="ghost" onClick={onCopy}>
                <span className="sr-only">
                  {isSuccess ? 'Successfully copied data' : 'Copy data to clipboard'}
                </span>
                {isSuccess ? (
                  <CheckIcon className="size-4 text-green-500" />
                ) : (
                  <CopyIcon className="cursor-pointer" />
                )}
              </Button>
            </TooltipTrigger>
            <TooltipContent>Copy data</TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
    )
  );
};

const handleCheckRequest = (
  data: Event,
  fields?: string[],
): {
  requestErrors: { [key: string]: string };
  invoiceErrors: { label: string; message: string }[];
} => {
  const labels: { [key: string]: string } = {
    jobTypeId: 'Job Type',
    logPaymentType: 'Payment Type',
    logJobStatus: 'Job Status',
    name: 'Name',
    departmentId: 'Department',
    description: 'Description',
    logTechnicianAssigned: 'Technician',
    logPo: 'PO',
    servicesperformedrow1: 'Services Performed (1)',
    servicesperformedrow2: 'Services Performed (2)',
    servicesperformedrow3: 'Services Performed (3)',
    servicesperformedrow4: 'Services Performed (4)',
  };
  const requestErrors: { [key: string]: string } = {};
  if ((!fields || fields.includes('jobTypeId')) && !data.jobTypeId) {
    requestErrors.jobTypeId = 'This field is required';
  }
  if ((!fields || fields.includes('logPaymentType')) && !data.logPaymentType.length) {
    requestErrors.logPaymentType = 'This field is required';
  }
  if ((!fields || fields.includes('logJobStatus')) && !data.logJobStatus.length) {
    requestErrors.logJobStatus = 'This field is required';
  }
  if ((!fields || fields.includes('name')) && !data.name.trim().replaceAll(' ', '').length) {
    requestErrors.name = 'This field is required';
  }
  if ((!fields || fields.includes('departmentId')) && !data.departmentId) {
    requestErrors.departmentId = 'This field is required';
  }
  if ((!fields || fields.includes('logPo')) && data.logPo.length > 1000) {
    requestErrors.logPo = 'This field cannot exceed 1000 characters in length';
  }
  if (
    (!fields || fields.includes('servicesperformedrow1')) &&
    data.servicesperformedrow1.length > 1000
  ) {
    requestErrors.servicesperformedrow1 = 'This field cannot exceed 1000 characters in length';
  }
  if (
    (!fields || fields.includes('servicesperformedrow2')) &&
    data.servicesperformedrow2.length > 1000
  ) {
    requestErrors.servicesperformedrow2 = 'This field cannot exceed 1000 characters in length';
  }
  if (
    (!fields || fields.includes('servicesperformedrow3')) &&
    data.servicesperformedrow3.length > 1000
  ) {
    requestErrors.servicesperformedrow3 = 'This field cannot exceed 1000 characters in length';
  }
  if (
    (!fields || fields.includes('servicesperformedrow4')) &&
    data.servicesperformedrow4.length > 1000
  ) {
    requestErrors.servicesperformedrow4 = 'This field cannot exceed 1000 characters in length';
  }
  if (
    (!fields || fields.includes('description')) &&
    !data.description.trim().replaceAll(' ', '').length
  ) {
    requestErrors.description = 'This field is required';
  }
  if (
    (!fields || fields.includes('logTechnicianAssigned')) &&
    data.logTechnicianAssigned.split(',').map(Number).length > 20
  ) {
    requestErrors.logTechnicianAssigned = 'Cannot add more than 20 technicians';
  }
  const invoiceErrors = Object.entries(requestErrors).map(([label, message]) => ({
    label: labels[label],
    message,
  }));

  return { requestErrors, invoiceErrors };
};

export const createVerboseLogging = (
  entry: Event | Payment,
  firstname: string,
  lastname: string,
) => {
  if (entry.fieldMask.length > 0) {
    let logMessage = `User ${firstname} ${lastname} changed the following \n`;
    for (let i = 0; i < entry.fieldMask.length; i++) {
      const messageString = `Changed ${entry.fieldMask[i]} to ${
        // @ts-ignore
        entry[entry.fieldMask[i].charAt(0).toLowerCase() + entry.fieldMask[i].slice(1)]
      } \n`;
      logMessage += messageString;
    }
    return logMessage;
  } else {
    return '';
  }
};

export const ServiceCall: FC<Props> = ({
  asProject = false,
  onClose,
  onSave,
  projectParentId,
  propertyId,
  serviceCallId: eventId,
  userID: userIdProp,
}) => {
  const auth = useAuth();
  const navigate = useNavigate();

  const loggedUserId = auth.user.id;

  const loggedUserQuery = useUserQuery({
    filters: {
      id: loggedUserId,
    },
    select(data) {
      return {
        ...data,
        hasBillingAccess: !!data.permissionGroups.find(
          ({ name }) => name === 'ServiceCallBillingAccess',
        ),
        isSpiffAdmin: !!data.permissionGroups.find(({ name }) => name === 'SpiffAdmin'),
        isArchiveAdmin: !!data.permissionGroups.find(({ name }) => name === 'ArchiveAdmin'),
        isWorkPieceAdmin: !!data.permissionGroups.find(({ name }) => name === 'PieceWorkAdmin'),
      };
    },
  });

  const params = useParams();
  if (params.jobId) {
    eventId = parseInt(params.jobId);
  }
  if (params.propertyId) {
    propertyId = parseInt(params.propertyId);
  }

  const workPieceQuery = useSpiffOptionBatchGetQuery({
    filter: {
      isActive: true,
      eventId,
    },
  });

  const initialState: State = {
    requestFields: [],
    tabIdx: 0,
    tabKey: 0,
    pendingSave: false,
    saveInvoice: false,
    sendInvoiceToCustomer: false,
    emailAlertOpen: false,
    serviceCallId: eventId || 0,
    entry: Event.create(),
    property: Property.create(),
    customer: User.create(),
    propertyEvents: [],
    loaded: false,
    loading: true,
    saving: false,
    payments: [],
    openSpiffApply: false,
    openPieceWorkApply: false,
    openCallbackReview: false,
    storedQuote: [],
    error: false,
    invoiceData: undefined,
    errorMessage: '',
    jobTypes: [],
    jobSubtypes: [],
    jobAdmins: [],
    jobTypeSubtypes: [],
    jobSubTypeOptions: [],
    servicesRendered: [],
    projects: [],
    parentId: null,
    confirmedParentId: null,
    contractData: undefined,
    projectData: Event.create(),
    openJobActivity: false,
    requestErrors: {},
    invoiceErrors: [],
    servicesRenderedPayment: SERVICES_RENDERED_PAYMENT_INITIAL,
    proposalsForm: undefined,
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const logJobNumber = state.entry.logJobNumber;
  const contractNumber = state.entry.contractNumber;
  const firstname = state.customer.firstname;
  const lastname = state.customer.lastname;
  const businessname = state.customer.businessname;
  const phone = state.customer.phone;
  const altphone = state.customer.altphone;
  const cellphone = state.customer.cellphone;
  const fax = state.customer.fax;
  const email = state.customer.email;
  const billingTerms = state.customer.billingTerms;
  const address = state.property.address;
  const city = state.property.city;
  const propertyState = state.property.state;
  const zip = state.property.zip;

  const requestRef = useRef(null);

  const handleUpdateMaterialsStringAndCost = useCallback(async () => {
    const totalMaterials: Quotable[] = [];
    let totalCost = 0;
    let fullString = '';

    const materialReq = QuotableRead.create();
    materialReq.eventId = state.serviceCallId;
    materialReq.isActive = true;

    const materials = await EventClientService.ReadQuotes(materialReq);

    if (materials) {
      if (materials.data.length > 0) {
        totalMaterials.concat(materials.data);
        for (let i = 0; i < state.servicesRendered.length; i++) {
          const date = state.servicesRendered[i].timeStarted;
          const tech = state.servicesRendered[i].name;
          const tempStringFirstPart = `${date}, - ${tech}`;
          const filteredMaterials = materials.data.filter(
            (material) => material.servicesRenderedId === state.servicesRendered[i].id,
          );
          if (filteredMaterials.length > 0) {
            let serviceRenderedMaterialString = tempStringFirstPart;
            for (let j = 0; j < filteredMaterials.length; j++) {
              const material = filteredMaterials[j];
              const tempStringSecondPart = ` - (${material.quantity})- ${material.description}- $${
                material.isBillable ? material.quotedPrice : 0
              }`;

              const cost = (material.isBillable ? material.quantity : 0) * material.quotedPrice;
              const taxAmount = 0;
              const markupAmount = 0;
              const qlReq = QuoteLinePart.create();
              qlReq.id = material.quoteLineId;
              /*
            try {
              const qlResult = await QuoteLinePartClientService.Get(qlReq);
              const tax = qlResult.tax;
              const markup = qlResult.markup;

              if (tax) {
                taxAmount = cost * tax - cost;
                console.log('Got tax', tax);
              }
              if (markup) {
                markupAmount = cost * markup - cost;
                console.log('got markup', markup);
              }
            } catch (err) {
              console.log('did not find quote line entry');
            }
            */
              totalCost += cost + markupAmount + taxAmount;
              serviceRenderedMaterialString += tempStringSecondPart;
            }

            fullString += `${serviceRenderedMaterialString} \n `;
          }
        }
      }
    }

    const updateEvent = Event.create();
    updateEvent.id = state.serviceCallId;
    updateEvent.materialUsed = fullString;
    updateEvent.materialTotal = totalCost;
    const updateStateEvent = Event.clone(state.entry);
    updateStateEvent.materialUsed = fullString;
    updateStateEvent.materialTotal = totalCost;
    updateEvent.fieldMask = ['MaterialUsed', 'MaterialTotal'];
    await EventClientService.Update(updateEvent);
    dispatch({ type: 'SET_ENTRY', payload: updateStateEvent });
  }, [state.serviceCallId, state.entry, state.servicesRendered]);

  const returnUpdatedMaterials = useCallback(
    async (servicesRendered: ServicesRendered[], serviceCallId: number) => {
      const totalMaterials: Quotable[] = [];
      let totalCost = 0;
      let fullString = '';

      const materialReq = QuotableRead.create();
      materialReq.eventId = serviceCallId;
      materialReq.isActive = true;

      const materials = (await EventClientService.ReadQuotes(materialReq))!;

      if (materials) {
        totalMaterials.concat(materials.data);
        for (let i = 0; i < servicesRendered.length; i++) {
          const date = servicesRendered[i].timeStarted;
          const tech = servicesRendered[i].name;
          const tempStringFirstPart = `${date}, - ${tech}`;
          const filteredMaterials = materials.data.filter(
            (material) => material.servicesRenderedId === servicesRendered[i].id,
          );
          if (filteredMaterials.length > 0) {
            let serviceRenderedMaterialString = tempStringFirstPart;
            for (let j = 0; j < filteredMaterials.length; j++) {
              const material = filteredMaterials[j];
              const tempStringSecondPart = ` - (${material.quantity})- ${material.description}- $${
                material.isBillable ? material.quotedPrice : 0
              }`;

              const cost = (material.isBillable ? material.quantity : 0) * material.quotedPrice;
              const taxAmount = 0;
              const markupAmount = 0;
              const qlReq = QuoteLinePart.create();
              qlReq.id = material.quoteLineId;

              totalCost += cost + markupAmount + taxAmount;
              serviceRenderedMaterialString += tempStringSecondPart;
            }

            fullString += `${serviceRenderedMaterialString} \n `;
          }
        }
      }

      return { materialString: fullString, totalCost: totalCost };
    },
    [],
  );

  const setCreateSpiffDialogStore = useStore(createSpiffDialogStore, (s) => s.setOpen);

  const toggleOpenSpiffApply = () => {
    dispatch({ type: 'SET_OPEN_SPIFF_APPLY', payload: !state.openSpiffApply });
  };
  const toggleOpenPieceWorkApply = () => {
    console.log('toggle open piece work apply');
    dispatch({ type: 'SET_OPEN_PIECE_WORK_APPLY', payload: !state.openPieceWorkApply });
  };
  const toggleOpenCallbackReview = () => {
    dispatch({
      type: 'SET_OPEN_CALLBACK_REVIEW',
      payload: !state.openCallbackReview,
    });
  };

  const toggleOpenJobActivity = () => {
    dispatch({
      type: 'SET_OPEN_JOB_ACTIVITY',
      payload: !state.openJobActivity,
    });
  };
  /*
  const updateStatusForm = (status: string) => {
    handleSetState({ stat: status });
  };
  */
  const updateServicesRenderedFromProp = useCallback((servicesRendered: ServicesRendered[]) => {
    dispatch({ type: 'SET_SERVICES_RENDERED', payload: servicesRendered });
  }, []);

  const loadPayments = useCallback(async () => {
    try {
      if (state.serviceCallId != 0 && state.serviceCallId != undefined) {
        const req = Int32.create();
        req.value = state.serviceCallId;
        const result = (await PaymentClientService.GetPaymentsByEventID(req))!.results;
        dispatch({ type: 'SET_PAYMENTS', payload: result });
        return result;
      }
    } catch (e) {
      console.log('failed to get payment data', e);
    }
    return [];
  }, [state.serviceCallId]);

  const customerQuery = useUserQuery({
    filters: {
      id: state.customer.id,
    },
  });

  const load = useCallback(async () => {
    console.log('load function time bb', eventId);
    if (typeof eventId === 'undefined') {
      console.log('we did not get an eventId');
      return;
    }
    try {
      dispatch({ type: 'SET_LOADING', payload: true });
      dispatch({ type: 'SET_SERVICE_CALL_ID', payload: eventId });

      console.log('event id passed', eventId);
      if (state.serviceCallId != 0) {
        const jobData = await EventClientService.GetEventDetails(
          JobNumberRequest.create({ jobNumber: eventId }),
        );
        if (jobData && jobData.event) {
          const startTimeData = jobData.event.timeStarted;
          const endTimeData = jobData.event.timeEnded;
          const startSplit = startTimeData.split(':');
          const endSplit = endTimeData!.split(':');
          const startDate = jobData.event.dateStarted;
          const endDate = jobData.event.dateEnded;
          const startTimeDate = startDate.split(' ')[0];
          const endTimeDate = endDate.split(' ')[0];
          const fullStartDate = `${startTimeDate} ${startSplit[0]}:${startSplit[1]}:00`;
          const fullEndDate = `${endTimeDate} ${endSplit[0]}:${endSplit[1]}:00`;
          const materialTotals = await returnUpdatedMaterials(
            jobData.servicesRendered,
            jobData.event.id,
          );
          jobData.event.materialUsed = materialTotals.materialString;
          jobData.event.materialTotal = materialTotals.totalCost;
          jobData.event.dateStarted = fullStartDate;
          jobData.event.dateEnded = fullEndDate;

          dispatch({ type: 'SET_ENTRY', payload: jobData.event });
          dispatch({ type: 'SET_PROPERTY', payload: jobData.property! });
          dispatch({ type: 'SET_CUSTOMER', payload: jobData.customer! });
          dispatch({
            type: 'SET_PROPERTY_EVENTS',
            payload: jobData.propertyEvent,
          });
          dispatch({ type: 'SET_JOB_TYPES', payload: jobData.jobTypes });
          dispatch({ type: 'SET_JOB_SUBTYPES', payload: jobData.jobSubtypes });
          dispatch({
            type: 'SET_JOB_TYPE_SUBTYPES',
            payload: jobData.jobTypesSubtypes,
          });
          dispatch({ type: 'SET_JOB_ADMINS', payload: jobData.jobAdmins });
          dispatch({
            type: 'SET_SERVICES_RENDERED',
            payload: jobData.servicesRendered,
          });
          dispatch({ type: 'SET_PAYMENTS', payload: jobData.payment });
          dispatch({ type: 'SET_INVOICE_DATA', payload: jobData.invoice });
          dispatch({ type: 'SET_LOADED', payload: true });
          dispatch({ type: 'SET_LOADING', payload: false });
          dispatch({ type: 'SET_CONTRACT_DATA', payload: undefined });
        }
      } else {
        const req = Event.create();
        req.isResidential = 1;
        const dateInit = new Date();
        req.dateStarted = format(dateInit, 'yyyy-MM-dd hh:mm:ss');
        req.dateEnded = format(dateInit, 'yyyy-MM-dd  hh:mm:ss');
        const propertyInfo = await PropertyClientService.loadPropertyByID(
          propertyId || state.entry.propertyId,
        );

        if (propertyInfo) {
          req.name = `${propertyInfo.address} ${propertyInfo.city}, ${propertyInfo.state} ${propertyInfo.zip}`;
          const entry = req;
          const dateStart = new Date();
          dateStart.setHours(8);
          dateStart.setMinutes(0);
          dateStart.setSeconds(0);
          const dateEnd = addHours(dateStart, 10);
          const dateStartString = format(dateStart, 'yyyy-MM-dd HH:mm:ss');
          const endDateString = format(dateEnd, 'yyyy-MM-dd HH:mm:ss');
          entry.dateStarted = dateStartString;
          entry.dateEnded = endDateString;

          const customerDetails = await UserClientService.loadUserById(propertyInfo.userId);
          const propertyEventDetails = await EventClientService.loadEventsByPropertyId(
            propertyInfo.id,
          );
          const jobTypeList = await JobTypeClientService.loadJobTypes();
          const jobSubTypeList = await JobSubtypeClientService.loadJobSubtypes();
          const jobTypeSubtypesList = await JobTypeSubtypeClientService.loadJobTypeSubtypes();

          dispatch({ type: 'SET_ENTRY', payload: entry });
          dispatch({ type: 'SET_PROPERTY', payload: propertyInfo });
          dispatch({ type: 'SET_CUSTOMER', payload: customerDetails });
          dispatch({
            type: 'SET_PROPERTY_EVENTS',
            payload: propertyEventDetails,
          });
          dispatch({ type: 'SET_JOB_TYPES', payload: jobTypeList });
          dispatch({ type: 'SET_JOB_SUBTYPES', payload: jobSubTypeList! });
          dispatch({
            type: 'SET_JOB_TYPE_SUBTYPES',
            payload: jobTypeSubtypesList!,
          });
          dispatch({ type: 'SET_JOB_ADMINS', payload: [] });
          dispatch({
            type: 'SET_SERVICES_RENDERED',
            payload: [ServicesRendered.create()],
          });
          dispatch({ type: 'SET_PAYMENTS', payload: [Payment.create()] });
          dispatch({ type: 'SET_INVOICE_DATA', payload: InvoiceType.create() });
          dispatch({ type: 'SET_LOADED', payload: true });
          dispatch({ type: 'SET_LOADING', payload: false });
          dispatch({ type: 'SET_CONTRACT_DATA', payload: undefined });
        }
      }
    } catch (err) {
      console.log('error', err);
      dispatch({ type: 'SET_ERROR', payload: true });
      dispatch({ type: 'SET_ERROR_MESSAGE', payload: err as string });
      dispatch({ type: 'SET_LOADING', payload: false });
      dispatch({ type: 'SET_LOADED', payload: true });
    }
  }, [state.serviceCallId, eventId, propertyId, state.entry.propertyId, returnUpdatedMaterials]);

  const handleSave = useCallback(() => {
    const { requestErrors, invoiceErrors } = handleCheckRequest(state.entry);
    dispatch({
      type: 'SET_REQUEST_ERRORS',
      payload: requestErrors,
    });
    dispatch({
      type: 'SET_INVOICE_ERRORS',
      payload: invoiceErrors,
    });
    if (!Object.keys(requestErrors).length) {
      dispatch({ type: 'SET_PENDING_SAVE', payload: true });
      dispatch({ type: 'SET_SAVE_INVOICE', payload: false });
      dispatch({ type: 'SET_SEND_INVOICE_TO_CUSTOMER', payload: false });
      dispatch({ type: 'SET_EMAIL_ALERT_OPEN', payload: false });
      dispatch({
        type: 'SET_TAB_IDX',
        payload: state.tabIdx !== 0 ? 0 : state.tabIdx,
      });
      dispatch({
        type: 'SET_TAB_KEY',
        payload: state.tabIdx !== 0 ? state.tabKey + 1 : state.tabKey,
      });
    }
  }, [state.entry, state.tabKey, state.tabIdx]);

  const saveServiceCall = useCallback(async () => {
    dispatch({ type: 'SET_SAVING', payload: true });
    dispatch({ type: 'SET_LOADING', payload: true });
    let jobId = null;
    const res = Event.clone(state.entry);
    if (res.totalamountrow1 == undefined || res.totalamountrow1 == '') {
      res.totalamountrow1 = '0';
    }
    if (res.totalamountrow2 == undefined || res.totalamountrow2 == '') {
      res.totalamountrow2 = '0';
    }
    if (res.totalamountrow3 == undefined || res.totalamountrow3 == '') {
      res.totalamountrow3 = '0';
    }
    if (res.totalamountrow4 == undefined || res.totalamountrow4 == '') {
      res.totalamountrow4 = '0';
    }
    if (res.discount == undefined || res.discount == '') {
      res.discount = '0';
    }
    if (res.discountcost == undefined || res.discountcost == '') {
      res.discountcost = '0';
    }

    try {
      if (state.serviceCallId) {
        const idArray = res.logTechnicianAssigned.split(',');
        let results: EventAssignment[] = [];
        console.log('ID array', idArray);
        try {
          const assignmentReq = EventAssignment.create();
          assignmentReq.eventId = res.id;
          const assignedEvents = await EventAssignmentClientService.BatchGet(assignmentReq);
          results = assignedEvents!.results;
        } catch {
          console.log('no one assigned, just create');
        }
        try {
          for (const event in results) {
            const assignment = EventAssignment.create();
            assignment.id = results[event].id;
            await EventAssignmentClientService.Delete(assignment);
          }
          for (const id in idArray) {
            const assignment = EventAssignment.create();
            assignment.userId = Number(idArray[id]);
            assignment.eventId = state.serviceCallId;
            if (assignment.userId != 0) {
              await EventAssignmentClientService.Create(assignment);
            }
          }
        } catch (err) {
          console.log('error updating event assignment');
        }

        res.id = state.serviceCallId;

        let activityName = `${res.logJobNumber} Edited Job`;
        if (res.fieldMask.length > 0) {
          if (res.fieldMask.find((el) => el == 'DateStarted')) {
            res.timeStarted = returnCorrectTimeField(res.dateStarted);
            res.dateStarted = returnLegactyDate(res.dateStarted);

            res.fieldMask.push('TimeStarted');
          }
          if (res.fieldMask.find((el) => el == 'DateEnded')) {
            res.timeEnded = returnCorrectTimeField(res.dateEnded);
            res.dateEnded = returnLegactyDate(res.dateEnded);

            res.fieldMask.push('TimeEnded');
          }

          const activityMessage = createVerboseLogging(
            res,
            loggedUserQuery.data?.firstname || '',
            loggedUserQuery.data?.lastname || '',
          );
          if (activityMessage != '') {
            const activity = ActivityLog.create();
            activity.eventId = res.id;
            activity.activityName = activityMessage;
            activity.activityDate = timestamp();
            activity.userId = loggedUserId!;
            await ActivityLogClientService.Create(activity);
          }

          await handleUpdateMaterialsStringAndCost();
          if (state.saveInvoice == true) {
            //we are invoicing, so set a billing date
            if (res.logBillingDate == NULL_TIME || res.logBillingDate == '') {
              res.logBillingDate = res.dateEnded;

              res.fieldMask.push('LogBillingDate');
            }
          }
          console.log('We are updating', res);
          //we can't be sure whne someone needs to update the discount amount, since it can change
          // because of the total factor, so we need to always update it by default
          res.fieldMask.push('Discount');
          res.fieldMask.push('DiscountCost');

          await EventClientService.Update(res);
        }
        if (state.saveInvoice == true) {
          const invoice = InvoiceType.create();
          invoice.eventId = state.serviceCallId;
          invoice.contractId = res.contractId;
          invoice.propertyId = res.propertyId;
          invoice.propertyBilling = res.propertyBilling;
          invoice.notes = res.notes;
          invoice.servicesperformedrow1 = res.servicesperformedrow1;
          invoice.servicesperformedrow2 = res.servicesperformedrow2;
          invoice.servicesperformedrow3 = res.servicesperformedrow3;
          invoice.servicesperformedrow4 = res.servicesperformedrow4;
          invoice.totalamountrow1 = res.totalamountrow1;
          invoice.totalamountrow2 = res.totalamountrow2;
          invoice.totalamountrow3 = res.totalamountrow3;
          invoice.totalamountrow4 = res.totalamountrow4;
          invoice.userId = state.customer.id;
          invoice.serviceItem = res.invoiceServiceItem;
          invoice.discount = res.discount;
          invoice.discountcost = res.discountcost;
          invoice.startDate = res.dateStarted;
          invoice.materialTotal = res.materialTotal.toString();
          invoice.materialUsed = res.materialUsed;
          const total1 = parseFloat(res.totalamountrow1);
          const total2 = parseFloat(res.totalamountrow2);
          const total3 = parseFloat(res.totalamountrow3);
          const total4 = parseFloat(res.totalamountrow4);

          const discountAmount = parseFloat(res.discountcost);
          invoice.serviceItem = res.invoiceServiceItem;
          const materialTotal = res.materialTotal;
          const grandTotal = total1 + total2 + total3 + total4 + materialTotal - discountAmount;
          invoice.startDate = res.logBillingDate;
          invoice.logPaymentStatus = res.logPaymentStatus;
          invoice.logPaymentType = res.logPaymentType;
          invoice.totalamounttotal = grandTotal.toString();
          if (state.invoiceData && state.invoiceData.id != 0) {
            invoice.id = state.invoiceData.id;
            invoice.fieldMask = [
              'EventId',
              'StartDate',
              'PropertyId',
              'PropertyBilling',
              'Notes',
              'Servicesperformedrow1',
              'Servicesperformedrow2',
              'Servicesperformedrow3',
              'Servicesperformedrow4',
              'ServiceItem',
              'Totalamountrow1',
              'Totalamountrow2',
              'Totalamountrow3',
              'Totalamountrow4',
              'Totalamounttotal',
              'Discount',
              'Discountcost',
              'LogPaymentStatus',
              'LogPaymentType',
              'MaterialTotal',
              'MaterialUsed',
            ];
            try {
              await InvoiceClientService.Update(invoice);
            } catch (err) {
              console.log('we had an error updating the invoice', err);
            }
            const sqsInvoiceEmail = SQSEmailAndDocument.create();
            const email = SQSEmail.create();
            const document = Document.create();
            document.invoiceId = invoice.id;
            document.propertyId = invoice.propertyId;
            document.userId = state.customer.id;
            if (invoice.propertyBilling == 0) {
              email.to = state.customer.email;
            } else {
              email.to = state.property.email;
            }
            if (state.sendInvoiceToCustomer == false) {
              email.to = 'emailed.invoices@kalosflorida.com';
            }
            sqsInvoiceEmail.document = document;
            sqsInvoiceEmail.email = email;
            try {
              await InvoiceClientService.SendSQSInvoiceEmail(sqsInvoiceEmail);
            } catch (err) {
              console.log('we had an error updating the invoice', err);
            }
          } else {
            //we need to create it

            const newInvoice = await InvoiceClientService.Create(invoice);
            if (newInvoice) {
              dispatch({ type: 'SET_INVOICE_DATA', payload: newInvoice });
            }
            const sqsInvoiceEmail = SQSEmailAndDocument.create();
            const email = SQSEmail.create();
            const document = Document.create();
            document.invoiceId = newInvoice!.id;
            document.propertyId = newInvoice!.propertyId;
            document.userId = state.customer.id;
            email.to = state.customer.email;
            if (invoice.propertyBilling == 0) {
              email.to = state.customer.email;
            } else {
              email.to = state.property.email;
            }
            if (state.sendInvoiceToCustomer == false) {
              email.to = 'austinkempa@hotmail.com';
            }
            sqsInvoiceEmail.document = document;
            sqsInvoiceEmail.email = email;
            // console.log(sqsInvoiceEmail);
            await InvoiceClientService.SendSQSInvoiceEmail(sqsInvoiceEmail);
          }
          activityName = activityName.concat(` and Invoice`);
          dispatch({ type: 'SET_SEND_INVOICE_TO_CUSTOMER', payload: false });
          dispatch({ type: 'SET_SAVE_INVOICE', payload: false });
        }
        const newActivity = ActivityLog.create();
        if (state.property.geolocationLat && state.property.geolocationLng) {
          newActivity.geolocationLat = state.property.geolocationLat;
          newActivity.geolocationLng = state.property.geolocationLng;
        } else {
          activityName = activityName.concat(` (location services disabled)`);
          newActivity.propertyId = propertyId || state.entry.propertyId;
          newActivity.eventId = state.serviceCallId;
          newActivity.activityDate = format(new Date(), 'yyyy-MM-dd HH:mm:ss');
          newActivity.userId = loggedUserId!;
          newActivity.activityName = activityName;

          await ActivityLogClientService.Create(newActivity);
        }
      } else {
        const temp = Event.clone(state.entry);
        temp.propertyId = propertyId || state.entry.propertyId;
        temp.logVersion = 1;
        temp.timeStarted = returnCorrectTimeField(temp.dateStarted);
        temp.timeEnded = returnCorrectTimeField(temp.dateEnded);
        temp.dateStarted = returnLegactyDate(temp.dateStarted);
        temp.dateEnded = returnLegactyDate(temp.dateEnded);

        temp.discount = state.customer.discount.toString();
        const res = await EventClientService.Create(temp);
        jobId = res?.id;
        for (let i = 0; i < state.jobAdmins.length; i++) {
          const jobAdminReq = JobAdmin.clone(state.jobAdmins[i]);
          if (res && res.id != 0) {
            jobAdminReq.jobNumber = res.id;
            await EventClientService.CreateJobAdmin(jobAdminReq);
          }
        }
        const logNumber = `${format(new Date(), 'yy')}-${res!.id}`;
        const newEvent = Event.create();
        const idArray = temp.logTechnicianAssigned.split(',');
        let results: EventAssignment[] = [];

        try {
          const assignmentReq = EventAssignment.create();
          assignmentReq.eventId = res!.id;
          const assignedEvents = await EventAssignmentClientService.BatchGet(assignmentReq);
          results = assignedEvents!.results;
        } catch {
          console.log('no one assigned, just create');
        }
        try {
          for (const event in results) {
            const assignment = EventAssignment.create();
            assignment.id = results[event].id;
            EventAssignmentClientService.Delete(assignment);
          }
          for (const id in idArray) {
            const assignment = EventAssignment.create();
            assignment.userId = Number(idArray[id]);
            assignment.eventId = res!.id;
            await EventAssignmentClientService.Create(assignment);
          }
        } catch (err) {
          console.log('error updating event assignment');
        }
        newEvent.id = res!.id;
        newEvent.logJobNumber = logNumber;
        newEvent.isResidential = temp.isResidential;
        newEvent.fieldMask = ['Id', 'LogJobNumber', 'IsResidential'];
        await EventClientService.Update(newEvent);
        const newActivity = ActivityLog.create();
        let activityName = `${logNumber} Added Job`;
        if (state.property.geolocationLat && state.property.geolocationLng) {
          newActivity.geolocationLat = state.property.geolocationLat;
          newActivity.geolocationLng = state.property.geolocationLng;
        } else {
          activityName = activityName.concat(` (location services disabled)`);
        }
        newActivity.propertyId = propertyId || state.entry.propertyId;
        newActivity.activityDate = format(new Date(), 'yyyy-MM-dd HH:mm:ss');
        newActivity.userId = loggedUserId!;
        newActivity.eventId = res!.id;
        newActivity.activityName = activityName;
        await ActivityLogClientService.Create(newActivity);
      }
    } catch (err) {
      console.error(err);
    }

    if (!state.serviceCallId) {
      dispatch({ type: 'SET_SERVICE_CALL_ID', payload: res.id });
    }
    if (onSave && jobId) {
      onSave(jobId);
    }
    if (onClose) {
      onClose();
    } else {
      dispatch({ type: 'SET_SAVING', payload: false });
      dispatch({ type: 'SET_LOADING', payload: false });
    }

    dispatch({ type: 'SET_SAVING', payload: false });
    dispatch({ type: 'SET_LOADING', payload: false });
    state.entry?.property?.id && navigate(`/properties/${state.entry.property.id}`);
  }, [
    state.entry,
    state.serviceCallId,
    state.property,
    propertyId,
    state.customer,
    state.saveInvoice,
    state.invoiceData,
    state.sendInvoiceToCustomer,
    state.jobAdmins,
    onSave,
    onClose,
    handleUpdateMaterialsStringAndCost,
    loggedUserId,
    navigate,
    loggedUserQuery.data?.firstname,
    loggedUserQuery.data?.lastname,
  ]);

  // useEffect for loading data
  useEffect(() => {
    if (!state.loaded) {
      console.log('hi we have not loaded yet');
      load();
    }
    if (state.pendingSave) {
      dispatch({ type: 'SET_PENDING_SAVE', payload: false });
      saveServiceCall();
    }
    if (state.pendingSave && state.tabIdx === 0 && requestRef.current) {
      //@ts-ignore
      requestRef.current.click();
    }
  }, [
    state.entry,
    state.loaded,
    load,
    state.pendingSave,
    saveServiceCall,
    state.tabIdx,
    requestRef,
    eventId,
  ]);

  const handleSetRequestfields = useCallback(
    (fields: string[]) => {
      dispatch({
        type: 'SET_REQUEST_FIELDS',
        payload: [...state.requestFields, ...fields],
      });
    },
    [state.requestFields],
  );
  /*
  const handleChangeEntry = useCallback((data: Event) => {
    updateServiceCallState({
      type: 'setChangeEntry',
      data: {
        entry: data,
        pendingSave: false,
      },
    });
  }, []);
  */

  const jobTypeOptions: Option[] = state.jobTypes.map((id) => ({
    label: id.name,
    value: id.id,
  }));

  const jobSubtypeOptions: Option[] = [
    { label: OPTION_BLANK, value: 0 },
    ...state.jobTypeSubtypes
      .filter((jobTypeId) => jobTypeId.jobTypeId === state.entry.jobTypeId)
      .map((jobSubtypeId) => ({
        value: jobSubtypeId.jobSubtypeId,
        label: state.jobSubtypes.find((id) => id.id === jobSubtypeId.jobSubtypeId)?.name || '',
      })),
  ];
  const updateStatusForm = (status: string) => {
    const entry = Event.clone(state.entry);
    entry.logJobStatus = status;
    dispatch({ type: 'SET_ENTRY', payload: entry });
  };
  //const { id, logJobNumber, contractNumber } = entry;
  /*
  const {
    firstname,
    lastname,
    businessname,
    phone,
    altphone,
    cellphone,
    fax,
    email,
    billingTerms,
    notification,
  } = customer;

  const { address, city, state, zip } = property;
  */

  const handleSetServicesRenderedPayment = useCallback((payload: ServicesRenderedPaymentType) => {
    console.log({ payload });

    dispatch({ type: 'SET_SERVICES_RENDERED_PAYMENT', payload });
  }, []);

  const [tab, setTab] = useState('Requests');

  const data: Data = [
    [
      {
        label: 'Customer',
        value: <CopyDataAction data={firstname + ' ' + lastname} />,
      },
      {
        label: 'Business Name',
        value: <CopyDataAction data={businessname} />,
      },
    ],
    [
      {
        label: 'Primary Phone',
        value: <CopyDataAction data={phone} href={true} hrefTag="tel" />,
      },
      {
        label: 'Alternate Phone',
        value: <CopyDataAction data={altphone} href={true} hrefTag="tel" />,
      },
    ],
    [
      {
        label: 'Cell Phone',
        value: <CopyDataAction data={cellphone} href={true} hrefTag="tel" />,
      },
      {
        label: 'Fax',
        value: <CopyDataAction data={fax} href={true} hrefTag="tel" />,
      },
    ],
    [
      {
        label: 'Billing Terms',
        value: <CopyDataAction data={billingTerms} />,
      },
      {
        label: 'Email',
        value: <CopyDataAction data={email} href={true} hrefTag="mailto" />,
      },
    ],
    [
      {
        label: 'Property',
        value: <CopyDataAction data={address} />,
      },
      {
        label: 'City, State, Zip',
        value: <CopyDataAction data={city + ' ' + propertyState + ' ' + zip} />,
      },
    ],
    ...(state.serviceCallId
      ? [
          [
            {
              label: 'Job Number',
              value: <CopyDataAction data={logJobNumber} />,
            },
            {
              label: 'Contract Number',
              value: <CopyDataAction data={contractNumber} />,
            },
          ],
        ]
      : []),
  ];

  return state.loading ? (
    <div className="AddServiceCallContent-loader">
      <Loader customStyles />
    </div>
  ) : (
    <>
      <div className="flex flex-col items-center justify-between gap-2 bg-gray-200 p-2 md:flex-row">
        <h2 className="min-w-max text-lg md:text-2xl">
          {asProject ? 'Project Details' : 'Job Details'}
        </h2>
        {state.serviceCallId && (
          <div className="flex flex-wrap gap-2">
            <Button size="sm" onClick={toggleOpenSpiffApply} disabled={state.loading}>
              Spiff Apply
            </Button>
            {loggedUserQuery.data?.isWorkPieceAdmin && !workPieceQuery.data?.results.length && (
              <Button size="sm" onClick={toggleOpenPieceWorkApply} disabled={state.loading}>
                Piece Work Apply
              </Button>
            )}
            <Button size="sm" onClick={toggleOpenCallbackReview} disabled={!state.entry.isCallback}>
              Callback Review
            </Button>
            <Button size="sm" onClick={toggleOpenJobActivity} disabled={state.loading}>
              Job Activity
            </Button>
            <CustomerNotificationDialog
              customerId={state.customer.id}
              showOnMount={false}
              trigger={
                <Button size="sm">
                  {customerQuery.data?.notification ? 'Notification' : 'Add Notification'}
                </Button>
              }
            />
            {onClose ? (
              <Button size="sm" onClick={onClose}>
                Close
              </Button>
            ) : (
              <Button size="sm" asChild>
                <Link to={`/properties/${state.entry.propertyId}`}>Close</Link>
              </Button>
            )}
          </div>
        )}
      </div>

      <InfoTable data={data} error={state.error} className="JobDetailsTable" />
      <SectionBar title="Job Admins" uncollapsable>
        <JobAdminComponent
          loading={state.loading}
          jobAdminEntries={state.jobAdmins}
          onUpdateJobAdmins={(data) => dispatch({ type: 'SET_JOB_ADMINS', payload: data })}
          jobNumber={state.serviceCallId}
        />
      </SectionBar>
      <SectionBar
        title="Job Data"
        actions={[
          {
            label: 'Save Job Only',
            onClick: handleSave,

            disabled: state.loading || state.saving,
          },
          ...(state.serviceCallId
            ? [
                {
                  label: 'Save and Invoice',
                  onClick: () => {
                    dispatch({
                      type: 'SET_EMAIL_ALERT_OPEN',
                      payload: true,
                    });
                  },

                  disabled:
                    state.loading ||
                    state.saving ||
                    !loggedUserQuery.data?.hasBillingAccess ||
                    state.entry.logJobStatus === 'Archived',
                },
              ]
            : []),
          /*
          {
            label: 'Cancel',
            url: [
              '/index.cfm?action=admin:properties.details',
              `property_id=${propertyId}`,
              `user_id=${userID}`,
            ].join('&'),
            disabled: state.loading || state.saving,
          },
          */
        ]}
      />
      <Tabs
        value={tab}
        onValueChange={setTab}
        defaultValue="Requests"
        className="mt-4 flex w-full flex-col"
      >
        <TabsList className="bg-foreground/10 mx-auto flex h-max flex-wrap">
          <TabsTrigger value="Requests">Requests</TabsTrigger>
          {!!state.serviceCallId && (
            <>
              <TabsTrigger value="Equipment">Equipment</TabsTrigger>
              <TabsTrigger value="Services">Services</TabsTrigger>
              <TabsTrigger value="Invoice">Invoice</TabsTrigger>
              <TabsTrigger value="Proposals">Proposals</TabsTrigger>
              {loggedUserQuery.data?.isSpiffAdmin && (
                <TabsTrigger value="Spiffs">Spiffs</TabsTrigger>
              )}
              {loggedUserQuery.data?.isWorkPieceAdmin && (
                <TabsTrigger value="PieceWorkOptions">Piece Work Options</TabsTrigger>
              )}
              {!!state.customer.id && !!eventId && !!state.invoiceData?.id && (
                <TabsTrigger value="InvoicePayment">Payments</TabsTrigger>
              )}
            </>
          )}
        </TabsList>
        <TabsContent value="Requests">
          <Request
            key={state.loading.toString()}
            className="Service-Request"
            serviceItem={state.entry}
            propertyEvents={state.propertyEvents}
            loading={state.loading}
            jobTypeOptions={jobTypeOptions}
            jobSubtypeOptions={jobSubtypeOptions}
            canArchive={loggedUserQuery.data?.isArchiveAdmin}
            onChange={(data: Event) => {
              const filteredData: Partial<Event> = Object.keys(data).reduce((filtered, key) => {
                if (
                  requestValidateFields.includes(key) &&
                  data[key as keyof Event] === state.entry[key as keyof Event]
                ) {
                  // @ts-ignore
                  filtered[key] = data[key as keyof Event];
                }
                return filtered;
              }, {});
              const fields: string[] = Object.keys(filteredData);
              const { requestErrors, invoiceErrors } = handleCheckRequest(data, fields);
              dispatch({
                type: 'SET_REQUEST_ERRORS',
                payload: requestErrors,
              });
              dispatch({
                type: 'SET_INVOICE_ERRORS',
                payload: invoiceErrors,
              });
              dispatch({ type: 'SET_ENTRY', payload: data });
            }}
            disabled={state.saving}
            formErrors={state.requestErrors}
            onInitSchema={handleSetRequestfields}
          />
        </TabsContent>
        {state.serviceCallId && (
          <>
            <TabsContent value="Equipment">
              <Equipment
                propertyId={propertyId || state.entry.propertyId}
                event={state.entry}
                onSelectedChange={(value: string) => {
                  dispatch({
                    type: 'SET_ENTRY',
                    payload: { ...state.entry, invoiceServiceItem: value },
                  });
                }}
              />
            </TabsContent>
            <TabsContent value="Services">
              {loggedUserQuery.isSuccess ? (
                <Services
                  serviceRenderedPayment={state.servicesRenderedPayment}
                  serviceCallId={state.serviceCallId}
                  archived={state.entry.logJobStatus === 'Archived'}
                  servicesRenderedProp={state.servicesRendered}
                  loggedUser={loggedUserQuery.data}
                  updateServicesRenderedProp={updateServicesRenderedFromProp}
                  paidServicesFetch={loadPayments}
                  loading={state.loading}
                  updateStatus={updateStatusForm}
                  onUpdateMaterials={handleUpdateMaterialsStringAndCost}
                  updateServiceRenderedPayment={handleSetServicesRenderedPayment}
                />
              ) : (
                <InfoTable data={makeFakeRows(4, 4)} loading />
              )}
            </TabsContent>
            <TabsContent value="Invoice">
              {state.loading ? (
                <InfoTable data={makeFakeRows(4, 5)} loading />
              ) : (
                <Invoice
                  event={state.entry}
                  showBilling={!!loggedUserQuery.data?.hasBillingAccess}
                  onChangeServices={(data) => dispatch({ type: 'SET_ENTRY', payload: data })}
                  payments={state.payments}
                  onChangePayment={(data) => dispatch({ type: 'SET_ENTRY', payload: data })}
                  disabled={state.saving}
                  servicesRendered={state.servicesRendered}
                  onInitSchema={handleSetRequestfields}
                  formErrors={state.invoiceErrors}
                />
              )}
            </TabsContent>

            <TabsContent value="Proposals">
              <div key={'ProposalTab'}>
                <Proposal
                  serviceItem={state.entry}
                  servicesRendered={state.servicesRendered}
                  customer={state.customer}
                  property={state.property}
                  propStoredQuotes={state.storedQuote}
                  initalForm={state.proposalsForm}
                  handleSetPropStoredQuotes={(data) =>
                    dispatch({
                      type: 'SET_STORED_QUOTE',
                      payload: data,
                    })
                  }
                  reload={() => load()}
                  onFormChange={(payload) => {
                    dispatch({
                      type: 'SET_PROPOSALS_FORM',
                      payload,
                    });
                  }}
                />
              </div>
            </TabsContent>

            {loggedUserQuery.data?.isSpiffAdmin && (
              <TabsContent value="Spiffs">
                <Spiffs serviceItem={state.entry} />
              </TabsContent>
            )}

            <TabsContent value="PieceWorkOptions">
              <PieceWorkOptionsTable eventId={eventId ?? 0} />
            </TabsContent>

            {!!state.customer.id && !!eventId && !!state.invoiceData?.id && (
              <TabsContent value="InvoicePayment">
                <InvoicePaymentModule
                  invoiceId={state.invoiceData.id}
                  customerId={state.customer.id}
                />
              </TabsContent>
            )}
          </>
        )}
      </Tabs>
      {state.openJobActivity && eventId && eventId != 0 && (
        <Modal open={state.openJobActivity} onClose={() => toggleOpenJobActivity()}>
          <ServiceCallLogs eventId={eventId} onClose={() => toggleOpenJobActivity()} />
        </Modal>
      )}
      {state.openSpiffApply && state.serviceCallId != 0 && (
        <SpiffApplyComponent
          loggedUserId={loggedUserId!}
          serviceCallId={state.serviceCallId}
          onClose={() => toggleOpenSpiffApply()}
        />
      )}
      <Dialog onOpenChange={() => toggleOpenPieceWorkApply()} open={state.openPieceWorkApply}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Create Piece Work Spiff</DialogTitle>
          </DialogHeader>
          <PieceWorkApplyForm
            onMissingOptionAlertCtaClick={() => {
              setTab('PieceWorkOptions');
              setCreateSpiffDialogStore(true);
            }}
            onSubmit={() => toggleOpenPieceWorkApply()}
            onClose={() => toggleOpenPieceWorkApply()}
            eventId={state.serviceCallId}
          />
        </DialogContent>
      </Dialog>
      {state.openCallbackReview && state.serviceCallId != 0 && (
        <CallbackReview
          loggedUserId={loggedUserId!}
          event={state.entry}
          onClose={() => toggleOpenCallbackReview()}
        />
      )}
      {state.emailAlertOpen && state.serviceCallId != 0 && (
        <Modal
          open={state.emailAlertOpen}
          onClose={() => {
            dispatch({ type: 'SET_EMAIL_ALERT_OPEN', payload: false });
          }}
        >
          <Form
            title="Confirm Invoice"
            key={state.serviceCallId + 'Invoice'}
            submitLabel="Invoice"
            schema={SCHEMA_EMAIL_INVOICE}
            onClose={() => {
              dispatch({ type: 'SET_EMAIL_ALERT_OPEN', payload: false });
            }}
            onSave={(sendInvoice) => {
              dispatch({
                type: 'SET_SEND_INVOICE_TO_CUSTOMER',
                payload: sendInvoice.emailInvoice,
              });
              dispatch({ type: 'SET_SAVE_INVOICE', payload: true });
              dispatch({ type: 'SET_EMAIL_ALERT_OPEN', payload: false });
              dispatch({ type: 'SET_PENDING_SAVE', payload: true });
            }}
            data={{ emailInvoice: state.sendInvoiceToCustomer }}
          ></Form>
        </Modal>
      )}
    </>
  );
};
