import { type Reading } from '@kalos/kalos-rpc';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Button,
  FileUploader,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  Input,
  SimpleSelect,
} from '@kalos/ui';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import { queryKeys } from '../../../hooks/react-query/constants';
import { type DirtyModelFields } from '../../../tools/helpers';
import { type ReadingSchemeType, useReadingForm } from './utils';

interface Option {
  label: string;
  value: string;
}

const REFRIGERANT_TYPES: Option[] = [
  { label: 'R410a', value: '1' },
  { label: 'R22', value: '2' },
  { label: 'Other', value: '3' },
];

const ReadingForm = ({
  data,
  showImages = false,
  onSave,
  isLoading,
  disabled,
}: {
  data: Reading;
  showImages?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  onSave?: (arg: {
    data: ReadingSchemeType;
    dirtyFields: DirtyModelFields<ReadingSchemeType>;
  }) => void;
}) => {
  const [_defaultValue, setDefaultValue] = useState(data);
  const queryClient = useQueryClient();

  useEffect(() => {
    setDefaultValue(data);
  }, [data]);

  const form = useReadingForm({ defaultValues: _defaultValue, disabled });
  const onFormSave = (formData: ReadingSchemeType) => {
    queryClient.invalidateQueries({
      queryKey: [queryKeys.readings.root, queryKeys.readings.list],
    });
    onSave?.({ data: formData, dirtyFields: form.formState.dirtyFields });
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onFormSave)} className="flex flex-col">
        <Accordion collapsible type="single" defaultChecked>
          <AccordionItem value="refrigerant">
            <AccordionTrigger className="mb-3 bg-[#e0e0e0] px-2 py-1 font-semibold duration-200 hover:bg-[#d3d3d3]">
              <h1>Refrigerant</h1>
            </AccordionTrigger>
            <AccordionContent>
              <div className="grid grid-cols-2 gap-2 overflow-hidden px-2 duration-300 sm:grid-cols-3 md:h-auto md:gap-3">
                <FormField
                  control={form.control}
                  name="refrigerantType"
                  render={({ field }) => (
                    <FormItem className="col-span-2 sm:col-span-3">
                      <FormLabel>Refrigerant Type</FormLabel>
                      <FormControl>
                        <SimpleSelect
                          {...field}
                          selectedValue={field.value.toString()}
                          onChange={(value) => {
                            field.onChange(value);
                          }}
                          values={REFRIGERANT_TYPES}
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="tstatBrand"
                  render={({ field }) => (
                    <FormItem className="flex flex-col justify-end">
                      <FormLabel>Tstat brand</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="blowerCapacitor"
                  render={({ field }) => (
                    <FormItem className="flex flex-col justify-end">
                      <FormLabel>Blower Capacitor</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="blowerAmps"
                  render={({ field }) => (
                    <FormItem className="flex flex-col justify-end">
                      <FormLabel>Blower Amps</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="returnDb"
                  render={({ field }) => (
                    <FormItem className="flex flex-col justify-end">
                      <FormLabel>Return Temp DB</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="supplyTemperature"
                  render={({ field }) => (
                    <FormItem className="flex flex-col justify-end">
                      <FormLabel>Supply Temp DB</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="poolSupplyTemp"
                  render={({ field }) => (
                    <FormItem className="flex flex-col justify-end">
                      <FormLabel>Pool supply temp</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="poolReturnTemp"
                  render={({ field }) => (
                    <FormItem className="flex flex-col justify-end">
                      <FormLabel>Pool return temp</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="ambientAirTemp"
                  render={({ field }) => (
                    <FormItem className="flex flex-col justify-end">
                      <FormLabel>Ambient air temp</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="coilStaticDrop"
                  render={({ field }) => (
                    <FormItem className="flex flex-col justify-end">
                      <FormLabel>Coil Static Drop</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="returnWb"
                  render={({ field }) => (
                    <FormItem className="flex flex-col justify-end">
                      <FormLabel>Return WB</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="evapTd"
                  render={({ field }) => (
                    <FormItem className="flex flex-col justify-end">
                      <FormLabel>Evap TD</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="tesp"
                  render={({ field }) => (
                    <FormItem className="flex flex-col justify-end">
                      <FormLabel>Tesp</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                    </FormItem>
                  )}
                />
              </div>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
        <Accordion collapsible type="single">
          <AccordionItem value="compressor">
            <AccordionTrigger className="mb-3 bg-[#e0e0e0] px-2 py-1 font-semibold duration-200 hover:bg-[#d3d3d3]">
              <h1>Compressor</h1>
            </AccordionTrigger>
            <AccordionContent>
              <div className="grid grid-cols-2 gap-2 overflow-hidden px-2 duration-300 md:h-auto md:grid-cols-3 md:gap-3">
                <FormField
                  control={form.control}
                  name="compressorAmps"
                  render={({ field }) => (
                    <FormItem className="flex flex-col justify-end">
                      <FormLabel>Compressor Amps</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="compressorCapacitor"
                  render={({ field }) => (
                    <FormItem className="flex flex-col justify-end">
                      <FormLabel>Compressor Capacitor</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="condensingFanAmps"
                  render={({ field }) => (
                    <FormItem className="flex flex-col justify-end">
                      <FormLabel>Condenser Fan Amps</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="condenserFanCapacitor"
                  render={({ field }) => (
                    <FormItem className="flex flex-col justify-end">
                      <FormLabel>Condenser Fan Capacitor</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="suctionPressure"
                  render={({ field }) => (
                    <FormItem className="flex flex-col justify-end">
                      <FormLabel>Suction Pressure</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="headPressure"
                  render={({ field }) => (
                    <FormItem className="flex flex-col justify-end">
                      <FormLabel>Head Pressure</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="dischargeTemperature"
                  render={({ field }) => (
                    <FormItem className="col-span-2 md:col-span-3">
                      <FormLabel>Discharge Temperature</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="subcool"
                  render={({ field }) => (
                    <FormItem className="flex flex-col justify-end">
                      <FormLabel>Subcool</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="superheat"
                  render={({ field }) => (
                    <FormItem className="flex flex-col justify-end">
                      <FormLabel>Superheat</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="gasPressureIn"
                  render={({ field }) => (
                    <FormItem className="flex flex-col justify-end">
                      <FormLabel>Gas Pressure In</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="gasPressureOut"
                  render={({ field }) => (
                    <FormItem className="flex flex-col justify-end">
                      <FormLabel>Gas Pressure Out</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="llTempDrop"
                  render={({ field }) => (
                    <FormItem className="flex flex-col justify-end">
                      <FormLabel>LL Temp Drop</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="slTempDrop"
                  render={({ field }) => (
                    <FormItem className="flex flex-col justify-end">
                      <FormLabel>SL Temp Drop</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="notes"
                  render={({ field }) => (
                    <FormItem className="col-span-2 md:col-span-3">
                      <FormLabel>Notes</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                    </FormItem>
                  )}
                />
              </div>
            </AccordionContent>
          </AccordionItem>
        </Accordion>

        {showImages && (
          <FormField
            control={form.control}
            name="images"
            render={({ field }) => (
              <FormItem className="col-span-2 md:col-span-3">
                <FormLabel>Images</FormLabel>
                <FormControl>
                  <FileUploader
                    value={field.value}
                    onValueChange={field.onChange}
                    multiple
                    disabled={field.disabled}
                    maxFiles={Infinity}
                  />
                </FormControl>
              </FormItem>
            )}
          />
        )}

        <Button
          disabled={disabled}
          isLoading={isLoading}
          type="submit"
          className="mt-3 w-max self-end"
        >
          Save
        </Button>
      </form>
    </Form>
  );
};

export default ReadingForm;
