// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "kalos_devices.proto" (syntax proto3)
// tslint:disable
// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "kalos_devices.proto" (syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { WireType } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
// @generated message type with reflection information, may provide speed optimized methods
class Device$Type extends MessageType {
    constructor() {
        super("Device", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "device_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "purchase_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "price", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 5, name: "brand", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "model", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "serial_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "device_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "admin_pass", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "admin_pin", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 11, name: "user_pin", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 12, name: "operating_system", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "is_active", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 14, name: "owner_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 15, name: "asset_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 18, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 19, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 20, name: "not_equals", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, deviceType: "", purchaseDate: "", price: 0, brand: "", model: "", serialNumber: "", deviceName: "", adminPass: "", adminPin: 0, userPin: 0, operatingSystem: "", isActive: 0, ownerId: 0, assetId: "", fieldMask: [], pageNumber: 0, orderBy: "", orderDir: "", notEquals: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string device_type */ 2:
                    message.deviceType = reader.string();
                    break;
                case /* string purchase_date */ 3:
                    message.purchaseDate = reader.string();
                    break;
                case /* double price */ 4:
                    message.price = reader.double();
                    break;
                case /* string brand */ 5:
                    message.brand = reader.string();
                    break;
                case /* string model */ 6:
                    message.model = reader.string();
                    break;
                case /* string serial_number */ 7:
                    message.serialNumber = reader.string();
                    break;
                case /* string device_name */ 8:
                    message.deviceName = reader.string();
                    break;
                case /* string admin_pass */ 9:
                    message.adminPass = reader.string();
                    break;
                case /* int32 admin_pin */ 10:
                    message.adminPin = reader.int32();
                    break;
                case /* int32 user_pin */ 11:
                    message.userPin = reader.int32();
                    break;
                case /* string operating_system */ 12:
                    message.operatingSystem = reader.string();
                    break;
                case /* int32 is_active */ 13:
                    message.isActive = reader.int32();
                    break;
                case /* int32 owner_id */ 14:
                    message.ownerId = reader.int32();
                    break;
                case /* string asset_id */ 15:
                    message.assetId = reader.string();
                    break;
                case /* repeated string field_mask */ 16:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 17:
                    message.pageNumber = reader.int32();
                    break;
                case /* string order_by */ 18:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 19:
                    message.orderDir = reader.string();
                    break;
                case /* repeated string not_equals */ 20:
                    message.notEquals.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string device_type = 2; */
        if (message.deviceType !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.deviceType);
        /* string purchase_date = 3; */
        if (message.purchaseDate !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.purchaseDate);
        /* double price = 4; */
        if (message.price !== 0)
            writer.tag(4, WireType.Bit64).double(message.price);
        /* string brand = 5; */
        if (message.brand !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.brand);
        /* string model = 6; */
        if (message.model !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.model);
        /* string serial_number = 7; */
        if (message.serialNumber !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.serialNumber);
        /* string device_name = 8; */
        if (message.deviceName !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.deviceName);
        /* string admin_pass = 9; */
        if (message.adminPass !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.adminPass);
        /* int32 admin_pin = 10; */
        if (message.adminPin !== 0)
            writer.tag(10, WireType.Varint).int32(message.adminPin);
        /* int32 user_pin = 11; */
        if (message.userPin !== 0)
            writer.tag(11, WireType.Varint).int32(message.userPin);
        /* string operating_system = 12; */
        if (message.operatingSystem !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.operatingSystem);
        /* int32 is_active = 13; */
        if (message.isActive !== 0)
            writer.tag(13, WireType.Varint).int32(message.isActive);
        /* int32 owner_id = 14; */
        if (message.ownerId !== 0)
            writer.tag(14, WireType.Varint).int32(message.ownerId);
        /* string asset_id = 15; */
        if (message.assetId !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.assetId);
        /* repeated string field_mask = 16; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(16, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 17; */
        if (message.pageNumber !== 0)
            writer.tag(17, WireType.Varint).int32(message.pageNumber);
        /* string order_by = 18; */
        if (message.orderBy !== "")
            writer.tag(18, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 19; */
        if (message.orderDir !== "")
            writer.tag(19, WireType.LengthDelimited).string(message.orderDir);
        /* repeated string not_equals = 20; */
        for (let i = 0; i < message.notEquals.length; i++)
            writer.tag(20, WireType.LengthDelimited).string(message.notEquals[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Device
 */
export const Device = new Device$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeviceList$Type extends MessageType {
    constructor() {
        super("DeviceList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Device },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Device results */ 1:
                    message.results.push(Device.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated Device results = 1; */
        for (let i = 0; i < message.results.length; i++)
            Device.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DeviceList
 */
export const DeviceList = new DeviceList$Type();
/**
 * @generated ServiceType for protobuf service DeviceService
 */
export const DeviceService = new ServiceType("DeviceService", [
    { name: "Create", options: {}, I: Device, O: Device },
    { name: "Get", options: {}, I: Device, O: Device },
    { name: "BatchGet", options: {}, I: Device, O: DeviceList },
    { name: "Update", options: {}, I: Device, O: Device },
    { name: "Delete", options: {}, I: Device, O: Device }
]);
