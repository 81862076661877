import { type FileObject, TransactionDocument, TransactionDocumentList } from '@kalos/kalos-rpc';
import { type Query, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { TransactionDocumentClientService } from '../../tools/helpers';
import { queryKeys } from './constants';
import { type EntityFilter } from './utils';

export type TransactionDocumentFilter = EntityFilter<TransactionDocument>;
export const useTransactionDocumentsBatchQuery = <TSelectData = TransactionDocument[],>({
  enabled = true,
  filter = {},
  select,
}: {
  enabled?: boolean;
  select?: (data: TransactionDocumentList) => TSelectData;
  filter?: TransactionDocumentFilter;
}) => {
  return useQuery({
    queryKey: [
      queryKeys.transactionDocument.root,
      queryKeys.transactionDocument.list,
      queryKeys.transactionDocument.getFilterHash(filter),
    ],
    queryFn: async () => {
      return await TransactionDocumentClientService.BatchGet(TransactionDocument.create(filter));
    },
    enabled,
    select,
  });
};

export const useDeleteTransactionDocumentByIDMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (arg: { fileId: number; transactionFileId: number }) => {
      return await TransactionDocumentClientService.DeleteTransactionDocument(arg);
    },
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: [queryKeys.transactionDocument.root] });
    },
  });
};

const getPredicateForTransactionDocumentListById = (id: number) => {
  return (query: Query) => {
    if (
      !(
        query.queryKey.at(0) === queryKeys.transactionDocument.root &&
        query.queryKey.at(1) === queryKeys.transactionDocument.list
      )
    ) {
      return false;
    }

    const filter = query.queryKey.at(2);
    if (!filter || typeof filter !== 'string') {
      return false;
    }

    const parsedFilter = JSON.parse(filter) as unknown;
    if (
      typeof parsedFilter === 'object' &&
      parsedFilter &&
      'transactionId' in parsedFilter &&
      typeof parsedFilter.transactionId === 'number'
    ) {
      return parsedFilter.transactionId === id;
    }

    return false;
  };
};

export const useCreateTransactionDocumentMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (arg: TransactionDocument) => {
      return await TransactionDocumentClientService.Create(arg);
    },
    onSuccess(result) {
      queryClient.setQueriesData<TransactionDocumentList>(
        {
          predicate: getPredicateForTransactionDocumentListById(result.transactionId),
        },
        (cache) => {
          if (cache) {
            return TransactionDocumentList.create({
              results: [...cache.results, result],
              totalCount: cache.totalCount + 1,
            });
          }
        },
      );
      queryClient.invalidateQueries({
        queryKey: [queryKeys.transactionDocument.root, queryKeys.transaction.list],
      });
    },
  });
};

export const useUploadTransactionDocumentMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      transactionId,
      fileData,
      fileName,
    }: {
      transactionId: number;
      fileName: string;
      fileData: Uint8Array;
    }) => await TransactionDocumentClientService.upload(transactionId, fileName, fileData),
    onSuccess(data) {
      if (data) {
        queryClient.setQueriesData<TransactionDocumentList>(
          {
            predicate: getPredicateForTransactionDocumentListById(data.transactionId),
          },
          (oldData) => {
            const updatedData: TransactionDocumentList = oldData || { results: [], totalCount: 0 };
            updatedData.results = [...updatedData.results, data];
            updatedData.totalCount += 1;
            return updatedData;
          },
        );
      }
    },
  });
};

export const useTransactionDocumentDataByIdQuery = <TSelectData = FileObject,>({
  enabled = true,
  transactionId,
  reference,
  select,
}: {
  enabled?: boolean;
  transactionId: number;
  reference: string;
  select?: (data?: FileObject) => TSelectData;
}) => {
  return useQuery({
    queryKey: [
      queryKeys.transactionDocument.root,
      queryKeys.transactionDocument.data,
      transactionId,
      reference,
    ],
    queryFn: async () => {
      return await TransactionDocumentClientService.download(transactionId, reference);
    },
    select,
  });
};
