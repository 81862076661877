import { BaseClient } from '../BaseClient';
import { Device, DeviceList } from '../compiled-protos/kalos_devices';
import { DeviceServiceClient } from '../compiled-protos/kalos_devices.client';

class DeviceClient extends BaseClient {
  self: DeviceServiceClient;

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new DeviceServiceClient(this.transport);
  }

  public async Create(req: Device) {
    let res = Device.create();
    try {
      res = await this.self.create(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Get(req: Device) {
    let res = Device.create();
    try {
      res = await this.self.get(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Update(req: Device) {
    let res = Device.create();
    try {
      res = await this.self.update(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Delete(req: Device) {
    let res = Device.create();
    try {
      res = await this.self.delete(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async BatchGet(req: Device) {
    let res = DeviceList.create();
    try {
      res = await this.self.batchGet(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }
}

export { Device, DeviceClient, DeviceList };
