import { BaseClient } from '../BaseClient';
import {
  TransactionStatus,
  TransactionStatusList,
} from '../compiled-protos/transaction';
import { TransactionServiceClient } from '../compiled-protos/transaction.client';

class TransactionStatusClient extends BaseClient {
  self: TransactionServiceClient;
  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new TransactionServiceClient(this.transport);
  }

  public async Get(req: TransactionStatus) {
    let res = TransactionStatus.create();
    try {
      res = await this.self.getTransactionStatus(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async BatchGet(req: TransactionStatus) {
    let res = TransactionStatusList.create();
    try {
      res = await this.self.batchGetTransactionStatus(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }
}

export { TransactionStatus, TransactionStatusList, TransactionStatusClient };
