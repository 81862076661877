import { EmployeeItem, EmployeeItemList } from '@kalos/kalos-rpc';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { UserClientService } from '../../tools/helpers';
import { queryKeys } from './constants';
import { type EntityFilter } from './utils';

export const useEmployeeItemCreateMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (employeeItem: EmployeeItem) => {
      return await UserClientService.CreateEmployeeItem(employeeItem);
    },
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries({ queryKey: [queryKeys.employeeItems.root] });
    },
  });
};

export const useEmployeeItemUpdateMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (employeeItem: EmployeeItem) => {
      return await UserClientService.UpdateEmployeeItem(employeeItem);
    },
    onSuccess(data, variables, context) {
      queryClient.setQueriesData<EmployeeItemList>(
        { queryKey: [queryKeys.employeeItems.root, queryKeys.employeeItems.list] },
        (oldData) => {
          if (oldData && oldData.results.find((item) => item.id === data.id)) {
            return EmployeeItemList.create({
              totalCount: oldData.totalCount,
              results: oldData.results.map((item) => (item.id === data.id ? data : item)),
            });
          }
        },
      );
    },
  });
};

export const useEmployeeItemDeleteMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (employeeItem: EmployeeItem) => {
      return await UserClientService.DeleteEmployeeItem(employeeItem);
    },
    onSuccess(data, variables, context) {
      queryClient.setQueriesData<EmployeeItemList>(
        { queryKey: [queryKeys.employeeItems.root, queryKeys.employeeItems.list] },
        (oldData) => {
          if (oldData && oldData.results.find((item) => item.id === data.id)) {
            return EmployeeItemList.create({
              totalCount: oldData.totalCount - 1,
              results: oldData.results.filter((item) => item.id !== data.id),
            });
          }
        },
      );
      queryClient.invalidateQueries({ queryKey: [queryKeys.employeeItems.root] });
    },
  });
};

export type EmployeeItemFilter = EntityFilter<EmployeeItem>;
export const useBatchGetEmployeeItemsQuery = ({ filter = {} }: { filter?: EmployeeItemFilter }) => {
  return useQuery({
    queryKey: [
      queryKeys.employeeItems.root,
      queryKeys.employeeItems.list,
      queryKeys.employeeItems.getHash(filter),
    ],
    queryFn: async () => {
      return await UserClientService.BatchGetEmployeeItem(EmployeeItem.create(filter));
    },
  });
};
