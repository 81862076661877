import { BaseClient } from '../BaseClient';
import { type OrderDir } from '../Common';
import { Bool, Empty, Int32 } from '../compiled-protos/common';
import {
  CardData, CardDataList, EmployeeItem, EmployeeItemList, NewHire, type NewHireFinalizeRequest, NewHireFinalizeResponse, NewHireList, NewHireOption,
  NewHireOptionList, type OrganizationGroup, OrganizationGroupList,
  type OrganizationGroupUser, OrganizationGroupUserList, type PermissionGroup,
  PermissionGroupList,
  PermissionGroupUser, PermissionGroupUserList,
  Team, TeamLevel, TeamLevelList, TeamList,
  TeamMember, TeamMemberList,
  User, UserAttributes,
  UserList
} from '../compiled-protos/user';
import { UserServiceClient } from '../compiled-protos/user.client';

class UserClient extends BaseClient {

  self: UserServiceClient
  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new UserServiceClient(this.transport)
  }
  public async GetCardList(req: CardData) {
    let res = CardDataList.create()
    try {
      res = await this.self.getCardList(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }

  public async loadCardList() {
    const req = CardData.create();
    return this.GetCardList(req);
  }

  public async Create(req: User) {
    let res = User.create()
    try {
      res = await this.self.create(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }

  public async CreateUserAttributes(req: UserAttributes) {
    let res = UserAttributes.create()
    try {
      res = await this.self.createUserAttributes(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }

  public async Get(req: User) {
    return await this.self.get(req, this.getMetaDataWithoutCache()).response
  }

  public async GetUserAttributes(req: UserAttributes) {
    let res = UserAttributes.create()
    try {
      res = await this.self.getUserAttributes(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }

  public async GetUserManager(req: User) {
    let res = User.create()
    try {
      res = await this.self.getUserManager(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }
  public async Update(req: User) {
    let res = User.create()
    try {
      res = await this.self.update(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }

  public async UpdateUserAttributes(req: UserAttributes) {
    let res = UserAttributes.create()
    try {
      res = await this.self.updateUserAttributes(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }

  public async Delete(req: User) {
    let res = User.create()
    try {
      res = await this.self.delete(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }

  public async BatchGet(req: User) {
    return await this.self.batchGet(req, this.getMetaData()).response
  }

  public async BatchGetUsersByIds(req: number[], overrideLimit = false) {
    return this.loadUsersByIds(req, overrideLimit);
  }
  /**
   * Returns loaded User by its ids
   * @param id: user id
   * @returns User
   */
  public async loadUserById(id: number, withProperties?: boolean) {
    try {
      return this.Get(User.create({ id: id, withProperties }));
    } catch (err) {
      console.error('Failed to fetch user with id', id, err);
      return User.create({ id: id, isActive: 1, isEmployee: 1 });
    }
  }

  public async GetUserIdsInPermissionGroup(id: number) {
    return this.self.getUserIdsInPermissionGroup(Int32.create({ value: id }), this.getMetaData())
  }

  public async AddUserToPermissionGroup(req: PermissionGroupUser) {
    let res = Bool.create()
    try {
      res = await this.self.addUserToPermissionGroup(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }

  public async GetPermissionGroupUser(req: PermissionGroupUser) {
    let res = PermissionGroupUser.create()
    try {
      res = await this.self.getPermissionGroupUser(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }

  public async BatchGetPermissionGroupUser(req: PermissionGroupUser) {
    let res = PermissionGroupUserList.create()
    try {
      res = await this.self.batchGetPermissionGroupUser(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }

  public async RemoveUserFromPermissionGroup(req: PermissionGroupUser) {
    let res = Empty.create()
    try {
      res = await this.self.removeUserFromPermissionGroup(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }

  public async BatchGetPermission(req: PermissionGroup) {
    let res = PermissionGroupList.create()
    try {
      res = await this.self.batchGetPermissions(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }

  public async BatchGetUserPermissions(req: PermissionGroupUser) {
    let res = PermissionGroupUserList.create()
    try {
      res = await this.self.batchGetUserPermissions(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }
  /**
   * fetches technicians
   * @returns User[]
   */
  public async loadTechnicians(sortByLastName = false) {
    let sortField = 'full_name'
    if (sortByLastName) {
      sortField = 'full_name_reverse'
    }
    const req = User.create({ isActive: 1, isEmployee: 1, overrideLimit: true, orderBy: sortField, orderDir: 'ASC' });

    const data = await this.BatchGet(req);
    return data.results
  }

  public async loadCreditCardWithAccount(account: string) {
    return this.GetCardList(CardData.create({ account, withUser: true }));
  }

  /** Returns all loaded Users by department id, does not support pagination, returns in alphabetical order
   * @param departmentId: number
   * @returns User[]
   */
  public async loadUsersByDepartmentId(departmentId: number) {
    const req = User.create({ isActive: 1, employeeDepartmentID: departmentId, overrideLimit: true });
    const data = await this.BatchGet(req);
    return data.results.sort((a, b) => {
      const A = `${a.firstname} ${a.lastname}`.toLocaleLowerCase();
      const B = `${b.firstname} ${b.lastname}`.toLocaleLowerCase();
      if (A < B) return -1;
      if (A > B) return 1;
      return 0;
    })
  }

  public deleteUserById = async (id: number) => {
    await this.Delete(User.create({ id: id }));
  };

  public async getUserManagerByUserID(id: number) {
    return this.GetUserManager(User.create({ id: id }));
  }

  public getEmailByUserID = async (id: number) => {
    return (await this.Get(User.create({ id: id }))).email
  };

  /**
   * Returns loaded Users by their ids
   * @param ids: array of user id
   * @returns object { [userId]: User }
   */
  public async loadUsersByIds(ids: number[], overrideLimit = false) {
    return this.BatchGet(User.create({ userIdList: ids.join(','), overrideLimit }));
  }

  public saveUser = async (data: User, userId?: number) => {
    if (userId) {
      data.id = userId;
    }
    if (data.id !== 0 && data.fieldMask.length === 0) {
      throw new Error(
        'Attempting to update entity without providing a field mask will result in a no op'
      );
    }
    return this[userId ? 'Update' : 'Create'](data);
  };

  public Customer_Get({ authToken, req }: { authToken: string, req: User }) {
    return this.self.get(req, this.getMetaData(authToken)).response;
  }
  public Customer_BatchGet({ authToken, req }: { authToken: string, req: User }) {
    return this.self.batchGet(req, this.getMetaData(authToken)).response;
  }

  public getBusinessName = (c: User): string =>
    c ? c.businessname.trim() : '';

  public getCustomerPhone = (c: User): string => (c ? c.phone.trim() : '');

  public getCustomerPhoneWithExt = (c: User): string =>
    c ? `${c.phone.trim()}${c.ext ? `, ${c.ext}` : ''}` : '';

  public getCustomerNameAndBusinessName = (c: User): string => {
    const name = this.getCustomerName(c);
    const businessname = this.getBusinessName(c);
    return `${name}${businessname ? ' - ' : ''}${businessname}`.trim();
  };

  public async CreateTeam(req: Team) {
    let res = Team.create()
    try {
      res = await this.self.createTeam(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }

  public async GetTeam(req: Team) {
    let res = Team.create()
    try {
      res = await this.self.getTeam(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }

  public async BatchGetTeams(req: Team) {
    let res = TeamList.create()
    try {
      res = await this.self.batchGetTeams(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }

  public async BatchGetOrganizationGroup(req: OrganizationGroup) {
    let res = OrganizationGroupList.create()
    try {
      res = await this.self.batchGetOrganizationGroup(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }

  public async BatchGetOrganizationGroupUser(req: OrganizationGroupUser) {
    let res = OrganizationGroupUserList.create()
    try {
      res = await this.self.batchGetOrganizationGroupUser(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }
  public async UpdateTeam(req: Team) {
    let res = Team.create()
    try {
      res = await this.self.updateTeam(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }

  public async DeleteTeam(req: Team) {
    let res = Team.create()
    try {
      res = await this.self.deleteTeam(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }

  public async CreateTeamMember(req: TeamMember) {
    let res = TeamMember.create()
    try {
      res = await this.self.createTeamMember(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }

  public async GetTeamMember(req: TeamMember) {
    let res = TeamMember.create()
    try {
      res = await this.self.getTeamMember(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }

  public async BatchGetTeamMembers(req: TeamMember) {
    let res = TeamMemberList.create()
    try {
      res = await this.self.batchGetTeamMembers(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }

  public async UpdateTeamMember(req: TeamMember) {
    let res = TeamMember.create()
    try {
      res = await this.self.updateTeamMember(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }

  public async DeleteTeamMember(req: TeamMember) {
    let res = TeamMember.create()
    try {
      res = await this.self.deleteTeamMember(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }

  public async CreateTeamLevel(req: TeamLevel) {
    let res = TeamLevel.create()
    try {
      res = await this.self.createTeamLevel(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }

  public async GetTeamLevel(req: TeamLevel) {
    let res = TeamLevel.create()
    try {
      res = await this.self.getTeamLevel(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }

  public async BatchGetTeamLevels(req: TeamLevel) {
    let res = TeamLevelList.create()
    try {
      res = await this.self.batchGetTeamLevels(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }

  public async UpdateTeamLevel(req: TeamLevel) {
    let res = TeamLevel.create()
    try {
      res = await this.self.updateTeamLevel(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }

  public async DeleteTeamLevel(req: TeamLevel) {
    let res = TeamLevel.create()
    try {
      res = await this.self.deleteTeamLevel(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }

  public async GetNewHire(req: NewHire) {
    let res = NewHire.create()
    res = await this.self.getNewHire(req, this.getMetaData()).response
    return res
  }

  public async BatchGetNewHire(req: NewHire) {
    let res = NewHireList.create()
    res = await this.self.batchGetNewHire(req, this.getMetaData()).response
    return res
  }

  public async CreateNewHire(req: NewHire) {
    let res = NewHire.create()
    res = await this.self.createNewHire(req, this.getMetaData()).response
    return res
  }

  public async UpdateNewHire(req: NewHire) {
    let res = NewHire.create()
    res = await this.self.updateNewHire(req, this.getMetaData()).response
    return res
  }

  public async BatchGetNewHireOption(req: NewHireOption) {
    let res = NewHireOptionList.create()
    res = await this.self.batchGetNewHireOption(req, this.getMetaData()).response
    return res
  }

  public async NewHireFinalize(req: NewHireFinalizeRequest) {
    let res = NewHireFinalizeResponse.create()
    res = await this.self.newHireFinalize(req, this.getMetaData()).response
    return res
  }

  public async CreateEmployeeItem(req: EmployeeItem) {
    const res = await this.self.createEmployeeItem(req, this.getMetaDataWithoutCache()).response
    return res
  }

  public async DeleteEmployeeItem(req: EmployeeItem) {
    const res = await this.self.deleteEmployeeItem(req, this.getMetaDataWithoutCache()).response
    return res
  }

  public async UpdateEmployeeItem(req: EmployeeItem) {
    const res = await this.self.updateEmployeeItem(req, this.getMetaDataWithoutCache()).response
    return res
  }

  public async GetEmployeeItem(req: EmployeeItem) {
    const res = await this.self.getEmployeeItem(req, this.getMetaDataWithoutCache()).response
    return res
  }

  public async BatchGetEmployeeItem(req: EmployeeItem) {
    const res = await this.self.batchGetEmployeeItem(req, this.getMetaDataWithoutCache()).response
    return res
  }

  public getCustomerName = (c: User, lastNameFirst: boolean = false): string =>
    c
      ? (lastNameFirst
        ? `${c.lastname}, ${c.firstname}`
        : `${c.firstname} ${c.lastname}`
      ).trim()
      : '';

  /**
   * Returns Users by filter
   * @param page number (use -1 to disable pagination)
   * @param filter UsersFilter
   * @param sort sort
   * @returns {results: User[], totalCount: number}
   */
  /**
   * Returns Users by filter
   * @param page number (use -1 to disable pagination)
   * @param filter UsersFilter
   * @param sort sort
   * @returns {results: User[], totalCount: number}
   */
  loadUsersByFilter = async ({
    page,
    filter,
    sort,
    withProperties = false,
  }: LoadUsersByFilter) => {
    const { orderBy, orderDir } = sort;

    /*
  phone?: string;
  email?: string;
  isEmployee?: number;
  empTitle?: string;
  employeeDepartmentId?: number;
  ext?: string;
  cellphone?: string;
  id?: number;
    */
    const req = User.create({ isEmployee: filter.isEmployee ?? 0, isActive: filter.isActive ?? 1, orderBy, orderDir, withProperties });
    if (filter.businessname) {
      req.businessname = `%${filter.businessname}%`
    }
    if (filter.firstname) {
      req.firstname = `%${filter.firstname}%`
    }
    if (filter.lastname) {
      req.lastname = `%${filter.lastname}%`
    }
    if (filter.empTitle) {
      req.empTitle = `%${filter.empTitle}%`
    }
    if (filter.cellphone) {
      req.cellphone = `%${filter.cellphone}%`
    }
    if (filter.phone) {
      req.phone = `%${filter.phone}%`
    }
    if (filter.ext) {
      req.ext = filter.ext
    }
    if (filter.employeeDepartmentId) {
      req.employeeDepartmentID = filter.employeeDepartmentId
    }
    if (filter.email) {
      req.email = `%${filter.email}%`
    }
    if (filter.id) {
      req.id = filter.id
    }
    if (filter.fieldMask) {
      req.fieldMask = filter.fieldMask
    }
    if (page === -1) {
      req.overrideLimit = true;
    } else {
      req.pageNumber = page;
    }
    console.log('request', req)
    const data = await this.BatchGet(req);
    return {
      results: data.results,
      totalCount: data.totalCount,
    };
  };
}

type UsersSort = {
  orderByField: keyof User;
  orderBy: string;
  orderDir: OrderDir;
};

type LoadUsersByFilter = {
  page: number;
  filter: UsersFilter;
  sort: UsersSort;
  withProperties?: boolean;
};

type UsersFilter = {
  firstname?: string;
  lastname?: string;
  businessname?: string;
  phone?: string;
  email?: string;
  isEmployee?: number;
  empTitle?: string;
  isActive?: number,
  employeeDepartmentId?: number;
  ext?: string;
  cellphone?: string;
  id?: number;
  fieldMask?: string[];
};

export {
  User,
  UserList,
  UserClient,
  CardData,
  CardDataList,
  NewHire,
  NewHireList,
  NewHireOptionList,
  NewHireOption,
  NewHireFinalizeRequest,
  NewHireFinalizeResponse,
  EmployeeItem,
  EmployeeItemList,
};

export type { UsersSort, LoadUsersByFilter, UsersFilter }
