import { BaseClient } from '../BaseClient';
import { QuoteDocument, QuoteDocumentList } from '../compiled-protos/quote';
import { QuoteServiceClient as QuoteDocumentServiceClient } from '../compiled-protos/quote.client';

class QuoteDocumentClient extends BaseClient {
  self: QuoteDocumentServiceClient;

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new QuoteDocumentServiceClient(this.transport);
  }

  public async Create(req: QuoteDocument) {
    let res = QuoteDocument.create();
    try {
      res = await this.self.createQuoteDocument(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
      return;
    }
    return res;
  }

  public async Get(req: QuoteDocument) {
    let res = QuoteDocument.create();
    try {
      res = await this.self.getQuoteDocument(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
      return;
    }
    return res;
  }

  public async Update(req: QuoteDocument) {
    let res = QuoteDocument.create();
    try {
      res = await this.self.updateQuoteDocument(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
      return;
    }
    return res;
  }

  public async Delete(req: QuoteDocument) {
    let res = QuoteDocument.create();
    try {
      res = await this.self.deleteQuoteDocument(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
      return;
    }
    return res;
  }

  public async BatchGet(req: QuoteDocument) {
    let res = QuoteDocumentList.create();
    try {
      res = await this.self.batchGetQuoteDocument(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
      return;
    }
    return res;
  }
}

export { QuoteDocument, QuoteDocumentList, QuoteDocumentClient };
