import { InvoicePayment, type InvoicePaymentLine, InvoicePaymentList } from '@kalos/kalos-rpc';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { EventClientService } from '../../tools/helpers';
import { queryKeys } from './constants';
import { type EntityFilter } from './utils';

export type InvoicePaymentFilter = EntityFilter<InvoicePayment>;
export const useInvoicePaymentBatchGetQuery = ({
  filter = {},
}: { filter?: InvoicePaymentFilter } = {}) => {
  return useQuery({
    queryKey: [
      queryKeys.invoicePayment.root,
      queryKeys.invoicePayment.list,
      queryKeys.invoicePayment.getHash(filter),
    ],
    queryFn: async () => {
      return EventClientService.BatchGetInvoicePayment(InvoicePayment.create(filter));
    },
  });
};

export const useInvoicePaymentCreateMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (invoicePayment: InvoicePayment) => {
      return await EventClientService.CreateInvoicePayment(invoicePayment);
    },
    async onSuccess(data, variables, context) {
      await queryClient.invalidateQueries({
        queryKey: [queryKeys.invoicePayment.root, queryKeys.invoicePayment.list],
      });
    },
  });
};

export const useInvoicePaymentUpdateMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (invoicePayment: InvoicePayment) => {
      return EventClientService.UpdateInvoicePayment(invoicePayment);
    },
    async onSuccess(data, variables, context) {
      queryClient.setQueriesData<InvoicePaymentList>(
        {
          queryKey: [queryKeys.invoicePayment.root, queryKeys.invoicePayment.list],
        },
        (cache) => {
          if (cache && cache.results.find((ip) => ip.id === data.id)) {
            return InvoicePaymentList.create({
              results: cache.results.map((ip) => (ip.id === data.id ? data : ip)),
              totalCount: cache.totalCount,
            });
          }

          return cache;
        },
      );
    },
  });
};

export const useInvoicePaymentDeleteMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (invoicePayment: InvoicePayment) => {
      return EventClientService.DeleteInvoicePayment(invoicePayment);
    },
    async onSuccess(data, variables, context) {
      queryClient.setQueriesData<InvoicePaymentList>(
        {
          queryKey: [queryKeys.invoicePayment.root, queryKeys.invoicePayment.list],
        },
        (cache) => {
          if (cache && cache.results.find((ip) => ip.id === data.id)) {
            return InvoicePaymentList.create({
              results: cache.results.filter((ip) => ip.id !== data.id),
              totalCount: cache.totalCount - 1,
            });
          }
          return cache;
        },
      );
    },
  });
};

export const useInvoicePaymentLineCreateMutation = () => {
  return useMutation({
    mutationFn: async (invoicePaymentLine: InvoicePaymentLine) =>
      await EventClientService.CreateInvoicePaymentLine(invoicePaymentLine),
  });
};
export const useInvoicePaymentLineDeleteMutation = () => {
  return useMutation({
    mutationFn: async (invoicePaymentLine: InvoicePaymentLine) =>
      await EventClientService.DeleteInvoicePaymentLine(invoicePaymentLine),
  });
};
export const useInvoicePaymentLineUpdateMutation = () => {
  return useMutation({
    mutationFn: async (invoicePaymentLine: InvoicePaymentLine) =>
      await EventClientService.UpdateInvoicePaymentLine(invoicePaymentLine),
  });
};

export const useInvoicePaymentLineBatchMutation = () => {
  const createMutation = useInvoicePaymentLineCreateMutation();
  const deleteMutation = useInvoicePaymentLineDeleteMutation();
  const updateMutation = useInvoicePaymentLineUpdateMutation();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      toCreate,
      toDelete,
      toUpdate,
    }: {
      toCreate: InvoicePaymentLine[];
      toDelete: InvoicePaymentLine[];
      toUpdate: InvoicePaymentLine[];
    }) => {
      return Promise.all([
        ...toCreate.map(async (line) => await createMutation.mutateAsync(line)),
        ...toDelete.map(async (line) => await deleteMutation.mutateAsync(line)),
        ...toUpdate.map(async (line) => await updateMutation.mutateAsync(line)),
      ]);
    },
    async onSuccess(data, variables, context) {
      await queryClient.invalidateQueries({
        queryKey: [queryKeys.invoicePayment.root],
      });
    },
  });
};
