// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "quote.proto" (syntax proto3)
// tslint:disable
// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "quote.proto" (syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { WireType } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
// @generated message type with reflection information, may provide speed optimized methods
class StoredQuote$Type extends MessageType {
    constructor() {
        super("StoredQuote", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "price", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, description: "", price: 0, fieldMask: [], pageNumber: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string description */ 2:
                    message.description = reader.string();
                    break;
                case /* int32 price */ 3:
                    message.price = reader.int32();
                    break;
                case /* repeated string field_mask */ 4:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 5:
                    message.pageNumber = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string description = 2; */
        if (message.description !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.description);
        /* int32 price = 3; */
        if (message.price !== 0)
            writer.tag(3, WireType.Varint).int32(message.price);
        /* repeated string field_mask = 4; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 5; */
        if (message.pageNumber !== 0)
            writer.tag(5, WireType.Varint).int32(message.pageNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message StoredQuote
 */
export const StoredQuote = new StoredQuote$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StoredQuoteList$Type extends MessageType {
    constructor() {
        super("StoredQuoteList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => StoredQuote },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated StoredQuote results */ 1:
                    message.results.push(StoredQuote.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated StoredQuote results = 1; */
        for (let i = 0; i < message.results.length; i++)
            StoredQuote.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message StoredQuoteList
 */
export const StoredQuoteList = new StoredQuoteList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class QuoteDocument$Type extends MessageType {
    constructor() {
        super("QuoteDocument", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "document_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "property_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "reading_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "event_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "job_notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, documentId: 0, propertyId: 0, readingId: 0, eventId: 0, jobNotes: "", fieldMask: [], pageNumber: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 document_id */ 2:
                    message.documentId = reader.int32();
                    break;
                case /* int32 property_id */ 3:
                    message.propertyId = reader.int32();
                    break;
                case /* int32 reading_id */ 4:
                    message.readingId = reader.int32();
                    break;
                case /* int32 event_id */ 5:
                    message.eventId = reader.int32();
                    break;
                case /* string job_notes */ 6:
                    message.jobNotes = reader.string();
                    break;
                case /* repeated string field_mask */ 7:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 8:
                    message.pageNumber = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 document_id = 2; */
        if (message.documentId !== 0)
            writer.tag(2, WireType.Varint).int32(message.documentId);
        /* int32 property_id = 3; */
        if (message.propertyId !== 0)
            writer.tag(3, WireType.Varint).int32(message.propertyId);
        /* int32 reading_id = 4; */
        if (message.readingId !== 0)
            writer.tag(4, WireType.Varint).int32(message.readingId);
        /* int32 event_id = 5; */
        if (message.eventId !== 0)
            writer.tag(5, WireType.Varint).int32(message.eventId);
        /* string job_notes = 6; */
        if (message.jobNotes !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.jobNotes);
        /* repeated string field_mask = 7; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(7, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 8; */
        if (message.pageNumber !== 0)
            writer.tag(8, WireType.Varint).int32(message.pageNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message QuoteDocument
 */
export const QuoteDocument = new QuoteDocument$Type();
// @generated message type with reflection information, may provide speed optimized methods
class QuoteDocumentList$Type extends MessageType {
    constructor() {
        super("QuoteDocumentList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => QuoteDocument },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated QuoteDocument results */ 1:
                    message.results.push(QuoteDocument.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated QuoteDocument results = 1; */
        for (let i = 0; i < message.results.length; i++)
            QuoteDocument.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message QuoteDocumentList
 */
export const QuoteDocumentList = new QuoteDocumentList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class QuoteLinePart$Type extends MessageType {
    constructor() {
        super("QuoteLinePart", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "quote_line_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "quote_part_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "quantity", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 5, name: "markup", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 6, name: "tax", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 7, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, quoteLineId: 0, quotePartId: 0, quantity: 0, markup: 0, tax: 0, fieldMask: [], pageNumber: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 quote_line_id */ 2:
                    message.quoteLineId = reader.int32();
                    break;
                case /* int32 quote_part_id */ 3:
                    message.quotePartId = reader.int32();
                    break;
                case /* double quantity */ 4:
                    message.quantity = reader.double();
                    break;
                case /* double markup */ 5:
                    message.markup = reader.double();
                    break;
                case /* double tax */ 6:
                    message.tax = reader.double();
                    break;
                case /* repeated string field_mask */ 7:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 8:
                    message.pageNumber = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 quote_line_id = 2; */
        if (message.quoteLineId !== 0)
            writer.tag(2, WireType.Varint).int32(message.quoteLineId);
        /* int32 quote_part_id = 3; */
        if (message.quotePartId !== 0)
            writer.tag(3, WireType.Varint).int32(message.quotePartId);
        /* double quantity = 4; */
        if (message.quantity !== 0)
            writer.tag(4, WireType.Bit64).double(message.quantity);
        /* double markup = 5; */
        if (message.markup !== 0)
            writer.tag(5, WireType.Bit64).double(message.markup);
        /* double tax = 6; */
        if (message.tax !== 0)
            writer.tag(6, WireType.Bit64).double(message.tax);
        /* repeated string field_mask = 7; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(7, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 8; */
        if (message.pageNumber !== 0)
            writer.tag(8, WireType.Varint).int32(message.pageNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message QuoteLinePart
 */
export const QuoteLinePart = new QuoteLinePart$Type();
// @generated message type with reflection information, may provide speed optimized methods
class QuoteLinePartList$Type extends MessageType {
    constructor() {
        super("QuoteLinePartList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => QuoteLinePart },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated QuoteLinePart results */ 1:
                    message.results.push(QuoteLinePart.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated QuoteLinePart results = 1; */
        for (let i = 0; i < message.results.length; i++)
            QuoteLinePart.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message QuoteLinePartList
 */
export const QuoteLinePartList = new QuoteLinePartList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Quote$Type extends MessageType {
    constructor() {
        super("Quote", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "line_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "readings_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "price", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 5, name: "document_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, lineId: 0, readingsId: 0, price: 0, documentId: 0, fieldMask: [], pageNumber: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 line_id */ 2:
                    message.lineId = reader.int32();
                    break;
                case /* int32 readings_id */ 3:
                    message.readingsId = reader.int32();
                    break;
                case /* double price */ 4:
                    message.price = reader.double();
                    break;
                case /* int32 document_id */ 5:
                    message.documentId = reader.int32();
                    break;
                case /* repeated string field_mask */ 6:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 7:
                    message.pageNumber = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 line_id = 2; */
        if (message.lineId !== 0)
            writer.tag(2, WireType.Varint).int32(message.lineId);
        /* int32 readings_id = 3; */
        if (message.readingsId !== 0)
            writer.tag(3, WireType.Varint).int32(message.readingsId);
        /* double price = 4; */
        if (message.price !== 0)
            writer.tag(4, WireType.Bit64).double(message.price);
        /* int32 document_id = 5; */
        if (message.documentId !== 0)
            writer.tag(5, WireType.Varint).int32(message.documentId);
        /* repeated string field_mask = 6; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(6, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 7; */
        if (message.pageNumber !== 0)
            writer.tag(7, WireType.Varint).int32(message.pageNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Quote
 */
export const Quote = new Quote$Type();
// @generated message type with reflection information, may provide speed optimized methods
class QuoteList$Type extends MessageType {
    constructor() {
        super("QuoteList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Quote },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Quote results */ 1:
                    message.results.push(Quote.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated Quote results = 1; */
        for (let i = 0; i < message.results.length; i++)
            Quote.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message QuoteList
 */
export const QuoteList = new QuoteList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class QuoteUsed$Type extends MessageType {
    constructor() {
        super("QuoteUsed", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "services_rendered_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "quote_line_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "quantity", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 5, name: "quoted_price", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 6, name: "billable", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "lmpc", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, servicesRenderedId: 0, quoteLineId: 0, quantity: 0, quotedPrice: 0, billable: 0, lmpc: 0, fieldMask: [], pageNumber: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 services_rendered_id */ 2:
                    message.servicesRenderedId = reader.int32();
                    break;
                case /* int32 quote_line_id */ 3:
                    message.quoteLineId = reader.int32();
                    break;
                case /* double quantity */ 4:
                    message.quantity = reader.double();
                    break;
                case /* double quoted_price */ 5:
                    message.quotedPrice = reader.double();
                    break;
                case /* int32 billable */ 6:
                    message.billable = reader.int32();
                    break;
                case /* int32 lmpc */ 7:
                    message.lmpc = reader.int32();
                    break;
                case /* repeated string field_mask */ 8:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 9:
                    message.pageNumber = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 services_rendered_id = 2; */
        if (message.servicesRenderedId !== 0)
            writer.tag(2, WireType.Varint).int32(message.servicesRenderedId);
        /* int32 quote_line_id = 3; */
        if (message.quoteLineId !== 0)
            writer.tag(3, WireType.Varint).int32(message.quoteLineId);
        /* double quantity = 4; */
        if (message.quantity !== 0)
            writer.tag(4, WireType.Bit64).double(message.quantity);
        /* double quoted_price = 5; */
        if (message.quotedPrice !== 0)
            writer.tag(5, WireType.Bit64).double(message.quotedPrice);
        /* int32 billable = 6; */
        if (message.billable !== 0)
            writer.tag(6, WireType.Varint).int32(message.billable);
        /* int32 lmpc = 7; */
        if (message.lmpc !== 0)
            writer.tag(7, WireType.Varint).int32(message.lmpc);
        /* repeated string field_mask = 8; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(8, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 9; */
        if (message.pageNumber !== 0)
            writer.tag(9, WireType.Varint).int32(message.pageNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message QuoteUsed
 */
export const QuoteUsed = new QuoteUsed$Type();
// @generated message type with reflection information, may provide speed optimized methods
class QuoteUsedList$Type extends MessageType {
    constructor() {
        super("QuoteUsedList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => QuoteUsed },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated QuoteUsed results */ 1:
                    message.results.push(QuoteUsed.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated QuoteUsed results = 1; */
        for (let i = 0; i < message.results.length; i++)
            QuoteUsed.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message QuoteUsedList
 */
export const QuoteUsedList = new QuoteUsedList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class QuotePart$Type extends MessageType {
    constructor() {
        super("QuotePart", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "vendor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "cost", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 5, name: "availability", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, description: "", vendor: "", cost: 0, availability: 0, fieldMask: [], pageNumber: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string description */ 2:
                    message.description = reader.string();
                    break;
                case /* string vendor */ 3:
                    message.vendor = reader.string();
                    break;
                case /* double cost */ 4:
                    message.cost = reader.double();
                    break;
                case /* int32 availability */ 5:
                    message.availability = reader.int32();
                    break;
                case /* repeated string field_mask */ 6:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 7:
                    message.pageNumber = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string description = 2; */
        if (message.description !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.description);
        /* string vendor = 3; */
        if (message.vendor !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.vendor);
        /* double cost = 4; */
        if (message.cost !== 0)
            writer.tag(4, WireType.Bit64).double(message.cost);
        /* int32 availability = 5; */
        if (message.availability !== 0)
            writer.tag(5, WireType.Varint).int32(message.availability);
        /* repeated string field_mask = 6; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(6, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 7; */
        if (message.pageNumber !== 0)
            writer.tag(7, WireType.Varint).int32(message.pageNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message QuotePart
 */
export const QuotePart = new QuotePart$Type();
// @generated message type with reflection information, may provide speed optimized methods
class QuotePartList$Type extends MessageType {
    constructor() {
        super("QuotePartList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => QuotePart },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated QuotePart results */ 1:
                    message.results.push(QuotePart.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated QuotePart results = 1; */
        for (let i = 0; i < message.results.length; i++)
            QuotePart.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message QuotePartList
 */
export const QuotePartList = new QuotePartList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class QuoteLine$Type extends MessageType {
    constructor() {
        super("QuoteLine", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "quote_type", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "labor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "refrigerant_lbs", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "adjustment", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "is_flatrate", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "diagnosis", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "warranty", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "quote_reference", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "job_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "quote_status", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 13, name: "for_user", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 14, name: "item_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 15, name: "photo_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 16, name: "is_active", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 17, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 18, name: "without_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 19, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 20, name: "departments", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 21, name: "include_proposal_photos", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 22, name: "photos", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => QuotePhoto }
        ]);
    }
    create(value) {
        const message = { id: 0, quoteType: 0, labor: "", refrigerantLbs: "", adjustment: "", isFlatrate: "", description: "", diagnosis: "", warranty: 0, quoteReference: "", jobNumber: "", quoteStatus: 0, forUser: 0, itemId: 0, photoId: 0, isActive: 0, fieldMask: [], withoutLimit: false, pageNumber: 0, departments: "", includeProposalPhotos: false, photos: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 quote_type */ 2:
                    message.quoteType = reader.int32();
                    break;
                case /* string labor */ 3:
                    message.labor = reader.string();
                    break;
                case /* string refrigerant_lbs */ 4:
                    message.refrigerantLbs = reader.string();
                    break;
                case /* string adjustment */ 5:
                    message.adjustment = reader.string();
                    break;
                case /* string is_flatrate */ 6:
                    message.isFlatrate = reader.string();
                    break;
                case /* string description */ 7:
                    message.description = reader.string();
                    break;
                case /* string diagnosis */ 8:
                    message.diagnosis = reader.string();
                    break;
                case /* int32 warranty */ 9:
                    message.warranty = reader.int32();
                    break;
                case /* string quote_reference */ 10:
                    message.quoteReference = reader.string();
                    break;
                case /* string job_number */ 11:
                    message.jobNumber = reader.string();
                    break;
                case /* int32 quote_status */ 12:
                    message.quoteStatus = reader.int32();
                    break;
                case /* int32 for_user */ 13:
                    message.forUser = reader.int32();
                    break;
                case /* int32 item_id */ 14:
                    message.itemId = reader.int32();
                    break;
                case /* int32 photo_id */ 15:
                    message.photoId = reader.int32();
                    break;
                case /* int32 is_active */ 16:
                    message.isActive = reader.int32();
                    break;
                case /* repeated string field_mask */ 17:
                    message.fieldMask.push(reader.string());
                    break;
                case /* bool without_limit */ 18:
                    message.withoutLimit = reader.bool();
                    break;
                case /* int32 page_number */ 19:
                    message.pageNumber = reader.int32();
                    break;
                case /* string departments */ 20:
                    message.departments = reader.string();
                    break;
                case /* bool include_proposal_photos */ 21:
                    message.includeProposalPhotos = reader.bool();
                    break;
                case /* repeated QuotePhoto photos */ 22:
                    message.photos.push(QuotePhoto.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 quote_type = 2; */
        if (message.quoteType !== 0)
            writer.tag(2, WireType.Varint).int32(message.quoteType);
        /* string labor = 3; */
        if (message.labor !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.labor);
        /* string refrigerant_lbs = 4; */
        if (message.refrigerantLbs !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.refrigerantLbs);
        /* string adjustment = 5; */
        if (message.adjustment !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.adjustment);
        /* string is_flatrate = 6; */
        if (message.isFlatrate !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.isFlatrate);
        /* string description = 7; */
        if (message.description !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.description);
        /* string diagnosis = 8; */
        if (message.diagnosis !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.diagnosis);
        /* int32 warranty = 9; */
        if (message.warranty !== 0)
            writer.tag(9, WireType.Varint).int32(message.warranty);
        /* string quote_reference = 10; */
        if (message.quoteReference !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.quoteReference);
        /* string job_number = 11; */
        if (message.jobNumber !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.jobNumber);
        /* int32 quote_status = 12; */
        if (message.quoteStatus !== 0)
            writer.tag(12, WireType.Varint).int32(message.quoteStatus);
        /* int32 for_user = 13; */
        if (message.forUser !== 0)
            writer.tag(13, WireType.Varint).int32(message.forUser);
        /* int32 item_id = 14; */
        if (message.itemId !== 0)
            writer.tag(14, WireType.Varint).int32(message.itemId);
        /* int32 photo_id = 15; */
        if (message.photoId !== 0)
            writer.tag(15, WireType.Varint).int32(message.photoId);
        /* int32 is_active = 16; */
        if (message.isActive !== 0)
            writer.tag(16, WireType.Varint).int32(message.isActive);
        /* repeated string field_mask = 17; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(17, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* bool without_limit = 18; */
        if (message.withoutLimit !== false)
            writer.tag(18, WireType.Varint).bool(message.withoutLimit);
        /* int32 page_number = 19; */
        if (message.pageNumber !== 0)
            writer.tag(19, WireType.Varint).int32(message.pageNumber);
        /* string departments = 20; */
        if (message.departments !== "")
            writer.tag(20, WireType.LengthDelimited).string(message.departments);
        /* bool include_proposal_photos = 21; */
        if (message.includeProposalPhotos !== false)
            writer.tag(21, WireType.Varint).bool(message.includeProposalPhotos);
        /* repeated QuotePhoto photos = 22; */
        for (let i = 0; i < message.photos.length; i++)
            QuotePhoto.internalBinaryWrite(message.photos[i], writer.tag(22, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message QuoteLine
 */
export const QuoteLine = new QuoteLine$Type();
// @generated message type with reflection information, may provide speed optimized methods
class QuoteLineList$Type extends MessageType {
    constructor() {
        super("QuoteLineList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => QuoteLine },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated QuoteLine results */ 1:
                    message.results.push(QuoteLine.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated QuoteLine results = 1; */
        for (let i = 0; i < message.results.length; i++)
            QuoteLine.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message QuoteLineList
 */
export const QuoteLineList = new QuoteLineList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class QuotePhoto$Type extends MessageType {
    constructor() {
        super("QuotePhoto", [
            { no: 1, name: "photo_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "file_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "quote_line_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "quote_part_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "is_active", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "without_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "file_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "file_bucket", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "download_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { photoId: 0, fileId: 0, quoteLineId: 0, quotePartId: 0, isActive: 0, fieldMask: [], pageNumber: 0, withoutLimit: false, fileName: "", fileBucket: "", downloadUrl: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 photo_id */ 1:
                    message.photoId = reader.int32();
                    break;
                case /* int32 file_id */ 2:
                    message.fileId = reader.int32();
                    break;
                case /* int32 quote_line_id */ 3:
                    message.quoteLineId = reader.int32();
                    break;
                case /* int32 quote_part_id */ 4:
                    message.quotePartId = reader.int32();
                    break;
                case /* int32 is_active */ 5:
                    message.isActive = reader.int32();
                    break;
                case /* repeated string field_mask */ 6:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 7:
                    message.pageNumber = reader.int32();
                    break;
                case /* bool without_limit */ 8:
                    message.withoutLimit = reader.bool();
                    break;
                case /* string file_name */ 9:
                    message.fileName = reader.string();
                    break;
                case /* string file_bucket */ 10:
                    message.fileBucket = reader.string();
                    break;
                case /* string download_url */ 11:
                    message.downloadUrl = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 photo_id = 1; */
        if (message.photoId !== 0)
            writer.tag(1, WireType.Varint).int32(message.photoId);
        /* int32 file_id = 2; */
        if (message.fileId !== 0)
            writer.tag(2, WireType.Varint).int32(message.fileId);
        /* int32 quote_line_id = 3; */
        if (message.quoteLineId !== 0)
            writer.tag(3, WireType.Varint).int32(message.quoteLineId);
        /* int32 quote_part_id = 4; */
        if (message.quotePartId !== 0)
            writer.tag(4, WireType.Varint).int32(message.quotePartId);
        /* int32 is_active = 5; */
        if (message.isActive !== 0)
            writer.tag(5, WireType.Varint).int32(message.isActive);
        /* repeated string field_mask = 6; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(6, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 7; */
        if (message.pageNumber !== 0)
            writer.tag(7, WireType.Varint).int32(message.pageNumber);
        /* bool without_limit = 8; */
        if (message.withoutLimit !== false)
            writer.tag(8, WireType.Varint).bool(message.withoutLimit);
        /* string file_name = 9; */
        if (message.fileName !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.fileName);
        /* string file_bucket = 10; */
        if (message.fileBucket !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.fileBucket);
        /* string download_url = 11; */
        if (message.downloadUrl !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.downloadUrl);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message QuotePhoto
 */
export const QuotePhoto = new QuotePhoto$Type();
// @generated message type with reflection information, may provide speed optimized methods
class QuotePhotoList$Type extends MessageType {
    constructor() {
        super("QuotePhotoList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => QuotePhoto },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated QuotePhoto results */ 1:
                    message.results.push(QuotePhoto.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated QuotePhoto results = 1; */
        for (let i = 0; i < message.results.length; i++)
            QuotePhoto.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message QuotePhotoList
 */
export const QuotePhotoList = new QuotePhotoList$Type();
/**
 * @generated ServiceType for protobuf service QuoteService
 */
export const QuoteService = new ServiceType("QuoteService", [
    { name: "Create", options: {}, I: Quote, O: Quote },
    { name: "Get", options: {}, I: Quote, O: Quote },
    { name: "BatchGet", options: {}, I: Quote, O: QuoteList },
    { name: "Update", options: {}, I: Quote, O: Quote },
    { name: "Delete", options: {}, I: Quote, O: Quote },
    { name: "CreateQuoteUsed", options: {}, I: QuoteUsed, O: QuoteUsed },
    { name: "GetQuoteUsed", options: {}, I: QuoteUsed, O: QuoteUsed },
    { name: "BatchGetQuoteUsed", options: {}, I: QuoteUsed, O: QuoteUsedList },
    { name: "UpdateQuoteUsed", options: {}, I: QuoteUsed, O: QuoteUsed },
    { name: "DeleteQuoteUsed", options: {}, I: QuoteUsed, O: QuoteUsed },
    { name: "CreateQuotePart", options: {}, I: QuotePart, O: QuotePart },
    { name: "GetQuotePart", options: {}, I: QuotePart, O: QuotePart },
    { name: "BatchGetQuotePart", options: {}, I: QuotePart, O: QuotePartList },
    { name: "UpdateQuotePart", options: {}, I: QuotePart, O: QuotePart },
    { name: "DeleteQuotePart", options: {}, I: QuotePart, O: QuotePart },
    { name: "CreateQuoteLine", options: {}, I: QuoteLine, O: QuoteLine },
    { name: "GetQuoteLine", options: {}, I: QuoteLine, O: QuoteLine },
    { name: "BatchGetQuoteLine", options: {}, I: QuoteLine, O: QuoteLineList },
    { name: "UpdateQuoteLine", options: {}, I: QuoteLine, O: QuoteLine },
    { name: "DeleteQuoteLine", options: {}, I: QuoteLine, O: QuoteLine },
    { name: "CreateQuoteLinePart", options: {}, I: QuoteLinePart, O: QuoteLinePart },
    { name: "GetQuoteLinePart", options: {}, I: QuoteLinePart, O: QuoteLinePart },
    { name: "BatchGetQuoteLinePart", options: {}, I: QuoteLinePart, O: QuoteLinePartList },
    { name: "UpdateQuoteLinePart", options: {}, I: QuoteLinePart, O: QuoteLinePart },
    { name: "DeleteQuoteLinePart", options: {}, I: QuoteLinePart, O: QuoteLinePart },
    { name: "CreateQuoteDocument", options: {}, I: QuoteDocument, O: QuoteDocument },
    { name: "GetQuoteDocument", options: {}, I: QuoteDocument, O: QuoteDocument },
    { name: "BatchGetQuoteDocument", options: {}, I: QuoteDocument, O: QuoteDocumentList },
    { name: "UpdateQuoteDocument", options: {}, I: QuoteDocument, O: QuoteDocument },
    { name: "DeleteQuoteDocument", options: {}, I: QuoteDocument, O: QuoteDocument },
    { name: "CreateStoredQuote", options: {}, I: StoredQuote, O: StoredQuote },
    { name: "GetStoredQuote", options: {}, I: StoredQuote, O: StoredQuote },
    { name: "BatchGetStoredQuote", options: {}, I: StoredQuote, O: StoredQuoteList },
    { name: "UpdateStoredQuote", options: {}, I: StoredQuote, O: StoredQuote },
    { name: "DeleteStoredQuote", options: {}, I: StoredQuote, O: StoredQuote },
    { name: "GetQuotePhoto", options: {}, I: QuotePhoto, O: QuotePhoto },
    { name: "CreateQuotePhoto", options: {}, I: QuotePhoto, O: QuotePhoto },
    { name: "BatchGetQuotePhoto", options: {}, I: QuotePhoto, O: QuotePhotoList },
    { name: "UpdateQuotePhoto", options: {}, I: QuotePhoto, O: QuotePhoto },
    { name: "DeleteQuotePhoto", options: {}, I: QuotePhoto, O: QuotePhoto }
]);
