import { BaseClient } from '../BaseClient';
import { getMimeType } from '../Common';
import { ServiceItem, ServiceItemImage,ServiceItemImageList,ServiceItemList } from '../compiled-protos/service_item';
import { ServiceItemServiceClient } from '../compiled-protos/service_item.client';
import { File as InternalFile,FileClient,   } from '../File';
import { S3Client, URLObject } from '../S3File';



class ServiceItemClient extends BaseClient {
  self: ServiceItemServiceClient;
  s3Client: S3Client;
  fileClient: FileClient
  readingImagesS3Bucket = 'kalos-service-item-images'

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new ServiceItemServiceClient(this.transport);
    this.s3Client = new S3Client(host, userID);
    this.fileClient = new FileClient(host, userID);
  }

  
  public async Create(req: ServiceItem) {
    const res = await this.self.create(req, this.getMetaData()).response;
    return res;
  }

  public async Get(req: ServiceItem) {
    const res = await this.self.get(req, this.getMetaData()).response;
    return res;
  }

  public async Update(req: ServiceItem) {
    const res = await this.self.update(req, this.getMetaData()).response;
    return res
  }

  public async Delete(req: ServiceItem) {
    const res = await this.self.delete(req, this.getMetaData()).response;
    return res;
  }

  public async BatchGet(req: ServiceItem) {
    const res = await this.self.batchGet(req, this.getMetaData()).response;
    return res;
  }

  public async CreateServiceItemImage({ req, file }: { req: Omit<ServiceItemImage, 'fileId' | 'fileBucket' | 'fileName'>, file: File }) {
    const fileKey = `${req.serviceItemId}-${Date.now()}-${file.name}`;
    const contentType = getMimeType(file.name);
    const urlObj = URLObject.create({
      bucket: this.readingImagesS3Bucket,
      key: fileKey,
      contentType,
    });
    const urlRes = await this.s3Client.GetUploadURL(urlObj);
    console.log({ urlRes })

    if (!urlRes) {
      throw new Error('Failed to get upload URL');
    }
    const uploadRes = await fetch(urlRes.url, {
      body: file,
      method: 'PUT',
    });
    console.log({ uploadRes })


    if (uploadRes.status !== 200) {
      throw new Error('Failed to upload file');
    }

    const fileObj = InternalFile.create({
      bucket: this.readingImagesS3Bucket,
      name: fileKey,
      mimeType: contentType,
    });
    const fileRes = await this.fileClient.Create(fileObj);
    console.log({ fileRes })

    return await this.self.createServiceItemImage(ServiceItemImage.create({ ...req, fileId: fileRes.id, imageName: fileRes.name }), this.getMetaData()).response;
  }

  public async CreateImage(req: ServiceItemImage) {
    const res = await this.self.createServiceItemImage(req, this.getMetaData()).response;
    return res;
  
  }
  public async GetImage(req: ServiceItemImage) {
    const res = await this.self.getServiceItemImage(req, this.getMetaData()).response;
    return res;
  }
  public async UpdateImage(req: ServiceItemImage) {
    const res = await this.self.updateServiceItemImage(req, this.getMetaData()).response;
    return res
  }
  public async DeleteImage(req: ServiceItemImage) {
    const res = await this.self.deleteServiceItemImage(req, this.getMetaData()).response;
    return res;
  }

  

  public async BatchGetImage(req: ServiceItemImage): Promise<ServiceItemImageList> {
    let res = ServiceItemImageList.create({ results: [] });
  
    try {
      res = await this.self.batchGetServiceItemImage(req, this.getMetaData()).response;
    } catch (err) {
      console.log('Error fetching service item images:', err);
      return res;
    }
  
    return res;
  }
  
  

  
  
  }








  

export { ServiceItem, ServiceItemList, ServiceItemClient,ServiceItemImage,ServiceItemImageList };
