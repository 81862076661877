import { getPropertyAddress, type Property, type User } from '@kalos/kalos-rpc';
import { type FC, type MouseEvent as ReactMouseEvent, useCallback } from 'react';

import { UserClientService } from '../../../tools/helpers';
import { type Columns, type Data, InfoTable } from '../../ComponentsLibrary/InfoTable';
import { Link } from '../../ComponentsLibrary/Link';

export interface Props {
  customer: User;
  onAddServiceCall: (property: Property) => void;
  onCustomerClick: (customer: User) => void;
  onAddProperty: (customer: User) => void;
}

export const CustomerItem: FC<Props> = ({
  customer,
  onAddServiceCall,
  onCustomerClick,
  onAddProperty,
}) => {
  const propertiesList = customer.properties;
  const handleCustomerClick = useCallback(
    (customer: User) => (event: ReactMouseEvent<HTMLAnchorElement, MouseEvent>) => {
      event.preventDefault();
      onCustomerClick(customer);
    },
    [onCustomerClick],
  );
  const handlePropertyClick = useCallback(
    (property: Property) => (event: ReactMouseEvent<HTMLAnchorElement, MouseEvent>) => {
      event.preventDefault();
      onAddServiceCall(property);
    },
    [onAddServiceCall],
  );
  const handleAddProperty = useCallback(
    (customer: User) => () => onAddProperty(customer),
    [onAddProperty],
  );
  const customerNameAndBusinessName = UserClientService.getCustomerNameAndBusinessName(customer);
  const columns: Columns = [
    {
      name: (
        <Link onClick={handleCustomerClick(customer)}>
          <big>
            <strong>{customerNameAndBusinessName}</strong>
            {customerNameAndBusinessName === '' && <i>no name</i>}
          </big>
        </Link>
      ),
      actions: [
        {
          label: 'Add Property',
          size: 'xsmall',
          variant: 'text',
          compact: true,
          onClick: handleAddProperty(customer),
        },
      ],
    },
  ];
  const data: Data = propertiesList.map((property) => [
    {
      value: <Link onClick={handlePropertyClick(property)}>{getPropertyAddress(property)}</Link>,
    },
  ]) as Data;
  return (
    <div>
      <InfoTable columns={columns} data={data} />
    </div>
  );
};
