import { BaseClient } from '../BaseClient';
import { IDInt32 } from '../compiled-protos/common';
import { FileTranscript } from '../compiled-protos/s3';
import { S3ServiceClient as TranscriptServiceClient } from '../compiled-protos/s3.client';

class FileTranscriptClient extends BaseClient {
  self: TranscriptServiceClient;

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new TranscriptServiceClient(this.transport);
  }

  public async Create(req: FileTranscript) {
    const res = await this.self.createFileTranscript(req, this.getMetaData()).response;
    return res;
  }

  public async Get(req: FileTranscript) {
    let res = FileTranscript.create();
    try {
      res = await this.self.getFileTranscript(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Update(req: FileTranscript) {
    try {
      await this.self.updateFileTranscript(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
  }

  public async Delete(req: FileTranscript) {
    try {
      await this.self.deleteFileTranscript(IDInt32.create({id: req.fileTranscriptId}), this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
  }

}

export { FileTranscript, FileTranscriptClient };
