import { BaseClient } from '../BaseClient';
import { Material, MaterialList } from '../compiled-protos/service_item';
import { ServiceItemServiceClient as MaterialServiceClient } from '../compiled-protos/service_item.client';

class MaterialClient extends BaseClient {
  self: MaterialServiceClient;

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new MaterialServiceClient(this.transport);
  }

  public async Create(req: Material) {
    let res = Material.create();
    try {
      res = await this.self.createBaseMaterial(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Get(req: Material) {
    let res = Material.create();
    try {
      res = await this.self.getBaseMaterial(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Update(req: Material) {
    let res = Material.create();
    try {
      res = await this.self.updateBaseMaterial(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Delete(req: Material) {
    let res = Material.create();
    try {
      res = await this.self.deleteBaseMaterial(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async BatchGet(req: Material) {
    let res = MaterialList.create();
    try {
      res = await this.self.batchGetBaseMaterial(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }
}

export { Material, MaterialList, MaterialClient };
