import { Transaction, TransactionBatchCreateRequest, TransactionList } from '@kalos/kalos-rpc';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { TransactionClientService } from '../../tools/helpers';
import { queryKeys } from './constants';
import { type EntityFilter } from './utils';

export type TransactionQueryFilters = EntityFilter<Transaction>;

export const useTransactionBatchGetQuery = <TSelectData = TransactionList,>({
  filters,
  enabled = true,
  select,
}: {
  select?: (data: TransactionList) => TSelectData;
  filters: TransactionQueryFilters;
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: [
      queryKeys.transaction.root,
      queryKeys.transaction.list,
      queryKeys.transaction.getTransactionHash(filters),
    ],
    queryFn: async () => {
      const req = Transaction.create(filters);
      if (req.searchPhrase && req.searchPhrase !== '') {
        return await TransactionClientService.Search(req);
      } else {
        return await TransactionClientService.BatchGet(req);
      }
    },
    enabled,
    select,
  });
};

export const useTransactionGetQuery = <TSelectData = Transaction,>({
  filters,
  enabled = true,
  select,
}: {
  select?: (data: Transaction) => TSelectData;
  filters: TransactionQueryFilters;
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: [queryKeys.transaction.root, queryKeys.transaction.getTransactionHash(filters)],
    queryFn: async () => {
      const req = Transaction.create(filters);
      return await TransactionClientService.Get(req);
    },
    enabled,
    select,
  });
};

export const useTransactionUpdateMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (filters: Transaction) => {
      return await TransactionClientService.Update(filters);
    },
    onSuccess: (updatedTransaction) => {
      queryClient.setQueriesData<TransactionList | Transaction>(
        { queryKey: [queryKeys.transaction.root] },
        (cache) => {
          if (cache) {
            if (
              'results' in cache &&
              cache.results.find((cachedTxn) => cachedTxn.id === updatedTransaction.id)
            ) {
              console.log('Updating transaction in cache', updatedTransaction);
              const updatedResults = cache.results.map((txn) =>
                txn.id === updatedTransaction.id
                  ? Transaction.create({
                      ...updatedTransaction,
                      transactionLineItems: txn.transactionLineItems,
                    })
                  : txn,
              );

              return TransactionList.create({
                results: updatedResults,
                totalCount: cache.totalCount,
              });
            }

            // individual queries
            if ('id' in cache && cache.id === updatedTransaction.id) {
              return Transaction.create({
                ...updatedTransaction,
                transactionLineItems: cache.transactionLineItems,
              });
            }
            return cache;
          }
        },
      );
    },
  });
};

export const useTransactionCreateMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (filters: Transaction) => {
      return await TransactionClientService.Create(filters);
    },
    onSuccess(data) {
      queryClient.setQueriesData<TransactionList>(
        { queryKey: [queryKeys.transaction.root, queryKeys.transaction.list] },
        (cache) => {
          if (cache) {
            if (!cache.results.find((txn) => txn.id === data.id)) {
              return TransactionList.create({
                results: [data, ...cache.results],
                totalCount: cache.totalCount + 1,
              });
            }
            return cache;
          }
        },
      );
      queryClient.invalidateQueries({
        queryKey: [queryKeys.transaction.root],
      });
    },
  });
};

export const useTransactionDeleteMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (filters: Transaction) => {
      return await TransactionClientService.Delete(filters);
    },
    onSuccess(_, deleted) {
      queryClient.setQueriesData<TransactionList>(
        { queryKey: [queryKeys.transaction.root, queryKeys.transaction.list] },
        (cache) => {
          if (cache) {
            if (cache.results.find((txn) => txn.id === deleted.id)) {
              return TransactionList.create({
                results: cache.results.filter((txn) => txn.id !== deleted.id),
                totalCount: cache.totalCount - 1,
              });
            }
            return cache;
          }
        },
      );
      queryClient.invalidateQueries({
        queryKey: [queryKeys.transaction.root],
      });
    },
  });
};

export const useTransactionClientServiceGetQuery = ({
  enabled = true,
  filters,
}: {
  enabled?: boolean;
  filters: TransactionQueryFilters;
}) => {
  return useQuery({
    queryKey: [queryKeys.transactionGet.root, queryKeys.transactionGet.getFiltersHash(filters)],
    queryFn: async () => {
      const req = Transaction.create(filters);
      return await TransactionClientService.Get(req);
    },
    enabled,
  });
};

export const useTransactionImmediateGetQuery = () => {
  const queryClient = useQueryClient();
  const fetchData = useCallback(
    async (filters: TransactionQueryFilters) => {
      const cache = queryClient.getQueryData<Transaction>([
        queryKeys.transactionGet.root,
        queryKeys.transactionGet.getFiltersHash(filters),
      ]);
      if (cache) {
        return cache;
      }
      const req = Transaction.create(filters);
      const data = await TransactionClientService.Get(req);
      queryClient.setQueryData(
        [queryKeys.transactionGet.root, queryKeys.transactionGet.getFiltersHash(filters)],
        data,
      );
      return data;
    },
    [queryClient],
  );
  return fetchData;
};

export const useTransactionBatchCreateMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (transactions: Transaction[]) => {
      return await TransactionClientService.BatchCreate(
        TransactionBatchCreateRequest.create({ transactions }),
      );
    },
    async onSuccess(data, variables, context) {
      await queryClient.invalidateQueries({ queryKey: [queryKeys.transaction.root] });
    },
  });
};
