import { BaseClient } from '../BaseClient';
import { timestamp } from '../Common';
import { StringList } from '../compiled-protos/common';
import {
  ProjectTask,
  ProjectTaskList,
  Spiff,
  SpiffDuplicate,
  SpiffList,
  SpiffOption,
  SpiffOptionList,
  SpiffType,
  SpiffTypeList,
  Task,
  TaskEventData,
  TaskList,
  TaskPriority,
  TaskPriorityList,
  TaskStatus,
  TaskStatusList,
  ToolFund,
} from '../compiled-protos/task';
import { TaskServiceClient } from '../compiled-protos/task.client';
import { User } from '../compiled-protos/user';
import { NULL_TIME } from '../constants';
class TaskClient extends BaseClient {
  self: TaskServiceClient

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new TaskServiceClient(this.transport)
  }

  public async Create(req: Task) {
    let res = Task.create()
    try {
      res = await this.self.create(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }

  public async CreateProjectTask(req: ProjectTask) {
    const res = ProjectTask.create()
    try {
      req = await this.self.createProjectTask(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return req
  }

  public async Get(req: Task) {
    let res = Task.create()
    try {
      res = await this.self.get(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }

  public async GetProjectTask(req: ProjectTask) {
    let res = ProjectTask.create()
    try {
      res = await this.self.getProjectTask(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }

  public async Update(req: Task) {
    let res = Task.create()
    try {
      res = await this.self.update(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }

  public async UpdateProjectTask(req: ProjectTask) {
    let res = ProjectTask.create()
    try {
      res = await this.self.updateProjectTask(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }

  public async Delete(req: Task) {
    let res = Task.create()
    try {
      res = await this.self.delete(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }

  public async DeleteProjectTask(req: ProjectTask) {
    let res = ProjectTask.create()
    try {
      res = await this.self.deleteProjectTask(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }

  public async BatchGet(req: Task) {
    let res = TaskList.create()

    try {
      res = await this.self.batchGet(req, this.getMetaDataWithoutCache()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }

  public async BatchGetProjectTasks(req: ProjectTask) {
    let res = ProjectTaskList.create()
    try {
      res = await this.self.batchGetProjectTasks(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }

  public async GetToolFundBalanceByID(userID: number) {
    const req = ToolFund.create();
    req.id = userID;
    const res = await this.self.getToolFundBalanceByID(req, this.getMetaData()).response
    return res
  }

  public async GetTaskStatusList(req: TaskStatus) {
    let res = TaskStatusList.create()
    try {
      res = await this.self.getTaskStatusList(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }

  public async GetTaskPriorityList(req: TaskPriority) {
    let res = TaskPriorityList.create()
    try {
      res = await this.self.getTaskPriorityList(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }

  public async BatchGetSpiffTypes(req: SpiffType) {
    let res = SpiffTypeList.create()
    try {
      res = await this.self.getSpiffTypes(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }

  public async BatchGetSpiffOption(req: SpiffOption) {
    const res = await this.self.batchGetSpiffOption(req, this.getMetaData()).response
    return res
  }

  public async CreateSpiffOption(req: SpiffOption) {
    return await this.self.createSpiffOption(req, this.getMetaData()).response
  }
  public async UpdateSpiffOption(req: SpiffOption) {
    return await this.self.updateSpiffOption(req, this.getMetaData()).response
  }
  public async DeleteSpiffOption(req: SpiffOption) {
    return await this.self.deleteSpiffOption(req, this.getMetaData()).response
  }

  // Called GetTaskBillableTypeList in the server side of things
  public async LoadTaskBillableTypeList() {
    let req = StringList.create()
    try {
      req = await this.self.getTaskBillableTypeList(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return req
  }

  // public async loadTaskBillableTypeList() {
  //   return new Promise<string[]>((resolve, reject) => {
  //     const opts: UnaryRpcOptions<Empty, StringList> = {
  //       request: new Empty(),
  //       host: this.host,
  //       metadata: this.getMetaData(),
  //       onEnd: output => {
  //         if (output.message) {
  //           resolve(output.message.toArray());
  //         } else {
  //           reject(output.statusMessage);
  //         }
  //       },
  //     };
  //     grpc.unary(TaskService.GetTaskBillableTypeList, opts);
  //   });
  // }

  public async GetAppliedSpiffs(userID: number) {
    const req = Spiff.create();
    req.externalId = userID;
    let res = SpiffList.create()
    try {
      res = await this.self.getAppliedSpiffs(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }

  public async newToolPurchase(
    amount: number,
    userID: number,
    ref: string,
    description: string,
    timestamp: string
  ) {
    const req = Task.create();
    const timeStr = `${new Date().valueOf()}`;
    req.externalCode = 'user';
    req.billableType = 'Tool Purchase';
    req.toolpurchaseCost = amount;
    req.externalId = userID;
    req.creatorUserId = userID;
    req.referenceNumber = ref;
    req.briefDescription = description;
    req.priorityId = 2;
    req.spiffJobNumber = '';
    req.datePerformed = timestamp;
    req.spiffToolId = `SPF-${userID}-${timeStr.substr(timeStr.length - 4)}`;
    return await this.Create(req);
  }

  public async loadTaskStatuses() {
    const req = TaskStatus.create();
    return await this.GetTaskStatusList(req);
  }

  public async loadTaskPriorityList() {
    const req = TaskPriority.create();
    return await this.GetTaskPriorityList(req);
  }
  public async loadSpiffTypes() {
    const req = SpiffType.create();
    req.isActive = true;
    const res = await this.BatchGetSpiffTypes(req);
    return res
      .results.filter(item => !!item.ext).sort((a, b) => {
        if (a.ext < b.ext) return -1;
        if (a.ext > b.ext) return 1
        return 0

      })
  }

  public deleteProjectTaskById = async (id: number) => {
    const req = ProjectTask.create();
    req.id = id;
    await this.DeleteProjectTask(req);
  };

  public loadProjectTaskPriorities = async () => {
    const res = await this.loadTaskPriorityList();
    return res.results
  };

  public loadProjectTaskStatuses = async () => {
    const res = await this.loadTaskStatuses();
    return res.results
  };

  public loadSpiffToolLogs = async ({
    page,
    type,
    technician,
    description,
    datePerformed,
    beginDate,
    endDate,
    jobNumber,
  }: {
    page: number;
    type: 'Spiff' | 'Tool';
    technician?: number;
    description?: string;
    datePerformed?: string;
    beginDate?: string;
    endDate?: string;
    jobNumber?: string;
  }) => {
    const req = Task.create();
    req.pageNumber = page;
    req.isActive = true;
    req.orderBy = type === 'Spiff' ? 'date_performed' : 'time_due';
    req.orderDir = 'ASC';
    if (technician) {
      req.externalId = technician;
    }
    req.billableType = type === 'Spiff' ? 'Spiff' : 'Tool Purchase';
    if (description) {
      req.briefDescription = `%${description}%`;
    }
    if (datePerformed) {
      req.datePerformed = datePerformed;
    }
    if (beginDate && endDate) {
      req.dateRange = ['>=', beginDate, '<', endDate];
      req.dateTarget = ['date_performed', 'date_performed'];
    }
    if (jobNumber) {
      req.spiffJobNumber = `%${jobNumber}%`;
    }
    const res = await this.BatchGet(req);
    console.log('we got results', res)
    const resultsList = res.results
    const count = res.totalCount
    return { resultsList, count };
  };

  public deleteSpiffTool = async (id: number) => {
    const req = Task.create();
    req.id = id;
    await this.Delete(req);
  };

  public GetPendingTasks = (billableType: string) => {
    return async (config: GetPendingSpiffConfig) => {
      const req = Task.create();
      req.orderBy = billableType === 'Spiff' ? 'date_performed' : 'time_due';
      req.billableType = billableType;
      if (billableType === 'Spiff') {
        req.datePerformed = NULL_TIME;
        req.notEquals = ['DatePerformed'];
      }
      if (billableType === 'Tool Purchase') {
        req.timeDue = NULL_TIME;
        req.notEquals = ['TimeDue'];
      }
      req.isActive = true;
      req.groupBy = 'external_id';
      req.pageNumber = config.page || 0;
      if (config.role === 'Manager') {
        req.fieldMask = ['AdminActionId'];
        if (billableType === 'Spiff') {
          req.notEquals = ['DatePerformed'];
        }
        if (billableType === 'Tool Purchase') {
          req.notEquals = ['TimeDue'];
        }
      }
      if (config.role === 'Payroll') {
        if (billableType === 'Spiff') {
          req.adminActionId = 0;
          req.notEquals = ['AdminActionId', 'DatePerformed'];
        }
        if (billableType === 'Tool Purchase') {
          req.adminActionId = 0;
          req.notEquals = ['AdminActionId', 'TimeDue'];
        }
      }
      if (config.role === 'Auditor') {
        console.log('Spiff Tool auditor');
        if (billableType === 'Spiff') {
          req.notEquals = [
            'AdminActionId',
            'DatePerformed',
            'NeedsAuditing',
          ];
        }
        if (billableType === 'Tool Purchase') {
          req.notEquals = ['AdminActionId', 'TimeDue', 'NeedsAuditing'];
        }
      }
      if (config.processed) {
        req.payrollProcessed = config.processed;
      }
      if (config.technicianUserID) {
        req.externalId = config.technicianUserID;
      }
      if (config.startDate && config.endDate) {
        req.dateRange = ['>=', config.startDate, '<=', config.endDate];
      }
      if (config.option) {
        const spiffType = SpiffType.create();
        spiffType.payout = config.option
        req.spiffType = spiffType;
      }
      if (config.departmentId) {
        const u = User.create();
        u.employeeDepartmentID = config.departmentId;
        req.searchUser = u;
      }
      console.log(req);
      return await this.self.batchGet(req);
    };
  };

  public upsertTask = async (data: Task) => {
    const id = data.id;
    if (id !== 0 && data.fieldMask.length === 0) {
      throw new Error(
        'Attempting to update entity without providing a field mask will result in a no op'
      );
    }
    const res = await this[id != 0 ? 'Update' : 'Create'](data);
    return res.id;
  };

  public loadPendingSpiffs = this.GetPendingTasks('Spiff');
  public loadPendingToolLogs = this.GetPendingTasks('Tool Purchase');

  /**
   *
   * @param pt a project task. FieldMaskList MUST be configured if the ID is not 0
   */
  public upsertEventTask = async (pt: ProjectTask) => {
    const req = ProjectTask.create();
    req.timeCreated = timestamp();
    if (pt.eventId) {
      req.eventId = pt.eventId;
    }
    const id = pt.id;
    if (id) {
      req.id = id;
    }
    const externalId = pt.externalId
    if (externalId) {
      req.externalId = externalId;
      req.externalCode = 'user';
    } else {
      req.externalId = 0;
      req.externalCode = 'project';
    }
    const briefDescription = pt.briefDescription;
    if (briefDescription) {
      req.briefDescription = briefDescription;
    }
    const creatorUserId = pt.creatorUserId;
    if (creatorUserId) {
      req.creatorUserId = creatorUserId;
    }
    const statusId = pt.statusId
    if (statusId) {
      req.statusId = statusId;
    }
    const startDate = pt.startDate;
    if (startDate) {
      req.startDate = startDate;
    }
    const endDate = pt.endDate;
    if (endDate) {
      req.endDate = endDate;
    }
    const priorityId = pt.priorityId;
    if (priorityId) {
      req.priorityId = priorityId;
    }
    const checkedIn = pt.checkedIn;
    if (checkedIn != undefined) {
      req.checkedIn = checkedIn;
    }
    req.fieldMask = pt.fieldMask;
    await this[id ? 'UpdateProjectTask' : 'CreateProjectTask'](req);
  };

  public loadTasks = async (filter: Task) => {
    return await this.BatchGet(filter);
  };
}

function spiffTypeReq() {
  const res = SpiffType.create();
  res.isActive = true;
  return res;
}
interface GetPendingSpiffConfig {
  page?: number;
  technicianUserID?: number;
  startDate?: string;
  endDate?: string;
  role?: string;
  departmentId?: number;
  option?: string;
  processed?: boolean;
}

export {
  Task,
  TaskList,
  TaskClient,
  Spiff,
  SpiffList,
  TaskEventData,
  TaskStatus,
  SpiffType,
  SpiffTypeList,
  SpiffDuplicate,
  ProjectTask,
  ProjectTaskList,
  TaskPriority,
  SpiffOption,
  SpiffOptionList,
  spiffTypeReq,
};

export type { GetPendingSpiffConfig }
