import {
  type ClassCode,
  type PermitTrade,
  type TimesheetDepartment,
  type TransactionAccount,
  type User,
} from '@kalos/kalos-rpc';
import { Combobox, SimpleSelect } from '@kalos/ui';
import { type UseQueryResult } from '@tanstack/react-query';
import { type ComponentProps, useMemo, useState } from 'react';

import { useClassCodeListQuery } from '../../../../hooks/react-query/useClassCodeQuery';
import { usePermitTradeBatchGetQuery } from '../../../../hooks/react-query/usePermits';
import { useTimesheetDepartmentListQuery } from '../../../../hooks/react-query/useTimesheetDepartmentQuery';
import {
  type TransactionAccountFilters,
  useTransactionAccountBatchGet,
} from '../../../../hooks/react-query/useTransactionAccountsQuery';
import { useBatchUserQuery, type UserFilters } from '../../../../hooks/react-query/useUserQuery';

type QueryBatchGetResult<T extends { id: number }> =
  | {
      results: T[];
      totalCount: number;
    }
  | undefined;

type QueryPickerProps<T extends { id: number }, QueryArgs> = {
  disabled?: boolean;
  query: (args: QueryArgs) => UseQueryResult<QueryBatchGetResult<T>>;
  queryArgs: QueryArgs;
  selected: string;
  loading?: boolean;
  filter?: (item: T) => boolean;
  sort?: (a: T, b: T) => number;
  emptyOption?: string;
  onSelect(item: T | null): void;
};

type QuerySelectPickerProps<T extends { id: number }, QueryArgs> = QueryPickerProps<T, QueryArgs> &
  Pick<ComponentProps<typeof SimpleSelect>, 'placeholder' | 'triggerClassName' | 'id'> & {
    renderItem(item: T): React.ReactNode;
  };

export const QuerySelectPickerV2 = <T extends { id: number }, QueryArgs>({
  filter,
  queryArgs,
  sort,
  onSelect,
  renderItem,
  selected,
  disabled,
  query,
  loading: propLoading,
  emptyOption,
  ...selectProps
}: QuerySelectPickerProps<T, QueryArgs>) => {
  const queryResult = query(queryArgs);

  const _disabled = disabled || !queryResult.isSuccess;

  const loading = queryResult.isPending || queryResult.isFetching || propLoading;

  const values = useMemo(() => {
    let data = queryResult.data?.results ?? [];
    if (data.length && sort && filter) {
      data = data.filter(filter).toSorted(sort);
    } else if (data.length && sort) {
      data = data.toSorted(sort);
    } else if (data.length && filter) {
      data = data.filter(filter);
    }
    return [
      ...(emptyOption
        ? [{ value: '0', label: <span className="opacity-60">{emptyOption}</span>, original: null }]
        : []),
      ...data.map((item) => ({
        value: item.id.toString(),
        label: renderItem(item),
        original: item,
      })),
    ];
  }, [queryResult.data?.results, sort, filter, emptyOption, renderItem]);

  const handleSelect: ComponentProps<typeof SimpleSelect>['onChange'] = (selectedValue) => {
    const selectedItem = values.find((item) => item.value === selectedValue);
    onSelect?.((selectedItem?.original as T) ?? null);
  };

  return (
    <SimpleSelect
      disabled={_disabled || loading}
      values={values}
      onChange={handleSelect}
      selectedValue={selected}
      {...selectProps}
    />
  );
};

type QueryComboboxPickerProps<T extends { id: number }, QueryArgs> = QueryPickerProps<
  T,
  QueryArgs
> &
  Pick<
    ComponentProps<typeof Combobox>,
    'emptyLabel' | 'emptySearch' | 'placeholder' | 'triggerClassName' | 'id'
  > & {
    renderItem(item: T): string;
  };

export const QueryComboboxPicker = <T extends { id: number }, QueryArgs>({
  filter,
  queryArgs,
  sort,
  onSelect,
  renderItem,
  selected,
  disabled,
  query,
  loading: propLoading,
  emptyOption,
  ...comboboxProps
}: QueryComboboxPickerProps<T, QueryArgs>) => {
  const queryResult = query(queryArgs);

  const _disabled = disabled || !queryResult.isSuccess;

  const loading = queryResult.isPending || queryResult.isFetching || propLoading;

  const values = useMemo(() => {
    let data = queryResult.data?.results ?? [];
    if (data.length && sort && filter) {
      data = data.filter(filter).toSorted(sort);
    } else if (data.length && sort) {
      data = data.toSorted(sort);
    } else if (data.length && filter) {
      data = data.filter(filter);
    }
    return [
      ...(emptyOption ? [{ value: '0', label: emptyOption, original: null }] : []),
      ...data.map((item) => ({
        value: item.id.toString(),
        label: renderItem(item),
        original: item,
      })),
    ];
  }, [queryResult.data?.results, sort, filter, emptyOption, renderItem]);

  const handleSelect: ComponentProps<typeof SimpleSelect>['onChange'] = (selectedValue) => {
    const selectedItem = values.find((item) => item.value === selectedValue);
    onSelect?.(selectedItem?.original ?? null);
  };

  return (
    <Combobox
      disabled={_disabled || loading}
      values={values}
      onChange={handleSelect}
      value={selected}
      {...comboboxProps}
    />
  );
};

type TransactionAccountPickerProps = Omit<
  QuerySelectPickerProps<TransactionAccount, Parameters<typeof useTransactionAccountBatchGet>['0']>,
  'query' | 'queryArgs'
>;

type TimesheetDepartmentPickerProps = Omit<
  QuerySelectPickerProps<
    TimesheetDepartment,
    Parameters<typeof useTimesheetDepartmentListQuery>['0']
  >,
  'query' | 'queryArgs'
> & {
  queryArgs?: Omit<Parameters<typeof useTimesheetDepartmentListQuery>['0'], 'select'>;
};

export const renderDepartment = (dep: TimesheetDepartment) => dep.description;
export const TimesheetDepartmentPickerV2 = ({
  queryArgs = {
    filter: {
      isActive: 1,
    },
  },
  ...props
}: TimesheetDepartmentPickerProps) => {
  return (
    <QuerySelectPickerV2 query={useTimesheetDepartmentListQuery} queryArgs={queryArgs} {...props} />
  );
};

type PermitTradePickerProps = Omit<
  QuerySelectPickerProps<PermitTrade, Parameters<typeof usePermitTradeBatchGetQuery>['0']>,
  'query' | 'queryArgs'
> & {
  queryArgs?: Omit<Parameters<typeof usePermitTradeBatchGetQuery>['0'], 'select'>;
};

export const PermitTradeSelectPicker = ({ queryArgs = {}, ...props }: PermitTradePickerProps) => {
  return (
    <QuerySelectPickerV2 query={usePermitTradeBatchGetQuery} queryArgs={queryArgs} {...props} />
  );
};

export const TransactionAccountSelectPickerV2 = (props: TransactionAccountPickerProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { onSelect } = props;
  return (
    <QuerySelectPickerV2
      query={useTransactionAccountBatchGet}
      queryArgs={{
        filter: {
          isActive: 1,
          orderBy: 'short_description',
          orderDir: 'ASC',
        },
      }}
      {...props}
      loading={props.loading || loading}
      onSelect={(id) => {
        setLoading(true);
        Promise.allSettled([onSelect?.(id)]).then(() => {
          setLoading(false);
        });
      }}
    />
  );
};

type TransactionAccountComboboxPickerProps = Omit<
  QueryComboboxPickerProps<
    TransactionAccount,
    Parameters<typeof useTransactionAccountBatchGet>['0']
  >,
  'query' | 'queryArgs'
>;

export const transactionAccountFilter: TransactionAccountFilters = {
  isActive: 1,
  orderBy: 'description',
  orderDir: 'ASC',
};
export const TransactionAccountComboboxPicker = (props: TransactionAccountComboboxPickerProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { onSelect } = props;
  return (
    <QueryComboboxPicker
      query={useTransactionAccountBatchGet}
      queryArgs={{ filter: transactionAccountFilter }}
      placeholder="Select Cost Center"
      emptySearch="No Cost centers found"
      emptyLabel="Search cost center"
      {...props}
      loading={props.loading || loading}
      onSelect={(id) => {
        setLoading(true);
        Promise.allSettled([onSelect?.(id)]).then(() => {
          setLoading(false);
        });
      }}
    />
  );
};

type EmployeePickerProps = Omit<
  QuerySelectPickerProps<User, Parameters<typeof useBatchUserQuery>['0']>,
  'query' | 'queryArgs'
>;

export const EmployeeSelectPicker = (props: EmployeePickerProps) => {
  return (
    <QuerySelectPickerV2
      query={useBatchUserQuery}
      queryArgs={{
        filters: {
          isEmployee: 1,
          orderBy: 'user_lastname',
          orderDir: 'ASC',
          overrideLimit: true,
        },
      }}
      {...props}
    />
  );
};

type TechniciansComboboxPickerProps = Omit<
  QueryComboboxPickerProps<User, Parameters<typeof useBatchUserQuery>['0']>,
  'query' | 'queryArgs'
>;

export const techniciansUserFilter: UserFilters = {
  isEmployee: 1,
  overrideLimit: true,
  isActive: 1,
};

export const renderUser = (user: Pick<User, 'firstname' | 'lastname' | 'id'>) =>
  `${user.firstname} ${user.lastname} (ID: ${user.id})`;

export const TechniciansComboboxPicker = (props: TechniciansComboboxPickerProps) => {
  return (
    <QueryComboboxPicker
      query={useBatchUserQuery}
      queryArgs={{
        filters: techniciansUserFilter,
      }}
      {...props}
    />
  );
};

export const adminUserFilter: UserFilters = {
  isAdmin: 1,
  isActive: 1,
  overrideLimit: true,
};

type AdminComboboxPickerProps = Omit<
  QueryComboboxPickerProps<User, Parameters<typeof useBatchUserQuery>['0']>,
  'query' | 'queryArgs'
>;

export const AdminComboboxPicker = (props: AdminComboboxPickerProps) => {
  return (
    <QueryComboboxPicker
      query={useBatchUserQuery}
      queryArgs={{
        filters: adminUserFilter,
      }}
      {...props}
    />
  );
};

type ClassCodeComboboxPickerProps = Omit<
  QueryComboboxPickerProps<ClassCode, Parameters<typeof useClassCodeListQuery>['0']>,
  'query' | 'queryArgs'
>;

export const ClassCodeComboboxPicker = (props: ClassCodeComboboxPickerProps) => {
  return (
    <QueryComboboxPicker
      query={useClassCodeListQuery}
      queryArgs={{ filters: { isActive: true } }}
      {...props}
    />
  );
};
