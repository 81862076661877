import { BaseClient } from '../BaseClient';
import { Group, GroupList } from '../compiled-protos/user';
import { UserServiceClient as GroupServiceClient } from '../compiled-protos/user.client';

class GroupClient extends BaseClient {
  self: GroupServiceClient;

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new GroupServiceClient(this.transport);
  }

  public async Create(req: Group) {
    let res = Group.create();
    try {
      res = await this.self.createGroup(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Get(req: Group) {
    let res = Group.create();
    try {
      res = await this.self.getGroup(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Update(req: Group) {
    let res = Group.create();
    try {
      res = await this.self.updateGroup(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Delete(req: Group) {
    let res = Group.create();
    try {
      res = await this.self.deleteGroup(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async BatchGet(req: Group) {
    let res = GroupList.create();
    try {
      res = await this.self.batchGetGroup(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  // Spent a good amount of time on this one. Please review.
  public loadGroups = async () => {
    const group = Group.create();
    return this.BatchGet(group);
  };
}

export { Group, GroupList, GroupClient };
