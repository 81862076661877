import { zodResolver } from '@hookform/resolvers/zod';
import { type TimesheetDepartment, type User } from '@kalos/kalos-rpc';
import { Button, Checkbox, FormField, FormItem, FormLabel, Input, SimpleSelect } from '@kalos/ui';
import { useCallback, useMemo } from 'react';
import { useForm, useFormContext, useWatch } from 'react-hook-form';
import { z } from 'zod';

import { MONTHS, OPTION_ALL } from '../../../constants';
import { useTransactionStatusBatchQuery } from '../../../hooks/react-query/useTransactionsStatusQuery';
import { sortUserByLastname } from '../../../tools/helpers';
import {
  TechniciansComboboxPicker,
  TimesheetDepartmentPickerV2,
  TransactionAccountComboboxPicker,
} from '../../ComponentsLibrary/Pickers/newPickers/QueryPickerV2';

const getYearList = () => {
  let start = 2019;
  let options: string[] = [];
  const end = new Date().getFullYear();

  while (start <= end) {
    options = options.concat(start.toString());
    start = start + 1;
  }
  return options;
};

export const ccOptions = [
  { label: OPTION_ALL, value: '0' },
  { label: 'Capital One (1974)', value: '1974' },
  { label: 'Capital One (0922)', value: '0922' },
];

const transactionAdminSearchScheme = z.object({
  yearCreated: z.number(),
  dateCreated: z.number(),
  departmentID: z.number().optional(),
  costCenterID: z.number().optional(),
  accountValue: z.string(),
  removeInActiveFromDropDown: z.boolean(),
  userID: z.number(),
  statusID: z.string(),
  searchValue: z.string().optional(),
});

export type TransactionAdminSearchSchemeType = z.infer<typeof transactionAdminSearchScheme>;
export const getDefaultAdminTransactionSearchValues: ({
  acceptOverride,
}: {
  acceptOverride: boolean;
}) => TransactionAdminSearchSchemeType = ({ acceptOverride }) => ({
  yearCreated: 0,
  dateCreated: 0,
  departmentID: undefined,
  costCenterID: undefined,
  accountValue: '0',
  removeInActiveFromDropDown: true,
  userID: 0,
  statusID: acceptOverride ? '2' : '9',
  searchValue: '',
});

export const useTransactionAdminSearchForm = ({ acceptOverride }: { acceptOverride: boolean }) => {
  const defaultValues = useMemo(
    () => getDefaultAdminTransactionSearchValues({ acceptOverride }),
    [acceptOverride],
  );

  const form = useForm<TransactionAdminSearchSchemeType>({
    resolver: zodResolver(transactionAdminSearchScheme),
    mode: 'onChange',
    defaultValues,
  });

  const _reset = useCallback(
    (
      arg1: Parameters<(typeof form)['reset']>['0'],
      arg2: Parameters<(typeof form)['reset']>['1'],
    ) => {
      return form.reset({ ...defaultValues, statusID: '0', ...arg1 }, arg2);
    },
    [defaultValues, form],
  );

  return useMemo(() => ({ ...form, reset: _reset }), [_reset, form]);
};

export const useTransactionAdminSearchFormContext = () => {
  return useFormContext<TransactionAdminSearchSchemeType>();
};

export const TransactionAdminSearchForm = ({
  className,
  departmentID,
  departmentIDList,
  isSU,
  isUserAccountingAdmin,
  hideAuditWorkflowFilters,
  showMultipleDepartments,
}: {
  hideAuditWorkflowFilters: boolean;
  className?: string;
  isSU: boolean;
  isUserAccountingAdmin: boolean;
  showMultipleDepartments: boolean;
  departmentIDList: string;
  departmentID: number;
}) => {
  const form = useTransactionAdminSearchFormContext();

  const selectedYear = useWatch({ control: form.control, name: 'yearCreated' });
  const excludeInactive = useWatch({ control: form.control, name: 'removeInActiveFromDropDown' });

  const transactionStatusOptionsQuery = useTransactionStatusBatchQuery({
    select(data) {
      return (
        data?.results.map((status) => ({
          label: status.description,
          value: status.id.toString(),
        })) ?? []
      );
    },
  });

  const statusOptions = useMemo(() => {
    return transactionStatusOptionsQuery.isSuccess
      ? [
          { value: '0', label: OPTION_ALL },
          ...transactionStatusOptionsQuery.data,
          {
            label: 'Not Audited',
            value: '7',
          },
          ...(!hideAuditWorkflowFilters
            ? [
                {
                  label: 'Not Recorded',
                  value: '8',
                },
                {
                  label: 'Accepted, Not Recorded, Not Audited',
                  value: '9',
                },
                {
                  label: 'Accepted, Audited, Not Recorded',
                  value: '10',
                },
                {
                  label: 'Audited and Recorded',
                  value: '11',
                },
                {
                  label: 'Deleted',
                  value: '12',
                },
              ]
            : []),
        ]
      : [];
  }, [
    hideAuditWorkflowFilters,
    transactionStatusOptionsQuery.data,
    transactionStatusOptionsQuery.isSuccess,
  ]);

  const employeeFilter = useMemo(() => {
    if (isSU || isUserAccountingAdmin) {
      return (user: User) => !!user.isActive;
    }

    if (showMultipleDepartments && departmentIDList) {
      return makeEmployeeTest(departmentID, excludeInactive, departmentIDList);
    } else {
      return makeEmployeeTest(departmentID, excludeInactive);
    }
  }, [
    isSU,
    isUserAccountingAdmin,
    showMultipleDepartments,
    departmentIDList,
    departmentID,
    excludeInactive,
  ]);

  const departmentFilter = useMemo(() => {
    if (isSU || isUserAccountingAdmin) {
      return undefined;
    }

    if (showMultipleDepartments && departmentIDList) {
      return makeDepartmentTest(departmentID, departmentIDList);
    } else {
      return makeDepartmentTest(departmentID);
    }
  }, [isSU, isUserAccountingAdmin, showMultipleDepartments, departmentIDList, departmentID]);

  const yearOptions = useMemo<{ label: string; value: string }[]>(
    () => [
      {
        label: OPTION_ALL,
        value: '0',
      },
      ...getYearList().map((value) => ({
        label: value,
        value,
      })),
    ],
    [],
  );
  const monthOptions = useMemo(
    () => [
      { label: OPTION_ALL, value: (0).toString() },
      ...MONTHS.map((month, idx) => ({ value: (idx + 1).toString(), label: month })),
    ],
    [],
  );

  return (
    <form className={className} onSubmit={form.handleSubmit(console.log)}>
      <FormField
        control={form.control}
        name="yearCreated"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Filter by year</FormLabel>
            <SimpleSelect
              placeholder={OPTION_ALL}
              selectedValue={field.value.toString() ?? ''}
              onChange={(value) => {
                field.onChange(Number(value));
                if (+value === 0 && form.getValues().dateCreated !== 0) {
                  form.setValue('dateCreated', 0);
                }
              }}
              values={yearOptions}
            />
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="dateCreated"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Filter by month</FormLabel>
            <SimpleSelect
              disabled={!Number(selectedYear)}
              placeholder={OPTION_ALL}
              selectedValue={field.value?.toString() ?? ''}
              onChange={(value) => {
                field.onChange(Number(value));
              }}
              values={monthOptions}
            />
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="departmentID"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Department</FormLabel>
            <TimesheetDepartmentPickerV2
              placeholder="Select Department"
              selected={field.value?.toString() ?? ''}
              emptyOption={OPTION_ALL}
              queryArgs={{ filter: { isActive: 1 } }}
              filter={departmentFilter}
              onSelect={(department) => {
                field.onChange(department?.id ?? 0);
              }}
              renderItem={(i) => i.description}
            />
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="statusID"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Filter by status</FormLabel>
            <SimpleSelect
              values={statusOptions}
              disabled={!transactionStatusOptionsQuery.isSuccess}
              selectedValue={field.value}
              onChange={field.onChange}
            />
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="costCenterID"
        render={({ field }) => {
          return (
            <FormItem>
              <FormLabel>Account</FormLabel>
              <TransactionAccountComboboxPicker
                placeholder="Search Account..."
                emptyLabel="Select Account"
                emptySearch="No Accounts found"
                selected={field.value?.toString() ?? ''}
                onSelect={(account) => {
                  field.onChange(account?.id ?? 0);
                }}
                renderItem={(i) => i.shortDescription}
              />
            </FormItem>
          );
        }}
      />

      {isUserAccountingAdmin && (
        <FormField
          control={form.control}
          name="accountValue"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Pick bank account</FormLabel>
              <SimpleSelect
                values={ccOptions}
                placeholder="None"
                selectedValue={field.value?.toString() ?? ''}
                onChange={field.onChange}
              />
            </FormItem>
          )}
        />
      )}

      <div className="col-span-1 flex flex-col gap-1.5 self-end">
        <FormField
          control={form.control}
          name="userID"
          render={({ field }) => {
            return (
              <FormItem className="flex-1 items-center">
                <div className="flex gap-4">
                  <FormLabel>Employee</FormLabel>
                  <FormField
                    control={form.control}
                    name="removeInActiveFromDropDown"
                    render={({ field }) => {
                      return (
                        <FormItem className="ml-auto flex items-center gap-2 space-y-0">
                          <FormLabel htmlFor="hideInactiveCheckbox">
                            Hide Inactive Employees?
                          </FormLabel>
                          <Checkbox
                            id="hideInactiveCheckbox"
                            checked={field.value}
                            onCheckedChange={field.onChange}
                          />
                        </FormItem>
                      );
                    }}
                  />
                </div>
                <TechniciansComboboxPicker
                  selected={field.value.toString()}
                  onSelect={(user) => {
                    field.onChange(user?.id ?? 0);
                  }}
                  filter={employeeFilter}
                  sort={sortUserByLastname}
                  emptyOption={OPTION_ALL}
                  renderItem={(i) => `${i.lastname}, ${i.firstname}`}
                />
              </FormItem>
            );
          }}
        />
      </div>

      <FormField
        control={form.control}
        name="searchValue"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Search</FormLabel>
            <Input {...field} />
          </FormItem>
        )}
      />

      <Button
        type="reset"
        onClick={() => {
          form.reset();
        }}
        variant="outline"
        className="max-w-max self-end"
      >
        Reset
      </Button>
    </form>
  );
};

const makeDepartmentTest = (
  departmentID: number,
  departmentList?: string,
): ((dept: TimesheetDepartment) => boolean) => {
  if (departmentList) {
    const parseDepartment = departmentList.split(',').map(Number);
    return (dept) => parseDepartment.some((el) => el === dept.id);
  }
  return (dept) => dept.id === departmentID;
};

const makeEmployeeTest = (
  departmentID: number,
  removeInactive: boolean,
  departmentList?: string,
): ((user: User) => boolean) => {
  return (user) => {
    if (departmentList) {
      const parsedDepartment = departmentList.split(',').map(Number);
      const isActive = removeInactive ? user.isActive === 1 : true;
      return parsedDepartment.some((el) => el === user.employeeDepartmentID && isActive);
    }
    return user.employeeDepartmentID === departmentID;
  };
};
