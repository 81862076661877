import { type User } from '@kalos/kalos-rpc';
import { Button, Card, CardContent, CardHeader, CardTitle, Toaster } from '@kalos/ui';
import { type CodeResponse, useGoogleLogin } from '@react-oauth/google';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';

import { useAuth } from './context/AuthContext';
import { ErrorBoundary } from './modules/ComponentsLibrary/ErrorBoundary';
import { StyledPage } from './modules/PageWrapper/styled';
import SideMenu from './modules/SideMenu/main';

export const RequireAuth = ({
  children,
  requiredPermissions,
  userCheck,
}: {
  children: JSX.Element;
  requiredPermissions?: number[];
  userCheck?: (user: User) => boolean;
}) => {
  const auth = useAuth();
  const location = useLocation();

  if (!auth.authChecked) {
    return null; // or some loading spinner
  }

  if (auth.user.id === 0) {
    return (
      <Navigate
        to="/"
        state={{
          from: location,
        }}
        replace
      />
    );
  }

  if (userCheck) {
    const isAllowed = userCheck(auth.user);
    if (!isAllowed) {
      return "You don't have permission to view this page";
    }
  }

  if (requiredPermissions) {
    const userPermissions = auth.user.permissionGroups.map((p) => p.id);
    const hasAllPermissions = requiredPermissions.every((perm) => userPermissions.includes(perm));

    if (!hasAllPermissions) {
      return null;
    }
  }

  return children;
};
/*
const client = new UserClient(ENDPOINT);
//We should return the old login here

export const LegacyLoginPage = () => {
  const credentials = { userName: '', password: '' };
  async function getLegacyUser(userName: string, password: string) {
    const userReq = User.create();
    userReq.pwd = password;
    userReq.login = userName;
    const legacyToken = await client.GetToken_DEPRECATED(userName, password);
    console.log(legacyToken);
  }
  return (
    <div className="flex min-h-[80vh] flex-col items-center justify-center">
      <Card>
        <CardHeader>
          <CardTitle className="text-center">Welcome To Kalos Services Inc.</CardTitle>
        </CardHeader>
        <CardContent className="flex flex-col gap-4">
          <Form
            className="main_box"
            schema={[
              [
                {
                  label: 'User Name',
                  name: 'userName',

                  required: true,
                },
              ],
              [
                {
                  label: 'Password',
                  name: 'password',
                  required: true,
                },
              ],
            ]}
            onSave={(data) => getLegacyUser(data.userName, data.password)}
            data={credentials}
            onClose={() => console.log('we do closing logic, if any')}
          />
          <p>{'If you cannot log in, please contact Webtech for assistance'}</p>
        </CardContent>
      </Card>
    </div>
  );
};
*/
export const authFlow = 'auth-code';
export const authScope = 'https://www.googleapis.com/auth/chat.memberships.app';
//Render the logic to allow a login selection

export const SelectLoginPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();
  const from = location.state?.from?.pathname || '/home';

  const handleSubmit = async function handleSubmit(credentialResponse: CodeResponse) {
    if (credentialResponse.code) {
      console.log({ credentialResponse });
      await auth.signin(credentialResponse.code);
      navigate(from);
    } else {
      console.log(credentialResponse);
    }
  };

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => handleSubmit(tokenResponse),
    onError: (response) => {
      console.log('Login Failed');
      console.log({ response });
    },
    flow: authFlow,
    redirect_uri: window.location.origin,
    scope: authScope,
  });

  return (
    <div className="flex min-h-[80vh] flex-col items-center justify-center">
      <Card>
        <CardHeader className="pb-16">
          <CardTitle className="text-center">Welcome To Kalos Services Inc.</CardTitle>
        </CardHeader>
        <CardContent className="flex flex-col gap-4">
          <Button className="flex-1" type="submit" onClick={login}>
            Employee Sign In
          </Button>
          <p>If you cannot log in, please contact Webtech for assistance</p>
        </CardContent>
      </Card>
    </div>
  );
};

export const Logout = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  auth.signout(() => {
    navigate('/');
  });
  return null;
};

export const withAuth = function withAuth(
  input: JSX.Element,
  userCheck?: (user: User) => boolean,
  ...permissions: number[]
) {
  // todo, extend permissions to include required flag, split permissions into required and denied permissions here
  return (
    <RequireAuth userCheck={userCheck} requiredPermissions={permissions}>
      {input}
    </RequireAuth>
  );
};

const RequireLogout = ({ children }: { children: JSX.Element }) => {
  const user = useAuth();

  return user.user.id ? <Navigate to="/home" replace /> : children;
};

export const withoutAuth = (input: JSX.Element) => {
  return <RequireLogout>{input}</RequireLogout>;
};

export const Layout = () => {
  return (
    <StyledPage>
      <ErrorBoundary>
        <Toaster />
        <div className="PageWrapper">
          <div className="PageWrapperMenu flex">
            <SideMenu />
          </div>
          <div className="PageWrapperContent">
            <Outlet />
          </div>
        </div>
      </ErrorBoundary>
    </StyledPage>
  );
};
