// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "timesheet_department.proto" (syntax proto3)
// tslint:disable
import { TimesheetDepartmentService } from "./timesheet_department";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service TimesheetDepartmentService
 */
export class TimesheetDepartmentServiceClient {
    constructor(_transport) {
        this._transport = _transport;
        this.typeName = TimesheetDepartmentService.typeName;
        this.methods = TimesheetDepartmentService.methods;
        this.options = TimesheetDepartmentService.options;
    }
    /**
     * @generated from protobuf rpc: Create(TimesheetDepartment) returns (TimesheetDepartment);
     */
    create(input, options) {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Get(TimesheetDepartment) returns (TimesheetDepartment);
     */
    get(input, options) {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGet(TimesheetDepartment) returns (TimesheetDepartmentList);
     */
    batchGet(input, options) {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Update(TimesheetDepartment) returns (TimesheetDepartment);
     */
    update(input, options) {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Delete(TimesheetDepartment) returns (TimesheetDepartment);
     */
    delete(input, options) {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetDepartmentsByIds(IntArray) returns (TimesheetDepartmentList);
     */
    batchGetDepartmentsByIds(input, options) {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateTimesheetDepartmentGroup(TimesheetDepartmentGroup) returns (TimesheetDepartmentGroup);
     */
    createTimesheetDepartmentGroup(input, options) {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetTimesheetDepartmentGroup(TimesheetDepartmentGroup) returns (TimesheetDepartmentGroup);
     */
    getTimesheetDepartmentGroup(input, options) {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetTimesheetDepartmentGroup(TimesheetDepartmentGroup) returns (TimesheetDepartmentGroupList);
     */
    batchGetTimesheetDepartmentGroup(input, options) {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateTimesheetDepartmentGroup(TimesheetDepartmentGroup) returns (TimesheetDepartmentGroup);
     */
    updateTimesheetDepartmentGroup(input, options) {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteTimesheetDepartmentGroup(TimesheetDepartmentGroup) returns (Empty);
     */
    deleteTimesheetDepartmentGroup(input, options) {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateTimesheetClassCode(TimesheetClassCode) returns (TimesheetClassCode);
     */
    createTimesheetClassCode(input, options) {
        const method = this.methods[11], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetTimesheetClassCode(TimesheetClassCode) returns (TimesheetClassCode);
     */
    getTimesheetClassCode(input, options) {
        const method = this.methods[12], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetTimesheetClassCode(TimesheetClassCode) returns (TimesheetClassCodeList);
     */
    batchGetTimesheetClassCode(input, options) {
        const method = this.methods[13], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateTimesheetClassCode(TimesheetClassCode) returns (TimesheetClassCode);
     */
    updateTimesheetClassCode(input, options) {
        const method = this.methods[14], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteTimesheetClassCode(TimesheetClassCode) returns (Empty);
     */
    deleteTimesheetClassCode(input, options) {
        const method = this.methods[15], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetDepartmentUserIDList(Int32) returns (String);
     */
    getDepartmentUserIDList(input, options) {
        const method = this.methods[16], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
}
