// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "geolocation_log.proto" (syntax proto3)
// tslint:disable
// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "geolocation_log.proto" (syntax proto3)
// tslint:disable
import { Empty } from "./common";
import { Bool } from "./common";
import { Int32List } from "./common";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { WireType } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
// @generated message type with reflection information, may provide speed optimized methods
class GeolocationRPCLog$Type extends MessageType {
    constructor() {
        super("GeolocationRPCLog", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "longitude", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 3, name: "latitude", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 4, name: "timestamp", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "has_timecard", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "event", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "odometer", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 9, name: "activity_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "activity_confidence", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 11, name: "should_retain", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 12, name: "date_range", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "date_target", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "event_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, longitude: 0, latitude: 0, timestamp: "", userId: 0, hasTimecard: false, event: "", odometer: 0, activityType: "", activityConfidence: 0, shouldRetain: false, dateRange: [], dateTarget: [], fieldMask: [], eventId: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* float longitude */ 2:
                    message.longitude = reader.float();
                    break;
                case /* float latitude */ 3:
                    message.latitude = reader.float();
                    break;
                case /* string timestamp */ 4:
                    message.timestamp = reader.string();
                    break;
                case /* int32 user_id */ 5:
                    message.userId = reader.int32();
                    break;
                case /* bool has_timecard */ 6:
                    message.hasTimecard = reader.bool();
                    break;
                case /* string event */ 7:
                    message.event = reader.string();
                    break;
                case /* float odometer */ 8:
                    message.odometer = reader.float();
                    break;
                case /* string activity_type */ 9:
                    message.activityType = reader.string();
                    break;
                case /* int32 activity_confidence */ 10:
                    message.activityConfidence = reader.int32();
                    break;
                case /* bool should_retain */ 11:
                    message.shouldRetain = reader.bool();
                    break;
                case /* repeated string date_range */ 12:
                    message.dateRange.push(reader.string());
                    break;
                case /* repeated string date_target */ 13:
                    message.dateTarget.push(reader.string());
                    break;
                case /* repeated string field_mask */ 14:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 event_id */ 15:
                    message.eventId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* float longitude = 2; */
        if (message.longitude !== 0)
            writer.tag(2, WireType.Bit32).float(message.longitude);
        /* float latitude = 3; */
        if (message.latitude !== 0)
            writer.tag(3, WireType.Bit32).float(message.latitude);
        /* string timestamp = 4; */
        if (message.timestamp !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.timestamp);
        /* int32 user_id = 5; */
        if (message.userId !== 0)
            writer.tag(5, WireType.Varint).int32(message.userId);
        /* bool has_timecard = 6; */
        if (message.hasTimecard !== false)
            writer.tag(6, WireType.Varint).bool(message.hasTimecard);
        /* string event = 7; */
        if (message.event !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.event);
        /* float odometer = 8; */
        if (message.odometer !== 0)
            writer.tag(8, WireType.Bit32).float(message.odometer);
        /* string activity_type = 9; */
        if (message.activityType !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.activityType);
        /* int32 activity_confidence = 10; */
        if (message.activityConfidence !== 0)
            writer.tag(10, WireType.Varint).int32(message.activityConfidence);
        /* bool should_retain = 11; */
        if (message.shouldRetain !== false)
            writer.tag(11, WireType.Varint).bool(message.shouldRetain);
        /* repeated string date_range = 12; */
        for (let i = 0; i < message.dateRange.length; i++)
            writer.tag(12, WireType.LengthDelimited).string(message.dateRange[i]);
        /* repeated string date_target = 13; */
        for (let i = 0; i < message.dateTarget.length; i++)
            writer.tag(13, WireType.LengthDelimited).string(message.dateTarget[i]);
        /* repeated string field_mask = 14; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(14, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 event_id = 15; */
        if (message.eventId !== 0)
            writer.tag(15, WireType.Varint).int32(message.eventId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GeolocationRPCLog
 */
export const GeolocationRPCLog = new GeolocationRPCLog$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GeolocationRPCLogList$Type extends MessageType {
    constructor() {
        super("GeolocationRPCLogList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => GeolocationRPCLog },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated GeolocationRPCLog results */ 1:
                    message.results.push(GeolocationRPCLog.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated GeolocationRPCLog results = 1; */
        for (let i = 0; i < message.results.length; i++)
            GeolocationRPCLog.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GeolocationRPCLogList
 */
export const GeolocationRPCLogList = new GeolocationRPCLogList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GeolocationLog$Type extends MessageType {
    constructor() {
        super("GeolocationLog", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "location", kind: "message", T: () => Location },
            { no: 3, name: "timestamp", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "has_timecard", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "event", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "odometer", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 8, name: "activity_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "activity_confidence", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "should_retain", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 11, name: "date_range", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "date_target", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "event_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, timestamp: "", userId: 0, hasTimecard: false, event: "", odometer: 0, activityType: "", activityConfidence: 0, shouldRetain: false, dateRange: [], dateTarget: [], fieldMask: [], eventId: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* Location location */ 2:
                    message.location = Location.internalBinaryRead(reader, reader.uint32(), options, message.location);
                    break;
                case /* string timestamp */ 3:
                    message.timestamp = reader.string();
                    break;
                case /* int32 user_id */ 4:
                    message.userId = reader.int32();
                    break;
                case /* bool has_timecard */ 5:
                    message.hasTimecard = reader.bool();
                    break;
                case /* string event */ 6:
                    message.event = reader.string();
                    break;
                case /* float odometer */ 7:
                    message.odometer = reader.float();
                    break;
                case /* string activity_type */ 8:
                    message.activityType = reader.string();
                    break;
                case /* int32 activity_confidence */ 9:
                    message.activityConfidence = reader.int32();
                    break;
                case /* bool should_retain */ 10:
                    message.shouldRetain = reader.bool();
                    break;
                case /* repeated string date_range */ 11:
                    message.dateRange.push(reader.string());
                    break;
                case /* repeated string date_target */ 12:
                    message.dateTarget.push(reader.string());
                    break;
                case /* repeated string field_mask */ 13:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 event_id */ 14:
                    message.eventId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* Location location = 2; */
        if (message.location)
            Location.internalBinaryWrite(message.location, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string timestamp = 3; */
        if (message.timestamp !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.timestamp);
        /* int32 user_id = 4; */
        if (message.userId !== 0)
            writer.tag(4, WireType.Varint).int32(message.userId);
        /* bool has_timecard = 5; */
        if (message.hasTimecard !== false)
            writer.tag(5, WireType.Varint).bool(message.hasTimecard);
        /* string event = 6; */
        if (message.event !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.event);
        /* float odometer = 7; */
        if (message.odometer !== 0)
            writer.tag(7, WireType.Bit32).float(message.odometer);
        /* string activity_type = 8; */
        if (message.activityType !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.activityType);
        /* int32 activity_confidence = 9; */
        if (message.activityConfidence !== 0)
            writer.tag(9, WireType.Varint).int32(message.activityConfidence);
        /* bool should_retain = 10; */
        if (message.shouldRetain !== false)
            writer.tag(10, WireType.Varint).bool(message.shouldRetain);
        /* repeated string date_range = 11; */
        for (let i = 0; i < message.dateRange.length; i++)
            writer.tag(11, WireType.LengthDelimited).string(message.dateRange[i]);
        /* repeated string date_target = 12; */
        for (let i = 0; i < message.dateTarget.length; i++)
            writer.tag(12, WireType.LengthDelimited).string(message.dateTarget[i]);
        /* repeated string field_mask = 13; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(13, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 event_id = 14; */
        if (message.eventId !== 0)
            writer.tag(14, WireType.Varint).int32(message.eventId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GeolocationLog
 */
export const GeolocationLog = new GeolocationLog$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Location$Type extends MessageType {
    constructor() {
        super("Location", [
            { no: 1, name: "latitude", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 2, name: "longitude", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ }
        ]);
    }
    create(value) {
        const message = { latitude: 0, longitude: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* float latitude */ 1:
                    message.latitude = reader.float();
                    break;
                case /* float longitude */ 2:
                    message.longitude = reader.float();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* float latitude = 1; */
        if (message.latitude !== 0)
            writer.tag(1, WireType.Bit32).float(message.latitude);
        /* float longitude = 2; */
        if (message.longitude !== 0)
            writer.tag(2, WireType.Bit32).float(message.longitude);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Location
 */
export const Location = new Location$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GeolocationLogList$Type extends MessageType {
    constructor() {
        super("GeolocationLogList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => GeolocationLog },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated GeolocationLog results */ 1:
                    message.results.push(GeolocationLog.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated GeolocationLog results = 1; */
        for (let i = 0; i < message.results.length; i++)
            GeolocationLog.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GeolocationLogList
 */
export const GeolocationLogList = new GeolocationLogList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IdList$Type extends MessageType {
    constructor() {
        super("IdList", [
            { no: 1, name: "ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { ids: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated int32 ids */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.ids.push(reader.int32());
                    else
                        message.ids.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated int32 ids = 1; */
        if (message.ids.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.ids.length; i++)
                writer.int32(message.ids[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message IdList
 */
export const IdList = new IdList$Type();
/**
 * @generated ServiceType for protobuf service GeolocationLogService
 */
export const GeolocationLogService = new ServiceType("GeolocationLogService", [
    { name: "Update", options: {}, I: GeolocationLog, O: GeolocationRPCLog },
    { name: "UpdateHasTimecard", options: {}, I: Int32List, O: Bool },
    { name: "Get", options: {}, I: GeolocationRPCLog, O: GeolocationRPCLog },
    { name: "BatchGet", options: {}, I: GeolocationRPCLog, O: GeolocationRPCLogList },
    { name: "Delete", options: {}, I: GeolocationLog, O: Empty }
]);
