import { type TransactionLineItem } from '@kalos/kalos-rpc';
import { cn, Input, Label } from '@kalos/ui';
import { CheckCircledIcon, CrossCircledIcon, TriangleDownIcon } from '@radix-ui/react-icons';
import { useStore } from 'zustand';

import { useTransactionLineItemsStore } from '../../../../../context/TransactionLIneItems';
import { isMoneyValue } from '../../../../../tools/helpers';
import { prettyMoney } from '../../../../Transactions/components/TransactionAdmin';

export const AmountField = ({
  transactionLineItem,
}: {
  transactionLineItem: TransactionLineItem;
}) => {
  const store = useTransactionLineItemsStore();
  const isEditing = useStore(store, (s) => s.isEditing);

  if (!isEditing) return transactionLineItem.amount;

  return <EditableAmountField transactionLineItem={transactionLineItem} />;
};

const EditableAmountField = ({
  transactionLineItem,
}: {
  transactionLineItem: TransactionLineItem;
}) => {
  const store = useTransactionLineItemsStore();
  const setLineItems = useStore(store, (s) => s.setLineItems);
  const value = useStore(store, (s) =>
    s.lineItems.find((li) => li.id === transactionLineItem.id),
  )?.amount;
  const isValid = useStore(store, (s) => s.isAmountValid());
  const isFormatCorrect = isMoneyValue(value ?? 0);

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    let newValue = parseFloat(e.target.value);
    if (isNaN(newValue)) {
      newValue = 0;
    }
    setLineItems((prevLineItems) => {
      return prevLineItems.map((li) => {
        if (li.id === transactionLineItem.id) {
          return { ...li, amount: newValue };
        }
        return li;
      });
    });
  };

  return (
    <div className="flex flex-col gap-1">
      {!isFormatCorrect && (
        <Label htmlFor={transactionLineItem.id.toString()} className="text-xs text-red-500">
          Should have 2 digits at max!
        </Label>
      )}
      {value === 0 && (
        <Label htmlFor={transactionLineItem.id.toString()} className="text-xs text-red-500">
          Amount cannot be 0!
        </Label>
      )}
      <Input
        value={value}
        id={transactionLineItem.id.toString()}
        type="number"
        step={0.01}
        onChange={onChange}
        className={cn(!(isValid && isFormatCorrect && value !== 0) && 'border-2 border-red-500')}
      />
    </div>
  );
};

export const AmountEditHeader = () => {
  const store = useTransactionLineItemsStore();
  const isAmountValid = useStore(store, (s) => s.isAmountValid());
  const transactionAmount = useStore(store, (s) => s.transaction.amount);
  const transactionLineItemsAmount = useStore(store, (s) => s.currentLineItemsAmountSum());

  const diff = transactionAmount - transactionLineItemsAmount;

  return (
    <div className="flex items-center gap-2">
      <div className="flex flex-col gap-1 text-xs">
        <span>
          Current transaction cost:{' '}
          <span className="text-foreground font-bold">{prettyMoney(transactionAmount)}</span>
        </span>
        <span>
          Current line Items cost{' '}
          <span className={cn(isAmountValid ? 'text-emerald-500' : 'text-red-500', 'font-bold')}>
            {prettyMoney(transactionLineItemsAmount)}
          </span>
        </span>
        {!isAmountValid && diff !== 0 && (
          <div className="flex items-center gap-1">
            Diff:{' '}
            <span className="text-foreground font-bold">
              {prettyMoney(Number(diff.toFixed(2)))}
            </span>
            <span>
              <TriangleDownIcon
                className={cn(
                  'size-6 text-red-500 transition-transform',
                  diff > 0 ? 'rotate-180' : 'rotate-0',
                )}
              />
            </span>
          </div>
        )}
      </div>
      {isAmountValid ? (
        <CheckCircledIcon className="size-6 text-emerald-500" />
      ) : (
        <CrossCircledIcon className="size-6 text-red-500" />
      )}
    </div>
  );
};
