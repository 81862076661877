import { BaseClient } from '../BaseClient';
import { Bool } from '../compiled-protos/common';
import { CreateMessageRequest, OAUTHRequest } from '../compiled-protos/google';
import { GoogleServiceClient as ChatServiceClient } from '../compiled-protos/google.client';

class KalosChatClient extends BaseClient {
  self: ChatServiceClient;

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new ChatServiceClient(this.transport);
  }

  public async CreateMessage(req: CreateMessageRequest) {
    let res = Bool.create();
    try {
      res = await this.self.createChatMessage(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async GetAuthURL() {
    return this.self.getOAUTHURL(OAUTHRequest.create(), this.getMetaData());
  }

  public async GetAuthToken(code: string, userID: number) {
    return this.self.getOAUTHToken(
      OAUTHRequest.create({ authCode: code, userId: userID }),
      this.getMetaData()
    );
  }
}

export {
  ChatCard,
  ChatMember,
  ChatMembers,
  ChatMessage,
  ChatSpace,
  ChatSpaces,
  CardAction,
  CardButton,
  CardFormAction,
  CardHeader,
  CardImage,
  CardKeyValue,
  CardOnClick,
  CardSection,
  CardWidget,
} from '../compiled-protos/google';

export { KalosChatClient, CreateMessageRequest };
