import { zodResolver } from '@hookform/resolvers/zod';
import { type SpiffOption } from '@kalos/kalos-rpc';
import { Button, FormControl, FormField, FormItem, FormLabel, Input } from '@kalos/ui';
import { useForm, useFormContext } from 'react-hook-form';
import { z } from 'zod';

import { type ZodObjectWithModelKeys } from '../../../../../tools/typeguargs';

type PieceWorkSearchFormFields = Pick<SpiffOption, 'name' | 'code' | 'amount'>;
const pieceWorkSearchScheme = z.object({
  name: z.string(),
  code: z.string(),
  amount: z.number(),
}) satisfies ZodObjectWithModelKeys<PieceWorkSearchFormFields>;

export type PieceWorkSearchScheme = z.infer<typeof pieceWorkSearchScheme>;

export const getDefaultPieceWorkSearchFormValues = () => {
  return {
    name: '',
    code: '',
    amount: 0,
  } satisfies PieceWorkSearchScheme;
};
export const usePieceWorkSearchForm = ({
  defaultValues,
}: {
  defaultValues: PieceWorkSearchScheme;
}) => {
  return useForm<PieceWorkSearchScheme>({
    defaultValues,
    resolver: zodResolver(pieceWorkSearchScheme),
    mode: 'onChange',
  });
};

export const usePieceWorkSearchFormContext = () => {
  return useFormContext<PieceWorkSearchScheme>();
};

export const PieceWorkOptionsSearchForm = () => {
  const form = usePieceWorkSearchFormContext();

  return (
    <form
      className="@sm:grid-cols-4 @2xl:grid-cols-8 grid grid-cols-2 items-end gap-2"
      onSubmit={form.handleSubmit(console.log)}
    >
      <FormField
        control={form.control}
        name="name"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Name</FormLabel>
            <FormControl>
              <Input {...field} />
            </FormControl>
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="code"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Code</FormLabel>
            <FormControl>
              <Input {...field} />
            </FormControl>
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="amount"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Amount</FormLabel>
            <FormControl>
              <Input {...field} />
            </FormControl>
          </FormItem>
        )}
      />

      <Button
        onClick={() => form.reset(form.formState.defaultValues)}
        className="max-w-max"
        type="reset"
        variant="outline"
        size="sm"
      >
        Reset
      </Button>
    </form>
  );
};
