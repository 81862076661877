import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';

import { cn } from '../utils';

const badgeVariants = cva(
  'inline-flex items-center rounded-md border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2',
  {
    variants: {
      variant: {
        default: 'border-transparent bg-primary text-primary-foreground shadow hover:bg-primary/80',
        secondary:
          'border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80',
        destructive:
          'border-transparent bg-destructive text-destructive-foreground shadow hover:bg-destructive/80',
        outline: 'text-foreground',
        warning: 'border-transparent bg-amber-500 text-white shadow hover:bg-amber-500/80',
        success: 'border-transparent bg-green-500 text-white shadow hover:bg-green-500/80',
        table: 'border border-input text-foreground',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);

export type BadgeProps<T extends React.ElementType> = React.ComponentPropsWithoutRef<T> &
  VariantProps<typeof badgeVariants> & {
    as?: T;
  };

function Badge<T extends React.ElementType = 'div'>({
  as,
  className,
  variant,
  ...props
}: BadgeProps<T>) {
  const Tag = as ?? 'div';

  return <Tag className={cn(badgeVariants({ variant }), className)} {...props} />;
}

export { Badge, badgeVariants };
