import { BaseClient } from '../BaseClient';
import {
  ContractFrequency,
  ContractFrequencyList,
} from '../compiled-protos/event';
import { EventServiceClient as ContractFrequencyServiceClient } from '../compiled-protos/event.client';

class ContractFrequencyClient extends BaseClient {
  self: ContractFrequencyServiceClient;

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new ContractFrequencyServiceClient(this.transport);
  }

  public async Create(req: ContractFrequency) {
    let res = ContractFrequency.create();
    try {
      res = await this.self.createContractFrequency(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Get(req: ContractFrequency) {
    let res = ContractFrequency.create();
    try {
      res = await this.self.getContractFrequency(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Update(req: ContractFrequency) {
    let res = ContractFrequency.create();
    try {
      res = await this.self.updateContractFrequency(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Delete(req: ContractFrequency) {
    let res = ContractFrequency.create();
    try {
      res = await this.self.deleteContractFrequency(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async BatchGet(req: ContractFrequency) {
    let res = ContractFrequencyList.create();
    try {
      res = await this.self.batchGetContractFrequency(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }
}

export { ContractFrequency, ContractFrequencyList, ContractFrequencyClient };
