// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "pdf.proto" (syntax proto3)
// tslint:disable
import { PDFService } from "./pdf";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service PDFService
 */
export class PDFServiceClient {
    constructor(_transport) {
        this._transport = _transport;
        this.typeName = PDFService.typeName;
        this.methods = PDFService.methods;
        this.options = PDFService.options;
    }
    /**
     * @generated from protobuf rpc: Create(HTML) returns (URLObject);
     */
    create(input, options) {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateWithWeasyPrint(HTML) returns (URLObject);
     */
    createWithWeasyPrint(input, options) {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
}
