import './SectionBar.module.less';

import { cn } from '@kalos/ui';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Pagination from '@mui/material/Pagination';
import TablePagination from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';
import { clsx } from 'clsx';
import { type CSSProperties, type FC, type ReactNode, useCallback, useRef, useState } from 'react';

import { Actions, type ActionsProps } from '../Actions';
import { Field, type Value } from '../Field';

export type PaginationType = {
  count: number;
  page: number;
  rowsPerPage?: number;
  disabled?: boolean;
  onPageChange: (page: number) => void;
};

interface Props {
  title?: ReactNode;
  subtitle?: ReactNode;
  actions?: ActionsProps;
  className?: string;
  pagination?: PaginationType;
  styles?: CSSProperties;
  fixedActions?: boolean;
  footer?: ReactNode;
  asideContent?: ReactNode;
  asideContentFirst?: boolean;
  small?: boolean;
  onCheck?: (checked: number) => void;
  checked?: number;
  loading?: boolean;
  uncollapsable?: boolean;
  sticky?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
  defaultCollapsed?: boolean;
  onToggleCollapse?: () => void;
  subtitleMinimized?: boolean;
}

export const SectionBar: FC<Props> = ({
  title = '',
  subtitle,
  actions = [],
  className = '',
  pagination,
  styles,
  fixedActions = false,
  footer,
  asideContent,
  asideContentFirst = false,
  small = false,
  children,
  onCheck,
  checked,
  loading = false,
  uncollapsable = false,
  sticky = true,
  disabled,
  defaultCollapsed = false,
  onToggleCollapse,
  subtitleMinimized: subtitleMinimizedProp = false,
}) => {
  const subtitleRef = useRef<HTMLElement | null>(null);
  const [collapsed, setCollapsed] = useState(defaultCollapsed);
  const [subtitleMinimized, setSubtitleMinimized] = useState<boolean>(false);
  const handleToggleCollapsed = useCallback(() => {
    uncollapsable && children && setCollapsed(!collapsed);
    if (onToggleCollapse) {
      onToggleCollapse();
    }
  }, [uncollapsable, children, collapsed, onToggleCollapse]);
  const handleChangePage = useCallback(
    (_: any, page: number) => {
      if (pagination) {
        pagination.onPageChange(page);
      }
    },
    [pagination],
  );
  const handleCheckChange = useCallback(
    (checked: Value) => {
      if (onCheck) {
        onCheck(+checked);
      }
    },
    [onCheck],
  );
  const collapsable = !!children && uncollapsable;

  const handleShowSubtitle = () => {
    if (subtitleMinimizedProp) {
      setSubtitleMinimized(!subtitleMinimized);
    }
  };

  return (
    <>
      <div
        className={clsx(className, 'SectionBar', {
          collapsable,
          collapsed,
          fixedActions,
          small,
          sticky,
        })}
        style={styles}
      >
        <div className="SectionBarHeaderWrapper">
          <div className="SectionBarHeader">
            {onCheck && (
              <Field
                name="check"
                value={checked}
                type="checkbox"
                onChange={handleCheckChange}
                className="SectionBarCheckbox"
                disabled={loading}
              />
            )}
            <div className={clsx('SectionBarTitleWrapper', { collapsable, small })}>
              <Typography
                variant="h5"
                className={clsx('SectionBarTitle', { small })}
                onClick={handleToggleCollapsed}
              >
                {title}
                {children && uncollapsable && (collapsed ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
              </Typography>
              {!collapsed && subtitle && (
                <Typography
                  variant="h6"
                  ref={subtitleRef}
                  className={cn(
                    subtitleMinimized ? 'ShowSubTitle' : '',
                    subtitleMinimizedProp ? 'cursor-pointer' : '',
                    '',
                  )}
                  onClick={subtitleMinimizedProp ? handleShowSubtitle : handleToggleCollapsed}
                >
                  {subtitle}
                </Typography>
              )}
            </div>
            {pagination &&
              pagination.count > 0 &&
              !collapsed &&
              (pagination.rowsPerPage ? (
                <TablePagination
                  classes={{
                    root: 'SectionBarToolbarRoot',
                    toolbar: 'SectionBarToolbar',
                  }}
                  component="div"
                  rowsPerPageOptions={[]}
                  {...pagination}
                  rowsPerPage={pagination.rowsPerPage}
                  onPageChange={handleChangePage}
                  backIconButtonProps={{
                    size: 'small',
                    disabled: pagination.disabled || pagination.page <= 0,
                  }}
                  nextIconButtonProps={{
                    size: 'small',
                    disabled:
                      pagination.disabled ||
                      pagination.count <= (pagination.page + 1) * pagination.rowsPerPage,
                  }}
                />
              ) : (
                <Pagination
                  count={pagination.count}
                  page={pagination.page + 1}
                  onChange={(_, page) => pagination.onPageChange(page - 1)}
                  siblingCount={1}
                  boundaryCount={1}
                  variant="outlined"
                  color="primary"
                  className="SectionBarPagination"
                  disabled={pagination.disabled}
                />
              ))}
          </div>
          <div className={'flex flex-col items-center gap-2 py-2 md:flex-row'}>
            {asideContentFirst && asideContent}
            {actions.length > 0 && (
              <Actions
                actions={actions}
                fixed={fixedActions}
                disabled={disabled}
                responsiveColumn={true}
              />
            )}
            {!asideContentFirst && asideContent}
          </div>
        </div>
        {!collapsed && footer && <div className="SectionBarFooter">{footer}</div>}
      </div>
      {!collapsed && children}
    </>
  );
};
