import { type Transaction, TransactionLineItem } from '@kalos/kalos-rpc';
import { createContext, useContext, useMemo } from 'react';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { isMoneyValue } from '../../tools/helpers';

export const costCentersThatRequireJobIds: readonly number[] = [
  51500, 50600, 50800, 51400, 51600, 51900, 52000, 53600, 53700,
];

export type DistributionType = 'even' | 'portioned';

export type ModifiedTransactionLineItem = TransactionLineItem & {
  new?: boolean;
  disableDepartmentPicker?: boolean;
};
type AmountToApplyToLineItem = { amount: number; lineItem: TransactionLineItem };
export type ApplyDistribution = { amount: number; distributionType: DistributionType };
type TransactionLineItemsStore = {
  transaction: Readonly<Transaction>;
  lineItems: ModifiedTransactionLineItem[];
  circumventCostCenterRequired?: boolean;

  setLineItems: (
    updater:
      | ModifiedTransactionLineItem[]
      | ((prevValue: ModifiedTransactionLineItem[]) => ModifiedTransactionLineItem[]),
  ) => void;
  addRemainderLineItem: () => void;
  currentLineItemsAmountSum: () => number;
  isAmountValid: () => boolean;
  isDescriptionsValid: () => boolean;
  isSecondaryAmountNoteValid: () => boolean;
  addValueToAllLineItems: (applyAmounts: AmountToApplyToLineItem[]) => void;
  isOwnersValid: () => boolean;
  isDepartmentsValid: () => boolean;
  isCostCentersValid: () => boolean;
  isJobsIdValid: () => boolean;
  isValidForSave: () => boolean;
  shouldBlockSave: () => boolean;
  isEditing: boolean;
  amountAndDistribution: ApplyDistribution;
  setAmountAndDistribution: (updater: ApplyDistribution) => void;
  setIsEditing: (updater: boolean | ((preValue: boolean) => boolean)) => void;
};

export class LineItemValidator {
  static isCostCenterValid(costCenterId: number): boolean {
    return costCenterId !== 0;
  }
  static isOwnerValid(ownerId: number): boolean {
    return ownerId !== 0;
  }
  static isDescriptionValid(description: string): { error: string; isValid: boolean } {
    if (!description) return { error: 'Please add notes', isValid: false };
    else if (description.length >= 255)
      return { error: 'Notes cannot exceed 255 characters', isValid: false };
    return { error: '', isValid: true };
  }
  static isSecondaryAmountNoteValid(
    secondaryAmountNote: string,
    secondaryAmount: number,
  ): {
    error: string;
    isValid: boolean;
  } {
    if (secondaryAmount !== 0) {
      if (!secondaryAmountNote)
        return { error: 'Please add secondary amount note', isValid: false };
      else if (secondaryAmountNote.length >= 255)
        return { error: 'Secondary amount note cannot exceed 255 characters', isValid: false };
    }
    return { error: '', isValid: true };
  }
  static isDepartmentValid(departmentId: number) {
    return !!departmentId;
  }
  static isJobIdValid(jobId: number): boolean {
    return jobId !== 0;
  }
  static isAmountValid(amount: number): boolean {
    return isMoneyValue(amount) && amount !== 0;
  }
  static isValidForSave({
    lineItems,
    parentTransaction,
  }: {
    parentTransaction: Transaction;
    lineItems: TransactionLineItem[];
  }) {
    const value =
      parentTransaction.amount ===
        Number(lineItems.reduce((acc, li) => acc + li.amount + li.secondaryAmount, 0).toFixed(2)) &&
      lineItems.every((li) => isMoneyValue(li.amount) && li.amount !== 0) &&
      lineItems.every((li) => LineItemValidator.isDescriptionValid(li.description).isValid) &&
      lineItems.every(
        (li) =>
          LineItemValidator.isSecondaryAmountNoteValid(li.secondaryAmountNote, li.secondaryAmount)
            .isValid,
      ) &&
      lineItems.every((li) => LineItemValidator.isOwnerValid(li.ownerId)) &&
      lineItems.every((li) => LineItemValidator.isDepartmentValid(li.departmentId)) &&
      lineItems.every((li) => LineItemValidator.isCostCenterValid(li.costCenterId)); //&&
    //lineItems.every((li) => LineItemValidator.isJobIdValid(li.jobId));

    return value;
  }
}

const createTransactionLineItemsStore = (
  transaction: Transaction,
  circumventCostCenterRequired = false,
) =>
  create<TransactionLineItemsStore>()(
    devtools(
      (set, get) => ({
        transaction,
        lineItems: transaction.transactionLineItems,
        circumventCostCenterRequired,
        setLineItems: (updater) => {
          const currentState = get();
          set({
            ...currentState,
            lineItems: typeof updater === 'function' ? updater(currentState.lineItems) : updater,
          });
        },
        addRemainderLineItem: () => {
          const transaction = get().transaction;
          get().setLineItems((prev) => [
            ...prev,
            {
              ...TransactionLineItem.create({
                id: Date.now(),
                transactionId: transaction.id,
                ownerId: transaction.ownerId,
                costCenterId: transaction.costCenterId,
                jobId: transaction.jobId,
                departmentId: transaction.departmentId,
                amount: Number(
                  (transaction.amount - prev.reduce((acc, li) => acc + li.amount, 0)).toFixed(2),
                ),
              }),
              new: true,
            },
          ]);
        },
        currentLineItemsAmountSum: () => {
          return Number(
            get()
              .lineItems.reduce(
                (acc, lineItem) => acc + lineItem.amount + lineItem.secondaryAmount,
                0,
              )
              .toFixed(2),
          );
        },
        addValueToAllLineItems: (value) => {
          get().setLineItems((prev) => {
            return prev.map((li) => {
              const applyAmount = value.find((v) => v.lineItem.id === li.id);
              if (applyAmount) {
                return {
                  ...li,
                  secondaryAmount: +(li.secondaryAmount + applyAmount.amount).toFixed(2),
                };
              }
              return li;
            });
          });
        },
        shouldBlockSave: () => {
          const { lineItems, circumventCostCenterRequired } = get();
          if (circumventCostCenterRequired) return false;
          return lineItems.some(
            (li) => costCentersThatRequireJobIds.includes(li.costCenterId) && !li.jobId,
          );
        },
        isAmountValid: () => {
          return (
            get().transaction.amount === get().currentLineItemsAmountSum() &&
            get().lineItems.every((li) => LineItemValidator.isAmountValid(li.amount))
          );
        },
        isDepartmentsValid() {
          return get().lineItems.every((li) =>
            LineItemValidator.isDepartmentValid(li.departmentId),
          );
        },
        isDescriptionsValid: () => {
          return get().lineItems.every(
            (li) => LineItemValidator.isDescriptionValid(li.description).isValid,
          );
        },
        isSecondaryAmountNoteValid: () => {
          return get().lineItems.every(
            (li) =>
              LineItemValidator.isSecondaryAmountNoteValid(
                li.secondaryAmountNote,
                li.secondaryAmount,
              ).isValid,
          );
        },
        isOwnersValid() {
          return get().lineItems.every((li) => LineItemValidator.isOwnerValid(li.ownerId));
        },
        isCostCentersValid() {
          return get().lineItems.every((li) =>
            LineItemValidator.isCostCenterValid(li.costCenterId),
          );
        },
        isJobsIdValid() {
          return get().lineItems.every((li) => LineItemValidator.isJobIdValid(li.jobId));
        },
        isValidForSave() {
          return LineItemValidator.isValidForSave({
            parentTransaction: get().transaction,
            lineItems: get().lineItems,
          });
          // &&
          // get().isAmountValid() &&
          // get().isDescriptionsValid() &&
          // get().isOwnersValid() &&
          // get().isDepartmentsValid() &&
          // get().isCostCentersValid() &&
          // get().isJobsIdValid()
        },
        isEditing: false,
        amountAndDistribution: { amount: 0, distributionType: 'even' },
        setAmountAndDistribution: (updater) => {
          const currentState = get();
          set({
            ...currentState,
            amountAndDistribution: updater,
          });
        },
        setIsEditing: (updater) => {
          const currentState = get();
          set({
            ...currentState,
            isEditing: typeof updater === 'function' ? updater(currentState.isEditing) : updater,
          });
        },
      }),
      { name: `TransactionAmountValidationStore-${transaction.id}` },
    ),
  );

const TransactionLineItemsStoreContext = createContext<ReturnType<
  typeof createTransactionLineItemsStore
> | null>(null);

export const useTransactionLineItemsStore = () => {
  const value = useContext(TransactionLineItemsStoreContext);
  if (!value) {
    throw new Error(
      'useAmountValidationStoreContext must be used within a AmountValidationStoreContext.Provider',
    );
  }
  return value;
};

export const withTransactionLineItemsStoreContext = <
  T extends object & { transaction: Transaction; circumventCostCenterRequired?: boolean },
>(
  Component: React.ComponentType<T>,
) => {
  return (props: T) => {
    const store = useMemo(() => {
      return createTransactionLineItemsStore(props.transaction, props.circumventCostCenterRequired);
    }, [props.transaction, props.circumventCostCenterRequired]);

    return (
      <TransactionLineItemsStoreContext.Provider value={store}>
        <Component {...props} />
      </TransactionLineItemsStoreContext.Provider>
    );
  };
};
