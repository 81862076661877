import { type EmailConfig, type SQSEmail } from '@kalos/kalos-rpc';
import { useMutation } from '@tanstack/react-query';

import { EmailClientService } from '../../tools/helpers';

export const useEmailClientSendMutation = () => {
  return useMutation({
    mutationFn: async (req: EmailConfig) => {
      return EmailClientService.sendMail(req);
    },
  });
};

export const useSendSQSEmailMutation = () => {
  return useMutation({
    mutationFn: async (req: SQSEmail) => {
      return EmailClientService.SendSQSMail(req);
    },
  });
};
