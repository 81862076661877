import { BaseClient } from '../BaseClient';
import {
  Driver,
  DriverList,
  type Vehicle,
  type VehicleAsset,
  type VehicleLog,
  VehiclePhoto,
  type VehicleTrailer,
} from '../compiled-protos/vehicle_driver';
import { VehicleDriverServiceClient } from '../compiled-protos/vehicle_driver.client';
import { FileClient } from '../File';
import { S3Client } from '../S3File';

export class VehicleDriverClient extends BaseClient {
  self: VehicleDriverServiceClient;
  s3Client: S3Client;
  fileClient: FileClient
  vehiclePhotoBucketName = 'kalos-vehicle-photo'

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.s3Client = new S3Client(host, userID);
    this.fileClient = new FileClient(host, userID);
    this.self = new VehicleDriverServiceClient(this.transport);
  }

  /** VEHICLE */

  public async CreateVehicle(req: Vehicle) {
    const res = await this.self.createVehicle(req, this.getMetaDataWithoutCache()).response;
    return res;
  }

  public async UpdateVehicle(req: Vehicle) {
    const res = await this.self.updateVehicle(req, this.getMetaDataWithoutCache()).response;
    return res;
  }

  public async DeleteVehicle(req: Vehicle) {
    const res = await this.self.deleteVehicle(req, this.getMetaDataWithoutCache()).response;
    return res;
  }

  public async GetVehicle(req: Vehicle) {
    const res = await this.self.getVehicle(req, this.getMetaDataWithoutCache()).response;
    return res;
  }

  public async BatchGetVehicles(req: Vehicle) {
    const res = await this.self.batchGetVehicle(req, this.getMetaDataWithoutCache()).response;
    return res;
  }

  /** DRIVER */

  public async CreateDriver(req: Driver) {
    let res = Driver.create();
    try {
      res = await this.self.createDriver(req, this.getMetaData()).response;
    } catch (err) {
      console.error(err);
    }
    return res;
  }

  public async UpdateDriver(req: Driver) {
    let res = Driver.create();
    try {
      res = await this.self.updateDriver(req, this.getMetaData()).response;
    } catch (err) {
      console.error(err);
    }
    return res;
  }

  public async DeleteDriver(req: Driver) {
    let res = Driver.create();
    try {
      res = await this.self.deleteDriver(req, this.getMetaData()).response;
    } catch (err) {
      console.error(err);
    }
    return res;
  }

  public async GetDriver(req: Driver) {
    let res = Driver.create();
    try {
      res = await this.self.getDriver(req, this.getMetaData()).response;
    } catch (err) {
      console.error(err);
    }
    return res;
  }

  public async BatchGetDrivers(req: Driver) {
    let res = DriverList.create();
    try {
      res = await this.self.batchGetDriver(req, this.getMetaData()).response;
    } catch (err) {
      console.error(err);
    }
    return res;
  }

  /* VEHICLE PHOTO */
  public async _CreateVehiclePhoto(req: VehiclePhoto) {
    return await this.self.createVehiclePhoto(req, this.getMetaDataWithoutCache()).response;
  }

  public async CreateVehiclePhoto({ file, ...rest }: { file: File } & (Pick<VehiclePhoto, 'vehicleId'> | Pick<VehiclePhoto, 'vehicleTrailerId'>)) {
    const fileKey = 'vehicleId' in rest ? `vehicle-${rest.vehicleId}-${Date.now()}-${file.name}` : `trailer-${rest.vehicleTrailerId}-${Date.now()}-${file.name}`;
    const uploadedFile = await this.fileClient.uploadFileToS3AndCreateFile({
      file,
      fileKey,
      bucket: this.vehiclePhotoBucketName,
    })

    return await this._CreateVehiclePhoto(VehiclePhoto.create({ ...rest, fileId: uploadedFile.id }));
  }

  public async BatchGetVehiclePhotos(req: VehiclePhoto) {
    return await this.self.batchGetVehiclePhoto(req, this.getMetaDataWithoutCache()).response;
  }

  public async DeleteVehiclePhoto(req: VehiclePhoto) {
    const result = await this.self.deleteVehiclePhoto(req, this.getMetaDataWithoutCache()).response;
    this.s3Client.deleteFileFromS3Buckets(req.fileName, req.fileBucket);
    this.fileClient.deleteFileById(req.fileId);
    return result;
  }
  public async UpdateVehiclePhoto(req: VehiclePhoto) {
    const res = await this.self.updateVehiclePhoto(req, this.getMetaDataWithoutCache()).response;
    return res;
  }

  /* VEHICLE TRAILER */
  public async CreateVehicleTrailer(req: VehicleTrailer) {
    const res = await this.self.createVehicleTrailer(req, this.getMetaDataWithoutCache()).response;
    return res;
  }

  public async UpdateVehicleTrailer(req: VehicleTrailer) {
    const res = await this.self.updateVehicleTrailer(req, this.getMetaDataWithoutCache()).response;
    return res;
  }

  public async DeleteVehicleTrailer(req: VehicleTrailer) {
    const res = await this.self.deleteVehicleTrailer(req, this.getMetaDataWithoutCache()).response;
    return res;
  }

  public async GetVehicleTrailer(req: VehicleTrailer) {
    const res = await this.self.getVehicleTrailer(req, this.getMetaDataWithoutCache()).response;
    return res;
  }

  public async BatchGetVehicleTrailers(req: VehicleTrailer) {
    const res = await this.self.batchGetVehicleTrailer(req, this.getMetaDataWithoutCache()).response;
    return res;
  }

  /* VEHICLE ASSET */
  public async CreateVehicleAsset(req: VehicleAsset) {
    const res = await this.self.createVehicleAsset(req, this.getMetaDataWithoutCache()).response;
    return res;
  }

  public async DeleteVehicleAsset(req: VehicleAsset) {
    const res = await this.self.deleteVehicleAsset(req, this.getMetaDataWithoutCache()).response;
    return res;
  }

  public async UpdateVehicleAsset(req: VehicleAsset) {
    const res = await this.self.updateVehicleAsset(req, this.getMetaDataWithoutCache()).response;
    return res;
  }

  public async BatchGetVehicleAsset(req: VehicleAsset) {
    const res = await this.self.batchGetVehicleAsset(req, this.getMetaDataWithoutCache()).response;
    return res;
  }

  /* VEHICLE LOG */
  public async BatchGetVehicleLog(req: VehicleLog) {
    const res = await this.self.batchGetVehicleLog(req, this.getMetaDataWithoutCache()).response;
    return res;
  }
}

