import { type TransactionLineItem } from '@kalos/kalos-rpc';
import {
  Button,
  DataTable,
  DataTableColumnHeader,
  Input,
  Label,
  SimpleSelect,
  toast,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@kalos/ui';
import { Pencil1Icon, PlusCircledIcon, PlusIcon, TrashIcon } from '@radix-ui/react-icons';
import { type ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { type ChangeEvent, useMemo } from 'react';
import { useStore } from 'zustand';

import {
  type DistributionType,
  type ModifiedTransactionLineItem,
  useTransactionLineItemsStore,
} from '../../../../context/TransactionLIneItems';
import { useTransactionLineItemBatchMutation } from '../../../../hooks/react-query/useTransactionLineItem';
import { useEvent } from '../../../../hooks/useEvent';
import { isTruthy } from '../../../../tools/typeguargs';
import { AmountEditHeader, AmountField } from './EditableFields/Amount';
import { CostCenterEditHeader, CostCenterField } from './EditableFields/CostCenter';
import { DepartmentEditHeader, DepartmentField } from './EditableFields/Department';
import { DescriptionEditHeader, DescriptionField } from './EditableFields/Description';
import { JobEditHeader, JobField } from './EditableFields/Job';
import { SecondaryAmountField } from './EditableFields/SecondaryAmount';
import {
  AdditionalAmountNotesEditHeader,
  SecondaryAmountNote,
} from './EditableFields/SecondaryAmountNote';

const staticFn = () => {};
export const TransactionLineItemsTable = ({
  onSave = staticFn,
  readonly = false,
}: {
  onSave?: () => void;
  readonly?: boolean;
}) => {
  const store = useTransactionLineItemsStore();
  const lineItems = useStore(store, (s) => s.lineItems);
  const addRemainderLineItem = useStore(store, (s) => s.addRemainderLineItem);

  const isEditing = useStore(store, (s) => s.isEditing);

  const onSaveEvent = useEvent(onSave);

  const transactionLineItemsColumnDefs = useMemo<ColumnDef<ModifiedTransactionLineItem>[]>(() => {
    return [
      {
        accessorKey: 'jobId',
        meta: {
          className: 'w-60',
        },
        header(props) {
          return (
            <div className="flex items-center gap-1">
              <DataTableColumnHeader title="Job #" column={props.column} />
              <JobEditHeader />
            </div>
          );
        },
        cell(props) {
          return (
            <div className={props.column.columnDef.meta?.className}>
              <JobField transactionLineItem={props.row.original} />
            </div>
          );
        },
      },
      {
        accessorKey: 'costCenterId',
        meta: {
          className: 'w-60',
        },
        header(props) {
          return (
            <div className="flex items-center gap-1">
              <DataTableColumnHeader title="Purchase Category" column={props.column} />
              <CostCenterEditHeader />
            </div>
          );
        },
        cell(props) {
          return (
            <div className={props.column.columnDef.meta?.className}>
              <CostCenterField transactionLineItem={props.row.original} />
            </div>
          );
        },
      },
      {
        accessorKey: 'departmentId',
        meta: {
          className: 'w-60',
        },
        header(props) {
          return (
            <div className="flex items-center gap-1">
              <DataTableColumnHeader title="Department" column={props.column} />
              <DepartmentEditHeader />
            </div>
          );
        },
        cell(props) {
          return (
            <div className={props.column.columnDef.meta?.className}>
              <DepartmentField transactionLineItem={props.row.original} />
            </div>
          );
        },
      },
      {
        accessorKey: 'description',
        meta: {
          className: 'w-40 text-xs overflow-auto',
        },
        header(props) {
          return (
            <div className="flex items-center gap-1">
              <DataTableColumnHeader title="Notes" column={props.column} />
              <DescriptionEditHeader />
            </div>
          );
        },
        cell(props) {
          return (
            <div className={props.column.columnDef.meta?.className}>
              <DescriptionField transactionLineItem={props.row.original} />
            </div>
          );
        },
      },
      {
        accessorKey: 'amount',
        meta: {
          className: 'w-40',
        },
        header(props) {
          return (
            <div className="flex flex-col gap-1">
              <DataTableColumnHeader title="Amount ($)" column={props.column} />
              <AmountEditHeader />
            </div>
          );
        },
        cell(props) {
          return (
            <div className={props.column.columnDef.meta?.className}>
              <AmountField transactionLineItem={props.row.original} />
            </div>
          );
        },
      },
      {
        accessorKey: 'secondary_amount',
        meta: {
          className: 'w-20',
        },
        header(props) {
          return (
            <div className="flex flex-col gap-1">
              <DataTableColumnHeader title="Additional Amount ($)" column={props.column} />
            </div>
          );
        },
        cell(props) {
          return (
            <div className={props.column.columnDef.meta?.className}>
              <SecondaryAmountField transactionLineItem={props.row.original} />
            </div>
          );
        },
      },
      {
        accessorKey: 'secondary_amount_notes',
        meta: {
          className: 'w-40 text-xs overflow-auto',
        },
        header(props) {
          return (
            <div className="flex items-center gap-1">
              <DataTableColumnHeader title="Additional Amount Notes" column={props.column} />
              <AdditionalAmountNotesEditHeader />
            </div>
          );
        },
        cell(props) {
          return (
            <div className={props.column.columnDef.meta?.className}>
              <SecondaryAmountNote transactionLineItem={props.row.original} />
            </div>
          );
        },
      },
      ...(!readonly
        ? [
            {
              id: 'actions',
              meta: {
                className: 'w-30 flex justify-end ml-auto',
              },
              header(props) {
                return (
                  <div className={props.column.columnDef.meta?.className}>
                    {!readonly && <ModeToggle onSave={onSaveEvent} />}
                  </div>
                );
              },
              cell(props) {
                return (
                  <div className={props.column.columnDef.meta?.className}>
                    <TooltipProvider>
                      <DeleteAction lineItemId={props.row.original.id} />
                    </TooltipProvider>
                  </div>
                );
              },
            } satisfies ColumnDef<ModifiedTransactionLineItem>,
          ]
        : []),
    ];
  }, [onSaveEvent, readonly]);

  const lineItemsTable = useReactTable({
    data: lineItems,
    columns: transactionLineItemsColumnDefs,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (lineItem) => lineItem.id.toString(),
    enableSorting: false,
  });

  const onAddLineItem = () => {
    addRemainderLineItem();
  };

  return (
    <div className="flex flex-col gap-4">
      <DataTable table={lineItemsTable} />
      {isEditing && (
        <div className="flex py-2">
          <Button onClick={onAddLineItem} className="mx-auto" size="icon-sm">
            <PlusIcon />
          </Button>
        </div>
      )}
    </div>
  );
};

const getLineItemUpdateFieldMask = ({
  original,
  updated,
}: {
  original: TransactionLineItem;
  updated: TransactionLineItem;
}) => {
  const fieldMask: string[] = [];
  if (original.amount !== updated.amount) {
    fieldMask.push('Amount');
  }
  if (original.description !== updated.description) {
    fieldMask.push('Description');
  }
  if (original.ownerId !== updated.ownerId) {
    fieldMask.push('OwnerId');
  }
  if (original.departmentId !== updated.departmentId) {
    fieldMask.push('DepartmentId');
  }
  if (original.costCenterId !== updated.costCenterId) {
    fieldMask.push('CostCenterId');
  }
  if (original.jobId !== updated.jobId) {
    fieldMask.push('JobId');
  }
  if (original.secondaryAmount !== updated.secondaryAmount) {
    fieldMask.push('SecondaryAmount');
  }
  if (original.secondaryAmountNote !== updated.secondaryAmountNote) {
    fieldMask.push('SecondaryAmountNote');
  }
  return fieldMask;
};

export const wasTransactionLineItemUpdated = ({
  original,
  updated,
}: {
  original: TransactionLineItem[];
  updated: TransactionLineItem;
}) => {
  const matchedOriginalLineItem = original.find((oli) => oli.id === updated.id);
  if (!matchedOriginalLineItem) {
    return true;
  }
  return (
    getLineItemUpdateFieldMask({
      original: matchedOriginalLineItem,
      updated,
    }).length !== 0
  );
};

const CancelButton = () => {
  const store = useTransactionLineItemsStore();
  const setLineItems = useStore(store, (s) => s.setLineItems);
  const originalLineItems = useStore(store, (s) => s.transaction.transactionLineItems);
  const lineItems = useStore(store, (s) => s.lineItems);
  const setEditing = useStore(store, (s) => s.setIsEditing);

  const onClick = () => {
    if (
      lineItems.some((li) =>
        wasTransactionLineItemUpdated({ original: originalLineItems, updated: li }),
      ) &&
      !confirm('Are you sure you want to cancel? You have unsaved changes!')
    ) {
      return;
    }

    setEditing(false);
    setLineItems(originalLineItems);
  };

  return (
    <Button onClick={onClick} size="sm" variant="secondary">
      Cancel
    </Button>
  );
};

const SaveButton = ({ onSave }: { onSave: () => void }) => {
  const store = useTransactionLineItemsStore();
  const setEditing = useStore(store, (s) => s.setIsEditing);
  const lineItems = useStore(store, (s) => s.lineItems);
  const originalLineItems = useStore(store, (s) => s.transaction.transactionLineItems);

  const isDisabled = useStore(store, (s) => s.shouldBlockSave());

  const batchUpdateMutation = useTransactionLineItemBatchMutation();

  const handleSave = async () => {
    if (lineItems.length > 0 && lineItems.every((li) => li.description.length > 255)) {
      toast({
        variant: 'destructive',
        title: 'Description cannot be more than 255 characters!',
      });
      return;
    }

    if (!lineItems.every((li) => li.amount !== 0)) {
      toast({
        variant: 'destructive',
        title: 'Amount cannot be 0!',
      });
      return;
    }

    if (lineItems.length > 0 && lineItems.every((li) => li.secondaryAmountNote.length > 255)) {
      toast({
        variant: 'destructive',
        title: 'Additional Amount Notes cannot be more than 255 characters!',
      });
      return;
    }

    if (
      !store.getState().isValidForSave() &&
      !confirm(
        "Are you sure you want to save line items in an invalid state? Transaction with invalid line items won't be applicable for submission!",
      )
    ) {
      return;
    }

    const newLineItems = lineItems
      .filter((li) => li.new)
      .map((li) => {
        li.id = 0;
        // to reset given random id and prevent issues during creation
        return li;
      });
    const deletedLineItems = originalLineItems.filter(
      (oli) => !lineItems.find((li) => li.id === oli.id),
    );
    const updatedLineItems = lineItems
      .filter((li) => !li.new && !deletedLineItems.find((dli) => dli.id === li.id))
      .map((li) => {
        const originalLineItem = originalLineItems.find((oli) => oli.id === li.id);
        if (!originalLineItem) {
          throw new Error('Original line item not found');
        }
        const fieldMask = getLineItemUpdateFieldMask({
          original: originalLineItem,
          updated: li,
        });

        if (!fieldMask.length) {
          return null;
        }
        li.fieldMask = fieldMask;
        return li;
      })
      .filter(isTruthy);

    // if there are any updates
    if (!(!newLineItems.length && !deletedLineItems.length && !updatedLineItems.length)) {
      try {
        await batchUpdateMutation.mutateAsync({
          lineItemsToCreate: newLineItems,
          lineItemsToDelete: deletedLineItems,
          lineItemsToUpdate: updatedLineItems,
        });
        onSave();
      } catch {
        toast({
          variant: 'destructive',
          title: 'Failed to update line items',
        });
        return;
      }

      toast({
        variant: 'success',
        title: 'Successfully updated line items!',
      });
    }

    setEditing(false);
  };

  return (
    <Button
      onClick={handleSave}
      isLoading={batchUpdateMutation.isPending}
      disabled={batchUpdateMutation.isPending || isDisabled}
      size="sm"
    >
      Save
    </Button>
  );
};
const AddToAllLineItemsButton = () => {
  const store = useTransactionLineItemsStore();
  const addValueToAllLineItems = useStore(store, (s) => s.addValueToAllLineItems);
  const setEditing = useStore(store, (s) => s.setIsEditing);
  const { amount, distributionType } = useStore(store, (s) => s.amountAndDistribution);
  const setAmountAndDistribution = useStore(store, (s) => s.setAmountAndDistribution);
  const lineItems = useStore(store, (s) => s.lineItems);

  const totalAmount = useStore(store, (s) => s.transaction).amount;
  const amountToApplyList = lineItems.map((li) => ({ amount: amount, lineItem: li }));

  if (distributionType !== 'even') {
    amountToApplyList.forEach((item) => {
      const percentage = item.lineItem.amount / totalAmount;
      item.amount = parseFloat((amount * percentage).toFixed(2));
    });
  }

  const onClick = () => {
    addValueToAllLineItems(amountToApplyList);
    setEditing(true);
  };

  const handleAmountChange = (e: ChangeEvent<HTMLInputElement>) => {
    const amount = parseFloat(e.target.value) || 0;
    setAmountAndDistribution({ amount: amount, distributionType });
  };

  const handleDistributionChange = (e: DistributionType) => {
    setAmountAndDistribution({ amount, distributionType: e });
  };

  return (
    <div className="space-y-1">
      <Label>Distribution Amount and Type</Label>
      <div className="flex flex-1 flex-col gap-1">
        <Input
          className="flex items-center "
          type="number"
          value={amount}
          onChange={handleAmountChange}
          placeholder="Enter amount"
        />
        <SimpleSelect
          selectedValue={distributionType}
          values={[
            { value: 'even', label: 'Even' },
            { value: 'portioned', label: 'Proportional' },
          ]}
          onChange={handleDistributionChange}
        />
      </div>
      <div>
        <Button
          onClick={onClick}
          className="mt-2 flex items-center gap-1"
          size="sm"
          variant="secondary"
        >
          <span>Add Amount to All Secondary Amounts</span>
          <PlusCircledIcon />
        </Button>
      </div>
    </div>
  );
};

const AddRemainderButton = () => {
  const store = useTransactionLineItemsStore();
  const addRemainderLineItem = useStore(store, (s) => s.addRemainderLineItem);
  const setEditing = useStore(store, (s) => s.setIsEditing);

  const onClick = () => {
    addRemainderLineItem();
    setEditing(true);
  };

  return (
    <Button onClick={onClick} className="flex items-center gap-1" size="sm" variant="secondary">
      <span>Add Remainder</span>
      <PlusCircledIcon />
    </Button>
  );
};

const ModeToggle = ({ onSave }: { onSave: () => void }) => {
  const store = useTransactionLineItemsStore();
  const isEditing = useStore(store, (s) => s.isEditing);
  const setIsEditing = useStore(store, (s) => s.setIsEditing);
  const lineItemLength = useStore(store, (s) => s.lineItems.length);
  const isAmountValid = useStore(store, (s) => s.isAmountValid());
  {
    !isAmountValid && <AddRemainderButton />;
  }

  return (
    <div className="flex flex-wrap items-center justify-center gap-1 py-2 md:py-4">
      <div className="flex-1">
        {isAmountValid === false && lineItemLength > 0 && <AddToAllLineItemsButton />}
      </div>
      <div className="flex-1">{isAmountValid === false && <AddRemainderButton />}</div>
      {isEditing ? (
        <>
          <CancelButton />
          <SaveButton onSave={onSave} />
        </>
      ) : (
        <Button size="icon" onClick={() => setIsEditing(true)}>
          <Pencil1Icon />
        </Button>
      )}
    </div>
  );
};

const DeleteAction = ({ lineItemId }: { lineItemId: TransactionLineItem['id'] }) => {
  const store = useTransactionLineItemsStore();
  const isEditing = useStore(store, (s) => s.isEditing);
  const setLineItems = useStore(store, (s) => s.setLineItems);

  const onDeleteClick = () => {
    setLineItems((prevLineItems) => prevLineItems.filter((li) => li.id !== lineItemId));
  };

  return (
    <Tooltip>
      <TooltipContent>{isEditing ? 'Delete' : 'Please start editing to delete'}</TooltipContent>
      <TooltipTrigger asChild>
        <span>
          <Button size="icon" onClick={onDeleteClick} disabled={!isEditing} variant="secondary">
            <TrashIcon />
          </Button>
        </span>
      </TooltipTrigger>
    </Tooltip>
  );
};
