import { SpiffOption } from '@kalos/kalos-rpc';
import { Dialog, DialogContent, DialogTitle, DialogTrigger, toast } from '@kalos/ui';
import { useCallback, useMemo, useState } from 'react';

import { useSpiffOptionUpdateMutation } from '../../../../../hooks/react-query/useTaskClientServiceQuery';
import { getFieldMaskFromDirtyField } from '../../../EmployeeDepartments/EditEmployeeFunction';
import { SpiffOptionForm } from './SpiffOptionForm';

export const EditSpiffOptionDialog = ({
  trigger,
  entry,
}: {
  trigger: React.ReactNode;
  entry: SpiffOption;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const defaultFormValues = useMemo<React.ComponentProps<typeof SpiffOptionForm>['defaultValues']>(
    () => ({
      amount: entry.amount,
      code: entry.code,
      eventId: entry.eventId,
      name: entry.name,
    }),
    [entry],
  );

  const updateSpiffOptionMutation = useSpiffOptionUpdateMutation();

  const onSubmit = useCallback<React.ComponentProps<typeof SpiffOptionForm>['onSubmit']>(
    async ({ data, dirtyFields }) => {
      if (Object.keys(dirtyFields).length === 0) {
        setIsOpen(false);
        return;
      }

      try {
        await updateSpiffOptionMutation.mutateAsync(
          SpiffOption.create({
            ...data,
            id: entry.id,
            fieldMask: getFieldMaskFromDirtyField(dirtyFields),
          }),
        );
        toast({
          variant: 'success',
          title: 'Success',
          description: 'Piece Work Updated',
        });
        setIsOpen(false);
      } catch (error) {
        toast({
          variant: 'destructive',
          title: 'Error',
          description: 'Something went wrong during update',
        });
        console.error(error);
      }
    },
    [entry.id, updateSpiffOptionMutation],
  );

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className="@container">
        <DialogTitle>Edit Piece Work</DialogTitle>
        <SpiffOptionForm
          loading={updateSpiffOptionMutation.isPending}
          disabled={updateSpiffOptionMutation.isPending}
          onSubmit={onSubmit}
          disableJobIdPicker
          defaultValues={defaultFormValues}
        />
      </DialogContent>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
    </Dialog>
  );
};
