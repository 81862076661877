import { type EventDetails, JobAdmin, JobAdminList, JobNumberRequest } from '@kalos/kalos-rpc';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { EventClientService } from '../../tools/helpers';
import { queryKeys } from './constants';
import { type EntityFilter } from './utils';

export type JobAdminFilter = EntityFilter<JobAdmin>;
export const useJobAdminsListQuery = ({
  filter = {},
  enabled,
}: {
  filter?: JobAdminFilter;
  enabled?: boolean;
}) =>
  useQuery({
    queryKey: [
      queryKeys.eventDetails.root,
      queryKeys.eventDetails.jobAdmins,
      queryKeys.eventDetails.jobAdminsList,
      queryKeys.eventDetails.getJobAdminHash(filter),
    ],
    queryFn: async () => await EventClientService.BatchGetJobAdmin(JobAdmin.create(filter)),
    enabled,
  });

export const useCreateJobAminMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (jobAdmin: JobAdmin) => EventClientService.CreateJobAdmin(jobAdmin),
    onSuccess: (createdJobAdmin) => {
      queryClient.setQueriesData<JobAdminList>(
        {
          queryKey: [
            queryKeys.eventDetails.root,
            queryKeys.eventDetails.jobAdmins,
            queryKeys.eventDetails.jobAdminsList,
          ],
        },
        (cache) => {
          if (cache && cache.results.find((el) => el.jobNumber === createdJobAdmin.jobNumber)) {
            return JobAdminList.create({
              totalCount: cache.totalCount + 1,
              results: cache.results.concat(createdJobAdmin),
            });
          }
        },
      );
      queryClient.invalidateQueries({
        queryKey: [queryKeys.eventDetails.root],
      });
    },
  });
};

export const useUpdateJobAdminMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (jobAdmin: JobAdmin) => EventClientService.UpdateJobAdmin(jobAdmin),
    onSuccess: (updatedAdmin) => {
      queryClient.setQueriesData<JobAdminList>(
        {
          queryKey: [
            queryKeys.eventDetails.root,
            queryKeys.eventDetails.jobAdmins,
            queryKeys.eventDetails.jobAdminsList,
          ],
        },
        (cache) => {
          if (cache && cache.results.find((el) => el.id === updatedAdmin.id)) {
            return JobAdminList.create({
              totalCount: cache.totalCount,
              results: cache.results.map((el) => (el.id === updatedAdmin.id ? updatedAdmin : el)),
            });
          }
        },
      );
      queryClient.invalidateQueries({
        queryKey: [queryKeys.eventDetails.root],
      });
    },
  });
};

export const useDeleteJobAdminMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (jobAdmin: JobAdmin) => EventClientService.DeleteJobAdmin(jobAdmin),
    onSuccess: (deletedJobAdmin) => {
      queryClient.setQueriesData<JobAdminList>(
        {
          queryKey: [
            queryKeys.eventDetails.root,
            queryKeys.eventDetails.jobAdmins,
            queryKeys.eventDetails.jobAdminsList,
          ],
        },
        (cache) => {
          if (cache && cache.results.find((el) => el.id === deletedJobAdmin.id)) {
            return JobAdminList.create({
              totalCount: cache.totalCount - 1,
              results: cache.results.filter((el) => el.id !== deletedJobAdmin.id),
            });
          }
        },
      );
      queryClient.invalidateQueries({
        queryKey: [queryKeys.eventDetails.root],
      });
    },
  });
};

export type EventDetailsFilters = EntityFilter<JobNumberRequest>;
export const useEventDetailsQuery = <TSelect = EventDetails,>({
  filter = {},
  select,
}: {
  filter?: EventDetailsFilters;
  select?: (data: EventDetails) => TSelect;
}) => {
  return useQuery({
    queryKey: [queryKeys.eventDetails.root, queryKeys.eventDetails.getEventDetailsHash(filter)],
    queryFn: async () => {
      return await EventClientService.GetEventDetails(JobNumberRequest.create(filter));
    },
    select,
    refetchOnMount: true,
  });
};
