import { BaseClient } from '../BaseClient';
import {
  PaidTimeOff,
  PaidTimeOffList,
  PTO,
} from '../compiled-protos/timesheet_line';
import { TimesheetLineServiceClient as PaidTimeOffServiceClient } from '../compiled-protos/timesheet_line.client';

class PaidTimeOffClient extends BaseClient {
  self: PaidTimeOffServiceClient;

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new PaidTimeOffServiceClient(this.transport);
  }

  public async Create(req: PaidTimeOff) {
    let res = PaidTimeOff.create();
    try {
      res = await this.self.createPaidTimeOff(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Get(req: PaidTimeOff) {
    let res = PaidTimeOff.create();
    try {
      res = await this.self.getPaidTimeOff(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Update(req: PaidTimeOff) {
    let res = PaidTimeOff.create();
    try {
      res = await this.self.updatePaidTimeOff(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Delete(req: PaidTimeOff) {
    let res = PaidTimeOff.create();
    try {
      res = await this.self.deletePaidTimeOff(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async BatchGet(req: PaidTimeOff) {
    let res = PaidTimeOffList.create();
    try {
      res = await this.self.batchGetPaidTimeOff(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }
}

export { PaidTimeOff, PaidTimeOffList, PaidTimeOffClient, PTO };
