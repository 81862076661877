import { BaseClient } from '../BaseClient';
import { Memo, type MemoClassification, MemoComment, MemoCommentList, MemoCommentLog, MemoCommentLogList, MemoList, type MemoStatus } from '../compiled-protos/memo';
import { MemoServiceClient } from '../compiled-protos/memo.client';

class MemoClient extends BaseClient {
  self: MemoServiceClient;

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new MemoServiceClient(this.transport);
  }

  public async Create(req: Memo) {
    let res = Memo.create();
    try {
      res = await this.self.create(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Get(req: Memo) {
    let res = Memo.create();
    try {
      res = await this.self.get(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Update(req: Memo) {
    let res = Memo.create();
    try {
      res = await this.self.update(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Delete(req: Memo) {
    let res = Memo.create();
    try {
      res = await this.self.delete(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async BatchGet(req: Memo) {
    let res = MemoList.create();
    try {
      res = await this.self.batchGet(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async CreateMemoComment(req: MemoComment) {
    let res = MemoComment.create();
    try {
      res = await this.self.createMemoComment(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async GetMemoComment(req: MemoComment) {
    let res = MemoComment.create();
    try {
      res = await this.self.getMemoComment(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async UpdateMemoComment(req: MemoComment) {
    let res = MemoComment.create();
    try {
      res = await this.self.updateMemoComment(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async DeleteMemoComment(req: MemoComment) {
    let res = MemoComment.create();
    try {
      res = await this.self.deleteMemoComment(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async BatchGetMemoComment(req: MemoComment) {
    let res = MemoCommentList.create();
    try {
      const md = this.getMetaData();
      Object.assign(md, { "cache-control": "no-cache" });
      res = await this.self.batchGetMemoComment(req, md).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async CreateMemoCommentLog(req: MemoCommentLog) {
    let res = MemoCommentLog.create();
    try {
      res = await this.self.createMemoCommentLog(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async GetMemoCommentLog(req: MemoCommentLog) {
    let res = MemoCommentLog.create();
    try {
      res = await this.self.getMemoCommentLog(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async UpdateMemoCommentLog(req: MemoCommentLog) {
    let res = MemoCommentLog.create();
    try {
      res = await this.self.updateMemoCommentLog(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async DeleteMemoCommentLog(req: MemoCommentLog) {
    let res = MemoCommentLog.create();
    try {
      res = await this.self.deleteMemoCommentLog(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async BatchGetMemoCommentLog(req: MemoCommentLog) {
    let res = MemoCommentLogList.create();
    try {
      const md = this.getMetaData();
      Object.assign(md, { "cache-control": "no-cache" });
      res = await this.self.batchGetMemoCommentLog(req, md).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async BatchGetMemoStatus(req: MemoStatus) {
    const md = this.getMetaData();
    Object.assign(md, { "cache-control": "no-cache" });
    return await this.self.batchGetMemoStatus(req, md).response;
  }

  public async BatchGetMemoCategory(req: MemoClassification) {
    const md = this.getMetaData();
    Object.assign(md, { "cache-control": "no-cache" });
    return await this.self.batchGetMemoCategory(req, md).response;
  }

  /*
  private activeStreams: { [id: string]: grpc.ClientReadableStream<MemoComment> } = {};

  public MemoCommentListStream(req: MemoComment, id: string) {
    let stream : grpc.ClientReadableStream<MemoComment>;
    try {
      stream = this.self.memoCommentListStream(req, this.getMetaData());
      console.log(stream);
      this.activeStreams[id] = stream;
      return stream;
    } catch (err) {
      console.log(err);
      return;
    }
  }

  public closeStream(id: string) {
    if (this.activeStreams[id]) {
      this.activeStreams[id].cancel();
      delete this.activeStreams[id];
    }
  }
  */
}

export { Memo, MemoList, MemoComment, MemoCommentList, MemoCommentLog, MemoCommentLogList, MemoClient };
