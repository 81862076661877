import { BaseClient } from '../BaseClient';
import { Bool, Empty, type Int32 , String$ } from '../compiled-protos/common';
import {
  GoogleUser,
  GoogleUserList,
  OAUTHRequest,
} from '../compiled-protos/google';
import { GoogleServiceClient as GoogleUserServiceClient } from '../compiled-protos/google.client';

class GoogleUserClient extends BaseClient {
  self: GoogleUserServiceClient;

  constructor(host: string) {
    super(host);
    this.self = new GoogleUserServiceClient(this.transport);
  }

  public async Create(req: GoogleUser) {
    let res = GoogleUser.create();
    try {
      res = await this.self.createGoogleUser(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Get(req: GoogleUser) {
    let res = GoogleUser.create();
    try {
      res = await this.self.getGoogleUser(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Update(req: GoogleUser) {
    let res = GoogleUser.create();
    try {
      res = await this.self.updateGoogleUser(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Delete(req: GoogleUser) {
    let res = Empty.create();
    try {
      res = await this.self.deleteGoogleUser(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async BatchGet(req: GoogleUser) {
    let res = GoogleUserList.create();
    try {
      res = await this.self.batchGetGoogleUser(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async AuthenticateToken(req: Int32) {
    let res = Bool.create();
    try {
      res = await this.self.authenticateToken(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async GetAuthURL() {
    const req = OAUTHRequest.create();
    let res = String$.create();
    try {
      res = await this.self.getOAUTHURL(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async GetAuthToken(code: string, userId = 0) {
    const req = OAUTHRequest.create({ authCode: code, userId });
    let res = String$.create();
    try {
      res = await this.self.getOAUTHToken(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res.value;
  }
}

export { GoogleUser, GoogleUserList, GoogleUserClient };
