import { QuotableRead } from '@kalos/kalos-rpc';
import { useQuery } from '@tanstack/react-query';

import { EventClientService } from '../../tools/helpers';
import { queryKeys } from './constants';
import { type EntityFilter } from './utils';

export type EventQuotesFilter = EntityFilter<QuotableRead>;
export const useEventQuotesQuery = ({
  filter = {},
  enabled,
}: {
  filter?: EventQuotesFilter;
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: [
      queryKeys.eventQuotes.root,
      queryKeys.eventQuotes.list,
      queryKeys.eventQuotes.getHash(filter),
    ],
    queryFn: async () => {
      return await EventClientService.ReadQuotes(QuotableRead.create(filter));
    },
    enabled,
  });
};
