import { Inspection } from '@kalos/kalos-rpc';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  DataTable,
  DataTableColumnHeader,
  Skeleton,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@kalos/ui';
import { Pencil1Icon, PlusCircledIcon, TrashIcon } from '@radix-ui/react-icons';
import { useQueryClient } from '@tanstack/react-query';
import { type ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useCallback, useMemo } from 'react';

import { queryKeys } from '../../../../../hooks/react-query/constants';
import {
  useDeleteInspectionMutation,
  usePermitGetQuery,
} from '../../../../../hooks/react-query/usePermits';
import { formatBackendDate } from '../../../../../tools/helpers';
import { InspectionStatusBadge } from '../../../components/InspectionStatusBadge';
import { InspectionCreateDialog } from './components/InspectionCreateDialog';
import { InspectionEditDialog } from './components/InspectionEditDialog';
import { PermitDocumentCreateDialog } from './components/PermitDocumentCreateDialog';
import { PermitDocuments } from './components/PermitDocuments';

const staticInspections = Array.from({ length: 3 }, (_, i) => Inspection.create());
export const PermitDetails = ({ permitId }: { permitId: number }) => {
  const permitQuery = usePermitGetQuery({
    filter: {
      id: permitId,
    },
  });

  const inspections = useMemo(() => {
    return permitQuery.data?.inspections ?? [];
  }, [permitQuery.data?.inspections]);

  const inspectionTableColumns = useMemo<ColumnDef<Inspection>[]>(() => {
    return [
      {
        accessorKey: 'statusDescription',
        header(props) {
          return <DataTableColumnHeader column={props.column} title="Status" />;
        },
        meta: {
          className: 'w-20',
        },
        cell: ({ row, column }) => (
          <div className={column.columnDef.meta?.className}>
            <InspectionStatusBadge inspectionStatusName={row.original.statusDescription} />
          </div>
        ),
      },
      {
        accessorKey: 'notes',
        header(props) {
          return <DataTableColumnHeader column={props.column} title="Notes" />;
        },
        meta: {
          className: 'w-40 lg:w-96 text-sm',
        },
        cell: ({ row, column }) => (
          <div className={column.columnDef.meta?.className}>{row.original.notes}</div>
        ),
      },
      {
        id: 'inspectionType',
        header: 'Type',
        cell: ({ row }) => <div>{row.original.inspectionType}</div>,
      },
      {
        id: 'scheduledDate',
        header: 'Requestor Scheduled Date',
        cell: ({ row }) => <div>{formatBackendDate(row.original.requestorScheduledDate)}</div>,
      },
      {
        id: 'scheduledDate',
        header: 'Scheduled Date',
        cell: ({ row }) => <div>{formatBackendDate(row.original.scheduledDate)}</div>,
      },
      {
        id: 'actions',
        meta: {
          className: 'w-20 flex gap-1 items-center justify-center',
        },
        cell: ({ row, column }) => (
          <div className={column.columnDef.meta?.className}>
            <PermitInspectionActions inspection={row.original} />
          </div>
        ),
      },
    ];
  }, []);

  const loadingColumns = useMemo<ColumnDef<Inspection>[]>(() => {
    return inspectionTableColumns.map((column) => ({
      ...column,
      cell: ({ column }) => <Skeleton className={column.columnDef.meta?.className} />,
    }));
  }, [inspectionTableColumns]);

  const table = useReactTable({
    data: permitQuery.isLoading ? staticInspections : inspections,
    columns: permitQuery.isLoading ? loadingColumns : inspectionTableColumns,
    getCoreRowModel: getCoreRowModel(),
    enableSorting: false,
  });

  return (
    <div className="relative flex h-full flex-col gap-4 pb-10">
      <Card>
        <CardHeader className="flex-row justify-between space-y-0">
          <CardTitle>Documents</CardTitle>

          <PermitDocumentCreateDialog
            permitId={permitId}
            trigger={
              <Button size="icon-sm">
                <PlusCircledIcon />
              </Button>
            }
          />
        </CardHeader>
        <CardContent>
          <PermitDocuments documents={permitQuery.data?.permitDocuments ?? []} />
        </CardContent>
      </Card>

      <Card className="relative flex h-full flex-col">
        <CardHeader className="flex-row justify-between space-y-0">
          <CardTitle>Inspections</CardTitle>

          <InspectionCreateDialog
            permitId={permitId}
            trigger={
              <Button size="icon-sm">
                <PlusCircledIcon />
              </Button>
            }
          />
        </CardHeader>
        <CardContent className="relative flex-auto overflow-y-auto px-0 pb-0">
          <DataTable table={table} />
        </CardContent>
      </Card>
    </div>
  );
};

const PermitInspectionActions = ({ inspection }: { inspection: Inspection }) => {
  const queryClient = useQueryClient();
  const deleteMutation = useDeleteInspectionMutation();
  const onDeleteClick = useCallback(() => {
    if (confirm('Are you sure you want to delete this inspection?')) {
      deleteMutation.mutate(inspection);
      queryClient.invalidateQueries({
        queryKey: [queryKeys.permitGroups.root],
      });
      queryClient.invalidateQueries({
        queryKey: [queryKeys.permits.root],
      });
    }
  }, [deleteMutation, inspection, queryClient]);

  return (
    <TooltipProvider>
      <Tooltip>
        <InspectionEditDialog
          inspection={inspection}
          trigger={
            <TooltipTrigger asChild>
              <Button variant="outline" size="icon" disabled={deleteMutation.isPending}>
                <Pencil1Icon />
              </Button>
            </TooltipTrigger>
          }
        />
        <TooltipContent>Edit Inspections</TooltipContent>
      </Tooltip>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            variant="outline"
            size="icon"
            disabled={deleteMutation.isPending}
            isLoading={deleteMutation.isPending}
            onClick={onDeleteClick}
          >
            <TrashIcon />
          </Button>
        </TooltipTrigger>
        <TooltipContent>Delete Inspections</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
