import { Button, FormControl, FormField, FormItem, FormLabel, Input } from '@kalos/ui';

import { useUserSearchFormContext } from './utils';

const CustomerSearchForm = () => {
  const form = useUserSearchFormContext();
  return (
    // intentionally ignored as values are used as they are typed in
    <form
      className="grid grid-cols-2 place-content-center gap-x-4 gap-y-3 md:grid-cols-3 lg:grid-cols-[repeat(5,minmax(0,1fr))_5rem]"
      onSubmit={form.handleSubmit(console.log)}
    >
      <FormField
        control={form.control}
        name="firstname"
        render={({ field }) => (
          <FormItem>
            <FormLabel>First Name</FormLabel>
            <FormControl>
              <Input {...field} />
            </FormControl>
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="lastname"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Last Name</FormLabel>
            <FormControl>
              <Input {...field} />
            </FormControl>
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="businessname"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Business Name</FormLabel>
            <FormControl>
              <Input {...field} />
            </FormControl>
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="phone"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Phone Number</FormLabel>
            <FormControl>
              <Input {...field} />
            </FormControl>
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="email"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Email</FormLabel>
            <FormControl>
              <Input {...field} />
            </FormControl>
          </FormItem>
        )}
      />
      <Button
        type="reset"
        variant="outline"
        className="mt-auto max-w-max"
        onClick={() => form.reset()}
      >
        Reset
      </Button>
    </form>
  );
};

export default CustomerSearchForm;
