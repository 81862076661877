import { type Inspection } from '@kalos/kalos-rpc';
import { Badge } from '@kalos/ui';
import { type ComponentProps } from 'react';

const permitStatusesBadgeVariantsMapping: Record<
  Inspection['statusDescription'],
  ComponentProps<typeof Badge>['variant']
> = {
  pending: 'warning',
  pass: 'success',
  fail: 'destructive',
};

export const InspectionStatusBadge = ({
  inspectionStatusName,
}: {
  inspectionStatusName: Inspection['statusDescription'];
}) => {
  return (
    <Badge
      className="break-before-avoid"
      variant={permitStatusesBadgeVariantsMapping[inspectionStatusName] || 'warning'}
    >
      {inspectionStatusName}
    </Badge>
  );
};
