import { Document, File } from '@kalos/kalos-rpc';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  type SelectChangeEvent,
  TextField,
} from '@mui/material';
import { useState } from 'react';

import {
  DocumentClientService,
  FileClientService,
  PDFClientService,
  timestamp,
} from '../../../tools/helpers';
import { Loader } from '../../Loader/main';
import { Alert } from '../Alert';
import { Button } from '../Button';

type Area = {
  l: number;
  w: number;
  h: number;
};
type StringDimension = {
  value1: string;
  value2: string;
};
export interface Props {
  propertyId: number;
  jobNumber: number;
  onCreateDoc?: () => void;
}
type SelectedItem = {
  checked: boolean;
  displayName: string;
};
type SellSheetType = {
  systemDescription: string;
  systemType: string;
  heatStrip: string;
  heatStripSize: string;
  condensorTonnage: number;
  copperSize: StringDimension;
  copperType: string;

  AHLocation: string;
  AHDimensions: Area[];
  selectedItems: {
    thermostat: SelectedItem;
    copperLineset: SelectedItem;
    pad: SelectedItem;
    returnBox: SelectedItem;
    filterBackReturnGrill: SelectedItem;
    outdoorHighVoltageDisconnect: SelectedItem;
    comboSmokeDetectorCOAlarm: SelectedItem;
    lowLevelCOMonitor: SelectedItem;
    noneOfTheAbove: SelectedItem;
  };
  filterLocation: string;
  smokeDetector: string;
  voltage: string;
  phaseVoltage: string;
  wireSize: string;
  filterSize: string;
  breakerType: string;
  newDisconnectNeeded: string;
  disconnectType: string;
  disconnectAmps: string;
  breakerDimensions: StringDimension[];
  staticReadings: StringDimension[];
  ductType: string;
  ductSize: StringDimension[];
  zoning: string;
  zones: string;
  panReplacement: string;
  AHLiftNeeded: string;
  craneNeeded: string;
  craneDistance: string;
  newThermostat: string;
  thermostatType: string;
};

type Errors = {
  systemDescription?: string;
  systemType?: string;
  selectedItems?: string;
};

const checkFormIsValid = (form: SellSheetType): Errors => {
  const errors: Errors = {};
  if (!form.systemDescription.length) errors.systemDescription = 'This field is required';
  if (form.systemType === 'Choose One') errors.systemType = 'This field is required';
  if (!Object.values(form.selectedItems).some((item) => item))
    errors.selectedItems = 'Please select any needs';
  return errors;
};

export const SellSheetCommercial: React.FC<Props> = ({ propertyId, jobNumber, onCreateDoc }) => {
  const [form, setForm] = useState<SellSheetType>({
    systemDescription: '',
    systemType: 'Choose One',
    heatStrip: 'No',
    heatStripSize: '',
    AHLocation: 'Choose One',
    copperSize: { value1: '', value2: '' },
    condensorTonnage: 0,
    copperType: 'Hard',
    AHDimensions: [{ l: 0, w: 0, h: 0 }],
    selectedItems: {
      thermostat: { checked: false, displayName: 'Thermostat' },
      copperLineset: { checked: false, displayName: 'Copper Lineset' },
      pad: { checked: false, displayName: 'Pad' },
      returnBox: { checked: false, displayName: 'Return Box' },
      filterBackReturnGrill: { checked: false, displayName: 'Filter Back Return Grill' },
      outdoorHighVoltageDisconnect: {
        checked: false,
        displayName: 'Outdoor High Voltage Disconnect',
      },
      comboSmokeDetectorCOAlarm: { checked: false, displayName: 'Combo Smoke Detector/CO Alarm' },
      lowLevelCOMonitor: { checked: false, displayName: 'Low Level CO Monitor' },
      noneOfTheAbove: { checked: false, displayName: '(none of the above)' },
    },
    filterLocation: 'Choose One',
    smokeDetector: 'No',
    wireSize: '',
    newThermostat: 'No',
    newDisconnectNeeded: 'No',
    AHLiftNeeded: 'No',
    disconnectType: '',
    disconnectAmps: '',
    thermostatType: '',
    filterSize: '',
    voltage: 'Single Phase',
    phaseVoltage: '',
    breakerType: 'Choose One',
    breakerDimensions: [{ value1: '', value2: '' }],
    staticReadings: [{ value1: '', value2: '' }],
    ductType: 'Choose One',
    ductSize: [{ value1: '', value2: '' }],
    zoning: 'Choose One',
    zones: 'Choose One',
    panReplacement: 'No',
    craneNeeded: 'No',
    craneDistance: '0',
  });
  const [message, setMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<Errors>({});

  const createMyDocument = async (formData: SellSheetType) => {
    const selectedItems = Object.entries(form.selectedItems)
      .filter(([key, value]) => value.checked)
      .map(([key, value]) => value.displayName)
      .join(', ');

    const content = `
    <div style="font-family: Arial, sans-serif; margin: 0 auto; width: 600px;">
      <h1 style="text-align: center;">AC Quote</h1>
      <table style="width: 100%; border-collapse: collapse;">
        <tr>
          <td style="border: 1px solid #000; padding: 10px;">System Description</td>
          <td style="border: 1px solid #000; padding: 10px;">${formData.systemDescription}</td>
        </tr>
        <tr>
          <td style="border: 1px solid #000; padding: 10px;">System Type</td>
          <td style="border: 1px solid #000; padding: 10px;">${formData.systemType}</td>
        </tr>
        <tr>
  <td style="border: 1px solid #000; padding: 10px;">Voltage</td>
  <td style="border: 1px solid #000; padding: 10px;">${formData.voltage}</td>
</tr>
${
  formData.voltage === '3 Phase'
    ? `
<tr>
  <td style="border: 1px solid #000; padding: 10px;">Phase Voltage</td>
  <td style="border: 1px solid #000; padding: 10px;">${formData.phaseVoltage}</td>
</tr>
`
    : ''
}
        <tr>
          <td style="border: 1px solid #000; padding: 10px;">Condensor Tonnage</td>
          <td style="border: 1px solid #000; padding: 10px;">${formData.condensorTonnage}</td>
        </tr>
        <tr>
  <td style="border: 1px solid #000; padding: 10px;">Heat Strip</td>
  <td style="border: 1px solid #000; padding: 10px;">${formData.heatStrip}</td>
</tr>
${
  formData.heatStrip === 'Yes'
    ? `
<tr>
  <td style="border: 1px solid #000; padding: 10px;">Heat Strip Size</td>
  <td style="border: 1px solid #000; padding: 10px;">${formData.heatStripSize}</td>
</tr>
`
    : ''
}
<tr>
  <td colspan="2" style="border: 1px solid #000; padding: 10px;">
    <h2>Copper Size LL & SL</h2>
    <p>LL: ${formData.copperSize.value1}</p>
    <p>SL: ${formData.copperSize.value2}</p>
  </td>
</tr>
<tr>
  <td style="border: 1px solid #000; padding: 10px;">Copper Type</td>
  <td style="border: 1px solid #000; padding: 10px;">${formData.copperType}</td>
</tr>
        <tr>
          <td style="border: 1px solid #000; padding: 10px;">AH Location</td>
          <td style="border: 1px solid #000; padding: 10px;">${formData.AHLocation}</td>
        </tr>
        ${formData.AHDimensions.map(
          (dim, index) => `
          <tr>
            <td colspan="2" style="border: 1px solid #000; padding: 10px;">
              <h2>AH Dimension ${index + 1}</h2>
              <p>Length: ${dim.l}</p>
              <p>Width: ${dim.w}</p>
              <p>Height: ${dim.h}</p>
            </td>
          </tr>
        `,
        ).join('')}
        <tr>
          <td style="border: 1px solid #000; padding: 10px;">Filter Location</td>
          <td style="border: 1px solid #000; padding: 10px;">${formData.filterLocation}</td>
        </tr>
        <tr>
          <td style="border: 1px solid #000; padding: 10px;">Filter Size</td>
          <td style="border: 1px solid #000; padding: 10px;">${formData.filterSize}</td>
        </tr>
        <tr>
          <td style="border: 1px solid #000; padding: 10px;">Breaker Type</td>
          <td style="border: 1px solid #000; padding: 10px;">${formData.breakerType}</td>
        </tr>
        ${formData.breakerDimensions
          .map(
            (dim, index) => `
          <tr>
            <td colspan="2" style="border: 1px solid #000; padding: 10px;">
              <h2>Breaker Dimension ${index + 1}</h2>
              <p>Value 1: ${dim.value1}</p>
              <p>Value 2: ${dim.value2}</p>
            </td>
          </tr>
        `,
          )
          .join('')}
          <tr>
  <td style="border: 1px solid #000; padding: 10px;">New Thermostat?</td>
  <td style="border: 1px solid #000; padding: 10px;">${formData.newThermostat}</td>
</tr>
${
  formData.newThermostat === 'Yes'
    ? `
<tr>
  <td style="border: 1px solid #000; padding: 10px;">Thermostat Type</td>
  <td style="border: 1px solid #000; padding: 10px;">${formData.thermostatType}</td>
</tr>
`
    : ''
}
<tr>
  <td style="border: 1px solid #000; padding: 10px;">New Disconnect Needed?</td>
  <td style="border: 1px solid #000; padding: 10px;">${formData.newDisconnectNeeded}</td>
</tr>
${
  formData.newDisconnectNeeded === 'Yes'
    ? `
<tr>
  <td style="border: 1px solid #000; padding: 10px;">Fused or Non Fused</td>
  <td style="border: 1px solid #000; padding: 10px;">${formData.disconnectType}</td>
</tr>
${
  formData.disconnectType === 'Fused'
    ? `
<tr>
  <td style="border: 1px solid #000; padding: 10px;">Note Amps</td>
  <td style="border: 1px solid #000; padding: 10px;">${formData.disconnectAmps}</td>
</tr>
`
    : ''
}`
    : ''
}
</table>
<div style="page-break-after: always;"></div>

<table style="width: 100%; border-collapse: collapse;">
        ${formData.staticReadings
          .map(
            (dim, index) => `
          <tr>
            <td colspan="2" style="border: 1px solid #000; padding: 10px;">
              <h2>Static Reading ${index + 1}</h2>
              <p>Dimension 1: ${dim.value1}</p>
              <p>Dimension 2: ${dim.value2}</p>
            </td>
          </tr>
        `,
          )
          .join('')}
          <tr>
          <td style="border: 1px solid #000; padding: 10px;">Pan Replacement?</td>
          <td style="border: 1px solid #000; padding: 10px;">${formData.panReplacement}</td>
        </tr>
        <tr>
  <td style="border: 1px solid #000; padding: 10px;">AH Lift Needed?</td>
  <td style="border: 1px solid #000; padding: 10px;">${formData.AHLiftNeeded}</td>
</tr>
        <tr>
        <td style="border: 1px solid #000; padding: 10px;">Crane Needed?</td>
        <td style="border: 1px solid #000; padding: 10px;">${formData.craneNeeded}</td>
      </tr>
      <tr>
      <td style="border: 1px solid #000; padding: 10px;">Distance From Roof To Unit</td>
      <td style="border: 1px solid #000; padding: 10px;">${formData.craneDistance}</td>
    </tr>
        <tr>
          <td style="border: 1px solid #000; padding: 10px;">Duct Type</td>
          <td style="border: 1px solid #000; padding: 10px;">${formData.ductType}</td>
        </tr>
        ${formData.ductSize
          .map(
            (dim, index) => `
          <tr>
            <td colspan="2" style="border: 1px solid #000; padding: 10px;">
              <h2>Duct Size ${index + 1}</h2>
              <p>Value 1: ${dim.value1}</p>
              <p>Value 2: ${dim.value2}</p>
            </td>
          </tr>
        `,
          )
          .join('')}
        <tr>
        <td style="border: 1px solid #000; padding: 10px;">Zoning</td>
        <td style="border: 1px solid #000; padding: 10px;">${formData.zoning}</td>
      </tr>
      <tr>
        <td style="border: 1px solid #000; padding: 10px;">Zones</td>
        <td style="border: 1px solid #000; padding: 10px;">${formData.zones}</td>
      </tr>
          <tr>
      <td style="border: 1px solid #000; padding: 10px;">Needs To Be Replaced</td>
      <td style="border: 1px solid #000; padding: 10px;">${selectedItems}</td>
    </tr>
    </table>
  </div>
`;

    const html = `
<!DOCTYPE html>
<html>
  <head>
    <title>Sell Sheet</title>
  </head>
  <body>

   ${content}

  </body>
</html>
`;
    // Use the PDFClientService to convert the HTML to a PDF
    const url = await PDFClientService.getUploadedHTMLUrl(
      html,
      `SellSheet-${jobNumber}-${timestamp()}.pdf`,
      'kalosdocs-prod',
    );

    return url;
  };

  const uploadDocument = async () => {
    const errors = checkFormIsValid(form);
    setErrors(errors);
    setLoading(true);
    try {
      if (!Object.keys(errors).length) {
        setLoading(true);
        const url = await createMyDocument(form);
        if (url) {
          //we have to fetch the file first
          const fileRes = await FileClientService.Get(File.create({ name: url.key }));
          if (fileRes) {
            // the file was created, so we can create the document record
            await DocumentClientService.Create(
              Document.create({
                fileId: fileRes.id,
                propertyId: propertyId,
                description: fileRes.name,
                filename: fileRes.name,
              }),
            );
          }
          onCreateDoc?.();
          setMessage(`Document Successfully Created`);
        }
      }
    } catch (error) {
      console.error('Failed to create or upload document:', error);
      setLoading(false);
      setMessage(`Error with Generating document: ${error}`);
      return;
    }
    setLoading(false);
  };
  const handleCopperSizeChange =
    (key: keyof StringDimension) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const newCopperSize = { ...form.copperSize };
      newCopperSize[key] = event.target.value;
      setForm({
        ...form,
        copperSize: newCopperSize,
      });
    };
  const handleAddAHDimension = () => {
    setForm({
      ...form,
      AHDimensions: [...form.AHDimensions, { l: 0, w: 0, h: 0 }],
    });
  };
  const handleRemoveAHDimension = (index: number) => {
    setForm({
      ...form,
      AHDimensions: form.AHDimensions.filter((_, i) => i !== index),
    });
  };
  const handleAddBreakerDimension = () => {
    setForm({
      ...form,
      breakerDimensions: [...form.breakerDimensions, { value1: '', value2: '' }],
    });
  };
  const handleRemoveBreakerDimension = (index: number) => {
    setForm({
      ...form,
      breakerDimensions: form.breakerDimensions.filter((_, i) => i !== index),
    });
  };
  const handleAddStaticReading = () => {
    setForm({
      ...form,
      staticReadings: [...form.staticReadings, { value1: '', value2: '' }],
    });
  };
  const handleRemoveStaticReading = (index: number) => {
    setForm({
      ...form,
      staticReadings: form.staticReadings.filter((_, i) => i !== index),
    });
  };
  const handleAddDuctSize = () => {
    setForm({
      ...form,
      ductSize: [...form.ductSize, { value1: '', value2: '' }],
    });
  };
  const handleRemoveDuctSize = (index: number) => {
    setForm({
      ...form,
      ductSize: form.ductSize.filter((_, i) => i !== index),
    });
  };
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLElement>) => {
    const { name, value } = event.target as HTMLInputElement;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name as string]: value as string,
    }));
  };
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name as keyof typeof form.selectedItems;
    setForm({
      ...form,
      selectedItems: {
        ...form.selectedItems,
        [name]: {
          ...form.selectedItems[name],
          checked: event.target.checked,
        },
      },
    });
  };

  const handleDimensionChange =
    (index: number, key: keyof Area) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const newDimensions = [...form.AHDimensions];
      newDimensions[index][key] = Number(event.target.value);
      setForm({
        ...form,
        AHDimensions: newDimensions,
      });
    };

  const handleBreakerChange =
    (index: number, key: keyof StringDimension) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const newBreaker = [...form.breakerDimensions];
      newBreaker[index][key] = String(event.target.value);
      setForm({
        ...form,
        breakerDimensions: newBreaker,
      });
    };

  const handleStaticReadingChange =
    (index: number, key: keyof StringDimension) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const readings = [...form.staticReadings];
      readings[index][key] = String(event.target.value);
      setForm({
        ...form,
        staticReadings: readings,
      });
    };

  const handleDuctSizeChange =
    (index: number, key: keyof StringDimension) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const ductSize = [...form.ductSize];
      ductSize[index][key] = String(event.target.value);
      setForm({
        ...form,
        ductSize: ductSize,
      });
    };

  return (
    <div>
      <form className="pt-4">
        <Grid container direction="column" alignItems="flex-start" spacing={2}>
          <Grid item>
            <TextField
              label="System Description"
              name="systemDescription"
              value={form.systemDescription}
              onChange={handleInputChange}
              error={!!errors.systemDescription}
            />
            <p className="pt-1 text-sm text-red-500 ">{errors.systemDescription}</p>
          </Grid>
          <Grid item>
            <InputLabel
              id="systemType-label"
              style={{ fontWeight: 'bold', borderBottom: '1px solid black' }}
            >
              System Type
            </InputLabel>

            <Select
              className="min-w-[12.75rem]"
              name="systemType"
              value={form.systemType}
              onChange={handleSelectChange}
              error={!!errors.systemType}
            >
              <MenuItem value="Choose One">Choose One</MenuItem>
              <MenuItem value="RTU">RTU</MenuItem>

              <MenuItem value="Split System AC">Split System AC</MenuItem>
              <MenuItem value="Split System Heat Pump">Split System Heat Pump</MenuItem>
              <MenuItem value="Split System Gas Furnace">Split System Gas Furnace</MenuItem>
              <MenuItem value="Package Unit AC">Package Unit AC</MenuItem>
              <MenuItem value="Package Unit Heat Pump">Package Unit Heat Pump</MenuItem>
              <MenuItem value="Ductless">Ductless</MenuItem>
            </Select>
            <p className="pt-1 text-sm text-[red]">{errors.systemType}</p>
          </Grid>
          {form.systemType != 'Split System Gas Furnace' && form.systemType != 'Ductless' && (
            <Grid item>
              <InputLabel id="heatStrip-label">Heat Strip</InputLabel>
              <Select name="heatStrip" value={form.heatStrip} onChange={handleSelectChange}>
                <MenuItem value="No">No</MenuItem>
                <MenuItem value="Yes">Yes</MenuItem>
              </Select>
            </Grid>
          )}
          {form.heatStrip === 'Yes' &&
            form.systemType !== 'Split System Gas Furnace' &&
            form.systemType !== 'Ductless' && (
              <Grid item>
                <TextField
                  label="Heat Strip Size"
                  name="heatStripSize"
                  value={form.heatStripSize}
                  onChange={handleInputChange}
                />
              </Grid>
            )}
          <Grid item>
            <TextField
              label="Copper Size LL"
              name="copperSize.value1"
              value={form.copperSize.value1}
              onChange={handleCopperSizeChange('value1')}
            />
          </Grid>
          <Grid item>
            <TextField
              label="Copper Size SL"
              name="copperSize.value2"
              value={form.copperSize.value2}
              onChange={handleCopperSizeChange('value2')}
            />
          </Grid>

          <Grid item>
            <InputLabel id="copperType-label">Copper Type</InputLabel>
            <Select
              name="copperType"
              className="min-w-[5rem]"
              value={form.copperType}
              onChange={handleSelectChange}
            >
              <MenuItem value="Hard">Hard</MenuItem>
              <MenuItem value="Soft">Soft</MenuItem>
            </Select>
          </Grid>
          <Grid item>
            <InputLabel id="voltage-label">Voltage</InputLabel>
            <Select
              className="min-w-[8.125]"
              name="voltage"
              value={form.voltage}
              onChange={handleSelectChange}
            >
              <MenuItem value="Single Phase">Single Phase</MenuItem>
              <MenuItem value="3 Phase">3 Phase</MenuItem>
            </Select>
          </Grid>
          {form.voltage === '3 Phase' && (
            <Grid item>
              <InputLabel id="phaseVoltage-label">Phase Voltage</InputLabel>
              <Select name="phaseVoltage" value={form.phaseVoltage} onChange={handleSelectChange}>
                <MenuItem value="208v">208v</MenuItem>
                <MenuItem value="480v">480v</MenuItem>
              </Select>
            </Grid>
          )}
          <Grid item>
            <TextField
              label="Condensor Tonnage"
              name="condensorTonnage"
              type="number"
              value={form.condensorTonnage}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item>
            {form.condensorTonnage > 5 && (
              <div>
                <InputLabel id="smokeDetector-label">Smoke Detector?</InputLabel>

                <Grid container>
                  <Grid item xs={6}>
                    <Select
                      name="smokeDetector"
                      value={form.smokeDetector}
                      onChange={handleSelectChange}
                    >
                      <MenuItem value="No">No</MenuItem>
                      <MenuItem value="Yes">Yes</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Wire Size"
                      name="wireSize"
                      value={form.wireSize}
                      onChange={handleInputChange}
                    />
                  </Grid>
                </Grid>
              </div>
            )}
          </Grid>
          <Grid item xs={6}>
            <FormLabel component="legend">AH Location</FormLabel>
            <Select
              className="min-w-[126px]"
              name="AHLocation"
              value={form.AHLocation}
              onChange={handleSelectChange}
            >
              <MenuItem value="Choose One">Choose One</MenuItem>
              <MenuItem value="Hanging Kit">Hanging Kit</MenuItem>
              <MenuItem value="Closet">Closet</MenuItem>
              <MenuItem value="Attic">Attic</MenuItem>
            </Select>
          </Grid>

          <Grid item>
            <InputLabel
              id="ahd-label"
              style={{
                fontWeight: 'bold',
                paddingBottom: '10px',
              }}
            >
              A/H Area Max Dimensions
            </InputLabel>
            {form.AHDimensions.map((dim, index) => (
              <Grid container spacing={2} key={index} style={{ paddingBottom: '10px' }}>
                <Grid item xs={3}>
                  <TextField
                    label={
                      form.AHLocation == 'Attic'
                        ? `Opening dimensions? ${index + 1}`
                        : `Unit Dimension ${index + 1} Length`
                    }
                    value={dim.l}
                    onChange={handleDimensionChange(index, 'l')}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label={
                      form.AHLocation == 'Attic'
                        ? `How far from opening is the AH? ${index + 1}`
                        : `Unit Dimension ${index + 1} Width`
                    }
                    value={dim.w}
                    onChange={handleDimensionChange(index, 'w')}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label={
                      form.AHLocation == 'Attic'
                        ? `Attic open or tight?? ${index + 1}`
                        : `Unit Dimension ${index + 1} Height`
                    }
                    value={dim.h}
                    onChange={handleDimensionChange(index, 'h')}
                  />
                </Grid>
                <Grid item xs={3} container alignItems="center">
                  {form.AHDimensions.length > 1 && (
                    <IconButton onClick={() => handleRemoveAHDimension(index)}>
                      <RemoveIcon />
                    </IconButton>
                  )}
                  <IconButton onClick={handleAddAHDimension}>
                    <AddIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
          </Grid>

          <Grid item>
            <InputLabel
              id="systemType-label"
              style={{ fontWeight: 'bold', borderBottom: '1px solid black' }}
            >
              Filter Location
            </InputLabel>

            <Select
              className="min-w-[190px]"
              name="filterLocation"
              value={form.filterLocation}
              onChange={handleSelectChange}
            >
              <MenuItem value="Choose One">Choose One</MenuItem>

              <MenuItem value="Underneath AH">Underneath AH</MenuItem>
              <MenuItem value="Filter Back Return Grill">Filter Back Return Grill</MenuItem>
            </Select>
          </Grid>
          <Grid item>
            {form.filterLocation === 'Filter Back Return Grill' && (
              <TextField
                label="Filter Size"
                name="filterSize"
                value={form.filterSize}
                onChange={handleInputChange}
              />
            )}
          </Grid>
          <Grid item>
            <InputLabel
              id="breakerType-label"
              style={{ fontWeight: 'bold', borderBottom: '1px solid black' }}
            >
              Breaker Type
            </InputLabel>

            <Select
              className="min-w-[190px]"
              name="breakerType"
              value={form.breakerType}
              onChange={handleSelectChange}
            >
              <MenuItem value="Choose One">Choose One</MenuItem>

              <MenuItem value="Siemens">Siemens</MenuItem>
              <MenuItem value="Square D QO">Square D QO</MenuItem>
              <MenuItem value="Square D Homeline">Square D Homeline</MenuItem>
              <MenuItem value="GE Slimeline">GE Slimeline</MenuItem>
              <MenuItem value="GE regular">GE regular</MenuItem>
              <MenuItem value="Cutler Hammer Black">Cutler Hammer Black</MenuItem>
              <MenuItem value="Cutler Hammer Brown">Cutler Hammer Brown</MenuItem>
              <MenuItem value="WestingHouse">WestingHouse</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </Select>
          </Grid>
          <Grid item>
            <InputLabel
              id="breakerSizes-label"
              style={{
                fontWeight: 'bold',
                paddingBottom: '10px',
              }}
            >
              Breaker Sizes
            </InputLabel>
            {form.breakerDimensions.map((dim, index) => (
              <Grid container spacing={4} key={index} style={{ paddingBottom: '10px' }}>
                <Grid item xs={4}>
                  <TextField
                    label={`AH/Furnace Breaker ${index + 1} `}
                    value={dim.value1}
                    onChange={handleBreakerChange(index, 'value1')}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label={`Condenser Breaker ${index + 1}`}
                    value={dim.value2}
                    onChange={handleBreakerChange(index, 'value2')}
                  />
                </Grid>
                <Grid item xs={3} container alignItems="center">
                  {form.breakerDimensions.length > 1 && (
                    <IconButton onClick={() => handleRemoveBreakerDimension(index)}>
                      <RemoveIcon />
                    </IconButton>
                  )}
                  <IconButton onClick={handleAddBreakerDimension}>
                    <AddIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Grid item>
            <InputLabel id="newThermostat-label">New Thermostat?</InputLabel>
            <Select name="newThermostat" value={form.newThermostat} onChange={handleSelectChange}>
              <MenuItem value="No">No</MenuItem>
              <MenuItem value="Yes">Yes</MenuItem>
            </Select>
          </Grid>
          {form.newThermostat === 'Yes' && (
            <Grid item>
              <InputLabel id="thermostatType-label">Thermostat Type</InputLabel>
              <Select
                name="thermostatType"
                value={form.thermostatType}
                onChange={handleSelectChange}
              >
                <MenuItem value="Occupied">Occupied</MenuItem>
                <MenuItem value="Non-occupied">Non-occupied</MenuItem>
              </Select>
            </Grid>
          )}
          <Grid item>
            <InputLabel id="newDisconnectNeeded-label">New Disconnect Needed?</InputLabel>
            <Select
              name="newDisconnectNeeded"
              value={form.newDisconnectNeeded}
              onChange={handleSelectChange}
            >
              <MenuItem value="No">No</MenuItem>
              <MenuItem value="Yes">Yes</MenuItem>
            </Select>
          </Grid>
          {form.newDisconnectNeeded === 'Yes' && (
            <>
              <Grid item>
                <InputLabel id="disconnectType-label">Fused or Non Fused</InputLabel>
                <Select
                  name="disconnectType"
                  value={form.disconnectType}
                  onChange={handleSelectChange}
                >
                  <MenuItem value="">Choose One</MenuItem>
                  <MenuItem value="Fused">Fused</MenuItem>
                  <MenuItem value="Non Fused">Non Fused</MenuItem>
                </Select>
              </Grid>
              {form.disconnectType === 'Fused' && (
                <Grid item>
                  <TextField
                    label="Note Amps"
                    name="disconnectAmps"
                    value={form.disconnectAmps}
                    onChange={handleInputChange}
                  />
                </Grid>
              )}
            </>
          )}
          <Grid item>
            <InputLabel
              id="staticPressureReadings-label"
              style={{
                fontWeight: 'bold',
                paddingBottom: '10px',
              }}
            >
              Static Pressure Readings
            </InputLabel>
            {form.staticReadings.map((dim, index) => (
              <Grid container spacing={2} key={index} style={{ paddingBottom: '10px' }}>
                <Grid item xs={4}>
                  <TextField
                    label={`Return Static ${index + 1} `}
                    value={dim.value1}
                    onChange={handleStaticReadingChange(index, 'value1')}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label={`Supply Static ${index + 1}`}
                    value={dim.value2}
                    onChange={handleStaticReadingChange(index, 'value2')}
                  />
                </Grid>
                <Grid item xs={3} container alignItems="center">
                  {form.staticReadings.length > 1 && (
                    <IconButton onClick={() => handleRemoveStaticReading(index)}>
                      <RemoveIcon />
                    </IconButton>
                  )}
                  <IconButton onClick={handleAddStaticReading}>
                    <AddIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
          </Grid>

          <Grid item>
            <InputLabel id="ductType-label">Duct Type</InputLabel>
            <Select
              className="min-w-[8.125]"
              name="ductType"
              value={form.ductType}
              onChange={handleSelectChange}
            >
              <MenuItem value="Choose One">Choose One</MenuItem>
              <MenuItem value="Metal">Metal</MenuItem>

              <MenuItem value="Flex">Flex</MenuItem>
              <MenuItem value="Duct Board">Duct Board</MenuItem>
            </Select>
          </Grid>
          <Grid item>
            <InputLabel
              id="ductSizes-label"
              style={{
                fontWeight: 'bold',
                paddingBottom: '10px',
              }}
            >
              Duct Sizes
            </InputLabel>
            {form.ductSize.map((dim, index) => (
              <Grid container spacing={2} key={index} style={{ paddingBottom: '10px' }}>
                <Grid item xs={4}>
                  <TextField
                    label={`Return Duct Size ${index + 1} `}
                    value={dim.value1}
                    onChange={handleDuctSizeChange(index, 'value1')}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label={`Supply Duct Size ${index + 1}`}
                    value={dim.value2}
                    onChange={handleDuctSizeChange(index, 'value2')}
                  />
                </Grid>
                <Grid item xs={3} container alignItems="center">
                  {form.ductSize.length > 1 && (
                    <IconButton onClick={() => handleRemoveDuctSize(index)}>
                      <RemoveIcon />
                    </IconButton>
                  )}
                  <IconButton onClick={handleAddDuctSize}>
                    <AddIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Grid item xs={12}>
            <InputLabel id="panReplacement-label">Secondary Pan Replacement Needed?</InputLabel>
            <Select
              className="min-w-[70px]"
              name="panReplacement"
              value={form.panReplacement}
              onChange={handleSelectChange}
            >
              <MenuItem value="No">No</MenuItem>
              <MenuItem value="Yes">Yes</MenuItem>
            </Select>
          </Grid>
          <Grid item>
            <InputLabel id="AHLiftNeeded-label">AH Lift Needed?</InputLabel>
            <Select
              className="min-w-[70px]"
              name="AHLiftNeeded"
              value={form.AHLiftNeeded}
              onChange={handleSelectChange}
            >
              <MenuItem value="No">No</MenuItem>
              <MenuItem value="Yes">Yes</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12}>
            <InputLabel id="craneNeeded-label">Crane Needed?</InputLabel>
            <Select
              className="min-w-[70px]"
              name="craneNeeded"
              value={form.craneNeeded}
              onChange={handleSelectChange}
            >
              <MenuItem value="No">No</MenuItem>
              <MenuItem value="Yes">Yes</MenuItem>
            </Select>
          </Grid>
          <Grid item>
            {form.craneNeeded == 'Yes' && (
              <div>
                <InputLabel id="-label" className="mb-3">
                  Distance between Unit and Roof Edge
                </InputLabel>
                <Grid container>
                  <Grid item xs={12}>
                    <TextField
                      label="Distance"
                      name="craneDistance"
                      value={form.craneDistance}
                      onChange={handleInputChange}
                    />
                  </Grid>
                </Grid>
              </div>
            )}
          </Grid>
          <Grid item>
            <InputLabel id="zoning-label">Zoning Type</InputLabel>
            <Select
              name="zoning"
              className="min-w-[126px]"
              value={form.zoning}
              onChange={handleSelectChange}
            >
              <MenuItem value="Choose One">Choose One</MenuItem>
              <MenuItem value="Yes">Yes</MenuItem>

              <MenuItem value="No">No</MenuItem>
            </Select>
          </Grid>
          <Grid item>
            {form.zoning === 'Yes' && (
              <div>
                <InputLabel id="Zones-label">Zones</InputLabel>
                <Select name="zones" value={form.zones} onChange={handleSelectChange}>
                  <MenuItem value="Choose One">Choose One</MenuItem>
                  <MenuItem value="One">One</MenuItem>
                  <MenuItem value="Two">Two</MenuItem>
                  <MenuItem value="Three">Three</MenuItem>
                  <MenuItem value="4 or More">4 or More</MenuItem>
                </Select>
              </div>
            )}
          </Grid>
          <Grid item>
            <FormControl component="fieldset">
              <FormLabel
                component="legend"
                style={{ fontWeight: 'bold', borderBottom: '1px solid black' }}
              >
                Needs to Be Replaced (select all that apply)
              </FormLabel>
              <FormGroup>
                <p className="pt-1 text-sm text-[red]">{errors.selectedItems}</p>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={form.selectedItems.copperLineset.checked}
                      onChange={handleCheckboxChange}
                      name="copperLineset"
                    />
                  }
                  label="Copper Lineset"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={form.selectedItems.pad.checked}
                      onChange={handleCheckboxChange}
                      name="pad"
                    />
                  }
                  label="Pad"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={form.selectedItems.returnBox.checked}
                      onChange={handleCheckboxChange}
                      name="returnBox"
                    />
                  }
                  label="Return Box"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={form.selectedItems.filterBackReturnGrill.checked}
                      onChange={handleCheckboxChange}
                      name="filterBackReturnGrill"
                    />
                  }
                  label="Filter Back Return Grill"
                />
                {form.systemType == 'Split System Gas Furnace' && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={form.selectedItems.comboSmokeDetectorCOAlarm.checked}
                        onChange={handleCheckboxChange}
                        name="comboSmokeDetectorCOAlarm"
                      />
                    }
                    label="Combo Smoke Detector/CO Alarm"
                  />
                )}
                {form.systemType == 'Split System Gas Furnace' && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={form.selectedItems.lowLevelCOMonitor.checked}
                        onChange={handleCheckboxChange}
                        name="lowLevelCOMonitor"
                      />
                    }
                    label="Low Level CO Monitor"
                  />
                )}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={form.selectedItems.noneOfTheAbove.checked}
                      onChange={handleCheckboxChange}
                      name="noneOfTheAbove"
                    />
                  }
                  label="(none of the above)"
                />
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Button label="Create" onClick={() => uploadDocument()}></Button>
      </form>
      {message != '' && (
        <Alert
          className="CenteredModal"
          title="Sell Sheet/AC Quote"
          open
          onClose={() => setMessage('')}
        >
          {message}
        </Alert>
      )}
      {loading && <Loader className="SellSheet-Loader" />}
    </div>
  );
};
