import { BaseClient } from '../BaseClient';
import { QuoteLine, QuoteLineList } from '../compiled-protos/quote';
import { QuoteServiceClient as QuoteLineServiceClient } from '../compiled-protos/quote.client';

class QuoteLineClient extends BaseClient {
  self: QuoteLineServiceClient;

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new QuoteLineServiceClient(this.transport);
  }

  public async Create(req: QuoteLine) {
    let res = QuoteLine.create();
    try {
      res = await this.self.createQuoteLine(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
      return;
    }
    return res;
  }

  public async Get(req: QuoteLine) {
    let res = QuoteLine.create();
    try {
      res = await this.self.getQuoteLine(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
      return;
    }
    return res;
  }

  public async Update(req: QuoteLine) {
    let res = QuoteLine.create();
    try {
      res = await this.self.updateQuoteLine(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
      return;
    }
    return res;
  }

  public async Delete(req: QuoteLine) {
    let res = QuoteLine.create();
    try {
      res = await this.self.deleteQuoteLine(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
      return;
    }
    return res;
  }

  public async BatchGet(req: QuoteLine) {
    const res = await this.self.batchGetQuoteLine(req, this.getMetaData()).response;
    return res;
  }

  /**
   * Returns loaded QuoteLines
   * @returns QuoteLine[]
   */
  public async loadQuoteLines() {
    const results: QuoteLine[] = [];
    const req = QuoteLine.create();
    req.pageNumber = 0;
    const res = await this.self.batchGetQuoteLine(req);
    const resultsList = res.response.results;
    const totalCount = res.response.totalCount;
    const len = results.length;
    results.concat(results);
    if (totalCount > len) {
      const batchesAmount = Math.ceil((totalCount - len) / len);
      const batchResults = await Promise.all(
        Array.from(Array(batchesAmount)).map(async (_, idx) => {
          req.pageNumber = idx + 1;
          return await (await this.BatchGet(req))!.results;
        })
      );
      results.push(
        ...batchResults.reduce((aggr, item) => [...aggr, ...item], [])
      );
    }
    return results;
  }
}

export { QuoteLine, QuoteLineList, QuoteLineClient };
