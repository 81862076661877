import { zodResolver } from '@hookform/resolvers/zod';
import { ActivityLog, User } from '@kalos/kalos-rpc';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Textarea,
  toast,
  useConfirm,
} from '@kalos/ui';
import { format } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { useUserQuery, useUserUpdateMutation } from '../../../hooks/react-query/useUserQuery';
import { ActivityLogClientService, type SaveHandler } from '../../../tools/helpers';
import { getFieldMaskFromDirtyField } from '../EmployeeDepartments/EditEmployeeFunction';

const notificationSchema = z.object({
  notification: z
    .string()
    .min(1, 'Notification is required')
    .max(1000, 'Notification is too long (max 1000 characters)')
    .refine((value) => value.trim() !== '', { message: 'Notification is required' }),
});
type Notification = z.infer<typeof notificationSchema>;

type NotificationDialogProps = {
  open: boolean;
  onOpenChange: (arg: boolean) => void;
  trigger?: React.ReactNode;
  onSave: SaveHandler<Notification>;
  disabled?: boolean;
  loading?: boolean;
  defaultValue: string;
  onDelete?: () => void;
  title?: string;
};

export const NotificationDialog: React.FC<NotificationDialogProps> = ({
  open,
  onOpenChange: onOpenChangeProp,
  onSave,
  disabled,
  loading,
  defaultValue,
  onDelete,
  trigger,
  title,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const notificationForm = useForm<Notification>({
    resolver: zodResolver(notificationSchema),
    disabled: !isEditing,
    defaultValues: useMemo(() => ({ notification: defaultValue }), [defaultValue]),
  });

  useEffect(() => {
    notificationForm.reset({ notification: defaultValue });
  }, [defaultValue, notificationForm]);

  useEffect(() => {
    setIsModalOpen(open);
    if (!open) {
      notificationForm.reset({ notification: defaultValue });
    }
  }, [open, defaultValue, notificationForm]);

  const onSubmit = notificationForm.handleSubmit(async (data) => {
    setIsSaveLoading(true);
    try {
      await onSave({ data, dirtyFields: notificationForm.formState.dirtyFields });
    } finally {
      setIsSaveLoading(false);
    }
  });

  const confirm = useConfirm();

  const onOpenChange = useCallback(
    async (open: boolean) => {
      if (!open) {
        if (notificationForm.formState.isDirty) {
          if (!(await confirm('Are you sure you want to leave without saving?'))) return;
          onOpenChangeProp(open);
          notificationForm.reset({ notification: defaultValue });
        } else {
          onOpenChangeProp(open);
        }
        setIsEditing(false);
        return;
      } else {
        onOpenChangeProp(open);
      }
    },
    [confirm, defaultValue, notificationForm, onOpenChangeProp],
  );

  const handleDelete = useCallback(() => {
    if (onDelete) {
      setIsDeleteConfirmOpen(true);
    }
  }, [onDelete]);

  const submitDisabled = loading || disabled || !notificationForm.formState.isDirty;

  return (
    <>
      <Dialog onOpenChange={onOpenChange} open={isModalOpen}>
        {trigger && <DialogTrigger asChild>{trigger}</DialogTrigger>}
        <DialogContent className="max-w-sm">
          {title && <DialogTitle>{title}</DialogTitle>}
          <form onSubmit={onSubmit} className="grid gap-4">
            <Form {...notificationForm}>
              <FormField
                control={notificationForm.control}
                name="notification"
                render={({ field }) => (
                  <FormItem className="flex flex-col justify-end">
                    <FormLabel>Notification</FormLabel>
                    <FormMessage />
                    <Textarea
                      {...field}
                      readOnly={!isEditing}
                      className="max-h-[800px] min-h-[200px] w-full overflow-y-auto text-lg"
                      rows={15}
                    />
                  </FormItem>
                )}
              />
              <div className="flex justify-end gap-4">
                {isEditing ? (
                  <>
                    <Button type="submit" disabled={submitDisabled} isLoading={isSaveLoading}>
                      Save
                    </Button>
                    {onDelete && (
                      <Button
                        type="button"
                        variant="outline"
                        onClick={handleDelete}
                        disabled={disabled || loading}
                        isLoading={isDeleteLoading}
                      >
                        Delete
                      </Button>
                    )}
                  </>
                ) : (
                  <Button type="button" onClick={() => setIsEditing(true)}>
                    Edit
                  </Button>
                )}
              </div>
            </Form>
          </form>
        </DialogContent>
      </Dialog>

      <Dialog open={isDeleteConfirmOpen} onOpenChange={setIsDeleteConfirmOpen}>
        <DialogContent>
          <DialogTitle>Confirm Delete</DialogTitle>
          <p>Are you sure you want to delete this notification?</p>
          <div className="flex justify-end gap-4">
            <Button variant="outline" onClick={() => setIsDeleteConfirmOpen(false)}>
              Cancel
            </Button>
            <Button
              isLoading={isDeleteLoading}
              onClick={async () => {
                setIsDeleteConfirmOpen(false);
                try {
                  setIsDeleteLoading(true);
                  await onDelete?.();
                } finally {
                  setIsDeleteLoading(false);
                }
              }}
            >
              Delete
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

type CustomerNotificationDialogProps = { customerId: User['id']; showOnMount?: boolean } & Pick<
  NotificationDialogProps,
  'trigger'
>;

export const CustomerNotificationDialog: React.FC<CustomerNotificationDialogProps> = ({
  customerId,
  showOnMount,
  trigger,
}) => {
  const notificationQuery = useUserQuery({
    filters: { id: customerId },
    select(data) {
      return data.notification;
    },
  });

  const [isNotificationDialogOpen, setIsNotificationDialogOpen] = useState(false);
  const clientMutation = useUserUpdateMutation();

  useEffect(() => {
    if (notificationQuery.isSuccess && notificationQuery.data && showOnMount) {
      const timer = setTimeout(() => {
        setIsNotificationDialogOpen((value) => {
          if (!value) {
            return true;
          }
          return value;
        });
      }, 2500);

      return () => clearTimeout(timer);
    }
  }, [notificationQuery.data, notificationQuery.isSuccess, showOnMount]);

  const handleCreateActivity = useCallback(
    async (message: string) => {
      const actLog = ActivityLog.create();
      actLog.userId = customerId;
      actLog.activityName = message;
      actLog.activityDate = format(new Date(), 'yyyy-MM-dd HH:mm:ss');
      await ActivityLogClientService.Create(actLog);
    },
    [customerId],
  );

  const handleSaveNotification = useCallback<
    React.ComponentProps<typeof NotificationDialog>['onSave']
  >(
    async ({ data: { notification }, dirtyFields }) => {
      try {
        await clientMutation.mutateAsync(
          User.create({
            id: customerId,
            notification,
            fieldMask: getFieldMaskFromDirtyField(dirtyFields),
          }),
        );
        handleCreateActivity(notificationQuery.data ? 'Edited notification' : 'Added notification');
        toast({ variant: 'success', title: 'Notification saved' });
        setIsNotificationDialogOpen(false);
      } catch (error) {
        toast({ variant: 'destructive', title: 'Failed to save notification' });
      }
    },
    [clientMutation, customerId, handleCreateActivity, notificationQuery.data],
  );

  const handleDeleteNotification = useCallback(async () => {
    try {
      await clientMutation.mutateAsync(
        User.create({
          id: customerId,
          notification: '',
          fieldMask: ['Notification'],
        }),
      );
      toast({ variant: 'success', title: 'Notification deleted' });
      setIsNotificationDialogOpen(false);
      handleCreateActivity('Deleted notification');
    } catch (error) {
      toast({ variant: 'destructive', title: 'Failed to delete notification' });
    }
  }, [clientMutation, customerId, handleCreateActivity]);

  return (
    <NotificationDialog
      title={
        notificationQuery.isSuccess
          ? notificationQuery.data
            ? 'Edit notification'
            : 'Add notification'
          : undefined
      }
      defaultValue={notificationQuery.data ?? ''}
      onOpenChange={setIsNotificationDialogOpen}
      open={isNotificationDialogOpen}
      onSave={handleSaveNotification}
      onDelete={notificationQuery.data ? handleDeleteNotification : undefined}
      disabled={clientMutation.isPending}
      loading={clientMutation.isPending}
      trigger={trigger}
    />
  );
};
