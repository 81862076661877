import { Button, FormControl, FormField, FormItem, FormLabel, Input } from '@kalos/ui';

import { usePropertySearchFormContext } from './utils';

const PropertySearchForm = () => {
  const form = usePropertySearchFormContext();
  return (
    // intentionally ignored as values are used as they are typed in
    <form
      className="grid grid-cols-2 place-content-center gap-x-4 gap-y-3 md:grid-cols-3 lg:grid-cols-[repeat(4,minmax(0,1fr))_5rem]"
      onSubmit={form.handleSubmit(console.log)}
    >
      <FormField
        control={form.control}
        name="address"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Address</FormLabel>
            <FormControl>
              <Input {...field} />
            </FormControl>
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="subdivision"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Subdivision</FormLabel>
            <FormControl>
              <Input {...field} />
            </FormControl>
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="city"
        render={({ field }) => (
          <FormItem>
            <FormLabel>City</FormLabel>
            <FormControl>
              <Input {...field} />
            </FormControl>
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="zip"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Zip Code</FormLabel>
            <FormControl>
              <Input {...field} />
            </FormControl>
          </FormItem>
        )}
      />
      <Button
        type="reset"
        variant="outline"
        className="mt-auto max-w-max"
        onClick={() => form.reset()}
      >
        Reset
      </Button>
    </form>
  );
};

export default PropertySearchForm;
