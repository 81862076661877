// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "credit_card.proto" (syntax proto3)
// tslint:disable
import { CreditCardService } from "./credit_card";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service CreditCardService
 */
export class CreditCardServiceClient {
    constructor(_transport) {
        this._transport = _transport;
        this.typeName = CreditCardService.typeName;
        this.methods = CreditCardService.methods;
        this.options = CreditCardService.options;
    }
    /**
     * @generated from protobuf rpc: Create(CreditCard) returns (CreditCard);
     */
    create(input, options) {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Get(CreditCard) returns (CreditCard);
     */
    get(input, options) {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGet(CreditCard) returns (CreditCardList);
     */
    batchGet(input, options) {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Update(CreditCard) returns (CreditCard);
     */
    update(input, options) {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Delete(CreditCard) returns (CreditCard);
     */
    delete(input, options) {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
}
