// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "samsara.proto" (syntax proto3)
// tslint:disable
import { SamsaraService } from "./samsara";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service SamsaraService
 */
export class SamsaraServiceClient {
    constructor(_transport) {
        this._transport = _transport;
        this.typeName = SamsaraService.typeName;
        this.methods = SamsaraService.methods;
        this.options = SamsaraService.options;
    }
    /**
     * @generated from protobuf rpc: BatchGetDrivers(Empty) returns (SamsaraDriversResponse);
     */
    batchGetDrivers(input, options) {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetLocations(Empty) returns (SamsaraLocationResponse);
     */
    batchGetLocations(input, options) {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateAddress(SamsaraAddress) returns (String);
     */
    createAddress(input, options) {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetVehicles(Empty) returns (SamsaraVehicleResponse);
     */
    batchGetVehicles(input, options) {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateVehicle(SamsaraVehicle) returns (SamsaraVehicleResponseSingular);
     */
    updateVehicle(input, options) {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateDriver(SamsaraDriverReq) returns (SamsaraDriverResponseSingular);
     */
    createDriver(input, options) {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ValidateVehicleList(Empty) returns (Empty);
     */
    validateVehicleList(input, options) {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
}
