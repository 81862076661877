import { zodResolver } from '@hookform/resolvers/zod';
import { type EmployeeItem } from '@kalos/kalos-rpc';
import {
  Button,
  DateInput,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
} from '@kalos/ui';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';

import { type DirtyModelFields } from '../../tools/helpers';
import { type ZodObjectWithModelKeys } from '../../tools/typeguargs';

type EmployeeItemsFormFields = Pick<EmployeeItem, 'name' | 'date'>;
const employeeItemsFormSchema = z.object({
  name: z.string().min(1, 'Please enter a name'),
  date: z.date(),
}) satisfies ZodObjectWithModelKeys<EmployeeItemsFormFields>;
type EmployeeItemsFormSchema = z.infer<typeof employeeItemsFormSchema>;
export const getDefaultEmployeeItemFormValues = (
  data?: Partial<EmployeeItemsFormSchema>,
): EmployeeItemsFormSchema => ({
  date: new Date(),
  name: '',
  ...data,
});

export const EmployeeItemForm: React.FC<{
  defaultValues: EmployeeItemsFormSchema;
  onSave: (data: {
    data: EmployeeItemsFormSchema;
    dirtyFields: DirtyModelFields<EmployeeItemsFormSchema>;
  }) => void;
  isLoading?: boolean;
  disabled?: boolean;
}> = ({ defaultValues, onSave, disabled, isLoading }) => {
  const form = useForm<EmployeeItemsFormSchema>({
    resolver: zodResolver(employeeItemsFormSchema),
    defaultValues,
    disabled,
  });
  const handleFormSubmit: SubmitHandler<EmployeeItemsFormSchema> = (data) => {
    onSave({
      data,
      dirtyFields: form.formState.dirtyFields,
    });
  };

  return (
    <form
      className="flex max-h-[calc(100vh-100px)] flex-col gap-4 overflow-y-auto p-4"
      onSubmit={form.handleSubmit(handleFormSubmit)}
    >
      <Form {...form}>
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Title</FormLabel>
              <FormMessage />
              <FormControl>
                <Input {...field} />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="date"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Issued Date</FormLabel>
              <FormMessage />
              <FormControl>
                <DateInput
                  onChange={field.onChange}
                  disabled={field.disabled}
                  value={field.value}
                />
              </FormControl>
            </FormItem>
          )}
        />

        <Button
          className="mt-4"
          disabled={disabled || !form.formState.isDirty}
          isLoading={isLoading}
        >
          Save
        </Button>
      </Form>
    </form>
  );
};
