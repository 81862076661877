// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "reimbursement.proto" (syntax proto3)
// tslint:disable
// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "reimbursement.proto" (syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { WireType } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
// @generated message type with reflection information, may provide speed optimized methods
class Reimbursement$Type extends MessageType {
    constructor() {
        super("Reimbursement", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "amount", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 4, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "reviewer_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "review_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "payroll_processed", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "processed_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "status", kind: "message", T: () => ReimbursementStatus },
            { no: 11, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 13, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "not_equals", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "documents", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ReimbursementDocument },
            { no: 17, name: "creator_user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 18, name: "created_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 19, name: "is_active", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 20, name: "date_target", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 21, name: "date_range", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 22, name: "status_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 23, name: "department_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 24, name: "job_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 25, name: "group_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 26, name: "owner_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 27, name: "reviewer_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 28, name: "without_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, userId: 0, amount: 0, description: "", reviewerId: 0, reviewDate: "", payrollProcessed: false, processedDate: "", fieldMask: [], pageNumber: 0, orderBy: "", orderDir: "", notEquals: [], documents: [], creatorUserId: 0, createdDate: "", isActive: 0, dateTarget: [], dateRange: [], statusId: 0, departmentId: 0, jobNumber: 0, groupBy: "", ownerName: "", reviewerName: "", withoutLimit: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 user_id */ 2:
                    message.userId = reader.int32();
                    break;
                case /* double amount */ 3:
                    message.amount = reader.double();
                    break;
                case /* string description */ 4:
                    message.description = reader.string();
                    break;
                case /* int32 reviewer_id */ 6:
                    message.reviewerId = reader.int32();
                    break;
                case /* string review_date */ 7:
                    message.reviewDate = reader.string();
                    break;
                case /* bool payroll_processed */ 8:
                    message.payrollProcessed = reader.bool();
                    break;
                case /* string processed_date */ 9:
                    message.processedDate = reader.string();
                    break;
                case /* ReimbursementStatus status */ 10:
                    message.status = ReimbursementStatus.internalBinaryRead(reader, reader.uint32(), options, message.status);
                    break;
                case /* repeated string field_mask */ 11:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 12:
                    message.pageNumber = reader.int32();
                    break;
                case /* string order_by */ 13:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 14:
                    message.orderDir = reader.string();
                    break;
                case /* repeated string not_equals */ 15:
                    message.notEquals.push(reader.string());
                    break;
                case /* repeated ReimbursementDocument documents */ 16:
                    message.documents.push(ReimbursementDocument.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 creator_user_id */ 17:
                    message.creatorUserId = reader.int32();
                    break;
                case /* string created_date */ 18:
                    message.createdDate = reader.string();
                    break;
                case /* int32 is_active */ 19:
                    message.isActive = reader.int32();
                    break;
                case /* repeated string date_target */ 20:
                    message.dateTarget.push(reader.string());
                    break;
                case /* repeated string date_range */ 21:
                    message.dateRange.push(reader.string());
                    break;
                case /* int32 status_id */ 22:
                    message.statusId = reader.int32();
                    break;
                case /* int32 department_id */ 23:
                    message.departmentId = reader.int32();
                    break;
                case /* int32 job_number */ 24:
                    message.jobNumber = reader.int32();
                    break;
                case /* string group_by */ 25:
                    message.groupBy = reader.string();
                    break;
                case /* string owner_name */ 26:
                    message.ownerName = reader.string();
                    break;
                case /* string reviewer_name */ 27:
                    message.reviewerName = reader.string();
                    break;
                case /* bool without_limit */ 28:
                    message.withoutLimit = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 user_id = 2; */
        if (message.userId !== 0)
            writer.tag(2, WireType.Varint).int32(message.userId);
        /* double amount = 3; */
        if (message.amount !== 0)
            writer.tag(3, WireType.Bit64).double(message.amount);
        /* string description = 4; */
        if (message.description !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.description);
        /* int32 reviewer_id = 6; */
        if (message.reviewerId !== 0)
            writer.tag(6, WireType.Varint).int32(message.reviewerId);
        /* string review_date = 7; */
        if (message.reviewDate !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.reviewDate);
        /* bool payroll_processed = 8; */
        if (message.payrollProcessed !== false)
            writer.tag(8, WireType.Varint).bool(message.payrollProcessed);
        /* string processed_date = 9; */
        if (message.processedDate !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.processedDate);
        /* ReimbursementStatus status = 10; */
        if (message.status)
            ReimbursementStatus.internalBinaryWrite(message.status, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* repeated string field_mask = 11; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(11, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 12; */
        if (message.pageNumber !== 0)
            writer.tag(12, WireType.Varint).int32(message.pageNumber);
        /* string order_by = 13; */
        if (message.orderBy !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 14; */
        if (message.orderDir !== "")
            writer.tag(14, WireType.LengthDelimited).string(message.orderDir);
        /* repeated string not_equals = 15; */
        for (let i = 0; i < message.notEquals.length; i++)
            writer.tag(15, WireType.LengthDelimited).string(message.notEquals[i]);
        /* repeated ReimbursementDocument documents = 16; */
        for (let i = 0; i < message.documents.length; i++)
            ReimbursementDocument.internalBinaryWrite(message.documents[i], writer.tag(16, WireType.LengthDelimited).fork(), options).join();
        /* int32 creator_user_id = 17; */
        if (message.creatorUserId !== 0)
            writer.tag(17, WireType.Varint).int32(message.creatorUserId);
        /* string created_date = 18; */
        if (message.createdDate !== "")
            writer.tag(18, WireType.LengthDelimited).string(message.createdDate);
        /* int32 is_active = 19; */
        if (message.isActive !== 0)
            writer.tag(19, WireType.Varint).int32(message.isActive);
        /* repeated string date_target = 20; */
        for (let i = 0; i < message.dateTarget.length; i++)
            writer.tag(20, WireType.LengthDelimited).string(message.dateTarget[i]);
        /* repeated string date_range = 21; */
        for (let i = 0; i < message.dateRange.length; i++)
            writer.tag(21, WireType.LengthDelimited).string(message.dateRange[i]);
        /* int32 status_id = 22; */
        if (message.statusId !== 0)
            writer.tag(22, WireType.Varint).int32(message.statusId);
        /* int32 department_id = 23; */
        if (message.departmentId !== 0)
            writer.tag(23, WireType.Varint).int32(message.departmentId);
        /* int32 job_number = 24; */
        if (message.jobNumber !== 0)
            writer.tag(24, WireType.Varint).int32(message.jobNumber);
        /* string group_by = 25; */
        if (message.groupBy !== "")
            writer.tag(25, WireType.LengthDelimited).string(message.groupBy);
        /* string owner_name = 26; */
        if (message.ownerName !== "")
            writer.tag(26, WireType.LengthDelimited).string(message.ownerName);
        /* string reviewer_name = 27; */
        if (message.reviewerName !== "")
            writer.tag(27, WireType.LengthDelimited).string(message.reviewerName);
        /* bool without_limit = 28; */
        if (message.withoutLimit !== false)
            writer.tag(28, WireType.Varint).bool(message.withoutLimit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Reimbursement
 */
export const Reimbursement = new Reimbursement$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReimbursementList$Type extends MessageType {
    constructor() {
        super("ReimbursementList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Reimbursement },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Reimbursement results */ 1:
                    message.results.push(Reimbursement.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated Reimbursement results = 1; */
        for (let i = 0; i < message.results.length; i++)
            Reimbursement.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ReimbursementList
 */
export const ReimbursementList = new ReimbursementList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReimbursementStatus$Type extends MessageType {
    constructor() {
        super("ReimbursementStatus", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, name: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ReimbursementStatus
 */
export const ReimbursementStatus = new ReimbursementStatus$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReimbursementDocument$Type extends MessageType {
    constructor() {
        super("ReimbursementDocument", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "reimbursement_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "file_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, reimbursementId: 0, description: "", fileId: 0, fieldMask: [], pageNumber: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 reimbursement_id */ 2:
                    message.reimbursementId = reader.int32();
                    break;
                case /* string description */ 3:
                    message.description = reader.string();
                    break;
                case /* int32 file_id */ 4:
                    message.fileId = reader.int32();
                    break;
                case /* repeated string field_mask */ 5:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 6:
                    message.pageNumber = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 reimbursement_id = 2; */
        if (message.reimbursementId !== 0)
            writer.tag(2, WireType.Varint).int32(message.reimbursementId);
        /* string description = 3; */
        if (message.description !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.description);
        /* int32 file_id = 4; */
        if (message.fileId !== 0)
            writer.tag(4, WireType.Varint).int32(message.fileId);
        /* repeated string field_mask = 5; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(5, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 6; */
        if (message.pageNumber !== 0)
            writer.tag(6, WireType.Varint).int32(message.pageNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ReimbursementDocument
 */
export const ReimbursementDocument = new ReimbursementDocument$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReimbursementDocumentList$Type extends MessageType {
    constructor() {
        super("ReimbursementDocumentList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ReimbursementDocument },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated ReimbursementDocument results */ 1:
                    message.results.push(ReimbursementDocument.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated ReimbursementDocument results = 1; */
        for (let i = 0; i < message.results.length; i++)
            ReimbursementDocument.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ReimbursementDocumentList
 */
export const ReimbursementDocumentList = new ReimbursementDocumentList$Type();
/**
 * @generated ServiceType for protobuf service ReimbursementService
 */
export const ReimbursementService = new ServiceType("ReimbursementService", [
    { name: "Create", options: {}, I: Reimbursement, O: Reimbursement },
    { name: "Get", options: {}, I: Reimbursement, O: Reimbursement },
    { name: "BatchGet", options: {}, I: Reimbursement, O: ReimbursementList },
    { name: "Update", options: {}, I: Reimbursement, O: Reimbursement },
    { name: "Delete", options: {}, I: Reimbursement, O: Reimbursement },
    { name: "CreateDocument", options: {}, I: ReimbursementDocument, O: ReimbursementDocument },
    { name: "GetDocument", options: {}, I: ReimbursementDocument, O: ReimbursementDocument },
    { name: "BatchGetDocument", options: {}, I: ReimbursementDocument, O: ReimbursementDocumentList },
    { name: "UpdateDocument", options: {}, I: ReimbursementDocument, O: ReimbursementDocument },
    { name: "DeleteDocument", options: {}, I: ReimbursementDocument, O: ReimbursementDocument }
]);
