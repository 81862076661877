// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "auth.proto" (syntax proto3)
// tslint:disable
import { AuthService } from "./auth";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service AuthService
 */
export class AuthServiceClient {
    constructor(_transport) {
        this._transport = _transport;
        this.typeName = AuthService.typeName;
        this.methods = AuthService.methods;
        this.options = AuthService.options;
    }
    /**
     * @generated from protobuf rpc: GetToken(AuthData) returns (Token);
     */
    getToken(input, options) {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GoogleAuthenticate(GoogleToken) returns (GoogleUser);
     */
    googleAuthenticate(input, options) {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetCustomerInvoiceData(Token) returns (CustomerInvoiceInformation);
     */
    getCustomerInvoiceData(input, options) {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
}
