import { TransactionStatus, type TransactionStatusList } from '@kalos/kalos-rpc';
import { useQuery } from '@tanstack/react-query';

import { TransactionStatusClientService } from '../../tools/helpers';
import { queryKeys } from './constants';
import { type EntityFilter } from './utils';

export type TransactionStatusFilters = EntityFilter<TransactionStatus>;
export const useTransactionStatusBatchQuery = <TSelectData = TransactionStatusList,>({
  enabled = true,
  select,
}: { enabled?: boolean; select?: (data?: TransactionStatusList) => TSelectData } = {}) => {
  return useQuery({
    queryFn: () => {
      const status = TransactionStatus.create();
      return TransactionStatusClientService.BatchGet(status);
    },
    queryKey: [queryKeys.transactionStatus.root, queryKeys.transactionStatus.list],
    enabled,
    select,
  });
};

export const useTransactionStatusQuery = <TSelectData = TransactionStatus,>({
  enabled = true,
  select,
  filter = {},
}: {
  enabled?: boolean;
  filter?: TransactionStatusFilters;
  select?: (data?: TransactionStatus) => TSelectData;
} = {}) => {
  return useQuery({
    queryFn: () => {
      const status = TransactionStatus.create(filter);
      return TransactionStatusClientService.Get(status);
    },
    queryKey: [queryKeys.transactionStatus.root, queryKeys.transactionStatus.getHash(filter)],
    enabled,
    select,
  });
};
