// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "job_type.proto" (syntax proto3)
// tslint:disable
import { JobTypeService } from "./job_type";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service JobTypeService
 */
export class JobTypeServiceClient {
    constructor(_transport) {
        this._transport = _transport;
        this.typeName = JobTypeService.typeName;
        this.methods = JobTypeService.methods;
        this.options = JobTypeService.options;
    }
    /**
     * @generated from protobuf rpc: GetJobType(JobType) returns (JobType);
     */
    getJobType(input, options) {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetJobType(JobType) returns (JobTypeList);
     */
    batchGetJobType(input, options) {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetJobSubtype(JobSubtype) returns (JobSubtype);
     */
    getJobSubtype(input, options) {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetJobSubtype(JobSubtype) returns (JobSubtypeList);
     */
    batchGetJobSubtype(input, options) {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateJobTypeSubtype(JobTypeSubtype) returns (JobTypeSubtype);
     */
    createJobTypeSubtype(input, options) {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetJobTypeSubtype(JobTypeSubtype) returns (JobTypeSubtype);
     */
    getJobTypeSubtype(input, options) {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetJobTypeSubtype(JobTypeSubtype) returns (JobTypeSubtypeList);
     */
    batchGetJobTypeSubtype(input, options) {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateJobTypeSubtype(JobTypeSubtype) returns (JobTypeSubtype);
     */
    updateJobTypeSubtype(input, options) {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteJobTypeSubtype(JobTypeSubtype) returns (JobTypeSubtype);
     */
    deleteJobTypeSubtype(input, options) {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
}
