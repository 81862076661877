// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "maps.proto" (syntax proto3)
// tslint:disable
// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "maps.proto" (syntax proto3)
// tslint:disable
import { Double } from "./common";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { WireType } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
// @generated message type with reflection information, may provide speed optimized methods
class MatrixRequest$Type extends MessageType {
    constructor() {
        super("MatrixRequest", [
            { no: 1, name: "origins", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Coordinates },
            { no: 2, name: "destination", kind: "message", T: () => Coordinates }
        ]);
    }
    create(value) {
        const message = { origins: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Coordinates origins */ 1:
                    message.origins.push(Coordinates.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* Coordinates destination */ 2:
                    message.destination = Coordinates.internalBinaryRead(reader, reader.uint32(), options, message.destination);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated Coordinates origins = 1; */
        for (let i = 0; i < message.origins.length; i++)
            Coordinates.internalBinaryWrite(message.origins[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* Coordinates destination = 2; */
        if (message.destination)
            Coordinates.internalBinaryWrite(message.destination, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message MatrixRequest
 */
export const MatrixRequest = new MatrixRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DistanceMatrixResponse$Type extends MessageType {
    constructor() {
        super("DistanceMatrixResponse", [
            { no: 1, name: "originAddresses", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "destinationAddresses", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "rows", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DistanceMatrixElementRow }
        ]);
    }
    create(value) {
        const message = { originAddresses: [], destinationAddresses: [], rows: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string originAddresses */ 1:
                    message.originAddresses.push(reader.string());
                    break;
                case /* repeated string destinationAddresses */ 2:
                    message.destinationAddresses.push(reader.string());
                    break;
                case /* repeated DistanceMatrixElementRow rows */ 3:
                    message.rows.push(DistanceMatrixElementRow.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated string originAddresses = 1; */
        for (let i = 0; i < message.originAddresses.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.originAddresses[i]);
        /* repeated string destinationAddresses = 2; */
        for (let i = 0; i < message.destinationAddresses.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.destinationAddresses[i]);
        /* repeated DistanceMatrixElementRow rows = 3; */
        for (let i = 0; i < message.rows.length; i++)
            DistanceMatrixElementRow.internalBinaryWrite(message.rows[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DistanceMatrixResponse
 */
export const DistanceMatrixResponse = new DistanceMatrixResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Place$Type extends MessageType {
    constructor() {
        super("Place", [
            { no: 1, name: "street_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "road_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "city", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "state", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "country", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "zip_code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "coords", kind: "message", T: () => Coordinates }
        ]);
    }
    create(value) {
        const message = { streetNumber: 0, roadName: "", city: "", state: "", country: "", zipCode: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 street_number */ 1:
                    message.streetNumber = reader.int32();
                    break;
                case /* string road_name */ 2:
                    message.roadName = reader.string();
                    break;
                case /* string city */ 3:
                    message.city = reader.string();
                    break;
                case /* string state */ 4:
                    message.state = reader.string();
                    break;
                case /* string country */ 5:
                    message.country = reader.string();
                    break;
                case /* string zip_code */ 6:
                    message.zipCode = reader.string();
                    break;
                case /* Coordinates coords */ 7:
                    message.coords = Coordinates.internalBinaryRead(reader, reader.uint32(), options, message.coords);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 street_number = 1; */
        if (message.streetNumber !== 0)
            writer.tag(1, WireType.Varint).int32(message.streetNumber);
        /* string road_name = 2; */
        if (message.roadName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.roadName);
        /* string city = 3; */
        if (message.city !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.city);
        /* string state = 4; */
        if (message.state !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.state);
        /* string country = 5; */
        if (message.country !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.country);
        /* string zip_code = 6; */
        if (message.zipCode !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.zipCode);
        /* Coordinates coords = 7; */
        if (message.coords)
            Coordinates.internalBinaryWrite(message.coords, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Place
 */
export const Place = new Place$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Places$Type extends MessageType {
    constructor() {
        super("Places", [
            { no: 1, name: "data", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Place }
        ]);
    }
    create(value) {
        const message = { data: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Place data */ 1:
                    message.data.push(Place.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated Place data = 1; */
        for (let i = 0; i < message.data.length; i++)
            Place.internalBinaryWrite(message.data[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Places
 */
export const Places = new Places$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TripData$Type extends MessageType {
    constructor() {
        super("TripData", [
            { no: 1, name: "distance_in_meters", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "time_in_ms", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value) {
        const message = { distanceInMeters: 0, timeInMs: 0n };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 distance_in_meters */ 1:
                    message.distanceInMeters = reader.int32();
                    break;
                case /* int64 time_in_ms */ 2:
                    message.timeInMs = reader.int64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 distance_in_meters = 1; */
        if (message.distanceInMeters !== 0)
            writer.tag(1, WireType.Varint).int32(message.distanceInMeters);
        /* int64 time_in_ms = 2; */
        if (message.timeInMs !== 0n)
            writer.tag(2, WireType.Varint).int64(message.timeInMs);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TripData
 */
export const TripData = new TripData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TripData_HumanReadableTime$Type extends MessageType {
    constructor() {
        super("TripData.HumanReadableTime", [
            { no: 1, name: "days", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "hours", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "minutes", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "seconds", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "display", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { days: 0, hours: 0, minutes: 0, seconds: 0, display: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 days */ 1:
                    message.days = reader.int32();
                    break;
                case /* int32 hours */ 2:
                    message.hours = reader.int32();
                    break;
                case /* int32 minutes */ 3:
                    message.minutes = reader.int32();
                    break;
                case /* int32 seconds */ 4:
                    message.seconds = reader.int32();
                    break;
                case /* string display */ 5:
                    message.display = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 days = 1; */
        if (message.days !== 0)
            writer.tag(1, WireType.Varint).int32(message.days);
        /* int32 hours = 2; */
        if (message.hours !== 0)
            writer.tag(2, WireType.Varint).int32(message.hours);
        /* int32 minutes = 3; */
        if (message.minutes !== 0)
            writer.tag(3, WireType.Varint).int32(message.minutes);
        /* int32 seconds = 4; */
        if (message.seconds !== 0)
            writer.tag(4, WireType.Varint).int32(message.seconds);
        /* string display = 5; */
        if (message.display !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.display);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TripData.HumanReadableTime
 */
export const TripData_HumanReadableTime = new TripData_HumanReadableTime$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Coordinates$Type extends MessageType {
    constructor() {
        super("Coordinates", [
            { no: 1, name: "latitude", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 2, name: "longitude", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value) {
        const message = { latitude: 0, longitude: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* double latitude */ 1:
                    message.latitude = reader.double();
                    break;
                case /* double longitude */ 2:
                    message.longitude = reader.double();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* double latitude = 1; */
        if (message.latitude !== 0)
            writer.tag(1, WireType.Bit64).double(message.latitude);
        /* double longitude = 2; */
        if (message.longitude !== 0)
            writer.tag(2, WireType.Bit64).double(message.longitude);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Coordinates
 */
export const Coordinates = new Coordinates$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DistanceMatrixElementRow$Type extends MessageType {
    constructor() {
        super("DistanceMatrixElementRow", [
            { no: 1, name: "distanceMatrixElement", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DistanceMatrixElement }
        ]);
    }
    create(value) {
        const message = { distanceMatrixElement: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated DistanceMatrixElement distanceMatrixElement */ 1:
                    message.distanceMatrixElement.push(DistanceMatrixElement.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated DistanceMatrixElement distanceMatrixElement = 1; */
        for (let i = 0; i < message.distanceMatrixElement.length; i++)
            DistanceMatrixElement.internalBinaryWrite(message.distanceMatrixElement[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DistanceMatrixElementRow
 */
export const DistanceMatrixElementRow = new DistanceMatrixElementRow$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DistanceMatrixElement$Type extends MessageType {
    constructor() {
        super("DistanceMatrixElement", [
            { no: 1, name: "status", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "duration", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 3, name: "durationInTraffic", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 4, name: "distance", kind: "message", T: () => Distance }
        ]);
    }
    create(value) {
        const message = { status: "", duration: 0n, durationInTraffic: 0n };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string status */ 1:
                    message.status = reader.string();
                    break;
                case /* int64 duration */ 2:
                    message.duration = reader.int64().toBigInt();
                    break;
                case /* int64 durationInTraffic */ 3:
                    message.durationInTraffic = reader.int64().toBigInt();
                    break;
                case /* Distance distance */ 4:
                    message.distance = Distance.internalBinaryRead(reader, reader.uint32(), options, message.distance);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string status = 1; */
        if (message.status !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.status);
        /* int64 duration = 2; */
        if (message.duration !== 0n)
            writer.tag(2, WireType.Varint).int64(message.duration);
        /* int64 durationInTraffic = 3; */
        if (message.durationInTraffic !== 0n)
            writer.tag(3, WireType.Varint).int64(message.durationInTraffic);
        /* Distance distance = 4; */
        if (message.distance)
            Distance.internalBinaryWrite(message.distance, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DistanceMatrixElement
 */
export const DistanceMatrixElement = new DistanceMatrixElement$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Distance$Type extends MessageType {
    constructor() {
        super("Distance", [
            { no: 1, name: "humanReadable", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "meters", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value) {
        const message = { humanReadable: "", meters: 0n };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string humanReadable */ 1:
                    message.humanReadable = reader.string();
                    break;
                case /* int64 meters */ 2:
                    message.meters = reader.int64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string humanReadable = 1; */
        if (message.humanReadable !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.humanReadable);
        /* int64 meters = 2; */
        if (message.meters !== 0n)
            writer.tag(2, WireType.Varint).int64(message.meters);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Distance
 */
export const Distance = new Distance$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CoordinatesList$Type extends MessageType {
    constructor() {
        super("CoordinatesList", [
            { no: 1, name: "data", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Coordinates }
        ]);
    }
    create(value) {
        const message = { data: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Coordinates data */ 1:
                    message.data.push(Coordinates.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated Coordinates data = 1; */
        for (let i = 0; i < message.data.length; i++)
            Coordinates.internalBinaryWrite(message.data[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CoordinatesList
 */
export const CoordinatesList = new CoordinatesList$Type();
/**
 * @generated ServiceType for protobuf service MapService
 */
export const MapService = new ServiceType("MapService", [
    { name: "Geocode", options: {}, I: Place, O: Coordinates },
    { name: "DistanceMatrix", options: {}, I: MatrixRequest, O: DistanceMatrixResponse },
    { name: "Elevation", options: {}, I: Coordinates, O: Double },
    { name: "GetDistanceBetweenPlaces", options: {}, I: Places, O: DistanceMatrixResponse }
]);
