import { FormControl, InputLabel, MenuItem, Select, type SelectChangeEvent } from '@mui/material';
import { useState } from 'react';

import { PoolHeaterSellSheet } from './PoolHeaterSellSheet';
import { RTUSellSheet } from './RTUSellSheet';
import { SellSheetCommercial } from './SellSheetCommercial';
import { SellSheetResidential } from './SellSheetResidential';
import { WaterHeaterSellSheet } from './WaterHeaterSellSheet';

export interface Props {
  propertyId: number;
  jobNumber: number;
  onCreateDoc?: () => void;
}
type SellSheetType = {
  type: string;
};

export const SellSheet: React.FC<Props> = ({ propertyId, jobNumber, onCreateDoc }) => {
  const [form, setForm] = useState<SellSheetType>({
    type: 'Choose One',
  });
  const handleChange = (event: SelectChangeEvent<string>) => {
    setForm({ type: event.target.value });
  };

  return (
    <div style={{ padding: '20px' }} className="relative">
      <FormControl className="properties-select">
        <InputLabel id="select-type-label" sx={{ p: 2 }}>
          Type
        </InputLabel>
        <Select
          className="min-w-[126px]"
          labelId="select-type-label"
          id="select-type"
          value={form.type}
          onChange={handleChange}
        >
          <MenuItem value="Choose One">Choose One</MenuItem>
          <MenuItem value="Residential">Residential</MenuItem>
          <MenuItem value="Commercial">Commercial</MenuItem>
          <MenuItem value="RTU">RTU</MenuItem>
          <MenuItem value="Water">Water Heater</MenuItem>
          <MenuItem value="Pool">Pool Heater</MenuItem>
        </Select>
      </FormControl>
      {form.type === 'Residential' && (
        <SellSheetResidential
          propertyId={propertyId}
          jobNumber={jobNumber}
          onCreateDoc={onCreateDoc}
        />
      )}
      {form.type === 'Commercial' && (
        <SellSheetCommercial
          propertyId={propertyId}
          jobNumber={jobNumber}
          onCreateDoc={onCreateDoc}
        />
      )}
      {form.type === 'RTU' && (
        <RTUSellSheet propertyId={propertyId} jobNumber={jobNumber} onCreateDoc={onCreateDoc} />
      )}
      {form.type === 'Water' && (
        <WaterHeaterSellSheet
          propertyId={propertyId}
          jobNumber={jobNumber}
          onCreateDoc={onCreateDoc}
        />
      )}
      {form.type === 'Pool' && (
        <PoolHeaterSellSheet
          propertyId={propertyId}
          jobNumber={jobNumber}
          onCreateDoc={onCreateDoc}
        />
      )}
    </div>
  );
};
