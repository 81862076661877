// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "transaction_accounts_receivable.proto" (syntax proto3)
// tslint:disable
// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "transaction_accounts_receivable.proto" (syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { WireType } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
// @generated message type with reflection information, may provide speed optimized methods
class TransactionAccountsReceivable$Type extends MessageType {
    constructor() {
        super("TransactionAccountsReceivable", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "status_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "billing_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "is_active", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "withLines", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "lines", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TransactionAccountsReceivableLine },
            { no: 8, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, userId: 0, statusId: 0, billingDate: "", isActive: 0, withLines: false, lines: [], fieldMask: [], pageNumber: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 user_id */ 2:
                    message.userId = reader.int32();
                    break;
                case /* int32 status_id */ 3:
                    message.statusId = reader.int32();
                    break;
                case /* string billing_date */ 4:
                    message.billingDate = reader.string();
                    break;
                case /* int32 is_active */ 5:
                    message.isActive = reader.int32();
                    break;
                case /* bool withLines */ 6:
                    message.withLines = reader.bool();
                    break;
                case /* repeated TransactionAccountsReceivableLine lines */ 7:
                    message.lines.push(TransactionAccountsReceivableLine.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated string field_mask */ 8:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 9:
                    message.pageNumber = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 user_id = 2; */
        if (message.userId !== 0)
            writer.tag(2, WireType.Varint).int32(message.userId);
        /* int32 status_id = 3; */
        if (message.statusId !== 0)
            writer.tag(3, WireType.Varint).int32(message.statusId);
        /* string billing_date = 4; */
        if (message.billingDate !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.billingDate);
        /* int32 is_active = 5; */
        if (message.isActive !== 0)
            writer.tag(5, WireType.Varint).int32(message.isActive);
        /* bool withLines = 6; */
        if (message.withLines !== false)
            writer.tag(6, WireType.Varint).bool(message.withLines);
        /* repeated TransactionAccountsReceivableLine lines = 7; */
        for (let i = 0; i < message.lines.length; i++)
            TransactionAccountsReceivableLine.internalBinaryWrite(message.lines[i], writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* repeated string field_mask = 8; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(8, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 9; */
        if (message.pageNumber !== 0)
            writer.tag(9, WireType.Varint).int32(message.pageNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TransactionAccountsReceivable
 */
export const TransactionAccountsReceivable = new TransactionAccountsReceivable$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TransactionAccountsReceivableList$Type extends MessageType {
    constructor() {
        super("TransactionAccountsReceivableList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TransactionAccountsReceivable },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated TransactionAccountsReceivable results */ 1:
                    message.results.push(TransactionAccountsReceivable.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated TransactionAccountsReceivable results = 1; */
        for (let i = 0; i < message.results.length; i++)
            TransactionAccountsReceivable.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TransactionAccountsReceivableList
 */
export const TransactionAccountsReceivableList = new TransactionAccountsReceivableList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TransactionAccountsReceivablePayment$Type extends MessageType {
    constructor() {
        super("TransactionAccountsReceivablePayment", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "amount", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 3, name: "date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "is_active", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "invoice_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "transaction_accounts_receivable_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, amount: 0, date: "", isActive: 0, invoiceId: 0, fieldMask: [], pageNumber: 0, transactionAccountsReceivableId: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* double amount */ 2:
                    message.amount = reader.double();
                    break;
                case /* string date */ 3:
                    message.date = reader.string();
                    break;
                case /* int32 is_active */ 4:
                    message.isActive = reader.int32();
                    break;
                case /* int32 invoice_id */ 5:
                    message.invoiceId = reader.int32();
                    break;
                case /* repeated string field_mask */ 6:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 7:
                    message.pageNumber = reader.int32();
                    break;
                case /* int32 transaction_accounts_receivable_id */ 8:
                    message.transactionAccountsReceivableId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* double amount = 2; */
        if (message.amount !== 0)
            writer.tag(2, WireType.Bit64).double(message.amount);
        /* string date = 3; */
        if (message.date !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.date);
        /* int32 is_active = 4; */
        if (message.isActive !== 0)
            writer.tag(4, WireType.Varint).int32(message.isActive);
        /* int32 invoice_id = 5; */
        if (message.invoiceId !== 0)
            writer.tag(5, WireType.Varint).int32(message.invoiceId);
        /* repeated string field_mask = 6; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(6, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 7; */
        if (message.pageNumber !== 0)
            writer.tag(7, WireType.Varint).int32(message.pageNumber);
        /* int32 transaction_accounts_receivable_id = 8; */
        if (message.transactionAccountsReceivableId !== 0)
            writer.tag(8, WireType.Varint).int32(message.transactionAccountsReceivableId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TransactionAccountsReceivablePayment
 */
export const TransactionAccountsReceivablePayment = new TransactionAccountsReceivablePayment$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TransactionAccountsReceivablePaymentList$Type extends MessageType {
    constructor() {
        super("TransactionAccountsReceivablePaymentList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TransactionAccountsReceivablePayment },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated TransactionAccountsReceivablePayment results */ 1:
                    message.results.push(TransactionAccountsReceivablePayment.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated TransactionAccountsReceivablePayment results = 1; */
        for (let i = 0; i < message.results.length; i++)
            TransactionAccountsReceivablePayment.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TransactionAccountsReceivablePaymentList
 */
export const TransactionAccountsReceivablePaymentList = new TransactionAccountsReceivablePaymentList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TransactionAccountsReceivableDocument$Type extends MessageType {
    constructor() {
        super("TransactionAccountsReceivableDocument", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "transaction_accounts_receivable_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "file_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "reference", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, transactionAccountsReceivableId: 0, fileId: 0, description: "", reference: "", fieldMask: [], pageNumber: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 transaction_accounts_receivable_id */ 2:
                    message.transactionAccountsReceivableId = reader.int32();
                    break;
                case /* int32 file_id */ 3:
                    message.fileId = reader.int32();
                    break;
                case /* string description */ 4:
                    message.description = reader.string();
                    break;
                case /* string reference */ 5:
                    message.reference = reader.string();
                    break;
                case /* repeated string field_mask */ 6:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 7:
                    message.pageNumber = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 transaction_accounts_receivable_id = 2; */
        if (message.transactionAccountsReceivableId !== 0)
            writer.tag(2, WireType.Varint).int32(message.transactionAccountsReceivableId);
        /* int32 file_id = 3; */
        if (message.fileId !== 0)
            writer.tag(3, WireType.Varint).int32(message.fileId);
        /* string description = 4; */
        if (message.description !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.description);
        /* string reference = 5; */
        if (message.reference !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.reference);
        /* repeated string field_mask = 6; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(6, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 7; */
        if (message.pageNumber !== 0)
            writer.tag(7, WireType.Varint).int32(message.pageNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TransactionAccountsReceivableDocument
 */
export const TransactionAccountsReceivableDocument = new TransactionAccountsReceivableDocument$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TransactionAccountsReceivableDocumentList$Type extends MessageType {
    constructor() {
        super("TransactionAccountsReceivableDocumentList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TransactionAccountsReceivableDocument },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated TransactionAccountsReceivableDocument results */ 1:
                    message.results.push(TransactionAccountsReceivableDocument.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated TransactionAccountsReceivableDocument results = 1; */
        for (let i = 0; i < message.results.length; i++)
            TransactionAccountsReceivableDocument.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TransactionAccountsReceivableDocumentList
 */
export const TransactionAccountsReceivableDocumentList = new TransactionAccountsReceivableDocumentList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TransactionAccountsReceivableLine$Type extends MessageType {
    constructor() {
        super("TransactionAccountsReceivableLine", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "transaction_accounts_receivable_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "job_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "invoice_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "date_created", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "department_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "property_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "is_active", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 11, name: "lineItems", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TransactionAccountsReceivableLineItem },
            { no: 12, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 14, name: "withLineItems", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, transactionAccountsReceivableId: 0, jobNumber: 0, invoiceId: 0, notes: "", description: "", dateCreated: "", departmentId: 0, propertyId: 0, isActive: 0, lineItems: [], fieldMask: [], pageNumber: 0, withLineItems: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 transaction_accounts_receivable_id */ 2:
                    message.transactionAccountsReceivableId = reader.int32();
                    break;
                case /* int32 job_number */ 3:
                    message.jobNumber = reader.int32();
                    break;
                case /* int32 invoice_id */ 4:
                    message.invoiceId = reader.int32();
                    break;
                case /* string notes */ 5:
                    message.notes = reader.string();
                    break;
                case /* string description */ 6:
                    message.description = reader.string();
                    break;
                case /* string date_created */ 7:
                    message.dateCreated = reader.string();
                    break;
                case /* int32 department_id */ 8:
                    message.departmentId = reader.int32();
                    break;
                case /* int32 property_id */ 9:
                    message.propertyId = reader.int32();
                    break;
                case /* int32 is_active */ 10:
                    message.isActive = reader.int32();
                    break;
                case /* repeated TransactionAccountsReceivableLineItem lineItems */ 11:
                    message.lineItems.push(TransactionAccountsReceivableLineItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated string field_mask */ 12:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 13:
                    message.pageNumber = reader.int32();
                    break;
                case /* bool withLineItems */ 14:
                    message.withLineItems = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 transaction_accounts_receivable_id = 2; */
        if (message.transactionAccountsReceivableId !== 0)
            writer.tag(2, WireType.Varint).int32(message.transactionAccountsReceivableId);
        /* int32 job_number = 3; */
        if (message.jobNumber !== 0)
            writer.tag(3, WireType.Varint).int32(message.jobNumber);
        /* int32 invoice_id = 4; */
        if (message.invoiceId !== 0)
            writer.tag(4, WireType.Varint).int32(message.invoiceId);
        /* string notes = 5; */
        if (message.notes !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.notes);
        /* string description = 6; */
        if (message.description !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.description);
        /* string date_created = 7; */
        if (message.dateCreated !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.dateCreated);
        /* int32 department_id = 8; */
        if (message.departmentId !== 0)
            writer.tag(8, WireType.Varint).int32(message.departmentId);
        /* int32 property_id = 9; */
        if (message.propertyId !== 0)
            writer.tag(9, WireType.Varint).int32(message.propertyId);
        /* int32 is_active = 10; */
        if (message.isActive !== 0)
            writer.tag(10, WireType.Varint).int32(message.isActive);
        /* repeated TransactionAccountsReceivableLineItem lineItems = 11; */
        for (let i = 0; i < message.lineItems.length; i++)
            TransactionAccountsReceivableLineItem.internalBinaryWrite(message.lineItems[i], writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* repeated string field_mask = 12; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(12, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 13; */
        if (message.pageNumber !== 0)
            writer.tag(13, WireType.Varint).int32(message.pageNumber);
        /* bool withLineItems = 14; */
        if (message.withLineItems !== false)
            writer.tag(14, WireType.Varint).bool(message.withLineItems);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TransactionAccountsReceivableLine
 */
export const TransactionAccountsReceivableLine = new TransactionAccountsReceivableLine$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TransactionAccountsReceivableLineList$Type extends MessageType {
    constructor() {
        super("TransactionAccountsReceivableLineList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TransactionAccountsReceivableLine },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated TransactionAccountsReceivableLine results */ 1:
                    message.results.push(TransactionAccountsReceivableLine.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated TransactionAccountsReceivableLine results = 1; */
        for (let i = 0; i < message.results.length; i++)
            TransactionAccountsReceivableLine.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TransactionAccountsReceivableLineList
 */
export const TransactionAccountsReceivableLineList = new TransactionAccountsReceivableLineList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TransactionAccountsReceivableLineItem$Type extends MessageType {
    constructor() {
        super("TransactionAccountsReceivableLineItem", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "transaction_accounts_receivable_line_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "date_created", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "is_active", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "amount", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 8, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, transactionAccountsReceivableLineId: 0, notes: "", description: "", dateCreated: "", isActive: 0, amount: 0, fieldMask: [], pageNumber: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 transaction_accounts_receivable_line_id */ 2:
                    message.transactionAccountsReceivableLineId = reader.int32();
                    break;
                case /* string notes */ 3:
                    message.notes = reader.string();
                    break;
                case /* string description */ 4:
                    message.description = reader.string();
                    break;
                case /* string date_created */ 5:
                    message.dateCreated = reader.string();
                    break;
                case /* int32 is_active */ 6:
                    message.isActive = reader.int32();
                    break;
                case /* double amount */ 7:
                    message.amount = reader.double();
                    break;
                case /* repeated string field_mask */ 8:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 9:
                    message.pageNumber = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 transaction_accounts_receivable_line_id = 2; */
        if (message.transactionAccountsReceivableLineId !== 0)
            writer.tag(2, WireType.Varint).int32(message.transactionAccountsReceivableLineId);
        /* string notes = 3; */
        if (message.notes !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.notes);
        /* string description = 4; */
        if (message.description !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.description);
        /* string date_created = 5; */
        if (message.dateCreated !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.dateCreated);
        /* int32 is_active = 6; */
        if (message.isActive !== 0)
            writer.tag(6, WireType.Varint).int32(message.isActive);
        /* double amount = 7; */
        if (message.amount !== 0)
            writer.tag(7, WireType.Bit64).double(message.amount);
        /* repeated string field_mask = 8; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(8, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 9; */
        if (message.pageNumber !== 0)
            writer.tag(9, WireType.Varint).int32(message.pageNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TransactionAccountsReceivableLineItem
 */
export const TransactionAccountsReceivableLineItem = new TransactionAccountsReceivableLineItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TransactionAccountsReceivableLineItemList$Type extends MessageType {
    constructor() {
        super("TransactionAccountsReceivableLineItemList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TransactionAccountsReceivableLineItem },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated TransactionAccountsReceivableLineItem results */ 1:
                    message.results.push(TransactionAccountsReceivableLineItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated TransactionAccountsReceivableLineItem results = 1; */
        for (let i = 0; i < message.results.length; i++)
            TransactionAccountsReceivableLineItem.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TransactionAccountsReceivableLineItemList
 */
export const TransactionAccountsReceivableLineItemList = new TransactionAccountsReceivableLineItemList$Type();
/**
 * @generated ServiceType for protobuf service TransactionAccountsReceivableService
 */
export const TransactionAccountsReceivableService = new ServiceType("TransactionAccountsReceivableService", [
    { name: "Create", options: {}, I: TransactionAccountsReceivable, O: TransactionAccountsReceivable },
    { name: "Get", options: {}, I: TransactionAccountsReceivable, O: TransactionAccountsReceivable },
    { name: "BatchGet", options: {}, I: TransactionAccountsReceivable, O: TransactionAccountsReceivableList },
    { name: "Update", options: {}, I: TransactionAccountsReceivable, O: TransactionAccountsReceivable },
    { name: "Delete", options: {}, I: TransactionAccountsReceivable, O: TransactionAccountsReceivable },
    { name: "CreateLine", options: {}, I: TransactionAccountsReceivableLine, O: TransactionAccountsReceivableLine },
    { name: "GetLine", options: {}, I: TransactionAccountsReceivableLine, O: TransactionAccountsReceivableLine },
    { name: "BatchGetLine", options: {}, I: TransactionAccountsReceivableLine, O: TransactionAccountsReceivableLineList },
    { name: "UpdateLine", options: {}, I: TransactionAccountsReceivableLine, O: TransactionAccountsReceivableLine },
    { name: "DeleteLine", options: {}, I: TransactionAccountsReceivableLine, O: TransactionAccountsReceivableLine },
    { name: "CreateLineItem", options: {}, I: TransactionAccountsReceivableLineItem, O: TransactionAccountsReceivableLineItem },
    { name: "GetLineItem", options: {}, I: TransactionAccountsReceivableLineItem, O: TransactionAccountsReceivableLineItem },
    { name: "BatchGetLineItem", options: {}, I: TransactionAccountsReceivableLineItem, O: TransactionAccountsReceivableLineItemList },
    { name: "UpdateLineItem", options: {}, I: TransactionAccountsReceivableLineItem, O: TransactionAccountsReceivableLineItem },
    { name: "DeleteLineItem", options: {}, I: TransactionAccountsReceivableLineItem, O: TransactionAccountsReceivableLineItem },
    { name: "CreatePayment", options: {}, I: TransactionAccountsReceivablePayment, O: TransactionAccountsReceivablePayment },
    { name: "GetPayment", options: {}, I: TransactionAccountsReceivablePayment, O: TransactionAccountsReceivablePayment },
    { name: "BatchGetPayment", options: {}, I: TransactionAccountsReceivablePayment, O: TransactionAccountsReceivablePaymentList },
    { name: "UpdatePayment", options: {}, I: TransactionAccountsReceivablePayment, O: TransactionAccountsReceivablePayment },
    { name: "DeletePayment", options: {}, I: TransactionAccountsReceivablePayment, O: TransactionAccountsReceivablePayment },
    { name: "CreateDocument", options: {}, I: TransactionAccountsReceivableDocument, O: TransactionAccountsReceivableDocument },
    { name: "GetDocument", options: {}, I: TransactionAccountsReceivableDocument, O: TransactionAccountsReceivableDocument },
    { name: "BatchGetDocument", options: {}, I: TransactionAccountsReceivableDocument, O: TransactionAccountsReceivableDocumentList },
    { name: "UpdateDocument", options: {}, I: TransactionAccountsReceivableDocument, O: TransactionAccountsReceivableDocument },
    { name: "DeleteDocument", options: {}, I: TransactionAccountsReceivableDocument, O: TransactionAccountsReceivableDocument }
]);
