import { type Reimbursement } from '@kalos/kalos-rpc';
import { memo, useMemo } from 'react';

export const REIMBURSEMENT_STATUSES = [
  { label: 'All', value: 0, color: '#A88' },
  { label: 'Pending', value: 1, color: '#FFFF00' },
  { label: 'Submitted', value: 2, color: '#0000FF' },
  { label: 'Approved', value: 3, color: '#080' },
  { label: 'Rejected', value: 4, color: '#D00' },
  { label: 'Processed', value: 5, color: '#CCC' },
];

export const ReimbursementStatusLabel = memo(({ id }: { id: Reimbursement['statusId'] }) => {
  const status = useMemo(() => REIMBURSEMENT_STATUSES.find((s) => s.value === id), [id]);
  if (!status) return null;
  return (
    <div className="flex items-center gap-2">
      <div className="size-4 rounded-full" style={{ background: status.color }} />
      <span>{status.label}</span>
    </div>
  );
});
