import { Button, DateInput, FormControl, FormField, FormItem, FormLabel, Input } from '@kalos/ui';

import { useContractsSearchFormContext } from './utils';

const ContractsSearchForm = () => {
  const form = useContractsSearchFormContext();
  return (
    // intentionally ignored as values are used as they are typed in
    <form
      className="grid grid-cols-2 place-content-center items-end gap-x-4 gap-y-3 lg:grid-cols-[repeat(5,minmax(0,1fr))_5rem]"
      onSubmit={form.handleSubmit(console.log)}
    >
      <FormField
        control={form.control}
        name="number"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Contract Number</FormLabel>
            <FormControl>
              <Input {...field} />
            </FormControl>
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="lastName"
        render={({ field }) => {
          return (
            <FormItem>
              <FormLabel>Last Name</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
            </FormItem>
          );
        }}
      />
      <FormField
        control={form.control}
        name="businessName"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Business Name</FormLabel>
            <FormControl>
              <Input {...field} />
            </FormControl>
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="dateStarted"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Contract Start Date</FormLabel>
            <DateInput value={field.value} onChange={field.onChange} />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="dateEnded"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Contract End Date</FormLabel>
            <DateInput value={field.value} onChange={field.onChange} />
          </FormItem>
        )}
      />
      <Button
        type="reset"
        variant="outline"
        className="mt-auto max-w-max"
        onClick={() => form.reset()}
      >
        Reset
      </Button>
    </form>
  );
};

export default ContractsSearchForm;
