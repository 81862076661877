import { StyledEngineProvider } from '@mui/material/styles';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { type FC, type PropsWithChildren } from 'react';

import customTheme from '../Theme/main';

export const StyledPage: FC<PropsWithChildren> = ({ children }) => (
  <StyledEngineProvider>
    <ThemeProvider theme={customTheme.lightTheme}>{children}</ThemeProvider>
  </StyledEngineProvider>
);
