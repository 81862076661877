import { PermissionGroup, PermissionGroupUser } from '@kalos/kalos-rpc';
import { type FC, useCallback, useEffect, useReducer } from 'react';

import { UserClientService } from '../../../tools/helpers';
import { Loader } from '../../Loader/main';
import { Button } from '../Button';
import { type Schema } from '../Form';
import { PlainForm } from '../PlainForm';
import { SectionBar } from '../SectionBar';
import { type FormData, reducer } from './reducer';
interface Props {
  userId: number;
  permissionType: string;
  userPermissions: PermissionGroup[];
  loggedUserPermissions: PermissionGroup[];
  limitMultiSelect: boolean;
  onClose?: () => void;
}

export const AddPermission: FC<Props> = ({
  userId,
  loggedUserPermissions,
  permissionType,
  userPermissions,
  onClose,
  limitMultiSelect,
}) => {
  const [state, dispatch] = useReducer(reducer, {
    init: true,
    loaded: false,
    permissionsLoaded: [],
    selectedPermissions: { permissionIds: [] },
  });
  const { init, loaded, selectedPermissions } = state;
  console.log('disable mui?', limitMultiSelect);
  const SCHEMA_PRINT: Schema<FormData> = [
    [
      {
        name: 'permissionIds',
        label: 'Permission(s)',
        options: state.permissionsLoaded.map((permission) => ({
          key: permission.id + permission.description,
          label: permission.name + '-' + permission.description,
          value: permission.id,
        })),
        type: limitMultiSelect ? 'number' : 'multiselect',
      },
    ],
  ];
  const AddPermission = async (permissionIdsToAdd: number[]) => {
    for (let i = 0; i < permissionIdsToAdd.length; i++) {
      const req = PermissionGroupUser.create();
      req.userId = userId;
      req.permissionGroupId = permissionIdsToAdd[i];
      await UserClientService.AddUserToPermissionGroup(req);
    }
    if (onClose) {
      onClose();
    }
  };

  const load = useCallback(async () => {
    const req = PermissionGroup.create();
    req.isActive = true;
    if (permissionType.toLowerCase().includes('privilege')) {
      req.type = 'privilege';
    }
    if (permissionType.toLowerCase().includes('department')) {
      req.type = 'department';
    }
    if (permissionType.toLowerCase().includes('role')) {
      req.type = 'role';
    }
    const loggedUserIsSU = loggedUserPermissions.find((p) => p.name === 'SU');

    const res = await UserClientService.BatchGetPermission(req);
    const resultList = res.results;
    let filteredList = resultList.filter(function (e) {
      return userPermissions.find((a) => a.id === e.id) === undefined;
    });
    if (loggedUserIsSU === undefined) {
      filteredList = filteredList.filter((p) => p.name != 'SU');
    }
    dispatch({ type: 'setPermissionsLoaded', data: filteredList });
    dispatch({ type: 'setLoaded', data: true });
  }, [permissionType, loggedUserPermissions, userPermissions]);
  useEffect(() => {
    if (init) {
      load();
      dispatch({ type: 'setInit', data: false });
    }
  }, [load, init]);

  return loaded ? (
    <SectionBar title="Add Permission" uncollapsable={true}>
      <PlainForm
        schema={SCHEMA_PRINT}
        data={selectedPermissions}
        onChange={(e) => dispatch({ type: 'setSelectedPermissions', data: e })}
      ></PlainForm>
      <Button
        className="!mx-2 !mb-2"
        disabled={selectedPermissions.permissionIds.length == 0}
        label={'Add Selected Permissions'}
        onClick={() => AddPermission(selectedPermissions.permissionIds)}
      ></Button>
    </SectionBar>
  ) : (
    <Loader></Loader>
  );
};
