import { Contract } from '@kalos/kalos-rpc';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { ContractClientService, loadContractsByFilter } from '../../tools/helpers';
import { queryKeys } from './constants';
import { type EntityFilter } from './utils';

export const useContractsQuery = ({
  enabled = true,
  filters,
}: {
  enabled?: boolean;
  filters: Parameters<typeof loadContractsByFilter>['0'];
}) => {
  return useQuery({
    queryKey: [
      queryKeys.contracts.root,
      queryKeys.contracts.list,
      queryKeys.contracts.getContractsHash(filters),
    ],
    queryFn: async () => {
      return await loadContractsByFilter(filters);
    },
    enabled,
  });
};

export type ContractFilter = EntityFilter<Contract>;
export const useContractQuery = ({
  filter = {},
  enabled = true,
}: {
  filter?: ContractFilter;
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: [queryKeys.contracts.root, queryKeys.contracts.getHash(filter)],
    enabled,
    queryFn: async () => {
      const req = Contract.create(filter);
      return await ContractClientService.Get(req);
    },
  });
};

export const useDeleteContractByIdMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: Contract['id']) => {
      const req = Contract.create({ id });
      return ContractClientService.Delete(req);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.contracts.root],
      });
    },
  });
};
