// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "metrics.proto" (syntax proto3)
// tslint:disable
import { MetricsService } from "./metrics";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service MetricsService
 */
export class MetricsServiceClient {
    constructor(_transport) {
        this._transport = _transport;
        this.typeName = MetricsService.typeName;
        this.methods = MetricsService.methods;
        this.options = MetricsService.options;
    }
    /**
     * @generated from protobuf rpc: GetBillable(Billable) returns (Billable);
     */
    getBillable(input, options) {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetBillable(Billable) returns (BillableList);
     */
    batchGetBillable(input, options) {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetAvgTicket(AvgTicket) returns (AvgTicket);
     */
    getAvgTicket(input, options) {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetAvgTicket(AvgTicket) returns (AvgTicketList);
     */
    batchGetAvgTicket(input, options) {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetCallbacks(Callbacks) returns (Callbacks);
     */
    getCallbacks(input, options) {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetCallbacks(Callbacks) returns (CallbacksList);
     */
    batchGetCallbacks(input, options) {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetRevenue(Revenue) returns (Revenue);
     */
    getRevenue(input, options) {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetMetricReportData(MertricReportDataRequest) returns (MetricReportDataList);
     */
    batchGetMetricReportData(input, options) {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
}
