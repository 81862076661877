import { File, PostRequestToOCRReq, SUBJECT_TAGS_ACCOUNTS_PAYABLE } from '@kalos/kalos-rpc';
import Alert from '@mui/material/Alert';
import { useQueryClient } from '@tanstack/react-query';
import { type FC, useCallback, useMemo, useState } from 'react';

import { useAuth } from '../../../context/AuthContext';
import { queryKeys } from '../../../hooks/react-query/constants';
import {
  FileClientService,
  getFileExt,
  getMimeType,
  S3ClientService,
  TransactionClientService,
} from '../../../tools/helpers';
import { Form, type Schema } from '../Form';
interface Props {
  title?: string;
  onClose: (() => void) | null;
  accountsPayableWorkflow?: boolean;
}

type Entry = {
  file: string;
  notes: string;
  name: string;
  tag: string;
};

export const UploadPhotoOCR: FC<Props> = ({
  onClose,
  accountsPayableWorkflow,

  title = 'Upload Receipt For Auto-Matching',
}) => {
  const queryClient = useQueryClient();
  const bucket = 'kalos-transactions';
  const { user } = useAuth();
  const [fileData, setFileData] = useState<string>('');
  const [saving, setSaving] = useState<boolean>(false);
  const [saved, setSaved] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [formData, setFormData] = useState<Entry>({
    file: '',
    name: '',
    tag: 'Subject=Receipt',
    notes: '',
  });
  const [formKey, setFormKey] = useState<number>(0);
  const handleFileLoad = useCallback(
    (fileData: string) => {
      setFileData(fileData);
    },
    [setFileData],
  );
  const handleSubmit = useCallback(
    async (data: Entry) => {
      setSaved(false);
      setError(false);
      setSaving(true);
      const ext = getFileExt(data.file);
      const name = `${data.name}-${Math.floor(Date.now() / 1000)}.${ext}`;
      const status = await S3ClientService.uploadFileToS3Bucket(name, fileData, bucket, data.tag);
      if (status === 'ok') {
        const ocrReq = PostRequestToOCRReq.create();
        ocrReq.filename = name;
        ocrReq.ownerId = user.id;
        ocrReq.ownerNotes = data.notes;
        const type = formData.tag.replace('Subject=', '');

        ocrReq.documentType = type;
        if (!accountsPayableWorkflow) {
          TransactionClientService.PostRequestToOCR(ocrReq);
        }
        const fReq = File.create();
        fReq.bucket = bucket;
        fReq.name = name;
        fReq.mimeType = getMimeType(name);
        fReq.ownerId = user.id;
        await FileClientService.upsertFile(fReq);
        setSaved(true);

        if (accountsPayableWorkflow) {
          const req = PostRequestToOCRReq.create();
          req.documentType = type;
          req.filename = name;
          req.ownerId = user.id;
          req.ownerNotes = data.notes;
          const res = await TransactionClientService.CreateUpdateSimpleOCR(req);
          if (res != 'ok') {
            setError(true);
          } else {
            setSaving(false);
            setFormData({
              file: '',
              name: '',
              notes: '',
              tag: 'Subject=Receipt',
            });
            setFormKey(formKey + 1);
          }
        }
        setSaved(true);
        setSaving(false);
        setFormData({
          file: '',
          name: '',
          notes: '',
          tag: 'Subject=Receipt',
        });
        setFormKey(formKey + 1);
      } else {
        setError(true);
      }
      // TODO there should be a mutation.
      queryClient.invalidateQueries({
        queryKey: [queryKeys.OCR.root],
      });
    },
    [fileData, queryClient, user.id, formData.tag, accountsPayableWorkflow, formKey],
  );

  const SCHEMA: Schema<Entry> = useMemo(
    () =>
      [
        [
          {
            name: 'tag',
            label: 'Tag',
            required: true,
            invisible: !accountsPayableWorkflow,
            options: SUBJECT_TAGS_ACCOUNTS_PAYABLE,
            validationOnSave: accountsPayableWorkflow
              ? (value: string) => {
                  if (!value || value === '0') {
                    return 'This field is required';
                  }
                  return '';
                }
              : undefined,
          },
        ],
        [
          {
            name: 'file',
            label: 'Photo',
            type: 'file',
            required: true,
            onFileLoad: handleFileLoad,
          },
        ],
        [
          {
            name: 'name',
            label: 'Name',
            required: true,
            validationOnSave(value) {
              if (!value.length) return 'This field is required';
              return /\W|_/g.test(value.replaceAll(' ', ''))
                ? "This field can't contain special characters "
                : '';
            },
          },
        ],
        [
          {
            name: 'notes',
            label: 'Notes',
            required: false,
            validationOnSave(value) {
              if (value.length > 500) {
                return 'This field cannot be more than 500 characters';
              } else {
                return '';
              }
            },
          },
        ],
      ] as Schema<Entry>,
    [handleFileLoad, accountsPayableWorkflow],
  );

  return (
    <Form<Entry>
      key={formKey}
      title={title}
      schema={SCHEMA}
      data={formData}
      onClose={onClose}
      onSave={handleSubmit}
      onChange={setFormData}
      submitLabel="Upload"
      cancelLabel="Close"
      disabled={saving}
      intro={
        saved && (
          <Alert severity="success" className="-m-4">
            <big>File uploaded successfully!</big>
            <br />
            You can upload another file.
          </Alert>
        )
      }
      error={error && <>Error while uploading file. Please try again.</>}
    />
  );
};
