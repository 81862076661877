import { BaseClient } from '../BaseClient';
import { type Int32 } from '../compiled-protos/common';
import { Payment, PaymentList } from '../compiled-protos/event';
import { EventServiceClient as PaymentServiceClient } from '../compiled-protos/event.client';

class PaymentClient extends BaseClient {
  self: PaymentServiceClient;

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new PaymentServiceClient(this.transport);
  }

  public async Create(req: Payment) {
    let res = Payment.create();
    try {
      res = await this.self.createPayment(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Get(req: Payment) {
    let res = Payment.create();
    try {
      res = await this.self.getPayment(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Update(req: Payment) {
    let res = Payment.create();
    try {
      res = await this.self.updatePayment(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Delete(req: Payment) {
    let res = Payment.create();
    try {
      res = await this.self.deletePayment(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async GetPaymentsByEventID(req: Int32) {
    let res = PaymentList.create();
    try {
      res = await this.self.getPaymentsByEventID(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async BatchGet(req: Payment) {
    let res = PaymentList.create();
    try {
      res = await this.self.batchGetPayment(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }
}

export { Payment, PaymentList, PaymentClient };
