// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "quote.proto" (syntax proto3)
// tslint:disable
import { QuoteService } from "./quote";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service QuoteService
 */
export class QuoteServiceClient {
    constructor(_transport) {
        this._transport = _transport;
        this.typeName = QuoteService.typeName;
        this.methods = QuoteService.methods;
        this.options = QuoteService.options;
    }
    /**
     * @generated from protobuf rpc: Create(Quote) returns (Quote);
     */
    create(input, options) {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Get(Quote) returns (Quote);
     */
    get(input, options) {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGet(Quote) returns (QuoteList);
     */
    batchGet(input, options) {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Update(Quote) returns (Quote);
     */
    update(input, options) {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Delete(Quote) returns (Quote);
     */
    delete(input, options) {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateQuoteUsed(QuoteUsed) returns (QuoteUsed);
     */
    createQuoteUsed(input, options) {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetQuoteUsed(QuoteUsed) returns (QuoteUsed);
     */
    getQuoteUsed(input, options) {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetQuoteUsed(QuoteUsed) returns (QuoteUsedList);
     */
    batchGetQuoteUsed(input, options) {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateQuoteUsed(QuoteUsed) returns (QuoteUsed);
     */
    updateQuoteUsed(input, options) {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteQuoteUsed(QuoteUsed) returns (QuoteUsed);
     */
    deleteQuoteUsed(input, options) {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateQuotePart(QuotePart) returns (QuotePart);
     */
    createQuotePart(input, options) {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetQuotePart(QuotePart) returns (QuotePart);
     */
    getQuotePart(input, options) {
        const method = this.methods[11], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetQuotePart(QuotePart) returns (QuotePartList);
     */
    batchGetQuotePart(input, options) {
        const method = this.methods[12], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateQuotePart(QuotePart) returns (QuotePart);
     */
    updateQuotePart(input, options) {
        const method = this.methods[13], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteQuotePart(QuotePart) returns (QuotePart);
     */
    deleteQuotePart(input, options) {
        const method = this.methods[14], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateQuoteLine(QuoteLine) returns (QuoteLine);
     */
    createQuoteLine(input, options) {
        const method = this.methods[15], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetQuoteLine(QuoteLine) returns (QuoteLine);
     */
    getQuoteLine(input, options) {
        const method = this.methods[16], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetQuoteLine(QuoteLine) returns (QuoteLineList);
     */
    batchGetQuoteLine(input, options) {
        const method = this.methods[17], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateQuoteLine(QuoteLine) returns (QuoteLine);
     */
    updateQuoteLine(input, options) {
        const method = this.methods[18], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteQuoteLine(QuoteLine) returns (QuoteLine);
     */
    deleteQuoteLine(input, options) {
        const method = this.methods[19], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateQuoteLinePart(QuoteLinePart) returns (QuoteLinePart);
     */
    createQuoteLinePart(input, options) {
        const method = this.methods[20], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetQuoteLinePart(QuoteLinePart) returns (QuoteLinePart);
     */
    getQuoteLinePart(input, options) {
        const method = this.methods[21], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetQuoteLinePart(QuoteLinePart) returns (QuoteLinePartList);
     */
    batchGetQuoteLinePart(input, options) {
        const method = this.methods[22], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateQuoteLinePart(QuoteLinePart) returns (QuoteLinePart);
     */
    updateQuoteLinePart(input, options) {
        const method = this.methods[23], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteQuoteLinePart(QuoteLinePart) returns (QuoteLinePart);
     */
    deleteQuoteLinePart(input, options) {
        const method = this.methods[24], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateQuoteDocument(QuoteDocument) returns (QuoteDocument);
     */
    createQuoteDocument(input, options) {
        const method = this.methods[25], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetQuoteDocument(QuoteDocument) returns (QuoteDocument);
     */
    getQuoteDocument(input, options) {
        const method = this.methods[26], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetQuoteDocument(QuoteDocument) returns (QuoteDocumentList);
     */
    batchGetQuoteDocument(input, options) {
        const method = this.methods[27], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateQuoteDocument(QuoteDocument) returns (QuoteDocument);
     */
    updateQuoteDocument(input, options) {
        const method = this.methods[28], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteQuoteDocument(QuoteDocument) returns (QuoteDocument);
     */
    deleteQuoteDocument(input, options) {
        const method = this.methods[29], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateStoredQuote(StoredQuote) returns (StoredQuote);
     */
    createStoredQuote(input, options) {
        const method = this.methods[30], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetStoredQuote(StoredQuote) returns (StoredQuote);
     */
    getStoredQuote(input, options) {
        const method = this.methods[31], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetStoredQuote(StoredQuote) returns (StoredQuoteList);
     */
    batchGetStoredQuote(input, options) {
        const method = this.methods[32], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateStoredQuote(StoredQuote) returns (StoredQuote);
     */
    updateStoredQuote(input, options) {
        const method = this.methods[33], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteStoredQuote(StoredQuote) returns (StoredQuote);
     */
    deleteStoredQuote(input, options) {
        const method = this.methods[34], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetQuotePhoto(QuotePhoto) returns (QuotePhoto);
     */
    getQuotePhoto(input, options) {
        const method = this.methods[35], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateQuotePhoto(QuotePhoto) returns (QuotePhoto);
     */
    createQuotePhoto(input, options) {
        const method = this.methods[36], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetQuotePhoto(QuotePhoto) returns (QuotePhotoList);
     */
    batchGetQuotePhoto(input, options) {
        const method = this.methods[37], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateQuotePhoto(QuotePhoto) returns (QuotePhoto);
     */
    updateQuotePhoto(input, options) {
        const method = this.methods[38], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteQuotePhoto(QuotePhoto) returns (QuotePhoto);
     */
    deleteQuotePhoto(input, options) {
        const method = this.methods[39], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
}
