import { BaseClient } from '../BaseClient';
import { Reading, ReadingImage, ReadingImageList, ReadingList } from '../compiled-protos/service_item';
import { ServiceItemServiceClient as ReadingServiceClient } from '../compiled-protos/service_item.client';
import { File as InternalFile, FileClient } from '../File';
import { S3Client } from '../S3File';

class ReadingClient extends BaseClient {
  self: ReadingServiceClient;
  s3Client: S3Client;
  fileClient: FileClient
  readingImagesS3Bucket = 'kalos-reading-images'

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new ReadingServiceClient(this.transport);
    this.s3Client = new S3Client(host, userID);
    this.fileClient = new FileClient(host, userID);
  }

  public async Create(req: Reading) {
    return await this.self.createReading(req, this.getMetaData()).response;
  }

  public async Get(req: Reading) {
    let res = Reading.create();
    try {
      res = await this.self.getReading(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Update(req: Reading) {
    let res = Reading.create();
    try {
      res = await this.self.updateReading(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Delete(req: Reading) {
    let res = Reading.create();
    try {
      res = await this.self.deleteReading(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async BatchGet(req: Reading) {
    let res = ReadingList.create();
    try {
      res = await this.self.batchGetReading(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return res;
    }
    return res;
  }

  /* READING IMAGES */
  public async CreateReadingImage({ req, file }: { req: Omit<ReadingImage, 'fileId' | 'fileBucket' | 'fileName'>, file: File }) {
    const fileKey = `${req.readingId}-${Date.now()}-${file.name}`;

    const fileRes = await this.fileClient.uploadFileToS3AndCreateFile({
      file,
      fileKey,
      bucket: this.readingImagesS3Bucket,
    })

    return await this.self.createReadingImage(ReadingImage.create({ ...req, fileId: fileRes.id, imageName: fileRes.name }), this.getMetaData()).response;
  }

  public async DeleteReadingImage(req: ReadingImage) {
    const fileObj = InternalFile.create({
      bucket: this.readingImagesS3Bucket,
      name: req.imageName,
    });
    await this.fileClient.Delete(fileObj);
    return await this.self.deleteReadingImage(req, this.getMetaData()).response;
  }
}

export { Reading, ReadingList, ReadingClient, ReadingImage, ReadingImageList };
