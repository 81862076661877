/*

  Description: The logs for Jobs (to replace admin:service.viewlogs)

  Design Specification / Document: admin:service.viewlogs

*/

import { ActivityLog } from '@kalos/kalos-rpc';
import Typography from '@mui/material/Typography';
import { type FC, useCallback, useEffect, useMemo, useReducer } from 'react';

import { ActivityLogClientService } from '../../../tools/helpers';
import { Loader } from '../../Loader/main';
import { type ActionsProps } from '../Actions';
import { Alert } from '../Alert';
import { InfoTable } from '../InfoTable';
import { SectionBar } from '../SectionBar';
import { ACTIONS, reducer } from './reducer';

// add any prop types here
interface props {
  eventId: number;
  onClose?: () => void;
}

export const ServiceCallLogs: FC<props> = ({ eventId, onClose }) => {
  const [state, dispatch] = useReducer(reducer, {
    isLoaded: false,
    error: undefined,
    activityLogs: undefined,
    page: 0,
    count: 0,
  });

  const handleError = useCallback(async (errorToSet: string) => {
    // This will send out an error devlog automatically when called
    // The idea is that this will be used for any errors which we should be able to look up for debugging
    console.log(errorToSet);
  }, []);

  const loadActivityLogs = useCallback(async () => {
    try {
      const req = ActivityLog.create();
      req.eventId = eventId;
      req.withUser = true;
      req.orderBy = 'activity_date';
      req.orderDir = 'DESC';
      req.pageNumber = state.page;
      const res = await ActivityLogClientService.BatchGet(req);

      dispatch({ type: ACTIONS.SET_ACTIVITY_LOGS, data: res!.results });
      dispatch({ type: ACTIONS.SET_COUNT, data: res!.totalCount });
    } catch (err) {
      console.error(`An error occurred while getting activity logs: ${err}`);
      dispatch({
        type: ACTIONS.SET_ERROR,
        data: `An error occurred while getting activity logs: ${err}`,
      });
      dispatch({ type: ACTIONS.SET_LOADED, data: true });

      await handleError(`${err}`);
    }
  }, [eventId, handleError, state.page]);

  const load = useCallback(async () => {
    await loadActivityLogs();

    dispatch({ type: ACTIONS.SET_LOADED, data: true });
  }, [loadActivityLogs]);

  const cleanup = useCallback(() => {}, []);

  const sectionBarActions = useMemo<ActionsProps>(
    () => (onClose ? [{ label: 'Close', onClick: onClose }] : []),
    [onClose],
  );

  useEffect(() => {
    if (!state.isLoaded) load();

    return () => {
      cleanup();
    };
  }, [load, cleanup, state.isLoaded]);

  return (
    <>
      {!state.activityLogs && <Loader />}
      <SectionBar
        fixedActions={true}
        actions={sectionBarActions}
        title={`Job Logs of Event ID: ${eventId}`}
        pagination={{
          page: state.page,
          onPageChange: (value) => {
            dispatch({
              type: ACTIONS.SET_PAGE,
              data: value,
            });
            dispatch({ type: ACTIONS.SET_LOADED, data: false });
          },
          rowsPerPage: 25,
          count: state.count,
        }}
      >
        {state.activityLogs && state.activityLogs.length === 0 && (
          <Typography>No activity logs found for this event.</Typography>
        )}
        <Alert
          open={state.error !== undefined}
          onClose={() => dispatch({ type: ACTIONS.SET_ERROR, data: undefined })}
          title="Error"
        >
          {state.error}
        </Alert>
        <InfoTable
          key={state.activityLogs?.toString()}
          columns={[
            {
              name: 'Date / Time',
            },
            {
              name: 'User',
            },
            {
              name: 'Activity Type',
            },
          ]}
          data={
            !state.activityLogs
              ? []
              : state.activityLogs.map((activityLog) => {
                  return [
                    { value: `${activityLog.activityDate}` },
                    {
                      value: `${activityLog.user?.firstname} ${activityLog.user?.lastname}`,
                    },
                    {
                      value: activityLog.activityName,
                    },
                  ];
                })
          }
          loading={!state.activityLogs}
          error={state.error !== undefined}
        />
      </SectionBar>
    </>
  );
};
