import { BaseClient } from '../BaseClient';
import { JobType, JobTypeList } from '../compiled-protos/job_type';
import { JobTypeServiceClient } from '../compiled-protos/job_type.client';

class JobTypeClient extends BaseClient {
  self: JobTypeServiceClient;

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new JobTypeServiceClient(this.transport);
  }

  public async Get(req: JobType) {
    let res = JobType.create();
    try {
      res = await this.self.getJobType(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async BatchGet(req: JobType) {
    let res = JobTypeList.create();
    try {
      res = await this.self.batchGetJobType(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  /**
   * loadJobTypes is a convenient wrapper for fetching all job types
   * @returns JobType[]
   */
  public async loadJobTypes() {
    const res = await this.BatchGet(JobType.create());
    return res!.results;
  }
}

export { JobType, JobTypeList, JobTypeClient };
