import { ServiceItemImage } from '@kalos/kalos-rpc';
import { Button, DataTablePagination, Skeleton, toast, useConfirm } from '@kalos/ui';
import { TrashIcon, UploadIcon } from '@radix-ui/react-icons';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';

import {
  useServiceItemImageBatchGetQuery,
  useServiceItemImageCreateMutation,
  useServiceItemImageDeletionMutation,
} from '../../../../hooks/react-query/useServiceItemQuery';
import { getMimeType, S3ClientService } from '../../../../tools/helpers';
import { RotateZoomImage } from '../../../AltGallery/main';
import { ServiceImageForm } from './ServiceImageForm';

const useFileArrayBufferQUery = <TSelectData = ArrayBuffer,>({
  filter,
  enabled,
  select,
}: {
  filter: { fileName: string; bucket: string };
  enabled?: boolean;
  select?: (data: ArrayBuffer) => TSelectData;
}) => {
  return useQuery({
    queryKey: ['s3', 'array-buffer', filter],
    queryFn: async () => {
      return await S3ClientService.downloadArrayBuffer(filter);
    },
    enabled,
    select,
  });
};

export const ServiceItemsImagesGallery = ({ serviceItemId }: { serviceItemId: number }) => {
  const [activeImageIdx, setActiveImageIdx] = useState(0);
  const [isAdding, setIsAdding] = useState(false);

  const serviceItemImageQuery = useServiceItemImageBatchGetQuery({
    filter: {
      serviceItemId,
    },
    enabled: true,
    select: (data) => {
      return {
        serviceItemImages: data.results,
      };
    },
  });

  const activeImage = serviceItemImageQuery.data?.serviceItemImages[activeImageIdx];
  const isImageQueryEnabled = !!activeImage;

  const imageQuery = useFileArrayBufferQUery({
    filter: {
      bucket: activeImage?.fileBucket ?? '',
      fileName: activeImage?.fileName ?? '',
    },
    enabled: isImageQueryEnabled,
    select: (data) => {
      return {
        arrayBuffer: data,
        urlObject: URL.createObjectURL(
          new Blob([data], {
            type: getMimeType(activeImage!.fileName) || 'image/png',
          }),
        ),
      };
    },
  });

  const createServiceItemImageMutation = useServiceItemImageCreateMutation();
  const deleteServiceItemImageMutation = useServiceItemImageDeletionMutation();

  const onImagesAdd: React.ComponentProps<typeof ServiceImageForm>['onSubmit'] = async ({
    images,
  }) => {
    try {
      await Promise.allSettled(
        images.map(async (image) =>
          createServiceItemImageMutation.mutateAsync({
            req: ServiceItemImage.create({ serviceItemId }),
            file: image,
          }),
        ),
      );
      console.log('activeImageIdx:', activeImageIdx);
      console.log('images:', images);
      setIsAdding(false);
      /*setLoaded(false);*/
    } catch {
      toast({ title: 'Failed to upload images', variant: 'destructive' });
    }
  };

  const confirm = useConfirm();

  const onImageDelete = async () => {
    const serviceItemImage = serviceItemImageQuery.data?.serviceItemImages[activeImageIdx];
    if (!serviceItemImage) return;
    if (!(await confirm({ title: 'Are you sure you want to delete this image?' }))) return;

    deleteServiceItemImageMutation.mutateAsync(serviceItemImage);

    // setLoaded(false);
    setActiveImageIdx((prev) => Math.max(prev - 1, 0));
    // loadServiceItemImages(serviceItemId);
  };

  const preview = serviceItemImageQuery.isFetching ? (
    <span>Loading...</span>
  ) : serviceItemImageQuery.data && serviceItemImageQuery.data.serviceItemImages.length == 0 ? (
    <span>No Images</span>
  ) : (
    <div className="mx-auto mt-4 max-h-[40vh] max-w-max">
      <div className="flex flex-col gap-4">
        <DataTablePagination
          className="flex-1"
          currentPage={activeImageIdx}
          pageCount={serviceItemImageQuery.data?.serviceItemImages.length || 0}
          setPage={setActiveImageIdx}
          disabled={
            deleteServiceItemImageMutation.isPending || createServiceItemImageMutation.isPending
          }
        />
        <div className="h-96">
          {imageQuery.isSuccess ? (
            <RotateZoomImage url={imageQuery.data.urlObject} />
          ) : (
            <Skeleton className="h-72 w-96" />
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div className="flex flex-col gap-2">
      {isAdding ? (
        <div className="flex justify-center  gap-2">
          <ServiceImageForm
            onSubmit={onImagesAdd}
            disabled={createServiceItemImageMutation.isPending}
            isLoading={createServiceItemImageMutation.isPending}
            actions={
              <Button variant="secondary" onClick={() => setIsAdding(false)}>
                Cancel{' '}
              </Button>
            }
          />
        </div>
      ) : (
        <div className="flex gap-2">
          <Button onClick={() => setIsAdding(true)}>
            <UploadIcon />
            <span>Add Image</span>
          </Button>
          <Button
            isLoading={deleteServiceItemImageMutation.isPending}
            disabled={deleteServiceItemImageMutation.isPending}
            onClick={onImageDelete}
          >
            <TrashIcon />
            <span>Delete</span>
          </Button>
        </div>
      )}
      {!isAdding && preview}
    </div>
  );
};
