import { BaseClient } from '../BaseClient';
import { QuotePart, QuotePartList } from '../compiled-protos/quote';
import { QuoteServiceClient as QuotePartServiceClient } from '../compiled-protos/quote.client';

class QuotePartClient extends BaseClient {
  self: QuotePartServiceClient;

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new QuotePartServiceClient(this.transport);
  }

  public async Create(req: QuotePart) {
    let res = QuotePart.create();
    try {
      res = await this.self.createQuotePart(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Get(req: QuotePart) {
    let res = QuotePart.create();
    try {
      res = await this.self.getQuotePart(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Update(req: QuotePart) {
    let res = QuotePart.create();
    try {
      res = await this.self.updateQuotePart(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Delete(req: QuotePart) {
    let res = QuotePart.create();
    try {
      res = await this.self.deleteQuotePart(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async BatchGet(req: QuotePart) {
    let res = QuotePartList.create();
    try {
      res = await this.self.batchGetQuotePart(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  /**
   * Returns loaded QuoteParts
   * @returns QuotePart[]
   */
  public async loadQuoteParts() {
    const results: QuotePart[] = [];
    const req = QuotePart.create();
    req.pageNumber = 0;
    const res = await this.BatchGet(req);
    const resultsList = res!.results;
    const totalCount = res!.totalCount;
    const len = resultsList.length;
    results.concat(resultsList);
    if (totalCount > len) {
      const batchesAmount = Math.ceil((totalCount - len) / len);
      const batchResults = await Promise.all(
        Array.from(Array(batchesAmount)).map(async (_, idx) => {
          req.pageNumber = idx + 1;
          return (await this.BatchGet(req))!.results;
        })
      );
      results.push(
        ...batchResults.reduce((aggr, item) => [...aggr, ...item], [])
      );
    }
    return results;
  }
}

export { QuotePart, QuotePartList, QuotePartClient };
