import { BaseClient } from '../BaseClient';
import { HTML } from '../compiled-protos/pdf';
import { PDFServiceClient } from '../compiled-protos/pdf.client';
import { URLObject } from '../compiled-protos/s3';

class PDFClient extends BaseClient {
  self: PDFServiceClient;

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new PDFServiceClient(this.transport);
  }

  public async Create(req: HTML) {
    let res = URLObject.create();
    try {
      res = await this.self.create(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }
  public async CreateWithEasy(req: HTML) {
    let res = URLObject.create();
    try {
      res = await this.self.createWithWeasyPrint(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async createWithWeasyPrint(req: HTML) {
    let res = URLObject.create();
    try {
      res = await this.self.createWithWeasyPrint(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public getUploadedHTMLUrl = async (
    HTMLString: string,
    filename: string,
    bucketName: string = 'testbuckethelios'
  ) => {
    const req = HTML.create();
    req.data = HTMLString;
    req.key = filename;
    req.bucket = bucketName;
    const url = await this.createWithWeasyPrint(req);
    return url;
  };

public getUploadedHTMLUrlEasyPrint = async (
  HTMLString: string,
  filename: string,
  bucketName: string = 'testbuckethelios'
) => {
  const req = HTML.create();
  req.data = HTMLString;
  req.key = filename;
  req.bucket = bucketName;
  const url = await this.CreateWithEasy(req);
  return url;
};
}

export { HTML, PDFClient };
