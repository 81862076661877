// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "activity_log.proto" (syntax proto3)
// tslint:disable
import { ActivityLogService } from "./activity_log";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service ActivityLogService
 */
export class ActivityLogServiceClient {
    constructor(_transport) {
        this._transport = _transport;
        this.typeName = ActivityLogService.typeName;
        this.methods = ActivityLogService.methods;
        this.options = ActivityLogService.options;
    }
    /**
     * @generated from protobuf rpc: Create(ActivityLog) returns (ActivityLog);
     */
    create(input, options) {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Get(ActivityLog) returns (ActivityLog);
     */
    get(input, options) {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGet(ActivityLog) returns (ActivityLogList);
     */
    batchGet(input, options) {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetEventLogs(ActivityLog) returns (ActivityLogList);
     */
    batchGetEventLogs(input, options) {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Update(ActivityLog) returns (ActivityLog);
     */
    update(input, options) {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Delete(ActivityLog) returns (ActivityLog);
     */
    delete(input, options) {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
}
