// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "vehicle_driver.proto" (syntax proto3)
// tslint:disable
// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "vehicle_driver.proto" (syntax proto3)
// tslint:disable
import { Empty } from "./common";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { WireType } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
// @generated message type with reflection information, may provide speed optimized methods
class Vehicle$Type extends MessageType {
    constructor() {
        super("Vehicle", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "owner_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "department_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "make", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "model", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "year", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "engine", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "identification_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "gps_tracking_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "is_active", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 12, name: "third_party_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "driver_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 14, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 16, name: "rider_ids", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "vehicle_trailers", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => VehicleTrailer },
            { no: 18, name: "vehicle_assets", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => VehicleAsset },
            { no: 19, name: "vehicle_photos", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => VehiclePhoto },
            { no: 20, name: "include_trailer", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 21, name: "include_asset", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 22, name: "include_photo", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 23, name: "owner_firstname", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 24, name: "owner_lastname", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 25, name: "department_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 26, name: "plate", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, ownerId: 0, departmentId: 0, make: "", model: "", year: "", engine: "", identificationNumber: "", gpsTrackingId: "", isActive: 0, thirdPartyId: "", driverId: 0, fieldMask: [], pageNumber: 0, riderIds: "", vehicleTrailers: [], vehicleAssets: [], vehiclePhotos: [], includeTrailer: false, includeAsset: false, includePhoto: false, ownerFirstname: "", ownerLastname: "", departmentName: "", plate: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 owner_id */ 2:
                    message.ownerId = reader.int32();
                    break;
                case /* int32 department_id */ 3:
                    message.departmentId = reader.int32();
                    break;
                case /* string make */ 4:
                    message.make = reader.string();
                    break;
                case /* string model */ 5:
                    message.model = reader.string();
                    break;
                case /* string year */ 6:
                    message.year = reader.string();
                    break;
                case /* string engine */ 7:
                    message.engine = reader.string();
                    break;
                case /* string identification_number */ 8:
                    message.identificationNumber = reader.string();
                    break;
                case /* string gps_tracking_id */ 10:
                    message.gpsTrackingId = reader.string();
                    break;
                case /* int32 is_active */ 11:
                    message.isActive = reader.int32();
                    break;
                case /* string third_party_id */ 12:
                    message.thirdPartyId = reader.string();
                    break;
                case /* int32 driver_id */ 13:
                    message.driverId = reader.int32();
                    break;
                case /* repeated string field_mask */ 14:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 15:
                    message.pageNumber = reader.int32();
                    break;
                case /* string rider_ids */ 16:
                    message.riderIds = reader.string();
                    break;
                case /* repeated VehicleTrailer vehicle_trailers */ 17:
                    message.vehicleTrailers.push(VehicleTrailer.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated VehicleAsset vehicle_assets */ 18:
                    message.vehicleAssets.push(VehicleAsset.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated VehiclePhoto vehicle_photos */ 19:
                    message.vehiclePhotos.push(VehiclePhoto.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bool include_trailer */ 20:
                    message.includeTrailer = reader.bool();
                    break;
                case /* bool include_asset */ 21:
                    message.includeAsset = reader.bool();
                    break;
                case /* bool include_photo */ 22:
                    message.includePhoto = reader.bool();
                    break;
                case /* string owner_firstname */ 23:
                    message.ownerFirstname = reader.string();
                    break;
                case /* string owner_lastname */ 24:
                    message.ownerLastname = reader.string();
                    break;
                case /* string department_name */ 25:
                    message.departmentName = reader.string();
                    break;
                case /* string plate */ 26:
                    message.plate = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 owner_id = 2; */
        if (message.ownerId !== 0)
            writer.tag(2, WireType.Varint).int32(message.ownerId);
        /* int32 department_id = 3; */
        if (message.departmentId !== 0)
            writer.tag(3, WireType.Varint).int32(message.departmentId);
        /* string make = 4; */
        if (message.make !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.make);
        /* string model = 5; */
        if (message.model !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.model);
        /* string year = 6; */
        if (message.year !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.year);
        /* string engine = 7; */
        if (message.engine !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.engine);
        /* string identification_number = 8; */
        if (message.identificationNumber !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.identificationNumber);
        /* string gps_tracking_id = 10; */
        if (message.gpsTrackingId !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.gpsTrackingId);
        /* int32 is_active = 11; */
        if (message.isActive !== 0)
            writer.tag(11, WireType.Varint).int32(message.isActive);
        /* string third_party_id = 12; */
        if (message.thirdPartyId !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.thirdPartyId);
        /* int32 driver_id = 13; */
        if (message.driverId !== 0)
            writer.tag(13, WireType.Varint).int32(message.driverId);
        /* repeated string field_mask = 14; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(14, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 15; */
        if (message.pageNumber !== 0)
            writer.tag(15, WireType.Varint).int32(message.pageNumber);
        /* string rider_ids = 16; */
        if (message.riderIds !== "")
            writer.tag(16, WireType.LengthDelimited).string(message.riderIds);
        /* repeated VehicleTrailer vehicle_trailers = 17; */
        for (let i = 0; i < message.vehicleTrailers.length; i++)
            VehicleTrailer.internalBinaryWrite(message.vehicleTrailers[i], writer.tag(17, WireType.LengthDelimited).fork(), options).join();
        /* repeated VehicleAsset vehicle_assets = 18; */
        for (let i = 0; i < message.vehicleAssets.length; i++)
            VehicleAsset.internalBinaryWrite(message.vehicleAssets[i], writer.tag(18, WireType.LengthDelimited).fork(), options).join();
        /* repeated VehiclePhoto vehicle_photos = 19; */
        for (let i = 0; i < message.vehiclePhotos.length; i++)
            VehiclePhoto.internalBinaryWrite(message.vehiclePhotos[i], writer.tag(19, WireType.LengthDelimited).fork(), options).join();
        /* bool include_trailer = 20; */
        if (message.includeTrailer !== false)
            writer.tag(20, WireType.Varint).bool(message.includeTrailer);
        /* bool include_asset = 21; */
        if (message.includeAsset !== false)
            writer.tag(21, WireType.Varint).bool(message.includeAsset);
        /* bool include_photo = 22; */
        if (message.includePhoto !== false)
            writer.tag(22, WireType.Varint).bool(message.includePhoto);
        /* string owner_firstname = 23; */
        if (message.ownerFirstname !== "")
            writer.tag(23, WireType.LengthDelimited).string(message.ownerFirstname);
        /* string owner_lastname = 24; */
        if (message.ownerLastname !== "")
            writer.tag(24, WireType.LengthDelimited).string(message.ownerLastname);
        /* string department_name = 25; */
        if (message.departmentName !== "")
            writer.tag(25, WireType.LengthDelimited).string(message.departmentName);
        /* string plate = 26; */
        if (message.plate !== "")
            writer.tag(26, WireType.LengthDelimited).string(message.plate);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Vehicle
 */
export const Vehicle = new Vehicle$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VehicleList$Type extends MessageType {
    constructor() {
        super("VehicleList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Vehicle },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Vehicle results */ 1:
                    message.results.push(Vehicle.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated Vehicle results = 1; */
        for (let i = 0; i < message.results.length; i++)
            Vehicle.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message VehicleList
 */
export const VehicleList = new VehicleList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Driver$Type extends MessageType {
    constructor() {
        super("Driver", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "technicianId", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "thirdPartyId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "is_active", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, technicianId: 0, thirdPartyId: "", isActive: 0, fieldMask: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 technicianId */ 2:
                    message.technicianId = reader.int32();
                    break;
                case /* string thirdPartyId */ 3:
                    message.thirdPartyId = reader.string();
                    break;
                case /* int32 is_active */ 4:
                    message.isActive = reader.int32();
                    break;
                case /* repeated string field_mask */ 5:
                    message.fieldMask.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 technicianId = 2; */
        if (message.technicianId !== 0)
            writer.tag(2, WireType.Varint).int32(message.technicianId);
        /* string thirdPartyId = 3; */
        if (message.thirdPartyId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.thirdPartyId);
        /* int32 is_active = 4; */
        if (message.isActive !== 0)
            writer.tag(4, WireType.Varint).int32(message.isActive);
        /* repeated string field_mask = 5; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(5, WireType.LengthDelimited).string(message.fieldMask[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Driver
 */
export const Driver = new Driver$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DriverList$Type extends MessageType {
    constructor() {
        super("DriverList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Driver },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Driver results */ 1:
                    message.results.push(Driver.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated Driver results = 1; */
        for (let i = 0; i < message.results.length; i++)
            Driver.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DriverList
 */
export const DriverList = new DriverList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VehicleTrailer$Type extends MessageType {
    constructor() {
        super("VehicleTrailer", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "vehicle_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "department_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "make", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "model", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "year", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "vehicle_identification_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "gps_tracking_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "plate", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 12, name: "third_party_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 17, name: "without_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 18, name: "include_photo", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 19, name: "vehicle_photos", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => VehiclePhoto },
            { no: 20, name: "department_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, vehicleId: 0, departmentId: 0, make: "", model: "", year: "", vehicleIdentificationNumber: "", gpsTrackingId: "", plate: "", isActive: false, thirdPartyId: "", fieldMask: [], orderBy: "", orderDir: "", pageNumber: 0, withoutLimit: false, includePhoto: false, vehiclePhotos: [], departmentName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 vehicle_id */ 2:
                    message.vehicleId = reader.int32();
                    break;
                case /* int32 department_id */ 3:
                    message.departmentId = reader.int32();
                    break;
                case /* string make */ 4:
                    message.make = reader.string();
                    break;
                case /* string model */ 5:
                    message.model = reader.string();
                    break;
                case /* string year */ 6:
                    message.year = reader.string();
                    break;
                case /* string vehicle_identification_number */ 7:
                    message.vehicleIdentificationNumber = reader.string();
                    break;
                case /* string gps_tracking_id */ 9:
                    message.gpsTrackingId = reader.string();
                    break;
                case /* string plate */ 10:
                    message.plate = reader.string();
                    break;
                case /* bool is_active */ 11:
                    message.isActive = reader.bool();
                    break;
                case /* string third_party_id */ 12:
                    message.thirdPartyId = reader.string();
                    break;
                case /* repeated string field_mask */ 13:
                    message.fieldMask.push(reader.string());
                    break;
                case /* string order_by */ 14:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 15:
                    message.orderDir = reader.string();
                    break;
                case /* int32 page_number */ 16:
                    message.pageNumber = reader.int32();
                    break;
                case /* bool without_limit */ 17:
                    message.withoutLimit = reader.bool();
                    break;
                case /* bool include_photo */ 18:
                    message.includePhoto = reader.bool();
                    break;
                case /* repeated VehiclePhoto vehicle_photos */ 19:
                    message.vehiclePhotos.push(VehiclePhoto.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string department_name */ 20:
                    message.departmentName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 vehicle_id = 2; */
        if (message.vehicleId !== 0)
            writer.tag(2, WireType.Varint).int32(message.vehicleId);
        /* int32 department_id = 3; */
        if (message.departmentId !== 0)
            writer.tag(3, WireType.Varint).int32(message.departmentId);
        /* string make = 4; */
        if (message.make !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.make);
        /* string model = 5; */
        if (message.model !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.model);
        /* string year = 6; */
        if (message.year !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.year);
        /* string vehicle_identification_number = 7; */
        if (message.vehicleIdentificationNumber !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.vehicleIdentificationNumber);
        /* string gps_tracking_id = 9; */
        if (message.gpsTrackingId !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.gpsTrackingId);
        /* string plate = 10; */
        if (message.plate !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.plate);
        /* bool is_active = 11; */
        if (message.isActive !== false)
            writer.tag(11, WireType.Varint).bool(message.isActive);
        /* string third_party_id = 12; */
        if (message.thirdPartyId !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.thirdPartyId);
        /* repeated string field_mask = 13; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(13, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* string order_by = 14; */
        if (message.orderBy !== "")
            writer.tag(14, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 15; */
        if (message.orderDir !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.orderDir);
        /* int32 page_number = 16; */
        if (message.pageNumber !== 0)
            writer.tag(16, WireType.Varint).int32(message.pageNumber);
        /* bool without_limit = 17; */
        if (message.withoutLimit !== false)
            writer.tag(17, WireType.Varint).bool(message.withoutLimit);
        /* bool include_photo = 18; */
        if (message.includePhoto !== false)
            writer.tag(18, WireType.Varint).bool(message.includePhoto);
        /* repeated VehiclePhoto vehicle_photos = 19; */
        for (let i = 0; i < message.vehiclePhotos.length; i++)
            VehiclePhoto.internalBinaryWrite(message.vehiclePhotos[i], writer.tag(19, WireType.LengthDelimited).fork(), options).join();
        /* string department_name = 20; */
        if (message.departmentName !== "")
            writer.tag(20, WireType.LengthDelimited).string(message.departmentName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message VehicleTrailer
 */
export const VehicleTrailer = new VehicleTrailer$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VehicleTrailerList$Type extends MessageType {
    constructor() {
        super("VehicleTrailerList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => VehicleTrailer },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated VehicleTrailer results */ 1:
                    message.results.push(VehicleTrailer.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated VehicleTrailer results = 1; */
        for (let i = 0; i < message.results.length; i++)
            VehicleTrailer.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message VehicleTrailerList
 */
export const VehicleTrailerList = new VehicleTrailerList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VehicleAsset$Type extends MessageType {
    constructor() {
        super("VehicleAsset", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "vehicle_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 11, name: "without_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, vehicleId: 0, name: "", notes: "", date: "", isActive: false, fieldMask: [], orderBy: "", orderDir: "", pageNumber: 0, withoutLimit: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 vehicle_id */ 2:
                    message.vehicleId = reader.int32();
                    break;
                case /* string name */ 3:
                    message.name = reader.string();
                    break;
                case /* string notes */ 4:
                    message.notes = reader.string();
                    break;
                case /* string date */ 5:
                    message.date = reader.string();
                    break;
                case /* bool is_active */ 6:
                    message.isActive = reader.bool();
                    break;
                case /* repeated string field_mask */ 7:
                    message.fieldMask.push(reader.string());
                    break;
                case /* string order_by */ 8:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 9:
                    message.orderDir = reader.string();
                    break;
                case /* int32 page_number */ 10:
                    message.pageNumber = reader.int32();
                    break;
                case /* bool without_limit */ 11:
                    message.withoutLimit = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 vehicle_id = 2; */
        if (message.vehicleId !== 0)
            writer.tag(2, WireType.Varint).int32(message.vehicleId);
        /* string name = 3; */
        if (message.name !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.name);
        /* string notes = 4; */
        if (message.notes !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.notes);
        /* string date = 5; */
        if (message.date !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.date);
        /* bool is_active = 6; */
        if (message.isActive !== false)
            writer.tag(6, WireType.Varint).bool(message.isActive);
        /* repeated string field_mask = 7; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(7, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* string order_by = 8; */
        if (message.orderBy !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 9; */
        if (message.orderDir !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.orderDir);
        /* int32 page_number = 10; */
        if (message.pageNumber !== 0)
            writer.tag(10, WireType.Varint).int32(message.pageNumber);
        /* bool without_limit = 11; */
        if (message.withoutLimit !== false)
            writer.tag(11, WireType.Varint).bool(message.withoutLimit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message VehicleAsset
 */
export const VehicleAsset = new VehicleAsset$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VehicleAssetList$Type extends MessageType {
    constructor() {
        super("VehicleAssetList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => VehicleAsset },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated VehicleAsset results */ 1:
                    message.results.push(VehicleAsset.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated VehicleAsset results = 1; */
        for (let i = 0; i < message.results.length; i++)
            VehicleAsset.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message VehicleAssetList
 */
export const VehicleAssetList = new VehicleAssetList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VehiclePhoto$Type extends MessageType {
    constructor() {
        super("VehiclePhoto", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "file_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "vehicle_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "vehicle_trailer_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "file_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "file_bucket", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "mime_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 14, name: "without_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 15, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, fileId: 0, vehicleId: 0, vehicleTrailerId: 0, date: "", notes: "", fileName: "", fileBucket: "", mimeType: "", fieldMask: [], orderBy: "", orderDir: "", pageNumber: 0, withoutLimit: false, isActive: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 file_id */ 2:
                    message.fileId = reader.int32();
                    break;
                case /* int32 vehicle_id */ 3:
                    message.vehicleId = reader.int32();
                    break;
                case /* int32 vehicle_trailer_id */ 4:
                    message.vehicleTrailerId = reader.int32();
                    break;
                case /* string date */ 5:
                    message.date = reader.string();
                    break;
                case /* string notes */ 6:
                    message.notes = reader.string();
                    break;
                case /* string file_name */ 7:
                    message.fileName = reader.string();
                    break;
                case /* string file_bucket */ 8:
                    message.fileBucket = reader.string();
                    break;
                case /* string mime_type */ 9:
                    message.mimeType = reader.string();
                    break;
                case /* repeated string field_mask */ 10:
                    message.fieldMask.push(reader.string());
                    break;
                case /* string order_by */ 11:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 12:
                    message.orderDir = reader.string();
                    break;
                case /* int32 page_number */ 13:
                    message.pageNumber = reader.int32();
                    break;
                case /* bool without_limit */ 14:
                    message.withoutLimit = reader.bool();
                    break;
                case /* bool is_active */ 15:
                    message.isActive = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 file_id = 2; */
        if (message.fileId !== 0)
            writer.tag(2, WireType.Varint).int32(message.fileId);
        /* int32 vehicle_id = 3; */
        if (message.vehicleId !== 0)
            writer.tag(3, WireType.Varint).int32(message.vehicleId);
        /* int32 vehicle_trailer_id = 4; */
        if (message.vehicleTrailerId !== 0)
            writer.tag(4, WireType.Varint).int32(message.vehicleTrailerId);
        /* string date = 5; */
        if (message.date !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.date);
        /* string notes = 6; */
        if (message.notes !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.notes);
        /* string file_name = 7; */
        if (message.fileName !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.fileName);
        /* string file_bucket = 8; */
        if (message.fileBucket !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.fileBucket);
        /* string mime_type = 9; */
        if (message.mimeType !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.mimeType);
        /* repeated string field_mask = 10; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(10, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* string order_by = 11; */
        if (message.orderBy !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 12; */
        if (message.orderDir !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.orderDir);
        /* int32 page_number = 13; */
        if (message.pageNumber !== 0)
            writer.tag(13, WireType.Varint).int32(message.pageNumber);
        /* bool without_limit = 14; */
        if (message.withoutLimit !== false)
            writer.tag(14, WireType.Varint).bool(message.withoutLimit);
        /* bool is_active = 15; */
        if (message.isActive !== false)
            writer.tag(15, WireType.Varint).bool(message.isActive);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message VehiclePhoto
 */
export const VehiclePhoto = new VehiclePhoto$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VehiclePhotoList$Type extends MessageType {
    constructor() {
        super("VehiclePhotoList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => VehiclePhoto },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated VehiclePhoto results */ 1:
                    message.results.push(VehiclePhoto.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated VehiclePhoto results = 1; */
        for (let i = 0; i < message.results.length; i++)
            VehiclePhoto.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message VehiclePhotoList
 */
export const VehiclePhotoList = new VehiclePhotoList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VehicleLog$Type extends MessageType {
    constructor() {
        super("VehicleLog", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "vehicle_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "vehicle_asset_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "vehicle_trailer_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "owner_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "activity_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "activity", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "activity_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 14, name: "without_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 15, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 16, name: "owner_firstname", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "owner_lastname", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, vehicleId: 0, vehicleAssetId: 0, vehicleTrailerId: 0, ownerId: 0, activityName: "", activity: "", activityDate: "", notes: "", fieldMask: [], orderBy: "", orderDir: "", pageNumber: 0, withoutLimit: false, isActive: false, ownerFirstname: "", ownerLastname: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 vehicle_id */ 2:
                    message.vehicleId = reader.int32();
                    break;
                case /* int32 vehicle_asset_id */ 3:
                    message.vehicleAssetId = reader.int32();
                    break;
                case /* int32 vehicle_trailer_id */ 4:
                    message.vehicleTrailerId = reader.int32();
                    break;
                case /* int32 owner_id */ 5:
                    message.ownerId = reader.int32();
                    break;
                case /* string activity_name */ 6:
                    message.activityName = reader.string();
                    break;
                case /* string activity */ 7:
                    message.activity = reader.string();
                    break;
                case /* string activity_date */ 8:
                    message.activityDate = reader.string();
                    break;
                case /* string notes */ 9:
                    message.notes = reader.string();
                    break;
                case /* repeated string field_mask */ 10:
                    message.fieldMask.push(reader.string());
                    break;
                case /* string order_by */ 11:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 12:
                    message.orderDir = reader.string();
                    break;
                case /* int32 page_number */ 13:
                    message.pageNumber = reader.int32();
                    break;
                case /* bool without_limit */ 14:
                    message.withoutLimit = reader.bool();
                    break;
                case /* bool is_active */ 15:
                    message.isActive = reader.bool();
                    break;
                case /* string owner_firstname */ 16:
                    message.ownerFirstname = reader.string();
                    break;
                case /* string owner_lastname */ 17:
                    message.ownerLastname = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 vehicle_id = 2; */
        if (message.vehicleId !== 0)
            writer.tag(2, WireType.Varint).int32(message.vehicleId);
        /* int32 vehicle_asset_id = 3; */
        if (message.vehicleAssetId !== 0)
            writer.tag(3, WireType.Varint).int32(message.vehicleAssetId);
        /* int32 vehicle_trailer_id = 4; */
        if (message.vehicleTrailerId !== 0)
            writer.tag(4, WireType.Varint).int32(message.vehicleTrailerId);
        /* int32 owner_id = 5; */
        if (message.ownerId !== 0)
            writer.tag(5, WireType.Varint).int32(message.ownerId);
        /* string activity_name = 6; */
        if (message.activityName !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.activityName);
        /* string activity = 7; */
        if (message.activity !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.activity);
        /* string activity_date = 8; */
        if (message.activityDate !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.activityDate);
        /* string notes = 9; */
        if (message.notes !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.notes);
        /* repeated string field_mask = 10; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(10, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* string order_by = 11; */
        if (message.orderBy !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 12; */
        if (message.orderDir !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.orderDir);
        /* int32 page_number = 13; */
        if (message.pageNumber !== 0)
            writer.tag(13, WireType.Varint).int32(message.pageNumber);
        /* bool without_limit = 14; */
        if (message.withoutLimit !== false)
            writer.tag(14, WireType.Varint).bool(message.withoutLimit);
        /* bool is_active = 15; */
        if (message.isActive !== false)
            writer.tag(15, WireType.Varint).bool(message.isActive);
        /* string owner_firstname = 16; */
        if (message.ownerFirstname !== "")
            writer.tag(16, WireType.LengthDelimited).string(message.ownerFirstname);
        /* string owner_lastname = 17; */
        if (message.ownerLastname !== "")
            writer.tag(17, WireType.LengthDelimited).string(message.ownerLastname);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message VehicleLog
 */
export const VehicleLog = new VehicleLog$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VehicleLogList$Type extends MessageType {
    constructor() {
        super("VehicleLogList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => VehicleLog },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated VehicleLog results */ 1:
                    message.results.push(VehicleLog.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated VehicleLog results = 1; */
        for (let i = 0; i < message.results.length; i++)
            VehicleLog.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message VehicleLogList
 */
export const VehicleLogList = new VehicleLogList$Type();
/**
 * @generated ServiceType for protobuf service VehicleDriverService
 */
export const VehicleDriverService = new ServiceType("VehicleDriverService", [
    { name: "CreateVehicle", options: {}, I: Vehicle, O: Vehicle },
    { name: "GetVehicle", options: {}, I: Vehicle, O: Vehicle },
    { name: "BatchGetVehicle", options: {}, I: Vehicle, O: VehicleList },
    { name: "UpdateVehicle", options: {}, I: Vehicle, O: Vehicle },
    { name: "DeleteVehicle", options: {}, I: Vehicle, O: Vehicle },
    { name: "CreateDriver", options: {}, I: Driver, O: Driver },
    { name: "GetDriver", options: {}, I: Driver, O: Driver },
    { name: "BatchGetDriver", options: {}, I: Driver, O: DriverList },
    { name: "UpdateDriver", options: {}, I: Driver, O: Driver },
    { name: "DeleteDriver", options: {}, I: Driver, O: Driver },
    { name: "CreateVehicleTrailer", options: {}, I: VehicleTrailer, O: VehicleTrailer },
    { name: "GetVehicleTrailer", options: {}, I: VehicleTrailer, O: VehicleTrailer },
    { name: "BatchGetVehicleTrailer", options: {}, I: VehicleTrailer, O: VehicleTrailerList },
    { name: "UpdateVehicleTrailer", options: {}, I: VehicleTrailer, O: VehicleTrailer },
    { name: "DeleteVehicleTrailer", options: {}, I: VehicleTrailer, O: Empty },
    { name: "CreateVehicleAsset", options: {}, I: VehicleAsset, O: VehicleAsset },
    { name: "GetVehicleAsset", options: {}, I: VehicleAsset, O: VehicleAsset },
    { name: "BatchGetVehicleAsset", options: {}, I: VehicleAsset, O: VehicleAssetList },
    { name: "UpdateVehicleAsset", options: {}, I: VehicleAsset, O: VehicleAsset },
    { name: "DeleteVehicleAsset", options: {}, I: VehicleAsset, O: Empty },
    { name: "CreateVehiclePhoto", options: {}, I: VehiclePhoto, O: VehiclePhoto },
    { name: "GetVehiclePhoto", options: {}, I: VehiclePhoto, O: VehiclePhoto },
    { name: "BatchGetVehiclePhoto", options: {}, I: VehiclePhoto, O: VehiclePhotoList },
    { name: "UpdateVehiclePhoto", options: {}, I: VehiclePhoto, O: VehiclePhoto },
    { name: "DeleteVehiclePhoto", options: {}, I: VehiclePhoto, O: Empty },
    { name: "GetVehicleLog", options: {}, I: VehicleLog, O: VehicleLog },
    { name: "BatchGetVehicleLog", options: {}, I: VehicleLog, O: VehicleLogList }
]);
