import { BaseClient } from '../BaseClient';
import { TaskAssignment, TaskAssignmentList } from '../compiled-protos/task';
import { TaskServiceClient as TaskAssignmentServiceClient } from '../compiled-protos/task.client';

class TaskAssignmentClient extends BaseClient {
  self: TaskAssignmentServiceClient;

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new TaskAssignmentServiceClient(this.transport);
  }

  public async Create(req: TaskAssignment) {
    let res = TaskAssignment.create();
    try {
      res = await this.self.createTaskAssignment(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Get(req: TaskAssignment) {
    let res = TaskAssignment.create();
    try {
      res = await this.self.getTaskAssignment(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Update(req: TaskAssignment) {
    let res = TaskAssignment.create();
    try {
      res = await this.self.updateTaskAssignment(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Delete(req: TaskAssignment) {
    let res = TaskAssignment.create();
    try {
      res = await this.self.deleteTaskAssignment(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async BatchGet(req: TaskAssignment) {
    let res = TaskAssignmentList.create();
    try {
      res = await this.self.batchGetTaskAssignment(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public loadTaskAssignment = async (taskId: number) => {
    const req = TaskAssignment.create();
    req.isActive = 1;
    req.taskId = taskId;
    const results: TaskAssignment[] = [];
    const res = await this.self.batchGetTaskAssignment(req);
    const resultsList = res.response.results;
    const totalCount = res.response.totalCount;
    const len = resultsList.length;
    results.concat(resultsList);
    if (totalCount > len) {
      const batchesAmount = Math.ceil((totalCount - len) / len);
      const batchResults = await Promise.all(
        Array.from(Array(batchesAmount)).map(async (_, idx) => {
          req.pageNumber = idx + 1;
          return (await this.BatchGet(req))!.results;
        })
      );
      results.push(
        ...batchResults.reduce((aggr, item) => [...aggr, ...item], [])
      );
    }
    return results;
  };

  public upsertTaskAssignments = async (
    taskId: number,
    technicianIds: number[]
  ) => {
    const req = TaskAssignment.create();
    req.taskId = taskId; // Below was added when this function was still in helpers in the frontend:
    await this.self.deleteTaskAssignment(req); // FIXME deleting by taskId doesn't work - resolve when task will return TaskAssignment[]
    await Promise.all(
      technicianIds.map(id => {
        const req = TaskAssignment.create();
        req.taskId = taskId;
        req.userId = id;
        this.Create(req);
      })
    );
  };
}

export { TaskAssignment, TaskAssignmentList, TaskAssignmentClient };
