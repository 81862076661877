import { BaseClient } from '../BaseClient';
import {
  TimesheetClassCode,
  TimesheetClassCodeList,
} from '../compiled-protos/timesheet_department';
import { TimesheetDepartmentServiceClient } from '../compiled-protos/timesheet_department.client';

class TimesheetClassCodeClient extends BaseClient {
  self: TimesheetDepartmentServiceClient;

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new TimesheetDepartmentServiceClient(this.transport);
  }

  public async Create(req: TimesheetClassCode) {
    let res = TimesheetClassCode.create();
    try {
      res = await this.self.createTimesheetClassCode(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Get(req: TimesheetClassCode) {
    let res = TimesheetClassCode.create();
    try {
      res = await this.self.getTimesheetClassCode(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Update(req: TimesheetClassCode) {
    let res = TimesheetClassCode.create();
    try {
      res = await this.self.updateTimesheetClassCode(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Delete(req: TimesheetClassCode) {
    const res = TimesheetClassCode.create();
    try {
      await this.self.deleteTimesheetClassCode(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return true;
  }

  public async BatchGet(req: TimesheetClassCode) {
    let res = TimesheetClassCodeList.create();
    try {
      res = await this.self.batchGetTimesheetClassCode(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }
}

export {
  TimesheetClassCode as ClassCode,
  TimesheetClassCodeList as ClassCodeList,
  TimesheetClassCodeClient as ClassCodeClient,
};
