import { BaseClient } from '../BaseClient';
import {
  SpiffToolAdminAction,
  SpiffToolAdminActionList,
} from '../compiled-protos/task';
import { TaskServiceClient as SpiffToolAdminActionServiceClient } from '../compiled-protos/task.client';

class SpiffToolAdminActionClient extends BaseClient {
  self: SpiffToolAdminActionServiceClient;

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new SpiffToolAdminActionServiceClient(this.transport);
  }

  public async Create(req: SpiffToolAdminAction) {
    return await this.self.createSpiffToolAdminAction(req, this.getMetaData())
      .response;
  }

  public async Get(req: SpiffToolAdminAction) {
    let res = SpiffToolAdminAction.create();
    try {
      res = await this.self.getSpiffToolAdminAction(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
      return;
    }
    return res;
  }

  public async Update(req: SpiffToolAdminAction) {
    return await this.self.updateSpiffToolAdminAction(req, this.getMetaData())
      .response;
  }

  public async Delete(req: SpiffToolAdminAction) {
    let res = SpiffToolAdminAction.create();
    try {
      res = await this.self.deleteSpiffToolAdminAction(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async BatchGet(req: SpiffToolAdminAction) {
    let res = SpiffToolAdminActionList.create();
    try {
      res = await this.self.batchGetSpiffToolAdminAction(
        req,
        this.getMetaData()
      ).response;
    } catch (err) {
      console.log(err);
      return;
      return;
    }
    return res;
  }
  /** Returns loaded SpiffToolAdminActions by task id
   * @param taskID: number
   * @returns SpiffToolAdminAction[]
   */
  public async loadSpiffToolAdminActionsByTaskId(taskID: number) {
    const results: SpiffToolAdminAction[] = [];
    const req = SpiffToolAdminAction.create();
    req.pageNumber = 0;
    req.taskId = taskID;
    const res = await this.BatchGet(req);
    const resultsList = res!.results;
    const totalCount = res!.totalCount;
    const len = resultsList.length;
    results.concat(resultsList);
    if (totalCount > resultsList.length) {
      const batchesAmount = Math.ceil((totalCount - len) / len);
      const batchResults = await Promise.all(
        Array.from(Array(batchesAmount)).map(async (_, idx) => {
          req.pageNumber = idx + 1;
          return (await this.self.batchGetSpiffToolAdminAction(req)).response
            .results;
        })
      );
      results.push(
        ...batchResults.reduce((aggr, item) => [...aggr, ...item], [])
      );
    }
    return results.sort((a, b) => {
      const A = a.id;
      const B = b.id;
      if (A < B) return 1;
      if (A > B) return -1;
      return 0;
    });
  }

  upsertSpiffToolAdminAction = async (data: SpiffToolAdminAction) => {
    const id = data.id;
    if (id !== 0 && data.fieldMask.length === 0) {
      throw new Error(
        'Attempting to update entity without providing a field mask will result in a no op'
      );
    }
    await this[id ? 'Create' : 'Update'](data);
  };
}

export {
  SpiffToolAdminAction,
  SpiffToolAdminActionList,
  SpiffToolAdminActionClient,
};
