import { type TimesheetDepartment } from '@kalos/kalos-rpc';
import {
  Button,
  cn,
  Combobox,
  DateInput,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  Input,
  SimpleSelect,
} from '@kalos/ui';
import { useCallback, useState } from 'react';

import { EVENT_STATUS_LIST, OPTION_ALL } from '../../../../constants';
import { useJobSubTypesQuery } from '../../../../hooks/react-query/useJobSubTypesQuery';
import { useJobTypesQuery } from '../../../../hooks/react-query/useJobTypesQuery';
import { useBatchUserQuery } from '../../../../hooks/react-query/useUserQuery';
import {
  techniciansUserFilter,
  TimesheetDepartmentPickerV2,
} from '../../Pickers/newPickers/QueryPickerV2';
import { useServiceCallsSearchFormContext } from './utils';

const EMPTY_VALUE = ' ';
const JOB_STATUS_OPTIONS = [
  { label: OPTION_ALL, value: EMPTY_VALUE },
  ...EVENT_STATUS_LIST.map((label) => ({
    label,
    value: label,
  })),
];

export const ServiceCallsSearchForm = () => {
  const [show, setShow] = useState<boolean>(true);
  const form = useServiceCallsSearchFormContext();
  const renderDepartment = useCallback((dep: TimesheetDepartment) => dep.description, []);

  const techniciansQuery = useBatchUserQuery({
    filters: techniciansUserFilter,
    select(data) {
      return data.results.map((technician) => ({
        label: `${technician.firstname} ${technician.lastname}`,
        value: technician.id.toString(),
      }));
    },
  });
  const jobTypesQuery = useJobTypesQuery({
    select(data) {
      return (
        data.map((jt) => ({
          label: jt.name,
          value: jt.id.toString(),
        })) ?? []
      );
    },
  });
  const jobSubTypesQuery = useJobSubTypesQuery({
    select(data) {
      return (
        data?.results.map((jst) => ({
          label: jst.name,
          value: jst.id.toString(),
        })) ?? []
      );
    },
  });

  return (
    <>
      <div className="flex justify-end pb-3">
        <Button
          className="xs:hidden"
          onClick={() => {
            setShow((isShown) => !isShown);
          }}
        >
          {show ? 'Hide' : 'Show'} Filters
        </Button>
      </div>
      <div
        tabIndex={show ? undefined : -1}
        className={cn('grid transition-all', show ? 'grid-rows-[1fr]' : 'grid-rows-[0fr]')}
      >
        <form
          aria-hidden={!show}
          onSubmit={form.handleSubmit(console.log)}
          className="xs:h-max xs:grid-cols-3 transition-al grid grid-cols-2 gap-x-4 gap-y-3 overflow-hidden p-0.5 transition-[0.2s] lg:grid-cols-6"
        >
          <FormField
            control={form.control}
            name="firstname"
            render={({ field }) => (
              <FormItem>
                <FormLabel>First Name</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="lastname"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Last Name</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="businessname"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Business Name</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="logNotes"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Job Notes</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Brief Description</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="logTechnicianAssigned"
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>Technician Assigned</FormLabel>
                  <Combobox
                    onChange={(value) => {
                      field.onChange(value ? Number(value) : undefined);
                    }}
                    value={field.value?.toString() ?? ''}
                    emptyLabel="Unassigned"
                    disabled={techniciansQuery.isPending}
                    values={techniciansQuery.data ?? []}
                    placeholder="Search Technician"
                  />
                </FormItem>
              );
            }}
          />

          <FormField
            control={form.control}
            name="logJobNumber"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Job #</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="departmentId"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Department</FormLabel>
                <FormControl>
                  <TimesheetDepartmentPickerV2
                    renderItem={renderDepartment}
                    queryArgs={{
                      filter: { isActive: 1, teamOnly: false },
                      fieldMask: ['TeamOnly'],
                    }}
                    onSelect={(val) => {
                      field.onChange(val?.id || 0);
                    }}
                    selected={(field?.value || '0').toString()}
                    placeholder="select department"
                    triggerClassName="h-auto text-xs"
                  />
                </FormControl>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="logPo"
            render={({ field }) => (
              <FormItem>
                <FormLabel>PO</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Services Needed</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="isResidential"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Sector</FormLabel>
                <SimpleSelect
                  placeholder={OPTION_ALL}
                  onChange={(value) => {
                    field.onChange(Number(value));
                  }}
                  selectedValue={field.value.toString()}
                  values={[
                    { label: OPTION_ALL, value: '0' },
                    { label: 'Residential', value: '1' },
                    { label: 'Commercial', value: '2' },
                  ]}
                />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="jobTypeId"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Job Type</FormLabel>
                <SimpleSelect
                  placeholder={OPTION_ALL}
                  onChange={(value) => {
                    field.onChange(Number(value));
                  }}
                  disabled={jobTypesQuery.isPending}
                  selectedValue={field.value.toString()}
                  values={[{ label: OPTION_ALL, value: '0' }, ...(jobTypesQuery.data ?? [])]}
                />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="jobSubtypeId"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Job Subtype</FormLabel>
                <SimpleSelect
                  placeholder={OPTION_ALL}
                  selectedValue={field.value.toString()}
                  disabled={jobSubTypesQuery.isPending}
                  onChange={(value) => {
                    field.onChange(Number(value));
                  }}
                  values={[{ label: OPTION_ALL, value: '0' }, ...(jobSubTypesQuery.data ?? [])]}
                />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="logJobStatus"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Job Status</FormLabel>
                <SimpleSelect
                  placeholder={OPTION_ALL}
                  selectedValue={field.value ?? EMPTY_VALUE}
                  onChange={(value) => field.onChange(value === EMPTY_VALUE ? '' : value)}
                  values={JOB_STATUS_OPTIONS}
                />
              </FormItem>
            )}
          />

          {/* TODO - here can be a date range */}
          <FormField
            control={form.control}
            name="dateStartedFrom"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Date Started - From</FormLabel>
                <DateInput value={field.value} onChange={field.onChange} />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="dateStartedTo"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Date Started - To</FormLabel>
                <DateInput value={field.value} onChange={field.onChange} />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="address"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Address</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="city"
            render={({ field }) => (
              <FormItem>
                <FormLabel>City</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="zip"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Zip Code</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
              </FormItem>
            )}
          />

          <Button
            className="mr-auto mt-auto max-w-max"
            onClick={() => form.reset()}
            type="reset"
            variant="outline"
          >
            Reset
          </Button>
        </form>
      </div>
    </>
  );
};
