// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "google.proto" (syntax proto3)
// tslint:disable
// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "google.proto" (syntax proto3)
// tslint:disable
import { Int32 } from "./common";
import { String$ } from "./common";
import { Bool } from "./common";
import { Empty } from "./common";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { WireType } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { User } from "./user";
/**
 * @generated from protobuf enum Icons
 */
export var Icons;
(function (Icons) {
    /**
     * @generated from protobuf enum value: ICON_UNSPECIFIED = 0;
     */
    Icons[Icons["ICON_UNSPECIFIED"] = 0] = "ICON_UNSPECIFIED";
    /**
     * @generated from protobuf enum value: AIRPLANE = 1;
     */
    Icons[Icons["AIRPLANE"] = 1] = "AIRPLANE";
    /**
     * @generated from protobuf enum value: BOOKMARK = 2;
     */
    Icons[Icons["BOOKMARK"] = 2] = "BOOKMARK";
    /**
     * @generated from protobuf enum value: BUS = 3;
     */
    Icons[Icons["BUS"] = 3] = "BUS";
    /**
     * @generated from protobuf enum value: CAP = 4;
     */
    Icons[Icons["CAP"] = 4] = "CAP";
    /**
     * @generated from protobuf enum value: CLOCK = 5;
     */
    Icons[Icons["CLOCK"] = 5] = "CLOCK";
    /**
     * @generated from protobuf enum value: CONFIRMATION_NUMBER_ICON = 6;
     */
    Icons[Icons["CONFIRMATION_NUMBER_ICON"] = 6] = "CONFIRMATION_NUMBER_ICON";
    /**
     * @generated from protobuf enum value: DOLLAR = 7;
     */
    Icons[Icons["DOLLAR"] = 7] = "DOLLAR";
    /**
     * @generated from protobuf enum value: DESCRIPTION = 8;
     */
    Icons[Icons["DESCRIPTION"] = 8] = "DESCRIPTION";
    /**
     * @generated from protobuf enum value: EMAIL = 9;
     */
    Icons[Icons["EMAIL"] = 9] = "EMAIL";
    /**
     * @generated from protobuf enum value: EVENT_PERFORMER = 10;
     */
    Icons[Icons["EVENT_PERFORMER"] = 10] = "EVENT_PERFORMER";
    /**
     * @generated from protobuf enum value: EVENT_SEAT = 11;
     */
    Icons[Icons["EVENT_SEAT"] = 11] = "EVENT_SEAT";
    /**
     * @generated from protobuf enum value: FLIGHT_ARRIVAL = 12;
     */
    Icons[Icons["FLIGHT_ARRIVAL"] = 12] = "FLIGHT_ARRIVAL";
    /**
     * @generated from protobuf enum value: FLIGHT_DEPARTURE = 13;
     */
    Icons[Icons["FLIGHT_DEPARTURE"] = 13] = "FLIGHT_DEPARTURE";
    /**
     * @generated from protobuf enum value: HOTEL = 14;
     */
    Icons[Icons["HOTEL"] = 14] = "HOTEL";
    /**
     * @generated from protobuf enum value: HOTEL_ROOM_TYPE = 15;
     */
    Icons[Icons["HOTEL_ROOM_TYPE"] = 15] = "HOTEL_ROOM_TYPE";
    /**
     * @generated from protobuf enum value: INVITE = 16;
     */
    Icons[Icons["INVITE"] = 16] = "INVITE";
    /**
     * @generated from protobuf enum value: MAP_PIN = 17;
     */
    Icons[Icons["MAP_PIN"] = 17] = "MAP_PIN";
    /**
     * @generated from protobuf enum value: MEMBERSHIP = 18;
     */
    Icons[Icons["MEMBERSHIP"] = 18] = "MEMBERSHIP";
    /**
     * @generated from protobuf enum value: MULTIPLE_PEOPLE = 19;
     */
    Icons[Icons["MULTIPLE_PEOPLE"] = 19] = "MULTIPLE_PEOPLE";
    /**
     * @generated from protobuf enum value: OFFER = 20;
     */
    Icons[Icons["OFFER"] = 20] = "OFFER";
    /**
     * @generated from protobuf enum value: PERSON = 21;
     */
    Icons[Icons["PERSON"] = 21] = "PERSON";
    /**
     * @generated from protobuf enum value: PHONE = 22;
     */
    Icons[Icons["PHONE"] = 22] = "PHONE";
    /**
     * @generated from protobuf enum value: RESTAURANT_ICON = 23;
     */
    Icons[Icons["RESTAURANT_ICON"] = 23] = "RESTAURANT_ICON";
    /**
     * @generated from protobuf enum value: SHOPPING_CART = 24;
     */
    Icons[Icons["SHOPPING_CART"] = 24] = "SHOPPING_CART";
    /**
     * @generated from protobuf enum value: STAR = 25;
     */
    Icons[Icons["STAR"] = 25] = "STAR";
    /**
     * @generated from protobuf enum value: STORE = 26;
     */
    Icons[Icons["STORE"] = 26] = "STORE";
    /**
     * @generated from protobuf enum value: TICKET = 27;
     */
    Icons[Icons["TICKET"] = 27] = "TICKET";
    /**
     * @generated from protobuf enum value: TRAIN = 28;
     */
    Icons[Icons["TRAIN"] = 28] = "TRAIN";
    /**
     * @generated from protobuf enum value: VIDEO_CAMERA = 29;
     */
    Icons[Icons["VIDEO_CAMERA"] = 29] = "VIDEO_CAMERA";
    /**
     * @generated from protobuf enum value: VIDEO_PLAY = 30;
     */
    Icons[Icons["VIDEO_PLAY"] = 30] = "VIDEO_PLAY";
})(Icons || (Icons = {}));
// @generated message type with reflection information, may provide speed optimized methods
class DirectMessageRequest$Type extends MessageType {
    constructor() {
        super("DirectMessageRequest", [
            { no: 1, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "message", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { userId: 0, message: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 user_id */ 1:
                    message.userId = reader.int32();
                    break;
                case /* string message */ 2:
                    message.message = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 user_id = 1; */
        if (message.userId !== 0)
            writer.tag(1, WireType.Varint).int32(message.userId);
        /* string message = 2; */
        if (message.message !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.message);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DirectMessageRequest
 */
export const DirectMessageRequest = new DirectMessageRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SheetData$Type extends MessageType {
    constructor() {
        super("SheetData", [
            { no: 1, name: "sheet_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "csv_data", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { sheetName: "", csvData: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string sheet_name */ 1:
                    message.sheetName = reader.string();
                    break;
                case /* repeated string csv_data */ 2:
                    message.csvData.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string sheet_name = 1; */
        if (message.sheetName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.sheetName);
        /* repeated string csv_data = 2; */
        for (let i = 0; i < message.csvData.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.csvData[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SheetData
 */
export const SheetData = new SheetData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UploadCsvRequest$Type extends MessageType {
    constructor() {
        super("UploadCsvRequest", [
            { no: 1, name: "sheets_data", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SheetData },
            { no: 2, name: "user_email", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { sheetsData: [], userEmail: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated SheetData sheets_data */ 1:
                    message.sheetsData.push(SheetData.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string user_email */ 2:
                    message.userEmail = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated SheetData sheets_data = 1; */
        for (let i = 0; i < message.sheetsData.length; i++)
            SheetData.internalBinaryWrite(message.sheetsData[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string user_email = 2; */
        if (message.userEmail !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.userEmail);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message UploadCsvRequest
 */
export const UploadCsvRequest = new UploadCsvRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UploadCsvResponse$Type extends MessageType {
    constructor() {
        super("UploadCsvResponse", [
            { no: 1, name: "success", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value) {
        const message = { success: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool success */ 1:
                    message.success = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* bool success = 1; */
        if (message.success !== false)
            writer.tag(1, WireType.Varint).bool(message.success);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message UploadCsvResponse
 */
export const UploadCsvResponse = new UploadCsvResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GoogleUser$Type extends MessageType {
    constructor() {
        super("GoogleUser", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "google_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "without_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value) {
        const message = { id: "", googleId: "", userId: 0, fieldMask: [], pageNumber: 0, withoutLimit: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string google_id */ 2:
                    message.googleId = reader.string();
                    break;
                case /* int32 user_id */ 3:
                    message.userId = reader.int32();
                    break;
                case /* repeated string field_mask */ 4:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 5:
                    message.pageNumber = reader.int32();
                    break;
                case /* bool without_limit */ 6:
                    message.withoutLimit = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string google_id = 2; */
        if (message.googleId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.googleId);
        /* int32 user_id = 3; */
        if (message.userId !== 0)
            writer.tag(3, WireType.Varint).int32(message.userId);
        /* repeated string field_mask = 4; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 5; */
        if (message.pageNumber !== 0)
            writer.tag(5, WireType.Varint).int32(message.pageNumber);
        /* bool without_limit = 6; */
        if (message.withoutLimit !== false)
            writer.tag(6, WireType.Varint).bool(message.withoutLimit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GoogleUser
 */
export const GoogleUser = new GoogleUser$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GoogleUserList$Type extends MessageType {
    constructor() {
        super("GoogleUserList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => GoogleUser },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated GoogleUser results */ 1:
                    message.results.push(GoogleUser.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated GoogleUser results = 1; */
        for (let i = 0; i < message.results.length; i++)
            GoogleUser.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GoogleUserList
 */
export const GoogleUserList = new GoogleUserList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CardAction$Type extends MessageType {
    constructor() {
        super("CardAction", [
            { no: 1, name: "action_label", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "on_click", kind: "message", T: () => CardOnClick }
        ]);
    }
    create(value) {
        const message = { actionLabel: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string action_label */ 1:
                    message.actionLabel = reader.string();
                    break;
                case /* CardOnClick on_click */ 2:
                    message.onClick = CardOnClick.internalBinaryRead(reader, reader.uint32(), options, message.onClick);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string action_label = 1; */
        if (message.actionLabel !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.actionLabel);
        /* CardOnClick on_click = 2; */
        if (message.onClick)
            CardOnClick.internalBinaryWrite(message.onClick, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CardAction
 */
export const CardAction = new CardAction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CardHeader$Type extends MessageType {
    constructor() {
        super("CardHeader", [
            { no: 1, name: "image_style", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "image_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "subtitle", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { imageStyle: "", imageUrl: "", title: "", subtitle: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string image_style */ 1:
                    message.imageStyle = reader.string();
                    break;
                case /* string image_url */ 2:
                    message.imageUrl = reader.string();
                    break;
                case /* string title */ 3:
                    message.title = reader.string();
                    break;
                case /* string subtitle */ 4:
                    message.subtitle = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string image_style = 1; */
        if (message.imageStyle !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.imageStyle);
        /* string image_url = 2; */
        if (message.imageUrl !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.imageUrl);
        /* string title = 3; */
        if (message.title !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.title);
        /* string subtitle = 4; */
        if (message.subtitle !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.subtitle);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CardHeader
 */
export const CardHeader = new CardHeader$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CardSection$Type extends MessageType {
    constructor() {
        super("CardSection", [
            { no: 1, name: "header", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "widgets", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CardWidget }
        ]);
    }
    create(value) {
        const message = { header: "", widgets: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string header */ 1:
                    message.header = reader.string();
                    break;
                case /* repeated CardWidget widgets */ 2:
                    message.widgets.push(CardWidget.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string header = 1; */
        if (message.header !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.header);
        /* repeated CardWidget widgets = 2; */
        for (let i = 0; i < message.widgets.length; i++)
            CardWidget.internalBinaryWrite(message.widgets[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CardSection
 */
export const CardSection = new CardSection$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CardButton$Type extends MessageType {
    constructor() {
        super("CardButton", [
            { no: 1, name: "text_button", kind: "message", T: () => TextButton },
            { no: 2, name: "image_button", kind: "message", T: () => ImageButton }
        ]);
    }
    create(value) {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* TextButton text_button */ 1:
                    message.textButton = TextButton.internalBinaryRead(reader, reader.uint32(), options, message.textButton);
                    break;
                case /* ImageButton image_button */ 2:
                    message.imageButton = ImageButton.internalBinaryRead(reader, reader.uint32(), options, message.imageButton);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* TextButton text_button = 1; */
        if (message.textButton)
            TextButton.internalBinaryWrite(message.textButton, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* ImageButton image_button = 2; */
        if (message.imageButton)
            ImageButton.internalBinaryWrite(message.imageButton, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CardButton
 */
export const CardButton = new CardButton$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImageButton$Type extends MessageType {
    constructor() {
        super("ImageButton", [
            { no: 1, name: "icon", kind: "enum", T: () => ["Icons", Icons] },
            { no: 2, name: "icon_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "on_click", kind: "message", T: () => CardOnClick }
        ]);
    }
    create(value) {
        const message = { icon: 0, iconUrl: "", name: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Icons icon */ 1:
                    message.icon = reader.int32();
                    break;
                case /* string icon_url */ 2:
                    message.iconUrl = reader.string();
                    break;
                case /* string name */ 3:
                    message.name = reader.string();
                    break;
                case /* CardOnClick on_click */ 4:
                    message.onClick = CardOnClick.internalBinaryRead(reader, reader.uint32(), options, message.onClick);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* Icons icon = 1; */
        if (message.icon !== 0)
            writer.tag(1, WireType.Varint).int32(message.icon);
        /* string icon_url = 2; */
        if (message.iconUrl !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.iconUrl);
        /* string name = 3; */
        if (message.name !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.name);
        /* CardOnClick on_click = 4; */
        if (message.onClick)
            CardOnClick.internalBinaryWrite(message.onClick, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ImageButton
 */
export const ImageButton = new ImageButton$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TextButton$Type extends MessageType {
    constructor() {
        super("TextButton", [
            { no: 1, name: "text", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "on_click", kind: "message", T: () => CardOnClick }
        ]);
    }
    create(value) {
        const message = { text: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string text */ 1:
                    message.text = reader.string();
                    break;
                case /* CardOnClick on_click */ 2:
                    message.onClick = CardOnClick.internalBinaryRead(reader, reader.uint32(), options, message.onClick);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string text = 1; */
        if (message.text !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.text);
        /* CardOnClick on_click = 2; */
        if (message.onClick)
            CardOnClick.internalBinaryWrite(message.onClick, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TextButton
 */
export const TextButton = new TextButton$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CardImage$Type extends MessageType {
    constructor() {
        super("CardImage", [
            { no: 1, name: "aspect_ration", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 2, name: "image_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "on_click", kind: "message", T: () => CardOnClick }
        ]);
    }
    create(value) {
        const message = { aspectRation: 0, imageUrl: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* double aspect_ration */ 1:
                    message.aspectRation = reader.double();
                    break;
                case /* string image_url */ 2:
                    message.imageUrl = reader.string();
                    break;
                case /* CardOnClick on_click */ 3:
                    message.onClick = CardOnClick.internalBinaryRead(reader, reader.uint32(), options, message.onClick);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* double aspect_ration = 1; */
        if (message.aspectRation !== 0)
            writer.tag(1, WireType.Bit64).double(message.aspectRation);
        /* string image_url = 2; */
        if (message.imageUrl !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.imageUrl);
        /* CardOnClick on_click = 3; */
        if (message.onClick)
            CardOnClick.internalBinaryWrite(message.onClick, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CardImage
 */
export const CardImage = new CardImage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CardKeyValue$Type extends MessageType {
    constructor() {
        super("CardKeyValue", [
            { no: 1, name: "bottom_label", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "button", kind: "message", T: () => CardButton },
            { no: 3, name: "content", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "content_multiline", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "icon", kind: "enum", T: () => ["Icons", Icons] },
            { no: 6, name: "icon_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "on_click", kind: "message", T: () => CardOnClick },
            { no: 8, name: "top_label", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { bottomLabel: "", content: "", contentMultiline: false, icon: 0, iconUrl: "", topLabel: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string bottom_label */ 1:
                    message.bottomLabel = reader.string();
                    break;
                case /* CardButton button */ 2:
                    message.button = CardButton.internalBinaryRead(reader, reader.uint32(), options, message.button);
                    break;
                case /* string content */ 3:
                    message.content = reader.string();
                    break;
                case /* bool content_multiline */ 4:
                    message.contentMultiline = reader.bool();
                    break;
                case /* Icons icon */ 5:
                    message.icon = reader.int32();
                    break;
                case /* string icon_url */ 6:
                    message.iconUrl = reader.string();
                    break;
                case /* CardOnClick on_click */ 7:
                    message.onClick = CardOnClick.internalBinaryRead(reader, reader.uint32(), options, message.onClick);
                    break;
                case /* string top_label */ 8:
                    message.topLabel = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string bottom_label = 1; */
        if (message.bottomLabel !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.bottomLabel);
        /* CardButton button = 2; */
        if (message.button)
            CardButton.internalBinaryWrite(message.button, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string content = 3; */
        if (message.content !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.content);
        /* bool content_multiline = 4; */
        if (message.contentMultiline !== false)
            writer.tag(4, WireType.Varint).bool(message.contentMultiline);
        /* Icons icon = 5; */
        if (message.icon !== 0)
            writer.tag(5, WireType.Varint).int32(message.icon);
        /* string icon_url = 6; */
        if (message.iconUrl !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.iconUrl);
        /* CardOnClick on_click = 7; */
        if (message.onClick)
            CardOnClick.internalBinaryWrite(message.onClick, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* string top_label = 8; */
        if (message.topLabel !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.topLabel);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CardKeyValue
 */
export const CardKeyValue = new CardKeyValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CardWidget$Type extends MessageType {
    constructor() {
        super("CardWidget", [
            { no: 1, name: "buttons", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CardButton },
            { no: 2, name: "text_paragraph", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "image", kind: "message", T: () => CardImage },
            { no: 4, name: "key_value", kind: "message", T: () => CardKeyValue }
        ]);
    }
    create(value) {
        const message = { buttons: [], textParagraph: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated CardButton buttons */ 1:
                    message.buttons.push(CardButton.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string text_paragraph */ 2:
                    message.textParagraph = reader.string();
                    break;
                case /* CardImage image */ 3:
                    message.image = CardImage.internalBinaryRead(reader, reader.uint32(), options, message.image);
                    break;
                case /* CardKeyValue key_value */ 4:
                    message.keyValue = CardKeyValue.internalBinaryRead(reader, reader.uint32(), options, message.keyValue);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated CardButton buttons = 1; */
        for (let i = 0; i < message.buttons.length; i++)
            CardButton.internalBinaryWrite(message.buttons[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string text_paragraph = 2; */
        if (message.textParagraph !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.textParagraph);
        /* CardImage image = 3; */
        if (message.image)
            CardImage.internalBinaryWrite(message.image, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* CardKeyValue key_value = 4; */
        if (message.keyValue)
            CardKeyValue.internalBinaryWrite(message.keyValue, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CardWidget
 */
export const CardWidget = new CardWidget$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CardOnClick$Type extends MessageType {
    constructor() {
        super("CardOnClick", [
            { no: 1, name: "action", kind: "message", T: () => CardFormAction },
            { no: 2, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { url: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* CardFormAction action */ 1:
                    message.action = CardFormAction.internalBinaryRead(reader, reader.uint32(), options, message.action);
                    break;
                case /* string url */ 2:
                    message.url = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* CardFormAction action = 1; */
        if (message.action)
            CardFormAction.internalBinaryWrite(message.action, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string url = 2; */
        if (message.url !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.url);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CardOnClick
 */
export const CardOnClick = new CardOnClick$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CardFormAction$Type extends MessageType {
    constructor() {
        super("CardFormAction", [
            { no: 1, name: "action_method_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "parameters", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => FormActionParameter }
        ]);
    }
    create(value) {
        const message = { actionMethodName: "", parameters: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string action_method_name */ 1:
                    message.actionMethodName = reader.string();
                    break;
                case /* repeated FormActionParameter parameters */ 2:
                    message.parameters.push(FormActionParameter.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string action_method_name = 1; */
        if (message.actionMethodName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.actionMethodName);
        /* repeated FormActionParameter parameters = 2; */
        for (let i = 0; i < message.parameters.length; i++)
            FormActionParameter.internalBinaryWrite(message.parameters[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CardFormAction
 */
export const CardFormAction = new CardFormAction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FormActionParameter$Type extends MessageType {
    constructor() {
        super("FormActionParameter", [
            { no: 1, name: "key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "value", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { key: "", value: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string key */ 1:
                    message.key = reader.string();
                    break;
                case /* string value */ 2:
                    message.value = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string key = 1; */
        if (message.key !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.key);
        /* string value = 2; */
        if (message.value !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.value);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message FormActionParameter
 */
export const FormActionParameter = new FormActionParameter$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChatCard$Type extends MessageType {
    constructor() {
        super("ChatCard", [
            { no: 1, name: "actions", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CardAction },
            { no: 2, name: "header", kind: "message", T: () => CardHeader },
            { no: 3, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "sections", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CardSection }
        ]);
    }
    create(value) {
        const message = { actions: [], name: "", sections: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated CardAction actions */ 1:
                    message.actions.push(CardAction.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* CardHeader header */ 2:
                    message.header = CardHeader.internalBinaryRead(reader, reader.uint32(), options, message.header);
                    break;
                case /* string name */ 3:
                    message.name = reader.string();
                    break;
                case /* repeated CardSection sections */ 4:
                    message.sections.push(CardSection.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated CardAction actions = 1; */
        for (let i = 0; i < message.actions.length; i++)
            CardAction.internalBinaryWrite(message.actions[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* CardHeader header = 2; */
        if (message.header)
            CardHeader.internalBinaryWrite(message.header, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string name = 3; */
        if (message.name !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.name);
        /* repeated CardSection sections = 4; */
        for (let i = 0; i < message.sections.length; i++)
            CardSection.internalBinaryWrite(message.sections[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ChatCard
 */
export const ChatCard = new ChatCard$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChatSpace$Type extends MessageType {
    constructor() {
        super("ChatSpace", [
            { no: 1, name: "display_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "single_user_bot_dm", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "threaded", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { displayName: "", name: "", singleUserBotDm: false, threaded: false, type: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string display_name */ 1:
                    message.displayName = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* bool single_user_bot_dm */ 3:
                    message.singleUserBotDm = reader.bool();
                    break;
                case /* bool threaded */ 4:
                    message.threaded = reader.bool();
                    break;
                case /* string type */ 5:
                    message.type = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string display_name = 1; */
        if (message.displayName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.displayName);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* bool single_user_bot_dm = 3; */
        if (message.singleUserBotDm !== false)
            writer.tag(3, WireType.Varint).bool(message.singleUserBotDm);
        /* bool threaded = 4; */
        if (message.threaded !== false)
            writer.tag(4, WireType.Varint).bool(message.threaded);
        /* string type = 5; */
        if (message.type !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.type);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ChatSpace
 */
export const ChatSpace = new ChatSpace$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChatSpaces$Type extends MessageType {
    constructor() {
        super("ChatSpaces", [
            { no: 1, name: "data", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ChatSpace }
        ]);
    }
    create(value) {
        const message = { data: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated ChatSpace data */ 1:
                    message.data.push(ChatSpace.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated ChatSpace data = 1; */
        for (let i = 0; i < message.data.length; i++)
            ChatSpace.internalBinaryWrite(message.data[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ChatSpaces
 */
export const ChatSpaces = new ChatSpaces$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChatMember$Type extends MessageType {
    constructor() {
        super("ChatMember", [
            { no: 1, name: "display_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "domain_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "is_anonymous", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { displayName: "", domainId: "", isAnonymous: false, name: "", type: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string display_name */ 1:
                    message.displayName = reader.string();
                    break;
                case /* string domain_id */ 2:
                    message.domainId = reader.string();
                    break;
                case /* bool is_anonymous */ 3:
                    message.isAnonymous = reader.bool();
                    break;
                case /* string name */ 4:
                    message.name = reader.string();
                    break;
                case /* string type */ 5:
                    message.type = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string display_name = 1; */
        if (message.displayName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.displayName);
        /* string domain_id = 2; */
        if (message.domainId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.domainId);
        /* bool is_anonymous = 3; */
        if (message.isAnonymous !== false)
            writer.tag(3, WireType.Varint).bool(message.isAnonymous);
        /* string name = 4; */
        if (message.name !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.name);
        /* string type = 5; */
        if (message.type !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.type);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ChatMember
 */
export const ChatMember = new ChatMember$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChatMembers$Type extends MessageType {
    constructor() {
        super("ChatMembers", [
            { no: 1, name: "data", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ChatMember }
        ]);
    }
    create(value) {
        const message = { data: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated ChatMember data */ 1:
                    message.data.push(ChatMember.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated ChatMember data = 1; */
        for (let i = 0; i < message.data.length; i++)
            ChatMember.internalBinaryWrite(message.data[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ChatMembers
 */
export const ChatMembers = new ChatMembers$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChatMessage$Type extends MessageType {
    constructor() {
        super("ChatMessage", [
            { no: 1, name: "text", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "cards", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ChatCard },
            { no: 3, name: "user", kind: "message", T: () => User },
            { no: 4, name: "google_user_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "date_created", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { text: "", cards: [], googleUserId: "", dateCreated: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string text */ 1:
                    message.text = reader.string();
                    break;
                case /* repeated ChatCard cards */ 2:
                    message.cards.push(ChatCard.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* User user */ 3:
                    message.user = User.internalBinaryRead(reader, reader.uint32(), options, message.user);
                    break;
                case /* string google_user_id */ 4:
                    message.googleUserId = reader.string();
                    break;
                case /* string date_created */ 5:
                    message.dateCreated = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string text = 1; */
        if (message.text !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.text);
        /* repeated ChatCard cards = 2; */
        for (let i = 0; i < message.cards.length; i++)
            ChatCard.internalBinaryWrite(message.cards[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* User user = 3; */
        if (message.user)
            User.internalBinaryWrite(message.user, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string google_user_id = 4; */
        if (message.googleUserId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.googleUserId);
        /* string date_created = 5; */
        if (message.dateCreated !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.dateCreated);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ChatMessage
 */
export const ChatMessage = new ChatMessage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateMessageRequest$Type extends MessageType {
    constructor() {
        super("CreateMessageRequest", [
            { no: 1, name: "space", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "skipSpaceLookup", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "message", kind: "message", T: () => ChatMessage }
        ]);
    }
    create(value) {
        const message = { space: "", skipSpaceLookup: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string space */ 1:
                    message.space = reader.string();
                    break;
                case /* bool skipSpaceLookup */ 2:
                    message.skipSpaceLookup = reader.bool();
                    break;
                case /* ChatMessage message */ 3:
                    message.message = ChatMessage.internalBinaryRead(reader, reader.uint32(), options, message.message);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string space = 1; */
        if (message.space !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.space);
        /* bool skipSpaceLookup = 2; */
        if (message.skipSpaceLookup !== false)
            writer.tag(2, WireType.Varint).bool(message.skipSpaceLookup);
        /* ChatMessage message = 3; */
        if (message.message)
            ChatMessage.internalBinaryWrite(message.message, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CreateMessageRequest
 */
export const CreateMessageRequest = new CreateMessageRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OAUTHRequest$Type extends MessageType {
    constructor() {
        super("OAUTHRequest", [
            { no: 1, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "redirectURL", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "auth_code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "is_localhost", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value) {
        const message = { userId: 0, redirectURL: "", authCode: "", isLocalhost: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 user_id */ 1:
                    message.userId = reader.int32();
                    break;
                case /* string redirectURL */ 2:
                    message.redirectURL = reader.string();
                    break;
                case /* string auth_code */ 3:
                    message.authCode = reader.string();
                    break;
                case /* bool is_localhost */ 4:
                    message.isLocalhost = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 user_id = 1; */
        if (message.userId !== 0)
            writer.tag(1, WireType.Varint).int32(message.userId);
        /* string redirectURL = 2; */
        if (message.redirectURL !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.redirectURL);
        /* string auth_code = 3; */
        if (message.authCode !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.authCode);
        /* bool is_localhost = 4; */
        if (message.isLocalhost !== false)
            writer.tag(4, WireType.Varint).bool(message.isLocalhost);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message OAUTHRequest
 */
export const OAUTHRequest = new OAUTHRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OAUTHResponse$Type extends MessageType {
    constructor() {
        super("OAUTHResponse", [
            { no: 1, name: "access_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "refresh_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { accessToken: "", refreshToken: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string access_token */ 1:
                    message.accessToken = reader.string();
                    break;
                case /* string refresh_token */ 2:
                    message.refreshToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string access_token = 1; */
        if (message.accessToken !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.accessToken);
        /* string refresh_token = 2; */
        if (message.refreshToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.refreshToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message OAUTHResponse
 */
export const OAUTHResponse = new OAUTHResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChatMessageRequest$Type extends MessageType {
    constructor() {
        super("ChatMessageRequest", [
            { no: 1, name: "space_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "start_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "end_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { spaceId: "", startDate: "", endDate: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string space_id */ 1:
                    message.spaceId = reader.string();
                    break;
                case /* string start_date */ 2:
                    message.startDate = reader.string();
                    break;
                case /* string end_date */ 3:
                    message.endDate = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string space_id = 1; */
        if (message.spaceId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.spaceId);
        /* string start_date = 2; */
        if (message.startDate !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.startDate);
        /* string end_date = 3; */
        if (message.endDate !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.endDate);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ChatMessageRequest
 */
export const ChatMessageRequest = new ChatMessageRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChatMessageList$Type extends MessageType {
    constructor() {
        super("ChatMessageList", [
            { no: 1, name: "messages", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ChatMessage },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { messages: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated ChatMessage messages */ 1:
                    message.messages.push(ChatMessage.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated ChatMessage messages = 1; */
        for (let i = 0; i < message.messages.length; i++)
            ChatMessage.internalBinaryWrite(message.messages[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ChatMessageList
 */
export const ChatMessageList = new ChatMessageList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateGoogleSpaceRequest$Type extends MessageType {
    constructor() {
        super("CreateGoogleSpaceRequest", [
            { no: 1, name: "space_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "members_email", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { spaceName: "", membersEmail: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string space_name */ 1:
                    message.spaceName = reader.string();
                    break;
                case /* repeated string members_email */ 2:
                    message.membersEmail.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string space_name = 1; */
        if (message.spaceName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.spaceName);
        /* repeated string members_email = 2; */
        for (let i = 0; i < message.membersEmail.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.membersEmail[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CreateGoogleSpaceRequest
 */
export const CreateGoogleSpaceRequest = new CreateGoogleSpaceRequest$Type();
/**
 * @generated ServiceType for protobuf service GoogleService
 */
export const GoogleService = new ServiceType("GoogleService", [
    { name: "CreateGoogleUser", options: {}, I: GoogleUser, O: GoogleUser },
    { name: "GetGoogleUser", options: {}, I: GoogleUser, O: GoogleUser },
    { name: "BatchGetGoogleUser", options: {}, I: GoogleUser, O: GoogleUserList },
    { name: "UpdateGoogleUser", options: {}, I: GoogleUser, O: GoogleUser },
    { name: "DeleteGoogleUser", options: {}, I: GoogleUser, O: Empty },
    { name: "UploadCSVToGoogleSheets", options: {}, I: UploadCsvRequest, O: UploadCsvResponse },
    { name: "CreateChatMessage", options: {}, I: CreateMessageRequest, O: Bool },
    { name: "GetOAUTHURL", options: {}, I: OAUTHRequest, O: String$ },
    { name: "GetOAUTHToken", options: {}, I: OAUTHRequest, O: String$ },
    { name: "AuthenticateToken", options: {}, I: Int32, O: Bool },
    { name: "RefreshCredentialsWithUserId", options: {}, I: Int32, O: String$ },
    { name: "RefreshToken", options: {}, I: Empty, O: String$ },
    { name: "HandleDirectMessage", options: {}, I: DirectMessageRequest, O: Empty },
    { name: "BatchGetChatMessage", options: {}, I: ChatMessageRequest, O: ChatMessageList },
    { name: "CreateGoogleSpace", options: {}, I: CreateGoogleSpaceRequest, O: Empty }
]);
