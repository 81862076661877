import { Reimbursement } from '@kalos/kalos-rpc';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
  Skeleton,
  toast,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  useConfirm,
} from '@kalos/ui';
import {
  CheckCircledIcon,
  CrossCircledIcon,
  Pencil1Icon,
  PlusIcon,
  ReloadIcon,
  TrashIcon,
} from '@radix-ui/react-icons';
import { Images } from 'lucide-react';
import { useCallback, useMemo, useState } from 'react';

import {
  useReimbursementDeleteMutation,
  useReimbursementDocumentBatchGetQuery,
  useReimbursementUpdateMutation,
} from '../../../hooks/react-query/useReimbursementQuery';
import { getMimeType, timestamp } from '../../../tools/helpers';
import { getFieldMaskFromDirtyField } from '../../ComponentsLibrary/EmployeeDepartments/EditEmployeeFunction';
import { ImageGallery } from '../../ComponentsLibrary/OmniGallery/components/ImageGallery';
import { ReimbursementEditForm } from './ReimbursementEditForm';

const EditAction = ({ reimbursement: r }: { reimbursement: Reimbursement }) => {
  const updateReimbursementMutation = useReimbursementUpdateMutation();
  const defaultEditFormValues = useMemo<
    React.ComponentProps<typeof ReimbursementEditForm>['defaultValues']
  >(
    () => ({
      amount: r.amount,
      description: r.description,
      jobNumber: r.jobNumber,
      userId: r.userId,
    }),
    [r.amount, r.description, r.jobNumber, r.userId],
  );
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  const handleUpdateReimbursement = useCallback<
    React.ComponentProps<typeof ReimbursementEditForm>['onSave']
  >(
    async ({ data, dirtyFields }) => {
      try {
        await updateReimbursementMutation.mutateAsync(
          Reimbursement.create({
            id: r.id,
            ...data,
            fieldMask: getFieldMaskFromDirtyField(dirtyFields),
          }),
        );
        toast({
          variant: 'success',
          title: 'Reimbursement Updated',
        });
        setIsEditDialogOpen(false);
      } catch (e) {
        toast({
          variant: 'destructive',
          title: 'Error Updating Reimbursement',
        });
      }
    },
    [r, updateReimbursementMutation],
  );

  return (
    <Dialog open={isEditDialogOpen} onOpenChange={setIsEditDialogOpen}>
      <Tooltip key="editAll">
        <TooltipContent>Edit Reimbursement</TooltipContent>
        <DialogTrigger asChild>
          <TooltipTrigger asChild>
            <Button
              isLoading={updateReimbursementMutation.isPending}
              disabled={updateReimbursementMutation.isPending}
              size="icon"
              variant="outline"
            >
              <Pencil1Icon />
            </Button>
          </TooltipTrigger>
        </DialogTrigger>
      </Tooltip>

      <DialogContent className="@container">
        <DialogTitle>Edit Reimbursement</DialogTitle>
        <ReimbursementEditForm
          onSave={handleUpdateReimbursement}
          disableTechnicianPicker
          isLoading={updateReimbursementMutation.isPending}
          disabled={updateReimbursementMutation.isPending}
          defaultValues={defaultEditFormValues}
        />
      </DialogContent>
    </Dialog>
  );
};

const ProcessAction = ({ reimbursement: r }: { reimbursement: Reimbursement }) => {
  const updateReimbursementMutation = useReimbursementUpdateMutation();
  const confirm = useConfirm();
  const handlePayroll = async () => {
    if (!(await confirm('Are you sure you want to process this reimbursement?'))) return;
    try {
      await updateReimbursementMutation.mutateAsync(
        Reimbursement.create({
          id: r.id,
          payrollProcessed: true,
          statusId: 5,
          processedDate: timestamp(),
          fieldMask: ['PayrollProcessed', 'StatusId', 'ProcessedDate'],
        }),
      );
      toast({
        variant: 'success',
        title: 'Reimbursement Processed',
      });
    } catch (e) {
      toast({
        variant: 'destructive',
        title: 'Error Processing Reimbursement',
      });
    }
  };

  return (
    <Tooltip>
      <TooltipContent>Process Reimbursement</TooltipContent>
      <TooltipTrigger asChild>
        <Button
          size="icon"
          variant="outline"
          onClick={handlePayroll}
          isLoading={updateReimbursementMutation.isPending}
          disabled={r.payrollProcessed == true || updateReimbursementMutation.isPending}
        >
          <ReloadIcon />
        </Button>
      </TooltipTrigger>
    </Tooltip>
  );
};

const ApproveAction = ({ reimbursement: r }: { reimbursement: Reimbursement }) => {
  const updateReimbursementMutation = useReimbursementUpdateMutation();
  const confirm = useConfirm();

  const handleApprove = async () => {
    if (!(await confirm('Are you sure you want to approve this reimbursement?'))) return;
    try {
      await updateReimbursementMutation.mutateAsync(
        Reimbursement.create({
          id: r.id,
          statusId: 3,
          reviewerId: 1,
          reviewDate: timestamp(),
          fieldMask: ['StatusId', 'ReviewerId', 'ReviewDate'],
        }),
      );
      toast({
        variant: 'success',
        title: 'Reimbursement Approved',
      });
    } catch (e) {
      toast({
        variant: 'destructive',
        title: 'Error Approving Reimbursement',
      });
    }
  };

  return (
    <Tooltip>
      <TooltipContent>Approve Reimbursement</TooltipContent>
      <TooltipTrigger asChild>
        <Button
          size="icon"
          variant="outline"
          onClick={handleApprove}
          isLoading={updateReimbursementMutation.isPending}
          disabled={updateReimbursementMutation.isPending}
        >
          <CheckCircledIcon />
        </Button>
      </TooltipTrigger>
    </Tooltip>
  );
};

const DeleteAction = ({ reimbursement: r }: { reimbursement: Reimbursement }) => {
  const deleteReimbursementMutation = useReimbursementDeleteMutation();
  const confirm = useConfirm();

  const handleDelete = async () => {
    if (!(await confirm('Are you sure you want to delete this reimbursement?'))) return;
    try {
      await deleteReimbursementMutation.mutateAsync(
        Reimbursement.create({
          id: r.id,
        }),
      );
      toast({
        variant: 'success',
        title: 'Reimbursement Deleted',
      });
    } catch (e) {
      toast({
        variant: 'destructive',
        title: 'Error Deleting Reimbursement',
      });
    }
  };

  return (
    <Tooltip>
      <TooltipContent>Delete Reimbursement</TooltipContent>
      <TooltipTrigger asChild>
        <Button
          size="icon"
          variant="outline"
          onClick={handleDelete}
          isLoading={deleteReimbursementMutation.isPending}
          disabled={deleteReimbursementMutation.isPending}
        >
          <TrashIcon />
        </Button>
      </TooltipTrigger>
    </Tooltip>
  );
};

const RejectAction = ({ reimbursement: r }: { reimbursement: Reimbursement }) => {
  const updateReimbursementMutation = useReimbursementUpdateMutation();
  const confirm = useConfirm();

  const handleReject = async () => {
    if (!(await confirm('Are you sure you want to reject this reimbursement?'))) return;
    try {
      await updateReimbursementMutation.mutateAsync(
        Reimbursement.create({
          id: r.id,
          statusId: 4,
          fieldMask: ['StatusId'],
        }),
      );
      toast({
        variant: 'success',
        title: 'Reimbursement Rejected',
      });
    } catch (e) {
      toast({
        variant: 'destructive',
        title: 'Error Rejecting Reimbursement',
      });
    }
  };

  return (
    <Tooltip>
      <TooltipContent>Reject Reimbursement</TooltipContent>
      <TooltipTrigger asChild>
        <Button
          size="icon"
          variant="outline"
          onClick={handleReject}
          isLoading={updateReimbursementMutation.isPending}
          disabled={updateReimbursementMutation.isPending}
        >
          <CrossCircledIcon />
        </Button>
      </TooltipTrigger>
    </Tooltip>
  );
};

const SubmitAction = ({ reimbursement: r }: { reimbursement: Reimbursement }) => {
  const updateReimbursementMutation = useReimbursementUpdateMutation();
  const confirm = useConfirm();

  const handleSubmit = async () => {
    if (!(await confirm('Are you sure you want to submit this reimbursement?'))) return;
    try {
      await updateReimbursementMutation.mutateAsync(
        Reimbursement.create({
          id: r.id,
          statusId: 2,
          fieldMask: ['StatusId'],
        }),
      );
      toast({
        variant: 'success',
        title: 'Reimbursement Submitted',
      });
    } catch (e) {
      toast({
        variant: 'destructive',
        title: 'Error Submitting Reimbursement',
      });
    }
  };

  return (
    <Tooltip>
      <TooltipContent>Submit Reimbursement</TooltipContent>
      <TooltipTrigger asChild>
        <Button
          size="icon"
          variant="outline"
          onClick={handleSubmit}
          isLoading={updateReimbursementMutation.isPending}
          disabled={updateReimbursementMutation.isPending}
        >
          <PlusIcon />
        </Button>
      </TooltipTrigger>
    </Tooltip>
  );
};

const ReimbursementGallery = ({
  reimbursementId,
  trigger,
}: {
  reimbursementId: Reimbursement['id'];
  trigger: React.ReactNode;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const reimbursementDocumentsQuery = useReimbursementDocumentBatchGetQuery({
    filter: {
      reimbursementId,
    },
    enabled: isOpen,
    select(data) {
      return data.results.map((doc) => ({
        name: doc.description,
        bucket: 'kalos-reimbursements',
        mimeType: getMimeType(doc.description) || '.png',
      }));
    },
  });

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className="max-h-screen max-w-screen-2xl overflow-auto px-0 pb-0 pt-0 md:max-h-[90vh]">
        {reimbursementDocumentsQuery.isPending && <Skeleton className="h-[80vh] w-full" />}
        {reimbursementDocumentsQuery.isSuccess && (
          <div className="h-[80vh]">
            <ImageGallery title="Reimbursement Uploads" files={reimbursementDocumentsQuery.data} />{' '}
          </div>
        )}
      </DialogContent>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
    </Dialog>
  );
};

export const ReimbursementTableActions = ({
  reimbursement: r,
  isManager,
  isOwner,
  isPayroll,
}: {
  reimbursement: Reimbursement;
  isOwner: boolean;
  isPayroll: boolean;
  isManager: boolean;
}) => {
  const status = r.statusId;

  const editAction = <EditAction reimbursement={r} />;

  const galleryAction = (
    <ReimbursementGallery
      reimbursementId={r.id}
      trigger={
        <Button size="icon" variant="outline">
          <Images size={15} />
        </Button>
      }
    />
  );

  const processAction = <ProcessAction reimbursement={r} />;

  const approveAction = <ApproveAction reimbursement={r} />;

  const deleteAction = <DeleteAction reimbursement={r} />;

  const rejectAction = <RejectAction reimbursement={r} />;

  const submitAction = <SubmitAction reimbursement={r} />;

  let actions: React.ReactNode = null;
  // pending
  if (status == 1) {
    actions = (
      <>
        {galleryAction}
        {isOwner ||
          (isManager && (
            <>
              {editAction}
              {deleteAction}
              {submitAction}
            </>
          ))}
      </>
    );
  }

  // submitted
  if (status == 2) {
    actions = (
      <>
        {galleryAction}
        {(isOwner || isManager) && (
          <>
            {editAction}
            {deleteAction}
          </>
        )}
        {isManager && !isOwner && (
          <>
            {approveAction}
            {rejectAction}
          </>
        )}
      </>
    );
  }

  // approved
  if (status == 3) {
    actions = (
      <>
        {galleryAction}
        {isManager && rejectAction}
        {isPayroll && processAction}
      </>
    );
  }

  // rejected
  if (status == 4) {
    actions = (
      <>
        {galleryAction}
        {(isOwner || isManager) && (
          <>
            {editAction}
            {deleteAction}
            {submitAction}
          </>
        )}
      </>
    );
  }

  return <TooltipProvider>{actions}</TooltipProvider>;
};
