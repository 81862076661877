import { Button, Popover, PopoverContent, PopoverTrigger, Skeleton } from '@kalos/ui';

import { useEventQuery } from '../hooks/react-query/useEventsQuery';

export const JobPreview = ({ jobId }: { jobId: number }) => {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button size="sm" onClick={(e) => e.stopPropagation()} className="min-w-fit">
          {jobId}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-52">
        <JobInfo jobId={jobId} />
      </PopoverContent>
    </Popover>
  );
};

export const JobInfo = ({ jobId }: { jobId: number }) => {
  const eventDataQuery = useEventQuery({
    filter: {
      id: jobId,
    },
    select(data) {
      const result = {
        description: '',
        customer: '',
        property: '',
      };
      if (data) {
        result.description = 'Job Description: ' + data.description;
        result.customer = data.customer
          ? 'Customer: ' + data.customer.firstname + ' ' + data.customer.lastname
          : 'No Customer';
        result.property = data.property
          ? 'Property: ' + data.property.address + ' ' + data.property.city
          : 'No Property';
      }
      return result;
    },
  });

  if (eventDataQuery.isLoading) {
    return (
      <div className="flex flex-col gap-1">
        <Skeleton className="bg-foreground/30 h-4 w-3/4" />
        <Skeleton className="bg-foreground/30 h-4 w-2/3" />
        <Skeleton className="bg-foreground/30 h-4 w-1/2" />
      </div>
    );
  }
  if (eventDataQuery.isError) return 'Error loading job info';
  if (eventDataQuery.isSuccess) {
    return (
      <div className="flex flex-col gap-1 text-sm">
        {eventDataQuery.data.customer && <p>{eventDataQuery.data.customer}</p>}
        {eventDataQuery.data.property && <p>{eventDataQuery.data.property}</p>}
        {eventDataQuery.data.description && <p>{eventDataQuery.data.description}</p>}
      </div>
    );
  }
};
