// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "geolocation_log.proto" (syntax proto3)
// tslint:disable
import { GeolocationLogService } from "./geolocation_log";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service GeolocationLogService
 */
export class GeolocationLogServiceClient {
    constructor(_transport) {
        this._transport = _transport;
        this.typeName = GeolocationLogService.typeName;
        this.methods = GeolocationLogService.methods;
        this.options = GeolocationLogService.options;
    }
    /**
     * @generated from protobuf rpc: Update(GeolocationLog) returns (GeolocationRPCLog);
     */
    update(input, options) {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateHasTimecard(Int32List) returns (Bool);
     */
    updateHasTimecard(input, options) {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Get(GeolocationRPCLog) returns (GeolocationRPCLog);
     */
    get(input, options) {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGet(GeolocationRPCLog) returns (GeolocationRPCLogList);
     */
    batchGet(input, options) {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Delete(GeolocationLog) returns (Empty);
     */
    delete(input, options) {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
}
