import { BaseClient } from '../BaseClient';
import {
  MaintenanceQuestion,
  MaintenanceQuestionList,
} from '../compiled-protos/event';
import { EventServiceClient as MaintenanceQuestionServiceClient } from '../compiled-protos/event.client';

class MaintenanceQuestionClient extends BaseClient {
  self: MaintenanceQuestionServiceClient;

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new MaintenanceQuestionServiceClient(this.transport);
  }

  public async Create(req: MaintenanceQuestion) {
    let res = MaintenanceQuestion.create();
    try {
      res = await this.self.createMaintenanceQuestion(req, this.getMetaData())
        .response;
    } catch (err) {
      console.error(err);
    }
    return res;
  }

  public async Get(req: MaintenanceQuestion) {
    let res = MaintenanceQuestion.create();
    try {
      res = await this.self.getMaintenanceQuestion(req, this.getMetaData())
        .response;
    } catch (err) {
      console.error(err);
    }
    return res;
  }

  public async Update(req: MaintenanceQuestion) {
    let res = MaintenanceQuestion.create();
    try {
      res = await this.self.updateMaintenanceQuestion(req, this.getMetaData())
        .response;
    } catch (err) {
      console.error(err);
    }
    return res;
  }

  public async Delete(req: MaintenanceQuestion) {
    let res = MaintenanceQuestion.create();
    try {
      res = await this.self.deleteMaintenanceQuestion(req, this.getMetaData())
        .response;
    } catch (err) {
      console.error(err);
    }
    return res;
  }

  public async BatchGet(req: MaintenanceQuestion) {
    let res = MaintenanceQuestionList.create();
    try {
      res = await this.self.batchGetMaintenanceQuestion(req, this.getMetaData())
        .response;
    } catch (err) {
      console.error(err);
    }
    return res;
  }
}

export {
  MaintenanceQuestion,
  MaintenanceQuestionList,
  MaintenanceQuestionClient,
};
