import { z } from 'zod';

import { useStrictParams } from '../../../../../hooks/useStrictParams';
import { PermitDetails } from './PermitDetails';

const permitIdParamsSchema = z.object({
  permitId: z.coerce.number().positive(),
});

const PermitInspectionsPage = () => {
  const { permitId } = useStrictParams(permitIdParamsSchema);

  return (
    <div className="flex h-full flex-col overflow-hidden pb-10 pt-4">
      <PermitDetails permitId={permitId} />
    </div>
  );
};

export default PermitInspectionsPage;
