export const SPIFF_TOOL_STATUSES = [
  {
    label: (
      <div className="flex items-center gap-2">
        <div className="size-4 rounded-full bg-green-500" />
        <span>Approved</span>
      </div>
    ),
    value: '1',
  },
  {
    label: (
      <div className="flex items-center gap-2">
        <div className="size-4 rounded-full bg-red-500" />
        <span>Not Approved</span>
      </div>
    ),
    value: '2',
  },
  {
    label: (
      <div className="flex items-center gap-2">
        <div className="size-4 rounded-full bg-slate-500" />
        <span>Revoked</span>
      </div>
    ),
    value: '3',
  },
];
