// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "user.proto" (syntax proto3)
// tslint:disable
import { UserService } from "./user";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service UserService
 */
export class UserServiceClient {
    constructor(_transport) {
        this._transport = _transport;
        this.typeName = UserService.typeName;
        this.methods = UserService.methods;
        this.options = UserService.options;
    }
    /**
     * @generated from protobuf rpc: Create(User) returns (User);
     */
    create(input, options) {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Get(User) returns (User);
     */
    get(input, options) {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGet(User) returns (UserList);
     */
    batchGet(input, options) {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Update(User) returns (User);
     */
    update(input, options) {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Delete(User) returns (User);
     */
    delete(input, options) {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetCardList(CardData) returns (CardDataList);
     */
    getCardList(input, options) {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetUserManager(User) returns (User);
     */
    getUserManager(input, options) {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreatePermissionGroup(PermissionGroup) returns (Int32);
     */
    createPermissionGroup(input, options) {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetPermissionGroupUser(PermissionGroupUser) returns (PermissionGroupUser);
     */
    getPermissionGroupUser(input, options) {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetPermissionGroupUser(PermissionGroupUser) returns (PermissionGroupUserList);
     */
    batchGetPermissionGroupUser(input, options) {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeletePermissionGroup(Int32) returns (Empty);
     */
    deletePermissionGroup(input, options) {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdatePermissionGroup(PermissionGroup) returns (Empty);
     */
    updatePermissionGroup(input, options) {
        const method = this.methods[11], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: AddUserToPermissionGroup(PermissionGroupUser) returns (Bool);
     */
    addUserToPermissionGroup(input, options) {
        const method = this.methods[12], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: RemoveUserFromPermissionGroup(PermissionGroupUser) returns (Empty);
     */
    removeUserFromPermissionGroup(input, options) {
        const method = this.methods[13], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UserInPermissionGroup(PermissionGroupUser) returns (Bool);
     */
    userInPermissionGroup(input, options) {
        const method = this.methods[14], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetUserPermissions(PermissionGroupUser) returns (PermissionGroupUserList);
     */
    batchGetUserPermissions(input, options) {
        const method = this.methods[15], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetPermissions(PermissionGroup) returns (PermissionGroupList);
     */
    batchGetPermissions(input, options) {
        const method = this.methods[16], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetUserIdsInPermissionGroup(Int32) returns (Int32List);
     */
    getUserIdsInPermissionGroup(input, options) {
        const method = this.methods[17], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: PushNotificationDebug(Empty) returns (Empty);
     */
    pushNotificationDebug(input, options) {
        const method = this.methods[18], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetUserAttributes(UserAttributes) returns (UserAttributes);
     */
    getUserAttributes(input, options) {
        const method = this.methods[19], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateUserAttributes(UserAttributes) returns (UserAttributes);
     */
    updateUserAttributes(input, options) {
        const method = this.methods[20], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateUserAttributes(UserAttributes) returns (UserAttributes);
     */
    createUserAttributes(input, options) {
        const method = this.methods[21], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateTeam(Team) returns (Team);
     */
    createTeam(input, options) {
        const method = this.methods[22], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetTeam(Team) returns (Team);
     */
    getTeam(input, options) {
        const method = this.methods[23], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateTeam(Team) returns (Team);
     */
    updateTeam(input, options) {
        const method = this.methods[24], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetTeams(Team) returns (TeamList);
     */
    batchGetTeams(input, options) {
        const method = this.methods[25], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteTeam(Team) returns (Team);
     */
    deleteTeam(input, options) {
        const method = this.methods[26], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateTeamMember(TeamMember) returns (TeamMember);
     */
    createTeamMember(input, options) {
        const method = this.methods[27], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetTeamMember(TeamMember) returns (TeamMember);
     */
    getTeamMember(input, options) {
        const method = this.methods[28], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateTeamMember(TeamMember) returns (TeamMember);
     */
    updateTeamMember(input, options) {
        const method = this.methods[29], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetTeamMembers(TeamMember) returns (TeamMemberList);
     */
    batchGetTeamMembers(input, options) {
        const method = this.methods[30], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteTeamMember(TeamMember) returns (TeamMember);
     */
    deleteTeamMember(input, options) {
        const method = this.methods[31], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateTeamLevel(TeamLevel) returns (TeamLevel);
     */
    createTeamLevel(input, options) {
        const method = this.methods[32], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetTeamLevel(TeamLevel) returns (TeamLevel);
     */
    getTeamLevel(input, options) {
        const method = this.methods[33], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateTeamLevel(TeamLevel) returns (TeamLevel);
     */
    updateTeamLevel(input, options) {
        const method = this.methods[34], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetTeamLevels(TeamLevel) returns (TeamLevelList);
     */
    batchGetTeamLevels(input, options) {
        const method = this.methods[35], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteTeamLevel(TeamLevel) returns (TeamLevel);
     */
    deleteTeamLevel(input, options) {
        const method = this.methods[36], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateEmployeeFunction(EmployeeFunction) returns (EmployeeFunction);
     */
    createEmployeeFunction(input, options) {
        const method = this.methods[37], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetEmployeeFunction(EmployeeFunction) returns (EmployeeFunction);
     */
    getEmployeeFunction(input, options) {
        const method = this.methods[38], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetEmployeeFunction(EmployeeFunction) returns (EmployeeFunctionList);
     */
    batchGetEmployeeFunction(input, options) {
        const method = this.methods[39], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateEmployeeFunction(EmployeeFunction) returns (EmployeeFunction);
     */
    updateEmployeeFunction(input, options) {
        const method = this.methods[40], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteEmployeeFunction(EmployeeFunction) returns (EmployeeFunction);
     */
    deleteEmployeeFunction(input, options) {
        const method = this.methods[41], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateUserGroupLink(UserGroupLink) returns (UserGroupLink);
     */
    createUserGroupLink(input, options) {
        const method = this.methods[42], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetUserGroupLink(UserGroupLink) returns (UserGroupLink);
     */
    getUserGroupLink(input, options) {
        const method = this.methods[43], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetUserGroupLink(UserGroupLink) returns (UserGroupLinkList);
     */
    batchGetUserGroupLink(input, options) {
        const method = this.methods[44], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateUserGroupLink(UserGroupLink) returns (UserGroupLink);
     */
    updateUserGroupLink(input, options) {
        const method = this.methods[45], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteUserGroupLink(UserGroupLink) returns (UserGroupLink);
     */
    deleteUserGroupLink(input, options) {
        const method = this.methods[46], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateGroup(Group) returns (Group);
     */
    createGroup(input, options) {
        const method = this.methods[47], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetGroup(Group) returns (Group);
     */
    getGroup(input, options) {
        const method = this.methods[48], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetGroup(Group) returns (GroupList);
     */
    batchGetGroup(input, options) {
        const method = this.methods[49], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateGroup(Group) returns (Group);
     */
    updateGroup(input, options) {
        const method = this.methods[50], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteGroup(Group) returns (Group);
     */
    deleteGroup(input, options) {
        const method = this.methods[51], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetOrganizationGroup(OrganizationGroup) returns (OrganizationGroupList);
     */
    batchGetOrganizationGroup(input, options) {
        const method = this.methods[52], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetOrganizationGroupUser(OrganizationGroupUser) returns (OrganizationGroupUserList);
     */
    batchGetOrganizationGroupUser(input, options) {
        const method = this.methods[53], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateNewHire(NewHire) returns (NewHire);
     */
    createNewHire(input, options) {
        const method = this.methods[54], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetNewHire(NewHire) returns (NewHire);
     */
    getNewHire(input, options) {
        const method = this.methods[55], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetNewHire(NewHire) returns (NewHireList);
     */
    batchGetNewHire(input, options) {
        const method = this.methods[56], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateNewHire(NewHire) returns (NewHire);
     */
    updateNewHire(input, options) {
        const method = this.methods[57], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteNewHire(NewHire) returns (NewHire);
     */
    deleteNewHire(input, options) {
        const method = this.methods[58], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetNewHireOption(NewHireOption) returns (NewHireOptionList);
     */
    batchGetNewHireOption(input, options) {
        const method = this.methods[59], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateEmployeeItem(EmployeeItem) returns (EmployeeItem);
     */
    createEmployeeItem(input, options) {
        const method = this.methods[60], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetEmployeeItem(EmployeeItem) returns (EmployeeItem);
     */
    getEmployeeItem(input, options) {
        const method = this.methods[61], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetEmployeeItem(EmployeeItem) returns (EmployeeItemList);
     */
    batchGetEmployeeItem(input, options) {
        const method = this.methods[62], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateEmployeeItem(EmployeeItem) returns (EmployeeItem);
     */
    updateEmployeeItem(input, options) {
        const method = this.methods[63], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteEmployeeItem(EmployeeItem) returns (EmployeeItem);
     */
    deleteEmployeeItem(input, options) {
        const method = this.methods[64], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: NewHireFinalize(NewHireFinalizeRequest) returns (NewHireFinalizeResponse);
     */
    newHireFinalize(input, options) {
        const method = this.methods[65], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateTerminationForm(TerminationForm) returns (TerminationForm);
     */
    createTerminationForm(input, options) {
        const method = this.methods[66], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetTerminationForm(TerminationForm) returns (TerminationForm);
     */
    getTerminationForm(input, options) {
        const method = this.methods[67], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetTerminationForm(TerminationForm) returns (TerminationFormList);
     */
    batchGetTerminationForm(input, options) {
        const method = this.methods[68], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateTerminationForm(TerminationForm) returns (TerminationForm);
     */
    updateTerminationForm(input, options) {
        const method = this.methods[69], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteTerminationForm(TerminationForm) returns (Empty);
     */
    deleteTerminationForm(input, options) {
        const method = this.methods[70], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
}
