import { SimpleSelect } from '@kalos/ui';
import { type ComponentProps } from 'react';

import { useInspectionStatusesQuery } from '../../../../hooks/react-query/usePermits';
import { InspectionStatusBadge } from '../InspectionStatusBadge';

export const InspectionStatusSelect = (
  props: Omit<ComponentProps<typeof SimpleSelect>, 'values'>,
) => {
  const permitStatusQuery = useInspectionStatusesQuery({
    select(data) {
      return data.results.map((status) => ({
        value: status.id.toString(),
        label: <InspectionStatusBadge inspectionStatusName={status.description} />,
      })) satisfies ComponentProps<typeof SimpleSelect>['values'];
    },
  });
  return (
    <SimpleSelect
      {...props}
      disabled={props.disabled || !permitStatusQuery.isSuccess}
      values={permitStatusQuery.data ?? []}
    />
  );
};
