import { BaseClient } from '../BaseClient';
import { StoredQuote, StoredQuoteList } from '../compiled-protos/quote';
import { QuoteServiceClient as StoredQuoteServiceClient } from '../compiled-protos/quote.client';

class StoredQuoteClient extends BaseClient {
  self: StoredQuoteServiceClient;

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new StoredQuoteServiceClient(this.transport);
  }

  public async Create(req: StoredQuote) {
    let res = StoredQuote.create();
    try {
      res = await this.self.createStoredQuote(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Get(req: StoredQuote) {
    let res = StoredQuote.create();
    try {
      res = await this.self.getStoredQuote(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Update(req: StoredQuote) {
    let res = StoredQuote.create();
    try {
      res = await this.self.updateStoredQuote(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Delete(req: StoredQuote) {
    let res = StoredQuote.create();
    try {
      res = await this.self.deleteStoredQuote(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async BatchGet(req: StoredQuote) {
    let res = StoredQuoteList.create();
    try {
      res = await this.self.batchGetStoredQuote(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  /**
   * Returns loaded StoredQuotes
   * @returns StoredQuote[]
   */
  public async loadStoredQuotes() {
    const results: StoredQuote[] = [];
    const req = StoredQuote.create();
    for (let page = 0; ; page += 1) {
      req.pageNumber = page;
      const res = await this.BatchGet(req);
      if (res) {
        const resultsList = res.results;
        const totalCount = res.totalCount;
        results.push(...resultsList);
        if (results.length === totalCount) break;
      }
    }
    return results.sort((a, b) => {
      const A = a.description.toLocaleLowerCase().trim();
      const B = b.description.toLocaleLowerCase().trim();
      if (A > B) return 1;
      if (A < B) return -1;
      return 0;
    });
  }
}

export { StoredQuote, StoredQuoteList, StoredQuoteClient };
