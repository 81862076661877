import './PrintParagraph.module.less';

import { clsx } from 'clsx';
import { type CSSProperties, type FC } from 'react';

type Tag = 'div' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5';

type Style = {
  tag?: Tag;
};

interface Props extends Style {
  align?: 'left' | 'center' | 'right';
  style?: CSSProperties;
  className?: string;
  children?: React.ReactNode;
}

export const PrintParagraph: FC<Props> = ({
  tag = 'div',
  align = 'left',
  children,
  className,
  style = {},
}) => {
  return (
    <div
      style={{
        textAlign: align,
        ...style,
      }}
      className={clsx('PrintParagraph', tag, className)}
    >
      {children}
    </div>
  );
};
