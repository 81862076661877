/*

  Description: create spiffs

  Design Specification / Document: None Specified

*/

import { Task } from '@kalos/kalos-rpc';
import { type FC, useCallback, useMemo } from 'react';

import { useEventQuery } from '../../../hooks/react-query/useEventsQuery';
import {
  useCreateTaskMutation,
  useSpiffTypeBatchGetQuery,
  useTaskUpdateMutation,
} from '../../../hooks/react-query/useTaskClientServiceQuery';
import { escapeText, timestamp } from '../../../tools/helpers';
import { Form, type Schema } from '../Form';
import { Modal } from '../Modal';
interface props {
  loggedUserId: number;
  onClose: () => void;
  serviceCallId: number;
}

export const SpiffApplyComponent: FC<props> = ({ loggedUserId, onClose, serviceCallId }) => {
  const spiffTypesQuery = useSpiffTypeBatchGetQuery({
    filter: { isActive: true },
    select(data) {
      return data.results.map((type) => ({
        label: escapeText(type.type),
        value: type.id,
      }));
    },
  });

  const eventQuery = useEventQuery({
    filter: {
      id: serviceCallId,
      notEquals: ['PropertyId'],
    },
  });

  const SCHEMA: Schema<Task> = useMemo(
    () => [
      [
        {
          name: 'externalId',
          label: 'Employee',
          type: 'technician',
          disabled: true,
        },
        {
          name: 'timeDue',
          label: 'Claim Date',
          disabled: true,
          type: 'date',
        },
        {
          name: 'spiffAmount',
          label: 'Amount',
          startAdornment: '$',
          type: 'number',
          required: true,
        },
        {
          name: 'datePerformed',
          label: 'Date Performed',
          type: 'date',
          required: true,
        },
      ],
      [
        {
          name: 'spiffTypeId',
          label: 'Spiff Type',
          options: spiffTypesQuery.data,
          required: true,
        },
        {
          name: 'briefDescription',
          label: 'Description',
          multiline: true,
          validationOnSave(value) {
            const updated = value.replaceAll(' ', '');
            return updated.length > 255
              ? 'This field cannot contain more than 255 characters.'
              : '';
          },
        },
      ],
    ],
    [spiffTypesQuery.data],
  );

  const initialTask = useMemo(() => {
    if (spiffTypesQuery.isSuccess) {
      const task = Task.create();
      task.timeDue = timestamp();
      task.datePerformed = timestamp();
      task.spiffTypeId = +spiffTypesQuery.data[0].value;
      task.externalId = loggedUserId;
      return task;
    }
  }, [loggedUserId, spiffTypesQuery.data, spiffTypesQuery.isSuccess]);

  const updateTaskMutation = useTaskUpdateMutation();
  const createTaskMutation = useCreateTaskMutation();

  const isSaving = createTaskMutation.isPending || updateTaskMutation.isPending;

  const handleSaveNewSpiff = useCallback(
    async (data: Task) => {
      if (eventQuery.isSuccess) {
        const now = timestamp();
        const req = Task.clone(data);
        req.spiffJobNumber = serviceCallId.toString();
        req.timeCreated = now;
        req.timeDue = now;
        req.priorityId = 2;
        req.externalCode = 'user';
        req.creatorUserId = loggedUserId;
        req.billableType = 'Spiff';
        req.statusId = 1;
        req.spiffAddress =
          eventQuery.data.property?.address === undefined
            ? eventQuery.data.customer?.address === undefined
              ? ''
              : eventQuery.data.customer.address
            : eventQuery.data.property.address;
        req.fieldMask = [];
        const res = await createTaskMutation.mutateAsync(req);
        const id = res.id;
        const updateReq = Task.create();
        updateReq.id = id;
        updateReq.fieldMask = ['AdminActionId'];
        updateReq.adminActionId = 0;
        await updateTaskMutation.mutateAsync(updateReq);
        onClose();
      }
    },
    [
      eventQuery.isSuccess,
      eventQuery.data?.property,
      eventQuery.data?.customer,
      serviceCallId,
      loggedUserId,
      createTaskMutation,
      updateTaskMutation,
      onClose,
    ],
  );
  return (
    <Modal open onClose={onClose}>
      {!!initialTask && spiffTypesQuery.isSuccess && eventQuery.isSuccess && (
        <Form<Task>
          title={'Add Spiff Request'}
          schema={SCHEMA}
          onClose={onClose}
          data={initialTask}
          onSave={handleSaveNewSpiff}
          disabled={isSaving}
        />
      )}
    </Modal>
  );
};
