import { BaseClient } from '../BaseClient';
import { Vendor, VendorList } from '../compiled-protos/vendor';
import { VendorServiceClient } from '../compiled-protos/vendor.client';

class VendorClient extends BaseClient {
  self: VendorServiceClient;
  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new VendorServiceClient(this.transport);
  }

  public async Create(req: Vendor) {
    let res = Vendor.create();
    try {
      res = await this.self.create(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return
    }
    return res;
  }

  public async Get(req: Vendor) {
    return await this.self.get(req, this.getMetaData()).response;
  }

  public async Update(req: Vendor) {
    let res = Vendor.create();
    try {
      res = await this.self.update(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return
    }
    return res;
  }

  public async Delete(req: Vendor) {
    let res = Vendor.create();
    try {
      res = await this.self.delete(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return
    }
    return res;
  }

  public async BatchGet(req: Vendor) {
    return await this.self.batchGet(req, this.getMetaData()).response;
  }
}

export { Vendor, VendorList, VendorClient };
