import { BaseClient } from '../BaseClient';
import {
  type AuditPageReq,
  type BatchCreateRequest,
  CSIDivision,
  CSIDivisionList,
  DocumentResponse,
  JSONTransactionOCR,
  PostRequestToOCRReq,
  type RecordPageReq,
  Transaction,
  type TransactionLineItem,
  TransactionList,
  TransactionOCR,
  TransactionOCRList,
} from '../compiled-protos/transaction';
import { TransactionServiceClient } from '../compiled-protos/transaction.client';

class TransactionClient extends BaseClient {
  self: TransactionServiceClient

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new TransactionServiceClient(this.transport)
  }

  public async Create(req: Transaction) {
    return await this.self.create(req, this.getMetaData()).response
  }

  public async BatchCreate(req: BatchCreateRequest) {
    return await this.self.batchCreate(req, this.getMetaData()).response
  }

  public async Get(req: Transaction) {
    return await this.self.get(req, this.getMetaData()).response
  }

  public async Update(req: Transaction) {
    return await this.self.update(req, this.getMetaData()).response
  }

  public async Delete(req: Transaction) {
    return await this.self.delete(req, this.getMetaData()).response
  }

  public async BatchGet(req: Transaction) {
    return await this.self.batchGet(req, this.getMetaDataWithoutCache()).response
  }

  public async RecordPage(req: RecordPageReq) {
    let res = TransactionList.create()
    try {
      res = await this.self.recordPage(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }

  public async AuditPage(req: AuditPageReq) {
    let res = TransactionList.create()
    try {
      res = await this.self.auditPage(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }

  public async Search(req: Transaction) {
    return await this.self.search(req, this.getMetaDataWithoutCache()).response
  }

  public async GetCSIDivision(req: CSIDivision) {
    let res = CSIDivision.create()
    try {
      res = await this.self.getCSIDivision(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }

  public async BatchGetCSIDivision(req: CSIDivision) {
    let res = CSIDivisionList.create()
    try {
      res = await this.self.batchGetCSIDivision(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }

  public async CreateCSIDivision(req: CSIDivision) {
    let res = CSIDivision.create()
    try {
      res = await this.self.createCSIDivision(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }

  public async UpdateCSIDivision(req: CSIDivision) {
    let res = CSIDivision.create()
    try {
      res = await this.self.updateCSIDivision(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }

  public async DeleteCSIDivision(req: CSIDivision) {
    let res = CSIDivision.create()
    try {
      res = await this.self.deleteCSIDivision(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }
  public async PostRequestToOCR(req: PostRequestToOCRReq) {
    let res = DocumentResponse.create()
    try {
      res = await this.self.postRequestToOCR(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }
  public async UpdateOCR(req: TransactionOCR) {
    let res = DocumentResponse.create()
    try {
      res = await this.self.updateTransactionOCR(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }
  public async BatchGetOCR(req: JSONTransactionOCR) {
    let res = TransactionOCRList.create()
    try {
      res = await this.self.batchGetTransactionOCR(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }
  public async CreateUpdateSimpleOCR(req: PostRequestToOCRReq) {
    let res = 'ok'
    try {
      await this.self.ocrCreateOrUpdateSimple(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
      res = 'nok'

      return res
    }
    return res
  }

  /**
   *
   * @param userID a valid employee userID
   * @returns An array containing all rejected receipts corresponding to userID
   */
  public async getRejectedTxnByUserID(userID: number): Promise<Transaction[]> {
    const result = await this.BatchGet(Transaction.create({ statusId: 4, ownerId: userID }));
    return result.results
  }
  /**
   *
   * @param userID A valid employee userID
   * @returns An array containing a boolean value and human readable string message
   * @example [false, "All transactions are resolved"]
   */
  public async timesheetCheck(userID: number): Promise<[boolean, string]> {
    const onTimeout = checkTimeout();
    try {
      if (!onTimeout) {
        const txn = Transaction.create({ ownerId: userID, statusId: 1, isActive: 1, notEquals: ["ArtificialId"] });
        const newTxn = (await this.Get(txn));
        if (newTxn.id !== 0) {
          return [
            true,
            'Receipts need review before timesheet can be completed',
          ];
        }
        txn.statusId = 4;
        const disputeTxn = (await this.Get(txn));
        if (disputeTxn.id !== 0) {
          return [
            true,
            'Receipts need review before timesheet can be completed',
          ];
        }

        return [false, 'All transactions are resolved'];
      } else {
        return [false, 'User is on 24 hour timeout'];
      }
    } catch (err) {
      return [false, 'No transactions need review'];
    }
  }

  public setTimeout(): void {
    const onTimeout = checkTimeout();
    if (!onTimeout) {
      localStorage.setItem('TIMESHEET_TIMEOUT', `${new Date().valueOf()}`);
    }
  }

  public loadTransactionsByEventId = async (
    eventId: number,
    withoutLimit = false,
    page = 0
  ) => {
    const req = Transaction.create({ jobId: eventId, isActive: 1, pageNumber: page, withoutLimit });
    const data = await this.BatchGet(req);
    return data;
  };

  /**
   * Line Items
   */

  public async BatchGetTransactionLineItems(req: TransactionLineItem) {
    return await this.self.batchGetLineItem(req, this.getMetaData()).response
  }
  public async CreateTransactionLineItem(req: TransactionLineItem) {
    return await this.self.createLineItem(req, this.getMetaData()).response
  }
  public async UpdateTransactionLineItem(req: TransactionLineItem) {
    return await this.self.updateLineItem(req, this.getMetaData()).response
  }
  public async DeleteTransactionLineItem(req: TransactionLineItem) {
    return await this.self.deleteLineItem(req, this.getMetaData()).response
  }
}

function txnToArray(txn: Transaction): TxnArray {
  const department = txn.department;
  let deptName = 'N/A';
  if (department) {
    deptName = department.description;
  }

  const costCenter = txn.costCenter;
  let accountName = 'N/A';
  if (costCenter) {
    accountName = costCenter.description;
  }

  return [
    txn.id,
    txn.jobId,
    deptName,
    txn.vendor,
    accountName,
    txn.description,
    txn.amount,
    txn.timestamp,
    txn.ownerName,
    txn.notes,
  ];
}

type TxnArray = [
  number,
  number,
  string,
  string,
  string,
  string,
  number,
  string,
  string,
  string
];

export type { TxnArray, }
export {
  Transaction,
  TransactionList,
  CSIDivision,
  CSIDivisionList,
  TransactionClient,
  PostRequestToOCRReq,
  AuditPageReq,
  JSONTransactionOCR,
  TransactionOCR,
  txnToArray,
};

function checkTimeout(): boolean {
  const lastTimeout = localStorage.getItem('TIMESHEET_TIMEOUT');
  if (lastTimeout) {
    const lastVal = parseInt(lastTimeout);
    const currVal = new Date().valueOf();
    if (currVal - lastVal > 86400000) {
      return false;
    } else {
      return true;
    }
  }
  return false;
}
