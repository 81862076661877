import { zodResolver } from '@hookform/resolvers/zod';
import { SUBJECT_TAGS_ACCOUNTS_PAYABLE, Transaction } from '@kalos/kalos-rpc';
import {
  Button,
  Combobox,
  DateInput,
  Form as FormContext,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  SimpleSelect,
  Textarea,
  toast,
} from '@kalos/ui';
import { format, isAfter, isValid, parseISO } from 'date-fns';
import { type FC, useCallback, useMemo } from 'react';
import { type SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { z } from 'zod';

import { JobSelector } from '../../../components/JobSelector';
import {
  type MostLikelyJobRequestFilter,
  useEventQueryInline,
} from '../../../hooks/react-query/useEventsQuery';
import { useTimesheetDepartmentListQuery } from '../../../hooks/react-query/useTimesheetDepartmentQuery';
import { useBatchUserQuery } from '../../../hooks/react-query/useUserQuery';
import { useVendorBatchQuery } from '../../../hooks/react-query/useVendorQuery';
import { isMoneyValue } from '../../../tools/helpers';
import { castToNumber } from '../../../tools/schemas';
import { type ZodObjectWithModelKeys } from '../../../tools/typeguargs';
import { getFieldMaskFromDirtyField } from '../EmployeeDepartments/EditEmployeeFunction';
import {
  techniciansUserFilter,
  TransactionAccountComboboxPicker,
} from '../Pickers/newPickers/QueryPickerV2';
interface Props {
  transactionInput: Transaction;
  onSave: (saved: Transaction) => void;
  onClose: () => void;
  title?: string;
  disabled?: boolean;
  isLoading?: boolean;
  changeCreator?: boolean;
}

type TransactionEditForm = Pick<
  Transaction,
  | 'jobId'
  | 'departmentId'
  | 'ownerId'
  | 'costCenterId'
  | 'amount'
  | 'assignedEmployeeId'
  | 'timestamp'
  | 'notes'
  | 'description'
  | 'vendorId'
  | 'orderNumber'
  | 'invoiceNumber'
  | 'vendorCategory'
>;

const MINIMAL_YEAR_DATE = new Date(2000, 0, 1);

const transactionEditScheme = z.object({
  timestamp: z
    .date()
    .default(() => new Date())
    .refine((date) => isValid(date), 'Not a valid date')
    .refine((date) => isAfter(date, MINIMAL_YEAR_DATE), 'Date should be after 01/01/2000'),
  orderNumber: z.string().optional(),
  invoiceNumber: z.string().optional().default(''),
  ownerId: castToNumber.optional().default(0),
  departmentId: castToNumber.default(0),
  jobId: z.number().optional().default(0),
  costCenterId: castToNumber.optional().default(0),
  // refine is to make sure that number has only 2 digits after comma
  amount: castToNumber
    .optional()
    .default(0)
    .refine(isMoneyValue, 'Amount should have only 2 digits after comma'),
  notes: z.string().optional().default(''),
  assignedEmployeeId: castToNumber.optional().default(0),
  description: z.string().optional().default(''),
  vendorId: castToNumber,
  vendorCategory: z.string().optional().default(''),
}) satisfies ZodObjectWithModelKeys<TransactionEditForm>;

type TransactionEditSchemaType = z.infer<typeof transactionEditScheme>;
const getDefaultTransactionEditFormValues: (txn: Transaction) => TransactionEditSchemaType = (
  txn,
) => {
  const parsedTime = parseISO(txn.timestamp);

  return {
    timestamp: isValid(parsedTime) ? parsedTime : new Date(),
    orderNumber: txn.orderNumber ?? '',
    invoiceNumber: txn.invoiceNumber ?? '',
    ownerId: txn.ownerId ?? 0,
    departmentId: txn.departmentId ?? 0,
    jobId: txn.jobId ?? 0,
    costCenterId: txn.costCenterId ?? 0,
    amount: txn.amount ?? 0,
    notes: txn.notes ?? '',
    assignedEmployeeId: txn.assignedEmployeeId ?? 0,
    description: txn.description ?? '',
    vendorId: txn.vendorId,
    vendorCategory: txn.vendorCategory ?? '',
  } satisfies TransactionEditSchemaType;
};

const useTransactionEditForm = ({
  defaultValues,
  disabled,
}: {
  defaultValues: TransactionEditSchemaType;
  disabled?: boolean;
}) => {
  return useForm<TransactionEditSchemaType>({
    resolver: zodResolver(transactionEditScheme),
    mode: 'onChange',
    defaultValues: defaultValues,
    disabled,
  });
};

const tagsTranslated: { label: string; value: string }[] = [];
for (let i = 0; i < SUBJECT_TAGS_ACCOUNTS_PAYABLE.length; i++) {
  const tempStruct = {
    label: SUBJECT_TAGS_ACCOUNTS_PAYABLE[i].label,
    value: SUBJECT_TAGS_ACCOUNTS_PAYABLE[i].label.replace(' ', ''),
  };
  tagsTranslated.push(tempStruct);
}

export const EditTransaction: FC<Props> = ({
  transactionInput,
  disabled,
  onSave,
  onClose,
  changeCreator,
  isLoading,
  title,
}) => {
  const form = useTransactionEditForm({
    defaultValues: useMemo(
      () => getDefaultTransactionEditFormValues(transactionInput),
      [transactionInput],
    ),
    disabled,
  });

  const jobId = useWatch({ control: form.control, name: 'jobId' });
  const jobSelectorHint = useMemo<MostLikelyJobRequestFilter>(
    () => ({
      targetDate: transactionInput.timestamp,
      userId: transactionInput.ownerId,
    }),
    [transactionInput.ownerId, transactionInput.timestamp],
  );

  const timesheetDepartmentQuery = useTimesheetDepartmentListQuery({
    filter: { isActive: 1, teamOnly: false, fieldMask: ['TeamOnly'] },
    select(data) {
      return (
        data?.results.map((department) => ({
          label: `${department.description}`,
          value: department.id.toString(),
        })) ?? []
      );
    },
  });

  const techniciansQuery = useBatchUserQuery({
    filters: techniciansUserFilter,
    select(data) {
      return data.results.map((technician) => ({
        label: `${technician.firstname} ${technician.lastname} (ID: ${technician.id})`,
        value: technician.id.toString(),
      }));
    },
  });

  const eventQuery = useEventQueryInline();

  const vendorsQuery = useVendorBatchQuery({
    filter: {
      isActive: 1,
      withoutLimit: true,
      orderBy: 'vendor_name',
    },
    select(data) {
      return (
        data?.results.map((vendor) => ({
          label: vendor.vendorName,
          value: vendor.id.toString(),
        })) ?? []
      );
    },
  });

  const handleSave: SubmitHandler<TransactionEditSchemaType> = async (data) => {
    const fieldMask = getFieldMaskFromDirtyField(form.formState.dirtyFields).map((fieldName) =>
      fieldName === 'vendorId' ? 'Vendor' : fieldName,
    );
    const vendorIdString = data.vendorId.toString();
    const vendorName = vendorsQuery.data?.find((vendor) => vendor.value === vendorIdString)?.label;
    const modifiedTransaction = Transaction.create({
      ...transactionInput,
      ...data,
      fieldMask,
      vendor: vendorName,
      timestamp: format(data.timestamp, 'yyyy-MM-dd HH:mm:ss'),
    });
    onSave(modifiedTransaction);
  };

  const handleSetDepartment = useCallback(
    async (id: number) => {
      try {
        const event = await eventQuery.fetchEvent({
          filter: {
            id,
          },
        });
        if (event?.departmentId) {
          form.setValue('departmentId', event.departmentId, {
            shouldDirty: true,
          });
        }
      } catch (error) {
        console.error(error);
        toast({
          variant: 'destructive',
          title: 'Please, try again! (failed to fetch job)',
        });
      }
    },
    [eventQuery, form],
  );

  return (
    <FormContext {...form}>
      <form
        onSubmit={form.handleSubmit(handleSave)}
        className="grid max-h-screen grid-cols-1 gap-4 overflow-auto p-4 sm:grid-cols-2"
      >
        <h3 className="text-xl font-bold">{title}</h3>
        <div className="flex gap-4 place-self-end">
          <Button variant="outline" type="button" onClick={onClose}>
            Cancel
          </Button>
          <Button
            type="submit"
            isLoading={isLoading}
            disabled={!form.formState.isValid || !form.formState.isDirty}
          >
            Save
          </Button>
        </div>
        <FormField
          control={form.control}
          name="jobId"
          render={({ field: { value, onChange, disabled } }) => (
            <FormItem>
              <FormLabel>Job #</FormLabel>
              <FormMessage />
              <FormControl>
                <JobSelector
                  hint={jobSelectorHint}
                  disabled={disabled}
                  value={value}
                  onChange={(val) => {
                    onChange(val);
                    handleSetDepartment(val);
                  }}
                />
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="departmentId"
          render={({ field }) => {
            return (
              <FormItem>
                <FormLabel>Department</FormLabel>
                <FormMessage />
                <SimpleSelect
                  disabled={!timesheetDepartmentQuery.isSuccess || field.disabled || !!jobId}
                  placeholder={'Select Department'}
                  selectedValue={field.value.toString()}
                  onChange={(value) => {
                    if (value) field.onChange(value);
                  }}
                  values={timesheetDepartmentQuery.data ?? []}
                />
              </FormItem>
            );
          }}
        />

        <FormField
          control={form.control}
          name="ownerId"
          render={({ field }) => {
            return (
              <FormItem>
                <FormLabel>Purchaser</FormLabel>
                <FormMessage />
                <Combobox
                  onChange={field.onChange}
                  value={field.value?.toString() ?? ''}
                  emptyLabel={'Select creator'}
                  disabled={techniciansQuery.isPending || field.disabled}
                  values={techniciansQuery.data ?? []}
                  placeholder="Search Purchaser"
                />
              </FormItem>
            );
          }}
        />

        <FormField
          control={form.control}
          name="costCenterId"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Cost Center</FormLabel>
              <FormMessage />
              <FormControl>
                <TransactionAccountComboboxPicker
                  disabled={field.disabled}
                  selected={field.value.toString()}
                  onSelect={(tA) => field.onChange(tA?.id ?? 0)}
                  renderItem={(tA) => `${tA.id}-${tA.description}`}
                />
              </FormControl>
            </FormItem>
          )}
        />

        <div className="flex items-center gap-4 ">
          <FormField
            control={form.control}
            name="amount"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Amount</FormLabel>
                <FormMessage />
                <FormControl>
                  <Input type="number" step="0.01" inputMode="numeric" {...field} />
                </FormControl>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="assignedEmployeeId"
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>Creator</FormLabel>
                  <FormMessage />
                  <FormControl>
                    <Combobox
                      onChange={field.onChange}
                      value={field.value?.toString() ?? ''}
                      emptyLabel={'Select purchaser'}
                      disabled={techniciansQuery.isPending || !changeCreator || field.disabled}
                      values={techniciansQuery.data ?? []}
                      placeholder="Search Purchaser"
                    />
                  </FormControl>
                </FormItem>
              );
            }}
          />
        </div>

        <FormField
          control={form.control}
          name="vendorCategory"
          render={({ field }) => {
            return (
              <FormItem>
                <FormLabel>Vendor Category</FormLabel>
                <FormMessage />
                <FormControl>
                  <SimpleSelect
                    placeholder={'Select Vendor Category'}
                    selectedValue={field.value.toString()}
                    disabled={field.disabled}
                    onChange={field.onChange}
                    values={tagsTranslated}
                  />
                </FormControl>
              </FormItem>
            );
          }}
        />

        <FormField
          control={form.control}
          name="timestamp"
          render={({ field: { ref, ...field } }) => {
            return (
              <FormItem>
                <FormLabel>Timestamp</FormLabel>
                <FormMessage />
                <FormControl>
                  <DateInput {...field} />
                </FormControl>
              </FormItem>
            );
          }}
        />

        <FormField
          control={form.control}
          name="notes"
          render={({ field }) => {
            return (
              <FormItem>
                <FormLabel>Notes</FormLabel>
                <FormMessage />
                <FormControl>
                  <Input type="text" {...field} />
                </FormControl>
              </FormItem>
            );
          }}
        />

        <FormField
          control={form.control}
          name="description"
          render={({ field }) => {
            return (
              <FormItem className="col-span-1 sm:col-span-2">
                <FormLabel>Description</FormLabel>
                <FormMessage />
                <FormControl>
                  <Textarea {...field} />
                </FormControl>
              </FormItem>
            );
          }}
        />

        <FormField
          control={form.control}
          name="vendorId"
          render={({ field }) => {
            return (
              <FormItem>
                <FormLabel>Vendor</FormLabel>
                <FormMessage />
                <FormControl>
                  <Combobox
                    onChange={field.onChange}
                    value={field.value?.toString()}
                    emptyLabel={'No vendor selected'}
                    disabled={vendorsQuery.isPending || field.disabled}
                    values={vendorsQuery.data ?? []}
                    placeholder="Search Vendor"
                  />
                </FormControl>
              </FormItem>
            );
          }}
        />

        <div className="flex gap-4">
          <FormField
            control={form.control}
            name="orderNumber"
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>Order #</FormLabel>
                  <FormMessage />
                  <FormControl>
                    <Input type="text" {...field} />
                  </FormControl>
                </FormItem>
              );
            }}
          />
          <FormField
            control={form.control}
            name="invoiceNumber"
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>Invoice #</FormLabel>
                  <FormMessage />
                  <FormControl>
                    <Input type="text" {...field} />
                  </FormControl>
                </FormItem>
              );
            }}
          />
        </div>
      </form>
    </FormContext>
  );
};
