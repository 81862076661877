import AppBar from '@mui/material/AppBar';
import Tab from '@mui/material/Tab';
import TabsUI from '@mui/material/Tabs';
import { type FC, type ReactNode, useCallback, useEffect, useState } from 'react';

interface Props {
  tabs: {
    label: string;
    content: ReactNode;
    disabled?: boolean;
  }[];
  defaultOpenIdx?: number;
  onChange?: (idx: number) => void;
}

const a11yProps = (idx: number) => ({
  id: `scrollable-auto-tab-${idx}`,
  'aria-controls': `scrollable-auto-tabpanel-${idx}`,
});

export const Tabs: FC<Props> = ({ tabs, defaultOpenIdx = 0, onChange }) => {
  const [tabIdx, setTabIdx] = useState<number>(defaultOpenIdx);

  const handleChange = useCallback(
    (_: any, newValue: number) => {
      setTabIdx(newValue);
      if (onChange) {
        onChange(newValue);
      }
    },
    [setTabIdx, onChange],
  );
  useEffect(() => {
    if (tabIdx >= tabs.length) {
      setTabIdx(0);
    }
  }, [tabIdx, tabs]);
  return (
    <div>
      <AppBar position="relative" color="default">
        <TabsUI
          value={tabIdx}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          {tabs.map((props, idx) => {
            return (
              <Tab key={idx} disabled={props.disabled} label={props.label} {...a11yProps(0)} />
            );
          })}
        </TabsUI>
      </AppBar>
      {tabIdx >= 0 && tabIdx < tabs.length && tabs[tabIdx].content}
    </div>
  );
};
