import { ClassCode } from '@kalos/kalos-rpc';
import { useQuery } from '@tanstack/react-query';

import { ClassCodeClientService } from '../../tools/helpers';
import { queryKeys } from './constants';
import { type EntityFilter } from './utils';

export type ClassCodeFilters = EntityFilter<ClassCode>;

type GetClassListBatchGetResponse = Awaited<ReturnType<typeof ClassCodeClientService.BatchGet>>;
export const useClassCodeListQuery = <TSelectData = GetClassListBatchGetResponse,>({
  enabled = true,
  filters = {},
  select,
}: {
  enabled?: boolean;
  filters?: ClassCodeFilters;
  select?: (data: GetClassListBatchGetResponse) => TSelectData;
} = {}) => {
  return useQuery({
    queryFn: () => {
      const request = ClassCode.create(filters);
      return ClassCodeClientService.BatchGet(request);
    },
    queryKey: [
      queryKeys.classcode.root,
      queryKeys.classcode.list,
      queryKeys.classcode.getClassCodeHash(filters),
    ],
    enabled,
    select,
  });
};
