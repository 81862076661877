import './Alert.module.less';

import Typography from '@mui/material/Typography';
import { type FC, type ReactNode } from 'react';

import { Modal } from '../Modal';
import { type PaginationType, SectionBar } from '../SectionBar';

interface Props {
  title?: string;
  open: boolean;
  onClose: () => void;
  label?: string;
  maxWidth?: number;
  fullscreen?: boolean;
  disabled?: boolean;
  children?: ReactNode;
  className?: string;
  pagination?: PaginationType;
}

export const Alert: FC<Props> = ({
  open,
  title,
  onClose,
  label = 'Okay',
  maxWidth = 370,
  fullscreen = false,
  children,
  disabled,
  className,
  pagination,
}) => (
  <Modal
    open={open}
    onClose={onClose}
    compact
    maxWidth={maxWidth}
    fullScreen={fullscreen}
    className={className}
  >
    <SectionBar
      title={title}
      actions={[{ onClick: onClose, label, disabled }]}
      fixedActions
      styles={{ zIndex: 999 }}
      pagination={pagination}
    />
    <Typography className="Alert" component={'div'} variant={'body2'}>
      {children}
    </Typography>
  </Modal>
);
