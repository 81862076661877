import { type File } from '@kalos/kalos-rpc';
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  CardTitle,
  DataTablePagination,
  toast,
} from '@kalos/ui';
import { DownloadIcon } from '@radix-ui/react-icons';
import { useEffect, useMemo, useState } from 'react';

import { useS3FileUrlQuery } from '../../../../hooks/react-query/useS3';
import { getMimeType } from '../../../../tools/helpers';
import { RotateZoomImage } from '../../../AltGallery/main';
import { Loader } from '../../../Loader/main';

type ImageGalleryProps<T extends Pick<File, 'name' | 'bucket' | 'mimeType'>> = {
  title?: string;
  files: T[];
  actions?: (props: { original: T }) => React.ReactNode;
};

export const ImageGallery = <T extends Pick<File, 'name' | 'bucket' | 'mimeType'>>({
  title,
  files,
  actions,
}: ImageGalleryProps<T>) => {
  const [activeImageIdx, setActiveImageIdx] = useState(0);

  useEffect(() => {
    setActiveImageIdx(0);
  }, [files.length]);
  const fetchImageUrl = useS3FileUrlQuery({
    filter: {
      fileName: files.at(activeImageIdx)?.name ?? '',
      bucket: files.at(activeImageIdx)?.bucket ?? '',
    },
    enabled: files.length > 0 && !!files.at(activeImageIdx),
  });
  const { mimeType } = useMemo(
    () => ({
      mimeType:
        files.at(activeImageIdx)?.mimeType != ''
          ? files.at(activeImageIdx)?.mimeType
          : getMimeType(files?.at(activeImageIdx)?.name ?? ''),
    }),
    [activeImageIdx, files],
  );
  const [isDownloading, setIsDownloading] = useState(false);

  const downloadFile = () => {
    setIsDownloading(true);

    const currentFileMeta = fetchImageUrl.data;
    if (!currentFileMeta) {
      console.error('No file data found');
      return;
    }

    const fileName = files[activeImageIdx].name;
    const el = document.createElement('a');
    el.download = fileName;

    fetch(currentFileMeta)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        el.href = url;
        el.click();
        URL.revokeObjectURL(url);
      })
      .catch((error) => {
        toast({
          variant: 'destructive',
          title: 'Error downloading file',
        });
        console.error('Error downloading file:', error);
      })
      .finally(() => {
        setIsDownloading(false);
      });
  };

  return (
    <Card className="relative flex h-full w-full flex-1 flex-col overflow-hidden">
      {!!title && (
        <CardHeader>
          <div>
            <CardTitle>{title}</CardTitle>
          </div>
        </CardHeader>
      )}

      <div
        className="flex h-[calc(100%_-138px)] w-full items-center justify-center"
        style={{ transform: 'scale(0.8)' }}
      >
        {fetchImageUrl.isLoading ? (
          <Loader className="AltGalleryModal-Loader" />
        ) : files?.length ? (
          mimeType === 'application/pdf' ? (
            <iframe title="preview" src={fetchImageUrl.data} className="h-full w-full" />
          ) : (
            <RotateZoomImage url={fetchImageUrl.data ?? ''} />
          )
        ) : (
          <div className="absolute flex h-full w-full items-center justify-center">
            <span className="text-xl">No files found.</span>
          </div>
        )}
      </div>

      <CardFooter className="flex justify-center gap-2 p-2">
        {fetchImageUrl.isSuccess && actions?.({ original: files[activeImageIdx] })}
        <Button
          className="flex gap-1 text-xs sm:text-sm"
          onClick={downloadFile}
          isLoading={isDownloading}
          disabled={fetchImageUrl.isFetching || !fetchImageUrl.data || isDownloading}
        >
          <DownloadIcon />
        </Button>
        <DataTablePagination
          currentPage={activeImageIdx}
          pageCount={files.length ?? 0}
          setPage={setActiveImageIdx}
          disabled={fetchImageUrl.isLoading}
        />
      </CardFooter>
    </Card>
  );
};
