import { type Transaction, TransactionActivity } from '@kalos/kalos-rpc';
import { DataTable, DataTableColumnHeader, DataTablePagination, Skeleton } from '@kalos/ui';
import { type ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { format } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';

import { useTransactionActivityBatchGet } from '../../../hooks/react-query/useTransactionActivityQuery';
import { useBatchUserQuery } from '../../../hooks/react-query/useUserQuery';
import { techniciansUserFilter } from '../../ComponentsLibrary/Pickers/newPickers/QueryPickerV2';

const loadingActivityLogs = Array.from({ length: 5 }, (_, i) => TransactionActivity.create());

export const TxnActivityLogs = ({ transactionId }: { transactionId: Transaction['id'] }) => {
  const [page, setPage] = useState<number>(0);
  const [totalCount, setCount] = useState<number>(0);

  const transactionLogsQuery = useTransactionActivityBatchGet({
    filters: {
      transactionId,
      pageNumber: page,
    },
    select(data) {
      const results = (data?.results || [])?.map((item) => ({
        ...item,
        timestamp: format(new Date(item.timestamp), 'yyyy-MM-dd hh:mm:ss a'),
      }));
      return { ...data, results };
    },
  });
  const techniciansQuery = useBatchUserQuery({
    filters: techniciansUserFilter,
  });

  useEffect(() => {
    if (transactionLogsQuery.isSuccess) {
      setCount(transactionLogsQuery.data?.totalCount || 0);
    }
  }, [transactionLogsQuery.data?.totalCount, transactionLogsQuery.isSuccess]);

  const transactionLogsTableColumns: ColumnDef<TransactionActivity>[] = useMemo(
    () =>
      [
        {
          accessorKey: 'timestamp',
          header: ({ column }) => <DataTableColumnHeader title="Action Date" column={column} />,
          cell: ({ row }) => <div className="w-48">{row.original.timestamp}</div>,
        },
        {
          accessorKey: 'description',
          header: ({ column }) => <DataTableColumnHeader title="Description" column={column} />,
          cell: ({ row }) => <div className="w-48">{row.original.description}</div>,
        },
        {
          accessorKey: 'userId',
          header: ({ column }) => <DataTableColumnHeader title="User" column={column} />,
          cell: ({ row }) => {
            const technician = techniciansQuery.data?.results.find(
              (technician) => technician.id === row.original.userId,
            );
            return (
              <div className="w-48">
                {technician ? `${technician.firstname} ${technician.lastname}` : ``}
              </div>
            );
          },
        },
        {
          accessorKey: 'description',
          header: ({ column }) => <DataTableColumnHeader title="Reason" column={column} />,
          cell: ({ row }) => <div className="w-48">{row.original.description}</div>,
        },
      ] satisfies ColumnDef<TransactionActivity>[],
    [techniciansQuery.data],
  );

  const loadingTableColumns = useMemo<ColumnDef<TransactionActivity>[]>(
    () =>
      transactionLogsTableColumns.map((column) => ({
        ...column,
        cell: () => <Skeleton className="h-4 w-48" />,
      })),
    [transactionLogsTableColumns],
  );

  const table = useReactTable({
    columns: transactionLogsQuery.isFetching ? loadingTableColumns : transactionLogsTableColumns,
    data: transactionLogsQuery.isFetching
      ? loadingActivityLogs
      : transactionLogsQuery?.data?.results ?? [],
    getCoreRowModel: getCoreRowModel(),
    enableSorting: false,
  });

  return (
    <div className="relative">
      <div className="h-[60vh] overflow-y-auto">
        <DataTable table={table} />
      </div>
      <div className="bg-background sticky inset-x-0 bottom-0 flex max-w-[100vw] items-center justify-between gap-3 px-3">
        <span className="text-sm">
          Total entries count: {transactionLogsQuery.data?.totalCount || totalCount}
        </span>
        <DataTablePagination
          currentPage={page}
          pageCount={Math.ceil((transactionLogsQuery.data?.totalCount || 0) / 25)}
          setPage={setPage}
          className="mb-3 mt-3"
        />
      </div>
    </div>
  );
};
