import { ApiKeyClient } from '../ApiKey';
import { BaseClient } from '../BaseClient';
import { Double } from '../compiled-protos/common';
import {
  Coordinates,
  DistanceMatrixResponse,
  MatrixRequest,
  Place,
  Places,
} from '../compiled-protos/maps';
import { MapServiceClient } from '../compiled-protos/maps.client';
export type tripResult = { distance: number; duration: number } | undefined;
class MapClient extends BaseClient {
  self: MapServiceClient;

  ApiKeyClient: ApiKeyClient;
  constructor(host: string, userID?: number) {
    super(host, userID);
    this.ApiKeyClient = new ApiKeyClient(host);
    this.self = new MapServiceClient(this.transport);
  }

  public async Elevation(req: Coordinates) {
    let res = Double.create();
    try {
      res = await this.self.elevation(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async DistanceMatrix(req: MatrixRequest) {
    const res = await this.self.distanceMatrix(req, this.getMetaData()).response;
    return res;
  }

  public async Geocode(req: Place) {
    let res = Coordinates.create();
    try {
      res = await this.self.geocode(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async GetDistanceBetweenPlaces(origin: string, destination: string) {
    const req = Places.create();
    const data = [
      addressStringToPlace(origin),
      addressStringToPlace(destination),
    ];
    req.data = data;
    let res = DistanceMatrixResponse.create();
    try {
      res = await this.self.getDistanceBetweenPlaces(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public getTripDistance = async (origin: string, destination: string) => {
    let results: tripResult = undefined;
    try {
      const matReq = MatrixRequest.create();
      const sortable: string[] = [];
      sortable.push(origin, destination);
      sortable.sort((a, b) => (a < b ? -1 : 1));
      const placeOrigin = addressStringToPlace(sortable[0]);
      const placeDestination = addressStringToPlace(sortable[1]);

      const [coordsOrigin, coordsDestination] = await Promise.all([this.Geocode(placeOrigin), await this.Geocode(placeDestination)])

      matReq.origins = [coordsOrigin!];

      matReq.destination = coordsDestination;
      const tripDistance = await this.DistanceMatrix(matReq);
      let status: string = '';
      let duration = 0;
      let distanceMeters: number | undefined;
      if (tripDistance!.rows.length > 1) {
        console.error('More than one row returned for Trip calculation.');
      } else {
        const rowReturned = tripDistance!.rows[0];
        rowReturned.distanceMatrixElement.forEach(listObj => {
          distanceMeters = Number(listObj.distance?.meters);
          duration = Number(listObj.duration);
          status = listObj.status;
        });
      }

      if (status != 'OK') {
        console.error("Status was not 'OK' on distanceMatrixRequest.");
        console.error('Status was: ', status);
      }

      const distanceMiles = metersToMiles(distanceMeters ? distanceMeters : 0);
      results = { distance: distanceMiles, duration: duration };
      return results;
    } catch (err) {
      console.error(
        'An error occurred while calculating the trip distance: ' + err
      );
      return results;
    }
  };
  public async loadGeoLocationByAddress(address: string) {
    try {
      const res = await this.ApiKeyClient.getKeyByKeyName('google_maps');
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${res!.apiKey
        }`
      );
      const data = await response.json();
      const {
        results: [
          {
            geometry: {
              location: { lat, lng },
            },
          },
        ],
      } = data;
      return {
        geolocationLat: +lat.toFixed(7),
        geolocationLng: +lng.toFixed(7),
      };
    } catch (e) {
      console.error(
        'Could not load geolocation by address. This error occurred:  ',
        e
      );
    }
    return undefined
  }
}

const metersToMiles = (meters: number): number => {
  const conversionFactor = 0.000621;
  return !Number.isNaN(meters * conversionFactor)
    ? meters * conversionFactor
    : 0;
};

const addressStringToPlace = (addressString: string): Place => {
  const pl = Place.create();
  // Can detect the zip code by the fact it's all numbers in USA and always
  // comes after everything else
  // Can detect the road name because it's always followed by commas
  const split = addressString.split(',');
  let streetAddress = split[0];
  let city = split[1]; // Gotta check on this one, may include the state
  let state = '',
    zipCode = '';
  const zipAndState = split.length > 2 ? split[2] : null;
  for (const str in city.split(' ')) {
    // If this doesn't work, it probably is next to the zip code
    if (
      Object.values(STATE_CODES).indexOf(String(str)) > -1 ||
      Object.keys(STATE_CODES).indexOf(String(str)) > -1
    ) {
      // This is a state
      state = str;
      city = city.replace(str, '');
      break;
    }
  }

  if (zipAndState) {
    zipAndState.split(' ').forEach(str => {
      if (
        (state == '' && Object.values(STATE_CODES).indexOf(String(str)) > -1) ||
        Object.keys(STATE_CODES).indexOf(String(str)) > -1
      ) {
        // This is a state
        state = str;
      }
      if (!isNaN(Number(str))) {
        zipCode = str;
      }
    });
  }
  const streetInfo = streetAddress.split(' ');
  let streetNumber = 0; // figuring this out in the loop
  if (!isNaN(Number(streetInfo[0]))) {
    streetNumber = Number(streetInfo[0]);
  }

  if (zipCode === '') {
    // still need to set this, so there must be only split[1]
    split[split.length - 1].split(' ').forEach(str => {
      if (!isNaN(Number(str))) {
        zipCode = str;
      }
    });
  }

  if (state === '') {
    // We really need to set this, see if anything in the last split has any states in it
    split[split.length - 1].split(' ').forEach(str => {
      if (
        (state == '' && Object.values(STATE_CODES).indexOf(String(str)) > -1) ||
        Object.keys(STATE_CODES).indexOf(String(str)) > -1
      ) {
        // This is a state
        state = str;
        city = city.replace(str, '');
        city = zipCode != '' ? city.replace(zipCode, '') : city;
      }
    });
  }

  streetAddress = streetAddress.replace(String(streetNumber), '');
  streetAddress = streetAddress.trim();
  city = city.trim();
  state = state.trim();

  pl.streetNumber = streetNumber;
  pl.roadName = streetAddress;
  pl.city = city;
  pl.state = state;
  pl.zipCode = zipCode;

  return pl;
};

export const STATE_CODES = {
  alabama: 'AL',
  alaska: 'AK',
  'american samoa': 'AS',
  arizona: 'AZ',
  arkansas: 'AR',
  california: 'CA',
  colorado: 'CO',
  connecticut: 'CT',
  delaware: 'DE',
  'district of columbia': 'DC',
  'federated states of micronesia': 'FM',
  florida: 'FL',
  georgia: 'GA',
  guam: 'GU',
  hawaii: 'HI',
  idaho: 'ID',
  illinois: 'IL',
  indiana: 'IN',
  iowa: 'IA',
  kansas: 'KS',
  kentucky: 'KY',
  louisiana: 'LA',
  maine: 'ME',
  'marshall islands': 'MH',
  maryland: 'MD',
  massachusetts: 'MA',
  michigan: 'MI',
  minnesota: 'MN',
  mississippi: 'MS',
  missouri: 'MO',
  montana: 'MT',
  nebraska: 'NE',
  nevada: 'NV',
  'new hampshire': 'NH',
  'new jersey': 'NJ',
  'new mexico': 'NM',
  'new york': 'NY',
  'north carolina': 'NC',
  'north dakota': 'ND',
  'northern mariana islands': 'MP',
  ohio: 'OH',
  oklahoma: 'OK',
  oregon: 'OR',
  palau: 'PW',
  pennsylvania: 'PA',
  'puerto rico': 'PR',
  'rhode island': 'RI',
  'south carolina': 'SC',
  'south dakota': 'SD',
  tennessee: 'TN',
  texas: 'TX',
  utah: 'UT',
  vermont: 'VT',
  'virgin islands': 'VI',
  virginia: 'VA',
  washington: 'WA',
  'west virginia': 'WV',
  wisconsin: 'WI',
  wyoming: 'WY',
};

export { Coordinates, addressStringToPlace, metersToMiles, Place, MatrixRequest, MapClient };
