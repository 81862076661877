import {
  type SpiffList,
  SpiffOption,
  SpiffOptionList,
  type SpiffToolAdminAction,
  SpiffType,
  type SpiffTypeList,
  Task,
  TaskList,
  type ToolFund,
} from '@kalos/kalos-rpc';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { SpiffToolAdminActionClientService, TaskClientService } from '../../tools/helpers';
import { isTruthy } from '../../tools/typeguargs';
import { queryKeys } from './constants';
import { type EntityFilter } from './utils';
export type SpiffOptionFilter = EntityFilter<SpiffOption>;

export const useToolFundBalanceQuery = <TSelectData = ToolFund,>({
  userId,
  select,
  enabled = true,
}: {
  userId: number;
  select?: (arg: ToolFund) => TSelectData;
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: [queryKeys.task.root, queryKeys.task.toolBudget, userId],
    queryFn: () => {
      return TaskClientService.GetToolFundBalanceByID(userId);
    },
    select,
    enabled,
  });
};

export const useAppliedSpiffsQuery = <TSelectData = SpiffList,>({
  userId,
  select,
  enabled = true,
}: {
  userId: number;
  select?: (arg: SpiffList) => TSelectData;
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: [queryKeys.task.root, queryKeys.task.appliedSpiffs, userId],
    queryFn: () => {
      return TaskClientService.GetAppliedSpiffs(userId);
    },
    select,
    enabled,
  });
};
export const useSpiffOptionBatchGetQuery = <TSelectData = SpiffOptionList,>({
  enabled = true,
  filter = {},
  select,
}: {
  enabled?: boolean;
  filter?: SpiffOptionFilter;
  select?: (data?: SpiffOptionList) => TSelectData;
}) => {
  return useQuery({
    queryKey: [
      queryKeys.spiffOptions.root,
      queryKeys.spiffOptions.list,
      queryKeys.spiffOptions.getHash(filter),
    ],
    queryFn: async () => {
      return await TaskClientService.BatchGetSpiffOption(SpiffOption.create(filter));
    },
    enabled,
    select,
  });
};

export type SpiffTypeFilter = EntityFilter<SpiffType>;
export const useSpiffTypeBatchGetQuery = <TSelectData = SpiffTypeList,>({
  filter = {},
  enabled,
  select,
}: {
  filter?: SpiffTypeFilter;
  select?: (data: SpiffTypeList) => TSelectData;
  enabled?: boolean;
} = {}) => {
  return useQuery({
    queryKey: [
      queryKeys.spiffTypes.root,
      queryKeys.spiffTypes.list,
      queryKeys.spiffTypes.getHash(filter),
    ],
    queryFn: async () => {
      return await TaskClientService.BatchGetSpiffTypes(SpiffType.create(filter));
    },
    select,
    enabled,
  });
};

export type TaskFilter = EntityFilter<Task>;
export const useTaskBatchGetQuery = <TSelectData = TaskList,>({
  enabled = true,
  filter = {},
  select,
}: {
  enabled?: boolean;
  filter?: EntityFilter<Task>;
  select?: (data: TaskList) => TSelectData;
}) => {
  return useQuery({
    queryKey: [queryKeys.task.root, queryKeys.task.list, queryKeys.task.getHash(filter)],
    queryFn: async () => {
      return await TaskClientService.BatchGet(Task.create(filter));
    },
    enabled,
    select,
  });
};

export const useTaskUpdateMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (task: Task) => {
      return await TaskClientService.Update(task);
    },
    onSuccess: (data) => {
      queryClient.setQueriesData<TaskList>(
        { queryKey: [queryKeys.task.root, queryKeys.task.list] },
        (oldData) => {
          if (oldData && oldData.results.find((task) => task.id === data.id)) {
            return TaskList.create({
              results: oldData.results.map((task) => (task.id === data.id ? data : task)),
              totalCount: oldData.totalCount,
            });
          }
        },
      );
      queryClient.invalidateQueries({
        queryKey: [queryKeys.task.root],
      });
    },
  });
};

export const useSpiffOptionCreateMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: SpiffOption) => {
      return await TaskClientService.CreateSpiffOption(data);
    },
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries({ queryKey: [queryKeys.spiffOptions.root] });
    },
  });
};

export const useSpiffOptionUpdateMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: SpiffOption) => {
      return await TaskClientService.UpdateSpiffOption(data);
    },
    onSuccess(data, variables, context) {
      queryClient.setQueriesData<SpiffOptionList>(
        {
          queryKey: [queryKeys.spiffOptions.root],
          predicate(query) {
            const filter = query.queryKey.at(2);
            if (
              query.queryKey[1] === queryKeys.spiffOptions.list &&
              typeof filter === 'string' &&
              filter.length > 0
            ) {
              try {
                const parsedFilter = JSON.parse(filter);
                if (
                  parsedFilter &&
                  typeof parsedFilter === 'object' &&
                  isTruthy(parsedFilter) &&
                  'eventId' in parsedFilter
                ) {
                  if (parsedFilter.eventId === data.eventId) {
                    return true;
                  }
                }
              } catch (error) {
                return false;
              }

              return true;
            }

            return false;
          },
        },
        (cache) => {
          if (cache && cache.results.find((el) => el.id === data.id)) {
            return SpiffOptionList.create({
              ...cache,
              results: cache.results.map((el) => (el.id === data.id ? data : el)),
            });
          }
        },
      );
    },
  });
};

export const useSpiffOptionDeleteMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: SpiffOption) => {
      return await TaskClientService.DeleteSpiffOption(data);
    },
    onSuccess(data, variables, context) {
      queryClient.setQueriesData<SpiffOptionList>(
        {
          queryKey: [queryKeys.spiffOptions.root],
          predicate(query) {
            const filter = query.queryKey.at(2);
            if (
              query.queryKey[1] === queryKeys.spiffOptions.list &&
              typeof filter === 'string' &&
              filter.length > 0
            ) {
              try {
                const parsedFilter = JSON.parse(filter);
                if (
                  parsedFilter &&
                  typeof parsedFilter === 'object' &&
                  isTruthy(parsedFilter) &&
                  'eventId' in parsedFilter
                ) {
                  if (parsedFilter.eventId === data.eventId) {
                    return true;
                  }
                }
              } catch (error) {
                return false;
              }

              return true;
            }

            return false;
          },
        },
        (cache) => {
          if (cache && cache.results.find((el) => el.id === data.id)) {
            return SpiffOptionList.create({
              ...cache,
              results: cache.results.filter((el) => el.id !== data.id),
            });
          }
        },
      );
    },
  });
};

export const useSpiffAdminActionUpdateMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (spiff: SpiffToolAdminAction) => {
      return await SpiffToolAdminActionClientService.Update(spiff);
    },
    onSuccess: (data) => {
      queryClient.setQueriesData<TaskList>(
        { queryKey: [queryKeys.task.root, queryKeys.task.list] },
        (oldData) => {
          if (oldData && oldData.results.find((task) => task.id === data.taskId)) {
            return TaskList.create({
              results: oldData.results.map((task) =>
                task.id === data.taskId
                  ? Task.create({
                      ...task,
                      actions: task.actions.map((action) =>
                        action.id === data.id ? data : action,
                      ),
                    })
                  : task,
              ),
              totalCount: oldData.totalCount,
            });
          }
        },
      );
    },
  });
};

export const useSpiffAdminActionCreateMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (spiff: SpiffToolAdminAction) => {
      return await SpiffToolAdminActionClientService.Create(spiff);
    },
    onSuccess: (data) => {
      queryClient.setQueriesData<TaskList>(
        { queryKey: [queryKeys.task.root, queryKeys.task.list] },
        (oldData) => {
          if (oldData && oldData.results.find((task) => task.id === data.taskId)) {
            return TaskList.create({
              results: oldData.results.map((task) =>
                task.id === data.taskId
                  ? Task.create({
                      ...task,
                      actions: [...task.actions, data],
                    })
                  : task,
              ),
              totalCount: oldData.totalCount,
            });
          }
        },
      );
      queryClient.invalidateQueries({
        queryKey: [queryKeys.task.root, queryKeys.task.spiffOptions],
      });
    },
  });
};

export const useSpiffAdminActionDeleteMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (spiff: SpiffToolAdminAction) => {
      return await SpiffToolAdminActionClientService.Delete(spiff);
    },
    onSuccess: (data, variables) => {
      queryClient.setQueriesData<TaskList>(
        { queryKey: [queryKeys.task.root, queryKeys.task.list] },
        (oldData) => {
          if (oldData && oldData.results.find((task) => task.id === variables.taskId)) {
            return TaskList.create({
              results: oldData.results.map((task) =>
                task.id === variables.taskId
                  ? Task.create({
                      ...task,
                      actions: task.actions.filter((action) => action.id !== variables.id),
                    })
                  : task,
              ),
              totalCount: oldData.totalCount,
            });
          }
        },
      );
      queryClient.invalidateQueries({
        queryKey: [queryKeys.task.root, queryKeys.task.spiffOptions],
      });
    },
  });
};

export const useCreateTaskMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (task: Task) => {
      return await TaskClientService.Create(task);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.task.root, queryKeys.task.list] });
      queryClient.invalidateQueries({ queryKey: [queryKeys.task.root, queryKeys.task.toolBudget] });
    },
  });
};

export const useDeleteTaskMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (task: Task) => {
      return await TaskClientService.Delete(task);
    },
    onSuccess: (data, variables) => {
      queryClient.setQueriesData<TaskList>(
        { queryKey: [queryKeys.task.root, queryKeys.task.list] },
        (oldData) => {
          if (oldData && oldData.results.find((task) => task.id === variables.id)) {
            return TaskList.create({
              results: oldData.results.filter((task) => task.id !== variables.id),
              totalCount: oldData.totalCount - 1,
            });
          }
        },
      );
      queryClient.invalidateQueries({
        queryKey: [queryKeys.task.root],
      });
    },
  });
};
