import { TransactionAccount, type TransactionAccountList } from '@kalos/kalos-rpc';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { TransactionAccountClientService } from '../../tools/helpers';
import { queryKeys } from './constants';
import { type EntityFilter } from './utils';

export type TransactionAccountFilters = EntityFilter<TransactionAccount>;

export const useTransactionAccountBatchGet = <TSelectData = TransactionAccountList,>({
  enabled = true,
  filter = {},
  select,
}: {
  enabled?: boolean;
  filter?: TransactionAccountFilters;
  select?: (data?: TransactionAccountList) => TSelectData;
}) => {
  return useQuery({
    queryKey: [
      queryKeys.transactionAccount.root,
      queryKeys.transactionAccount.getTransactionAccountHash(filter),
    ],
    queryFn: async () => {
      const req = TransactionAccount.create(filter);
      return await TransactionAccountClientService.BatchGet(req);
    },
    enabled,
    select,
  });
};

export const useTransactionAccountGet = ({
  enabled = true,
  filters,
}: {
  enabled?: boolean;
  filters: TransactionAccountFilters;
}) => {
  return useQuery({
    queryKey: [
      queryKeys.transactionAccountGet.root,
      queryKeys.transactionAccountGet.getFiltersHash(filters),
    ],
    queryFn: async () => {
      const req = TransactionAccount.create(filters);
      return await TransactionAccountClientService.Get(req);
    },
    enabled,
  });
};

export const useTransactionAccountGetImmediate = () => {
  const queryClient = useQueryClient();
  const fetchData = useCallback(
    async (filters: TransactionAccountFilters) => {
      const cache = queryClient.getQueryData<TransactionAccount | undefined>([
        queryKeys.vendor.root,
        queryKeys.vendor.getVendorHash(filters),
      ]);
      if (cache) {
        return cache;
      }
      const req = TransactionAccount.create(filters);
      const data = await TransactionAccountClientService.Get(req);
      queryClient.setQueryData(
        [
          queryKeys.transactionAccountGet.root,
          queryKeys.transactionAccountGet.getFiltersHash(filters),
        ],
        data,
      );
      console.log({ data });

      return data;
    },
    [queryClient],
  );
  return fetchData;
};
