import { CallCountRequest, type Event, EventClient } from '@kalos/kalos-rpc';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Button,
  Card,
  CardContent,
  CardSkeleton,
  CardTitle,
  DateInput,
} from '@kalos/ui';
import { Typography } from '@mui/material';
import { addHours, addMinutes, startOfDay } from 'date-fns';
import { format as formatTz, utcToZonedTime } from 'date-fns-tz';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ENDPOINT, JOB_STATUS_COLORS } from '../../../constants';

const EventClientService = new EventClient(ENDPOINT);

const generateTimeWindows = (date: Date) => {
  const windows = [];
  const timeZone = 'America/New_York';

  // Get the start of the day in the specified time zone
  const startOfTodayInTimeZone = utcToZonedTime(startOfDay(date), timeZone);
  startOfTodayInTimeZone.setHours(8, 0, 0, 0); // Set to 8:00 AM in Eastern Time

  let startTime = startOfTodayInTimeZone;
  const finalWindowStart = addMinutes(addHours(startOfTodayInTimeZone, 15), 45); // 11:45 PM local time

  // Create 4-hour windows for the entire day
  while (startTime <= finalWindowStart) {
    const windowEndTime = addHours(startTime, 4);
    const displayTime = `${formatTz(startTime, 'h aa', { timeZone })} - ${formatTz(windowEndTime, 'h aa', { timeZone })}`;

    let color;
    const hour = startTime.getHours();
    if (hour < 12) {
      color = '#FFA07A'; // Light Orange for 8 AM to 12 PM
    } else if (hour >= 12 && hour < 16) {
      color = '#FF8C00'; // Darker Orange for 12 PM to 4 PM
    } else {
      color = '#9370DB'; // Purple for 4 PM onwards
    }

    windows.push({
      startTime: formatTz(startTime, 'yyyy-MM-dd HH-mm-ss', { timeZone }),
      endTime: formatTz(windowEndTime, 'yyyy-MM-dd HH-mm-ss', { timeZone }),
      displayTime,
      color,
    });

    // Move to the next hour
    startTime = addHours(startTime, 1);
  }

  // Ensure a final window is included if the last generated window's start time is before 11:45 PM
  const lastWindow = windows[windows.length - 1];
  if (lastWindow.startTime !== formatTz(finalWindowStart, 'yyyy-MM-dd HH-mm-ss', { timeZone })) {
    windows.push({
      startTime: formatTz(finalWindowStart, 'yyyy-MM-dd HH-mm-ss', { timeZone }),
      endTime: formatTz(addHours(finalWindowStart, 4), 'yyyy-MM-dd HH-mm-ss', { timeZone }),
      displayTime: `${formatTz(finalWindowStart, 'h:mm aa', { timeZone })} - ${formatTz(addHours(finalWindowStart, 4), 'h:mm aa', { timeZone })}`,
      color: '#9370DB', // Purple for the last window
    });
  }

  return windows;
};

export const CallWindow: React.FC = () => {
  const [callCounts, setCallCounts] = useState<{ [key: string]: number }>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [jobsInWindow, setJobsInWindow] = useState<{ [key: string]: Event[] }>({});
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());

  const removeLastSegment = (dateTimeString: string) => {
    // Replace colons with hyphens
    const formattedString = dateTimeString.replace(/:/g, '-');
    // Remove the last '-00' segment
    return formattedString.substring(0, formattedString.lastIndexOf('-'));
  };

  const fetchActiveTimeWindows = useCallback(async (date: Date) => {
    const timeWindows = generateTimeWindows(date);
    console.log('timeWindows from switched day:', timeWindows);
    const promises = timeWindows.map(async (window) => {
      const req = CallCountRequest.create({
        startTime: removeLastSegment(window.startTime),
        endTime: removeLastSegment(window.endTime),
      });

      try {
        const res = await EventClientService.getCurrentDayServiceCallWindows(req);

        return {
          window,
          callCount: res?.callCount || 0,
          jobsInWindow: res?.jobsInWindow || [],
        };
      } catch (error) {
        console.error(`Error fetching time window ${window.startTime} - ${window.endTime}:`, error);
        return { window, callCount: 0, jobsInWindow: [] };
      }
    });

    const results = await Promise.all(promises);

    const initialAcc: {
      callCounts: { [key: string]: number };
      jobsInWindow: { [key: string]: Event[] };
    } = { callCounts: {}, jobsInWindow: {} };

    const { callCounts, jobsInWindow } = results.reduce((acc, item) => {
      acc.callCounts[item.window.startTime] = item.callCount;
      acc.jobsInWindow[item.window.startTime] = item.jobsInWindow;
      return acc;
    }, initialAcc);

    setCallCounts(callCounts);
    setJobsInWindow(jobsInWindow);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await fetchActiveTimeWindows(selectedDate);
      setLoading(false);
    };

    fetchData();

    const intervalId = setInterval(() => {
      fetchActiveTimeWindows(selectedDate);
    }, 60000);

    return () => clearInterval(intervalId);
  }, [fetchActiveTimeWindows, selectedDate]);

  const navigate = useNavigate();

  const handleJobClick = (jobId: number) => {
    navigate(`/jobs/${jobId}`);
  };

  const handleDateChange = (newDate: Date) => {
    setSelectedDate(newDate);
  };

  const timeWindowsGeneration = generateTimeWindows(selectedDate);

  return (
    <div className="p-4">
      <div className="mb-4 flex items-center justify-between">
        <DateInput value={selectedDate} onChange={handleDateChange} id="call-window-date-input" />
        <Button onClick={() => fetchActiveTimeWindows(selectedDate)}>Refresh</Button>
      </div>
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          {loading
            ? Array.from({ length: 4 }).map((_, index) => <CardSkeleton key={index} />)
            : timeWindowsGeneration.map((window, index) => {
                const callCount = callCounts[window.startTime] || 0;
                const jobs = jobsInWindow[window.startTime] || [];

                return (
                  <Card
                    key={index}
                    className="rounded-lg border shadow-sm"
                    style={{ backgroundColor: window.color }}
                  >
                    <CardTitle className="rounded-t-lg bg-gray-100 p-2 text-center text-lg font-semibold">
                      Window: {index + 1}
                    </CardTitle>

                    <CardContent className="p-4">
                      <Typography variant="h6" className="text-center text-base font-bold">
                        {window.displayTime}
                      </Typography>
                      <Typography
                        variant="body1"
                        className="mt-2 text-center text-sm"
                        style={{ fontWeight: 'bold' }}
                      >
                        Call Count: {callCount}
                      </Typography>
                    </CardContent>
                    {jobs.length > 0 && (
                      <Accordion type="single" collapsible>
                        <AccordionItem value={`item-${index}`}>
                          <AccordionTrigger className="rounded-t-md bg-white px-3 py-1 text-base font-bold shadow-sm">
                            Call Information
                          </AccordionTrigger>
                          <AccordionContent className="rounded-b-md bg-white px-2 py-1 shadow-sm">
                            {jobs.map((job, idx) => (
                              <div key={job.id} className="mb-3 p-2">
                                <div className="mb-2 rounded-md bg-white px-2 py-1 shadow-sm">
                                  <span
                                    className="cursor-pointer text-sm font-bold underline"
                                    onClick={() => handleJobClick(job.id)}
                                  >
                                    Job ID: {job.id}
                                  </span>
                                </div>
                                <div className="mb-2 inline-flex items-center gap-2 rounded-md bg-white px-2 py-1 shadow-sm">
                                  <div
                                    className="h-2 w-2 rounded-full"
                                    style={{
                                      backgroundColor: `#${JOB_STATUS_COLORS[job.logJobStatus] || JOB_STATUS_COLORS['*']}`,
                                    }}
                                  />
                                  <span className="text-xs font-bold">
                                    Job Status: {job.logJobStatus}
                                  </span>
                                </div>
                                <div className="mb-2 rounded-md bg-white px-2 py-1 shadow-sm">
                                  <span className="text-xs font-bold">Job Name: {job.name}</span>
                                </div>
                              </div>
                            ))}
                          </AccordionContent>
                        </AccordionItem>
                      </Accordion>
                    )}
                  </Card>
                );
              })}
        </div>
      </div>
    </div>
  );
};
