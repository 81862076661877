import { BaseClient } from '../BaseClient';
import { PendingBilling, PendingBillingList } from '../compiled-protos/event';
import { EventServiceClient as PendingBillingServiceClient } from '../compiled-protos/event.client';

class PendingBillingClient extends BaseClient {
  self: PendingBillingServiceClient;

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new PendingBillingServiceClient(this.transport);
  }

  public async Get(req: PendingBilling) {
    let res = PendingBilling.create();
    try {
      res = await this.self.getPendingBilling(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async BatchGet(req: PendingBilling) {
    let res = PendingBillingList.create();
    try {
      res = await this.self.batchGetPendingBilling(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }
}

export { PendingBilling, PendingBillingList, PendingBillingClient };
