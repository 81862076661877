import { BaseClient } from '../BaseClient';
import { Bool } from '../compiled-protos/common';
import {
  type PropertyDocumentRequest
  ,
  SQSEmail,
} from '../compiled-protos/email';
import { EmailServiceClient } from '../compiled-protos/email.client';

type EmailConfig = {
  type?: 'receipts' | 'it' | 'office' | 'invoice' | 'timeoff' | 'permitChange';
  recipient: string;
  body: string;
  subject: string;
  from?: string;
};

class EmailClient extends BaseClient {
  self: EmailServiceClient

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new EmailServiceClient(this.transport)
  }

  public async sendMail(config: EmailConfig) {
    const req = SQSEmail.create();
    req.body = config.body;
    req.subject = config.subject;
    req.to = config.recipient;
    return await this.SendSQSMail(req);
  }

  public async SendSQSMail(req: SQSEmail) {
    let res = Bool.create()
    try {
      res = await this.self.sendSQS(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }


  public async SendPropertyDocument(req: PropertyDocumentRequest) {
    let res = Bool.create()
    try {
      res = await this.self.sendPropertyDocument(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }
  public async SendPropertyDocumentExpire(req: PropertyDocumentRequest) {
    let res = Bool.create()
    try {
      res = await this.self.sendPropertyDocumentExpire(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }

}

export type { EmailConfig }
export { EmailClient, SQSEmail };
