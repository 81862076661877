import {
  type EmailConfig,
  Transaction,
  TransactionActivity,
  TransactionList,
} from '@kalos/kalos-rpc';
import {
  Button,
  Dialog,
  DialogCloseCustom,
  DialogContent,
  DialogTrigger,
  LoadingIcon,
  Popover,
  PopoverContent,
  PopoverTrigger,
  toast,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  useConfirm,
} from '@kalos/ui';
import {
  AvatarIcon,
  CameraIcon,
  CheckCircledIcon,
  CopyIcon,
  CrossCircledIcon,
  FileIcon,
  Pencil1Icon,
  ReaderIcon,
  ResetIcon,
  TextAlignLeftIcon,
  TrashIcon,
  UploadIcon,
} from '@radix-ui/react-icons';
import { useQueryClient } from '@tanstack/react-query';
import { format, parseISO } from 'date-fns';
import { type ComponentProps, useCallback, useMemo, useState } from 'react';

import { Prompt } from '../../../../components/Prompt';
import { useAuth } from '../../../../context/AuthContext';
import { queryKeys } from '../../../../hooks/react-query/constants';
import { useEmailClientSendMutation } from '../../../../hooks/react-query/useEmailClientQuery';
import { useEventQueryInline } from '../../../../hooks/react-query/useEventsQuery';
import { useTransactionActivityCreateMutation } from '../../../../hooks/react-query/useTransactionActivityQuery';
import {
  useTransactionDeleteMutation,
  useTransactionUpdateMutation,
} from '../../../../hooks/react-query/useTransactionQuery';
import { useUserQuery, useUserQueryInline } from '../../../../hooks/react-query/useUserQuery';
import { useImmediateVendorGetQuery } from '../../../../hooks/react-query/useVendorQuery';
import { useCopyToClipboard } from '../../../../hooks/useCopyToClipboard';
import { timestamp, TransactionActivityClientService } from '../../../../tools/helpers';
import { TransactionFilesGallery } from '../../../AltGallery/main';
import { prettyMoney } from '../../../Transactions/components/TransactionAdmin';
import {
  reasonSchema,
  TransactionLineItemsAction,
} from '../../../Transactions/components/TransactionRowAdminActions';
import { TxnActivityLogs } from '../../../Transactions/components/TxnLog';
import { ConfirmDelete } from '../../ConfirmDelete';
import { EditTransaction } from '../../EditTransaction';
import { Modal } from '../../Modal';
import { type AssignedUserData } from '../../Payroll';
import { PlainForm, type Schema } from '../../PlainForm';
import { SectionBar } from '../../SectionBar';
import { UploadPhotoToExistingTransaction } from '../../UploadPhotoToExistingTransaction';

const createActivityReq = (description: string, transactionId: number, loggedUserId: number) => {
  const activity = TransactionActivity.create();
  activity.isActive = 1;
  activity.timestamp = timestamp();
  activity.userId = loggedUserId;
  activity.description = description;
  activity.transactionId = transactionId;
  return activity;
};

export const useMakeTransactionActivityLog = () => {
  const loggedUserId = useAuth().user.id;
  const makeLog = useCallback(
    async (description: string, transactionId: number) => {
      const activity = createActivityReq(description, transactionId, loggedUserId);
      await TransactionActivityClientService.Create(activity);
    },
    [loggedUserId],
  );
  return useMemo(() => ({ makeLog }), [makeLog]);
};

export const useMakeUpdateTransactionStatus = ({
  shouldDeleteAfterUpdate,
}: { shouldDeleteAfterUpdate?: boolean } = {}) => {
  const { makeLog } = useMakeTransactionActivityLog();

  const transactionUpdateMutation = useTransactionUpdateMutation();
  const queryClient = useQueryClient();
  const makeUpdateStatus = useCallback(
    async ({
      id,
      statusID,
      description,
      reason,
    }: {
      id: Transaction['id'];
      statusID: Transaction['statusId'];
      description: string;
      reason?: string;
    }) => {
      const txn = Transaction.create();
      txn.id = id;
      txn.statusId = statusID;
      txn.fieldMask = ['StatusId'];
      txn.isBillingRecorded = true;

      await transactionUpdateMutation.mutateAsync(txn);
      if (shouldDeleteAfterUpdate) {
        queryClient.setQueriesData<TransactionList>(
          { queryKey: [queryKeys.transaction.root, queryKeys.transaction.list], type: 'active' },
          (cache) => {
            if (cache && cache.results.find((el) => el.id === txn.id)) {
              return TransactionList.create({
                results: cache.results.filter((entry) => entry.id !== txn.id),
                totalCount: cache.totalCount - 1,
              });
            }
          },
        );
        await queryClient.invalidateQueries({ queryKey: [queryKeys.transaction.root] });
      }

      try {
        await makeLog(`${description} ${reason || ''}`, id);
      } catch (err) {
        console.error(`An error occurred while making an activity log: ${err}`);
      }
    },
    [makeLog, queryClient, shouldDeleteAfterUpdate, transactionUpdateMutation],
  );

  return useMemo(() => ({ makeUpdateStatus }), [makeUpdateStatus]);
};

const EditTransactionAction = ({
  transaction,
  changeCreator,
  page,
}: { transaction: Transaction; page: number } & Pick<
  ComponentProps<typeof EditTransaction>,
  'changeCreator'
>) => {
  const [isEditingModalOpen, setIsEditingModalOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const loggedUserId = useAuth().user.id;

  const closeEditingModal = useCallback(() => {
    setIsEditingModalOpen(false);
  }, []);

  const transactionActivityCreateMutation = useTransactionActivityCreateMutation();
  const transactionUpdateMutation = useTransactionUpdateMutation();
  const vendorGetQuery = useImmediateVendorGetQuery();
  const eventQuery = useEventQueryInline();

  const updateTransaction = useCallback(
    async (transactionToSave: Transaction) => {
      setIsUpdating(true);
      try {
        const log = TransactionActivity.create();
        log.timestamp = format(new Date(), 'yyyy-MM-dd hh:mm:ss');
        log.pageNumber = page;
        log.transactionId = transactionToSave.id;
        log.userId = loggedUserId!;
        log.description = `Updating transaction with id ${transactionToSave.id} (done by user #${loggedUserId})`;
        await transactionActivityCreateMutation.mutateAsync(log);
      } catch (err) {
        setIsUpdating(false);
        console.error(`An error occurred while uploading an activity log: ${err}`);
      }

      try {
        if (transactionToSave.vendorId) {
          const vendorResult = await vendorGetQuery({ id: Number(transactionToSave.vendorId) });
          transactionToSave.vendor = vendorResult?.vendorName || '';
          if (!transactionToSave.fieldMask) {
            transactionToSave.fieldMask = [];
          }
          transactionToSave.fieldMask.push('VendorId');
          transactionToSave.fieldMask.push('Vendor');
        }
        const req = Transaction.clone(transactionToSave);
        if (req.vendorId) req.vendorId = Number(req.vendorId);
        if (req.jobId) {
          const event = await eventQuery.fetchEvent({
            filter: {
              id: req.jobId,
            },
          });
          req.departmentId = event.departmentId;
        }
        await transactionUpdateMutation.mutateAsync(req);
        setIsEditingModalOpen(false);
      } catch (err) {
        try {
          const errLog = TransactionActivity.create();
          errLog.timestamp = format(new Date(), 'yyyy-MM-dd hh:mm:ss');
          errLog.transactionId = transactionToSave.id;
          errLog.userId = loggedUserId!;
          errLog.description = `ERROR : An error occurred while updating a transaction: ${err}`;
          await transactionActivityCreateMutation.mutateAsync(errLog);
        } catch (errActivity) {
          console.error(`An error occurred while updating a transaction: ${err}`);
        }
        console.error('An error occurred while updating a transaction: ', err);
        setIsEditingModalOpen(false);
      }
      setIsEditingModalOpen(false);
      setIsUpdating(false);
    },
    [
      page,
      loggedUserId,
      transactionActivityCreateMutation,
      transactionUpdateMutation,
      eventQuery,
      vendorGetQuery,
    ],
  );

  return (
    <Dialog open={isEditingModalOpen} onOpenChange={setIsEditingModalOpen}>
      <Tooltip>
        <DialogTrigger asChild>
          <TooltipTrigger asChild>
            <Button variant="outline" className="px-2 py-2">
              <Pencil1Icon />
            </Button>
          </TooltipTrigger>
        </DialogTrigger>
        <TooltipContent>Edit this transaction</TooltipContent>
      </Tooltip>

      <DialogContent showClose={false} className="max-w-max p-0">
        <EditTransaction
          transactionInput={transaction}
          title="Edit Transaction"
          isLoading={isUpdating}
          disabled={isUpdating}
          onSave={(saved) => {
            saved.id = transaction.id;
            updateTransaction(saved);
          }}
          onClose={closeEditingModal}
          changeCreator={changeCreator}
        />
      </DialogContent>
    </Dialog>
  );
};

const UploadPhotoForTransactionAction = ({ transaction }: { transaction: Transaction }) => {
  const loggedUserId = useAuth().user.id;

  const [isUploadingPhotoModalOpen, setIsUploadingPhotoModalOpen] = useState(false);
  const openUploadPhotoModal = useCallback(() => {
    setIsUploadingPhotoModalOpen(true);
  }, []);
  const closeUploadPhotoModal = useCallback(() => {
    setIsUploadingPhotoModalOpen(false);
  }, []);

  const queryClient = useQueryClient();
  return (
    <>
      <Tooltip>
        <TooltipContent>Upload File</TooltipContent>
        <TooltipTrigger asChild>
          <Button variant="outline" className="px-2 py-2" onClick={openUploadPhotoModal}>
            <UploadIcon />
          </Button>
        </TooltipTrigger>
      </Tooltip>
      <Modal open={isUploadingPhotoModalOpen} maxWidth={1000} onClose={closeUploadPhotoModal}>
        <UploadPhotoToExistingTransaction
          transactionPassed={transaction}
          loggedUserId={loggedUserId}
          onClose={closeUploadPhotoModal}
          onUpload={() => {
            queryClient.invalidateQueries({
              queryKey: [queryKeys.transaction.root, queryKeys.transaction.list],
            });
            queryClient.invalidateQueries({
              queryKey: [queryKeys.transactionDocument.root],
            });
          }}
        />
      </Modal>
    </>
  );
};

const RejectTransactionAction = ({ transaction }: { transaction: Transaction }) => {
  const loggedUserId = useAuth().user.id;
  const currentUserQuery = useUserQuery({
    filters: {
      id: loggedUserId,
    },
  });
  const { fetchUser } = useUserQueryInline();
  const emailClientSendMutation = useEmailClientSendMutation();

  const [isUpdating, setIsUpdating] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const { makeUpdateStatus } = useMakeUpdateTransactionStatus({ shouldDeleteAfterUpdate: true });

  const dispute = useCallback(
    async ({ prompt }: { prompt: string }) => {
      setIsUpdating(true);
      if (!currentUserQuery.data) {
        // should not happen as button that calls this function is disabled if there is no user
        console.error('Need a user to send to for disputes, however none was gotten. Returning.');
        return;
      }

      const ownerUser = await fetchUser({
        filter: { id: transaction.ownerId },
      });

      const body = getRejectTxnBody(
        prompt,
        transaction.amount,
        transaction.description,
        transaction.vendor,
      );
      const email: EmailConfig = {
        type: 'receipts',
        recipient: ownerUser.email,
        subject: 'Receipts',
        from: currentUserQuery.data.email,
        body,
      };

      try {
        await emailClientSendMutation.mutateAsync(email);
      } catch (err) {
        alert('An error occurred, user was not notified via email');
      }

      try {
        await makeUpdateStatus({
          id: transaction.id,
          statusID: 4,
          description: 'rejected',
          reason: prompt,
        });
      } catch (err) {
        alert('An error occurred while updating the transaction status');
      }
      setIsUpdating(false);
    },
    [
      currentUserQuery.data,
      emailClientSendMutation,
      fetchUser,
      makeUpdateStatus,
      transaction.amount,
      transaction.description,
      transaction.id,
      transaction.ownerId,
      transaction.vendor,
    ],
  );

  return (
    <Tooltip>
      <TooltipContent>Reject</TooltipContent>
      <Prompt
        onSubmit={dispute}
        onOpenChange={setIsOpened}
        promptSchema={reasonSchema}
        isLoading={isUpdating}
        open={isOpened}
        title="Reject Transaction"
        label="Enter reason for rejection:"
        trigger={
          <TooltipTrigger asChild>
            <Button
              disabled={transaction.statusId === 5 || !currentUserQuery.isSuccess}
              size="icon"
              variant="outline"
            >
              <CrossCircledIcon />
              <span className="sr-only">Reject</span>
            </Button>
          </TooltipTrigger>
        }
      />
    </Tooltip>
  );
};

const ApproveTransactionAction = ({
  transaction,
  shouldDeleteAfterUpdate,
}: {
  transaction: Transaction;
  shouldDeleteAfterUpdate?: boolean;
}) => {
  const { makeUpdateStatus } = useMakeUpdateTransactionStatus({ shouldDeleteAfterUpdate });
  const [isApproving, setIsApproving] = useState(false);

  const requestUpdateStatus = useCallback(async () => {
    const ok = confirm(`Are you sure you want to mark this transaction as accepted?`);
    if (ok) {
      try {
        setIsApproving(true);
        await makeUpdateStatus({ id: transaction.id, statusID: 3, description: 'approved' });
      } catch {
        alert('An error occurred while updating the transaction status');
      }
      setIsApproving(false);
    }
  }, [makeUpdateStatus, transaction.id]);

  return (
    <Tooltip>
      <TooltipContent>Mark as accepted</TooltipContent>
      <TooltipTrigger asChild>
        <Button
          disabled={transaction.statusId === 5}
          variant="outline"
          className="px-2 py-2"
          onClick={requestUpdateStatus}
        >
          {isApproving ? <LoadingIcon /> : <CheckCircledIcon />}
        </Button>
      </TooltipTrigger>
    </Tooltip>
  );
};

const CopyTransactionInfoAction = ({ transaction }: { transaction: Transaction }) => {
  const [, copyToClipboard] = useCopyToClipboard();
  const [isSuccess, setIsSuccess] = useState(false);

  const copyTransaction = useCallback(() => {
    copyToClipboard(
      `${parseISO(transaction.timestamp.split(' ').join('T')).toLocaleDateString()},${
        transaction.description
      },${transaction.orderNumber},${transaction.invoiceNumber},${transaction.ownerName},${
        transaction.assignedEmployeeName
      },${transaction.department?.description},${transaction.jobId},${
        transaction.costCenter?.description
      },${transaction.amount},${transaction.vendor},${transaction.notes}`,
    ).then((res) => {
      setIsSuccess(res);
      if (res) {
        setTimeout(() => {
          setIsSuccess(false);
        }, 2000);
      }
    });
  }, [
    copyToClipboard,
    transaction.amount,
    transaction.assignedEmployeeName,
    transaction.costCenter?.description,
    transaction.department?.description,
    transaction.description,
    transaction.invoiceNumber,
    transaction.jobId,
    transaction.notes,
    transaction.orderNumber,
    transaction.ownerName,
    transaction.timestamp,
    transaction.vendor,
  ]);

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button variant="outline" className="px-2 py-2" onClick={copyTransaction}>
          {isSuccess ? <CheckCircledIcon /> : <CopyIcon />}
        </Button>
      </TooltipTrigger>
      <TooltipContent>Copy transaction information</TooltipContent>
    </Tooltip>
  );
};

const ProcessTransactionAction = ({
  transaction,
  shouldDeleteAfterUpdate,
}: {
  transaction: Transaction;
  shouldDeleteAfterUpdate?: boolean;
}) => {
  const { makeUpdateStatus } = useMakeUpdateTransactionStatus({ shouldDeleteAfterUpdate });
  const confirm = useConfirm();
  const [isLoading, setIsLoading] = useState(false);

  const requestUpdateStatus = useCallback(async () => {
    const ok = await confirm({
      title: `Are you sure you want to mark this transaction as Processed?`,
    });
    if (!ok) return;

    try {
      setIsLoading(true);
      await makeUpdateStatus({
        id: transaction.id,
        statusID: 5,
        description: 'Recorded and Processed',
      });
      toast({
        title: 'successfully updated transaction status to Processed!',
        variant: 'success',
      });
    } catch {
      toast({
        title: 'An error occurred while updating the transaction status',
        variant: 'destructive',
      });
    } finally {
      setIsLoading(false);
    }
  }, [confirm, makeUpdateStatus, setIsLoading, transaction.id]);

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button
          disabled={isLoading}
          isLoading={isLoading}
          variant="outline"
          className="px-2 py-2"
          onClick={requestUpdateStatus}
        >
          <FileIcon />
        </Button>
      </TooltipTrigger>
      <TooltipContent>Mark as processed</TooltipContent>
    </Tooltip>
  );
};

const SCHEMA_ASSIGN_USER: Schema<AssignedUserData> = [
  [
    {
      name: 'employeeId',
      label: 'Employee to assign',
      type: 'technician',
    },
  ],
];

const DeleteTransactionAction = ({ transaction }: { transaction: Transaction }) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const openDeleteModal = useCallback(() => {
    setIsDeleting(true);
  }, []);

  const closeDeleteModal = useCallback(() => {
    setIsDeleting(false);
  }, []);

  const transactionDeleteMutation = useTransactionDeleteMutation();
  const loggedUserId = useAuth().user.id;
  const transactionActivityCreate = useTransactionActivityCreateMutation();

  const deleteTransaction = useCallback(async () => {
    try {
      await transactionDeleteMutation.mutateAsync(transaction);
      const activity = createActivityReq(
        'Accounts Payable Record Deleted',
        transaction.id,
        loggedUserId,
      );
      await transactionActivityCreate.mutateAsync(activity);
      closeDeleteModal();
    } catch (err) {
      console.error(`An error occurred while deleting a transaction: ${err}`);
    }
  }, [
    closeDeleteModal,
    loggedUserId,
    transaction,
    transactionDeleteMutation,
    transactionActivityCreate,
  ]);

  return (
    <>
      <ConfirmDelete
        open={isDeleting}
        onClose={closeDeleteModal}
        onConfirm={deleteTransaction}
        kind="this transaction"
        name=""
        title="Delete"
        disabled={transactionDeleteMutation.isPending || transactionActivityCreate.isPending}
      >
        Are you sure you want to delete this transaction?
      </ConfirmDelete>
      <Tooltip>
        <TooltipContent>Delete</TooltipContent>
        <TooltipTrigger asChild>
          <Button variant="outline" className="px-2 py-2" onClick={openDeleteModal}>
            <TrashIcon />
          </Button>
        </TooltipTrigger>
      </Tooltip>
    </>
  );
};
const AssignTransactionAction = ({ transaction }: { transaction: Transaction }) => {
  const [isAssignUserModalOpen, setIsAssignUserModalOpen] = useState(false);
  const closeAssignUserModal = useCallback(() => {
    setIsAssignUserModalOpen(false);
  }, []);

  const openAssignUserModal = useCallback(() => {
    setIsAssignUserModalOpen(true);
  }, []);

  const transactionUpdateMutation = useTransactionUpdateMutation();

  interface AssignedEmployeeType {
    employeeId: number;
  }

  const [assigningUser, setAssigningUser] = useState<AssignedEmployeeType>({
    employeeId: transaction.assignedEmployeeId,
  });

  const handleAssignEmployee = useCallback(
    async (employeeIdToAssign: number) => {
      try {
        const req = Transaction.create();
        req.id = transaction.id;
        req.assignedEmployeeId = employeeIdToAssign;
        req.fieldMask = ['AssignedEmployeeId'];
        const result = await transactionUpdateMutation.mutateAsync(req);
        if (!result) {
          console.error('Unable to assign employee.');
        }

        setIsAssignUserModalOpen(false);
        setAssigningUser({ employeeId: 0 });
      } catch (err) {
        console.error('An error occurred while assigning an employee: ', err);
      }
    },
    [transaction.id, transactionUpdateMutation],
  );

  const sectionBarAction = useMemo(() => {
    return [
      {
        label: 'Assign',
        disabled: !assigningUser?.employeeId,
        onClick: () => {
          if (!assigningUser?.employeeId) {
            alert('Please select an employee to assign.');
            return;
          }
          handleAssignEmployee(assigningUser?.employeeId);
        },
      },
    ];
  }, [assigningUser?.employeeId, handleAssignEmployee]);

  return (
    <>
      <Tooltip>
        <TooltipContent>Assign an employee to this task</TooltipContent>
        <TooltipTrigger asChild>
          <Button variant="outline" className="px-2 py-2" onClick={openAssignUserModal}>
            <AvatarIcon />
          </Button>
        </TooltipTrigger>
      </Tooltip>

      <Modal open={isAssignUserModalOpen} onClose={closeAssignUserModal}>
        <SectionBar title="Assign Employee to Task" actions={sectionBarAction} />
        <PlainForm
          data={assigningUser}
          onChange={setAssigningUser}
          schema={SCHEMA_ASSIGN_USER}
          className="PayrollFilter"
        />
      </Modal>
    </>
  );
};

export const TransactionGalleryAction = (
  galleryProps: ComponentProps<typeof TransactionFilesGallery>,
) => (
  <Dialog>
    <Tooltip>
      <DialogTrigger asChild>
        <TooltipTrigger asChild>
          <Button className="px-2 py-2" variant="outline">
            <CameraIcon />
          </Button>
        </TooltipTrigger>
      </DialogTrigger>
      <TooltipContent>View Photos and Documents</TooltipContent>
    </Tooltip>
    <DialogContent
      showClose={false}
      className="h-[80vh] w-full max-w-none overflow-y-auto bg-none p-0 sm:w-[60vw]"
    >
      <div className="relative">
        <DialogCloseCustom className="absolute right-8 top-4  z-20" />
        <TransactionFilesGallery {...galleryProps} />
      </div>
    </DialogContent>
  </Dialog>
);

export const ViewTransactionLogsActions = ({ transactionId }: { transactionId: number }) => (
  <Dialog>
    <Tooltip>
      <DialogTrigger asChild>
        <TooltipTrigger asChild>
          <Button className="px-2 py-2" variant="outline">
            <ReaderIcon />
          </Button>
        </TooltipTrigger>
      </DialogTrigger>
      <TooltipContent>View Activity logs</TooltipContent>
    </Tooltip>
    <DialogContent
      showClose={false}
      className="flex max-h-[80vh] max-w-max overflow-y-auto border-none p-0 pt-2"
    >
      <div className="relative flex flex-1">
        <DialogCloseCustom className="absolute right-8 top-4  z-20" />
        <TxnActivityLogs transactionId={transactionId} />
      </div>
    </DialogContent>
  </Dialog>
);

const SubmitTransactionAction = ({ transaction }: { transaction: Transaction }) => {
  const { makeUpdateStatus } = useMakeUpdateTransactionStatus({ shouldDeleteAfterUpdate: true });

  const updateStatusSubmit = useCallback(async () => {
    const ok = confirm(`Are you sure you want resubmitted this?`);
    if (ok) {
      try {
        await makeUpdateStatus({
          id: transaction.id,
          statusID: 2,
          description: 'Re-submitted Transaction',
        });
      } catch {
        alert('An error occurred while updating the transaction status');
      }
    }
  }, [makeUpdateStatus, transaction.id]);

  return (
    <Tooltip>
      <TooltipContent>Submit</TooltipContent>
      <TooltipTrigger asChild>
        <Button variant="outline" className="px-2 py-2" onClick={updateStatusSubmit}>
          <ResetIcon />
        </Button>
      </TooltipTrigger>
    </Tooltip>
  );
};

export const TransactionActions = ({
  transaction,
  shouldDeleteAfterUpdate,
  page,
}: { transaction: Transaction; shouldDeleteAfterUpdate?: boolean } & Pick<
  ComponentProps<typeof EditTransactionAction>,
  'page'
>) => {
  const loggedUserId = useAuth().user.id;
  const currentUserQuery = useUserQuery({
    filters: {
      id: loggedUserId,
    },
    select(data) {
      return {
        ...data,
        role: data.permissionGroups.find(
          (p) => p.type === 'role' && (p.name === 'Manager' || p.name === 'AccountsPayable'),
        )?.name,
        isAccountsPayableAdmin: !!data.permissionGroups.find(
          (p) => p.name === 'AccountsPayableAdmin',
        )?.name,
      };
    },
  });

  const isOwner =
    loggedUserId == transaction.ownerId || loggedUserId == transaction.assignedEmployeeId;
  const isManager = currentUserQuery.data?.role == 'Manager';
  const isAdmin = currentUserQuery.data?.isAccountsPayableAdmin;

  const editAction = (
    <EditTransactionAction
      page={page}
      transaction={transaction}
      changeCreator={!!currentUserQuery.data?.isAccountsPayableAdmin}
    />
  );

  const copyAction = <CopyTransactionInfoAction transaction={transaction} />;

  const uploadAction = <UploadPhotoForTransactionAction transaction={transaction} />;

  const transactionGalleryAction = (
    <TransactionGalleryAction
      transactionId={transaction.id}
      canDelete={isOwner || isManager || isAdmin}
      title="Transaction Uploads"
      waiverDisplay
    />
  );

  const logAction = <ViewTransactionLogsActions transactionId={transaction.id} />;

  const noteAction = (
    <Popover>
      <Tooltip>
        <PopoverTrigger asChild>
          <TooltipTrigger asChild>
            <Button className="px-2 py-2" disabled={!transaction.notes} variant="outline">
              <TextAlignLeftIcon />
            </Button>
          </TooltipTrigger>
        </PopoverTrigger>
        <TooltipContent>View Notes</TooltipContent>
      </Tooltip>
      <PopoverContent className="max-w-max">{transaction.notes}</PopoverContent>
    </Popover>
  );

  const rejectAction = <RejectTransactionAction transaction={transaction} />;
  const assignAction = <AssignTransactionAction transaction={transaction} />;
  const deleteAction = <DeleteTransactionAction transaction={transaction} />;

  const commonActions = (
    <>
      {transactionGalleryAction}
      {noteAction}
      {logAction}
      {copyAction}
    </>
  );

  if (transaction.statusId == 2) {
    // pending
    const canEdit = isOwner || isManager || isAdmin;
    return (
      <>
        {commonActions}
        {canEdit ? editAction : null}
        {isManager || isAdmin || isOwner ? deleteAction : null}
        {isManager || isAdmin || isOwner ? uploadAction : null}
        {isManager || isAdmin ? assignAction : null}
        {isManager || isAdmin ? (
          <ApproveTransactionAction
            transaction={transaction}
            shouldDeleteAfterUpdate={shouldDeleteAfterUpdate}
          />
        ) : null}
        {isManager || isAdmin ? rejectAction : null}
        <TransactionLineItemsAction transaction={transaction} readonly={!canEdit} />
      </>
    );
  }

  if (transaction.statusId == 3) {
    const canEdit = isManager || isAdmin;
    return (
      <>
        {commonActions}
        {canEdit ? editAction : null}
        {isManager || isAdmin ? uploadAction : null}
        {isManager || isAdmin ? assignAction : null}
        {isManager || isAdmin ? rejectAction : null}
        {isAdmin ? (
          <ProcessTransactionAction
            transaction={transaction}
            shouldDeleteAfterUpdate={shouldDeleteAfterUpdate}
          />
        ) : null}
        {isAdmin ? deleteAction : null}
        <TransactionLineItemsAction transaction={transaction} readonly={!canEdit} />
      </>
    );
  }

  if (transaction.statusId === 4) {
    // rejected
    const canEdit = isOwner || isManager || isAdmin;
    return (
      <>
        {commonActions}
        {canEdit ? editAction : null}
        {isOwner || isAdmin || isManager ? deleteAction : null}
        {isOwner || isAdmin || isManager ? uploadAction : null}
        {isOwner || isAdmin || isManager ? (
          <SubmitTransactionAction transaction={transaction} />
        ) : null}
        {isManager || isAdmin ? assignAction : null}
        <TransactionLineItemsAction transaction={transaction} readonly={!canEdit} />
      </>
    );
  }

  if (transaction.statusId == 5) {
    // processed
    const canEdit = isAdmin;
    return (
      <>
        {commonActions}
        {canEdit ? editAction : null}
        {isAdmin ? uploadAction : null}
        {isAdmin ? deleteAction : null}
        <TransactionLineItemsAction transaction={transaction} readonly={!canEdit} />
      </>
    );
  }

  console.warn(`No defined actions for transaction status ${transaction.statusId}`);
  return commonActions;
};

const getRejectTxnBody = (
  reason: string,
  amount: number,
  description: string,
  vendor: string,
): string => {
  return `
<body>
  <table style="width:70%;">
    <thead>
      <th style="text-align:left;">Reason</th>
      <th style="text-align:left;">Amount</th>
      <th style="text-align:left;">Info</th>
    </thead>
    <tbody>
      <tr>
        <td>${reason}</td>
        <td>${prettyMoney(amount)}</td>
        <td>${description}${vendor != '' ? ` - ${vendor}` : ''}</td>
      </tr>
    </body>
  </table>
  <a href="https://live.kalosflorida.com/transactions">Go to receipts</a>
</body>`;
};
