// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "transaction.proto" (syntax proto3)
// tslint:disable
import { TransactionService } from "./transaction";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service TransactionService
 */
export class TransactionServiceClient {
    constructor(_transport) {
        this._transport = _transport;
        this.typeName = TransactionService.typeName;
        this.methods = TransactionService.methods;
        this.options = TransactionService.options;
    }
    /**
     * @generated from protobuf rpc: Create(Transaction) returns (Transaction);
     */
    create(input, options) {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Get(Transaction) returns (Transaction);
     */
    get(input, options) {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGet(Transaction) returns (TransactionList);
     */
    batchGet(input, options) {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Search(Transaction) returns (TransactionList);
     */
    search(input, options) {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Update(Transaction) returns (Transaction);
     */
    update(input, options) {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Delete(Transaction) returns (Transaction);
     */
    delete(input, options) {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: RecordPage(RecordPageReq) returns (TransactionList);
     */
    recordPage(input, options) {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: AuditPage(AuditPageReq) returns (TransactionList);
     */
    auditPage(input, options) {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchCreate(BatchCreateRequest) returns (BatchCreateResponse);
     */
    batchCreate(input, options) {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateCSIDivision(CSIDivision) returns (CSIDivision);
     */
    createCSIDivision(input, options) {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetCSIDivision(CSIDivision) returns (CSIDivision);
     */
    getCSIDivision(input, options) {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetCSIDivision(CSIDivision) returns (CSIDivisionList);
     */
    batchGetCSIDivision(input, options) {
        const method = this.methods[11], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateCSIDivision(CSIDivision) returns (CSIDivision);
     */
    updateCSIDivision(input, options) {
        const method = this.methods[12], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteCSIDivision(CSIDivision) returns (CSIDivision);
     */
    deleteCSIDivision(input, options) {
        const method = this.methods[13], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetTransactionStatus(TransactionStatus) returns (TransactionStatus);
     */
    getTransactionStatus(input, options) {
        const method = this.methods[14], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetTransactionStatus(TransactionStatus) returns (TransactionStatusList);
     */
    batchGetTransactionStatus(input, options) {
        const method = this.methods[15], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateTransactionDocument(TransactionDocument) returns (TransactionDocument);
     */
    createTransactionDocument(input, options) {
        const method = this.methods[16], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetTransactionDocument(TransactionDocument) returns (TransactionDocument);
     */
    getTransactionDocument(input, options) {
        const method = this.methods[17], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetTransactionDocument(TransactionDocument) returns (TransactionDocumentList);
     */
    batchGetTransactionDocument(input, options) {
        const method = this.methods[18], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateTransactionDocument(TransactionDocument) returns (TransactionDocument);
     */
    updateTransactionDocument(input, options) {
        const method = this.methods[19], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteTransactionDocument(TransactionDocument) returns (TransactionDocument);
     */
    deleteTransactionDocument(input, options) {
        const method = this.methods[20], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateTransactionActivity(TransactionActivity) returns (TransactionActivity);
     */
    createTransactionActivity(input, options) {
        const method = this.methods[21], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetTransactionActivity(TransactionActivity) returns (TransactionActivity);
     */
    getTransactionActivity(input, options) {
        const method = this.methods[22], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetTransactionActivity(TransactionActivity) returns (TransactionActivityList);
     */
    batchGetTransactionActivity(input, options) {
        const method = this.methods[23], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateTransactionActivity(TransactionActivity) returns (TransactionActivity);
     */
    updateTransactionActivity(input, options) {
        const method = this.methods[24], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteTransactionActivity(TransactionActivity) returns (TransactionActivity);
     */
    deleteTransactionActivity(input, options) {
        const method = this.methods[25], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: MergeTransactionActivityLogs(MergeTransactionIds) returns (Empty);
     */
    mergeTransactionActivityLogs(input, options) {
        const method = this.methods[26], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateTransactionAccount(TransactionAccount) returns (TransactionAccount);
     */
    createTransactionAccount(input, options) {
        const method = this.methods[27], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetTransactionAccount(TransactionAccount) returns (TransactionAccount);
     */
    getTransactionAccount(input, options) {
        const method = this.methods[28], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetTransactionAccount(TransactionAccount) returns (TransactionAccountList);
     */
    batchGetTransactionAccount(input, options) {
        const method = this.methods[29], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateTransactionAccount(TransactionAccount) returns (TransactionAccount);
     */
    updateTransactionAccount(input, options) {
        const method = this.methods[30], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteTransactionAccount(TransactionAccount) returns (TransactionAccount);
     */
    deleteTransactionAccount(input, options) {
        const method = this.methods[31], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreatePendingInvoiceTransaction(PendingInvoiceTransaction) returns (PendingInvoiceTransaction);
     */
    createPendingInvoiceTransaction(input, options) {
        const method = this.methods[32], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetPendingInvoiceTransaction(PendingInvoiceTransaction) returns (PendingInvoiceTransaction);
     */
    getPendingInvoiceTransaction(input, options) {
        const method = this.methods[33], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetPendingInvoiceTransaction(PendingInvoiceTransaction) returns (PendingInvoiceTransactionList);
     */
    batchGetPendingInvoiceTransaction(input, options) {
        const method = this.methods[34], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdatePendingInvoiceTransaction(PendingInvoiceTransaction) returns (PendingInvoiceTransaction);
     */
    updatePendingInvoiceTransaction(input, options) {
        const method = this.methods[35], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeletePendingInvoiceTransaction(PendingInvoiceTransaction) returns (PendingInvoiceTransaction);
     */
    deletePendingInvoiceTransaction(input, options) {
        const method = this.methods[36], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: PostRequestToOCR(PostRequestToOCRReq) returns (DocumentResponse);
     */
    postRequestToOCR(input, options) {
        const method = this.methods[37], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetTransactionOCR(JSONTransactionOCR) returns (TransactionOCR);
     */
    getTransactionOCR(input, options) {
        const method = this.methods[38], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateTransactionOCR(TransactionOCR) returns (TransactionOCR);
     */
    updateTransactionOCR(input, options) {
        const method = this.methods[39], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: OcrCreateOrUpdateSimple(PostRequestToOCRReq) returns (Empty);
     */
    ocrCreateOrUpdateSimple(input, options) {
        const method = this.methods[40], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetTransactionOCR(JSONTransactionOCR) returns (TransactionOCRList);
     */
    batchGetTransactionOCR(input, options) {
        const method = this.methods[41], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateLineItem(TransactionLineItem) returns (TransactionLineItem);
     */
    createLineItem(input, options) {
        const method = this.methods[42], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetLineItem(TransactionLineItem) returns (TransactionLineItem);
     */
    getLineItem(input, options) {
        const method = this.methods[43], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetLineItem(TransactionLineItem) returns (TransactionLineItemList);
     */
    batchGetLineItem(input, options) {
        const method = this.methods[44], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateLineItem(TransactionLineItem) returns (TransactionLineItem);
     */
    updateLineItem(input, options) {
        const method = this.methods[45], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteLineItem(TransactionLineItem) returns (TransactionLineItem);
     */
    deleteLineItem(input, options) {
        const method = this.methods[46], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
}
