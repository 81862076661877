import { type Event } from '@kalos/kalos-rpc';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  Skeleton,
} from '@kalos/ui';

import {
  type MostLikelyJobRequestFilter,
  useEventHintQuery,
} from '../../hooks/react-query/useEventsQuery';
import { useMediaQuery } from '../../hooks/useMedia';

type JobSelectorHintComboboxProps = {
  hint: MostLikelyJobRequestFilter;
  onSelect: (selected: Event) => void;
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
};
export const JobSelectorHintCombobox = ({
  hint,
  onSelect,
  onBlur,
}: JobSelectorHintComboboxProps) => {
  const eventHintQuery = useEventHintQuery({
    filter: { ...hint },
    enabled: !!hint,
    select(data) {
      return data.results.map((event) => ({
        value: event.id.toString(),
        label: event.id.toString() + event.name + event.notes + event.description,
        displayLabel: (
          <div className="flex flex-col gap-0.5 text-xs">
            <span>Number: {event.id}</span>
            <span className="line-clamp-3">Details: {event.name}</span>
            <span className="line-clamp-3">Description: {event.description}</span>
          </div>
        ),
        original: event,
      }));
    },
  });

  const isPhone = useMediaQuery('(max-width: 640px)');

  const _onSelect = (id: string) => {
    const event = eventHintQuery.data?.find(({ value }) => value === id);
    if (event) {
      onSelect(event.original);
    }
  };

  return (
    <Command
      onBlur={onBlur}
      filter={(value, search) => {
        return eventHintQuery.data
          ?.find(({ value: v }) => value === v)
          ?.label.toLowerCase()
          .includes(search.toLowerCase())
          ? 1
          : 0;
      }}
    >
      <CommandList className="max-h-max">
        <CommandInput data-focus-marker="job-selector-hint-input" placeholder="Search job" />
        {!eventHintQuery.isPending && <CommandEmpty>No Jobs to offer ;(</CommandEmpty>}
        {eventHintQuery.isPending ? (
          <div className="flex flex-col gap-1">
            <Skeleton className="bg-foreground/20 h-16 w-full" />
            {!isPhone && (
              <>
                <Skeleton className="bg-foreground/20 h-16 w-full" />
                <Skeleton className="bg-foreground/20 h-16 w-full" />
              </>
            )}
          </div>
        ) : (
          <CommandGroup
            data-focus-marker="job-selector-hint-input"
            className="max-h-72 overflow-y-auto md:max-h-96"
          >
            {eventHintQuery.isSuccess &&
              eventHintQuery.data.map(({ value, displayLabel }) => (
                <CommandItem
                  className="mt-1 shadow-md"
                  key={value}
                  value={value}
                  onKeyUp={(event: React.KeyboardEvent<HTMLDivElement>) => {
                    if (event.key === 'Enter') {
                      _onSelect(value);
                    }
                  }}
                  onSelect={_onSelect}
                >
                  {displayLabel}
                </CommandItem>
              ))}
          </CommandGroup>
        )}
      </CommandList>
    </Command>
  );
};
