import { cn } from '../utils';

export const LoadingIcon = ({
  className,
  width = 20,
  height = 20,
}: {
  className?: string;
  width?: number;
  height?: number;
}) => {
  return (
    <svg
      className={cn('animate-spin', className)}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
    >
      <circle
        cx="10"
        cy="10"
        r="8.5"
        fill="none"
        stroke="currentColor"
        strokeWidth="3"
        strokeOpacity=".2"
      />
      <circle
        cx="10"
        cy="10"
        r="8.5"
        fill="none"
        stroke="currentColor"
        strokeWidth="3"
        strokeDasharray="13.3525 40.0575"
      />
    </svg>
  );
};
