// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "activity_log.proto" (syntax proto3)
// tslint:disable
// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "activity_log.proto" (syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { WireType } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { User } from "./user";
// @generated message type with reflection information, may provide speed optimized methods
class ActivityLog$Type extends MessageType {
    constructor() {
        super("ActivityLog", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "activity_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "activity_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "property_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "contract_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "geolocation_lat", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 8, name: "geolocation_lng", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 9, name: "customer_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "task_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 11, name: "timesheet_line_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 12, name: "event_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 13, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 15, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "date_range", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 18, name: "with_user", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 19, name: "user", kind: "message", T: () => User },
            { no: 20, name: "timeoff_request_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 21, name: "date_target", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 22, name: "not_equals", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, userId: 0, activityName: "", activityDate: "", propertyId: 0, contractId: 0, geolocationLat: 0, geolocationLng: 0, customerId: 0, taskId: 0, timesheetLineId: 0, eventId: 0, fieldMask: [], pageNumber: 0, orderBy: "", orderDir: "", dateRange: [], withUser: false, timeoffRequestId: 0, dateTarget: "", notEquals: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 user_id */ 2:
                    message.userId = reader.int32();
                    break;
                case /* string activity_name */ 3:
                    message.activityName = reader.string();
                    break;
                case /* string activity_date */ 4:
                    message.activityDate = reader.string();
                    break;
                case /* int32 property_id */ 5:
                    message.propertyId = reader.int32();
                    break;
                case /* int32 contract_id */ 6:
                    message.contractId = reader.int32();
                    break;
                case /* double geolocation_lat */ 7:
                    message.geolocationLat = reader.double();
                    break;
                case /* double geolocation_lng */ 8:
                    message.geolocationLng = reader.double();
                    break;
                case /* int32 customer_id */ 9:
                    message.customerId = reader.int32();
                    break;
                case /* int32 task_id */ 10:
                    message.taskId = reader.int32();
                    break;
                case /* int32 timesheet_line_id */ 11:
                    message.timesheetLineId = reader.int32();
                    break;
                case /* int32 event_id */ 12:
                    message.eventId = reader.int32();
                    break;
                case /* repeated string field_mask */ 13:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 14:
                    message.pageNumber = reader.int32();
                    break;
                case /* string order_by */ 15:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 16:
                    message.orderDir = reader.string();
                    break;
                case /* repeated string date_range */ 17:
                    message.dateRange.push(reader.string());
                    break;
                case /* bool with_user */ 18:
                    message.withUser = reader.bool();
                    break;
                case /* User user */ 19:
                    message.user = User.internalBinaryRead(reader, reader.uint32(), options, message.user);
                    break;
                case /* int32 timeoff_request_id */ 20:
                    message.timeoffRequestId = reader.int32();
                    break;
                case /* string date_target */ 21:
                    message.dateTarget = reader.string();
                    break;
                case /* repeated string not_equals */ 22:
                    message.notEquals.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 user_id = 2; */
        if (message.userId !== 0)
            writer.tag(2, WireType.Varint).int32(message.userId);
        /* string activity_name = 3; */
        if (message.activityName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.activityName);
        /* string activity_date = 4; */
        if (message.activityDate !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.activityDate);
        /* int32 property_id = 5; */
        if (message.propertyId !== 0)
            writer.tag(5, WireType.Varint).int32(message.propertyId);
        /* int32 contract_id = 6; */
        if (message.contractId !== 0)
            writer.tag(6, WireType.Varint).int32(message.contractId);
        /* double geolocation_lat = 7; */
        if (message.geolocationLat !== 0)
            writer.tag(7, WireType.Bit64).double(message.geolocationLat);
        /* double geolocation_lng = 8; */
        if (message.geolocationLng !== 0)
            writer.tag(8, WireType.Bit64).double(message.geolocationLng);
        /* int32 customer_id = 9; */
        if (message.customerId !== 0)
            writer.tag(9, WireType.Varint).int32(message.customerId);
        /* int32 task_id = 10; */
        if (message.taskId !== 0)
            writer.tag(10, WireType.Varint).int32(message.taskId);
        /* int32 timesheet_line_id = 11; */
        if (message.timesheetLineId !== 0)
            writer.tag(11, WireType.Varint).int32(message.timesheetLineId);
        /* int32 event_id = 12; */
        if (message.eventId !== 0)
            writer.tag(12, WireType.Varint).int32(message.eventId);
        /* repeated string field_mask = 13; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(13, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 14; */
        if (message.pageNumber !== 0)
            writer.tag(14, WireType.Varint).int32(message.pageNumber);
        /* string order_by = 15; */
        if (message.orderBy !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 16; */
        if (message.orderDir !== "")
            writer.tag(16, WireType.LengthDelimited).string(message.orderDir);
        /* repeated string date_range = 17; */
        for (let i = 0; i < message.dateRange.length; i++)
            writer.tag(17, WireType.LengthDelimited).string(message.dateRange[i]);
        /* bool with_user = 18; */
        if (message.withUser !== false)
            writer.tag(18, WireType.Varint).bool(message.withUser);
        /* User user = 19; */
        if (message.user)
            User.internalBinaryWrite(message.user, writer.tag(19, WireType.LengthDelimited).fork(), options).join();
        /* int32 timeoff_request_id = 20; */
        if (message.timeoffRequestId !== 0)
            writer.tag(20, WireType.Varint).int32(message.timeoffRequestId);
        /* string date_target = 21; */
        if (message.dateTarget !== "")
            writer.tag(21, WireType.LengthDelimited).string(message.dateTarget);
        /* repeated string not_equals = 22; */
        for (let i = 0; i < message.notEquals.length; i++)
            writer.tag(22, WireType.LengthDelimited).string(message.notEquals[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ActivityLog
 */
export const ActivityLog = new ActivityLog$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ActivityLogList$Type extends MessageType {
    constructor() {
        super("ActivityLogList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ActivityLog },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated ActivityLog results */ 1:
                    message.results.push(ActivityLog.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated ActivityLog results = 1; */
        for (let i = 0; i < message.results.length; i++)
            ActivityLog.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ActivityLogList
 */
export const ActivityLogList = new ActivityLogList$Type();
/**
 * @generated ServiceType for protobuf service ActivityLogService
 */
export const ActivityLogService = new ServiceType("ActivityLogService", [
    { name: "Create", options: {}, I: ActivityLog, O: ActivityLog },
    { name: "Get", options: {}, I: ActivityLog, O: ActivityLog },
    { name: "BatchGet", options: {}, I: ActivityLog, O: ActivityLogList },
    { name: "BatchGetEventLogs", options: {}, I: ActivityLog, O: ActivityLogList },
    { name: "Update", options: {}, I: ActivityLog, O: ActivityLog },
    { name: "Delete", options: {}, I: ActivityLog, O: ActivityLog }
]);
