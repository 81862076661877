import { BaseClient } from "../BaseClient";
import { getMimeType } from "../Common";
import { Empty } from "../compiled-protos/common";
import { type Inspection, type Permit, PermitDocument, type PermitGroup, PermitGroupDocument, type PermitTrade } from "../compiled-protos/permit";
import { PermitServiceClient } from "../compiled-protos/permit.client";
import { File, FileClient } from "../File";
import { S3Client, URLObject } from "../S3File";

export class PermitClient extends BaseClient {
  self: PermitServiceClient;
  private S3: S3Client;
  private FileClient: FileClient;
  private permitsS3BucketName = 'kalos-permits'

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new PermitServiceClient(this.transport);
    this.S3 = new S3Client(host, userID);
    this.FileClient = new FileClient(host, userID);
  }

  /*
   * PermitGroup
   */
  public async BatchGetPermitGroup(input: PermitGroup) {
    const res = await this.self.batchGetPermitGroup(input, this.getMetaDataWithoutCache()).response;
    return res
  }
  public async GetPermitGroup(input: PermitGroup) {
    const res = await this.self.getPermitGroup(input, this.getMetaDataWithoutCache()).response;
    return res
  }

  public async CreatePermitGroup(input: PermitGroup) {
    const res = await this.self.createPermitGroup(input, this.getMetaData()).response;
    return res
  }

  public async UpdatePermitGroup(input: PermitGroup) {
    const res = await this.self.updatePermitGroup(input, this.getMetaData()).response;
    return res
  }

  public async DeletePermitGroup(input: PermitGroup) {
    const res = await this.self.deletePermitGroup(input, this.getMetaData()).response;
    return res
  }

  /**
   * PermitGroupDocument
   */
  public async _createPermitGroupDocument(input: PermitGroupDocument) {
    const res = await this.self.createPermitGroupDocument(input, this.getMetaData()).response
    return res
  }

  public async CreatePermitGroupDocument({ permitGroupId, fileName, fileData }: { permitGroupId: number, fileName: string, fileData: Uint8Array }) {
    const contentType = getMimeType(fileName);
    const fileKey = `permit-group-${permitGroupId}-${Date.now()}-${fileName}`;
    const urlObj = URLObject.create({
      bucket: this.permitsS3BucketName,
      key: fileKey,
      contentType: contentType,
    });
    const urlRes = await this.S3.GetUploadURL(urlObj);

    if (!urlRes) {
      throw new Error('Failed to get upload URL');
    }

    const uploadRes = await fetch(urlRes!.url, {
      body: fileData.buffer,
      method: 'PUT',
    });

    if (uploadRes.status !== 200) {
      throw new Error('Failed to upload file');
    }

    const fileObj = File.create({
      bucket: this.permitsS3BucketName,
      name: fileKey,
      mimeType: contentType,
    });
    const fileRes = await this.FileClient.Create(fileObj);

    const req = PermitGroupDocument.create({
      fileId: fileRes.id,
      permitGroupId: permitGroupId,
    });

    const docResult = await this._createPermitGroupDocument(req);
    return docResult;
  }

  public async DeletePermitGroupDocument(input: PermitGroupDocument) {
    const res = await this.self.deletePermitGroupDocument(input, this.getMetaData()).response;
    return res
  }

  public async GetDocumentDownloadURL({ filename, mimeType }: { filename: string, mimeType: string }) {
    const res = await this.S3.GetDownloadURL(URLObject.create({
      bucket: this.permitsS3BucketName,
      key: filename,
      contentType: mimeType,
    }));
    return res;
  }


  /*
   * Permit
   */
  public async BatchGetPermit(input: Permit) {
    const res = await this.self.batchGetPermit(input, this.getMetaData()).response;
    return res
  }
  public async GetPermit(input: Permit) {
    const res = await this.self.getPermit(input, this.getMetaData()).response;
    return res
  }

  public async CreatePermit(input: Permit) {
    const res = await this.self.createPermit(input, this.getMetaData()).response;
    return res
  }

  public async UpdatePermit(input: Permit) {
    const res = await this.self.updatePermit(input, this.getMetaData()).response;
    return res
  }

  public async DeletePermit(input: Permit) {
    const res = await this.self.deletePermit(input, this.getMetaData()).response;
    return res
  }
  /**
   * PermitDocument
   */
  public async _createPermitDocument(input: PermitDocument) {
    const res = await this.self.createPermitDocument(input, this.getMetaData()).response
    return res
  }

  public async CreatePermitDocument({ permitId, fileName, fileData }: { permitId: number, fileName: string, fileData: Uint8Array }) {
    const contentType = getMimeType(fileName);
    const fileKey = `permit-${permitId}-${Date.now()}-${fileName}`;
    const urlObj = URLObject.create({
      bucket: this.permitsS3BucketName,
      key: fileKey,
      contentType: contentType,
    });
    const urlRes = await this.S3.GetUploadURL(urlObj);

    if (!urlRes) {
      throw new Error('Failed to get upload URL');
    }

    const uploadRes = await fetch(urlRes!.url, {
      body: fileData.buffer,
      method: 'PUT',
    });

    if (uploadRes.status !== 200) {
      throw new Error('Failed to upload file');
    }

    const fileObj = File.create({
      bucket: this.permitsS3BucketName,
      name: fileKey,
      mimeType: contentType,
    });
    const fileRes = await this.FileClient.Create(fileObj);

    const req = PermitDocument.create({
      fileId: fileRes.id,
      permitId: permitId,
    });

    const docResult = await this._createPermitDocument(req);
    return docResult;
  }

  public async DeletePermitDocument(input: PermitDocument) {
    const res = await this.self.deletePermitDocument(input, this.getMetaData()).response;
    return res
  }

  /*
  * PermitStatus
  */
  public async BatchGetPermitStatuses() {
    const res = await this.self.batchGetPermitStatus({}, this.getMetaData()).response;
    return res
  }

  /*
  * PermitTrade
  */
  public async BatchGetPermitTrades(input: PermitTrade) {
    const res = await this.self.batchGetPermitTrade(input, this.getMetaData()).response;
    return res
  }

  /*
  * Inspection
  */
  public async BatchGetInspection(input: Inspection) {
    const res = await this.self.batchGetInspection(input, this.getMetaData()).response;
    return res
  }
  public async CreateInspection(input: Inspection) {
    const res = await this.self.createInspection(input, this.getMetaData()).response;
    return res
  }
  public async UpdateInspection(input: Inspection) {
    const res = await this.self.updateInspection(input, this.getMetaData()).response;
    return res
  }
  public async DeleteInspection(input: Inspection) {
    const res = await this.self.deleteInspection(input, this.getMetaData()).response;
    return res
  }
  /*
  * InspectionStatus
  */
  public async BatchGetInspectionStatus() {
    const res = await this.self.batchGetInspectionStatus(Empty.create(), this.getMetaData()).response;
    return res
  }

}
