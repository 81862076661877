import { useCallback, useLayoutEffect, useRef } from 'react';

export const useEvent = <T extends (...args: any[]) => any>(fn: T) => {
  const fnRef = useRef(fn);

  useLayoutEffect(() => {
    fnRef.current = fn;
  });

  const eventCallBack = useCallback(
    (...args: Parameters<T>) => fnRef.current.apply(null, args),
    [fnRef],
  );

  return eventCallBack;
};
