import { BaseClient } from '../BaseClient';
import {
  JobTypeSubtype,
  JobTypeSubtypeList,
} from '../compiled-protos/job_type';
import { JobTypeServiceClient as JobTypeSubtypeServiceClient } from '../compiled-protos/job_type.client';

class JobTypeSubtypeClient extends BaseClient {
  self: JobTypeSubtypeServiceClient;

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new JobTypeSubtypeServiceClient(this.transport);
  }

  public async Create(req: JobTypeSubtype) {
    let res = JobTypeSubtype.create();
    try {
      res = await this.self.createJobTypeSubtype(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Get(req: JobTypeSubtype) {
    let res = JobTypeSubtype.create();
    try {
      res = await this.self.getJobTypeSubtype(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Update(req: JobTypeSubtype) {
    let res = JobTypeSubtype.create();
    try {
      res = await this.self.updateJobTypeSubtype(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Delete(req: JobTypeSubtype) {
    let res = JobTypeSubtype.create();
    try {
      res = await this.self.deleteJobTypeSubtype(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async BatchGet(req: JobTypeSubtype) {
    let res = JobTypeSubtypeList.create();
    try {
      res = await this.self.batchGetJobTypeSubtype(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  /**
   * Returns loaded JobTypeSubtypes
   * @returns JobTypeSubtype[]
   */
  public async loadJobTypeSubtypes() {
    const req = JobTypeSubtype.create();
    req.withoutLimit = true;
    const res = await this.BatchGet(req);
    if (res) {
      return res.results;
    }
    return res;
  }
}

export { JobTypeSubtype, JobTypeSubtypeList, JobTypeSubtypeClient };
