import { BaseClient } from '../BaseClient';
import { TaskEvent, TaskEventList } from '../compiled-protos/task';
import { TaskServiceClient as TaskEventServiceClient } from '../compiled-protos/task.client';
class TaskEventClient extends BaseClient {
  self: TaskEventServiceClient;

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new TaskEventServiceClient(this.transport);
  }

  public async Create(req: TaskEvent) {
    let res = TaskEvent.create();
    try {
      res = await this.self.createTaskEvent(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Get(req: TaskEvent) {
    let res = TaskEvent.create();
    try {
      res = await this.self.getTaskEvent(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Update(req: TaskEvent) {
    let res = TaskEvent.create();
    try {
      res = await this.self.updateTaskEvent(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Delete(req: TaskEvent) {
    let res = TaskEvent.create();
    try {
      res = await this.self.deleteTaskEvent(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async BatchGet(req: TaskEvent) {
    let res = TaskEventList.create();
    try {
      res = await this.self.batchGetTaskEvent(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async deleteTaskEvent(id: number) {
    const req = TaskEvent.create();
    req.id = id;
    await this.Delete(req);
  }

  public upsertTaskEvent = async (data: TaskEvent) => {
    const id = data.id;
    if (id !== 0 && data.fieldMask.length === 0) {
      throw new Error(
        'Attempting to update entity without providing a field mask will result in a no op'
      );
    }
    return await this[id != 0 ? 'Update' : 'Create'](data);
  };

  public loadTaskEventsByFilter = async ({
    id,
    technicianUserId,
  }: {
    id: number;
    technicianUserId?: number;
    withTechnicianNames?: boolean;
  }) => {
    const req = TaskEvent.create();
    req.taskId = id;
    if (technicianUserId) {
      req.technicianUserId = technicianUserId;
    }
    req.isActive = true;
    req.pageNumber = 0;
    const results: TaskEvent[] = [];
    const res = await this.BatchGet(req);
    if (res) {
      const resultsList = res.results;
      const totalCount = res.totalCount;
      const len = resultsList.length;
      results.concat(...resultsList);
      if (totalCount > len) {
        const batchesAmount = Math.ceil((totalCount - len) / len);
        const batchResults = await Promise.all(
          Array.from(Array(batchesAmount)).map(async (_, idx) => {
            req.pageNumber = idx + 1;
            return (await this.self.batchGetTaskEvent(req)).response.results;
          })
        );
        results.push(
          ...batchResults.reduce((aggr, item) => [...aggr, ...item], [])
        );
      }
    }
    return results.sort((a, b) => {
      const A = a.timeStarted;
      const B = b.timeStarted;
      if (A < B) return 1;
      if (A > B) return -1;
      return 0;
    });
  };
}

const ENROUTE = 'Enroute';
const CHECKIN = 'Check In';
const CHECKOUT = 'Check Out';

export {
  TaskEvent,
  TaskEventList,
  TaskEventClient,
  ENROUTE,
  CHECKIN,
  CHECKOUT,
};
