import { type TransactionAccount, type TransactionLineItem } from '@kalos/kalos-rpc';
import { cn, Skeleton } from '@kalos/ui';
import { CheckCircledIcon, CrossCircledIcon } from '@radix-ui/react-icons';
import { useMemo } from 'react';
import { useStore } from 'zustand';

import {
  LineItemValidator,
  useTransactionLineItemsStore,
} from '../../../../../context/TransactionLIneItems';
import { useTransactionAccountBatchGet } from '../../../../../hooks/react-query/useTransactionAccountsQuery';
import {
  TransactionAccountComboboxPicker,
  transactionAccountFilter,
} from '../../../../ComponentsLibrary/Pickers/newPickers/QueryPickerV2';

export const CostCenterField = ({
  transactionLineItem,
}: {
  transactionLineItem: TransactionLineItem;
}) => {
  const store = useTransactionLineItemsStore();
  const isEditing = useStore(store, (s) => s.isEditing);

  const isQueryEnabled = transactionLineItem.costCenterId !== 0;

  const costCenterQuery = useTransactionAccountBatchGet({
    filter: transactionAccountFilter,
    select(data) {
      return data?.results.find((el) => el.id === transactionLineItem.costCenterId);
    },
    enabled: isQueryEnabled,
  });

  if (!isEditing) {
    if (isQueryEnabled) {
      return costCenterQuery.isLoading ? (
        <Skeleton className="w-12" />
      ) : (
        costCenterQuery.data?.description || ''
      );
    }

    if (!transactionLineItem.costCenterId) {
      return <span className="text-red-500">N/A</span>;
    }
  }

  return <EditableCostCenterField transactionLineItem={transactionLineItem} />;
};

const EditableCostCenterField = ({
  transactionLineItem,
}: {
  transactionLineItem: TransactionLineItem;
}) => {
  const store = useTransactionLineItemsStore();
  const setLineItems = useStore(store, (s) => s.setLineItems);

  const onChange = (newCostCenter: TransactionAccount | null) => {
    setLineItems((prevLineItems) => {
      return prevLineItems.map((li) => {
        if (li.id === transactionLineItem.id) {
          return { ...li, costCenterId: newCostCenter?.id ?? 0 };
        }
        return li;
      });
    });
  };

  const value = transactionLineItem.costCenterId;
  const isValid = useMemo(() => LineItemValidator.isCostCenterValid(value), [value]);

  return (
    <div className="flex flex-col gap-0.5">
      {!isValid && <div className="text-xs text-red-500">Please select Purchase Category!</div>}

      <TransactionAccountComboboxPicker
        triggerClassName={cn('text-xs', !isValid && 'border-red-500')}
        selected={value.toString()}
        onSelect={onChange}
        renderItem={(tA) => `${tA.id}-${tA.shortDescription}`}
      />
    </div>
  );
};

export const CostCenterEditHeader = () => {
  const store = useTransactionLineItemsStore();
  const isCostCentersValid = useStore(store, (s) => s.isCostCentersValid());

  return isCostCentersValid ? (
    <CheckCircledIcon className="size-6 text-emerald-500" />
  ) : (
    <CrossCircledIcon className="size-6 text-red-500" />
  );
};
