import { zodResolver } from '@hookform/resolvers/zod';
import { type SpiffOption } from '@kalos/kalos-rpc';
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
} from '@kalos/ui';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';

import { JobSelector } from '../../../../../components/JobSelector';
import { type DirtyModelFields, isMoneyValue } from '../../../../../tools/helpers';
import { type ZodObjectWithModelKeys } from '../../../../../tools/typeguargs';

type SpiffOptionFormFields = Pick<SpiffOption, 'amount' | 'code' | 'eventId' | 'name'>;
const spiffOptionSchema = z.object({
  amount: z.coerce
    .number()
    .refine(isMoneyValue, { message: 'Amount should be a valid money value' }),
  code: z.string(),
  eventId: z.number(),
  name: z.string().min(1, 'Name is required'),
}) satisfies ZodObjectWithModelKeys<SpiffOptionFormFields>;

export type SpiffOptionFormSchema = z.infer<typeof spiffOptionSchema>;

export const SpiffOptionForm = ({
  defaultValues,
  disabled,
  loading,
  onSubmit,
  disableJobIdPicker,
}: {
  defaultValues: SpiffOptionFormSchema;
  disabled?: boolean;
  loading?: boolean;
  onSubmit: (arg: {
    data: SpiffOptionFormSchema;
    dirtyFields: DirtyModelFields<SpiffOptionFormSchema>;
  }) => void;
  disableJobIdPicker?: boolean;
}) => {
  const form = useForm<SpiffOptionFormSchema>({
    resolver: zodResolver(spiffOptionSchema),
    defaultValues,
    disabled,
  });

  const _onSubmit: SubmitHandler<SpiffOptionFormSchema> = (data) => {
    onSubmit({ data, dirtyFields: form.formState.dirtyFields });
  };

  return (
    <form className="@sm:grid-cols-2 grid gap-2" onSubmit={form.handleSubmit(_onSubmit)}>
      <Form {...form}>
        <FormField
          control={form.control}
          name="amount"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Amount</FormLabel>
              <FormMessage />
              <FormControl>
                <Input {...field} type="number" />
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="code"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Code</FormLabel>
              <FormMessage />
              <FormControl>
                <Input {...field} />
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Name</FormLabel>
              <FormMessage />
              <FormControl>
                <Input {...field} />
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="eventId"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Job #</FormLabel>
              <FormMessage />
              <FormControl>
                <JobSelector
                  value={field.value}
                  onChange={field.onChange}
                  disabled={disableJobIdPicker || field.disabled}
                />
              </FormControl>
            </FormItem>
          )}
        />

        <Button className="@sm:col-span-2" isLoading={loading} disabled={disabled} type="submit">
          Submit
        </Button>
      </Form>
    </form>
  );
};
