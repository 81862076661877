import { type SpiffToolAdminAction, type Task } from '@kalos/kalos-rpc';
import { Dialog, DialogContent, DialogTitle, DialogTrigger, toast } from '@kalos/ui';
import { type ComponentProps, useCallback, useMemo, useState } from 'react';

import { SpiffToolAdminActionForm } from './SpiffToolAdminActionForm';
import { useCreateOrUpdateSpiffToolAdminAction } from './useCreateOrUpdateSpiffToolAdminAction';

export const SpiffToolAdminActionEditFormDialog = ({
  original,
  parentTask,
  trigger,
  onSave: onSaveProp,
  initialOpen = false,
}: {
  original: SpiffToolAdminAction;
  parentTask: Task;
  trigger: React.ReactNode;
  onSave?: () => void;
  initialOpen?: boolean;
}) => {
  const defaultValues = useMemo<ComponentProps<typeof SpiffToolAdminActionForm>['defaultValues']>(
    () => ({
      decisionDate: new Date(original.decisionDate),
      reviewedBy: original.reviewedBy,
      status: original.status,
      reason: original.reason,
    }),
    [original.decisionDate, original.reason, original.reviewedBy, original.status],
  );

  const updateAction = useCreateOrUpdateSpiffToolAdminAction({ parentTask });
  const [isLoading, setIsLoading] = useState(false);
  const [isOpened, setIsOpened] = useState(initialOpen);

  const onSave = useCallback<ComponentProps<typeof SpiffToolAdminActionForm>['onSubmit']>(
    async ({ data, dirtyFields }) => {
      setIsLoading(true);
      try {
        await updateAction({ adminAction: data, originalId: original.id, type: 'Update' });
        setIsLoading(false);
        setIsOpened(false);
        onSaveProp?.();
      } catch {
        toast({
          variant: 'destructive',
          title: 'Error updating action',
        });
      }
    },
    [onSaveProp, original.id, updateAction],
  );

  return (
    <Dialog open={isOpened} onOpenChange={setIsOpened}>
      <DialogContent className="max-h-screen max-w-xl overflow-auto">
        <DialogTitle>Edit status</DialogTitle>
        <SpiffToolAdminActionForm
          onSubmit={onSave}
          disabled={isLoading}
          loading={isLoading}
          defaultValues={defaultValues}
        />
      </DialogContent>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
    </Dialog>
  );
};
