// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "prompt_payment.proto" (syntax proto3)
// tslint:disable
// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "prompt_payment.proto" (syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { WireType } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
// @generated message type with reflection information, may provide speed optimized methods
class PromptPaymentOverride$Type extends MessageType {
    constructor() {
        super("PromptPaymentOverride", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "event_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "force_award", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "force_forfeit", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "reason", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, eventId: 0, forceAward: 0, forceForfeit: 0, reason: "", date: "", fieldMask: [], pageNumber: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 event_id */ 2:
                    message.eventId = reader.int32();
                    break;
                case /* int32 force_award */ 3:
                    message.forceAward = reader.int32();
                    break;
                case /* int32 force_forfeit */ 4:
                    message.forceForfeit = reader.int32();
                    break;
                case /* string reason */ 5:
                    message.reason = reader.string();
                    break;
                case /* string date */ 6:
                    message.date = reader.string();
                    break;
                case /* repeated string field_mask */ 7:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 8:
                    message.pageNumber = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 event_id = 2; */
        if (message.eventId !== 0)
            writer.tag(2, WireType.Varint).int32(message.eventId);
        /* int32 force_award = 3; */
        if (message.forceAward !== 0)
            writer.tag(3, WireType.Varint).int32(message.forceAward);
        /* int32 force_forfeit = 4; */
        if (message.forceForfeit !== 0)
            writer.tag(4, WireType.Varint).int32(message.forceForfeit);
        /* string reason = 5; */
        if (message.reason !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.reason);
        /* string date = 6; */
        if (message.date !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.date);
        /* repeated string field_mask = 7; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(7, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 8; */
        if (message.pageNumber !== 0)
            writer.tag(8, WireType.Varint).int32(message.pageNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PromptPaymentOverride
 */
export const PromptPaymentOverride = new PromptPaymentOverride$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PromptPaymentOverrideList$Type extends MessageType {
    constructor() {
        super("PromptPaymentOverrideList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PromptPaymentOverride },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated PromptPaymentOverride results */ 1:
                    message.results.push(PromptPaymentOverride.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated PromptPaymentOverride results = 1; */
        for (let i = 0; i < message.results.length; i++)
            PromptPaymentOverride.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PromptPaymentOverrideList
 */
export const PromptPaymentOverrideList = new PromptPaymentOverrideList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PromptPaymentRebate$Type extends MessageType {
    constructor() {
        super("PromptPaymentRebate", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "payment_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "event_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "amount", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 5, name: "date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, paymentId: 0, eventId: 0, amount: 0, date: "", fieldMask: [], pageNumber: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 payment_id */ 2:
                    message.paymentId = reader.int32();
                    break;
                case /* int32 event_id */ 3:
                    message.eventId = reader.int32();
                    break;
                case /* double amount */ 4:
                    message.amount = reader.double();
                    break;
                case /* string date */ 5:
                    message.date = reader.string();
                    break;
                case /* repeated string field_mask */ 6:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 7:
                    message.pageNumber = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 payment_id = 2; */
        if (message.paymentId !== 0)
            writer.tag(2, WireType.Varint).int32(message.paymentId);
        /* int32 event_id = 3; */
        if (message.eventId !== 0)
            writer.tag(3, WireType.Varint).int32(message.eventId);
        /* double amount = 4; */
        if (message.amount !== 0)
            writer.tag(4, WireType.Bit64).double(message.amount);
        /* string date = 5; */
        if (message.date !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.date);
        /* repeated string field_mask = 6; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(6, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 7; */
        if (message.pageNumber !== 0)
            writer.tag(7, WireType.Varint).int32(message.pageNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PromptPaymentRebate
 */
export const PromptPaymentRebate = new PromptPaymentRebate$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PromptPaymentRebateList$Type extends MessageType {
    constructor() {
        super("PromptPaymentRebateList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PromptPaymentRebate },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated PromptPaymentRebate results */ 1:
                    message.results.push(PromptPaymentRebate.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated PromptPaymentRebate results = 1; */
        for (let i = 0; i < message.results.length; i++)
            PromptPaymentRebate.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PromptPaymentRebateList
 */
export const PromptPaymentRebateList = new PromptPaymentRebateList$Type();
/**
 * @generated ServiceType for protobuf service PromptPaymentService
 */
export const PromptPaymentService = new ServiceType("PromptPaymentService", [
    { name: "CreatePromptPaymentOverride", options: {}, I: PromptPaymentOverride, O: PromptPaymentOverride },
    { name: "GetPromptPaymentOverride", options: {}, I: PromptPaymentOverride, O: PromptPaymentOverride },
    { name: "BatchGetPromptPaymentOverride", options: {}, I: PromptPaymentOverride, O: PromptPaymentOverrideList },
    { name: "UpdatePromptPaymentOverride", options: {}, I: PromptPaymentOverride, O: PromptPaymentOverride },
    { name: "DeletePromptPaymentOverride", options: {}, I: PromptPaymentOverride, O: PromptPaymentOverride },
    { name: "CreatePromptPaymentRebate", options: {}, I: PromptPaymentRebate, O: PromptPaymentRebate },
    { name: "GetPromptPaymentRebate", options: {}, I: PromptPaymentRebate, O: PromptPaymentRebate },
    { name: "BatchGetPromptPaymentRebate", options: {}, I: PromptPaymentRebate, O: PromptPaymentRebateList },
    { name: "UpdatePromptPaymentRebate", options: {}, I: PromptPaymentRebate, O: PromptPaymentRebate },
    { name: "DeletePromptPaymentRebate", options: {}, I: PromptPaymentRebate, O: PromptPaymentRebate }
]);
