import { zodResolver } from '@hookform/resolvers/zod';
import { type User } from '@kalos/kalos-rpc';
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Button,
  Checkbox,
  DateInput,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  PhoneInput,
  SimpleSelect,
} from '@kalos/ui';
import { useQuery } from '@tanstack/react-query';
import { useCallback, useMemo, useState } from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { type Country } from 'react-phone-number-input';
import { z } from 'zod';

import { MONTHS, USA_STATES_OPTIONS, WORKERS_COMP_CODES } from '../../../../../constants';
import { getEmployeeImageQueryConfig } from '../../../../../hooks/react-query/useEmployeesQuery';
import { type DirtyModelFields } from '../../../../../tools/helpers';
import { requiredPhoneNumberSchema } from '../../../../../tools/schemas';
import { renderUser, TechniciansComboboxPicker } from '../../../Pickers/newPickers/QueryPickerV2';
const employeeFormSchema = z.object({
  id: z.number().optional(),
  firstname: z.string().min(1, 'Required'),
  lastname: z.string().min(1, 'Required'),
  address: z.string().min(1, 'Required'),
  city: z.string(),
  zip: z.string().min(1, 'Required'),
  state: z.string(),
  empTitle: z.string(),
  employeeDepartmentID: z.number().min(1, 'Please select segment'),
  managedBy: z.number(),
  empBadgeId: z.number(),
  phone: requiredPhoneNumberSchema,
  cellphone: z.string(),
  ext: z.string().optional(),
  toolFund: z.number().nonnegative(),
  email: z.string().email(),
  phoneEmail: z.string().optional(),
  serviceCalls: z.number(),
  isAdmin: z.number(),
  paidServiceCallStatus: z.number(),
  showBilling: z.number(),
  isOfficeStaff: z.number(),
  isHvacTech: z.number(),
  techAssist: z.number(),
  isColorMute: z.number(),
  hireDate: z.date(),
  overrideMonth: z.string().optional(),
  ptoValue: z.number().optional(),
  image: z.any().optional(),
});

const allowedCountries: Country[] = ['US'];

export type EmployeeFormType = z.infer<typeof employeeFormSchema>;

const defaultFormValues: EmployeeFormType = {
  firstname: '',
  lastname: '',
  address: '',
  city: '',
  zip: '',
  state: '',
  empTitle: '',
  employeeDepartmentID: 0,
  managedBy: 0,
  empBadgeId: 0,
  phone: '',
  cellphone: '',
  ext: '',
  toolFund: 0,
  email: '',
  phoneEmail: '',
  serviceCalls: 0,
  isAdmin: 0,
  paidServiceCallStatus: 0,
  showBilling: 0,
  isOfficeStaff: 0,
  isHvacTech: 0,
  techAssist: 0,
  isColorMute: 0,
  hireDate: new Date(),
  overrideMonth: '',
  ptoValue: 0,
  image: undefined,
};

interface EmployeeFormProps {
  isAdmin: boolean;
  isHR: boolean;
  userQuery: { data: { id: number } | undefined };
  entry: User;
  limitedAccess?: boolean;
  pendingEmployeeEditing?: { id: number } | undefined;
  departmentsQuery: { data?: { results: { id: number; value: string; description: string }[] } };
  associatedPto?: { totalHours: number } | null;
  saving: boolean;
  onSave: (
    data: EmployeeFormType,
    dirtyFields: DirtyModelFields<EmployeeFormType>,
    hireDate: Date,
    file: string | undefined,
  ) => void;
}

export const getEmployeeFormDefaults = ({
  defaultValues,
}: {
  defaultValues?: Partial<EmployeeFormType>;
}): EmployeeFormType => {
  return {
    ...defaultFormValues,
    ...defaultValues,
  };
};

export const useEmployeeForm = ({
  defaultValues,
  disabled,
}: {
  defaultValues: Partial<EmployeeFormType>;
  disabled?: boolean;
}) => {
  return useForm<EmployeeFormType>({
    resolver: zodResolver(employeeFormSchema),
    mode: 'onSubmit',
    defaultValues: getEmployeeFormDefaults({ defaultValues }),
  });
};

export const EmployeeForm: React.FC<EmployeeFormProps> = ({
  isAdmin,
  userQuery,
  entry,
  limitedAccess,
  pendingEmployeeEditing,
  departmentsQuery,
  associatedPto,
  isHR,
  saving,
  onSave,
}) => {
  const defaultValues = useMemo(
    () =>
      getEmployeeFormDefaults({
        defaultValues: {
          ...entry,
          hireDate: entry.hireDate ? new Date(entry.hireDate) : new Date(),
        },
      }),
    [entry],
  );
  const form = useEmployeeForm({ defaultValues: defaultValues });

  const [employeeUploadedPhoto, setEmployeeUploadedPhoto] = useState<string | undefined>();

  const handleEmployeePhotoUpload = useCallback(
    (file: File) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        setEmployeeUploadedPhoto(reader.result as string);
        form.setValue('image', file.name);
      };
      reader.readAsDataURL(file);
    },
    [form],
  );

  const handleDeleteEmployeePhoto = useCallback(() => {
    setEmployeeUploadedPhoto(undefined);
    form.setValue('image', '');
  }, [form]);
  const imageQuery = useQuery({
    ...getEmployeeImageQueryConfig({ imageUrl: entry?.image || '' }),
    enabled: !!pendingEmployeeEditing,
  });
  const onSubmitForm: SubmitHandler<EmployeeFormType> = useCallback(
    (data) => {
      onSave(data, form.formState.dirtyFields, data.hireDate, employeeUploadedPhoto);
    },
    [form.formState.dirtyFields, onSave, employeeUploadedPhoto],
  );
  return (
    <Form {...form}>
      <form className="space-y-4" onSubmit={form.handleSubmit(onSubmitForm)}>
        <div className="flex justify-end space-x-4 border-b pb-4">
          <Button type="submit" disabled={saving} isLoading={saving}>
            Save
          </Button>
        </div>
        <div>
          <h3 className="text-2xl font-semibold tracking-wide">Employee Information</h3>
          <div className="grid grid-cols-1 gap-6 rounded-lg border bg-white p-6 shadow sm:grid-cols-2 md:grid-cols-3">
            <FormField
              control={form.control}
              name="firstname"
              render={({ field }) => (
                <FormItem>
                  <div className="flex flex-wrap items-center gap-2.5">
                    <FormLabel className="block text-sm font-medium text-gray-700">
                      First Name
                    </FormLabel>
                    <FormMessage />
                  </div>
                  <FormControl>
                    <Input
                      {...field}
                      className="mt-1 block w-full"
                      disabled={!isAdmin && !limitedAccess && !isHR}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="lastname"
              render={({ field }) => (
                <FormItem>
                  <div className="flex flex-wrap items-center gap-2.5">
                    <FormLabel className="block text-sm font-medium text-gray-700">
                      Last Name
                    </FormLabel>
                    <FormMessage />
                  </div>
                  <FormControl>
                    <Input
                      {...field}
                      className="mt-1 block w-full"
                      disabled={!isAdmin && !limitedAccess && !isHR}
                    />
                  </FormControl>
                </FormItem>
              )}
            />

            <div className="col-span-full">
              <div className="grid grid-cols-3 gap-4 md:grid-cols-6">
                <FormField
                  control={form.control}
                  name="address"
                  render={({ field }) => (
                    <FormItem className="col-span-3">
                      <div className="flex flex-wrap items-center gap-2.5">
                        <FormLabel className="block text-sm font-medium text-gray-700">
                          Street Address
                        </FormLabel>
                        <FormMessage />
                      </div>
                      <FormControl>
                        <Input {...field} disabled={!isAdmin && !limitedAccess && !isHR} />
                      </FormControl>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="city"
                  render={({ field }) => (
                    <FormItem className="col-span-1">
                      <div className="flex flex-wrap items-center gap-2.5">
                        <FormLabel className="block text-sm font-medium text-gray-700">
                          City
                        </FormLabel>
                        <FormMessage />
                      </div>
                      <FormControl>
                        <Input
                          {...field}
                          className="mt-1 block w-full"
                          disabled={!isAdmin && !limitedAccess && !isHR}
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="state"
                  render={({ field }) => (
                    <FormItem className="col-span-1">
                      <FormLabel className="block text-sm font-medium text-gray-700">
                        State
                      </FormLabel>
                      <SimpleSelect
                        placeholder="State"
                        onChange={(value) => field.onChange(value)}
                        disabled={!isAdmin && !limitedAccess && !isHR}
                        selectedValue={field.value.toString()}
                        values={USA_STATES_OPTIONS.map((option) => ({
                          label: option.toString(),
                          value: option.toString(),
                        }))}
                      />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="zip"
                  render={({ field }) => (
                    <FormItem className="col-span-1">
                      <div className="flex flex-wrap items-center gap-2.5">
                        <FormLabel className="block text-sm font-medium text-gray-700">
                          Zip
                        </FormLabel>
                        <FormMessage />
                      </div>
                      <FormControl>
                        <Input
                          {...field}
                          className="mt-1 block w-full"
                          disabled={
                            !isAdmin &&
                            userQuery.data?.id !== entry.id &&
                            limitedAccess == undefined
                          }
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
              </div>
            </div>
            <FormField
              control={form.control}
              name="phone"
              render={({ field }) => (
                <FormItem>
                  <div className="flex flex-wrap items-center gap-2.5">
                    <FormLabel className="block text-sm font-medium text-gray-700">
                      Primary Phone
                    </FormLabel>
                    <FormMessage />
                  </div>
                  <FormControl>
                    <PhoneInput
                      {...field}
                      countries={allowedCountries}
                      defaultCountry="US"
                      disabled={!isAdmin && !limitedAccess && !isHR}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="cellphone"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="block text-sm font-medium text-gray-700">
                    Cell Phone
                  </FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      className="mt-1 block w-full"
                      disabled={!isAdmin && !limitedAccess && !isHR}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="ext"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="block text-sm font-medium text-gray-700">Ext</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      className="mt-1 block w-full"
                      disabled={!isAdmin && !limitedAccess && !isHR}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <div className="flex flex-wrap items-center gap-2.5">
                    <FormLabel className="block text-sm font-medium text-gray-700">Email</FormLabel>
                    <FormMessage />
                  </div>
                  <FormControl>
                    <Input
                      {...field}
                      className="mt-1 block w-full"
                      disabled={!isAdmin && !limitedAccess && !isHR}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="phoneEmail"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="block text-sm font-medium text-gray-700">
                    Email-to-SMS
                  </FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      className="mt-1 block w-full"
                      disabled={!isAdmin && !limitedAccess && !isHR}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
          </div>
        </div>

        <div>
          <h3 className="text-2xl font-semibold tracking-wide">Kalos Information</h3>
          <div className="grid grid-cols-1 gap-6 rounded-lg border bg-gray-50 p-6 shadow sm:grid-cols-2 md:grid-cols-3">
            <FormField
              control={form.control}
              name="empTitle"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="block text-sm font-medium text-gray-700">Title</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      className="mt-1 block w-full"
                      disabled={!isAdmin && !limitedAccess && !isHR}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="employeeDepartmentID"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="block text-sm font-medium text-gray-700">
                    Employee Segment
                  </FormLabel>
                  <SimpleSelect
                    placeholder="Select Employee Segment"
                    onChange={(value) => field.onChange(Number(value))}
                    disabled={!isHR}
                    selectedValue={field.value.toString()}
                    values={
                      departmentsQuery.data?.results.map((d) => ({
                        label: `${d.value} - ${d.description}`,
                        value: d.id.toString(),
                      })) ?? []
                    }
                  />
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="managedBy"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="block text-sm font-medium text-gray-700">
                    Assigned Supervisor
                  </FormLabel>
                  <TechniciansComboboxPicker
                    placeholder="Select"
                    disabled={!isAdmin && !limitedAccess && !isHR}
                    onSelect={(user) => field.onChange(user?.id ?? 0)}
                    selected={field.value.toString()}
                    renderItem={renderUser}
                  />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="empBadgeId"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="block text-sm font-medium text-gray-700">
                    Workers Comp Code
                  </FormLabel>
                  <FormControl>
                    <SimpleSelect
                      placeholder="Select Workers Comp Code"
                      onChange={(value) => field.onChange(Number(value))}
                      disabled={!isAdmin && !limitedAccess && !isHR}
                      selectedValue={field.value.toString()}
                      values={[
                        { label: 'Select Workers Comp Code', value: '0' },
                        ...WORKERS_COMP_CODES.map((code) => ({
                          label: `WC-${code.label}`,
                          value: code.value.toString(),
                        })),
                      ]}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="toolFund"
              render={({ field }) => (
                <FormItem className="flex flex-col justify-between">
                  <FormLabel>Tool Fund</FormLabel>
                  <FormControl>
                    <Input
                      disabled={!isAdmin && !limitedAccess && !isHR}
                      type="number"
                      {...field}
                      onChange={(e) => field.onChange(+e.target.value)}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>

        <div>
          <h3 className="text-2xl font-semibold tracking-wide">Legacy Permissions</h3>
          <div className="grid grid-cols-1 gap-6 rounded-lg border bg-white p-6 shadow sm:grid-cols-2 md:grid-cols-3">
            <FormField
              control={form.control}
              name="serviceCalls"
              render={({ field }) => (
                <FormItem className="flex items-center space-x-2 space-y-0">
                  <FormLabel className="text-sm font-medium text-gray-700">Runs Jobs</FormLabel>
                  <FormDescription className="text-xs text-gray-500">Yes/No</FormDescription>
                  <Checkbox
                    checked={field.value === 1}
                    onCheckedChange={(checked) => field.onChange(checked ? 1 : 0)}
                    disabled={!isAdmin}
                  />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="isAdmin"
              render={({ field }) => (
                <FormItem className="flex items-center space-x-2 space-y-0">
                  <FormLabel className="text-sm font-medium text-gray-700">
                    Admin Menu Rights
                  </FormLabel>
                  <FormDescription className="text-xs text-gray-500">Yes/No</FormDescription>
                  <Checkbox
                    checked={field.value === 1}
                    onCheckedChange={(checked) => field.onChange(checked ? 1 : 0)}
                    disabled={!isAdmin}
                  />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="paidServiceCallStatus"
              render={({ field }) => (
                <FormItem className="flex items-center space-x-2 space-y-0">
                  <FormLabel className="text-sm font-medium text-gray-700">
                    Paid Job Status
                  </FormLabel>
                  <FormDescription className="text-xs text-gray-500">Yes/No</FormDescription>
                  <Checkbox
                    checked={field.value === 1}
                    onCheckedChange={(checked) => field.onChange(checked ? 1 : 0)}
                    disabled={!isAdmin}
                  />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="showBilling"
              render={({ field }) => (
                <FormItem className="flex items-center space-x-2 space-y-0">
                  <FormLabel className="text-sm font-medium text-gray-700">
                    Show billing to user
                  </FormLabel>
                  <FormDescription className="text-xs text-gray-500">Yes/No</FormDescription>
                  <Checkbox
                    checked={field.value === 1}
                    onCheckedChange={(checked) => field.onChange(checked ? 1 : 0)}
                    disabled={!isAdmin}
                  />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="isOfficeStaff"
              render={({ field }) => (
                <FormItem className="flex items-center space-x-2 space-y-0">
                  <FormLabel className="text-sm font-medium text-gray-700">Office Staff</FormLabel>
                  <FormDescription className="text-xs text-gray-500">Yes/No</FormDescription>
                  <Checkbox
                    checked={field.value === 1}
                    onCheckedChange={(checked) => field.onChange(checked ? 1 : 0)}
                    disabled={!isAdmin}
                  />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="isHvacTech"
              render={({ field }) => (
                <FormItem className="flex items-center space-x-2 space-y-0">
                  <FormLabel className="text-sm font-medium text-gray-700">
                    Display On Dispatch?
                  </FormLabel>
                  <FormDescription className="text-xs text-gray-500">Yes/No</FormDescription>
                  <Checkbox
                    checked={field.value === 1}
                    onCheckedChange={(checked) => field.onChange(checked ? 1 : 0)}
                    disabled={!isAdmin}
                  />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="techAssist"
              render={({ field }) => (
                <FormItem className="flex items-center space-x-2 space-y-0">
                  <FormLabel className="text-sm font-medium text-gray-700">Tech Assist</FormLabel>
                  <FormDescription className="text-xs text-gray-500">Yes/No</FormDescription>
                  <Checkbox
                    checked={field.value === 1}
                    onCheckedChange={(checked) => field.onChange(checked ? 1 : 0)}
                    disabled={!isAdmin}
                  />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="isColorMute"
              render={({ field }) => (
                <FormItem className="flex items-center space-x-2 space-y-0">
                  <FormLabel className="text-sm font-medium  text-gray-700">
                    Color Mute [2017]
                  </FormLabel>
                  <FormDescription className="text-xs text-gray-500">Yes/No</FormDescription>
                  <Checkbox
                    checked={field.value === 1}
                    onCheckedChange={(checked) => field.onChange(checked ? 1 : 0)}
                    disabled={userQuery.data?.id !== entry.id}
                  />
                </FormItem>
              )}
            />
          </div>
        </div>

        <div className="mt-4 grid grid-cols-2 gap-2 overflow-hidden rounded-lg border bg-white p-4 shadow md:grid-cols-3 md:gap-3">
          <FormField
            control={form.control}
            name="hireDate"
            render={({ field }) => (
              <FormItem className="col-span-2 sm:col-span-1">
                <FormLabel>Hire Date</FormLabel>

                <DateInput
                  value={new Date(field.value)}
                  disabled={!isHR}
                  onChange={field.onChange}
                />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="overrideMonth"
            render={({ field }) => (
              <FormItem className="col-span-2 sm:col-span-1">
                <FormLabel>Override Hire Month</FormLabel>
                <SimpleSelect
                  placeholder="Select Month"
                  disabled={!isHR}
                  onChange={(value) => field.onChange(Number(value))}
                  selectedValue={field.value?.toString()}
                  values={MONTHS.map((month, index) => ({
                    label: month,
                    value: (index + 1).toString(),
                    key: index + 1,
                  }))}
                />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="ptoValue"
            render={({ field }) => (
              <FormItem className="col-span-2 sm:col-span-1">
                <FormLabel>Currently Allocated PTO</FormLabel>
                <FormControl>
                  <Input
                    type="number"
                    {...field}
                    disabled={true}
                    value={associatedPto?.totalHours ?? 0}
                  />
                </FormControl>
              </FormItem>
            )}
          />
        </div>
        <div>
          <h3 className="text-2xl font-semibold tracking-wide">Employee Photo</h3>

          <div className="flex items-center space-x-6">
            <Avatar className="h-40 w-40">
              <AvatarImage
                className="object-cover"
                src={employeeUploadedPhoto || imageQuery.data}
              />
              <AvatarFallback>{`${entry.firstname.at(0)?.toUpperCase() || ''} ${
                entry.lastname.at(0)?.toUpperCase() || ''
              }`}</AvatarFallback>
            </Avatar>
            <div>
              <FormField
                control={form.control}
                name="image"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                          const file = e.target.files?.[0];
                          if (file) {
                            handleEmployeePhotoUpload(file);
                          }
                        }}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
              {(employeeUploadedPhoto || entry.image) && (
                <Button variant="outline" className="mt-2" onClick={handleDeleteEmployeePhoto}>
                  Delete Photo
                </Button>
              )}
            </div>
          </div>
        </div>
      </form>
    </Form>
  );
};
