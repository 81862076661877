// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "api_key.proto" (syntax proto3)
// tslint:disable
// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "api_key.proto" (syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { WireType } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
// @generated message type with reflection information, may provide speed optimized methods
class ApiKey$Type extends MessageType {
    constructor() {
        super("ApiKey", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "text_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "api_key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "api_endpoint", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "api_description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "api_user", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "expire_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, textId: "", apiKey: "", apiEndpoint: "", apiDescription: "", apiUser: 0, expireDate: "", fieldMask: [], pageNumber: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string text_id */ 2:
                    message.textId = reader.string();
                    break;
                case /* string api_key */ 3:
                    message.apiKey = reader.string();
                    break;
                case /* string api_endpoint */ 4:
                    message.apiEndpoint = reader.string();
                    break;
                case /* string api_description */ 5:
                    message.apiDescription = reader.string();
                    break;
                case /* int32 api_user */ 6:
                    message.apiUser = reader.int32();
                    break;
                case /* string expire_date */ 7:
                    message.expireDate = reader.string();
                    break;
                case /* repeated string field_mask */ 8:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 9:
                    message.pageNumber = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string text_id = 2; */
        if (message.textId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.textId);
        /* string api_key = 3; */
        if (message.apiKey !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.apiKey);
        /* string api_endpoint = 4; */
        if (message.apiEndpoint !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.apiEndpoint);
        /* string api_description = 5; */
        if (message.apiDescription !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.apiDescription);
        /* int32 api_user = 6; */
        if (message.apiUser !== 0)
            writer.tag(6, WireType.Varint).int32(message.apiUser);
        /* string expire_date = 7; */
        if (message.expireDate !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.expireDate);
        /* repeated string field_mask = 8; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(8, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 9; */
        if (message.pageNumber !== 0)
            writer.tag(9, WireType.Varint).int32(message.pageNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ApiKey
 */
export const ApiKey = new ApiKey$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ApiKeyList$Type extends MessageType {
    constructor() {
        super("ApiKeyList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ApiKey },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated ApiKey results */ 1:
                    message.results.push(ApiKey.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated ApiKey results = 1; */
        for (let i = 0; i < message.results.length; i++)
            ApiKey.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ApiKeyList
 */
export const ApiKeyList = new ApiKeyList$Type();
/**
 * @generated ServiceType for protobuf service ApiKeyService
 */
export const ApiKeyService = new ServiceType("ApiKeyService", [
    { name: "Create", options: {}, I: ApiKey, O: ApiKey },
    { name: "Get", options: {}, I: ApiKey, O: ApiKey },
    { name: "BatchGet", options: {}, I: ApiKey, O: ApiKeyList },
    { name: "Update", options: {}, I: ApiKey, O: ApiKey },
    { name: "Delete", options: {}, I: ApiKey, O: ApiKey }
]);
