import { BaseClient } from '../BaseClient';
import { Empty } from '../compiled-protos/common';
import {
  type MergeTransactionIds
,
  TransactionActivity,
  TransactionActivityList,
} from '../compiled-protos/transaction';
import { TransactionServiceClient } from '../compiled-protos/transaction.client';

class TransactionActivityClient extends BaseClient {
  self: TransactionServiceClient;
  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new TransactionServiceClient(this.transport);
  }

  public async Create(req: TransactionActivity) {
    let res = TransactionActivity.create();
    try {
      res = await this.self.createTransactionActivity(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Get(req: TransactionActivity) {
    let res = TransactionActivity.create();
    try {
      res = await this.self.getTransactionActivity(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Update(req: TransactionActivity) {
    let res = TransactionActivity.create();
    try {
      res = await this.self.updateTransactionActivity(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async MergeTransactionLogs(req: MergeTransactionIds) {
    let res = Empty.create();
    try {
      res = await this.self.mergeTransactionActivityLogs(
        req,
        this.getMetaData()
      ).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Delete(req: TransactionActivity) {
    let res = TransactionActivity.create();
    try {
      res = await this.self.deleteTransactionActivity(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async BatchGet(req: TransactionActivity) {
    let res = TransactionActivityList.create();
    try {
      res = await this.self.batchGetTransactionActivity(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }
}

export {
  TransactionActivity,
  TransactionActivityList,
  TransactionActivityClient,
};
