import { type Property } from '@kalos/kalos-rpc';
import compact from 'lodash/compact';
import { type FC } from 'react';

import { formatDate, usd } from '../../../../tools/helpers';
import { PrintPage } from '../../PrintPage';
import { PrintParagraph } from '../../PrintParagraph';
import { PrintTable } from '../../PrintTable';

interface Props {
  displayName: string;
  notes?: string;
  entries: {
    diagnosis?: string;
    description: string;
    price: number;
  }[];
  logJobNumber: string;
  property: Property;
  withDiagnosis?: boolean;
  onFileCreated?: (file: Uint8Array) => void;
  downloadFile?: boolean;
  downloadPDdfFileName: string;
  uploadBucket?: string;
}

export const ProposalPrint: FC<Props> = ({
  displayName,
  notes,
  logJobNumber,
  entries,
  property,
  withDiagnosis = false,
  uploadBucket,
  onFileCreated,
  downloadPDdfFileName,
  downloadFile,
}) => (
  <PrintPage
    headerProps={{
      withKalosAddress: true,
      withKalosContact: true,
      bigLogo: true,
    }}
    buttonProps={{ label: 'Print Preview' }}
    downloadPdfFilename={downloadPDdfFileName}
    downloadLabel="Download PDF Preview"
    onFileCreated={onFileCreated}
    downloadFile={downloadFile}
    uploadBucket={uploadBucket ? uploadBucket : 'testbuckethelios'}
  >
    <PrintParagraph>
      Date: {formatDate(new Date().toISOString())}
      <br />
      Job Number: {logJobNumber}
    </PrintParagraph>
    <PrintParagraph tag="h2">
      {displayName}
      <br />
      {property.address}
      <br />
      {compact([property.city, property.state, property.zip]).join(', ')}
    </PrintParagraph>
    <PrintParagraph tag="h1" align="center">
      Proposed Services
    </PrintParagraph>
    {notes && (
      <PrintTable
        columns={['Notes']}
        data={notes
          .split('\n')
          .filter((n) => !!n)
          .map((n) => [n])}
      />
    )}
    <PrintTable
      columns={[
        ...(withDiagnosis ? ['Diagnosis'] : []),
        'Description of Repair',
        { title: 'Price', align: 'right' },
      ]}
      data={entries.map(({ diagnosis, description, price }) => [
        ...(withDiagnosis ? [diagnosis] : []),
        description,
        usd(price),
      ])}
      nowraps={[false, true]}
    />

    <PrintParagraph
      style={{
        margin: 10,
        fontSize: 9,
        pageBreakBefore: 'always',
        flexDirection: 'row',
        fontWeight: 'black',
        textAlign: 'center',
        alignItems: 'center',
      }}
    >
      <b>TERMS AND CONDITIONS</b>
    </PrintParagraph>
    <PrintParagraph
      style={{
        margin: 10,
        fontSize: 9,
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      Kalos Services, Inc. ({'"'}Kalos{'"'}) warrants to the Purchaser that all services provided
      will be in conformance with this Agreement. Kalos agrees to furnish and install the equipment
      and materials as described above on the terms and conditions provided herein and Purchaser
      hereby accepts the equipment and services described above and agrees to pay Kalos the price
      shown above upon completion of the installation. Materials and work in addition to that
      described herein will be furnished only on Purchaser{"'"}s authorization and will be paid by
      Purchaser as an extra charge.
    </PrintParagraph>
    <PrintParagraph
      style={{
        margin: 10,
        fontSize: 9,
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      Failure to pay any sums due hereunder, Purchaser agrees to pay Kalos interest at the rate of
      one and one-half percent ((1<sup>1</sup>/<sub>2</sub>
      {'%'}) ) per month or the maximum permitted by law (whichever is less) on all outstanding
      balances. In the event that there is any controversy or claim arising out of or relating to
      this Agreement, or to the interpretation, breach, or enforcement thereof, and any action or
      proceeding is commenced to enforce the provisions of this Agreement, Kalos shall be entitled
      to reasonable attorney{"'"}s fee, costs, and expenses. Any reversal or dispute of charges with
      a bank, credit card, or financing company will be considered a breach of this agreement, and
      all warranty obligations will be null and void and payment will be sent to collections and a
      lien placed on the property according to Florida law.
    </PrintParagraph>
    <PrintParagraph
      style={{
        margin: 10,
        fontSize: 12,
        textAlign: 'center',
        flexDirection: 'row',

        alignItems: 'center',
      }}
    >
      FLORIDA HOMEOWNERS{"'"} CONSTRUCTION RECOVERY FUND
    </PrintParagraph>
    <PrintParagraph
      style={{
        margin: 10,
        fontSize: 12,

        flexDirection: 'row',

        alignItems: 'center',
      }}
    >
      PAYMENT, UP TO A LIMITED AMOUNT, MAY BE AVAILABLE FROM THE FLORIDA HOMEOWNERS{"'"}{' '}
      CONSTRUCTION RECOVERY FUND IF YOU LOSE MONEY ON A PROJECT PERFORMED UNDER CONTRACT, WHERE THE
      LOSS RESULTS FROM SPECIFIED VIOLATIONS OF FLORIDA LAW BY A LICENSED CONTRACTOR. FOR
      INFORMATION ABOUT THE RECOVERY FUND AND FILING A CLAIM, CONTACT THE FLORIDA CONSTRUCTION
      INDUSTRY LICENSING BOARD AT THE FOLLOWING TELEPHONE NUMBER AND ADDRESS: 850-487-1395, 1940
      NORTH MONROE ST., TALLAHASSEE, FLORIDA 32399
    </PrintParagraph>
    <PrintParagraph
      style={{
        margin: 10,
        fontSize: 12,
        fontWeight: 'black',

        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <b>
        ACCORDING TO FLORIDA{"'"}S CONSTRUCTION LIEN LAW (SECTIONS 713.001-713.37, FLORIDA
        STATUTES), THOSE WHO WORK ON YOUR PROPERTY OR PROVIDE MATERIALS AND SERVICES AND ARE NOT
        PAID IN FULL HAVE A RIGHT TO ENFORCE THEIR CLAIM FOR PAYMENT AGAINST YOUR PROPERTY. THIS
        CLAIM IS KNOWN AS A CONSTRUCTION LIEN. IF YOUR CONTRACTOR OR A SUBCONTRACTOR FAILS TO PAY
        SUBCONTRACTORS, SUB-SUBCONTRACTORS, OR MATERIAL SUPPLIERS, THOSE PEOPLE WHO ARE OWED MONEY
        MAY LOOK TO YOUR PROPERTY FOR PAYMENT, EVEN IF YOU HAVE ALREADY PAID YOUR CONTRACTOR IN
        FULL. IF YOU FAIL TO PAY YOUR CONTRACTOR, YOUR CONTRACTOR MAY ALSO HAVE A LIEN ON YOUR
        PROPERTY. THIS MEANS IF A LIEN IS FILED YOUR PROPERTY COULD BE SOLD AGAINST YOUR WILL TO PAY
        FOR LABOR, MATERIALS, OR OTHER SERVICES THAT YOUR CONTRACTOR OR A SUBCONTRACTOR MAY HAVE
        FAILED TO PAY. TO PROTECT YOURSELF, YOU SHOULD STIPULATE IN THIS CONTRACT THAT BEFORE ANY
        PAYMENT IS MADE, YOUR CONTRACTOR IS REQUIRED TO PROVIDE YOU WITH A WRITTEN RELEASE OF LIEN
        FROM ANY PERSON OR COMPANY THAT HAS PROVIDED TO YOU A {'"'}NOTICE TO OWNER.{'"'} FLORIDA
        {"'"}S CONSTRUCTION LIEN LAW IS COMPLEX, AND IT IS RECOMMENDED THAT YOU CONSULT AN ATTORNEY.
      </b>
    </PrintParagraph>
    <PrintParagraph
      style={{
        margin: 10,
        fontSize: 12,
        fontWeight: 'black',
        textAlign: 'center',

        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <b>
        {'"'}ANY CLAIMS FOR CONSTRUCTION DEFECTS ARE SUBJECT TO THE NOTICE AND CURE PROVISIONS OF
        CHAPTER 558, FLORIDA STATUTES.{'"'}
      </b>
    </PrintParagraph>
    <PrintParagraph
      style={{
        margin: 10,
        fontSize: 9,
        flexDirection: 'row',

        alignItems: 'center',
      }}
    >
      Purchaser shall permit Kalos reasonable access to the property on which equipment is to be
      installed. Title to all provided equipment remains with Kalos until all amounts due thereon
      are paid in full, whether such equipment is affixed to the realty or not, and shall remain
      personal property and be deemed severable without injury to the freehold.
    </PrintParagraph>
    <PrintParagraph
      style={{
        margin: 10,
        fontSize: 9,
        flexDirection: 'row',

        alignItems: 'center',
      }}
    >
      Purchaser shall indemnify and hold harmless Kalos from and against all claims, damages,
      losses, and expenses arising out of or resulting from acts or omissions of Kalos, Kalos
      representatives and subcontractors, or otherwise arising out of the performance of services by
      Kalos. Further, Purchaser agrees to hold harmless Kalos, its employees, managers, and
      leadership of all claims related to viruses, bacteria, and fungus past, present, and future.
    </PrintParagraph>
    <PrintParagraph
      style={{
        margin: 10,
        fontSize: 9,
        flexDirection: 'row',

        alignItems: 'center',
      }}
    >
      If performance of this Agreement or any obligation under this Agreement is prevented,
      restricted, or interfered with by causes beyond Kalos{'"'} reasonable control ({'"'}Force
      Majeure{'"'}), and if Kalos is unable to carry out its obligations, then the obligations of
      the party invoking this provision shall be suspended to the extent necessary by such event.
      The term Force Majeure shall include, without limitation, acts of God, plague, epidemic,
      pandemic, outbreaks of infectious disease or any other public health crisis, including
      quarantine or other employee restrictions, fire, explosion, vandalism, storm, or other similar
      occurrence, orders or acts of military or civil authority, or by national emergencies,
      insurrections, riots, or wars, or strikes, lock-outs, work stoppages.
    </PrintParagraph>
    <PrintParagraph
      style={{
        margin: 10,
        fontSize: 9,
        flexDirection: 'row',

        alignItems: 'center',
      }}
    >
      Once the equipment is connected at Purchaser{"'"}s property, Purchaser assumes all risk of
      loss or damage to such equipment and shall ensure the same fully to protect all interests of
      Kalos Services cost of insurance to be paid by Purchaser.
    </PrintParagraph>
    <PrintParagraph
      style={{
        margin: 10,
        fontSize: 9,
        flexDirection: 'row',

        alignItems: 'center',
      }}
    >
      There are no warranties, expressed or implied, for existing equipment, ductwork, or other
      materials not installed by Kalos. Except as provided herein Kalos Services makes no other
      representations or warranties, either express or implied, including, but not limited to, any
      implied warranties of merchantability or fitness for a particular purpose Kalos expressly
      disclaims all other warranties. Kalos{"'"} maximum liability hereunder shall consist of
      refunding all monies paid to it by Purchaser hereunder subject to removal and return to Kalos
      of all equipment provided hereunder. Under no circumstances will Kalos be liable to Purchaser
      or any other person for any damages, including, without limitation, any indirect, incidental,
      special, or consequential damages, expenses, cost, profits, lost savings or earnings, lost or
      corrupted data, or other liability arising out of or related to this agreement, or the
      services or equipment provided hereunder.
    </PrintParagraph>
    <PrintParagraph
      style={{
        margin: 10,
        fontSize: 9,
        flexDirection: 'row',

        alignItems: 'center',
      }}
    >
      This agreement is the complete and exclusive statement of the agreement between Purchaser and
      Kalos and it supersedes all prior oral and written proposals and any prior or subsequent
      communications pertaining to the subject matter hereof. Kalos Services Inc. reserves the right
      to terminate this Agreement in whole or in part, at any time.
    </PrintParagraph>
    <PrintParagraph
      style={{
        margin: 10,
        fontSize: 9,
        flexDirection: 'row',

        alignItems: 'center',
      }}
    >
      This proposal is good for thirty (30) days from the date hereof but may be accepted at any
      later date at the sole discretion of Kalos Services Inc.
    </PrintParagraph>
    <PrintParagraph
      style={{
        margin: 10,
        fontSize: 9,
        flexDirection: 'row',
        fontWeight: 'black',

        textAlign: 'center',
        alignItems: 'center',
      }}
    >
      <b>
        LICENSE # EC0001523, CBC057190, CAC1814620, EC13008082, CGC1504389, EC13007011, AND
        CFC1429840
      </b>
    </PrintParagraph>
  </PrintPage>
);
