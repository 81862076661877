import './PageWrapper.module.less';

import { type FC, type PropsWithChildren } from 'react';

import { ErrorBoundary } from '../ComponentsLibrary/ErrorBoundary';
import SideMenu, { type SideMenuProps } from '../SideMenu/main';
import { StyledPage } from './styled';

export interface PageWrapperProps {
  padding?: number;
  withHeader?: boolean;
}
type Props = PageWrapperProps & SideMenuProps & PropsWithChildren;

export const PageWrapper: FC<Props> = ({ children, padding = 0, withHeader = false, ...props }) => {
  return (
    <StyledPage>
      <ErrorBoundary>
        <div className="PageWrapper">
          {withHeader && (
            <div className="PageWrapperMenu">
              <SideMenu {...props} />
            </div>
          )}
          <div className="PageWrapperContent" style={{ padding: `${padding}rem` }}>
            {children}
          </div>
        </div>
      </ErrorBoundary>
    </StyledPage>
  );
};
