import { EmployeeFunction, EmployeeFunctionList } from '@kalos/kalos-rpc';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { EmployeeFunctionClientService } from '../../tools/helpers';
import { queryKeys } from './constants';
import { type EntityFilter } from './utils';

export type EmployeeFunctionFilter = EntityFilter<EmployeeFunction>;

export const useEmployeeFunctionListQuery = () => {
  return useQuery({
    queryKey: [queryKeys.employeeFunctions.root, queryKeys.employeeFunctions.list],
    queryFn: async () => {
      const req = EmployeeFunction.create();
      const employeeFunctions = await EmployeeFunctionClientService.BatchGet(req);
      return employeeFunctions;
    },
  });
};

export const useEmployeeFunctionQuery = <TSelect = EmployeeFunction,>({
  filter = {},
  select,
}: { filter?: EmployeeFunctionFilter; select?: (data: EmployeeFunction) => TSelect } = {}) => {
  return useQuery({
    queryKey: [queryKeys.employeeFunctions.root, filter],
    queryFn: async () => {
      const req = EmployeeFunction.create(filter);
      const employeeFunction = await EmployeeFunctionClientService.Get(req);
      return employeeFunction;
    },
    select,
  });
};

export const useEmployeeFunctionDeleteMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: EmployeeFunctionClientService.deleteEmployeeFunctionById,
    onSuccess(data, deletedId) {
      queryClient.setQueriesData<EmployeeFunctionList>(
        { queryKey: [queryKeys.employeeFunctions.root, queryKeys.employeeFunctions.list] },
        (oldData) => {
          if (oldData) {
            return EmployeeFunctionList.create({
              totalCount: oldData.totalCount - 1,
              results: oldData.results.filter((item) => item.id !== deletedId),
            });
          }
        },
      );
    },
  });
};

export const useEmployeeFunctionCreateMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (arg: EmployeeFunction) => EmployeeFunctionClientService.Create(arg),
    onSuccess(data) {
      queryClient.setQueriesData<EmployeeFunctionList>(
        { queryKey: [queryKeys.employeeFunctions.root, queryKeys.employeeFunctions.list] },
        (oldData) => {
          if (oldData && data) {
            return EmployeeFunctionList.create({
              totalCount: oldData.totalCount + 1,
              results: [...oldData.results, data],
            });
          }
        },
      );
    },
  });
};

export const useEmployeeFunctionUpdateMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (arg: EmployeeFunction) => EmployeeFunctionClientService.Update(arg),
    onSuccess(updatedFunction, passedEntry) {
      queryClient.setQueriesData<EmployeeFunctionList | EmployeeFunction>(
        { queryKey: [queryKeys.employeeFunctions.root] },
        (oldData) => {
          if (oldData) {
            console.log('oldData', oldData);
            if ('results' in oldData) {
              return EmployeeFunctionList.create({
                totalCount: oldData.totalCount,
                results: oldData.results.map((item) => {
                  if (item.id === updatedFunction.id) {
                    return updatedFunction;
                  }
                  return item;
                }),
              });
            } else {
              return updatedFunction;
            }
          }
        },
      );
    },
  });
};
