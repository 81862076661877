const isArray = (value: unknown): value is Array<unknown> => Array.isArray(value);

export function sortObjectKeys<T extends Record<string, unknown>>(data: T): T {
  return Object.keys(data)
    .sort()
    .reduce<T>(function (acc, key) {
      const value = data[key];
      if ((!value && typeof value !== 'boolean') || (isArray(value) && !value.length)) return acc;
      if (Array.isArray(value) && value.length && value.every((v) => typeof v === 'object')) {
        Object.assign(acc, { [key]: value.map(sortObjectKeys) });
        return acc;
      }
      Object.assign(acc, { [key]: data[key] });
      return acc;
    }, {} as T);
}

export type EntityFilter<T> = { [K in keyof T]?: T[K] };

type RequestObject = Record<string, any>;
export function trimEmptyValues(obj: RequestObject): RequestObject {
  if (typeof obj !== 'object' || obj === null) {
    // Base case: If the object is not an object or is null, return it as is
    return obj;
  }

  if (Array.isArray(obj)) {
    // If it's an array, recursively trim each element and remove empty ones
    const trimmedArray = obj.map((item) => trimEmptyValues(item) as RequestObject[string]);
    return trimmedArray.filter((item) => {
      return !(
        item === 0 ||
        item === '' ||
        (Array.isArray(item) && item.length === 0) ||
        item === false
      );
    });
  }

  // If it's an object, recursively trim its properties and remove empty ones
  const trimmedObject: RequestObject = {};
  for (const key in obj) {
    const value = trimEmptyValues(obj[key]) as RequestObject[string];
    if (
      !(
        value === 0 ||
        value === '' ||
        (Array.isArray(value) && value.length === 0) ||
        value === false
      )
    ) {
      trimmedObject[key] = value;
    }
  }

  return trimmedObject;
}
