// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "employee_profile.proto" (syntax proto3)
// tslint:disable
// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "employee_profile.proto" (syntax proto3)
// tslint:disable
import { IDInt64 } from "./common";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { WireType } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
// @generated message type with reflection information, may provide speed optimized methods
class EmployeeProfile$Type extends MessageType {
    constructor() {
        super("EmployeeProfile", [
            { no: 1, name: "first_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "last_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "department_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "image", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "phone", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "supervisor_first_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "supervisor_last_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "supervisor_email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "supervisor_phone", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { firstName: "", lastName: "", title: "", departmentName: "", image: "", email: "", phone: "", supervisorFirstName: "", supervisorLastName: "", supervisorEmail: "", supervisorPhone: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string first_name */ 1:
                    message.firstName = reader.string();
                    break;
                case /* string last_name */ 2:
                    message.lastName = reader.string();
                    break;
                case /* string title */ 3:
                    message.title = reader.string();
                    break;
                case /* string department_name */ 4:
                    message.departmentName = reader.string();
                    break;
                case /* string image */ 5:
                    message.image = reader.string();
                    break;
                case /* string email */ 6:
                    message.email = reader.string();
                    break;
                case /* string phone */ 7:
                    message.phone = reader.string();
                    break;
                case /* string supervisor_first_name */ 8:
                    message.supervisorFirstName = reader.string();
                    break;
                case /* string supervisor_last_name */ 9:
                    message.supervisorLastName = reader.string();
                    break;
                case /* string supervisor_email */ 10:
                    message.supervisorEmail = reader.string();
                    break;
                case /* string supervisor_phone */ 11:
                    message.supervisorPhone = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string first_name = 1; */
        if (message.firstName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.firstName);
        /* string last_name = 2; */
        if (message.lastName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.lastName);
        /* string title = 3; */
        if (message.title !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.title);
        /* string department_name = 4; */
        if (message.departmentName !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.departmentName);
        /* string image = 5; */
        if (message.image !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.image);
        /* string email = 6; */
        if (message.email !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.email);
        /* string phone = 7; */
        if (message.phone !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.phone);
        /* string supervisor_first_name = 8; */
        if (message.supervisorFirstName !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.supervisorFirstName);
        /* string supervisor_last_name = 9; */
        if (message.supervisorLastName !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.supervisorLastName);
        /* string supervisor_email = 10; */
        if (message.supervisorEmail !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.supervisorEmail);
        /* string supervisor_phone = 11; */
        if (message.supervisorPhone !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.supervisorPhone);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message EmployeeProfile
 */
export const EmployeeProfile = new EmployeeProfile$Type();
/**
 * @generated ServiceType for protobuf service EmployeeProfileService
 */
export const EmployeeProfileService = new ServiceType("EmployeeProfileService", [
    { name: "GetEmployeeProfile", options: {}, I: IDInt64, O: EmployeeProfile }
]);
