import { BaseClient } from '../BaseClient';
import {
  PendingInvoiceTransaction,
  PendingInvoiceTransactionList,
} from '../compiled-protos/transaction';
import { TransactionServiceClient as PendingInvoiceTransactionServiceClient } from '../compiled-protos/transaction.client';

class PendingInvoiceTransactionClient extends BaseClient {
  self: PendingInvoiceTransactionServiceClient;

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new PendingInvoiceTransactionServiceClient(this.transport);
  }

  public async Create(req: PendingInvoiceTransaction) {
    let res = PendingInvoiceTransaction.create();
    try {
      res = await this.self.createPendingInvoiceTransaction(
        req,
        this.getMetaData()
      ).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Get(req: PendingInvoiceTransaction) {
    let res = PendingInvoiceTransaction.create();
    try {
      res = await this.self.getPendingInvoiceTransaction(
        req,
        this.getMetaData()
      ).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Update(req: PendingInvoiceTransaction) {
    let res = PendingInvoiceTransaction.create();
    try {
      res = await this.self.updatePendingInvoiceTransaction(
        req,
        this.getMetaData()
      ).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Delete(req: PendingInvoiceTransaction) {
    let res = PendingInvoiceTransaction.create();
    try {
      res = await this.self.deletePendingInvoiceTransaction(
        req,
        this.getMetaData()
      ).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async BatchGet(req: PendingInvoiceTransaction) {
    let res = PendingInvoiceTransactionList.create();
    try {
      res = await this.self.batchGetPendingInvoiceTransaction(
        req,
        this.getMetaData()
      ).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }
}

export {
  PendingInvoiceTransaction,
  PendingInvoiceTransactionList,
  PendingInvoiceTransactionClient,
};
