// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "vehicle_driver.proto" (syntax proto3)
// tslint:disable
import { VehicleDriverService } from "./vehicle_driver";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service VehicleDriverService
 */
export class VehicleDriverServiceClient {
    constructor(_transport) {
        this._transport = _transport;
        this.typeName = VehicleDriverService.typeName;
        this.methods = VehicleDriverService.methods;
        this.options = VehicleDriverService.options;
    }
    /**
     * @generated from protobuf rpc: CreateVehicle(Vehicle) returns (Vehicle);
     */
    createVehicle(input, options) {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetVehicle(Vehicle) returns (Vehicle);
     */
    getVehicle(input, options) {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetVehicle(Vehicle) returns (VehicleList);
     */
    batchGetVehicle(input, options) {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateVehicle(Vehicle) returns (Vehicle);
     */
    updateVehicle(input, options) {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteVehicle(Vehicle) returns (Vehicle);
     */
    deleteVehicle(input, options) {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateDriver(Driver) returns (Driver);
     */
    createDriver(input, options) {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetDriver(Driver) returns (Driver);
     */
    getDriver(input, options) {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetDriver(Driver) returns (DriverList);
     */
    batchGetDriver(input, options) {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateDriver(Driver) returns (Driver);
     */
    updateDriver(input, options) {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteDriver(Driver) returns (Driver);
     */
    deleteDriver(input, options) {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateVehicleTrailer(VehicleTrailer) returns (VehicleTrailer);
     */
    createVehicleTrailer(input, options) {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetVehicleTrailer(VehicleTrailer) returns (VehicleTrailer);
     */
    getVehicleTrailer(input, options) {
        const method = this.methods[11], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetVehicleTrailer(VehicleTrailer) returns (VehicleTrailerList);
     */
    batchGetVehicleTrailer(input, options) {
        const method = this.methods[12], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateVehicleTrailer(VehicleTrailer) returns (VehicleTrailer);
     */
    updateVehicleTrailer(input, options) {
        const method = this.methods[13], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteVehicleTrailer(VehicleTrailer) returns (Empty);
     */
    deleteVehicleTrailer(input, options) {
        const method = this.methods[14], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateVehicleAsset(VehicleAsset) returns (VehicleAsset);
     */
    createVehicleAsset(input, options) {
        const method = this.methods[15], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetVehicleAsset(VehicleAsset) returns (VehicleAsset);
     */
    getVehicleAsset(input, options) {
        const method = this.methods[16], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetVehicleAsset(VehicleAsset) returns (VehicleAssetList);
     */
    batchGetVehicleAsset(input, options) {
        const method = this.methods[17], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateVehicleAsset(VehicleAsset) returns (VehicleAsset);
     */
    updateVehicleAsset(input, options) {
        const method = this.methods[18], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteVehicleAsset(VehicleAsset) returns (Empty);
     */
    deleteVehicleAsset(input, options) {
        const method = this.methods[19], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateVehiclePhoto(VehiclePhoto) returns (VehiclePhoto);
     */
    createVehiclePhoto(input, options) {
        const method = this.methods[20], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetVehiclePhoto(VehiclePhoto) returns (VehiclePhoto);
     */
    getVehiclePhoto(input, options) {
        const method = this.methods[21], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetVehiclePhoto(VehiclePhoto) returns (VehiclePhotoList);
     */
    batchGetVehiclePhoto(input, options) {
        const method = this.methods[22], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateVehiclePhoto(VehiclePhoto) returns (VehiclePhoto);
     */
    updateVehiclePhoto(input, options) {
        const method = this.methods[23], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteVehiclePhoto(VehiclePhoto) returns (Empty);
     */
    deleteVehiclePhoto(input, options) {
        const method = this.methods[24], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetVehicleLog(VehicleLog) returns (VehicleLog);
     */
    getVehicleLog(input, options) {
        const method = this.methods[25], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetVehicleLog(VehicleLog) returns (VehicleLogList);
     */
    batchGetVehicleLog(input, options) {
        const method = this.methods[26], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
}
