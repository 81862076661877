// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "metrics.proto" (syntax proto3)
// tslint:disable
// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "metrics.proto" (syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { WireType } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
// @generated message type with reflection information, may provide speed optimized methods
class BillableList$Type extends MessageType {
    constructor() {
        super("BillableList", [
            { no: 1, name: "data", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Billable }
        ]);
    }
    create(value) {
        const message = { data: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Billable data */ 1:
                    message.data.push(Billable.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated Billable data = 1; */
        for (let i = 0; i < message.data.length; i++)
            Billable.internalBinaryWrite(message.data[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BillableList
 */
export const BillableList = new BillableList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Billable$Type extends MessageType {
    constructor() {
        super("Billable", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "value", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 3, name: "id_list", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "department_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, value: 0, idList: "", departmentId: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* double value */ 2:
                    message.value = reader.double();
                    break;
                case /* string id_list */ 3:
                    message.idList = reader.string();
                    break;
                case /* int32 department_id */ 4:
                    message.departmentId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* double value = 2; */
        if (message.value !== 0)
            writer.tag(2, WireType.Bit64).double(message.value);
        /* string id_list = 3; */
        if (message.idList !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.idList);
        /* int32 department_id = 4; */
        if (message.departmentId !== 0)
            writer.tag(4, WireType.Varint).int32(message.departmentId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Billable
 */
export const Billable = new Billable$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AvgTicketList$Type extends MessageType {
    constructor() {
        super("AvgTicketList", [
            { no: 1, name: "data", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AvgTicket }
        ]);
    }
    create(value) {
        const message = { data: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated AvgTicket data */ 1:
                    message.data.push(AvgTicket.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated AvgTicket data = 1; */
        for (let i = 0; i < message.data.length; i++)
            AvgTicket.internalBinaryWrite(message.data[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message AvgTicketList
 */
export const AvgTicketList = new AvgTicketList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AvgTicket$Type extends MessageType {
    constructor() {
        super("AvgTicket", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "value", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 3, name: "id_list", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "department_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, value: 0, idList: "", departmentId: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* double value */ 2:
                    message.value = reader.double();
                    break;
                case /* string id_list */ 3:
                    message.idList = reader.string();
                    break;
                case /* int32 department_id */ 4:
                    message.departmentId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* double value = 2; */
        if (message.value !== 0)
            writer.tag(2, WireType.Bit64).double(message.value);
        /* string id_list = 3; */
        if (message.idList !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.idList);
        /* int32 department_id = 4; */
        if (message.departmentId !== 0)
            writer.tag(4, WireType.Varint).int32(message.departmentId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message AvgTicket
 */
export const AvgTicket = new AvgTicket$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CallbacksList$Type extends MessageType {
    constructor() {
        super("CallbacksList", [
            { no: 1, name: "data", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Callbacks }
        ]);
    }
    create(value) {
        const message = { data: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Callbacks data */ 1:
                    message.data.push(Callbacks.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated Callbacks data = 1; */
        for (let i = 0; i < message.data.length; i++)
            Callbacks.internalBinaryWrite(message.data[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CallbacksList
 */
export const CallbacksList = new CallbacksList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Callbacks$Type extends MessageType {
    constructor() {
        super("Callbacks", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "value", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 3, name: "id_list", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "department_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, value: 0, idList: "", departmentId: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* double value */ 2:
                    message.value = reader.double();
                    break;
                case /* string id_list */ 3:
                    message.idList = reader.string();
                    break;
                case /* int32 department_id */ 4:
                    message.departmentId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* double value = 2; */
        if (message.value !== 0)
            writer.tag(2, WireType.Bit64).double(message.value);
        /* string id_list = 3; */
        if (message.idList !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.idList);
        /* int32 department_id = 4; */
        if (message.departmentId !== 0)
            writer.tag(4, WireType.Varint).int32(message.departmentId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Callbacks
 */
export const Callbacks = new Callbacks$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Revenue$Type extends MessageType {
    constructor() {
        super("Revenue", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "value", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 3, name: "id_list", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "department_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, value: 0, idList: "", departmentId: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* double value */ 2:
                    message.value = reader.double();
                    break;
                case /* string id_list */ 3:
                    message.idList = reader.string();
                    break;
                case /* int32 department_id */ 4:
                    message.departmentId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* double value = 2; */
        if (message.value !== 0)
            writer.tag(2, WireType.Bit64).double(message.value);
        /* string id_list = 3; */
        if (message.idList !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.idList);
        /* int32 department_id = 4; */
        if (message.departmentId !== 0)
            writer.tag(4, WireType.Varint).int32(message.departmentId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Revenue
 */
export const Revenue = new Revenue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MetricReportData$Type extends MessageType {
    constructor() {
        super("MetricReportData", [
            { no: 1, name: "techName", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "incomePerHour", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "percentageBillable", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "serviceCallbackRate", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "driveTime", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { techName: 0, incomePerHour: 0, percentageBillable: 0, serviceCallbackRate: 0, driveTime: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 techName */ 1:
                    message.techName = reader.int32();
                    break;
                case /* int32 incomePerHour */ 2:
                    message.incomePerHour = reader.int32();
                    break;
                case /* int32 percentageBillable */ 3:
                    message.percentageBillable = reader.int32();
                    break;
                case /* int32 serviceCallbackRate */ 4:
                    message.serviceCallbackRate = reader.int32();
                    break;
                case /* int32 driveTime */ 5:
                    message.driveTime = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 techName = 1; */
        if (message.techName !== 0)
            writer.tag(1, WireType.Varint).int32(message.techName);
        /* int32 incomePerHour = 2; */
        if (message.incomePerHour !== 0)
            writer.tag(2, WireType.Varint).int32(message.incomePerHour);
        /* int32 percentageBillable = 3; */
        if (message.percentageBillable !== 0)
            writer.tag(3, WireType.Varint).int32(message.percentageBillable);
        /* int32 serviceCallbackRate = 4; */
        if (message.serviceCallbackRate !== 0)
            writer.tag(4, WireType.Varint).int32(message.serviceCallbackRate);
        /* int32 driveTime = 5; */
        if (message.driveTime !== 0)
            writer.tag(5, WireType.Varint).int32(message.driveTime);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message MetricReportData
 */
export const MetricReportData = new MetricReportData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MertricReportDataRequest$Type extends MessageType {
    constructor() {
        super("MertricReportDataRequest", [
            { no: 1, name: "startDate", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "endDate", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "pageNumber", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { startDate: "", endDate: "", pageNumber: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string startDate */ 1:
                    message.startDate = reader.string();
                    break;
                case /* string endDate */ 2:
                    message.endDate = reader.string();
                    break;
                case /* int32 pageNumber */ 3:
                    message.pageNumber = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string startDate = 1; */
        if (message.startDate !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.startDate);
        /* string endDate = 2; */
        if (message.endDate !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.endDate);
        /* int32 pageNumber = 3; */
        if (message.pageNumber !== 0)
            writer.tag(3, WireType.Varint).int32(message.pageNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message MertricReportDataRequest
 */
export const MertricReportDataRequest = new MertricReportDataRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MetricReportDataList$Type extends MessageType {
    constructor() {
        super("MetricReportDataList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MetricReportData },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated MetricReportData results */ 1:
                    message.results.push(MetricReportData.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated MetricReportData results = 1; */
        for (let i = 0; i < message.results.length; i++)
            MetricReportData.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message MetricReportDataList
 */
export const MetricReportDataList = new MetricReportDataList$Type();
/**
 * @generated ServiceType for protobuf service MetricsService
 */
export const MetricsService = new ServiceType("MetricsService", [
    { name: "GetBillable", options: {}, I: Billable, O: Billable },
    { name: "BatchGetBillable", options: {}, I: Billable, O: BillableList },
    { name: "GetAvgTicket", options: {}, I: AvgTicket, O: AvgTicket },
    { name: "BatchGetAvgTicket", options: {}, I: AvgTicket, O: AvgTicketList },
    { name: "GetCallbacks", options: {}, I: Callbacks, O: Callbacks },
    { name: "BatchGetCallbacks", options: {}, I: Callbacks, O: CallbacksList },
    { name: "GetRevenue", options: {}, I: Revenue, O: Revenue },
    { name: "BatchGetMetricReportData", options: {}, I: MertricReportDataRequest, O: MetricReportDataList }
]);
