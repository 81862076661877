import { zodResolver } from '@hookform/resolvers/zod';
import { type CreditCard, type User } from '@kalos/kalos-rpc';
import { Checkbox, FormControl, FormField, FormItem, FormLabel, SimpleSelect } from '@kalos/ui';
import { useForm, useFormContext } from 'react-hook-form';
import { z } from 'zod';

import { type CreditCardFilter } from '../../hooks/react-query/useCreditCardsQuery';
import { type ZodObjectWithModelKeys } from '../../tools/typeguargs';
import { TechniciansComboboxPicker } from '../ComponentsLibrary/Pickers/newPickers/QueryPickerV2';
import { ccOptions } from '../Transactions/components/TransactionAdminSearch';

type CreditCardSearchFields = Pick<CreditCard, 'owner' | 'account' | 'isCompromised'>;

const creditCardSearchSchema = z.object({
  account: z.string().optional(),
  isCompromised: z.coerce.number(),
  owner: z.number(),
}) satisfies ZodObjectWithModelKeys<CreditCardSearchFields>;

export type CreditCardSearchScheme = z.infer<typeof creditCardSearchSchema>;
export const useCreditCardSearchForm = () => {
  return useForm<CreditCardSearchScheme>({
    resolver: zodResolver(creditCardSearchSchema),
    mode: 'onChange',
    defaultValues: {
      account: undefined,
      isCompromised: 0,
      owner: 0,
    },
  });
};
const useCreditCardsSearchFormContext = () => {
  return useFormContext<CreditCardSearchScheme>();
};

export const CreditCardsSearchForm = () => {
  const form = useCreditCardsSearchFormContext();

  return (
    <form
      className="grid grid-cols-2 gap-4 sm:grid-cols-5  lg:grid-cols-8"
      onSubmit={form.handleSubmit(console.log)}
    >
      <FormField
        control={form.control}
        name="owner"
        render={({ field }) => (
          <FormItem className="col-span-2">
            <FormLabel>Owner</FormLabel>
            <FormControl>
              <TechniciansComboboxPicker
                renderItem={(user) => `${user.firstname} ${user.lastname}`}
                selected={field.value.toString()}
                onSelect={(user) => field.onChange(user?.id ?? 0)}
                emptySearch="No technicians found"
                emptyLabel="Select technician"
                placeholder="search technician"
              />
            </FormControl>
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="account"
        render={({ field }) => (
          <FormItem className="col-span-2">
            <FormLabel>Card account</FormLabel>
            <SimpleSelect
              values={ccOptions}
              placeholder="None"
              selectedValue={field.value?.toString() ?? ''}
              onChange={field.onChange}
            />
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="isCompromised"
        render={({ field }) => (
          <FormItem className="items-centers relative col-span-2 flex flex-col justify-end text-center sm:col-span-1">
            <FormLabel className="before:absolute before:inset-0">Is Compromised</FormLabel>
            <FormControl>
              <Checkbox
                className="mx-auto scale-150"
                onBlur={field.onBlur}
                disabled={field.disabled}
                ref={field.ref}
                checked={!!field.value}
                onCheckedChange={field.onChange}
              />
            </FormControl>
          </FormItem>
        )}
      />
    </form>
  );
};

export const convertCreditCardSearchFormToFilter = (
  form: CreditCardSearchScheme,
): CreditCardFilter => {
  return {
    account: form.account && form.account !== '0' ? `%${form.account}%` : undefined,
    isCompromised: form.isCompromised ? 1 : 0,
    owner: form.owner,
  };
};

export const creditCardsPagePermissionCheck = (user: User) => {
  return !!user.permissionGroups.find((item) => item.name === 'AccountingAdmin');
};
