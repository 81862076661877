import { type Event, type TransactionLineItem } from '@kalos/kalos-rpc';
import { toast } from '@kalos/ui';
import { CheckCircledIcon, CrossCircledIcon } from '@radix-ui/react-icons';
import { useState } from 'react';
import { useStore } from 'zustand';

import { JobPreview } from '../../../../../components/JobPreview';
import { JobSelector } from '../../../../../components/JobSelector';
import {
  costCentersThatRequireJobIds,
  LineItemValidator,
  useTransactionLineItemsStore,
} from '../../../../../context/TransactionLIneItems';
import { useEventQueryInline } from '../../../../../hooks/react-query/useEventsQuery';

export const JobField = ({ transactionLineItem }: { transactionLineItem: TransactionLineItem }) => {
  const store = useTransactionLineItemsStore();
  const isEditing = useStore(store, (s) => s.isEditing);
  const value = transactionLineItem.jobId;

  if (!isEditing)
    return value ? <JobPreview jobId={value} /> : <span className="text-red-500">N/A</span>;

  return <EditableJobField transactionLineItem={transactionLineItem} />;
};

const EditableJobField = ({
  transactionLineItem,
}: {
  transactionLineItem: TransactionLineItem;
}) => {
  const store = useTransactionLineItemsStore();
  const setLineItems = useStore(store, (s) => s.setLineItems);
  const value = transactionLineItem.jobId;
  const [loading, setLoading] = useState(false);
  const { fetchEvent: fetchJob } = useEventQueryInline();

  const onChange = async (newJobId: number) => {
    try {
      setLoading(true);
      let job: Event | undefined = undefined;
      if (newJobId !== 0) {
        job = await fetchJob({ filter: { id: newJobId } });
        if (!job) throw new Error('Job not found!');
      }
      setLineItems((prevLineItems) => {
        return prevLineItems.map((li) => {
          if (li.id === transactionLineItem.id) {
            return {
              ...li,
              jobId: newJobId,
              ...(job?.departmentId !== 0
                ? { departmentId: job?.departmentId, disableDepartmentPicker: true }
                : {}),
            };
          }
          return li;
        });
      });
    } catch (error) {
      toast({
        variant: 'destructive',
        title: 'Error',
        description: 'Could not update job',
      });
    } finally {
      setLoading(false);
    }
  };

  const isValid = LineItemValidator.isJobIdValid(value);
  const isEnforcedRequired =
    costCentersThatRequireJobIds.includes(transactionLineItem.costCenterId) &&
    !store.getState().circumventCostCenterRequired;

  return (
    <div className="flex flex-col gap-1">
      {!isValid && (
        <div className="text-xs text-red-500">
          {isEnforcedRequired ? 'Selected Purchase category requires job' : 'Please select a job!'}
        </div>
      )}

      <JobSelector disabled={loading} value={value} onChange={onChange} omitArchivedJobs />
    </div>
  );
};

export const JobEditHeader = () => {
  const store = useTransactionLineItemsStore();
  const isJobsIdsValid = useStore(store, (s) => s.isJobsIdValid());

  return isJobsIdsValid ? (
    <CheckCircledIcon className="size-6 text-emerald-500" />
  ) : (
    <CrossCircledIcon className="size-6 text-red-500" />
  );
};
