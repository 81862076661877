import './Link.module.less';

import { type FC } from 'react';
interface Props {
  href?: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  blank?: boolean;
  children?: React.ReactNode;
}

export const Link: FC<Props> = ({ href = '', blank = false, ...props }) => (
  // eslint-disable-next-line jsx-a11y/anchor-has-content
  <a
    className="Link"
    href={href}
    {...props}
    target={blank ? '_blank' : '_self'}
    rel={'noreferrer'}
  />
);
