import { InvoicePayment } from '@kalos/kalos-rpc';
import { Dialog, DialogContent, DialogTitle, DialogTrigger, toast } from '@kalos/ui';
import { useCallback, useMemo, useState } from 'react';

import { useInvoicePaymentUpdateMutation } from '../../../../../hooks/react-query/useInvoicePayment';
import { timestamp } from '../../../../../tools/helpers';
import { getFieldMaskFromDirtyField } from '../../../EmployeeDepartments/EditEmployeeFunction';
import {
  getDefaultInvoiceValues,
  InvoicePaymentForm,
  permitFormSchema,
} from './InvoicePaymentForm';

export const InvoicePaymentUpdateDialog = ({
  trigger,
  origin,
}: {
  trigger: React.ReactNode;
  origin: InvoicePayment;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const updateInvoicePaymentMutation = useInvoicePaymentUpdateMutation();

  const defaultValues = useMemo(
    () =>
      getDefaultInvoiceValues({
        amount: origin.amount,
        paymentDate: new Date(origin.paymentDate),
        paymentTypeId: permitFormSchema.shape.paymentTypeId.parse(origin.paymentTypeId),
      }),
    [origin.amount, origin.paymentDate, origin.paymentTypeId],
  );

  const onSubmit: React.ComponentProps<typeof InvoicePaymentForm>['onSave'] = useCallback(
    async ({ data, dirtyFields }) => {
      try {
        await updateInvoicePaymentMutation.mutateAsync(
          InvoicePayment.create({
            ...data,
            paymentDate: timestamp(true, data.paymentDate),
            id: origin.id,
            fieldMask: getFieldMaskFromDirtyField(dirtyFields),
          }),
        );
        setIsOpen(false);
        toast({
          variant: 'success',
          title: 'Invoice Payment updated',
        });
      } catch (error) {
        console.error(error);
      }
    },
    [updateInvoicePaymentMutation, origin.id],
  );

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <DialogContent className="@container">
        <DialogTitle>Update Invoice Payment</DialogTitle>
        {updateInvoicePaymentMutation.isError && (
          <p className="mb-2 text-red-500">Something went wrong during creation</p>
        )}
        <InvoicePaymentForm
          defaultValues={defaultValues}
          onSave={onSubmit}
          disabled={updateInvoicePaymentMutation.isPending}
          isLoading={updateInvoicePaymentMutation.isPending}
        />
      </DialogContent>
    </Dialog>
  );
};
