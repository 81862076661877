import { BaseClient } from '../BaseClient';
import { Empty, String$ } from '../compiled-protos/common';
import {
  type SamsaraAddress
,
  SamsaraDriversResponse,
  SamsaraLocationResponse,
} from '../compiled-protos/samsara';
import { SamsaraServiceClient } from '../compiled-protos/samsara.client';

class SamsaraClient extends BaseClient {
  self: SamsaraServiceClient;

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new SamsaraServiceClient(this.transport);
  }

  public async BatchGetLocations() {
    const req = Empty.create();
    let res = SamsaraLocationResponse.create();
    try {
      res = await this.self.batchGetLocations(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
      return;
    }
    return res;
  }

  public async BatchGetDrivers() {
    const req = Empty.create();
    let res = SamsaraDriversResponse.create();
    try {
      res = await this.self.batchGetDrivers(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
      return;
    }
    return res;
  }

  public async CreateAddress(req: SamsaraAddress) {
    let res = String$.create();
    try {
      res = await this.self.createAddress(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
      return;
    }
    return res;
  }

  // This is really only for debugging until we find a better solution
  public async ValidateVehicleList() {
    try {
      await this.self.validateVehicleList(Empty.create(), this.getMetaData());
    } catch (err) {
      console.log(err);
      return;
    }
  }
}

export { SamsaraClient };
