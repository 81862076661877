import CircularProgress from '@mui/material/CircularProgress';
import { type FC, useEffect } from 'react';

interface Props {
  zIndex?: number;
  className?: string;
  backgroundColor?: string;
  opacity?: number;
  height?: number | string;
  width?: number | string;
  customStyles?: boolean;
  style?: React.CSSProperties;
}

const Loader: FC<Props> = ({
  style: styles,
  zIndex = 100,
  backgroundColor = 'grey',
  opacity = 0.2,
  height = window.innerHeight,
  width = window.innerWidth,
  className,
}) => {
  const porgressStyles: React.CSSProperties = styles || {
    zIndex: zIndex + 300,
    position: 'absolute',
    top: window.innerHeight * 0.5 + window.scrollY,
    left: '50%',
    marginLeft: -10,
    marginTop: -10,
  };

  const style: React.CSSProperties = {
    backgroundColor,
    position: 'absolute',
    opacity,
    zIndex,
    height,
    width,
    top: window.scrollY,
  };
  useEffect(() => {
    const oldOverflow = document.body.style.overflow;
    document.body.style.overflow = 'hidden';
    return function cleanup() {
      if (oldOverflow === 'hidden' || oldOverflow === '') {
        document.body.style.overflow = 'visible';
      } else {
        document.body.style.overflow = oldOverflow;
      }
    };
  });
  return (
    <>
      <div style={style} className={className} />
      <CircularProgress style={porgressStyles} />
    </>
  );
};

export { Loader };
