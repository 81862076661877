import { zodResolver } from '@hookform/resolvers/zod';
import { Button, FileUploader, Form, FormControl, FormField, FormItem, FormLabel } from '@kalos/ui';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';

const schema = z.object({
  images: z.array(z.instanceof(File)),
});

type MultipleFilesSchema = z.infer<typeof schema>;

interface MultipleFilesFormProps {
  disabled?: boolean;
  isLoading?: boolean;
  onSubmit: (data: MultipleFilesSchema) => void;
  actions?: React.ReactNode;
}

export const ServiceImageForm = ({
  disabled = false,
  isLoading = false,
  onSubmit,
  actions,
}: MultipleFilesFormProps) => {
  const form = useForm<MultipleFilesSchema>({
    resolver: zodResolver(schema),
  });

  const handleSubmit: SubmitHandler<MultipleFilesSchema> = (data) => {
    onSubmit(data);
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)} className="flex flex-col gap-2">
        <FormField
          control={form.control}
          name="images"
          render={({ field }) => (
            <FormItem className="col-span-2 md:col-span-3">
              <FormLabel>Images</FormLabel>
              <FormControl>
                <FileUploader
                  value={field.value}
                  onValueChange={field.onChange}
                  multiple
                  disabled={disabled || field.disabled}
                  maxFiles={Infinity}
                />
              </FormControl>
            </FormItem>
          )}
        />

        <div className="flex items-center justify-end gap-2">
          {actions}
          <Button
            disabled={disabled}
            isLoading={isLoading}
            type="submit"
            className="w-max self-end"
          >
            Submit
          </Button>
        </div>
      </form>
    </Form>
  );
};
