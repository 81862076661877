// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "memo.proto" (syntax proto3)
// tslint:disable
// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "memo.proto" (syntax proto3)
// tslint:disable
import { Bool } from "./common";
import { Empty } from "./common";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { WireType } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { FileList } from "./s3";
// @generated message type with reflection information, may provide speed optimized methods
class Memo$Type extends MessageType {
    constructor() {
        super("Memo", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "file_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "created_by", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "event_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "property_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 11, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "not_equals", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 15, name: "files", kind: "message", T: () => FileList },
            { no: 16, name: "created_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "status_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 18, name: "classification_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 19, name: "status_list", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 20, name: "classification_list", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 21, name: "include_files", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 22, name: "documents", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MemoDocument },
            { no: 23, name: "created_by_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 24, name: "assignee_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, fileId: "", name: "", description: "", createdBy: 0, userId: 0, eventId: 0, propertyId: 0, fieldMask: [], pageNumber: 0, orderBy: "", orderDir: "", notEquals: [], isActive: false, createdDate: "", statusId: 0, classificationId: 0, statusList: "", classificationList: "", includeFiles: false, documents: [], createdByName: "", assigneeName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string file_id */ 2:
                    message.fileId = reader.string();
                    break;
                case /* string name */ 3:
                    message.name = reader.string();
                    break;
                case /* string description */ 4:
                    message.description = reader.string();
                    break;
                case /* int32 created_by */ 5:
                    message.createdBy = reader.int32();
                    break;
                case /* int32 user_id */ 6:
                    message.userId = reader.int32();
                    break;
                case /* int32 event_id */ 7:
                    message.eventId = reader.int32();
                    break;
                case /* int32 property_id */ 8:
                    message.propertyId = reader.int32();
                    break;
                case /* repeated string field_mask */ 9:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 10:
                    message.pageNumber = reader.int32();
                    break;
                case /* string order_by */ 11:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 12:
                    message.orderDir = reader.string();
                    break;
                case /* repeated string not_equals */ 13:
                    message.notEquals.push(reader.string());
                    break;
                case /* bool is_active */ 14:
                    message.isActive = reader.bool();
                    break;
                case /* FileList files */ 15:
                    message.files = FileList.internalBinaryRead(reader, reader.uint32(), options, message.files);
                    break;
                case /* string created_date */ 16:
                    message.createdDate = reader.string();
                    break;
                case /* int32 status_id */ 17:
                    message.statusId = reader.int32();
                    break;
                case /* int32 classification_id */ 18:
                    message.classificationId = reader.int32();
                    break;
                case /* string status_list */ 19:
                    message.statusList = reader.string();
                    break;
                case /* string classification_list */ 20:
                    message.classificationList = reader.string();
                    break;
                case /* bool include_files */ 21:
                    message.includeFiles = reader.bool();
                    break;
                case /* repeated MemoDocument documents */ 22:
                    message.documents.push(MemoDocument.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string created_by_name */ 23:
                    message.createdByName = reader.string();
                    break;
                case /* string assignee_name */ 24:
                    message.assigneeName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string file_id = 2; */
        if (message.fileId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.fileId);
        /* string name = 3; */
        if (message.name !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.name);
        /* string description = 4; */
        if (message.description !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.description);
        /* int32 created_by = 5; */
        if (message.createdBy !== 0)
            writer.tag(5, WireType.Varint).int32(message.createdBy);
        /* int32 user_id = 6; */
        if (message.userId !== 0)
            writer.tag(6, WireType.Varint).int32(message.userId);
        /* int32 event_id = 7; */
        if (message.eventId !== 0)
            writer.tag(7, WireType.Varint).int32(message.eventId);
        /* int32 property_id = 8; */
        if (message.propertyId !== 0)
            writer.tag(8, WireType.Varint).int32(message.propertyId);
        /* repeated string field_mask = 9; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(9, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 10; */
        if (message.pageNumber !== 0)
            writer.tag(10, WireType.Varint).int32(message.pageNumber);
        /* string order_by = 11; */
        if (message.orderBy !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 12; */
        if (message.orderDir !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.orderDir);
        /* repeated string not_equals = 13; */
        for (let i = 0; i < message.notEquals.length; i++)
            writer.tag(13, WireType.LengthDelimited).string(message.notEquals[i]);
        /* bool is_active = 14; */
        if (message.isActive !== false)
            writer.tag(14, WireType.Varint).bool(message.isActive);
        /* FileList files = 15; */
        if (message.files)
            FileList.internalBinaryWrite(message.files, writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        /* string created_date = 16; */
        if (message.createdDate !== "")
            writer.tag(16, WireType.LengthDelimited).string(message.createdDate);
        /* int32 status_id = 17; */
        if (message.statusId !== 0)
            writer.tag(17, WireType.Varint).int32(message.statusId);
        /* int32 classification_id = 18; */
        if (message.classificationId !== 0)
            writer.tag(18, WireType.Varint).int32(message.classificationId);
        /* string status_list = 19; */
        if (message.statusList !== "")
            writer.tag(19, WireType.LengthDelimited).string(message.statusList);
        /* string classification_list = 20; */
        if (message.classificationList !== "")
            writer.tag(20, WireType.LengthDelimited).string(message.classificationList);
        /* bool include_files = 21; */
        if (message.includeFiles !== false)
            writer.tag(21, WireType.Varint).bool(message.includeFiles);
        /* repeated MemoDocument documents = 22; */
        for (let i = 0; i < message.documents.length; i++)
            MemoDocument.internalBinaryWrite(message.documents[i], writer.tag(22, WireType.LengthDelimited).fork(), options).join();
        /* string created_by_name = 23; */
        if (message.createdByName !== "")
            writer.tag(23, WireType.LengthDelimited).string(message.createdByName);
        /* string assignee_name = 24; */
        if (message.assigneeName !== "")
            writer.tag(24, WireType.LengthDelimited).string(message.assigneeName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Memo
 */
export const Memo = new Memo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MemoList$Type extends MessageType {
    constructor() {
        super("MemoList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Memo },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Memo results */ 1:
                    message.results.push(Memo.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated Memo results = 1; */
        for (let i = 0; i < message.results.length; i++)
            Memo.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message MemoList
 */
export const MemoList = new MemoList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MemoComment$Type extends MessageType {
    constructor() {
        super("MemoComment", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "memo_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "create_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "comment", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "not_equals", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 12, name: "latest_log", kind: "message", T: () => MemoCommentLog },
            { no: 13, name: "without_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, memoId: 0, userId: 0, createDate: "", comment: "", fieldMask: [], pageNumber: 0, orderBy: "", orderDir: "", notEquals: [], isActive: false, withoutLimit: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 memo_id */ 2:
                    message.memoId = reader.int32();
                    break;
                case /* int32 user_id */ 3:
                    message.userId = reader.int32();
                    break;
                case /* string create_date */ 4:
                    message.createDate = reader.string();
                    break;
                case /* string comment */ 5:
                    message.comment = reader.string();
                    break;
                case /* repeated string field_mask */ 6:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 7:
                    message.pageNumber = reader.int32();
                    break;
                case /* string order_by */ 8:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 9:
                    message.orderDir = reader.string();
                    break;
                case /* repeated string not_equals */ 10:
                    message.notEquals.push(reader.string());
                    break;
                case /* bool is_active */ 11:
                    message.isActive = reader.bool();
                    break;
                case /* MemoCommentLog latest_log */ 12:
                    message.latestLog = MemoCommentLog.internalBinaryRead(reader, reader.uint32(), options, message.latestLog);
                    break;
                case /* bool without_limit */ 13:
                    message.withoutLimit = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 memo_id = 2; */
        if (message.memoId !== 0)
            writer.tag(2, WireType.Varint).int32(message.memoId);
        /* int32 user_id = 3; */
        if (message.userId !== 0)
            writer.tag(3, WireType.Varint).int32(message.userId);
        /* string create_date = 4; */
        if (message.createDate !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.createDate);
        /* string comment = 5; */
        if (message.comment !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.comment);
        /* repeated string field_mask = 6; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(6, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 7; */
        if (message.pageNumber !== 0)
            writer.tag(7, WireType.Varint).int32(message.pageNumber);
        /* string order_by = 8; */
        if (message.orderBy !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 9; */
        if (message.orderDir !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.orderDir);
        /* repeated string not_equals = 10; */
        for (let i = 0; i < message.notEquals.length; i++)
            writer.tag(10, WireType.LengthDelimited).string(message.notEquals[i]);
        /* bool is_active = 11; */
        if (message.isActive !== false)
            writer.tag(11, WireType.Varint).bool(message.isActive);
        /* MemoCommentLog latest_log = 12; */
        if (message.latestLog)
            MemoCommentLog.internalBinaryWrite(message.latestLog, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* bool without_limit = 13; */
        if (message.withoutLimit !== false)
            writer.tag(13, WireType.Varint).bool(message.withoutLimit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message MemoComment
 */
export const MemoComment = new MemoComment$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MemoCommentList$Type extends MessageType {
    constructor() {
        super("MemoCommentList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MemoComment },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated MemoComment results */ 1:
                    message.results.push(MemoComment.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated MemoComment results = 1; */
        for (let i = 0; i < message.results.length; i++)
            MemoComment.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message MemoCommentList
 */
export const MemoCommentList = new MemoCommentList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MemoStatus$Type extends MessageType {
    constructor() {
        super("MemoStatus", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "status_description", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, statusDescription: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string status_description */ 2:
                    message.statusDescription = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string status_description = 2; */
        if (message.statusDescription !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.statusDescription);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message MemoStatus
 */
export const MemoStatus = new MemoStatus$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MemoStatusList$Type extends MessageType {
    constructor() {
        super("MemoStatusList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MemoStatus },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated MemoStatus results */ 1:
                    message.results.push(MemoStatus.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated MemoStatus results = 1; */
        for (let i = 0; i < message.results.length; i++)
            MemoStatus.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message MemoStatusList
 */
export const MemoStatusList = new MemoStatusList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MemoClassification$Type extends MessageType {
    constructor() {
        super("MemoClassification", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "category_description", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, categoryDescription: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string category_description */ 2:
                    message.categoryDescription = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string category_description = 2; */
        if (message.categoryDescription !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.categoryDescription);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message MemoClassification
 */
export const MemoClassification = new MemoClassification$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MemoClassificationList$Type extends MessageType {
    constructor() {
        super("MemoClassificationList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MemoClassification },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated MemoClassification results */ 1:
                    message.results.push(MemoClassification.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated MemoClassification results = 1; */
        for (let i = 0; i < message.results.length; i++)
            MemoClassification.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message MemoClassificationList
 */
export const MemoClassificationList = new MemoClassificationList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MemoCommentLog$Type extends MessageType {
    constructor() {
        super("MemoCommentLog", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "memo_comment_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "log_event", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "event_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "not_equals", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "memo_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 12, name: "comment", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "without_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, memoCommentId: 0, userId: 0, logEvent: "", eventDate: "", fieldMask: [], pageNumber: 0, orderBy: "", orderDir: "", notEquals: [], memoId: 0, comment: "", withoutLimit: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 memo_comment_id */ 2:
                    message.memoCommentId = reader.int32();
                    break;
                case /* int32 user_id */ 3:
                    message.userId = reader.int32();
                    break;
                case /* string log_event */ 4:
                    message.logEvent = reader.string();
                    break;
                case /* string event_date */ 5:
                    message.eventDate = reader.string();
                    break;
                case /* repeated string field_mask */ 6:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 7:
                    message.pageNumber = reader.int32();
                    break;
                case /* string order_by */ 8:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 9:
                    message.orderDir = reader.string();
                    break;
                case /* repeated string not_equals */ 10:
                    message.notEquals.push(reader.string());
                    break;
                case /* int32 memo_id */ 11:
                    message.memoId = reader.int32();
                    break;
                case /* string comment */ 12:
                    message.comment = reader.string();
                    break;
                case /* bool without_limit */ 13:
                    message.withoutLimit = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 memo_comment_id = 2; */
        if (message.memoCommentId !== 0)
            writer.tag(2, WireType.Varint).int32(message.memoCommentId);
        /* int32 user_id = 3; */
        if (message.userId !== 0)
            writer.tag(3, WireType.Varint).int32(message.userId);
        /* string log_event = 4; */
        if (message.logEvent !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.logEvent);
        /* string event_date = 5; */
        if (message.eventDate !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.eventDate);
        /* repeated string field_mask = 6; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(6, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 7; */
        if (message.pageNumber !== 0)
            writer.tag(7, WireType.Varint).int32(message.pageNumber);
        /* string order_by = 8; */
        if (message.orderBy !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 9; */
        if (message.orderDir !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.orderDir);
        /* repeated string not_equals = 10; */
        for (let i = 0; i < message.notEquals.length; i++)
            writer.tag(10, WireType.LengthDelimited).string(message.notEquals[i]);
        /* int32 memo_id = 11; */
        if (message.memoId !== 0)
            writer.tag(11, WireType.Varint).int32(message.memoId);
        /* string comment = 12; */
        if (message.comment !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.comment);
        /* bool without_limit = 13; */
        if (message.withoutLimit !== false)
            writer.tag(13, WireType.Varint).bool(message.withoutLimit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message MemoCommentLog
 */
export const MemoCommentLog = new MemoCommentLog$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MemoCommentLogList$Type extends MessageType {
    constructor() {
        super("MemoCommentLogList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MemoCommentLog },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated MemoCommentLog results */ 1:
                    message.results.push(MemoCommentLog.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated MemoCommentLog results = 1; */
        for (let i = 0; i < message.results.length; i++)
            MemoCommentLog.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message MemoCommentLogList
 */
export const MemoCommentLogList = new MemoCommentLogList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StreamIdentifier$Type extends MessageType {
    constructor() {
        super("StreamIdentifier", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message StreamIdentifier
 */
export const StreamIdentifier = new StreamIdentifier$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MemoReportReq$Type extends MessageType {
    constructor() {
        super("MemoReportReq", [
            { no: 1, name: "memo_ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "job_ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "pdf_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "created_date_start", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "created_date_end", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { memoIds: [], jobIds: [], pdfName: "", createdDateStart: "", createdDateEnd: "", userId: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated int32 memo_ids */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.memoIds.push(reader.int32());
                    else
                        message.memoIds.push(reader.int32());
                    break;
                case /* repeated int32 job_ids */ 2:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.jobIds.push(reader.int32());
                    else
                        message.jobIds.push(reader.int32());
                    break;
                case /* string pdf_name */ 3:
                    message.pdfName = reader.string();
                    break;
                case /* string created_date_start */ 4:
                    message.createdDateStart = reader.string();
                    break;
                case /* string created_date_end */ 5:
                    message.createdDateEnd = reader.string();
                    break;
                case /* int32 user_id */ 6:
                    message.userId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated int32 memo_ids = 1; */
        if (message.memoIds.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.memoIds.length; i++)
                writer.int32(message.memoIds[i]);
            writer.join();
        }
        /* repeated int32 job_ids = 2; */
        if (message.jobIds.length) {
            writer.tag(2, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.jobIds.length; i++)
                writer.int32(message.jobIds[i]);
            writer.join();
        }
        /* string pdf_name = 3; */
        if (message.pdfName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.pdfName);
        /* string created_date_start = 4; */
        if (message.createdDateStart !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.createdDateStart);
        /* string created_date_end = 5; */
        if (message.createdDateEnd !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.createdDateEnd);
        /* int32 user_id = 6; */
        if (message.userId !== 0)
            writer.tag(6, WireType.Varint).int32(message.userId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message MemoReportReq
 */
export const MemoReportReq = new MemoReportReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FullReport$Type extends MessageType {
    constructor() {
        super("FullReport", [
            { no: 1, name: "html", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "pdf", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { html: "", pdf: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string html */ 1:
                    message.html = reader.string();
                    break;
                case /* string pdf */ 2:
                    message.pdf = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string html = 1; */
        if (message.html !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.html);
        /* string pdf = 2; */
        if (message.pdf !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.pdf);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message FullReport
 */
export const FullReport = new FullReport$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SummaryReport$Type extends MessageType {
    constructor() {
        super("SummaryReport", [
            { no: 1, name: "html", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "pdf", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { html: "", pdf: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string html */ 1:
                    message.html = reader.string();
                    break;
                case /* string pdf */ 2:
                    message.pdf = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string html = 1; */
        if (message.html !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.html);
        /* string pdf = 2; */
        if (message.pdf !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.pdf);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SummaryReport
 */
export const SummaryReport = new SummaryReport$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SelectedMemos$Type extends MessageType {
    constructor() {
        super("SelectedMemos", [
            { no: 1, name: "html", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "pdf", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { html: "", pdf: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string html */ 1:
                    message.html = reader.string();
                    break;
                case /* string pdf */ 2:
                    message.pdf = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string html = 1; */
        if (message.html !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.html);
        /* string pdf = 2; */
        if (message.pdf !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.pdf);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SelectedMemos
 */
export const SelectedMemos = new SelectedMemos$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MemoDetailedReport$Type extends MessageType {
    constructor() {
        super("MemoDetailedReport", [
            { no: 1, name: "full_report", kind: "message", T: () => FullReport },
            { no: 2, name: "summary_report", kind: "message", T: () => SummaryReport },
            { no: 3, name: "selected_memos", kind: "message", T: () => SelectedMemos }
        ]);
    }
    create(value) {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* FullReport full_report */ 1:
                    message.fullReport = FullReport.internalBinaryRead(reader, reader.uint32(), options, message.fullReport);
                    break;
                case /* SummaryReport summary_report */ 2:
                    message.summaryReport = SummaryReport.internalBinaryRead(reader, reader.uint32(), options, message.summaryReport);
                    break;
                case /* SelectedMemos selected_memos */ 3:
                    message.selectedMemos = SelectedMemos.internalBinaryRead(reader, reader.uint32(), options, message.selectedMemos);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* FullReport full_report = 1; */
        if (message.fullReport)
            FullReport.internalBinaryWrite(message.fullReport, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* SummaryReport summary_report = 2; */
        if (message.summaryReport)
            SummaryReport.internalBinaryWrite(message.summaryReport, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* SelectedMemos selected_memos = 3; */
        if (message.selectedMemos)
            SelectedMemos.internalBinaryWrite(message.selectedMemos, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message MemoDetailedReport
 */
export const MemoDetailedReport = new MemoDetailedReport$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MemoDocument$Type extends MessageType {
    constructor() {
        super("MemoDocument", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "memo_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "file_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "file_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "file_bucket", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "date_created", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, memoId: 0, fileId: "", description: "", fileName: "", fileBucket: "", dateCreated: "", fieldMask: [], pageNumber: 0, orderBy: "", orderDir: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 memo_id */ 2:
                    message.memoId = reader.int32();
                    break;
                case /* string file_id */ 3:
                    message.fileId = reader.string();
                    break;
                case /* string description */ 4:
                    message.description = reader.string();
                    break;
                case /* string file_name */ 5:
                    message.fileName = reader.string();
                    break;
                case /* string file_bucket */ 6:
                    message.fileBucket = reader.string();
                    break;
                case /* string date_created */ 7:
                    message.dateCreated = reader.string();
                    break;
                case /* repeated string field_mask */ 8:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 9:
                    message.pageNumber = reader.int32();
                    break;
                case /* string order_by */ 10:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 11:
                    message.orderDir = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 memo_id = 2; */
        if (message.memoId !== 0)
            writer.tag(2, WireType.Varint).int32(message.memoId);
        /* string file_id = 3; */
        if (message.fileId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.fileId);
        /* string description = 4; */
        if (message.description !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.description);
        /* string file_name = 5; */
        if (message.fileName !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.fileName);
        /* string file_bucket = 6; */
        if (message.fileBucket !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.fileBucket);
        /* string date_created = 7; */
        if (message.dateCreated !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.dateCreated);
        /* repeated string field_mask = 8; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(8, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 9; */
        if (message.pageNumber !== 0)
            writer.tag(9, WireType.Varint).int32(message.pageNumber);
        /* string order_by = 10; */
        if (message.orderBy !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 11; */
        if (message.orderDir !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.orderDir);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message MemoDocument
 */
export const MemoDocument = new MemoDocument$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MemoDocumentList$Type extends MessageType {
    constructor() {
        super("MemoDocumentList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MemoDocument },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated MemoDocument results */ 1:
                    message.results.push(MemoDocument.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated MemoDocument results = 1; */
        for (let i = 0; i < message.results.length; i++)
            MemoDocument.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message MemoDocumentList
 */
export const MemoDocumentList = new MemoDocumentList$Type();
/**
 * @generated ServiceType for protobuf service MemoService
 */
export const MemoService = new ServiceType("MemoService", [
    { name: "Create", options: {}, I: Memo, O: Memo },
    { name: "Get", options: {}, I: Memo, O: Memo },
    { name: "BatchGet", options: {}, I: Memo, O: MemoList },
    { name: "Update", options: {}, I: Memo, O: Memo },
    { name: "Delete", options: {}, I: Memo, O: Memo },
    { name: "CreateMemoComment", options: {}, I: MemoComment, O: MemoComment },
    { name: "GetMemoComment", options: {}, I: MemoComment, O: MemoComment },
    { name: "BatchGetMemoComment", options: {}, I: MemoComment, O: MemoCommentList },
    { name: "UpdateMemoComment", options: {}, I: MemoComment, O: MemoComment },
    { name: "DeleteMemoComment", options: {}, I: MemoComment, O: MemoComment },
    { name: "MemoCommentListStream", serverStreaming: true, options: {}, I: MemoComment, O: MemoComment },
    { name: "closeStream", options: {}, I: StreamIdentifier, O: Empty },
    { name: "CreateMemoCommentLog", options: {}, I: MemoCommentLog, O: MemoCommentLog },
    { name: "GetMemoCommentLog", options: {}, I: MemoCommentLog, O: MemoCommentLog },
    { name: "BatchGetMemoCommentLog", options: {}, I: MemoCommentLog, O: MemoCommentLogList },
    { name: "UpdateMemoCommentLog", options: {}, I: MemoCommentLog, O: MemoCommentLog },
    { name: "DeleteMemoCommentLog", options: {}, I: MemoCommentLog, O: MemoCommentLog },
    { name: "BatchGetMemoStatus", options: {}, I: MemoStatus, O: MemoStatusList },
    { name: "BatchGetMemoCategory", options: {}, I: MemoClassification, O: MemoClassificationList },
    { name: "GetMemoReport", options: {}, I: MemoReportReq, O: Bool },
    { name: "GetMemoDocument", options: {}, I: MemoDocument, O: MemoDocument },
    { name: "BatchGetMemoDocument", options: {}, I: MemoDocument, O: MemoDocumentList },
    { name: "CreateMemoDocument", options: {}, I: MemoDocument, O: MemoDocument },
    { name: "UpdateMemoDocument", options: {}, I: MemoDocument, O: MemoDocument },
    { name: "DeleteMemoDocument", options: {}, I: MemoDocument, O: MemoDocument }
]);
