import { EmployeeFunction } from '@kalos/kalos-rpc';
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  Input,
  SimpleSelect,
  Skeleton,
} from '@kalos/ui';
import { type ComponentProps } from 'react';
import { type SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

import {
  useEmployeeFunctionQuery,
  useEmployeeFunctionUpdateMutation,
} from '../../../hooks/react-query/useEmployeeFunction';
import { useStrictParams } from '../../../hooks/useStrictParams';
import { type DirtyModelFields } from '../../../tools/helpers';
import { type EmployeeFunctionFormType, useEmployeeFunctionForm } from './EmployeeFunctionForm';

export const getFieldMaskFromString = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
export const getFieldMaskFromDirtyField = (
  dirtyFields: DirtyModelFields<Record<string, unknown>>,
) => {
  const fieldMask: string[] = [];
  for (const key in dirtyFields) {
    fieldMask.push(getFieldMaskFromString(key));
  }
  return fieldMask;
};

const idZodObject = z.object({
  functionId: z.coerce.number().positive(),
});

export const EditEmployeeFunction = () => {
  const { functionId } = useStrictParams(idZodObject);
  const dataQuery = useEmployeeFunctionQuery({
    filter: {
      id: functionId,
    },
    select: (data) => {
      return {
        status: data.status,
        name: data.name,
        color: data.color,
      } satisfies EmployeeFunctionFormType;
    },
  });

  const navigate = useNavigate();

  const { mutateAsync: updateEmployeeFunction, isPending: updatingEmployeeFunction } =
    useEmployeeFunctionUpdateMutation();

  const onSubmit: ComponentProps<typeof EmployeeFunctionForm>['onSubmit'] = async ({
    data,
    dirtyFields,
  }) => {
    const fieldMask = getFieldMaskFromDirtyField(dirtyFields);
    const request = EmployeeFunction.create({ ...data, fieldMask, id: functionId });
    try {
      await updateEmployeeFunction(request);
      navigate(-1);
    } catch (error) {
      alert('Failed to update employee function');
      console.error('Failed to update employee function', error);
    }
  };

  return (
    <div className="flex h-full flex-col gap-4">
      <h1 className="scroll-m-20 text-xl font-semibold tracking-tight">Edit Employee Function</h1>
      {dataQuery.isPending && <Skeleton className="h-40 w-full" />}
      {dataQuery.isSuccess && (
        <EmployeeFunctionForm
          disabled={updatingEmployeeFunction}
          onSubmit={onSubmit}
          defaultValues={dataQuery.data}
        />
      )}
    </div>
  );
};

const statusOptions = [
  { label: 'Deactive', value: '0' },
  { label: 'Active', value: '1' },
];

export const EmployeeFunctionForm = ({
  defaultValues,
  onSubmit,
  disabled,
}: {
  defaultValues: EmployeeFunctionFormType;
  disabled?: boolean;
  onSubmit: (arg: {
    data: EmployeeFunctionFormType;
    dirtyFields: Record<string, boolean | undefined>;
  }) => void;
}) => {
  const form = useEmployeeFunctionForm({ defaultValues });
  const _onSubmit: SubmitHandler<EmployeeFunctionFormType> = (data) => {
    onSubmit({ data, dirtyFields: form.formState.dirtyFields });
  };

  return (
    <Form {...form}>
      <form
        className="grid max-w-3xl grid-cols-1 gap-4 sm:grid-cols-3"
        onSubmit={form.handleSubmit(_onSubmit)}
      >
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => {
            return (
              <FormItem>
                <FormLabel>Name</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
              </FormItem>
            );
          }}
        />

        <FormField
          control={form.control}
          name="color"
          render={({ field }) => {
            return (
              <FormItem>
                <FormLabel>Color</FormLabel>
                <FormControl>
                  <Input {...field} type="color" />
                </FormControl>
              </FormItem>
            );
          }}
        />

        <FormField
          control={form.control}
          name="status"
          render={({ field }) => {
            return (
              <FormItem>
                <FormLabel>Status</FormLabel>
                <FormControl>
                  <SimpleSelect
                    onChange={field.onChange}
                    selectedValue={field.value.toString()}
                    values={statusOptions}
                  />
                </FormControl>
              </FormItem>
            );
          }}
        />

        <Button
          className="sm:col-span-3"
          disabled={!form.formState.isValid || !form.formState.isDirty || disabled}
          type="submit"
        >
          Save
        </Button>
      </form>
    </Form>
  );
};
