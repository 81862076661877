import { BaseClient } from '../BaseClient';
import { UserGroupLink, UserGroupLinkList } from '../compiled-protos/user';
import { UserServiceClient as UserGroupLinkServiceClient } from '../compiled-protos/user.client';

class UserGroupLinkClient extends BaseClient {
  self: UserGroupLinkServiceClient;
  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new UserGroupLinkServiceClient(this.transport);
  }

  public async Create(req: UserGroupLink) {
    let res = UserGroupLink.create();
    try {
      res = await this.self.createUserGroupLink(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Get(req: UserGroupLink) {
    let res = UserGroupLink.create();
    try {
      res = await this.self.getUserGroupLink(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Update(req: UserGroupLink) {
    let res = UserGroupLink.create();
    try {
      res = await this.self.updateUserGroupLink(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Delete(req: UserGroupLink) {
    let res = UserGroupLink.create();
    try {
      res = await this.self.deleteUserGroupLink(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async BatchGet(req: UserGroupLink) {
    let res = UserGroupLinkList.create();
    try {
      res = await this.self.batchGetUserGroupLink(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public loadUserGroupLinksByUserId = async (userId: number) => {
    const groupLink = UserGroupLink.create();
    groupLink.userId = userId;
    return this.BatchGet(groupLink);
  };
}

export { UserGroupLink, UserGroupLinkList, UserGroupLinkClient };
