import './Field.module.less';

import {
  type ClassCode as TimesheetClassCode,
  type Event,
  type TimesheetDepartment,
  type User,
  Vendor,
} from '@kalos/kalos-rpc';
import { Dialog, DialogContent } from '@kalos/ui';
import BlockIcon from '@mui/icons-material/Block';
import CheckIcon from '@mui/icons-material/Check';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { type SvgIconProps } from '@mui/material/SvgIcon';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { DesktopDatePicker, DesktopDateTimePicker, DesktopTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { default as clsx } from 'clsx';
import { format, isValid, parseISO } from 'date-fns';
import {
  type CSSProperties,
  type FC,
  type ForwardedRef,
  forwardRef,
  type MouseEvent,
  type Ref,
  type RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import SignatureCanvas from 'react-signature-pad-wrapper';

import {
  EventClientService,
  makeFakeRows,
  trailingZero,
  UserClientService,
  VendorClientService,
} from '../../../tools/helpers';
import { Actions } from '../Actions';
import { AdvancedSearch, type Kind } from '../AdvancedSearch';
import { Button } from '../Button';
import { type Data, InfoTable } from '../InfoTable';
import { Modal } from '../Modal';
import { ClassCodePicker, TimsheetDepartmentPicker } from '../Pickers/newPickers/QueryPicker';
import { type SchemaProps } from '../PlainForm';
import { SectionBar } from '../SectionBar';

const kindsSearch = ['serviceCalls'] satisfies Kind[];

export type FieldType =
  | 'boolean'
  | 'text'
  | 'password'
  | 'number'
  | 'search'
  | 'checkbox'
  | 'date'
  | 'time'
  | 'datetime'
  | 'mui-date'
  | 'mui-time'
  | 'mui-datetime'
  | 'mui-datemonth'
  | 'technician'
  | 'technicians'
  | 'signature'
  | 'file'
  | 'vendor'
  | 'autocomplete-vendor'
  | 'department'
  | 'classCode'
  | 'hidden'
  | 'color'
  | 'eventId'
  | 'multiselect'
  | 'action-group';

export type Value = string | number;

export type Option = {
  label: string;
  value: string | number;
  color?: string;
  icon?: FC<SvgIconProps>;
};

export type Options = (string | Option)[];

export type Props<T> = SchemaProps<T> & {
  value?: T[keyof T];
  disabled?: boolean;
  validation?: string;
  readOnly?: boolean;
  className?: string;
  placeholder?: string;
  onClose?: () => void;
  onClear?: () => void;
  style?: CSSProperties;
  compact?: boolean;
  technicianIdForRecentServiceCalls?: number;
  omitArchivedJobs?: boolean;
  white?: boolean;
  ref?: RefObject<T> | Ref<T>;
  technicianFilters?: number[];
};

const typeMap = new Map<FieldType, any>([
  ['number', 0],
  ['time', '00:00'],
  ['datetime', '0001-01-01 00:00:00'],

  ['checkbox', 0],
  ['department', 0],
  ['technician', 0],
  ['eventId', 0],
  ['vendor', 0],
  ['classCode', 0],
  ['technicians', ''],
  ['boolean', 0],
]);

export const getDefaultValueByType = (type: FieldType) => {
  if (typeMap.has(type)) {
    return typeMap.get(type);
  }
  return '';
};

// ? The '| null' at the end of the type for the forward ref is simply there to ensure Typescript doesn't error
export const Field: <T>(
  props: Props<T>,
  ref: ForwardedRef<HTMLInputElement> | null,
) => React.ReactNode = forwardRef(
  (
    {
      name,
      label,
      headline,
      options,
      onChange,
      onBlur,
      onFileLoad,
      onClose,
      onClear,
      disabled = false,
      technicianIdForRecentServiceCalls,
      removeExcessCheckboxArea = false,
      required = false,
      validation = '',
      helperText = '',
      type = 'text',
      readOnly = false,
      disableMinutesStep = false,
      className = '',
      startAdornment,
      endAdornment,
      content,
      actionsInLabel = false,
      style = {},
      compact = false,
      jobAdminTechnicianFilter = false,
      technicianAsEmployee = false,
      white = false,
      displayEmpty = false,
      forceShrinkLabel = true,
      defaultLabel = '',
      minutesStep = 15,
      technicianFilters,
      omitArchivedJobs = false,
      ...props
    },
    functionRef,
  ) => {
    const signatureRef = useRef(null);
    const dateTimePart = type === 'date' ? (props.value + '').substr(11, 8) : '';
    const value = type === 'date' ? (props.value + '').substr(0, 10) : props.value; // props.value set by "data" prop on Form
    const [technicians, setTechnicians] = useState<User[]>([]);
    const [vendors, setVendors] = useState<Vendor[]>([]);

    const [loadedTechnicians, setLoadedTechnicians] = useState<boolean>(false);
    const [loadedVendors, setLoadedVendors] = useState<boolean>(false);

    const [eventsOpened, setEventsOpened] = useState<boolean>(false);
    const [techniciansOpened, setTechniciansOpened] = useState<boolean>(false);
    const [vendorsOpened, setVendorsOpened] = useState<boolean>(false);
    const [openAddVendor, setOpenAddVendor] = useState<boolean>(false);
    const [newVendor, setNewVendor] = useState<string>('');

    const [techniciansIds, setTechniciansIds] = useState<number[]>(
      (value + '').split(',').map((id) => +id),
    );
    const [vendorIds, setVendorIds] = useState<number[]>((value + '').split(',').map((id) => +id));
    const [filename, setFilename] = useState<string>('');
    const [searchTechnician, setSearchTechnician] = useState<Value>('');
    const [searchVendors, setSearchVendors] = useState<Value>('');

    const [eventStatus, setEventStatus] = useState<number>(+(value || '') > 0 ? 1 : -1);
    const [eventIdValue, setEventIdValue] = useState<number>(+(value || ''));
    const [autoCompleteValue, setAutoCompleteValue] = useState<{
      id: number;
      label: string;
    }>({ id: 0, label: 'None' });
    const [autoCompleteInputValue, setAutoCompleteInputValue] = useState<string>('None');
    const loadUserTechnicians = useCallback(async () => {
      let technicians = await UserClientService.loadTechnicians();
      if (jobAdminTechnicianFilter) {
        const admins = (await UserClientService.GetUserIdsInPermissionGroup(67)).response.values;
        technicians = technicians.filter((el) => admins.includes(el.id));
      }
      if (technicianFilters?.length) {
        technicians = technicians.filter((item) => {
          return !technicianFilters.some((id) => {
            return Number(id) === Number(item.id);
          });
        });
      }
      setLoadedTechnicians(true);
      setTechnicians(technicians);
    }, [jobAdminTechnicianFilter, technicianFilters]);

    const loadVendors = useCallback(async () => {
      const req = Vendor.create();
      req.isActive = 1;
      req.withoutLimit = true;
      req.orderBy = 'vendor_name';
      const vendors = (await VendorClientService.BatchGet(req))!.results;
      setLoadedVendors(true);
      const options = vendors.map((option) => ({
        id: option.id,
        label: option.vendorName,
      }));

      const initValue = options.find((el) => el.id == (props.value as unknown as number));
      if (initValue) {
        console.log('found value', initValue);
        setAutoCompleteInputValue(initValue.label);
        setAutoCompleteValue(initValue);
      } else {
        setAutoCompleteInputValue('None');
        setAutoCompleteValue({ id: 0, label: 'None' });
      }
      setVendors(vendors);
    }, [props.value]);
    const handleEventsOpenedToggle = useCallback(
      (opened: boolean) => () => setEventsOpened(opened),
      [setEventsOpened],
    );

    const handleEventsSearchClicked = useCallback(async () => {
      if (eventIdValue === 0) {
        setEventStatus(-1);
        setEventsOpened(true);
        return;
      }
      setEventStatus(0);
      try {
        //const event = await loadEventById(eventIdValue);
        const event = await EventClientService.LoadEventByServiceCallID(
          eventIdValue,
          omitArchivedJobs,
        );
        if (onChange) {
          onChange(event!.id);
        }
        setEventStatus(1);
      } catch (e) {
        setEventStatus(-1);
        setEventsOpened(true);
      }
    }, [setEventStatus, eventIdValue, onChange, setEventsOpened, omitArchivedJobs]);
    const handleEventSelect = useCallback(
      (event: Event) => {
        if (onChange) {
          onChange(event.id);
        }
        setEventIdValue(event.id);
        setEventStatus(1);
      },
      [onChange, setEventStatus, setEventIdValue],
    );
    const handleSetTechniciansOpened = useCallback(
      (opened: boolean) => () => {
        setTechniciansOpened(opened);
        setSearchTechnician('');
        if (!loadedTechnicians) {
          loadUserTechnicians();
        }
        setTechniciansIds((value + '').split(',').map((id) => +id));
      },
      [setTechniciansOpened, setSearchTechnician, loadedTechnicians, loadUserTechnicians, value],
    );

    const handleSetVendorsOpen = useCallback(
      (opened: boolean) => () => {
        setVendorsOpened(opened);
        setSearchVendors('');
        if (!loadedVendors) {
          loadVendors();
        }
      },
      [loadedVendors, loadVendors],
    );

    const handleSetAddVendorOpen = useCallback(
      (opened: boolean) => () => {
        setOpenAddVendor(opened);
        if (!opened) {
          setNewVendor('');
          loadVendors();
        }
      },
      [loadVendors],
    );

    useEffect(() => {
      if (
        (type === 'technicians' || type === 'technician') &&
        !loadedTechnicians &&
        value !== '0'
      ) {
        loadUserTechnicians();
      }
      if (
        (type === 'technicians' || type === 'technician') &&
        (value === '0' || value === '') &&
        techniciansIds.length === 0
      ) {
        setTechniciansIds([0]);
      }
      if (type === 'vendor' || (type == 'autocomplete-vendor' && !loadedVendors && value !== '0')) {
        loadVendors();
      }
    }, [
      loadUserTechnicians,
      loadedVendors,
      loadVendors,
      type,
      value,
      loadedTechnicians,
      techniciansIds,
    ]);
    const eventAdornment = useMemo(() => {
      if (eventStatus === -1) return <BlockIcon className="FieldEventFailure" />;
      if (eventStatus === 0) return <CircularProgress size={20} />;
      if (eventStatus === -2) return <HelpOutlineIcon className="FieldEventUnknown" />;
      return <CheckIcon className="FieldEventSuccess" />;
    }, [eventStatus]);
    const handleTechniciansSelect = useCallback(() => {
      if (onChange) {
        if (type == 'technicians') {
          onChange(techniciansIds.filter((id) => id > 0).join(','));
        } else {
          const newTech = techniciansIds[0];
          console.log('set new vallue as', newTech);
          onChange(newTech);
        }
      }
      setTechniciansOpened(false);
    }, [onChange, type, techniciansIds]);
    const handleVendorSelect = useCallback(() => {
      if (onChange) {
        onChange(vendorIds.filter((id) => id > 0).join(','));
      }
      setVendorsOpened(false);
    }, [onChange, vendorIds, setVendorsOpened]);
    const handleChangeAutoComplete = useCallback(() => {
      if (onChange) {
        onChange(autoCompleteValue.id);
      }
    }, [onChange, autoCompleteValue]);

    const handleCreateNewVendor = useCallback(async () => {
      if (newVendor != '') {
        const req = Vendor.create();
        req.vendorName = newVendor;
        await VendorClientService.Create(req);
      }
      setNewVendor('');
      setOpenAddVendor(false);
      setLoadedVendors(false);
      loadVendors();
    }, [newVendor, loadVendors]);

    const handleTechnicianChecked = useCallback(
      (id: number) => (checked: Value) => {
        if (id === 0) {
          setTechniciansIds([0]);
        } else if (type === 'technician') {
          setTechniciansIds([id]);
        } else {
          const ids = [
            ...techniciansIds.filter((techId) => {
              if (techId === 0) return false;
              if (!checked && id === techId) return false;
              return true;
            }),
            ...(checked ? [id] : []),
          ];
          setTechniciansIds(ids.length > 0 ? ids : [0]);
        }
      },
      [techniciansIds, setTechniciansIds, type],
    );
    const handleVendorChecked = useCallback(
      (id: number) => (checked: Value) => {
        if (id === 0) {
          setVendorIds([0]);
        } else if (type === 'vendor' || type == 'autocomplete-vendor') {
          setVendorIds([id]);
        } else {
          const ids = [
            ...vendorIds.filter((vendorId) => {
              if (vendorId === 0) return false;
              if (!checked && id === vendorId) return false;
              return true;
            }),
            ...(checked ? [id] : []),
          ];
          setVendorIds(ids.length > 0 ? ids : [0]);
        }
      },
      [vendorIds, setVendorIds, type],
    );
    const { actions = [], description } = props;
    const handleChange = useCallback(
      ({ target: { value } }: any) => {
        if (type === 'eventId') {
          setEventIdValue(+value);
          setEventStatus(-2);
          if (onChange) {
            onChange('');
          }
          return;
        }
        if (onChange) {
          let newValue;
          if (type === 'number') {
            // Convert the value to a float or keep it as a string if not a valid float

            newValue = isNaN(parseFloat(value)) ? value : parseFloat(value);
            console.log('our new value', newValue);
          } else if (type === 'classCode') {
            // Remove any non-integer characters, including decimal points
            newValue = parseInt(value, 10);
            // If the input is not a number, set it to an empty string
            if (isNaN(newValue)) {
              newValue = '';
            }
          } else {
            newValue = value;
          }
          if (type === 'date') {
            newValue = (newValue + ' ' + dateTimePart).trim();
          }
          console.log('value we are sending', newValue);
          onChange(newValue);
        }
      },
      [type, setEventIdValue, dateTimePart, onChange],
    );
    const handleBlur = useCallback(
      ({ target: { value } }: any) => {
        if (onBlur) {
          const newValue = type === 'number' ? +value : value;
          onBlur(newValue);
        }
      },
      [type, onBlur],
    );
    const handleFileChange = useCallback(
      ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        if (!target.files) {
          return;
        }
        const file = Array.from(target.files)?.at(0);
        if (file) {
          const filename = file.name;
          setFilename(filename);
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file);
          fileReader.onload = () => {
            if (onFileLoad) {
              onFileLoad(fileReader.result, filename);
            }
            if (onChange) {
              onChange(filename);
            }
          };
        }
      },
      [setFilename, onFileLoad, onChange],
    );

    const onDeleteFile = useCallback(() => {
      setFilename('');
      if ('onDeleteClick' in props) {
        props.onDeleteClick?.();
      }
      if (onChange) {
        onChange('');
      }
    }, [onChange, props]);

    const handleChangeCheckbox = useCallback(
      (_: any, value: any) => {
        if (onChange) {
          onChange(+value);
        }
      },
      [onChange],
    );
    const handleDateTimeChange = useCallback(
      (date: any, hour: any) => {
        if (onChange) {
          onChange(`${date} ${hour}`);
        }
      },
      [onChange],
    );
    const handleTimeChange = useCallback(
      (hour: any, minutes: any, ampm: any) => {
        if (onChange) {
          let hourVal = +hour;
          if (hourVal === 12 && ampm === 'AM') {
            hourVal = 0;
          }
          if (ampm === 'PM' && hourVal < 12) {
            hourVal += 12;
          }
          onChange(`${trailingZero(hourVal)}:${trailingZero(+minutes)}`);
        }
      },
      [onChange],
    );
    const handleSignatureEnd = useCallback(() => {
      if (onChange && signatureRef !== null && signatureRef.current !== null) {
        // @ts-ignore
        const signature = signatureRef.current.toDataURL();
        onChange(signature);
      }
    }, [onChange, signatureRef]);
    const handleSignatureClear = useCallback(() => {
      if (signatureRef !== null && signatureRef.current !== null) {
        // @ts-ignore
        signatureRef.current.clear();
        if (onChange) {
          onChange('');
        }
      }
    }, [onChange, signatureRef]);
    const inputLabel = label ? (
      <>
        {label}
        {required && !readOnly ? <span className="FieldRequired"> *</span> : ''}
      </>
    ) : null;
    const error = validation !== '';
    const helper =
      validation !== '' || helperText !== '' ? validation + ' ' + helperText : undefined;

    if ((name === undefined || value === undefined) && type !== 'action-group') {
      if (headline) {
        return (
          <Typography component="div" className={clsx('FieldHeadline', { disabled })}>
            {label}
            {description && <span className="FieldDescription">{description}</span>}
            {actions.length > 0 && <Actions actions={actions} fixed />}
          </Typography>
        );
      }
      return (
        <div
          className={clsx('FieldInput', 'FieldContent', className, {
            compact,
            disabled,
          })}
        >
          {content}
          {actions.length > 0 && <Actions actions={actions} fixed />}
        </div>
      );
    }

    if (type === 'action-group') {
      return (
        <div className="self-center justify-self-center ">
          <Actions actions={actions} fixed />
        </div>
      );
    }

    if (type === 'signature') {
      return (
        <div className={clsx('Field', className)} style={style}>
          <div
            style={{
              maxWidth: '100%',
            }}
          >
            <div className="FieldSignatureHeader">
              <InputLabel shrink>{inputLabel}</InputLabel>
              <Button
                label="Clear"
                size="xsmall"
                variant="outlined"
                compact
                onClick={handleSignatureClear}
              />
            </div>
            <span className="FieldSignature">
              <SignatureCanvas
                ref={signatureRef}
                canvasProps={{
                  width: 300,
                  height: 160,
                  className: 'FieldCanvas',
                }}
                options={{
                  onEnd: handleSignatureEnd,
                }}
              />
            </span>
          </div>
        </div>
      );
    }
    if (type === 'datetime') {
      const { value } = props;
      const [valDate, valHour] = String(value || getDefaultValueByType('datetime')).split(' ');
      return (
        <div className="FieldDateTime">
          <Field
            value={valDate}
            name={`${String(name)}_date`}
            type="date"
            label={label}
            readOnly={readOnly}
            onChange={(date) => handleDateTimeChange(date, valHour)}
            required={required}
          />
          <Field
            value={valHour}
            readOnly={readOnly}
            name={`${String(name)}_hour`}
            onChange={(hour) => handleDateTimeChange(valDate, hour)}
          />
        </div>
      );
    }
    if (type === 'time') {
      const { value } = props;
      const [valHour, valMinutes] = String(value || getDefaultValueByType('time')).split(':');
      const hour = +valHour === 0 ? 12 : +valHour > 12 ? +valHour - 12 : +valHour;
      let minutes = +valMinutes;
      if (minutes >= 45) {
        minutes = 45;
      } else if (minutes >= 30) {
        minutes = 30;
      } else if (minutes >= 15) {
        minutes = 15;
      } else {
        minutes = 0;
      }
      const ampm = +valHour < 12 ? 'AM' : 'PM';
      return (
        <div className={clsx('Field', 'FieldHourWrapper', className)} style={style}>
          <InputLabel shrink disabled={disabled}>
            {inputLabel}
          </InputLabel>
          <div className="FieldHour">
            <Field
              name={`${String(name)}_hour`}
              value={trailingZero(hour)}
              options={['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']}
              onChange={(hour) => handleTimeChange(hour, minutes, ampm)}
              style={{ width: 'calc(100% / 3' }}
              disabled={disabled}
              readOnly={readOnly}
            />
            <Field
              name={`${String(name)}_minutes`}
              value={trailingZero(minutes)}
              options={['00', '15', '30', '45']}
              onChange={(minutes) => handleTimeChange(trailingZero(hour), minutes, ampm)}
              style={{ width: 'calc(100% / 3' }}
              disabled={disabled}
              readOnly={readOnly}
            />
            <Field
              name={`${String(name)}_ampm`}
              value={ampm}
              options={['AM', 'PM']}
              onChange={(ampm) => handleTimeChange(trailingZero(hour), minutes, ampm)}
              style={{ width: 'calc(100% / 3' }}
              disabled={disabled}
              readOnly={readOnly}
            />
          </div>
        </div>
      );
    }

    if (type === 'mui-datetime') {
      return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDateTimePicker
            className="Field-Date"
            views={disableMinutesStep ? ['day', 'hours'] : ['day', 'hours', 'minutes']}
            label={inputLabel}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth={true}
                sx={[style, { paddingBottom: '15px' }]}
                variant="outlined"
                inputProps={{
                  readOnly: true,
                  value: (() => {
                    if (value && typeof value === 'string') {
                      return format(parseISO(value), 'yyyy-MM-dd hh:mm:ss');
                    } else {
                      return format(new Date(), 'yyyy-MM-dd hh:mm:ss');
                    }
                  })(),
                  inputMode: 'text',
                }}
              ></TextField>
            )}
            value={parseISO(props.value as unknown as string)}
            //@ts-ignore
            onClose={onClose ? onClose : undefined}
            onChange={(value) =>
              handleChange({
                target: {
                  value: format(value || new Date(), 'yyyy-MM-dd HH:mm:ss'),
                },
              })
            }
            minutesStep={minutesStep}
            disabled={disabled}
            disablePast={'disablePast' in props && props.disablePast}
            disableFuture={'disableFuture' in props && props.disableFuture}
            maxDate={'maxDate' in props ? props.maxDate : undefined}
            minDate={'minDate' in props ? props.minDate : undefined}
          />
        </LocalizationProvider>
      );
    }
    if (type === 'mui-datemonth') {
      return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            views={['year', 'month']}
            minDate={'2000-01-01'}
            openTo="month"
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={label}
                sx={[
                  style,
                  { paddingBottom: '15px', borderColor: 'white' },
                  white
                    ? {
                        svg: { color: 'white' },
                        input: { color: 'white' },
                        label: { color: 'white' },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'white',
                          },
                        },
                      }
                    : {},
                ]}
                inputProps={{
                  ...params.inputProps,
                  readOnly: true,
                  value: (() => {
                    let dateValue: Date;
                    if (typeof value === 'string') {
                      dateValue = parseISO(value);
                    } else if (value instanceof Date) {
                      dateValue = value;
                    } else {
                      // Handle null case, defaulting to the current date
                      dateValue = new Date();
                    }
                    return format(dateValue, 'MMMM-yyyy');
                  })(),
                  inputMode: 'text',
                }}
              ></TextField>
            )}
            value={typeof value === 'string' ? parseISO(value) : value}
            onChange={(value: string | Date | null) => {
              console.log('what we got', value);

              let dateValue: Date;

              if (typeof value === 'string') {
                dateValue = parseISO(value);
              } else if (value instanceof Date) {
                dateValue = value;
              } else {
                // Handle null case, defaulting to the current date
                dateValue = new Date();
              }

              handleChange({
                target: {
                  value: format(dateValue, 'yyyy-MM-dd HH:mm:ss'),
                },
              });
            }}
            disabled={disabled}
          />
        </LocalizationProvider>
      );
    }
    if (type === 'mui-date') {
      return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            className={clsx('FieldInput Field-Date', className, {
              compact,
              disabled,
            })}
            views={['day']}
            renderInput={(params) => (
              <TextField
                className={clsx('FieldInput', className, { compact, disabled })}
                {...params}
                label={label}
                sx={[
                  style,
                  white
                    ? {
                        svg: { color: 'white' },
                        input: { color: 'white' },
                        label: { color: 'white' },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'white',
                          },
                        },
                      }
                    : {},
                ]}
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  readOnly: true,
                  value: (() => {
                    let dateValue: Date;
                    if (typeof value === 'string') {
                      dateValue = parseISO(value);
                    } else if (value instanceof Date && isValid(value)) {
                      dateValue = value;
                    } else {
                      // Handle null case, defaulting to the current date
                      dateValue = new Date();
                    }
                    return format(dateValue, 'MM/dd/yyyy');
                  })(),
                  inputMode: 'text',
                }}
              />
            )}
            value={typeof value === 'string' ? parseISO(value) : value}
            onChange={(value: string | Date | null) => {
              let dateValue: Date;

              if (typeof value === 'string') {
                dateValue = parseISO(value);
              } else if (value instanceof Date) {
                dateValue = value;
              } else {
                // Handle null case, defaulting to the current date
                dateValue = new Date();
              }
              handleChange({
                target: {
                  value: format(
                    dateValue,
                    'dateFormat' in props && props.dateFormat
                      ? props.dateFormat
                      : 'yyyy-MM-dd HH:mm:00',
                  ),
                },
              });
            }}
            disabled={disabled}
            disableFuture={'disableFuture' in props && props.disableFuture}
            maxDate={'maxDate' in props ? props.maxDate : undefined}
            minDate={'minDate' in props ? props.minDate : undefined}
            disablePast={'disablePast' in props && props.disablePast}
          />
        </LocalizationProvider>
      );
    }

    if (type === 'mui-time') {
      return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopTimePicker
            className={clsx('FieldInput Field-Date', className, {
              compact,
              disabled,
            })}
            renderInput={(params) => (
              <TextField
                className={clsx('FieldInput', className, { compact, disabled })}
                {...params}
                label={label}
                variant="outlined"
                inputProps={{
                  readOnly: true,
                  value:
                    typeof value === 'string'
                      ? format(parseISO(value), 'hh:mm aaa')
                      : format(new Date(), 'hh:mm aaa'),
                  inputMode: 'text',
                }}
              />
            )}
            value={parseISO(props.value as unknown as string)}
            onChange={(value) => {
              let hrs: number, min: number;
              if (value) {
                [hrs, min] = timesheetRound(value.getHours(), value.getMinutes());
                value.setHours(hrs);
                value.setMinutes(min);
              }

              handleChange({
                target: {
                  value: format(value || new Date(), 'yyyy-MM-dd HH:mm:ss'),
                },
              });
            }}
            disabled={disabled}
          />
        </LocalizationProvider>
      );
    }

    if (type === 'checkbox') {
      if (removeExcessCheckboxArea) {
        return (
          <FormControl
            className={clsx('FieldInput checkbox', className, { compact, disabled })}
            fullWidth
            disabled={disabled}
            error={error}
            style={style}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox
                tabIndex={0}
                readOnly={readOnly}
                suppressContentEditableWarning={true}
                onKeyDown={(event) => {
                  if (event.keyCode === 32) {
                    console.log('checkbox spacebar');
                    event.stopPropagation();
                    event.preventDefault();
                    if (onChange && typeof value === 'string') {
                      onChange(+value);
                    } else if (onChange && value !== undefined) {
                      onChange(value as Value);
                    }
                  }
                }}
                // @ts-ignore
                checked={+value === 1}
                onChange={handleChangeCheckbox}
                value={value}
                color="primary"
              />
              <span style={{ marginLeft: 8 }}>{inputLabel}</span>
            </div>
            {helper && <FormHelperText>{helper}</FormHelperText>}
          </FormControl>
        );
      } else
        return (
          <FormControl
            className={clsx('FieldInput checkbox', className, {
              compact,
              disabled,
            })}
            fullWidth
            disabled={disabled}
            error={error}
            style={style}
          >
            <FormControlLabel
              control={
                <Checkbox
                  tabIndex={0}
                  readOnly={readOnly}
                  suppressContentEditableWarning={true}
                  onKeyDown={(event) => {
                    if (event.keyCode == 32) {
                      console.log('checkbox spacebar');
                      event.stopPropagation();
                      event.preventDefault();
                      if (onChange && typeof value === 'string') {
                        onChange(+value);
                      } else {
                        if (onChange && value != undefined) {
                          onChange(value as Value);
                        }
                      }
                    }
                  }}
                  // @ts-ignore
                  checked={+value === 1}
                  onChange={handleChangeCheckbox}
                  value={value}
                  color="primary"
                />
              }
              label={inputLabel}
            />
            {helper && <FormHelperText>{helper}</FormHelperText>}
          </FormControl>
        );
    }

    if (type === 'technicians' || type === 'technician') {
      const id = `${String(name)}-technician-label`;
      const ids = (value + '').split(',').map((id) => +id);
      const valueTechnicians =
        ids.length === 1 && ids[0] === 0
          ? 'Unassigned'
          : ids
              .map((id) => {
                const technician = technicians.find((item) => item.id === id);
                if (!technician) return 'Loading...';
                return `${technician.firstname} ${technician.lastname}`;
              })
              .join('\n');
      const searchTechnicianPhrase = (searchTechnician + '').toLowerCase();
      const data: Data = loadedTechnicians
        ? [
            [
              {
                value: (
                  <Field
                    name="technician-0"
                    value={techniciansIds.includes(0)}
                    label="Unassigned"
                    type="checkbox"
                    className="FieldTechnician"
                    onChange={handleTechnicianChecked(0)}
                  />
                ),
              },
            ],
            ...technicians
              .filter((t) => {
                const fullName =
                  t.firstname.toLocaleLowerCase() + ' ' + t.lastname.toLocaleLowerCase();
                return fullName.includes(searchTechnicianPhrase);
              })
              .filter(props.filter || (() => true))

              .map((t) => [
                {
                  value: (
                    <Field
                      name={`technician-${id}`}
                      value={techniciansIds.includes(t.id)}
                      label={`${t.firstname} ${t.lastname}`}
                      type="checkbox"
                      className="FieldTechnician"
                      onChange={handleTechnicianChecked(t.id)}
                    />
                  ),
                },
              ]),
          ]
        : makeFakeRows(1, 30);

      return (
        <>
          <FormControl
            className={clsx('FieldInput technician', className, {
              compact,
              disabled,
            })}
            fullWidth
            disabled={disabled}
            error={error}
          >
            <InputLabel htmlFor={id}>{inputLabel}</InputLabel>
            <div className="FieldTechnicians">
              <Input
                id={id}
                value={valueTechnicians}
                readOnly
                fullWidth
                multiline
                endAdornment={
                  <InputAdornment position="end" className="FieldTechnicianButton">
                    <Button
                      label="Change"
                      variant="outlined"
                      size="xsmall"
                      onClick={handleSetTechniciansOpened(true)}
                      disabled={disabled}
                      compact
                    />
                  </InputAdornment>
                }
              />
            </div>
          </FormControl>
          {techniciansOpened && (
            <Modal open onClose={handleSetTechniciansOpened(false)} fullHeight>
              <SectionBar
                className="technicians-field"
                title={`Select ${technicianAsEmployee ? 'Employee' : 'Technician'}${
                  type === 'technicians' ? '(s)' : ''
                }`}
                subtitle={
                  techniciansIds.length === 1 && techniciansIds[0] === 0
                    ? 'Unassigned'
                    : `${techniciansIds.length} selected`
                }
                actions={[
                  { label: 'Select', onClick: handleTechniciansSelect },
                  {
                    label: 'Close',
                    variant: 'outlined',
                    onClick: handleSetTechniciansOpened(false),
                  },
                ]}
                fixedActions
                footer={
                  <Field
                    className="FieldSearchTechnician"
                    name="searchTechnician"
                    value={searchTechnician}
                    placeholder={`Search ${technicianAsEmployee ? 'employee' : 'technician'}...`}
                    type="search"
                    onChange={setSearchTechnician}
                  />
                }
              />
              <InfoTable data={data} loading={!loadedTechnicians} />
            </Modal>
          )}
        </>
      );
    }
    if (type === 'vendor') {
      const id = `${String(name)}-vendor-label`;
      const ids = (value + '').split(',').map((id) => +id);
      const valueVendors =
        ids.length === 1 && ids[0] === 0
          ? 'Unselected'
          : ids
              .map((id) => {
                const vendor = vendors.find((item) => item.id === id);

                if (!vendor) return 'Loading...';
                return `${vendor.vendorName}`;
              })
              .join('\n');
      const searchVendorPhrase = (searchVendors + '').toLowerCase();

      const data: Data = loadedVendors
        ? [
            [
              {
                value: (
                  <Field
                    name="vendor-0"
                    value={vendorIds.includes(0)}
                    label="Unselected"
                    type="checkbox"
                    className="FieldVendor"
                    onChange={handleVendorChecked(0)}
                  />
                ),
              },
            ],
            ...vendors
              .filter((v) => v.vendorName.toLowerCase().includes(searchVendorPhrase))
              .map((v) => [
                {
                  value: (
                    <Field
                      name={`vendor-${id}`}
                      value={vendorIds.includes(v.id)}
                      label={`${v.vendorName}`}
                      type="checkbox"
                      className="FieldVendor"
                      onChange={handleVendorChecked(v.id)}
                    />
                  ),
                },
              ]),
          ]
        : makeFakeRows(1, 30);
      return (
        <>
          <FormControl
            className={clsx('FieldInput vendor', className, {
              compact,
              disabled,
            })}
            fullWidth
            disabled={disabled}
            error={error}
          >
            <InputLabel htmlFor={id}>{inputLabel}</InputLabel>
            <div className="FieldVendors">
              <Input
                id={id}
                value={valueVendors}
                readOnly
                fullWidth
                multiline
                onKeyUp={(event) => {
                  if (event.key === 'Enter') {
                    event.stopPropagation();
                    event.preventDefault();
                    handleSetVendorsOpen(true)();
                  }
                }}
                endAdornment={
                  <InputAdornment position="end" className="FieldVendorButton">
                    <Button
                      label="Change"
                      variant="outlined"
                      size="xsmall"
                      onClick={handleSetVendorsOpen(true)}
                      disabled={disabled}
                      compact
                    />
                  </InputAdornment>
                }
              />
            </div>
            {openAddVendor && vendorsOpened && (
              <Modal open onClose={handleSetAddVendorOpen(false)}>
                <SectionBar
                  title={`Add Vendor`}
                  actions={[
                    {
                      label: 'Create',
                      variant: 'outlined',
                      onClick: handleCreateNewVendor,
                    },
                    {
                      label: 'Close',
                      variant: 'outlined',
                      onClick: handleSetAddVendorOpen(false),
                    },
                  ]}
                  fixedActions
                  footer={
                    <Field
                      className="FieldSearchVendor"
                      name="newVendor"
                      value={newVendor}
                      placeholder={`Create New Vendor`}
                      type="text"
                      onChange={(data) => setNewVendor(data as string)}
                    />
                  }
                />
              </Modal>
            )}
          </FormControl>
          {vendorsOpened && (
            <Modal open onClose={handleSetVendorsOpen(false)} fullHeight>
              <SectionBar
                title={`Vendors`}
                actions={[
                  { label: 'Select', onClick: handleVendorSelect },
                  {
                    label: 'Add Vendor',
                    variant: 'outlined',
                    onClick: handleSetAddVendorOpen(true),
                  },
                  {
                    label: 'Close',
                    variant: 'outlined',
                    onClick: handleSetVendorsOpen(false),
                  },
                ]}
                footer={
                  <Field
                    className="FieldSearchVendor"
                    name="searchVendor"
                    value={searchVendors}
                    placeholder={`Search Vendors...`}
                    type="search"
                    onChange={setSearchVendors}
                  />
                }
              />
              <InfoTable onEnter={handleVendorSelect} data={data} loading={!loadedVendors} />
            </Modal>
          )}
        </>
      );
    }

    if (type == 'autocomplete-vendor' && loadedVendors) {
      const mappedVendorList = vendors.map((el) => ({
        label: el.vendorName,
        value: el.id,
      }));
      const defaultOption = { id: 0, label: 'None' };
      const options = mappedVendorList.map((option) => ({
        id: option.value,
        label: option.label,
      }));

      options.push(defaultOption);
      return (
        <FormControl
          className={clsx('FieldInput', className, { compact, disabled })}
          fullWidth
          disabled={disabled}
          error={error}
        >
          <div className="FieldVendorAutocomplete">
            <Autocomplete
              options={options}
              renderInput={(params) => <TextField {...params} label="Vendors" />}
              value={autoCompleteValue}
              onChange={(_, newValue) => {
                if (newValue != null) {
                  setAutoCompleteValue(newValue);
                }
              }}
              onSelect={handleChangeAutoComplete}
              isOptionEqualToValue={(option, value) => {
                console.log(option);
                console.log(value);
                return option.id === value.id;
              }}
              inputValue={autoCompleteInputValue}
              onInputChange={(_, newInputValue) => {
                setAutoCompleteInputValue(newInputValue);
              }}
            />
          </div>
        </FormControl>
      );
    }
    if (options) {
      const id = `${String(name)}-select-label`;
      return (
        <div className={clsx('Field select', className, { white })} style={style}>
          <FormControl
            className={clsx('FieldInput select', { compact, disabled })}
            fullWidth
            disabled={disabled}
            error={error}
          >
            {inputLabel && (
              <InputLabel sx={{ paddingTop: '10px' }} id={id} shrink={forceShrinkLabel}>
                {inputLabel}
              </InputLabel>
            )}
            <Select
              labelId={id}
              id={`${String(name)}-select`}
              onChange={handleChange}
              {...props}
              value={value}
              readOnly={readOnly}
              multiple={type === 'multiselect'}
              displayEmpty={displayEmpty}
              renderValue={
                type === 'multiselect'
                  ? (selected) => {
                      const { length } = selected as Value[];
                      return `${length} item${length === 1 ? '' : 's'}`;
                    }
                  : undefined
              }
            >
              {displayEmpty && (
                <MenuItem value="" style={{ fontWeight: 'bold' }}>
                  {defaultLabel}
                </MenuItem>
              )}
              {options.map((option) => {
                const isStringOption = typeof option === 'string';
                const label = isStringOption ? (option as string) : (option as Option).label;
                const valueOption = isStringOption ? (option as string) : (option as Option).value;
                const color = isStringOption ? undefined : (option as Option).color;
                const Icon = isStringOption ? undefined : (option as Option).icon;
                return (
                  <MenuItem key={valueOption} value={valueOption} className="FieldOption" dense>
                    {type === 'multiselect' && (
                      <Checkbox
                        checked={
                          //@ts-ignore
                          value.indexOf(valueOption) > -1
                        }
                        size="small"
                        color="primary"
                      />
                    )}
                    {color && <div className="FieldColor" style={{ backgroundColor: color }} />}
                    {Icon && (
                      <div className="FieldIcon">
                        <Icon />
                      </div>
                    )}
                    {label}
                  </MenuItem>
                );
              })}
            </Select>
            {helper && <FormHelperText>{helper}</FormHelperText>}{' '}
          </FormControl>
          {actions.length > 0 && !actionsInLabel && (
            <Actions className="FieldActions" actions={actions} fixed responsiveColumn />
          )}
          {actions.length > 0 && actionsInLabel && (
            <Actions
              className="FieldActionsInLabel"
              actions={actions.map((item) => ({
                ...item,
                size: 'xsmall',
                compact: true,
              }))}
              fixed
            />
          )}
        </div>
      );
    }
    if (type === 'department') {
      return (
        <div className={clsx('Field department', className)} style={style}>
          <TimsheetDepartmentPicker
            className={clsx('FieldInput', { compact, disabled })}
            filter={
              props.filter ? (props.filter as (a: TimesheetDepartment) => boolean) : undefined
            }
            renderItem={(i) => (
              <option value={i.id} key={`${i.id}-${i.description}`}>
                {i.value} - {i.description}
              </option>
            )}
            label={label || null}
            selected={props.value as unknown as number}
            // TODO validate that pickers onSelect definition doesnt need to change
            // @ts-ignore
            onSelect={onChange}
            disabled={disabled}
            required={required}
            fullWidth
          />
          {actions.length > 0 && !actionsInLabel && (
            <Actions className="FieldActions" actions={actions} fixed responsiveColumn />
          )}
        </div>
      );
    }
    if (type === 'classCode') {
      return (
        <ClassCodePicker
          className={clsx('FieldInput', className, { compact, disabled })}
          renderItem={(i) => (
            <option value={i.id} key={`${i.id}-${i.description}`}>
              {i.id} - {i.description}
            </option>
          )}
          filter={props.filter ? (props.filter as (a: TimesheetClassCode) => boolean) : undefined}
          selected={props.value as unknown as number}
          label={label}
          onSelect={onChange}
          disabled={disabled}
          required={required}
          fullWidth
        />
      );
    }
    return (
      <div className={clsx('Field', className, `type-${type}`)} style={style}>
        {type === 'file' && (
          <input
            id={String(name) + '-file'}
            onChange={handleFileChange}
            type="file"
            className="hidden"
          />
        )}
        <TextField
          inputRef={functionRef}
          multiline={'multiline' in props ? props.multiline : false}
          variant={'standard'}
          className={clsx('FieldInput', { compact, disabled })}
          disabled={disabled}
          onChange={handleChange}
          onBlur={handleBlur}
          label={inputLabel}
          fullWidth
          InputProps={{
            readOnly: type === 'file' ? true : readOnly,
            step: type === 'number' ? 'any' : undefined,
            startAdornment:
              startAdornment || type === 'file' ? (
                <InputAdornment position="start">
                  {startAdornment}
                  {type === 'file' && (
                    <label htmlFor={String(name) + '-file'}>
                      <Button
                        label="Upload file"
                        className="FieldUpload"
                        disabled={disabled}
                        span
                        compact
                      />
                    </label>
                  )}
                </InputAdornment>
              ) : null,
            endAdornment: endAdornment ? (
              <InputAdornment position="end">{endAdornment}</InputAdornment>
            ) : type === 'eventId' ? (
              eventAdornment
            ) : null,
          }}
          onClick={(event: MouseEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            if (type === 'search' && target.value) {
              const clickX = event.clientX - target.getBoundingClientRect().left;
              const clickY = event.clientY - target.getBoundingClientRect().top;
              const inputWidth = target.clientWidth;
              const inputHeight = target.clientHeight;
              const xCond = inputWidth - clickX;
              const yCond = inputHeight - clickY;
              if (xCond <= 14 && yCond > 10 && yCond < 21) {
                onClear?.();
              }
            }
          }}
          onKeyPress={(event) => {
            const keyCode = event.which || event.keyCode;
            if (type === 'number') {
              // Allow all numbers including decimals and negatives
              if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 45) {
                event.preventDefault();
              }
            } else if (type === 'eventId') {
              // Allow only positive integers
              if (keyCode < 48 || keyCode > 57) {
                event.preventDefault();
              }
            }
          }}
          InputLabelProps={{
            shrink: true,
          }}
          error={error}
          {...(props as any)}
          type={type === 'file' ? 'text' : type === 'eventId' ? 'number' : type}
          value={
            type === 'file' ? filename || value : type === 'eventId' ? eventIdValue : value // Need to change this value to being the initial value passed
            // done via state
          }
          helperText={helper}
        />
        {type === 'file' && 'withDeleteButton' in props && props.withDeleteButton && (
          <Button label="Delete" onClick={onDeleteFile} variant="outlined" />
        )}
        {actions.length > 0 && !actionsInLabel && (
          <Actions className="FieldActions" actions={actions} fixed responsiveColumn />
        )}
        {((actions.length > 0 && actionsInLabel) || type === 'eventId') && (
          <Actions
            className="FieldActionsInLabel eventId"
            actions={[
              ...actions,
              ...(type === 'eventId'
                ? [
                    {
                      label: 'Search Job Number',
                      variant: 'outlined' as const,
                      onClick: handleEventsSearchClicked,
                    },
                  ]
                : []),
            ].map((item) => ({
              ...item,
              size: 'xsmall' as const,
              compact: true,
            }))}
            fixed
          />
        )}
        {eventsOpened && (
          <Dialog open onOpenChange={handleEventsOpenedToggle(false)}>
            <DialogContent className="h-screen max-w-full overflow-auto p-0">
              <AdvancedSearch
                title="Job Search"
                showRecentServiceCallsForEmployee={technicianIdForRecentServiceCalls ? true : false}
                kinds={kindsSearch}
                onSelectEvent={handleEventSelect}
                onClose={handleEventsOpenedToggle(false)}
                omitArchivedJobs={omitArchivedJobs}
              />
            </DialogContent>
          </Dialog>
        )}
      </div>
    );
  },
);

const timesheetRound = function timesheetRound(hr: number, min: number): [number, number] {
  if (min > 52) {
    hr += 1;
  }

  min = ((((min + 7.5) / 15) | 0) * 15) % 60;
  return [hr, min];
};
