import { BaseClient } from '../BaseClient';
import { Bool, type DateRange, Empty,type Int32  } from '../compiled-protos/common';
import {
  DismissalMsg,
  DispatchableTech,
  DispatchableTechList,
  DispatchCall,
  DispatchCallBack,
  DispatchCallBacksList,
  DispatchCallCount,
  DispatchCallCountList,
  DispatchCallList,
  DispatchCallTime,
  DispatchCallTimeList,
  DispatchFirstCall,
  DispatchFirstCallList,
} from '../compiled-protos/dispatch';
import { DispatchServiceClient } from '../compiled-protos/dispatch.client';
import {
  TimeoffRequest,
  TimeoffRequestList,
} from '../compiled-protos/timesheet_line';

class DispatchClient extends BaseClient {
  self: DispatchServiceClient;

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new DispatchServiceClient(this.transport);
  }

  public async DispatchMessage(req: Int32) {
    let res = Bool.create();
    try {
      res = await this.self.dispatchMessage(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async DismissalMessage(
    userID: number,
    dismiss: boolean,
    dispatcherID = 0
  ) {
    const req = DismissalMsg.create();
    req.userId = userID;
    req.dismiss = dismiss;
    req.dispatcherId = dispatcherID;
    let res = Empty.create();
    try {
      res = await this.self.dismissalMessage(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async GetDispatchableTechnicians(req: DispatchableTech) {
    let res = DispatchableTechList.create();
    try {
      res = await this.self.getDispatchableTechnicians(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async GetDispatchCalls(req: DispatchCall) {
    let res = DispatchCallList.create();
    try {
      res = await this.self.getDispatchCalls(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async GetTimeoffData(req: TimeoffRequest) {
    let res = TimeoffRequestList.create();
    try {
      res = await this.self.getTimeoffData(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async GetDispatchCallBacks(req: DateRange) {
    let res = DispatchCallBacksList.create();
    try {
      res = await this.self.getDispatchCallBacks(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async GetDispatchCallTimes(req: DateRange) {
    let res = DispatchCallTimeList.create();
    try {
      res = await this.self.getDispatchCallTimes(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async GetDispatchCallCount(req: DateRange) {
    let res = DispatchCallCountList.create();
    try {
      res = await this.self.getDispatchCallCount(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async GetDispatchFirstCall() {
    const req = Empty.create();
    let res = DispatchFirstCallList.create();
    try {
      res = await this.self.getDispatchFirstCall(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }
}

export {
  DispatchableTechList,
  DispatchableTech,
  DispatchClient,
  DispatchCall,
  DispatchCallBack,
  DispatchCallTime,
  DispatchCallCount,
  DispatchFirstCall,
  TimeoffRequest,
};
