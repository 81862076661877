import { Property, type PropertyList } from '@kalos/kalos-rpc';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';

import { PropertyClientService } from '../../tools/helpers';
import { queryKeys } from './constants';
import { type EntityFilter } from './utils';

export const usePropertiesQueryByRequest = ({
  enabled = true,
  request,
}: {
  enabled?: boolean;
  request: Property;
}) => {
  return useQuery({
    queryKey: [queryKeys.properties.root, queryKeys.properties.getRequestHash(request)],
    enabled,
    queryFn: () => {
      return PropertyClientService.BatchGet(request);
    },
  });
};

export type PropertyFilter = EntityFilter<Property>;
export const usePropertiesBatchGetQuery = <TSelectData = PropertyList,>({
  filter = {},
  enabled = true,
  select,
}: {
  filter?: PropertyFilter;
  enabled?: boolean;
  select?: (data?: PropertyList) => TSelectData;
} = {}) => {
  return useQuery({
    queryFn: () => {
      const req = Property.create(filter);
      return PropertyClientService.BatchGet(req);
    },
    queryKey: [
      queryKeys.properties.root,
      queryKeys.properties.list,
      queryKeys.properties.getHash(filter),
    ],
    enabled,
    select,
  });
};

export const usePropertiesGetQuery = <TSelectData = Property,>({
  filter = {},
  enabled = true,
  select,
}: {
  filter?: PropertyFilter;
  enabled?: boolean;
  select?: (data?: Property) => TSelectData;
} = {}) => {
  return useQuery({
    queryFn: () => {
      const req = Property.create(filter);
      return PropertyClientService.Get(req);
    },
    queryKey: [queryKeys.properties.root, queryKeys.properties.getHash(filter)],
    enabled,
    select,
  });
};

export const usePropertyQueryInline = () => {
  const queryClient = useQueryClient();

  const fetch = useCallback(
    async ({ filter = {} }: { filter?: PropertyFilter } = {}) => {
      const queryKey = [queryKeys.properties.root, queryKeys.properties.getHash(filter)];
      const cache = queryClient.getQueryData<Property>(queryKey);
      if (!cache) {
        const req = Property.create(filter);
        const event = await PropertyClientService.Get(req);
        if (!event) throw new Error('Property not found');
        queryClient.setQueryData(queryKey, event);
        return event;
      }
      return cache;
    },
    [queryClient],
  );

  return useMemo(() => ({ fetch }), [fetch]);
};
