// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "perdiem.proto" (syntax proto3)
// tslint:disable
// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "perdiem.proto" (syntax proto3)
// tslint:disable
import { String$ } from "./common";
import { IntArray } from "./common";
import { Double } from "./common";
import { Int32 } from "./common";
import { Empty } from "./common";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { WireType } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { TimesheetDepartment } from "./timesheet_department";
// @generated message type with reflection information, may provide speed optimized methods
class Months$Type extends MessageType {
    constructor() {
        super("Months", [
            { no: 1, name: "month", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Month }
        ]);
    }
    create(value) {
        const message = { month: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Month month */ 1:
                    message.month.push(Month.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated Month month = 1; */
        for (let i = 0; i < message.month.length; i++)
            Month.internalBinaryWrite(message.month[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Months
 */
export const Months = new Months$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Month$Type extends MessageType {
    constructor() {
        super("Month", [
            { no: 1, name: "value", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "number", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "short", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "long", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { value: 0, number: 0, short: "", long: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 value */ 1:
                    message.value = reader.uint32();
                    break;
                case /* uint32 number */ 2:
                    message.number = reader.uint32();
                    break;
                case /* string short */ 3:
                    message.short = reader.string();
                    break;
                case /* string long */ 4:
                    message.long = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* uint32 value = 1; */
        if (message.value !== 0)
            writer.tag(1, WireType.Varint).uint32(message.value);
        /* uint32 number = 2; */
        if (message.number !== 0)
            writer.tag(2, WireType.Varint).uint32(message.number);
        /* string short = 3; */
        if (message.short !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.short);
        /* string long = 4; */
        if (message.long !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.long);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Month
 */
export const Month = new Month$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Rate$Type extends MessageType {
    constructor() {
        super("Rate", [
            { no: 1, name: "months", kind: "message", T: () => Months },
            { no: 2, name: "meals", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "zip", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "county", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "city", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "standardRate", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { meals: 0, zip: "", county: "", city: "", standardRate: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* Months months */ 1:
                    message.months = Months.internalBinaryRead(reader, reader.uint32(), options, message.months);
                    break;
                case /* uint32 meals */ 2:
                    message.meals = reader.uint32();
                    break;
                case /* string zip */ 3:
                    message.zip = reader.string();
                    break;
                case /* string county */ 4:
                    message.county = reader.string();
                    break;
                case /* string city */ 5:
                    message.city = reader.string();
                    break;
                case /* string standardRate */ 6:
                    message.standardRate = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* Months months = 1; */
        if (message.months)
            Months.internalBinaryWrite(message.months, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* uint32 meals = 2; */
        if (message.meals !== 0)
            writer.tag(2, WireType.Varint).uint32(message.meals);
        /* string zip = 3; */
        if (message.zip !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.zip);
        /* string county = 4; */
        if (message.county !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.county);
        /* string city = 5; */
        if (message.city !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.city);
        /* string standardRate = 6; */
        if (message.standardRate !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.standardRate);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Rate
 */
export const Rate = new Rate$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Rates$Type extends MessageType {
    constructor() {
        super("Rates", [
            { no: 1, name: "oconusInfo", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "rate", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Rate },
            { no: 3, name: "state", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "year", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 5, name: "isOconus", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { oconusInfo: "", rate: [], state: "", year: 0, isOconus: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string oconusInfo */ 1:
                    message.oconusInfo = reader.string();
                    break;
                case /* repeated Rate rate */ 2:
                    message.rate.push(Rate.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string state */ 3:
                    message.state = reader.string();
                    break;
                case /* uint32 year */ 4:
                    message.year = reader.uint32();
                    break;
                case /* string isOconus */ 5:
                    message.isOconus = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string oconusInfo = 1; */
        if (message.oconusInfo !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.oconusInfo);
        /* repeated Rate rate = 2; */
        for (let i = 0; i < message.rate.length; i++)
            Rate.internalBinaryWrite(message.rate[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string state = 3; */
        if (message.state !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.state);
        /* uint32 year = 4; */
        if (message.year !== 0)
            writer.tag(4, WireType.Varint).uint32(message.year);
        /* string isOconus = 5; */
        if (message.isOconus !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.isOconus);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Rates
 */
export const Rates = new Rates$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GovPerDiemResponse$Type extends MessageType {
    constructor() {
        super("GovPerDiemResponse", [
            { no: 1, name: "request", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "errors", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "rates", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Rates },
            { no: 4, name: "version", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { request: "", errors: "", rates: [], version: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string request */ 1:
                    message.request = reader.string();
                    break;
                case /* string errors */ 2:
                    message.errors = reader.string();
                    break;
                case /* repeated Rates rates */ 3:
                    message.rates.push(Rates.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string version */ 4:
                    message.version = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string request = 1; */
        if (message.request !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.request);
        /* string errors = 2; */
        if (message.errors !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.errors);
        /* repeated Rates rates = 3; */
        for (let i = 0; i < message.rates.length; i++)
            Rates.internalBinaryWrite(message.rates[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string version = 4; */
        if (message.version !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.version);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GovPerDiemResponse
 */
export const GovPerDiemResponse = new GovPerDiemResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SQLRequest$Type extends MessageType {
    constructor() {
        super("SQLRequest", [
            { no: 1, name: "data", kind: "message", T: () => PerDiem },
            { no: 2, name: "request_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { requestType: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* PerDiem data */ 1:
                    message.data = PerDiem.internalBinaryRead(reader, reader.uint32(), options, message.data);
                    break;
                case /* string request_type */ 2:
                    message.requestType = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* PerDiem data = 1; */
        if (message.data)
            PerDiem.internalBinaryWrite(message.data, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string request_type = 2; */
        if (message.requestType !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.requestType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SQLRequest
 */
export const SQLRequest = new SQLRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PerDiemReportRequest$Type extends MessageType {
    constructor() {
        super("PerDiemReportRequest", [
            { no: 1, name: "week", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "user_id", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "department_id", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { week: [], userId: [], departmentId: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string week */ 1:
                    message.week.push(reader.string());
                    break;
                case /* repeated int32 user_id */ 2:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.userId.push(reader.int32());
                    else
                        message.userId.push(reader.int32());
                    break;
                case /* repeated int32 department_id */ 3:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.departmentId.push(reader.int32());
                    else
                        message.departmentId.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated string week = 1; */
        for (let i = 0; i < message.week.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.week[i]);
        /* repeated int32 user_id = 2; */
        if (message.userId.length) {
            writer.tag(2, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.userId.length; i++)
                writer.int32(message.userId[i]);
            writer.join();
        }
        /* repeated int32 department_id = 3; */
        if (message.departmentId.length) {
            writer.tag(3, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.departmentId.length; i++)
                writer.int32(message.departmentId[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PerDiemReportRequest
 */
export const PerDiemReportRequest = new PerDiemReportRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PerDiem$Type extends MessageType {
    constructor() {
        super("PerDiem", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "date_started", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "department_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "owner_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 8, name: "department", kind: "message", T: () => TimesheetDepartment },
            { no: 9, name: "with_rows", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 10, name: "rows", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PerDiemRow },
            { no: 11, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 13, name: "date_submitted", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "date_approved", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "approved_by_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 16, name: "approved_by_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "needs_auditing", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 18, name: "without_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 19, name: "payroll_processed", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 20, name: "not_equals", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 21, name: "reference_row", kind: "message", T: () => PerDiemRow },
            { no: 22, name: "date_range", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 23, name: "date_target", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 24, name: "date_processed", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 25, name: "amount_processed_lodging", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 26, name: "amount_processed_meals", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 27, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 28, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 29, name: "group_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, dateStarted: "", userId: 0, departmentId: 0, notes: "", ownerName: "", isActive: false, withRows: false, rows: [], fieldMask: [], pageNumber: 0, dateSubmitted: "", dateApproved: "", approvedById: 0, approvedByName: "", needsAuditing: false, withoutLimit: false, payrollProcessed: false, notEquals: [], dateRange: [], dateTarget: [], dateProcessed: "", amountProcessedLodging: 0, amountProcessedMeals: 0, orderBy: "", orderDir: "", groupBy: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string date_started */ 2:
                    message.dateStarted = reader.string();
                    break;
                case /* int32 user_id */ 3:
                    message.userId = reader.int32();
                    break;
                case /* int32 department_id */ 4:
                    message.departmentId = reader.int32();
                    break;
                case /* string notes */ 5:
                    message.notes = reader.string();
                    break;
                case /* string owner_name */ 6:
                    message.ownerName = reader.string();
                    break;
                case /* bool is_active */ 7:
                    message.isActive = reader.bool();
                    break;
                case /* TimesheetDepartment department */ 8:
                    message.department = TimesheetDepartment.internalBinaryRead(reader, reader.uint32(), options, message.department);
                    break;
                case /* bool with_rows */ 9:
                    message.withRows = reader.bool();
                    break;
                case /* repeated PerDiemRow rows */ 10:
                    message.rows.push(PerDiemRow.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated string field_mask */ 11:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 12:
                    message.pageNumber = reader.int32();
                    break;
                case /* string date_submitted */ 13:
                    message.dateSubmitted = reader.string();
                    break;
                case /* string date_approved */ 14:
                    message.dateApproved = reader.string();
                    break;
                case /* int32 approved_by_id */ 15:
                    message.approvedById = reader.int32();
                    break;
                case /* string approved_by_name */ 16:
                    message.approvedByName = reader.string();
                    break;
                case /* bool needs_auditing */ 17:
                    message.needsAuditing = reader.bool();
                    break;
                case /* bool without_limit */ 18:
                    message.withoutLimit = reader.bool();
                    break;
                case /* bool payroll_processed */ 19:
                    message.payrollProcessed = reader.bool();
                    break;
                case /* repeated string not_equals */ 20:
                    message.notEquals.push(reader.string());
                    break;
                case /* PerDiemRow reference_row */ 21:
                    message.referenceRow = PerDiemRow.internalBinaryRead(reader, reader.uint32(), options, message.referenceRow);
                    break;
                case /* repeated string date_range */ 22:
                    message.dateRange.push(reader.string());
                    break;
                case /* repeated string date_target */ 23:
                    message.dateTarget.push(reader.string());
                    break;
                case /* string date_processed */ 24:
                    message.dateProcessed = reader.string();
                    break;
                case /* double amount_processed_lodging */ 25:
                    message.amountProcessedLodging = reader.double();
                    break;
                case /* double amount_processed_meals */ 26:
                    message.amountProcessedMeals = reader.double();
                    break;
                case /* string order_by */ 27:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 28:
                    message.orderDir = reader.string();
                    break;
                case /* string group_by */ 29:
                    message.groupBy = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string date_started = 2; */
        if (message.dateStarted !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.dateStarted);
        /* int32 user_id = 3; */
        if (message.userId !== 0)
            writer.tag(3, WireType.Varint).int32(message.userId);
        /* int32 department_id = 4; */
        if (message.departmentId !== 0)
            writer.tag(4, WireType.Varint).int32(message.departmentId);
        /* string notes = 5; */
        if (message.notes !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.notes);
        /* string owner_name = 6; */
        if (message.ownerName !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.ownerName);
        /* bool is_active = 7; */
        if (message.isActive !== false)
            writer.tag(7, WireType.Varint).bool(message.isActive);
        /* TimesheetDepartment department = 8; */
        if (message.department)
            TimesheetDepartment.internalBinaryWrite(message.department, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* bool with_rows = 9; */
        if (message.withRows !== false)
            writer.tag(9, WireType.Varint).bool(message.withRows);
        /* repeated PerDiemRow rows = 10; */
        for (let i = 0; i < message.rows.length; i++)
            PerDiemRow.internalBinaryWrite(message.rows[i], writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* repeated string field_mask = 11; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(11, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 12; */
        if (message.pageNumber !== 0)
            writer.tag(12, WireType.Varint).int32(message.pageNumber);
        /* string date_submitted = 13; */
        if (message.dateSubmitted !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.dateSubmitted);
        /* string date_approved = 14; */
        if (message.dateApproved !== "")
            writer.tag(14, WireType.LengthDelimited).string(message.dateApproved);
        /* int32 approved_by_id = 15; */
        if (message.approvedById !== 0)
            writer.tag(15, WireType.Varint).int32(message.approvedById);
        /* string approved_by_name = 16; */
        if (message.approvedByName !== "")
            writer.tag(16, WireType.LengthDelimited).string(message.approvedByName);
        /* bool needs_auditing = 17; */
        if (message.needsAuditing !== false)
            writer.tag(17, WireType.Varint).bool(message.needsAuditing);
        /* bool without_limit = 18; */
        if (message.withoutLimit !== false)
            writer.tag(18, WireType.Varint).bool(message.withoutLimit);
        /* bool payroll_processed = 19; */
        if (message.payrollProcessed !== false)
            writer.tag(19, WireType.Varint).bool(message.payrollProcessed);
        /* repeated string not_equals = 20; */
        for (let i = 0; i < message.notEquals.length; i++)
            writer.tag(20, WireType.LengthDelimited).string(message.notEquals[i]);
        /* PerDiemRow reference_row = 21; */
        if (message.referenceRow)
            PerDiemRow.internalBinaryWrite(message.referenceRow, writer.tag(21, WireType.LengthDelimited).fork(), options).join();
        /* repeated string date_range = 22; */
        for (let i = 0; i < message.dateRange.length; i++)
            writer.tag(22, WireType.LengthDelimited).string(message.dateRange[i]);
        /* repeated string date_target = 23; */
        for (let i = 0; i < message.dateTarget.length; i++)
            writer.tag(23, WireType.LengthDelimited).string(message.dateTarget[i]);
        /* string date_processed = 24; */
        if (message.dateProcessed !== "")
            writer.tag(24, WireType.LengthDelimited).string(message.dateProcessed);
        /* double amount_processed_lodging = 25; */
        if (message.amountProcessedLodging !== 0)
            writer.tag(25, WireType.Bit64).double(message.amountProcessedLodging);
        /* double amount_processed_meals = 26; */
        if (message.amountProcessedMeals !== 0)
            writer.tag(26, WireType.Bit64).double(message.amountProcessedMeals);
        /* string order_by = 27; */
        if (message.orderBy !== "")
            writer.tag(27, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 28; */
        if (message.orderDir !== "")
            writer.tag(28, WireType.LengthDelimited).string(message.orderDir);
        /* string group_by = 29; */
        if (message.groupBy !== "")
            writer.tag(29, WireType.LengthDelimited).string(message.groupBy);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PerDiem
 */
export const PerDiem = new PerDiem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PerDiemList$Type extends MessageType {
    constructor() {
        super("PerDiemList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PerDiem },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated PerDiem results */ 1:
                    message.results.push(PerDiem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated PerDiem results = 1; */
        for (let i = 0; i < message.results.length; i++)
            PerDiem.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PerDiemList
 */
export const PerDiemList = new PerDiemList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PerDiemRow$Type extends MessageType {
    constructor() {
        super("PerDiemRow", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "per_diem_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "zip_code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "service_call_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "date_string", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "meals_only", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 8, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "lodging_request_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, perDiemId: 0, zipCode: "", serviceCallId: 0, notes: "", dateString: "", mealsOnly: false, fieldMask: [], lodgingRequestId: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 per_diem_id */ 2:
                    message.perDiemId = reader.int32();
                    break;
                case /* string zip_code */ 3:
                    message.zipCode = reader.string();
                    break;
                case /* int32 service_call_id */ 4:
                    message.serviceCallId = reader.int32();
                    break;
                case /* string notes */ 5:
                    message.notes = reader.string();
                    break;
                case /* string date_string */ 6:
                    message.dateString = reader.string();
                    break;
                case /* bool meals_only */ 7:
                    message.mealsOnly = reader.bool();
                    break;
                case /* repeated string field_mask */ 8:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 lodging_request_id */ 9:
                    message.lodgingRequestId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 per_diem_id = 2; */
        if (message.perDiemId !== 0)
            writer.tag(2, WireType.Varint).int32(message.perDiemId);
        /* string zip_code = 3; */
        if (message.zipCode !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.zipCode);
        /* int32 service_call_id = 4; */
        if (message.serviceCallId !== 0)
            writer.tag(4, WireType.Varint).int32(message.serviceCallId);
        /* string notes = 5; */
        if (message.notes !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.notes);
        /* string date_string = 6; */
        if (message.dateString !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.dateString);
        /* bool meals_only = 7; */
        if (message.mealsOnly !== false)
            writer.tag(7, WireType.Varint).bool(message.mealsOnly);
        /* repeated string field_mask = 8; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(8, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 lodging_request_id = 9; */
        if (message.lodgingRequestId !== 0)
            writer.tag(9, WireType.Varint).int32(message.lodgingRequestId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PerDiemRow
 */
export const PerDiemRow = new PerDiemRow$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Trip$Type extends MessageType {
    constructor() {
        super("Trip", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "distance_in_miles", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 3, name: "origin_address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "destination_address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "per_diem_row_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "payroll_processed", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 10, name: "page", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 11, name: "approved", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 12, name: "department_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 13, name: "date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "job_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 15, name: "calculated_duration_in_seconds", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 16, name: "not_equals", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "user_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 18, name: "department_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 19, name: "admin_action_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 20, name: "date_created", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 21, name: "home_travel", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 22, name: "date_processed", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 23, name: "date_range", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 24, name: "date_target", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 25, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 26, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 27, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 28, name: "group_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 29, name: "without_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, distanceInMiles: 0, originAddress: "", destinationAddress: "", perDiemRowId: 0, fieldMask: [], userId: 0, notes: "", payrollProcessed: false, page: 0, approved: false, departmentId: 0, date: "", jobNumber: 0, calculatedDurationInSeconds: 0, notEquals: [], userName: "", departmentName: "", adminActionDate: "", dateCreated: "", homeTravel: false, dateProcessed: "", dateRange: [], dateTarget: [], isActive: false, orderBy: "", orderDir: "", groupBy: "", withoutLimit: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* double distance_in_miles */ 2:
                    message.distanceInMiles = reader.double();
                    break;
                case /* string origin_address */ 3:
                    message.originAddress = reader.string();
                    break;
                case /* string destination_address */ 4:
                    message.destinationAddress = reader.string();
                    break;
                case /* int32 per_diem_row_id */ 5:
                    message.perDiemRowId = reader.int32();
                    break;
                case /* repeated string field_mask */ 6:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 user_id */ 7:
                    message.userId = reader.int32();
                    break;
                case /* string notes */ 8:
                    message.notes = reader.string();
                    break;
                case /* bool payroll_processed */ 9:
                    message.payrollProcessed = reader.bool();
                    break;
                case /* int32 page */ 10:
                    message.page = reader.int32();
                    break;
                case /* bool approved */ 11:
                    message.approved = reader.bool();
                    break;
                case /* int32 department_id */ 12:
                    message.departmentId = reader.int32();
                    break;
                case /* string date */ 13:
                    message.date = reader.string();
                    break;
                case /* int32 job_number */ 14:
                    message.jobNumber = reader.int32();
                    break;
                case /* int32 calculated_duration_in_seconds */ 15:
                    message.calculatedDurationInSeconds = reader.int32();
                    break;
                case /* repeated string not_equals */ 16:
                    message.notEquals.push(reader.string());
                    break;
                case /* string user_name */ 17:
                    message.userName = reader.string();
                    break;
                case /* string department_name */ 18:
                    message.departmentName = reader.string();
                    break;
                case /* string admin_action_date */ 19:
                    message.adminActionDate = reader.string();
                    break;
                case /* string date_created */ 20:
                    message.dateCreated = reader.string();
                    break;
                case /* bool home_travel */ 21:
                    message.homeTravel = reader.bool();
                    break;
                case /* string date_processed */ 22:
                    message.dateProcessed = reader.string();
                    break;
                case /* repeated string date_range */ 23:
                    message.dateRange.push(reader.string());
                    break;
                case /* repeated string date_target */ 24:
                    message.dateTarget.push(reader.string());
                    break;
                case /* bool is_active */ 25:
                    message.isActive = reader.bool();
                    break;
                case /* string order_by */ 26:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 27:
                    message.orderDir = reader.string();
                    break;
                case /* string group_by */ 28:
                    message.groupBy = reader.string();
                    break;
                case /* bool without_limit */ 29:
                    message.withoutLimit = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* double distance_in_miles = 2; */
        if (message.distanceInMiles !== 0)
            writer.tag(2, WireType.Bit64).double(message.distanceInMiles);
        /* string origin_address = 3; */
        if (message.originAddress !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.originAddress);
        /* string destination_address = 4; */
        if (message.destinationAddress !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.destinationAddress);
        /* int32 per_diem_row_id = 5; */
        if (message.perDiemRowId !== 0)
            writer.tag(5, WireType.Varint).int32(message.perDiemRowId);
        /* repeated string field_mask = 6; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(6, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 user_id = 7; */
        if (message.userId !== 0)
            writer.tag(7, WireType.Varint).int32(message.userId);
        /* string notes = 8; */
        if (message.notes !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.notes);
        /* bool payroll_processed = 9; */
        if (message.payrollProcessed !== false)
            writer.tag(9, WireType.Varint).bool(message.payrollProcessed);
        /* int32 page = 10; */
        if (message.page !== 0)
            writer.tag(10, WireType.Varint).int32(message.page);
        /* bool approved = 11; */
        if (message.approved !== false)
            writer.tag(11, WireType.Varint).bool(message.approved);
        /* int32 department_id = 12; */
        if (message.departmentId !== 0)
            writer.tag(12, WireType.Varint).int32(message.departmentId);
        /* string date = 13; */
        if (message.date !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.date);
        /* int32 job_number = 14; */
        if (message.jobNumber !== 0)
            writer.tag(14, WireType.Varint).int32(message.jobNumber);
        /* int32 calculated_duration_in_seconds = 15; */
        if (message.calculatedDurationInSeconds !== 0)
            writer.tag(15, WireType.Varint).int32(message.calculatedDurationInSeconds);
        /* repeated string not_equals = 16; */
        for (let i = 0; i < message.notEquals.length; i++)
            writer.tag(16, WireType.LengthDelimited).string(message.notEquals[i]);
        /* string user_name = 17; */
        if (message.userName !== "")
            writer.tag(17, WireType.LengthDelimited).string(message.userName);
        /* string department_name = 18; */
        if (message.departmentName !== "")
            writer.tag(18, WireType.LengthDelimited).string(message.departmentName);
        /* string admin_action_date = 19; */
        if (message.adminActionDate !== "")
            writer.tag(19, WireType.LengthDelimited).string(message.adminActionDate);
        /* string date_created = 20; */
        if (message.dateCreated !== "")
            writer.tag(20, WireType.LengthDelimited).string(message.dateCreated);
        /* bool home_travel = 21; */
        if (message.homeTravel !== false)
            writer.tag(21, WireType.Varint).bool(message.homeTravel);
        /* string date_processed = 22; */
        if (message.dateProcessed !== "")
            writer.tag(22, WireType.LengthDelimited).string(message.dateProcessed);
        /* repeated string date_range = 23; */
        for (let i = 0; i < message.dateRange.length; i++)
            writer.tag(23, WireType.LengthDelimited).string(message.dateRange[i]);
        /* repeated string date_target = 24; */
        for (let i = 0; i < message.dateTarget.length; i++)
            writer.tag(24, WireType.LengthDelimited).string(message.dateTarget[i]);
        /* bool is_active = 25; */
        if (message.isActive !== false)
            writer.tag(25, WireType.Varint).bool(message.isActive);
        /* string order_by = 26; */
        if (message.orderBy !== "")
            writer.tag(26, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 27; */
        if (message.orderDir !== "")
            writer.tag(27, WireType.LengthDelimited).string(message.orderDir);
        /* string group_by = 28; */
        if (message.groupBy !== "")
            writer.tag(28, WireType.LengthDelimited).string(message.groupBy);
        /* bool without_limit = 29; */
        if (message.withoutLimit !== false)
            writer.tag(29, WireType.Varint).bool(message.withoutLimit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Trip
 */
export const Trip = new Trip$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TripList$Type extends MessageType {
    constructor() {
        super("TripList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Trip },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Trip results */ 1:
                    message.results.push(Trip.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated Trip results = 1; */
        for (let i = 0; i < message.results.length; i++)
            Trip.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TripList
 */
export const TripList = new TripList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PerDiemRowList$Type extends MessageType {
    constructor() {
        super("PerDiemRowList", [
            { no: 1, name: "rows", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PerDiemRow },
            { no: 2, name: "count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { rows: [], count: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated PerDiemRow rows */ 1:
                    message.rows.push(PerDiemRow.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 count */ 2:
                    message.count = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated PerDiemRow rows = 1; */
        for (let i = 0; i < message.rows.length; i++)
            PerDiemRow.internalBinaryWrite(message.rows[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 count = 2; */
        if (message.count !== 0)
            writer.tag(2, WireType.Varint).int32(message.count);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PerDiemRowList
 */
export const PerDiemRowList = new PerDiemRowList$Type();
/**
 * @generated ServiceType for protobuf service PerDiemService
 */
export const PerDiemService = new ServiceType("PerDiemService", [
    { name: "Create", options: {}, I: PerDiem, O: PerDiem },
    { name: "Get", options: {}, I: PerDiem, O: PerDiem },
    { name: "BatchGet", options: {}, I: PerDiem, O: PerDiemList },
    { name: "Update", options: {}, I: PerDiem, O: PerDiem },
    { name: "Delete", options: {}, I: PerDiem, O: PerDiem },
    { name: "CreateRow", options: {}, I: PerDiemRow, O: PerDiemRow },
    { name: "UpdateRow", options: {}, I: PerDiemRow, O: PerDiemRow },
    { name: "GetRow", options: {}, I: PerDiemRow, O: PerDiemRow },
    { name: "DeleteRow", options: {}, I: PerDiemRow, O: Empty },
    { name: "GetPerDiemReport", options: {}, I: PerDiemReportRequest, O: PerDiemList },
    { name: "CreateTrip", options: {}, I: Trip, O: Trip },
    { name: "UpdateTrip", options: {}, I: Trip, O: Trip },
    { name: "GetTrip", options: {}, I: Trip, O: Trip },
    { name: "DeleteTrip", options: {}, I: Trip, O: Empty },
    { name: "BatchGetTrips", options: {}, I: Trip, O: TripList },
    { name: "GetTotalRowTripDistance", options: {}, I: Int32, O: Double },
    { name: "BatchDeleteTrips", options: {}, I: Trip, O: Trip },
    { name: "BatchGetPerDiemsByIds", options: {}, I: IntArray, O: PerDiemList },
    { name: "GetDebugSQLOutput", options: {}, I: SQLRequest, O: String$ },
    { name: "FetchGovPerDiemByZipCode", options: {}, I: Int32, O: Rate }
]);
