import { BaseClient } from '../BaseClient';
import { Bool } from '../compiled-protos/common';
import { FirstCall, FirstCallList } from '../compiled-protos/dispatch';
import { DispatchServiceClient as FirstCallServiceClient } from '../compiled-protos/dispatch.client';

class FirstCallClient extends BaseClient {
  self: FirstCallServiceClient;

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new FirstCallServiceClient(this.transport);
  }

  public async Create(req: FirstCall) {
    let res = FirstCall.create();
    try {
      res = await this.self.createFirstCall(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Get(req: FirstCall) {
    let res = FirstCall.create();
    try {
      res = await this.self.getFirstCall(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Update(req: FirstCall) {
    let res = FirstCall.create();
    try {
      res = await this.self.updateFirstCall(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Delete(req: FirstCall) {
    let res = FirstCall.create();
    try {
      res = await this.self.deleteFirstCall(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async BatchGet(req: FirstCall) {
    let res = FirstCallList.create();
    try {
      res = await this.self.batchGetFirstCall(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Message(req: FirstCall) {
    let res = Bool.create();
    try {
      res = await this.self.firstCallMessage(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }
}

export { FirstCall, FirstCallList, FirstCallClient };
