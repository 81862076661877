import { type FC, useState } from 'react';

import { PlainForm, type Schema } from '../PlainForm';

interface Props {
  onSearch: (description: string) => void;
}

type FilterState = {
  description: string;
};

export const Filter: FC<Props> = ({ onSearch }) => {
  const [filter, setFilter] = useState<FilterState>({ description: '' });
  const SCHEMA_FILTER: Schema<FilterState> = [
    [
      {
        name: 'description',
        type: 'search',
        label: 'Part/Labor',
        actions: [
          {
            label: 'Search',
            variant: 'outlined',
            onClick: () => onSearch(filter.description),
          },
        ],
      },
    ],
  ];
  return (
    <PlainForm
      schema={SCHEMA_FILTER}
      data={filter}
      onChange={(data) => {
        if (!data.description) onSearch('');
        setFilter(data);
      }}
    />
  );
};
