// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "transaction_accounts_receivable.proto" (syntax proto3)
// tslint:disable
import { TransactionAccountsReceivableService } from "./transaction_accounts_receivable";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service TransactionAccountsReceivableService
 */
export class TransactionAccountsReceivableServiceClient {
    constructor(_transport) {
        this._transport = _transport;
        this.typeName = TransactionAccountsReceivableService.typeName;
        this.methods = TransactionAccountsReceivableService.methods;
        this.options = TransactionAccountsReceivableService.options;
    }
    /**
     * @generated from protobuf rpc: Create(TransactionAccountsReceivable) returns (TransactionAccountsReceivable);
     */
    create(input, options) {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Get(TransactionAccountsReceivable) returns (TransactionAccountsReceivable);
     */
    get(input, options) {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGet(TransactionAccountsReceivable) returns (TransactionAccountsReceivableList);
     */
    batchGet(input, options) {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Update(TransactionAccountsReceivable) returns (TransactionAccountsReceivable);
     */
    update(input, options) {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Delete(TransactionAccountsReceivable) returns (TransactionAccountsReceivable);
     */
    delete(input, options) {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateLine(TransactionAccountsReceivableLine) returns (TransactionAccountsReceivableLine);
     */
    createLine(input, options) {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetLine(TransactionAccountsReceivableLine) returns (TransactionAccountsReceivableLine);
     */
    getLine(input, options) {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetLine(TransactionAccountsReceivableLine) returns (TransactionAccountsReceivableLineList);
     */
    batchGetLine(input, options) {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateLine(TransactionAccountsReceivableLine) returns (TransactionAccountsReceivableLine);
     */
    updateLine(input, options) {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteLine(TransactionAccountsReceivableLine) returns (TransactionAccountsReceivableLine);
     */
    deleteLine(input, options) {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateLineItem(TransactionAccountsReceivableLineItem) returns (TransactionAccountsReceivableLineItem);
     */
    createLineItem(input, options) {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetLineItem(TransactionAccountsReceivableLineItem) returns (TransactionAccountsReceivableLineItem);
     */
    getLineItem(input, options) {
        const method = this.methods[11], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetLineItem(TransactionAccountsReceivableLineItem) returns (TransactionAccountsReceivableLineItemList);
     */
    batchGetLineItem(input, options) {
        const method = this.methods[12], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateLineItem(TransactionAccountsReceivableLineItem) returns (TransactionAccountsReceivableLineItem);
     */
    updateLineItem(input, options) {
        const method = this.methods[13], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteLineItem(TransactionAccountsReceivableLineItem) returns (TransactionAccountsReceivableLineItem);
     */
    deleteLineItem(input, options) {
        const method = this.methods[14], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreatePayment(TransactionAccountsReceivablePayment) returns (TransactionAccountsReceivablePayment);
     */
    createPayment(input, options) {
        const method = this.methods[15], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetPayment(TransactionAccountsReceivablePayment) returns (TransactionAccountsReceivablePayment);
     */
    getPayment(input, options) {
        const method = this.methods[16], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetPayment(TransactionAccountsReceivablePayment) returns (TransactionAccountsReceivablePaymentList);
     */
    batchGetPayment(input, options) {
        const method = this.methods[17], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdatePayment(TransactionAccountsReceivablePayment) returns (TransactionAccountsReceivablePayment);
     */
    updatePayment(input, options) {
        const method = this.methods[18], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeletePayment(TransactionAccountsReceivablePayment) returns (TransactionAccountsReceivablePayment);
     */
    deletePayment(input, options) {
        const method = this.methods[19], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateDocument(TransactionAccountsReceivableDocument) returns (TransactionAccountsReceivableDocument);
     */
    createDocument(input, options) {
        const method = this.methods[20], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetDocument(TransactionAccountsReceivableDocument) returns (TransactionAccountsReceivableDocument);
     */
    getDocument(input, options) {
        const method = this.methods[21], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetDocument(TransactionAccountsReceivableDocument) returns (TransactionAccountsReceivableDocumentList);
     */
    batchGetDocument(input, options) {
        const method = this.methods[22], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateDocument(TransactionAccountsReceivableDocument) returns (TransactionAccountsReceivableDocument);
     */
    updateDocument(input, options) {
        const method = this.methods[23], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteDocument(TransactionAccountsReceivableDocument) returns (TransactionAccountsReceivableDocument);
     */
    deleteDocument(input, options) {
        const method = this.methods[24], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
}
