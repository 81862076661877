import { BaseClient } from '../BaseClient';
import { Empty } from '../compiled-protos/common';
import {
  GeolocationLog,
  GeolocationLogList,
  type GeolocationRPCLog
,
  GeolocationRPCLogList,
} from '../compiled-protos/geolocation_log';
import { GeolocationLogServiceClient } from '../compiled-protos/geolocation_log.client';

class GeolocationLogClient extends BaseClient {
  self: GeolocationLogServiceClient;

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new GeolocationLogServiceClient(this.transport);
  }

  public async BatchGet(req: GeolocationRPCLog) {
    let res = GeolocationRPCLogList.create();
    try {
      res = await this.self.batchGet(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Delete(req: GeolocationLog) {
    let res = Empty.create();
    try {
      res = await this.self.delete(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }
}

export { GeolocationLog, GeolocationLogList, GeolocationLogClient };
