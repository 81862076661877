import { BaseClient } from '../BaseClient';
import {
  PromptPaymentOverride,
  PromptPaymentOverrideList,
} from '../compiled-protos/prompt_payment';
import { PromptPaymentServiceClient as PromptPaymentOverrideServiceClient } from '../compiled-protos/prompt_payment.client';

class PromptPaymentOverrideClient extends BaseClient {
  self: PromptPaymentOverrideServiceClient;

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new PromptPaymentOverrideServiceClient(this.transport);
  }

  public async Create(req: PromptPaymentOverride) {
    let res = PromptPaymentOverride.create();
    try {
      res = await this.self.createPromptPaymentOverride(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Get(req: PromptPaymentOverride) {
    let res = PromptPaymentOverride.create();
    try {
      res = await this.self.getPromptPaymentOverride(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Update(req: PromptPaymentOverride) {
    let res = PromptPaymentOverride.create();
    try {
      res = await this.self.updatePromptPaymentOverride(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Delete(req: PromptPaymentOverride) {
    let res = PromptPaymentOverride.create();
    try {
      res = await this.self.deletePromptPaymentOverride(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async BatchGet(req: PromptPaymentOverride) {
    let res = PromptPaymentOverrideList.create();
    try {
      res = await this.self.batchGetPromptPaymentOverride(
        req,
        this.getMetaData()
      ).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }
}

export {
  PromptPaymentOverride,
  PromptPaymentOverrideList,
  PromptPaymentOverrideClient,
};
