import { GrpcWebFetchTransport } from '@protobuf-ts/grpcweb-transport';

import {
  CustomerInvoiceInformation,
  GoogleToken,
  Token,
} from '../compiled-protos/auth';
import { AuthServiceClient } from '../compiled-protos/auth.client';
import { AUTH_KEY } from '../constants';

interface RpcMetadata {
  [key: string]: string | string[];
}

export interface ActivityLogConfig {
  name: string;
  propertyID?: number;
  contractID?: number;
  customerID?: number;
  taskID?: number;
  eventID?: number;
}


export class BaseClient {
  host: string;
  userID: number;
  authService: AuthServiceClient;
  transport: GrpcWebFetchTransport;

  constructor(host: string, userID?: number) {
    this.host = host;
    this.userID = userID || 0;
    this.transport = new GrpcWebFetchTransport({
      baseUrl: host,
    });
    this.authService = new AuthServiceClient(this.transport);
  }

  private storeToken(token: string) {
    localStorage.setItem(AUTH_KEY, token);
  }

  protected getMetaData(authToken?: string) {
    const token = authToken ?? localStorage.getItem(AUTH_KEY);
    const md: RpcMetadata = {};
    if (token) {
      md['authorization'] = token;
      return { meta: md };
    }
    return { meta: md };
  }

  protected getMetaDataWithoutCache(authToken?: string) {
    const md = this.getMetaData(authToken);
    md.meta['cache-control'] = 'no-cache';
    return md;
  }
/*
  public async GetToken_DEPRECATED(username: string, password: string) {
    const authData = AuthData.create();
    authData.username = username;
    authData.password = password;
    const res = await this.authService.getToken(authData);
    if (res.response) {
      this.storeToken(res.response.asString);
      return true;
    } else {
      return false;
    }
  }
  */
  public async GetCustomerInvoiceFromToken(token: string) {
    this.storeToken(token);
    const res = await this.authService.getCustomerInvoiceData(
      Token.create({ asString: token })
    );
    if (res.response) {
      return res.response;
    } else {
      return CustomerInvoiceInformation.create();
    }
  }

  public async VerifyGoogleToken(data: string) {
    const res = await this.authService.googleAuthenticate(
      GoogleToken.create({ data })
    );
    if (res.response) {
      return res.response.userId;
    }
    return 0;
  }
}
