import { BaseClient } from '../BaseClient';
import { getMimeType } from '../Common';
import { File as InternalFile, FileList, URLObject } from '../compiled-protos/s3';
import { S3ServiceClient as FileServiceClient } from '../compiled-protos/s3.client';
import { S3Client } from '../S3File';

class FileClient extends BaseClient {
  self: FileServiceClient;
  s3Client: S3Client;

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.s3Client = new S3Client(host, userID);
    this.self = new FileServiceClient(this.transport);
  }

  public async Create(req: InternalFile) {
    const res = await this.self.createFile(req, this.getMetaData()).response;
    return res;
  }

  public async Get(req: InternalFile) {
    let res = InternalFile.create();
    try {
      res = await this.self.getFile(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Update(req: InternalFile) {
    let res = InternalFile.create();
    try {
      res = await this.self.updateFile(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Delete(req: InternalFile) {
    let res = InternalFile.create();
    try {
      res = await this.self.deleteFile(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async BatchGet(req: InternalFile) {
    let res = FileList.create();
    try {
      res = await this.self.batchGetFile(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public deleteFileById = async (id: number) => {
    const req = InternalFile.create();
    req.id = id;
    await this.Delete(req);
  };

  // Unsure of function refactor | Like bottom function in Event file
  public upsertFile = async (data: InternalFile) => {
    return await this[data.id ? 'Update' : 'Create'](data);
  };

  public loadFiles = async (req: InternalFile) => {
    let res = FileList.create();
    try {
      // @ts-ignore
      res = await this.BatchGet(req);
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  };

  public uploadFileToS3AndCreateFile = async ({ file, fileKey, bucket }: { fileKey: string, bucket: string, file: File }) => {

    const contentType = getMimeType(file.name);
    const urlObj = URLObject.create({
      bucket,
      key: fileKey,
      contentType,
    });
    const urlRes = await this.s3Client.GetUploadURL(urlObj);

    if (!urlRes) {
      throw new Error('Failed to get upload URL');
    }
    const uploadRes = await fetch(urlRes.url, {
      body: file,
      method: 'PUT',
    });

    if (uploadRes.status !== 200) {
      throw new Error('Failed to upload file');
    }

    const fileObj = InternalFile.create({
      bucket,
      name: fileKey,
      mimeType: contentType,
    });
    const fileRes = await this.Create(fileObj);
    return fileRes
  }
}

export { InternalFile as File, FileList, FileClient };
