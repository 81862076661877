import {
  type Contract,
  type Event,
  type Invoice as InvoiceType,
  type JobAdmin,
  type JobSubtype,
  type JobType,
  type JobTypeSubtype,
  type Payment,
  type Property,
  type ServicesRendered,
  type StoredQuote,
  type User,
} from '@kalos/kalos-rpc';

import { type Option } from '../Field';
import { type ProposalsForm } from './components/Proposal';
import { type ServicesRenderedPaymentType } from './components/Services';

export interface RequestError {
  label: string;
  message: string;
}

export interface State {
  requestFields: string[];
  tabIdx: number;
  tabKey: number;
  pendingSave: boolean;
  saveInvoice: boolean;
  sendInvoiceToCustomer: boolean;
  emailAlertOpen: boolean;
  serviceCallId: number;
  entry: Event;
  property: Property;
  customer: User;
  propertyEvents: Event[];
  loaded: boolean;
  loading: boolean;
  saving: boolean;
  payments: Payment[];
  openSpiffApply: boolean;
  openPieceWorkApply: boolean;
  openCallbackReview: boolean;
  storedQuote: StoredQuote[];
  error: boolean;
  invoiceData: InvoiceType | undefined;
  errorMessage: string;
  jobTypes: JobType[];
  jobSubtypes: JobSubtype[];
  jobTypeSubtypes: JobTypeSubtype[];
  jobAdmins: JobAdmin[];
  jobSubTypeOptions: Option[];
  servicesRendered: ServicesRendered[];
  projects: Event[];
  parentId: number | null;
  confirmedParentId: number | null;
  contractData: Contract | undefined;
  projectData: Event;
  openJobActivity: boolean;
  proposalsForm: ProposalsForm | undefined;
  requestErrors: { [key: string]: string };
  invoiceErrors: { label: string; message: string }[];
  servicesRenderedPayment: ServicesRenderedPaymentType;
}
export type Action =
  | { type: 'SET_REQUEST_FIELDS'; payload: string[] }
  | { type: 'SET_TAB_IDX'; payload: number }
  | { type: 'SET_TAB_KEY'; payload: number }
  | { type: 'SET_PENDING_SAVE'; payload: boolean }
  | { type: 'SET_SAVE_INVOICE'; payload: boolean }
  | { type: 'SET_SEND_INVOICE_TO_CUSTOMER'; payload: boolean }
  | { type: 'SET_EMAIL_ALERT_OPEN'; payload: boolean }
  | { type: 'SET_REQUEST_ERRORS'; payload: { [key: string]: string } }
  | { type: 'SET_INVOICE_ERRORS'; payload: { label: string; message: string }[] }
  | { type: 'SET_SERVICE_CALL_ID'; payload: number }
  | { type: 'SET_ENTRY'; payload: Event }
  | { type: 'SET_PROPERTY'; payload: Property }
  | { type: 'SET_CUSTOMER'; payload: User }
  | { type: 'SET_PROPERTY_EVENTS'; payload: Event[] }
  | { type: 'SET_LOADED'; payload: boolean }
  | { type: 'SET_LOADING'; payload: boolean }
  | { type: 'SET_SAVING'; payload: boolean }
  | { type: 'SET_PAYMENTS'; payload: Payment[] }
  | { type: 'SET_OPEN_SPIFF_APPLY'; payload: boolean }
  | { type: 'SET_OPEN_PIECE_WORK_APPLY'; payload: boolean }
  | { type: 'SET_OPEN_CALLBACK_REVIEW'; payload: boolean }
  | { type: 'SET_STORED_QUOTE'; payload: StoredQuote[] }
  | { type: 'SET_ERROR'; payload: boolean }
  | { type: 'SET_INVOICE_DATA'; payload: InvoiceType | undefined }
  | { type: 'SET_ERROR_MESSAGE'; payload: string }
  | { type: 'SET_JOB_TYPES'; payload: JobType[] }
  | { type: 'SET_JOB_SUBTYPES'; payload: JobSubtype[] }
  | { type: 'SET_JOB_TYPE_SUBTYPES'; payload: JobTypeSubtype[] }
  | { type: 'SET_JOB_ADMINS'; payload: JobAdmin[] }
  | { type: 'SET_JOB_SUBTYPE_OPTIONS'; payload: Option[] }
  | { type: 'SET_SERVICES_RENDERED'; payload: ServicesRendered[] }
  | { type: 'SET_PROJECTS'; payload: Event[] }
  | { type: 'SET_PARENT_ID'; payload: number | null }
  | { type: 'SET_CONFIRMED_PARENT_ID'; payload: number | null }
  | { type: 'SET_CONTRACT_DATA'; payload: Contract | undefined }
  | { type: 'SET_PROPOSALS_FORM'; payload: ProposalsForm }
  | { type: 'SET_PROJECT_DATA'; payload: Event }
  | { type: 'SET_SPIFF_FORM_KEY'; payload: number }
  | { type: 'SET_OPEN_JOB_ACTIVITY'; payload: boolean }
  | { type: 'SET_SERVICES_RENDERED_PAYMENT'; payload: ServicesRenderedPaymentType };

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_REQUEST_FIELDS':
      return { ...state, requestFields: action.payload };
    case 'SET_TAB_IDX':
      return { ...state, tabIdx: action.payload };
    case 'SET_TAB_KEY':
      return { ...state, tabKey: action.payload };
    case 'SET_PENDING_SAVE':
      return { ...state, pendingSave: action.payload };
    case 'SET_SAVE_INVOICE':
      return { ...state, saveInvoice: action.payload };
    case 'SET_SEND_INVOICE_TO_CUSTOMER':
      return { ...state, sendInvoiceToCustomer: action.payload };
    case 'SET_EMAIL_ALERT_OPEN':
      return { ...state, emailAlertOpen: action.payload };
    case 'SET_REQUEST_ERRORS':
      return { ...state, requestErrors: action.payload };
    case 'SET_INVOICE_ERRORS':
      return { ...state, invoiceErrors: action.payload };
    case 'SET_SERVICE_CALL_ID':
      return { ...state, serviceCallId: action.payload };
    case 'SET_ENTRY': {
      return { ...state, entry: action.payload };
    }
    case 'SET_PROPERTY':
      return { ...state, property: action.payload };
    case 'SET_CUSTOMER':
      return { ...state, customer: action.payload };
    case 'SET_PROPERTY_EVENTS':
      return { ...state, propertyEvents: action.payload };
    case 'SET_LOADED':
      return { ...state, loaded: action.payload };
    case 'SET_LOADING':
      return { ...state, loading: action.payload };
    case 'SET_SAVING':
      return { ...state, saving: action.payload };
    case 'SET_PAYMENTS':
      return { ...state, payments: action.payload };
    case 'SET_OPEN_SPIFF_APPLY':
      return { ...state, openSpiffApply: action.payload };
    case 'SET_OPEN_PIECE_WORK_APPLY':
      return { ...state, openPieceWorkApply: action.payload };
    case 'SET_OPEN_CALLBACK_REVIEW':
      return { ...state, openCallbackReview: action.payload };
    case 'SET_STORED_QUOTE':
      return { ...state, storedQuote: action.payload };
    case 'SET_ERROR':
      return { ...state, error: action.payload };
    case 'SET_INVOICE_DATA':
      return { ...state, invoiceData: action.payload };
    case 'SET_ERROR_MESSAGE':
      return { ...state, errorMessage: action.payload };
    case 'SET_JOB_TYPES':
      return { ...state, jobTypes: action.payload };
    case 'SET_JOB_SUBTYPES':
      return { ...state, jobSubtypes: action.payload };
    case 'SET_JOB_TYPE_SUBTYPES':
      return { ...state, jobTypeSubtypes: action.payload };
    case 'SET_JOB_ADMINS':
      return { ...state, jobAdmins: action.payload };
    case 'SET_JOB_SUBTYPE_OPTIONS':
      return { ...state, jobSubTypeOptions: action.payload };
    case 'SET_SERVICES_RENDERED':
      return { ...state, servicesRendered: action.payload };
    case 'SET_PROJECTS':
      return { ...state, projects: action.payload };
    case 'SET_PARENT_ID':
      return { ...state, parentId: action.payload };
    case 'SET_CONFIRMED_PARENT_ID':
      return { ...state, confirmedParentId: action.payload };
    case 'SET_CONTRACT_DATA':
      return { ...state, contractData: action.payload };
    case 'SET_PROPOSALS_FORM':
      return { ...state, proposalsForm: action.payload };
    case 'SET_PROJECT_DATA':
      return { ...state, projectData: action.payload };
    case 'SET_OPEN_JOB_ACTIVITY':
      return { ...state, openJobActivity: action.payload };
    case 'SET_SERVICES_RENDERED_PAYMENT':
      return { ...state, servicesRenderedPayment: action.payload };
    default:
      return state;
  }
};
