import { BaseClient } from '../BaseClient';
import { IntArray } from '../compiled-protos/common';
import {
  TimesheetDepartment,
  TimesheetDepartmentList,
} from '../compiled-protos/timesheet_department';
import { TimesheetDepartmentServiceClient } from '../compiled-protos/timesheet_department.client';

class TimesheetDepartmentClient extends BaseClient {
  self: TimesheetDepartmentServiceClient;
  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new TimesheetDepartmentServiceClient(this.transport);
  }

  public async Create(req: TimesheetDepartment) {
    let res = TimesheetDepartment.create();
    try {
      res = await this.self.create(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Get(req: TimesheetDepartment) {
    return await this.self.get(req, this.getMetaData()).response;
  }

  public async Update(req: TimesheetDepartment) {
    let res = TimesheetDepartment.create();
    try {
      res = await this.self.update(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Delete(req: TimesheetDepartment) {
    let res = TimesheetDepartment.create();
    try {
      res = await this.self.delete(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async BatchGet(req: TimesheetDepartment) {
    return await this.self.batchGet(req, this.getMetaData()).response;
  }

  public async isManagerCheck(managerId: number) {
    try {
      await this.Get(TimesheetDepartment.create({ managerId }));
      return true;
    } catch (err) {
      return false;
    }
  }

  public async getDepartmentByManagerID(managerId: number) {
    return await this.Get(TimesheetDepartment.create({ managerId }));
  }

  public async loadTimeSheetDepartments() {
    const results: TimesheetDepartment[] = [];
    const req = TimesheetDepartment.create({ pageNumber: 0, isActive: 1 });
    const res = await this.BatchGet(req);
    const len = res!.results.length;
    const totalCount = res!.totalCount;

    results.concat(res!.results);
    if (totalCount > len) {
      const batchesAmount = Math.ceil((totalCount - len) / len);
      const batchResults = await Promise.all(
        Array.from(Array(batchesAmount)).map(async (_, idx) => {
          req.pageNumber = idx + 1;
          return (await this.BatchGet(req))!.results;
        })
      );
      results.push(
        ...batchResults.reduce((aggr, item) => [...aggr, ...item], [])
      );
    }
    return results;
  }

  public async BatchGetDepartmentsByIds(req: number[]) {
    const arr = IntArray.create({ iDs: req });
    let res = TimesheetDepartmentList.create();
    try {
      res = await this.self.batchGetDepartmentsByIds(arr, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }
  public getDepartmentName = (d: TimesheetDepartment): string =>
    d ? `${d.description}` : '';
}

export {
  TimesheetDepartment,
  TimesheetDepartmentList,
  TimesheetDepartmentClient,
};
