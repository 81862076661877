import { Button, Input } from '@kalos/ui';
import { useCallback, useLayoutEffect, useRef, useState } from 'react';

type FileData = {
  filename: string;
  fileData: Uint8Array;
};

export const FileForm = ({
  onSave,
  disabled,
  isLoading,
}: {
  disabled?: boolean;
  onSave: (arg: FileData) => void;
  isLoading?: boolean;
}) => {
  const [fileData, setFileData] = useState<FileData | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useLayoutEffect(() => {
    const node = fileInputRef.current;
    const cancelEventHandler = () => {
      setFileData(null);
    };
    node?.addEventListener('cancel', cancelEventHandler);
    return () => {
      node?.removeEventListener('cancel', cancelEventHandler);
    };
  }, []);

  const handleFile = useCallback(() => {
    const fr = new FileReader();
    fr.onload = async () => {
      try {
        const fileName = fileInputRef.current!.files![0].name;
        const fileData = new Uint8Array(fr.result as ArrayBuffer);
        setFileData({
          filename: fileName,
          fileData: fileData,
        });
      } catch (err) {
        alert('File could not be uploaded');
        console.error(err);
      }
    };
    if (
      fileInputRef.current &&
      fileInputRef.current.files &&
      fileInputRef.current.files.length != 0
    ) {
      fr.readAsArrayBuffer(fileInputRef.current.files[0]);
    } else {
      setFileData(null);
    }
  }, []);

  const onResetClick = () => {
    setFileData(null);
  };

  const createFile: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    if (fileData) {
      onSave(fileData);
      setFileData(null);
    }
  };

  return (
    <form className="flex flex-col gap-4" onSubmit={createFile}>
      <Input type="file" ref={fileInputRef} onChange={handleFile} />
      <div className="flex justify-end gap-3">
        <Button onClick={onResetClick} type="reset" className="max-w-max" variant="outline">
          Reset
        </Button>
        <Button
          isLoading={isLoading}
          className="cols-col-span-2 ml-autos max-w-max"
          type="submit"
          disabled={!fileData || disabled}
        >
          Create
        </Button>
      </div>
    </form>
  );
};
