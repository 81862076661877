import { type User } from '@kalos/kalos-rpc';
import { Avatar, AvatarFallback, AvatarImage } from '@kalos/ui';
import { useQuery } from '@tanstack/react-query';

import { getEmployeeImageQueryConfig } from '../../../../../hooks/react-query/useEmployeesQuery';

export const EmployeeAvatar = ({
  image,
  fallback,
  className,
}: {
  image: User['image'];
  fallback: React.ReactNode;
  className?: string;
}) => {
  const imageQuery = useQuery(getEmployeeImageQueryConfig({ imageUrl: image }));
  return (
    <Avatar className={className}>
      <AvatarImage className="object-cover" src={imageQuery.data ?? ''} />
      <AvatarFallback>{fallback}</AvatarFallback>
    </Avatar>
  );
};
