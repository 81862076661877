// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "memo.proto" (syntax proto3)
// tslint:disable
import { MemoService } from "./memo";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service MemoService
 */
export class MemoServiceClient {
    constructor(_transport) {
        this._transport = _transport;
        this.typeName = MemoService.typeName;
        this.methods = MemoService.methods;
        this.options = MemoService.options;
    }
    /**
     * @generated from protobuf rpc: Create(Memo) returns (Memo);
     */
    create(input, options) {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Get(Memo) returns (Memo);
     */
    get(input, options) {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGet(Memo) returns (MemoList);
     */
    batchGet(input, options) {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Update(Memo) returns (Memo);
     */
    update(input, options) {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Delete(Memo) returns (Memo);
     */
    delete(input, options) {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateMemoComment(MemoComment) returns (MemoComment);
     */
    createMemoComment(input, options) {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetMemoComment(MemoComment) returns (MemoComment);
     */
    getMemoComment(input, options) {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetMemoComment(MemoComment) returns (MemoCommentList);
     */
    batchGetMemoComment(input, options) {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateMemoComment(MemoComment) returns (MemoComment);
     */
    updateMemoComment(input, options) {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteMemoComment(MemoComment) returns (MemoComment);
     */
    deleteMemoComment(input, options) {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: MemoCommentListStream(MemoComment) returns (stream MemoComment);
     */
    memoCommentListStream(input, options) {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept("serverStreaming", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: closeStream(StreamIdentifier) returns (Empty);
     */
    closeStream(input, options) {
        const method = this.methods[11], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateMemoCommentLog(MemoCommentLog) returns (MemoCommentLog);
     */
    createMemoCommentLog(input, options) {
        const method = this.methods[12], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetMemoCommentLog(MemoCommentLog) returns (MemoCommentLog);
     */
    getMemoCommentLog(input, options) {
        const method = this.methods[13], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetMemoCommentLog(MemoCommentLog) returns (MemoCommentLogList);
     */
    batchGetMemoCommentLog(input, options) {
        const method = this.methods[14], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateMemoCommentLog(MemoCommentLog) returns (MemoCommentLog);
     */
    updateMemoCommentLog(input, options) {
        const method = this.methods[15], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteMemoCommentLog(MemoCommentLog) returns (MemoCommentLog);
     */
    deleteMemoCommentLog(input, options) {
        const method = this.methods[16], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetMemoStatus(MemoStatus) returns (MemoStatusList);
     */
    batchGetMemoStatus(input, options) {
        const method = this.methods[17], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetMemoCategory(MemoClassification) returns (MemoClassificationList);
     */
    batchGetMemoCategory(input, options) {
        const method = this.methods[18], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetMemoReport(MemoReportReq) returns (Bool);
     */
    getMemoReport(input, options) {
        const method = this.methods[19], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetMemoDocument(MemoDocument) returns (MemoDocument);
     */
    getMemoDocument(input, options) {
        const method = this.methods[20], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetMemoDocument(MemoDocument) returns (MemoDocumentList);
     */
    batchGetMemoDocument(input, options) {
        const method = this.methods[21], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateMemoDocument(MemoDocument) returns (MemoDocument);
     */
    createMemoDocument(input, options) {
        const method = this.methods[22], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateMemoDocument(MemoDocument) returns (MemoDocument);
     */
    updateMemoDocument(input, options) {
        const method = this.methods[23], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteMemoDocument(MemoDocument) returns (MemoDocument);
     */
    deleteMemoDocument(input, options) {
        const method = this.methods[24], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
}
