// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "task.proto" (syntax proto3)
// tslint:disable
import { TaskService } from "./task";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service TaskService
 */
export class TaskServiceClient {
    constructor(_transport) {
        this._transport = _transport;
        this.typeName = TaskService.typeName;
        this.methods = TaskService.methods;
        this.options = TaskService.options;
    }
    /**
     * @generated from protobuf rpc: Create(Task) returns (Task);
     */
    create(input, options) {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateProjectTask(ProjectTask) returns (ProjectTask);
     */
    createProjectTask(input, options) {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Get(Task) returns (Task);
     */
    get(input, options) {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetProjectTask(ProjectTask) returns (ProjectTask);
     */
    getProjectTask(input, options) {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGet(Task) returns (TaskList);
     */
    batchGet(input, options) {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetProjectTasks(ProjectTask) returns (ProjectTaskList);
     */
    batchGetProjectTasks(input, options) {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Update(Task) returns (Task);
     */
    update(input, options) {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateProjectTask(ProjectTask) returns (ProjectTask);
     */
    updateProjectTask(input, options) {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Delete(Task) returns (Task);
     */
    delete(input, options) {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteProjectTask(ProjectTask) returns (ProjectTask);
     */
    deleteProjectTask(input, options) {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetSpiffTypes(SpiffType) returns (SpiffTypeList);
     */
    getSpiffTypes(input, options) {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetToolFundBalanceByID(ToolFund) returns (ToolFund);
     */
    getToolFundBalanceByID(input, options) {
        const method = this.methods[11], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetAppliedSpiffs(Spiff) returns (SpiffList);
     */
    getAppliedSpiffs(input, options) {
        const method = this.methods[12], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetTaskStatusList(TaskStatus) returns (TaskStatusList);
     */
    getTaskStatusList(input, options) {
        const method = this.methods[13], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetTaskPriorityList(TaskPriority) returns (TaskPriorityList);
     */
    getTaskPriorityList(input, options) {
        const method = this.methods[14], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetTaskBillableTypeList(Empty) returns (StringList);
     */
    getTaskBillableTypeList(input, options) {
        const method = this.methods[15], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateTaskEvent(TaskEvent) returns (TaskEvent);
     */
    createTaskEvent(input, options) {
        const method = this.methods[16], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetTaskEvent(TaskEvent) returns (TaskEvent);
     */
    getTaskEvent(input, options) {
        const method = this.methods[17], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetTaskEvent(TaskEvent) returns (TaskEventList);
     */
    batchGetTaskEvent(input, options) {
        const method = this.methods[18], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateTaskEvent(TaskEvent) returns (TaskEvent);
     */
    updateTaskEvent(input, options) {
        const method = this.methods[19], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteTaskEvent(TaskEvent) returns (TaskEvent);
     */
    deleteTaskEvent(input, options) {
        const method = this.methods[20], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateTaskAssignment(TaskAssignment) returns (TaskAssignment);
     */
    createTaskAssignment(input, options) {
        const method = this.methods[21], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetTaskAssignment(TaskAssignment) returns (TaskAssignment);
     */
    getTaskAssignment(input, options) {
        const method = this.methods[22], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetTaskAssignment(TaskAssignment) returns (TaskAssignmentList);
     */
    batchGetTaskAssignment(input, options) {
        const method = this.methods[23], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateTaskAssignment(TaskAssignment) returns (TaskAssignment);
     */
    updateTaskAssignment(input, options) {
        const method = this.methods[24], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteTaskAssignment(TaskAssignment) returns (TaskAssignment);
     */
    deleteTaskAssignment(input, options) {
        const method = this.methods[25], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateSpiffToolAdminAction(SpiffToolAdminAction) returns (SpiffToolAdminAction);
     */
    createSpiffToolAdminAction(input, options) {
        const method = this.methods[26], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetSpiffToolAdminAction(SpiffToolAdminAction) returns (SpiffToolAdminAction);
     */
    getSpiffToolAdminAction(input, options) {
        const method = this.methods[27], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetSpiffToolAdminAction(SpiffToolAdminAction) returns (SpiffToolAdminActionList);
     */
    batchGetSpiffToolAdminAction(input, options) {
        const method = this.methods[28], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateSpiffToolAdminAction(SpiffToolAdminAction) returns (SpiffToolAdminAction);
     */
    updateSpiffToolAdminAction(input, options) {
        const method = this.methods[29], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteSpiffToolAdminAction(SpiffToolAdminAction) returns (SpiffToolAdminAction);
     */
    deleteSpiffToolAdminAction(input, options) {
        const method = this.methods[30], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateSpiffOption(SpiffOption) returns (SpiffOption);
     */
    createSpiffOption(input, options) {
        const method = this.methods[31], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetSpiffOption(SpiffOption) returns (SpiffOption);
     */
    getSpiffOption(input, options) {
        const method = this.methods[32], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetSpiffOption(SpiffOption) returns (SpiffOptionList);
     */
    batchGetSpiffOption(input, options) {
        const method = this.methods[33], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateSpiffOption(SpiffOption) returns (SpiffOption);
     */
    updateSpiffOption(input, options) {
        const method = this.methods[34], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteSpiffOption(SpiffOption) returns (SpiffOption);
     */
    deleteSpiffOption(input, options) {
        const method = this.methods[35], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
}
