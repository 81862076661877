// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "performance_review.proto" (syntax proto3)
// tslint:disable
// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "performance_review.proto" (syntax proto3)
// tslint:disable
import { Empty } from "./common";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { WireType } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { User } from "./user";
// @generated message type with reflection information, may provide speed optimized methods
class PerformanceReview$Type extends MessageType {
    constructor() {
        super("PerformanceReview", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "employee_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "status_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "is60Day", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "createDate", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "scheduleDate", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "completed_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "includes_paychange", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "text", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "department_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 11, name: "department_list", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "finalize_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "employee", kind: "message", T: () => User },
            { no: 14, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "not_equals", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 17, name: "without_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 18, name: "manager_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 19, name: "manager", kind: "message", T: () => User },
            { no: 20, name: "is_active", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 21, name: "lock_review", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 22, name: "review_type", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, employeeId: 0, statusId: 0, is60Day: false, createDate: "", scheduleDate: "", completedDate: "", includesPaychange: false, text: "", departmentId: 0, departmentList: "", finalizeDate: "", fieldMask: [], notEquals: [], pageNumber: 0, withoutLimit: false, managerId: 0, isActive: 0, lockReview: 0, reviewType: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 employee_id */ 2:
                    message.employeeId = reader.int32();
                    break;
                case /* int32 status_id */ 3:
                    message.statusId = reader.int32();
                    break;
                case /* bool is60Day */ 4:
                    message.is60Day = reader.bool();
                    break;
                case /* string createDate */ 5:
                    message.createDate = reader.string();
                    break;
                case /* string scheduleDate */ 6:
                    message.scheduleDate = reader.string();
                    break;
                case /* string completed_date */ 7:
                    message.completedDate = reader.string();
                    break;
                case /* bool includes_paychange */ 8:
                    message.includesPaychange = reader.bool();
                    break;
                case /* string text */ 9:
                    message.text = reader.string();
                    break;
                case /* int32 department_id */ 10:
                    message.departmentId = reader.int32();
                    break;
                case /* string department_list */ 11:
                    message.departmentList = reader.string();
                    break;
                case /* string finalize_date */ 12:
                    message.finalizeDate = reader.string();
                    break;
                case /* User employee */ 13:
                    message.employee = User.internalBinaryRead(reader, reader.uint32(), options, message.employee);
                    break;
                case /* repeated string field_mask */ 14:
                    message.fieldMask.push(reader.string());
                    break;
                case /* repeated string not_equals */ 15:
                    message.notEquals.push(reader.string());
                    break;
                case /* int32 page_number */ 16:
                    message.pageNumber = reader.int32();
                    break;
                case /* bool without_limit */ 17:
                    message.withoutLimit = reader.bool();
                    break;
                case /* int32 manager_id */ 18:
                    message.managerId = reader.int32();
                    break;
                case /* User manager */ 19:
                    message.manager = User.internalBinaryRead(reader, reader.uint32(), options, message.manager);
                    break;
                case /* int32 is_active */ 20:
                    message.isActive = reader.int32();
                    break;
                case /* int32 lock_review */ 21:
                    message.lockReview = reader.int32();
                    break;
                case /* int32 review_type */ 22:
                    message.reviewType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 employee_id = 2; */
        if (message.employeeId !== 0)
            writer.tag(2, WireType.Varint).int32(message.employeeId);
        /* int32 status_id = 3; */
        if (message.statusId !== 0)
            writer.tag(3, WireType.Varint).int32(message.statusId);
        /* bool is60Day = 4; */
        if (message.is60Day !== false)
            writer.tag(4, WireType.Varint).bool(message.is60Day);
        /* string createDate = 5; */
        if (message.createDate !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.createDate);
        /* string scheduleDate = 6; */
        if (message.scheduleDate !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.scheduleDate);
        /* string completed_date = 7; */
        if (message.completedDate !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.completedDate);
        /* bool includes_paychange = 8; */
        if (message.includesPaychange !== false)
            writer.tag(8, WireType.Varint).bool(message.includesPaychange);
        /* string text = 9; */
        if (message.text !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.text);
        /* int32 department_id = 10; */
        if (message.departmentId !== 0)
            writer.tag(10, WireType.Varint).int32(message.departmentId);
        /* string department_list = 11; */
        if (message.departmentList !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.departmentList);
        /* string finalize_date = 12; */
        if (message.finalizeDate !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.finalizeDate);
        /* User employee = 13; */
        if (message.employee)
            User.internalBinaryWrite(message.employee, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* repeated string field_mask = 14; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(14, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* repeated string not_equals = 15; */
        for (let i = 0; i < message.notEquals.length; i++)
            writer.tag(15, WireType.LengthDelimited).string(message.notEquals[i]);
        /* int32 page_number = 16; */
        if (message.pageNumber !== 0)
            writer.tag(16, WireType.Varint).int32(message.pageNumber);
        /* bool without_limit = 17; */
        if (message.withoutLimit !== false)
            writer.tag(17, WireType.Varint).bool(message.withoutLimit);
        /* int32 manager_id = 18; */
        if (message.managerId !== 0)
            writer.tag(18, WireType.Varint).int32(message.managerId);
        /* User manager = 19; */
        if (message.manager)
            User.internalBinaryWrite(message.manager, writer.tag(19, WireType.LengthDelimited).fork(), options).join();
        /* int32 is_active = 20; */
        if (message.isActive !== 0)
            writer.tag(20, WireType.Varint).int32(message.isActive);
        /* int32 lock_review = 21; */
        if (message.lockReview !== 0)
            writer.tag(21, WireType.Varint).int32(message.lockReview);
        /* int32 review_type = 22; */
        if (message.reviewType !== 0)
            writer.tag(22, WireType.Varint).int32(message.reviewType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PerformanceReview
 */
export const PerformanceReview = new PerformanceReview$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReviewForm$Type extends MessageType {
    constructor() {
        super("ReviewForm", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "parent_pr_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "sequence_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "last_edit_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "question_1_rating", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "question_1", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "question_2_rating", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "question_2", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "question_3_rating", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 11, name: "question_3", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "question_4_rating", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 13, name: "question_4", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "question_5_rating", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 15, name: "question_5", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "question_6_rating", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 17, name: "question_6", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 18, name: "is_self_review", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 19, name: "manager", kind: "message", T: () => User },
            { no: 20, name: "employee", kind: "message", T: () => User },
            { no: 21, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 22, name: "not_equals", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 23, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 24, name: "without_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, parentPrId: 0, sequenceNumber: 0, lastEditDate: "", userId: 0, question1Rating: 0, question1: "", question2Rating: 0, question2: "", question3Rating: 0, question3: "", question4Rating: 0, question4: "", question5Rating: 0, question5: "", question6Rating: 0, question6: "", isSelfReview: false, fieldMask: [], notEquals: [], pageNumber: 0, withoutLimit: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 parent_pr_id */ 2:
                    message.parentPrId = reader.int32();
                    break;
                case /* int32 sequence_number */ 3:
                    message.sequenceNumber = reader.int32();
                    break;
                case /* string last_edit_date */ 4:
                    message.lastEditDate = reader.string();
                    break;
                case /* int32 user_id */ 5:
                    message.userId = reader.int32();
                    break;
                case /* int32 question_1_rating */ 6:
                    message.question1Rating = reader.int32();
                    break;
                case /* string question_1 */ 7:
                    message.question1 = reader.string();
                    break;
                case /* int32 question_2_rating */ 8:
                    message.question2Rating = reader.int32();
                    break;
                case /* string question_2 */ 9:
                    message.question2 = reader.string();
                    break;
                case /* int32 question_3_rating */ 10:
                    message.question3Rating = reader.int32();
                    break;
                case /* string question_3 */ 11:
                    message.question3 = reader.string();
                    break;
                case /* int32 question_4_rating */ 12:
                    message.question4Rating = reader.int32();
                    break;
                case /* string question_4 */ 13:
                    message.question4 = reader.string();
                    break;
                case /* int32 question_5_rating */ 14:
                    message.question5Rating = reader.int32();
                    break;
                case /* string question_5 */ 15:
                    message.question5 = reader.string();
                    break;
                case /* int32 question_6_rating */ 16:
                    message.question6Rating = reader.int32();
                    break;
                case /* string question_6 */ 17:
                    message.question6 = reader.string();
                    break;
                case /* bool is_self_review */ 18:
                    message.isSelfReview = reader.bool();
                    break;
                case /* User manager */ 19:
                    message.manager = User.internalBinaryRead(reader, reader.uint32(), options, message.manager);
                    break;
                case /* User employee */ 20:
                    message.employee = User.internalBinaryRead(reader, reader.uint32(), options, message.employee);
                    break;
                case /* repeated string field_mask */ 21:
                    message.fieldMask.push(reader.string());
                    break;
                case /* repeated string not_equals */ 22:
                    message.notEquals.push(reader.string());
                    break;
                case /* int32 page_number */ 23:
                    message.pageNumber = reader.int32();
                    break;
                case /* bool without_limit */ 24:
                    message.withoutLimit = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 parent_pr_id = 2; */
        if (message.parentPrId !== 0)
            writer.tag(2, WireType.Varint).int32(message.parentPrId);
        /* int32 sequence_number = 3; */
        if (message.sequenceNumber !== 0)
            writer.tag(3, WireType.Varint).int32(message.sequenceNumber);
        /* string last_edit_date = 4; */
        if (message.lastEditDate !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.lastEditDate);
        /* int32 user_id = 5; */
        if (message.userId !== 0)
            writer.tag(5, WireType.Varint).int32(message.userId);
        /* int32 question_1_rating = 6; */
        if (message.question1Rating !== 0)
            writer.tag(6, WireType.Varint).int32(message.question1Rating);
        /* string question_1 = 7; */
        if (message.question1 !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.question1);
        /* int32 question_2_rating = 8; */
        if (message.question2Rating !== 0)
            writer.tag(8, WireType.Varint).int32(message.question2Rating);
        /* string question_2 = 9; */
        if (message.question2 !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.question2);
        /* int32 question_3_rating = 10; */
        if (message.question3Rating !== 0)
            writer.tag(10, WireType.Varint).int32(message.question3Rating);
        /* string question_3 = 11; */
        if (message.question3 !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.question3);
        /* int32 question_4_rating = 12; */
        if (message.question4Rating !== 0)
            writer.tag(12, WireType.Varint).int32(message.question4Rating);
        /* string question_4 = 13; */
        if (message.question4 !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.question4);
        /* int32 question_5_rating = 14; */
        if (message.question5Rating !== 0)
            writer.tag(14, WireType.Varint).int32(message.question5Rating);
        /* string question_5 = 15; */
        if (message.question5 !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.question5);
        /* int32 question_6_rating = 16; */
        if (message.question6Rating !== 0)
            writer.tag(16, WireType.Varint).int32(message.question6Rating);
        /* string question_6 = 17; */
        if (message.question6 !== "")
            writer.tag(17, WireType.LengthDelimited).string(message.question6);
        /* bool is_self_review = 18; */
        if (message.isSelfReview !== false)
            writer.tag(18, WireType.Varint).bool(message.isSelfReview);
        /* User manager = 19; */
        if (message.manager)
            User.internalBinaryWrite(message.manager, writer.tag(19, WireType.LengthDelimited).fork(), options).join();
        /* User employee = 20; */
        if (message.employee)
            User.internalBinaryWrite(message.employee, writer.tag(20, WireType.LengthDelimited).fork(), options).join();
        /* repeated string field_mask = 21; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(21, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* repeated string not_equals = 22; */
        for (let i = 0; i < message.notEquals.length; i++)
            writer.tag(22, WireType.LengthDelimited).string(message.notEquals[i]);
        /* int32 page_number = 23; */
        if (message.pageNumber !== 0)
            writer.tag(23, WireType.Varint).int32(message.pageNumber);
        /* bool without_limit = 24; */
        if (message.withoutLimit !== false)
            writer.tag(24, WireType.Varint).bool(message.withoutLimit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ReviewForm
 */
export const ReviewForm = new ReviewForm$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PayChangeRequest$Type extends MessageType {
    constructor() {
        super("PayChangeRequest", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "pr_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "emp_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "submitted_by", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "date_submitted", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "pay_change_amount", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 7, name: "reviewed_by", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "date_reviewed", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "request_status", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, prId: 0, empId: 0, submittedBy: 0, dateSubmitted: "", payChangeAmount: 0, reviewedBy: 0, dateReviewed: "", requestStatus: "", fieldMask: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 pr_id */ 2:
                    message.prId = reader.int32();
                    break;
                case /* int32 emp_id */ 3:
                    message.empId = reader.int32();
                    break;
                case /* int32 submitted_by */ 4:
                    message.submittedBy = reader.int32();
                    break;
                case /* string date_submitted */ 5:
                    message.dateSubmitted = reader.string();
                    break;
                case /* float pay_change_amount */ 6:
                    message.payChangeAmount = reader.float();
                    break;
                case /* int32 reviewed_by */ 7:
                    message.reviewedBy = reader.int32();
                    break;
                case /* string date_reviewed */ 8:
                    message.dateReviewed = reader.string();
                    break;
                case /* string request_status */ 9:
                    message.requestStatus = reader.string();
                    break;
                case /* repeated string field_mask */ 10:
                    message.fieldMask.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 pr_id = 2; */
        if (message.prId !== 0)
            writer.tag(2, WireType.Varint).int32(message.prId);
        /* int32 emp_id = 3; */
        if (message.empId !== 0)
            writer.tag(3, WireType.Varint).int32(message.empId);
        /* int32 submitted_by = 4; */
        if (message.submittedBy !== 0)
            writer.tag(4, WireType.Varint).int32(message.submittedBy);
        /* string date_submitted = 5; */
        if (message.dateSubmitted !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.dateSubmitted);
        /* float pay_change_amount = 6; */
        if (message.payChangeAmount !== 0)
            writer.tag(6, WireType.Bit32).float(message.payChangeAmount);
        /* int32 reviewed_by = 7; */
        if (message.reviewedBy !== 0)
            writer.tag(7, WireType.Varint).int32(message.reviewedBy);
        /* string date_reviewed = 8; */
        if (message.dateReviewed !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.dateReviewed);
        /* string request_status = 9; */
        if (message.requestStatus !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.requestStatus);
        /* repeated string field_mask = 10; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(10, WireType.LengthDelimited).string(message.fieldMask[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PayChangeRequest
 */
export const PayChangeRequest = new PayChangeRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PerformanceReviewStatus$Type extends MessageType {
    constructor() {
        super("PerformanceReviewStatus", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, name: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PerformanceReviewStatus
 */
export const PerformanceReviewStatus = new PerformanceReviewStatus$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PerformanceReviewList$Type extends MessageType {
    constructor() {
        super("PerformanceReviewList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PerformanceReview },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated PerformanceReview results */ 1:
                    message.results.push(PerformanceReview.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated PerformanceReview results = 1; */
        for (let i = 0; i < message.results.length; i++)
            PerformanceReview.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PerformanceReviewList
 */
export const PerformanceReviewList = new PerformanceReviewList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReviewFormList$Type extends MessageType {
    constructor() {
        super("ReviewFormList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ReviewForm },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated ReviewForm results */ 1:
                    message.results.push(ReviewForm.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated ReviewForm results = 1; */
        for (let i = 0; i < message.results.length; i++)
            ReviewForm.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ReviewFormList
 */
export const ReviewFormList = new ReviewFormList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PayChangeRequestList$Type extends MessageType {
    constructor() {
        super("PayChangeRequestList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PayChangeRequest },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated PayChangeRequest results */ 1:
                    message.results.push(PayChangeRequest.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated PayChangeRequest results = 1; */
        for (let i = 0; i < message.results.length; i++)
            PayChangeRequest.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PayChangeRequestList
 */
export const PayChangeRequestList = new PayChangeRequestList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StatusList$Type extends MessageType {
    constructor() {
        super("StatusList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PerformanceReviewStatus },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated PerformanceReviewStatus results */ 1:
                    message.results.push(PerformanceReviewStatus.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated PerformanceReviewStatus results = 1; */
        for (let i = 0; i < message.results.length; i++)
            PerformanceReviewStatus.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message StatusList
 */
export const StatusList = new StatusList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InternalLink$Type extends MessageType {
    constructor() {
        super("InternalLink", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "tag", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "without_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, name: "", url: "", isActive: false, tag: 0, pageNumber: 0, fieldMask: [], withoutLimit: false, orderBy: "", orderDir: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string url */ 3:
                    message.url = reader.string();
                    break;
                case /* bool is_active */ 4:
                    message.isActive = reader.bool();
                    break;
                case /* int32 tag */ 5:
                    message.tag = reader.int32();
                    break;
                case /* int32 page_number */ 6:
                    message.pageNumber = reader.int32();
                    break;
                case /* repeated string field_mask */ 7:
                    message.fieldMask.push(reader.string());
                    break;
                case /* bool without_limit */ 8:
                    message.withoutLimit = reader.bool();
                    break;
                case /* string order_by */ 9:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 10:
                    message.orderDir = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string url = 3; */
        if (message.url !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.url);
        /* bool is_active = 4; */
        if (message.isActive !== false)
            writer.tag(4, WireType.Varint).bool(message.isActive);
        /* int32 tag = 5; */
        if (message.tag !== 0)
            writer.tag(5, WireType.Varint).int32(message.tag);
        /* int32 page_number = 6; */
        if (message.pageNumber !== 0)
            writer.tag(6, WireType.Varint).int32(message.pageNumber);
        /* repeated string field_mask = 7; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(7, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* bool without_limit = 8; */
        if (message.withoutLimit !== false)
            writer.tag(8, WireType.Varint).bool(message.withoutLimit);
        /* string order_by = 9; */
        if (message.orderBy !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 10; */
        if (message.orderDir !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.orderDir);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message InternalLink
 */
export const InternalLink = new InternalLink$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InternalLinkList$Type extends MessageType {
    constructor() {
        super("InternalLinkList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => InternalLink },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated InternalLink results */ 1:
                    message.results.push(InternalLink.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated InternalLink results = 1; */
        for (let i = 0; i < message.results.length; i++)
            InternalLink.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message InternalLinkList
 */
export const InternalLinkList = new InternalLinkList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InternalLinkTag$Type extends MessageType {
    constructor() {
        super("InternalLinkTag", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, name: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message InternalLinkTag
 */
export const InternalLinkTag = new InternalLinkTag$Type();
/**
 * @generated ServiceType for protobuf service PerformanceReviewService
 */
export const PerformanceReviewService = new ServiceType("PerformanceReviewService", [
    { name: "Create", options: {}, I: PerformanceReview, O: PerformanceReview },
    { name: "Get", options: {}, I: PerformanceReview, O: PerformanceReview },
    { name: "BatchGet", options: {}, I: PerformanceReview, O: PerformanceReviewList },
    { name: "Update", options: {}, I: PerformanceReview, O: PerformanceReview },
    { name: "Delete", options: {}, I: PerformanceReview, O: PerformanceReview },
    { name: "CreateReviewForm", options: {}, I: ReviewForm, O: ReviewForm },
    { name: "GetReviewForm", options: {}, I: ReviewForm, O: ReviewForm },
    { name: "BatchGetReviewForm", options: {}, I: ReviewForm, O: ReviewFormList },
    { name: "UpdateReviewForm", options: {}, I: ReviewForm, O: ReviewForm },
    { name: "DeleteReviewForm", options: {}, I: ReviewForm, O: ReviewForm },
    { name: "CreatePayChange", options: {}, I: PayChangeRequest, O: PayChangeRequest },
    { name: "GetPayChange", options: {}, I: PayChangeRequest, O: PayChangeRequest },
    { name: "BatchGetPayChange", options: {}, I: PayChangeRequest, O: PayChangeRequestList },
    { name: "UpdatePayChange", options: {}, I: PayChangeRequest, O: PayChangeRequest },
    { name: "GetAllPRStatuses", options: {}, I: Empty, O: StatusList },
    { name: "CreateInternalLink", options: {}, I: InternalLink, O: InternalLink },
    { name: "GetInternalLink", options: {}, I: InternalLink, O: InternalLink },
    { name: "BatchGetInternalLink", options: {}, I: InternalLink, O: InternalLinkList },
    { name: "UpdateInternalLink", options: {}, I: InternalLink, O: InternalLink },
    { name: "DeleteInternalLink", options: {}, I: InternalLink, O: InternalLink }
]);
