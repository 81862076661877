import { TransactionActivity, type TransactionActivityList } from '@kalos/kalos-rpc';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { TransactionActivityClientService } from '../../tools/helpers';
import { queryKeys } from './constants';
import { type EntityFilter } from './utils';

export type TransactionActivityFilter = EntityFilter<TransactionActivity>;

type GetTransactionActivityBatchGetResponse = Awaited<
  ReturnType<typeof TransactionActivityClientService.BatchGet>
>;
export const useTransactionActivityBatchGet = <TSelectData = TransactionActivityList,>({
  filters,
  enabled = true,
  select,
}: {
  filters: TransactionActivityFilter;
  enabled?: boolean;
  select?: (data: GetTransactionActivityBatchGetResponse) => TSelectData;
}) => {
  return useQuery({
    queryKey: [
      queryKeys.transactionActivity.root,
      queryKeys.transactionActivity.list,
      queryKeys.transactionActivity.getFiltersHash(filters),
    ],
    queryFn: async () => {
      const req = TransactionActivity.create(filters);
      return await TransactionActivityClientService.BatchGet(req);
    },
    select,
    enabled,
  });
};

export const useTransactionActivityCreateMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (params: TransactionActivity) => {
      return await TransactionActivityClientService.Create(params);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.transactionActivity.root, queryKeys.transactionActivity.list],
      });
    },
  });
};

export const useImmediateTransactionActivityBatchGet = () => {
  const queryClient = useQueryClient();
  const fetchData = useCallback(
    async (filters: TransactionActivityFilter) => {
      const cache = queryClient.getQueryData<TransactionActivityList | undefined>([
        queryKeys.transactionActivity.root,
        queryKeys.transactionActivity.getFiltersHash(filters),
      ]);
      if (cache) {
        return cache;
      }
      const req = TransactionActivity.create(filters);
      const data = await TransactionActivityClientService.BatchGet(req);
      queryClient.setQueryData(
        [queryKeys.transactionActivity.root, queryKeys.transactionActivity.getFiltersHash(filters)],
        data,
      );
      return data;
    },
    [queryClient],
  );
  return fetchData;
};
