// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "property.proto" (syntax proto3)
// tslint:disable
// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "property.proto" (syntax proto3)
// tslint:disable
import { Empty } from "./common";
import { Int32 } from "./common";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { WireType } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { LatLng } from "./common";
// @generated message type with reflection information, may provide speed optimized methods
class PropertyCoordinates$Type extends MessageType {
    constructor() {
        super("PropertyCoordinates", [
            { no: 1, name: "coords", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => LatLng }
        ]);
    }
    create(value) {
        const message = { coords: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated LatLng coords */ 1:
                    message.coords.push(LatLng.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated LatLng coords = 1; */
        for (let i = 0; i < message.coords.length; i++)
            LatLng.internalBinaryWrite(message.coords[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PropertyCoordinates
 */
export const PropertyCoordinates = new PropertyCoordinates$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SubAccountPropertiesRequest$Type extends MessageType {
    constructor() {
        super("SubAccountPropertiesRequest", [
            { no: 1, name: "sub_account_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "property", kind: "message", T: () => Property }
        ]);
    }
    create(value) {
        const message = { subAccountId: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 sub_account_id */ 1:
                    message.subAccountId = reader.int32();
                    break;
                case /* Property property */ 2:
                    message.property = Property.internalBinaryRead(reader, reader.uint32(), options, message.property);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 sub_account_id = 1; */
        if (message.subAccountId !== 0)
            writer.tag(1, WireType.Varint).int32(message.subAccountId);
        /* Property property = 2; */
        if (message.property)
            Property.internalBinaryWrite(message.property, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SubAccountPropertiesRequest
 */
export const SubAccountPropertiesRequest = new SubAccountPropertiesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Property$Type extends MessageType {
    constructor() {
        super("Property", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "contract_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "additional_address_line", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "city", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "state", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "zip", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "subdivision", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "directions", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "date_created", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "is_active", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 14, name: "is_residential", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 15, name: "notification", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "firstname", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "lastname", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 18, name: "businessname", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 19, name: "phone", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 20, name: "altphone", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 21, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 22, name: "geolocation_lat", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 23, name: "geolocation_lng", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 24, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 25, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 26, name: "not_equals", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 27, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 28, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 29, name: "group_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 30, name: "without_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, userId: 0, contractId: 0, address: "", additionalAddressLine: "", city: "", state: "", zip: "", subdivision: "", directions: "", notes: "", dateCreated: "", isActive: 0, isResidential: 0, notification: "", firstname: "", lastname: "", businessname: "", phone: "", altphone: "", email: "", geolocationLat: 0, geolocationLng: 0, fieldMask: [], pageNumber: 0, notEquals: [], orderBy: "", orderDir: "", groupBy: "", withoutLimit: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 user_id */ 2:
                    message.userId = reader.int32();
                    break;
                case /* int32 contract_id */ 3:
                    message.contractId = reader.int32();
                    break;
                case /* string address */ 4:
                    message.address = reader.string();
                    break;
                case /* string additional_address_line */ 5:
                    message.additionalAddressLine = reader.string();
                    break;
                case /* string city */ 6:
                    message.city = reader.string();
                    break;
                case /* string state */ 7:
                    message.state = reader.string();
                    break;
                case /* string zip */ 8:
                    message.zip = reader.string();
                    break;
                case /* string subdivision */ 9:
                    message.subdivision = reader.string();
                    break;
                case /* string directions */ 10:
                    message.directions = reader.string();
                    break;
                case /* string notes */ 11:
                    message.notes = reader.string();
                    break;
                case /* string date_created */ 12:
                    message.dateCreated = reader.string();
                    break;
                case /* int32 is_active */ 13:
                    message.isActive = reader.int32();
                    break;
                case /* int32 is_residential */ 14:
                    message.isResidential = reader.int32();
                    break;
                case /* string notification */ 15:
                    message.notification = reader.string();
                    break;
                case /* string firstname */ 16:
                    message.firstname = reader.string();
                    break;
                case /* string lastname */ 17:
                    message.lastname = reader.string();
                    break;
                case /* string businessname */ 18:
                    message.businessname = reader.string();
                    break;
                case /* string phone */ 19:
                    message.phone = reader.string();
                    break;
                case /* string altphone */ 20:
                    message.altphone = reader.string();
                    break;
                case /* string email */ 21:
                    message.email = reader.string();
                    break;
                case /* double geolocation_lat */ 22:
                    message.geolocationLat = reader.double();
                    break;
                case /* double geolocation_lng */ 23:
                    message.geolocationLng = reader.double();
                    break;
                case /* repeated string field_mask */ 24:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 25:
                    message.pageNumber = reader.int32();
                    break;
                case /* repeated string not_equals */ 26:
                    message.notEquals.push(reader.string());
                    break;
                case /* string order_by */ 27:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 28:
                    message.orderDir = reader.string();
                    break;
                case /* string group_by */ 29:
                    message.groupBy = reader.string();
                    break;
                case /* bool without_limit */ 30:
                    message.withoutLimit = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 user_id = 2; */
        if (message.userId !== 0)
            writer.tag(2, WireType.Varint).int32(message.userId);
        /* int32 contract_id = 3; */
        if (message.contractId !== 0)
            writer.tag(3, WireType.Varint).int32(message.contractId);
        /* string address = 4; */
        if (message.address !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.address);
        /* string additional_address_line = 5; */
        if (message.additionalAddressLine !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.additionalAddressLine);
        /* string city = 6; */
        if (message.city !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.city);
        /* string state = 7; */
        if (message.state !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.state);
        /* string zip = 8; */
        if (message.zip !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.zip);
        /* string subdivision = 9; */
        if (message.subdivision !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.subdivision);
        /* string directions = 10; */
        if (message.directions !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.directions);
        /* string notes = 11; */
        if (message.notes !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.notes);
        /* string date_created = 12; */
        if (message.dateCreated !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.dateCreated);
        /* int32 is_active = 13; */
        if (message.isActive !== 0)
            writer.tag(13, WireType.Varint).int32(message.isActive);
        /* int32 is_residential = 14; */
        if (message.isResidential !== 0)
            writer.tag(14, WireType.Varint).int32(message.isResidential);
        /* string notification = 15; */
        if (message.notification !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.notification);
        /* string firstname = 16; */
        if (message.firstname !== "")
            writer.tag(16, WireType.LengthDelimited).string(message.firstname);
        /* string lastname = 17; */
        if (message.lastname !== "")
            writer.tag(17, WireType.LengthDelimited).string(message.lastname);
        /* string businessname = 18; */
        if (message.businessname !== "")
            writer.tag(18, WireType.LengthDelimited).string(message.businessname);
        /* string phone = 19; */
        if (message.phone !== "")
            writer.tag(19, WireType.LengthDelimited).string(message.phone);
        /* string altphone = 20; */
        if (message.altphone !== "")
            writer.tag(20, WireType.LengthDelimited).string(message.altphone);
        /* string email = 21; */
        if (message.email !== "")
            writer.tag(21, WireType.LengthDelimited).string(message.email);
        /* double geolocation_lat = 22; */
        if (message.geolocationLat !== 0)
            writer.tag(22, WireType.Bit64).double(message.geolocationLat);
        /* double geolocation_lng = 23; */
        if (message.geolocationLng !== 0)
            writer.tag(23, WireType.Bit64).double(message.geolocationLng);
        /* repeated string field_mask = 24; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(24, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 25; */
        if (message.pageNumber !== 0)
            writer.tag(25, WireType.Varint).int32(message.pageNumber);
        /* repeated string not_equals = 26; */
        for (let i = 0; i < message.notEquals.length; i++)
            writer.tag(26, WireType.LengthDelimited).string(message.notEquals[i]);
        /* string order_by = 27; */
        if (message.orderBy !== "")
            writer.tag(27, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 28; */
        if (message.orderDir !== "")
            writer.tag(28, WireType.LengthDelimited).string(message.orderDir);
        /* string group_by = 29; */
        if (message.groupBy !== "")
            writer.tag(29, WireType.LengthDelimited).string(message.groupBy);
        /* bool without_limit = 30; */
        if (message.withoutLimit !== false)
            writer.tag(30, WireType.Varint).bool(message.withoutLimit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Property
 */
export const Property = new Property$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PropertyList$Type extends MessageType {
    constructor() {
        super("PropertyList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Property },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Property results */ 1:
                    message.results.push(Property.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated Property results = 1; */
        for (let i = 0; i < message.results.length; i++)
            Property.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PropertyList
 */
export const PropertyList = new PropertyList$Type();
/**
 * @generated ServiceType for protobuf service PropertyService
 */
export const PropertyService = new ServiceType("PropertyService", [
    { name: "Create", options: {}, I: Property, O: Property },
    { name: "Get", options: {}, I: Property, O: Property },
    { name: "BatchGet", options: {}, I: Property, O: PropertyList },
    { name: "Update", options: {}, I: Property, O: Property },
    { name: "Delete", options: {}, I: Property, O: Property },
    { name: "GetPropertyCoordinates", options: {}, I: Property, O: PropertyCoordinates },
    { name: "BatchGetPropertiesbyContractID", options: {}, I: Int32, O: PropertyList },
    { name: "FetchSubAccountProperties", options: {}, I: SubAccountPropertiesRequest, O: PropertyList },
    { name: "FixPropertyCoordinates", options: {}, I: Empty, O: Empty }
]);
