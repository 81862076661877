import './JobAdmin.module.less';

import { JobAdmin, type User } from '@kalos/kalos-rpc';
import {
  Button,
  cn,
  DataTable,
  DataTableColumnHeader,
  Skeleton,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@kalos/ui';
import { Pencil1Icon, TrashIcon } from '@radix-ui/react-icons';
import { type ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { produce } from 'immer';
import { type FC, useCallback, useMemo, useState } from 'react';

import {
  useCreateJobAminMutation,
  useDeleteJobAdminMutation,
  useJobAdminsListQuery,
  useUpdateJobAdminMutation,
} from '../../../hooks/react-query/eventDetails';
import { useBatchUserQuery } from '../../../hooks/react-query/useUserQuery';
import { Form } from '../Form';
import { Modal } from '../Modal';
import { type Schema } from '../PlainForm';
export interface Props {
  jobAdminEntries: JobAdmin[];
  jobNumber: number;
  loading?: boolean;
  onUpdateJobAdmins?: (entries: JobAdmin[]) => void; // this function, if used, should be something where we return the list to the component
}

const staticJobAdmins: JobAdmin[] = Array.from({ length: 3 }, (_, i) => JobAdmin.create());
/**
 * Component that show a list of job admins for the given job number.
 * In case jobNumber is not provided (0) - can be used as a component to create pending Job admin entries that can be retrieved via `onUpdateJobAdmins` prop. in this case `jobAdminEntries` props should be provided and adjusted using `onUpdateJobAdmins`
 */
export const JobAdminComponent: FC<Props> = ({
  jobAdminEntries,
  jobNumber,
  loading: loadingProp,
  onUpdateJobAdmins,
}) => {
  const mode: 'modification of existing job' | 'job creation' = jobNumber
    ? 'modification of existing job'
    : 'job creation';

  const jobAdminsQuery = useJobAdminsListQuery({
    filter: {
      jobNumber,
      isActive: true,
    },
    enabled: mode === 'modification of existing job',
  });

  const employeesQuery = useBatchUserQuery({
    filters: {
      isActive: 1,
      isEmployee: 1,
      overrideLimit: true,
    },
    select(data) {
      return data.results.map((entry) => ({
        ...entry,
        fullName: `${entry.lastname} ${entry.firstname}`,
      }));
    },
  });

  const loading =
    employeesQuery.isPending ||
    (mode === 'modification of existing job' && jobAdminsQuery.isPending) ||
    loadingProp;

  const createJobAdminMutation = useCreateJobAminMutation();
  const updateJobAdminMutation = useUpdateJobAdminMutation();
  const deleteJobAdminMutation = useDeleteJobAdminMutation();

  interface state$ {
    loaded: boolean;
    editing: JobAdmin | undefined;
    saving: boolean;
    users: User[];
    method: 'Create' | 'Edit' | undefined;
  }

  const initialState = {
    loaded: false,
    loading: true,
    editing: undefined,
    saving: false,
    users: [],
    method: undefined,
  };

  const [state, setState] = useState<state$>(initialState);

  const handleSetState = useCallback(function handleSetState(partialState: Partial<state$>) {
    setState((currState) =>
      produce(currState, (draft) => {
        return { ...draft, ...partialState };
      }),
    );
  }, []);

  const createRecord = async (entry: JobAdmin) => {
    //We should create a record, update the local list
    if (mode === 'modification of existing job') {
      try {
        const res = await createJobAdminMutation.mutateAsync(JobAdmin.clone(entry));
        if (onUpdateJobAdmins) {
          onUpdateJobAdmins(jobAdminEntries.concat(res));
        }
        handleSetState({ editing: undefined, method: undefined });
      } catch (err) {
        alert('Failed to job creation admin record');
        console.error('Job admin creation error', err);
      }
    }

    if (mode === 'job creation' && onUpdateJobAdmins) {
      onUpdateJobAdmins(jobAdminEntries.concat(entry));
      handleSetState({ editing: undefined, method: undefined });
    }
  };

  const updateJobAdminById = (items: JobAdmin[], newItem: JobAdmin): JobAdmin[] => {
    return items.map((item) => {
      if (item.id === newItem.id) {
        return newItem;
      } else {
        return item;
      }
    });
  };

  const updateRecord = async (entry: JobAdmin) => {
    if (mode === 'modification of existing job') {
      try {
        const updateResult = await updateJobAdminMutation.mutateAsync(JobAdmin.clone(entry));

        if (onUpdateJobAdmins) {
          const updatedItems = updateJobAdminById(jobAdminEntries, updateResult);
          onUpdateJobAdmins(updatedItems);
        }

        handleSetState({ editing: undefined, method: undefined });
      } catch (err) {
        alert('There was an error updating job admin');
        console.error('Job admin update error', err);
      }
    }

    if (mode === 'job creation' && onUpdateJobAdmins) {
      const updatedItems = updateJobAdminById(jobAdminEntries, entry);
      onUpdateJobAdmins(updatedItems);
      handleSetState({ editing: undefined, method: undefined });
    }
  };

  const deleteRecord = useCallback(
    async (entry: JobAdmin) => {
      //We should create a record, update the local list
      if (
        mode === 'modification of existing job' &&
        confirm(`Are you sure you want to delete job admin?`)
      ) {
        console.log({ entry });
        try {
          const deleteResult = await deleteJobAdminMutation.mutateAsync(JobAdmin.clone(entry));
          console.log({ deleteResult });
          if (onUpdateJobAdmins) {
            onUpdateJobAdmins(jobAdminEntries.filter((el) => el.id !== entry.id));
          }
        } catch (err) {
          alert('There was an error deleting job admin');
          console.error('Job admin deleting error', err);
        }
      }

      if (
        mode === 'job creation' &&
        onUpdateJobAdmins &&
        confirm(`Are you sure you want to delete job admin?`)
      ) {
        onUpdateJobAdmins(jobAdminEntries.filter((el) => el.id !== entry.id));
      }
    },
    [deleteJobAdminMutation, jobAdminEntries, mode, onUpdateJobAdmins],
  );

  const entriesToShow = useMemo(() => {
    return mode === 'modification of existing job'
      ? jobAdminsQuery.data?.results ?? []
      : jobAdminEntries;
  }, [jobAdminEntries, jobAdminsQuery.data?.results, mode]);

  const tableColumns = useMemo<ColumnDef<JobAdmin>[]>(() => {
    return [
      {
        accessorKey: 'title',
        meta: {
          className: 'w-24',
        },
        header: ({ column }) => <DataTableColumnHeader column={column} title="Title" />,
        cell({ column, row }) {
          return <div className={column.columnDef.meta?.className}>{row.original.title}</div>;
        },
      },
      {
        accessorKey: 'userId',
        meta: {
          className: 'xs:w-40 ',
        },
        header: ({ column }) => <DataTableColumnHeader column={column} title="Employee" />,
        cell({ row, column }) {
          return (
            <div className={column.columnDef.meta?.className}>
              {employeesQuery.data?.find((el) => el.id === row.original.userId)?.fullName ||
                'Unknown'}
            </div>
          );
        },
      },
      {
        id: 'actions',
        meta: {
          className: 'ml-auto w-24 flex gap-2',
        },
        header: () => (
          <div className="flex">
            <Button
              disabled={loading}
              size="sm"
              className="ml-auto"
              onClick={() => {
                handleSetState({
                  editing: JobAdmin.create({ jobNumber: jobNumber }),
                  method: 'Create',
                });
              }}
            >
              Add Admin
            </Button>
          </div>
        ),

        cell({ row, column }) {
          return (
            <div className={column.columnDef.meta?.className}>
              <TooltipProvider delayDuration={150}>
                <Tooltip>
                  <TooltipContent>Edit Job Admin</TooltipContent>
                  <TooltipTrigger asChild>
                    <Button
                      size="icon"
                      variant="outline"
                      onClick={() => handleSetState({ editing: row.original, method: 'Edit' })}
                    >
                      <Pencil1Icon />
                    </Button>
                  </TooltipTrigger>
                </Tooltip>

                <Tooltip>
                  <TooltipContent>Delete Job Admin</TooltipContent>
                  <TooltipTrigger asChild>
                    <Button
                      variant="outline"
                      size="icon"
                      onClick={() => deleteRecord(row.original)}
                    >
                      <TrashIcon />
                    </Button>
                  </TooltipTrigger>
                </Tooltip>
              </TooltipProvider>
            </div>
          );
        },
      },
    ];
  }, [deleteRecord, employeesQuery.data, handleSetState, jobNumber, loading]);

  const loadingColumns = useMemo<ColumnDef<JobAdmin>[]>(() => {
    return tableColumns.map((column) => {
      return {
        ...column,
        cell: ({ column }) => <Skeleton className={cn(column.columnDef.meta?.className, 'h-4')} />,
      };
    });
  }, [tableColumns]);

  const table = useReactTable({
    columns: loading ? loadingColumns : tableColumns,
    data: loading ? staticJobAdmins : entriesToShow,
    getCoreRowModel: getCoreRowModel(),
    enableSorting: false,
  });

  const technicianFilters = useMemo(() => {
    return jobAdminEntries
      .map((entry) => state.users.find((el) => el.id === entry.userId)?.id || 0)
      .filter((item) => state.method === 'Create' || item !== state.editing?.userId);
  }, [jobAdminEntries, state.editing?.userId, state.method, state.users]);

  const SCHEMA: Schema<JobAdmin> = [
    [
      {
        label: 'Job Admin',
        name: 'userId',
        type: 'technician',
        required: true,
        jobAdminTechnicianFilter: true,
        technicianFilters,
      },
    ],
    [
      {
        label: 'Title',
        name: 'title',
        type: 'text',
        required: true,
      },
    ],
    [{ name: 'jobNumber', type: 'hidden' }],

    [{ name: 'id', type: 'hidden' }],
  ];

  return (
    <div className="flex flex-col">
      <DataTable table={table} />
      {state.editing && (
        <Modal open onClose={() => handleSetState({ editing: undefined, method: undefined })}>
          <Form
            title={state.method}
            data={state.editing}
            onClose={() => handleSetState({ editing: undefined, method: undefined })}
            onSave={(data) => (state.method === 'Create' ? createRecord(data) : updateRecord(data))}
            schema={SCHEMA}
            disabled={createJobAdminMutation.isPending || updateJobAdminMutation.isPending}
          />
        </Modal>
      )}
    </div>
  );
};
