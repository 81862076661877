import { type LoadUsersByFilter, User, type UsersSort } from '@kalos/kalos-rpc';
import {
  Button,
  DataTable,
  DataTableColumnHeader,
  Form,
  Skeleton,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@kalos/ui';
import { MagnifyingGlassIcon, Pencil1Icon, TrashIcon } from '@radix-ui/react-icons';
import { useQueryClient } from '@tanstack/react-query';
import {
  type ColumnDef,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  type OnChangeFn,
  type SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { FORM_AUTOMATIC_SEARCH_DEBOUNCE_TIME } from '../../../../constants';
import { queryKeys } from '../../../../hooks/react-query/constants';
import { useUserListQuery } from '../../../../hooks/react-query/useUserQuery';
import { debounce, UserClientService } from '../../../../tools/helpers';
import { ConfirmDelete } from '../../ConfirmDelete';
import { CustomerEdit } from '../../CustomerEdit';
import { Modal } from '../../Modal';
import { type AdvancedSearchProps } from '..';
import { useAdvancedSearchPaginationStore } from '../paginationStore';
import CustomerSearchForm from './CustomerSearchForm';
import { type UserSearchSchemeType, useUserSearchForm } from './utils';

const loadingCustomers: User[] = Array.from({ length: 5 }, (_, i) => User.create());

type CustomersSearchProps = Pick<AdvancedSearchProps, 'editableCustomers' | 'deletableCustomers'>;
export const CustomersSearch = ({
  editableCustomers,
  deletableCustomers,
}: CustomersSearchProps) => {
  const page = useAdvancedSearchPaginationStore((state) => state.page);
  const setPage = useAdvancedSearchPaginationStore((state) => state.setPage);
  const setTotalEntriesCount = useAdvancedSearchPaginationStore(
    (state) => state.setTotalEntriesCount,
  );
  const navigate = useNavigate();

  const userSearchForm = useUserSearchForm();

  const [customerFilter, setCustomerFilter] = useState<UserSearchSchemeType>(() => {
    return userSearchForm.getValues();
  });

  useEffect(() => {
    const debouncedUpdate = debounce((data: UserSearchSchemeType) => {
      setPage(0);
      setCustomerFilter(data);
    }, FORM_AUTOMATIC_SEARCH_DEBOUNCE_TIME);
    const updateSubscription = userSearchForm.watch(debouncedUpdate);
    return () => updateSubscription.unsubscribe();
  }, [setPage, userSearchForm]);

  const [customersSort, setCustomersSort] = useState<UsersSort>({
    orderByField: 'lastname',
    orderBy: 'user_lastname',
    orderDir: 'ASC',
  });

  const customersFilterCriteria = useMemo<LoadUsersByFilter>(() => {
    const criteria: LoadUsersByFilter = {
      page,
      filter: {
        ...customerFilter,
        employeeDepartmentId: 0,
        isEmployee: 0,
        fieldMask: ['IsEmployee'],
      },
      sort: customersSort,
    };
    return criteria;
  }, [page, customerFilter, customersSort]);

  const customersQuery = useUserListQuery({
    filters: customersFilterCriteria,
  });

  const queryClient = useQueryClient();

  const onRowClick = useCallback(
    (user: User) => {
      navigate(`/customers/${user.id}`);
    },
    [navigate],
  );

  useEffect(() => {
    if (customersQuery.isSuccess) {
      setTotalEntriesCount(customersQuery.data?.totalCount || 0);
    }
  }, [customersQuery.data?.totalCount, customersQuery.isSuccess, setTotalEntriesCount]);

  const [pendingCustomerEditing, setPendingCustomerEditing] = useState<User | null>(null);
  const handlePendingCustomerEditingToggle = useCallback(
    (pendingCustomerEditing: User | null) => () =>
      setPendingCustomerEditing(pendingCustomerEditing),
    [setPendingCustomerEditing],
  );

  const [pendingCustomerDeleting, setPendingCustomerDeleting] = useState<User | null>(null);
  const handlePendingCustomerDeletingToggle = useCallback(
    (pendingCustomerDeleting: User | null) => () =>
      setPendingCustomerDeleting(pendingCustomerDeleting),
    [setPendingCustomerDeleting],
  );

  const customersTableColumns = useMemo<ColumnDef<User>[]>(() => {
    const columns: ColumnDef<User>[] = [
      {
        accessorKey: 'firstname',
        header: ({ column }) => (
          <DataTableColumnHeader hideVisibilityToggle title="First Name" column={column} />
        ),
        cell({ row }) {
          const firstName = row.getValue('firstname');
          return <div className="w-32">{String(firstName)}</div>;
        },
        sortingFn: () => 0,
      },
      {
        accessorKey: 'lastname',
        header: ({ column }) => (
          <DataTableColumnHeader hideVisibilityToggle title="Last Name" column={column} />
        ),
        cell({ row }) {
          const lastname = row.getValue('lastname');
          return <div className="w-32">{String(lastname)}</div>;
        },
        sortingFn: () => 0,
      },
      {
        accessorKey: 'businessname',
        header: ({ column }) => (
          <DataTableColumnHeader hideVisibilityToggle title="Business Name" column={column} />
        ),
        cell({ row }) {
          const lastname = row.getValue('businessname');
          return <div className="w-32">{String(lastname)}</div>;
        },
        sortingFn: () => 0,
      },
      {
        accessorKey: 'phone',
        header: ({ column }) => (
          <DataTableColumnHeader hideVisibilityToggle title="Primary Phone" column={column} />
        ),
        cell({ row }) {
          const phone = row.getValue('phone');
          return <div className="w-32">{String(phone)}</div>;
        },
        sortingFn: () => 0,
      },
      {
        accessorKey: 'email',
        header: ({ column }) => (
          <DataTableColumnHeader hideVisibilityToggle title="Email" column={column} />
        ),
        cell({ row }) {
          const email = row.getValue('email');
          return <div className="w-32 break-all">{String(email)}</div>;
        },
        sortingFn: () => 0,
      },
      {
        id: 'actions',
        header: 'Actions',
        cell({ row }) {
          const entry = row.original;
          return (
            <div className="flex w-32 gap-2">
              <TooltipProvider delayDuration={150}>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      variant="outline"
                      className="px-2"
                      asChild
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <Link to={`/customers/${row.original.id}`}>
                        <MagnifyingGlassIcon />
                      </Link>
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent className="px-2 py-1">View customer</TooltipContent>
                </Tooltip>
                {editableCustomers && (
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        variant="outline"
                        className="px-2"
                        onClick={(e) => {
                          e.stopPropagation();
                          setPendingCustomerEditing(entry);
                        }}
                      >
                        <Pencil1Icon />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent className="px-2 py-1">Edit customer</TooltipContent>
                  </Tooltip>
                )}
                {deletableCustomers && (
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        title="Delete"
                        variant="outline"
                        className="px-2"
                        onClick={(e) => {
                          e.stopPropagation();
                          setPendingCustomerDeleting(entry);
                        }}
                      >
                        <TrashIcon />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent className="px-2 py-1">Delete customer</TooltipContent>
                  </Tooltip>
                )}
              </TooltipProvider>
            </div>
          );
        },
      },
    ];
    return columns;
  }, [deletableCustomers, editableCustomers]);

  const [sorting, setSorting] = useState<SortingState>([]);

  const onSortChange: OnChangeFn<SortingState> = useCallback(
    (updater) => {
      setSorting((previousState) => {
        const newState = typeof updater === 'function' ? updater(previousState) : updater;
        setCustomersSort({
          orderByField: newState[0].id as keyof User,
          orderDir: newState[0].desc ? 'DESC' : 'ASC',
          orderBy: `user_${newState[0].id}`,
        });
        return newState;
      });
      setPage(0);
    },
    [setPage],
  );

  const skeletonColumns = useMemo<ColumnDef<User>[]>(() => {
    return customersTableColumns.map((column) => ({
      ...column,
      cell: () => <Skeleton className="bg-foreground/30 h-4 w-32" />,
    }));
  }, [customersTableColumns]);

  const table = useReactTable({
    data: customersQuery.isPending ? loadingCustomers : customersQuery.data?.results ?? [],
    columns: customersQuery.isPending ? skeletonColumns : customersTableColumns,
    state: {
      sorting,
      pagination: {
        pageIndex: 0,
        pageSize: 25,
      },
    },
    onSortingChange: onSortChange,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  const onSaveCustomer = useCallback(() => {
    setPendingCustomerEditing(null);

    queryClient.refetchQueries({ queryKey: [queryKeys.user.root, queryKeys.user.list] });
  }, [queryClient]);

  const handleDeleteCustomer = useCallback(async () => {
    if (pendingCustomerDeleting) {
      const id = pendingCustomerDeleting.id;
      setPendingCustomerDeleting(null);
      await UserClientService.deleteUserById(id);
      queryClient.refetchQueries({ queryKey: [queryKeys.user.root, queryKeys.user.list] });
    }
  }, [pendingCustomerDeleting, queryClient]);

  return (
    <>
      <div className="p-4">
        <Form {...userSearchForm}>
          <CustomerSearchForm />
        </Form>
      </div>

      <DataTable onRowClick={onRowClick} table={table} />

      {pendingCustomerEditing && (
        <Modal open onClose={handlePendingCustomerEditingToggle(null)} fullScreen>
          <CustomerEdit
            onClose={handlePendingCustomerEditingToggle(null)}
            userId={pendingCustomerEditing.id}
            customer={pendingCustomerEditing}
            onSave={onSaveCustomer}
          />
        </Modal>
      )}
      {pendingCustomerDeleting && (
        <ConfirmDelete
          open
          onClose={handlePendingCustomerDeletingToggle(null)}
          onConfirm={handleDeleteCustomer}
          kind="Customer"
          name={UserClientService.getCustomerNameAndBusinessName(pendingCustomerDeleting)}
        />
      )}
    </>
  );
};
