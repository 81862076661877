// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "vendor.proto" (syntax proto3)
// tslint:disable
// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "vendor.proto" (syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { WireType } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
// @generated message type with reflection information, may provide speed optimized methods
class VendorOrder$Type extends MessageType {
    constructor() {
        super("VendorOrder", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "vendor_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "quote_part_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "reference", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "order_status", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "order_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "order_eta", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, vendorId: 0, quotePartId: 0, reference: "", orderStatus: 0, orderDate: "", orderEta: "", fieldMask: [], pageNumber: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 vendor_id */ 2:
                    message.vendorId = reader.int32();
                    break;
                case /* int32 quote_part_id */ 3:
                    message.quotePartId = reader.int32();
                    break;
                case /* string reference */ 4:
                    message.reference = reader.string();
                    break;
                case /* int32 order_status */ 5:
                    message.orderStatus = reader.int32();
                    break;
                case /* string order_date */ 6:
                    message.orderDate = reader.string();
                    break;
                case /* string order_eta */ 7:
                    message.orderEta = reader.string();
                    break;
                case /* repeated string field_mask */ 8:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 9:
                    message.pageNumber = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 vendor_id = 2; */
        if (message.vendorId !== 0)
            writer.tag(2, WireType.Varint).int32(message.vendorId);
        /* int32 quote_part_id = 3; */
        if (message.quotePartId !== 0)
            writer.tag(3, WireType.Varint).int32(message.quotePartId);
        /* string reference = 4; */
        if (message.reference !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.reference);
        /* int32 order_status = 5; */
        if (message.orderStatus !== 0)
            writer.tag(5, WireType.Varint).int32(message.orderStatus);
        /* string order_date = 6; */
        if (message.orderDate !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.orderDate);
        /* string order_eta = 7; */
        if (message.orderEta !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.orderEta);
        /* repeated string field_mask = 8; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(8, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 9; */
        if (message.pageNumber !== 0)
            writer.tag(9, WireType.Varint).int32(message.pageNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message VendorOrder
 */
export const VendorOrder = new VendorOrder$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VendorOrderList$Type extends MessageType {
    constructor() {
        super("VendorOrderList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => VendorOrder },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated VendorOrder results */ 1:
                    message.results.push(VendorOrder.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated VendorOrder results = 1; */
        for (let i = 0; i < message.results.length; i++)
            VendorOrder.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message VendorOrderList
 */
export const VendorOrderList = new VendorOrderList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Vendor$Type extends MessageType {
    constructor() {
        super("Vendor", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "vendor_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "vendor_city", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "vendor_state", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "vendor_zip", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "vendor_address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "is_active", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "without_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 11, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, vendorName: "", vendorCity: "", vendorState: "", vendorZip: "", vendorAddress: "", isActive: 0, fieldMask: [], pageNumber: 0, withoutLimit: false, orderBy: "", orderDir: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string vendor_name */ 2:
                    message.vendorName = reader.string();
                    break;
                case /* string vendor_city */ 3:
                    message.vendorCity = reader.string();
                    break;
                case /* string vendor_state */ 4:
                    message.vendorState = reader.string();
                    break;
                case /* string vendor_zip */ 5:
                    message.vendorZip = reader.string();
                    break;
                case /* string vendor_address */ 6:
                    message.vendorAddress = reader.string();
                    break;
                case /* int32 is_active */ 7:
                    message.isActive = reader.int32();
                    break;
                case /* repeated string field_mask */ 8:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 9:
                    message.pageNumber = reader.int32();
                    break;
                case /* bool without_limit */ 10:
                    message.withoutLimit = reader.bool();
                    break;
                case /* string order_by */ 11:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 12:
                    message.orderDir = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string vendor_name = 2; */
        if (message.vendorName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.vendorName);
        /* string vendor_city = 3; */
        if (message.vendorCity !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.vendorCity);
        /* string vendor_state = 4; */
        if (message.vendorState !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.vendorState);
        /* string vendor_zip = 5; */
        if (message.vendorZip !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.vendorZip);
        /* string vendor_address = 6; */
        if (message.vendorAddress !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.vendorAddress);
        /* int32 is_active = 7; */
        if (message.isActive !== 0)
            writer.tag(7, WireType.Varint).int32(message.isActive);
        /* repeated string field_mask = 8; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(8, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 9; */
        if (message.pageNumber !== 0)
            writer.tag(9, WireType.Varint).int32(message.pageNumber);
        /* bool without_limit = 10; */
        if (message.withoutLimit !== false)
            writer.tag(10, WireType.Varint).bool(message.withoutLimit);
        /* string order_by = 11; */
        if (message.orderBy !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 12; */
        if (message.orderDir !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.orderDir);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Vendor
 */
export const Vendor = new Vendor$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VendorList$Type extends MessageType {
    constructor() {
        super("VendorList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Vendor },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Vendor results */ 1:
                    message.results.push(Vendor.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated Vendor results = 1; */
        for (let i = 0; i < message.results.length; i++)
            Vendor.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message VendorList
 */
export const VendorList = new VendorList$Type();
/**
 * @generated ServiceType for protobuf service VendorService
 */
export const VendorService = new ServiceType("VendorService", [
    { name: "Create", options: {}, I: Vendor, O: Vendor },
    { name: "Get", options: {}, I: Vendor, O: Vendor },
    { name: "BatchGet", options: {}, I: Vendor, O: VendorList },
    { name: "Update", options: {}, I: Vendor, O: Vendor },
    { name: "Delete", options: {}, I: Vendor, O: Vendor },
    { name: "CreateVendorOrder", options: {}, I: VendorOrder, O: VendorOrder },
    { name: "GetVendorOrder", options: {}, I: VendorOrder, O: VendorOrder },
    { name: "BatchGetVendorOrder", options: {}, I: VendorOrder, O: VendorOrderList },
    { name: "UpdateVendorOrder", options: {}, I: VendorOrder, O: VendorOrder },
    { name: "DeleteVendorOrder", options: {}, I: VendorOrder, O: VendorOrder }
]);
