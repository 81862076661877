// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "prompt_payment.proto" (syntax proto3)
// tslint:disable
import { PromptPaymentService } from "./prompt_payment";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service PromptPaymentService
 */
export class PromptPaymentServiceClient {
    constructor(_transport) {
        this._transport = _transport;
        this.typeName = PromptPaymentService.typeName;
        this.methods = PromptPaymentService.methods;
        this.options = PromptPaymentService.options;
    }
    /**
     * @generated from protobuf rpc: CreatePromptPaymentOverride(PromptPaymentOverride) returns (PromptPaymentOverride);
     */
    createPromptPaymentOverride(input, options) {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetPromptPaymentOverride(PromptPaymentOverride) returns (PromptPaymentOverride);
     */
    getPromptPaymentOverride(input, options) {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetPromptPaymentOverride(PromptPaymentOverride) returns (PromptPaymentOverrideList);
     */
    batchGetPromptPaymentOverride(input, options) {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdatePromptPaymentOverride(PromptPaymentOverride) returns (PromptPaymentOverride);
     */
    updatePromptPaymentOverride(input, options) {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeletePromptPaymentOverride(PromptPaymentOverride) returns (PromptPaymentOverride);
     */
    deletePromptPaymentOverride(input, options) {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreatePromptPaymentRebate(PromptPaymentRebate) returns (PromptPaymentRebate);
     */
    createPromptPaymentRebate(input, options) {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetPromptPaymentRebate(PromptPaymentRebate) returns (PromptPaymentRebate);
     */
    getPromptPaymentRebate(input, options) {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetPromptPaymentRebate(PromptPaymentRebate) returns (PromptPaymentRebateList);
     */
    batchGetPromptPaymentRebate(input, options) {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdatePromptPaymentRebate(PromptPaymentRebate) returns (PromptPaymentRebate);
     */
    updatePromptPaymentRebate(input, options) {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeletePromptPaymentRebate(PromptPaymentRebate) returns (PromptPaymentRebate);
     */
    deletePromptPaymentRebate(input, options) {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
}
