// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "email.proto" (syntax proto3)
// tslint:disable
// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "email.proto" (syntax proto3)
// tslint:disable
import { Bool } from "./common";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { WireType } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
// @generated message type with reflection information, may provide speed optimized methods
class SQSEmail$Type extends MessageType {
    constructor() {
        super("SQSEmail", [
            { no: 1, name: "body", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "subject", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "to", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "from", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { body: "", subject: "", to: "", from: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string body */ 1:
                    message.body = reader.string();
                    break;
                case /* string subject */ 2:
                    message.subject = reader.string();
                    break;
                case /* string to */ 3:
                    message.to = reader.string();
                    break;
                case /* string from */ 4:
                    message.from = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string body = 1; */
        if (message.body !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.body);
        /* string subject = 2; */
        if (message.subject !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.subject);
        /* string to = 3; */
        if (message.to !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.to);
        /* string from = 4; */
        if (message.from !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.from);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SQSEmail
 */
export const SQSEmail = new SQSEmail$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Email$Type extends MessageType {
    constructor() {
        super("Email", [
            { no: 1, name: "recipient", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "from_key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "body", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "sent", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "error", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "subject", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "from_override", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "download_uRL", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { recipient: "", fromKey: "", body: "", sent: 0, error: "", subject: "", fromOverride: "", downloadURL: "", address: "", userId: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string recipient */ 1:
                    message.recipient = reader.string();
                    break;
                case /* string from_key */ 2:
                    message.fromKey = reader.string();
                    break;
                case /* string body */ 3:
                    message.body = reader.string();
                    break;
                case /* int32 sent */ 4:
                    message.sent = reader.int32();
                    break;
                case /* string error */ 5:
                    message.error = reader.string();
                    break;
                case /* string subject */ 6:
                    message.subject = reader.string();
                    break;
                case /* string from_override */ 7:
                    message.fromOverride = reader.string();
                    break;
                case /* string download_uRL */ 8:
                    message.downloadURL = reader.string();
                    break;
                case /* string address */ 9:
                    message.address = reader.string();
                    break;
                case /* int32 user_id */ 10:
                    message.userId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string recipient = 1; */
        if (message.recipient !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.recipient);
        /* string from_key = 2; */
        if (message.fromKey !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.fromKey);
        /* string body = 3; */
        if (message.body !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.body);
        /* int32 sent = 4; */
        if (message.sent !== 0)
            writer.tag(4, WireType.Varint).int32(message.sent);
        /* string error = 5; */
        if (message.error !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.error);
        /* string subject = 6; */
        if (message.subject !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.subject);
        /* string from_override = 7; */
        if (message.fromOverride !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.fromOverride);
        /* string download_uRL = 8; */
        if (message.downloadURL !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.downloadURL);
        /* string address = 9; */
        if (message.address !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.address);
        /* int32 user_id = 10; */
        if (message.userId !== 0)
            writer.tag(10, WireType.Varint).int32(message.userId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Email
 */
export const Email = new Email$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InvoiceBodyReturn$Type extends MessageType {
    constructor() {
        super("InvoiceBodyReturn", [
            { no: 1, name: "body", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "subject", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { body: "", subject: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string body */ 1:
                    message.body = reader.string();
                    break;
                case /* string subject */ 2:
                    message.subject = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string body = 1; */
        if (message.body !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.body);
        /* string subject = 2; */
        if (message.subject !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.subject);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message InvoiceBodyReturn
 */
export const InvoiceBodyReturn = new InvoiceBodyReturn$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PropertyDocumentRequest$Type extends MessageType {
    constructor() {
        super("PropertyDocumentRequest", [
            { no: 1, name: "emailList", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "document_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "property_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { emailList: [], documentId: 0, propertyId: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string emailList */ 1:
                    message.emailList.push(reader.string());
                    break;
                case /* int32 document_id */ 2:
                    message.documentId = reader.int32();
                    break;
                case /* int32 property_id */ 3:
                    message.propertyId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated string emailList = 1; */
        for (let i = 0; i < message.emailList.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.emailList[i]);
        /* int32 document_id = 2; */
        if (message.documentId !== 0)
            writer.tag(2, WireType.Varint).int32(message.documentId);
        /* int32 property_id = 3; */
        if (message.propertyId !== 0)
            writer.tag(3, WireType.Varint).int32(message.propertyId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PropertyDocumentRequest
 */
export const PropertyDocumentRequest = new PropertyDocumentRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InvoiceBodyRequest$Type extends MessageType {
    constructor() {
        super("InvoiceBodyRequest", [
            { no: 1, name: "filename", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "property_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "invoice_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "type", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { filename: "", propertyId: 0, invoiceId: 0, type: 0, description: "", email: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string filename */ 1:
                    message.filename = reader.string();
                    break;
                case /* int32 property_id */ 2:
                    message.propertyId = reader.int32();
                    break;
                case /* int32 invoice_id */ 3:
                    message.invoiceId = reader.int32();
                    break;
                case /* int32 type */ 4:
                    message.type = reader.int32();
                    break;
                case /* string description */ 5:
                    message.description = reader.string();
                    break;
                case /* string email */ 6:
                    message.email = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string filename = 1; */
        if (message.filename !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.filename);
        /* int32 property_id = 2; */
        if (message.propertyId !== 0)
            writer.tag(2, WireType.Varint).int32(message.propertyId);
        /* int32 invoice_id = 3; */
        if (message.invoiceId !== 0)
            writer.tag(3, WireType.Varint).int32(message.invoiceId);
        /* int32 type = 4; */
        if (message.type !== 0)
            writer.tag(4, WireType.Varint).int32(message.type);
        /* string description = 5; */
        if (message.description !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.description);
        /* string email = 6; */
        if (message.email !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.email);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message InvoiceBodyRequest
 */
export const InvoiceBodyRequest = new InvoiceBodyRequest$Type();
/**
 * @generated ServiceType for protobuf service EmailService
 */
export const EmailService = new ServiceType("EmailService", [
    { name: "Create", options: {}, I: Email, O: Email },
    { name: "SendSQS", options: {}, I: SQSEmail, O: Bool },
    { name: "SendPropertyDocument", options: {}, I: PropertyDocumentRequest, O: Bool },
    { name: "SendPropertyDocumentExpire", options: {}, I: PropertyDocumentRequest, O: Bool }
]);
