import { EmployeeItem, type User } from '@kalos/kalos-rpc';
import { Dialog, DialogContent, DialogTitle, DialogTrigger, toast } from '@kalos/ui';
import { useCallback, useMemo, useState } from 'react';

import { useEmployeeItemCreateMutation } from '../../hooks/react-query/employeeItems';
import { timestamp } from '../../tools/helpers';
import { EmployeeItemForm, getDefaultEmployeeItemFormValues } from './EmployeeItemForm';

export const EmployeeItemCreateDialog = ({
  trigger,
  assignToUserId,
}: {
  trigger: React.ReactNode;
  assignToUserId: User['id'];
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const defaultValues = useMemo(() => getDefaultEmployeeItemFormValues(), []);
  const createMutation = useEmployeeItemCreateMutation();

  const onSave = useCallback<React.ComponentProps<typeof EmployeeItemForm>['onSave']>(
    async ({ data }) => {
      try {
        await createMutation.mutateAsync(
          EmployeeItem.create({
            date: timestamp(false, data.date),
            name: data.name,
            isActive: true,
            userId: assignToUserId,
          }),
        );
        toast({
          variant: 'success',
          title: 'Employee Item Created',
        });
        setIsOpen(false);
      } catch (err) {
        toast({
          variant: 'destructive',
          title: 'Employee Item Creation failed',
        });
      }
    },
    [assignToUserId, createMutation],
  );

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <DialogContent className="@container max-w-xl">
        <DialogTitle>Create Employee Item</DialogTitle>
        <EmployeeItemForm
          defaultValues={defaultValues}
          onSave={onSave}
          disabled={createMutation.isPending}
          isLoading={createMutation.isPending}
        />
      </DialogContent>
    </Dialog>
  );
};
