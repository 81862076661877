import {
  AuditPageReq,
  Event,
  RecordPageReq,
  type TimesheetDepartment,
  Transaction,
  type TransactionAccount,
  TransactionActivity,
} from '@kalos/kalos-rpc';
import {
  Button,
  Card,
  Checkbox,
  cn,
  DataTable,
  DataTableColumnHeader,
  DataTablePagination,
  Form,
  Skeleton,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@kalos/ui';
import { CheckIcon, Cross1Icon, Pencil1Icon } from '@radix-ui/react-icons';
import { useQueryClient } from '@tanstack/react-query';
import {
  type ColumnDef,
  getCoreRowModel,
  getPaginationRowModel,
  type OnChangeFn,
  type Row,
  type SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { format, parseISO } from 'date-fns';
import { produce } from 'immer';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { JobPreview } from '../../../components/JobPreview';
import { type JobCheckStatus, JobSelector } from '../../../components/JobSelector';
import { FORM_AUTOMATIC_SEARCH_DEBOUNCE_TIME } from '../../../constants';
import { withTransactionLineItemsStoreContext } from '../../../context/TransactionLIneItems';
import { queryKeys } from '../../../hooks/react-query/constants';
import {
  eventQuery,
  useEventQueryInline,
  useEventUpdateMutation,
} from '../../../hooks/react-query/useEventsQuery';
import { useTransactionAdminQuery } from '../../../hooks/react-query/useTransactionAdminQuery';
import { useTransactionUpdateMutation } from '../../../hooks/react-query/useTransactionQuery';
import { useUserQuery } from '../../../hooks/react-query/useUserQuery';
import { useCopyToClipboard } from '../../../hooks/useCopyToClipboard';
import {
  debounce,
  type OrderDir,
  trailingZero,
  TransactionActivityClientService,
  TransactionClientService,
} from '../../../tools/helpers';
import { ExportJSON } from '../../ComponentsLibrary/ExportJSON';
import {
  TimesheetDepartmentPickerV2,
  TransactionAccountSelectPickerV2,
} from '../../ComponentsLibrary/Pickers/newPickers/QueryPickerV2';
import { useMakeTransactionActivityLog } from '../../ComponentsLibrary/TransactionTable/components/TransactionActions';
import { Loader } from '../../Loader/main';
import {
  TransactionAdminSearchForm,
  type TransactionAdminSearchSchemeType,
  useTransactionAdminSearchForm,
} from './TransactionAdminSearch';
import { TransactionRowAdminActions } from './TransactionRowAdminActions';

interface props {
  userID: number;
  departmentID: number;
  isSU: boolean;
  showMultipleDepartments?: boolean;
  departmentIDList?: string;
}

export type AdminTransactionFilter = {
  sort: ISort;
  userID?: number;
  dateCreated?: number;
  yearCreated?: number;
  searchValue?: string;
  dateSubmitted?: string;
  accountValue?: string;
  approvedByID?: number;
  costCenterID?: number;
  departmentID?: number;
  statusID: string;
  withoutLimit?: boolean;
  includeLineItems?: boolean;
};

interface ISort {
  sortBy: sortString;
  sortDir: OrderDir;
}
interface IState {
  page: number;
  isLoading: boolean;
  filters: TransactionAdminSearchSchemeType;
  editingCostCenter: IsEditingStruct;
  editingDepartment: IsEditingStruct;
  editingStateTax: IsEditingStruct;
  showCreateModal: boolean;
  updatingStateTax: IsEditingStruct;
}

type sortString =
  | 'description'
  | 'timestamp'
  | 'owner_id'
  | 'cost_center_id'
  | 'department_id'
  | 'job_id'
  | 'amount'
  | 'owner_name'
  | 'vendor'
  | 'state_tax_applied';

const staticTransactions = Array.from({ length: 5 }, (_, i) => Transaction.create({ id: i }));
const staticArray: Transaction[] = [];

export const TRANSACTION_STATUSES_MAP = {
  accept: 3,
  reject: 4,
} as const;

type IsEditingStruct = {
  [key: number]: boolean;
};

const formatTransactionForExport = (transaction: Transaction) => ({
  Date: new Date(transaction.timestamp.split(' ').join('T')).toLocaleDateString(),
  Purchaser: transaction.ownerName,
  Account: transaction.costCenter
    ? `${transaction.costCenter.shortDescription} (${transaction.costCenter.id})`
    : '',
  Department: transaction.department ? transaction.department.description : '',
  'Job #': transaction.jobId ? `#${transaction.jobId}` : '',
  ID: transaction.artificialId,
  Amount: prettyMoney(transaction.amount),
  Vendor: transaction.vendor || '',
  'State Tax':
    transaction.stateTaxApplied !== undefined
      ? transaction.stateTaxApplied
        ? 'Applied'
        : 'Not Applied'
      : '',
});

export const TransactionAdminView = ({
  userID,
  isSU,
  departmentID,
  departmentIDList,
  showMultipleDepartments,
}: props) => {
  const userQuery = useUserQuery({
    filters: {
      id: userID,
    },
  });

  const { isUserAccountingAdmin, acceptOverride, showWorkflowFilters } = useMemo(() => {
    const result = {
      isUserAccountingAdmin: false,
      acceptOverride: false,
      showWorkflowFilters: false,
    };
    if (!userQuery.isSuccess) {
      return result;
    }
    result.isUserAccountingAdmin = !!userQuery.data.permissionGroups.find(
      (item) => item.name === 'AccountingAdmin',
    );

    result.acceptOverride = !userQuery.data.permissionGroups.find(
      (item) => item.name === 'AccountingAcceptOverride',
    );

    result.showWorkflowFilters = !!userQuery.data.permissionGroups.find(
      (item) => item.name === 'AccountingAcceptOverride',
    );

    return result;
  }, [userQuery.data, userQuery.isSuccess]);

  const searchForm = useTransactionAdminSearchForm({
    acceptOverride,
  });

  const [state, setState] = useState<IState>(() => ({
    page: 0,
    isLoading: false,
    showWorkflowFilters: false,
    filters: searchForm.getValues(),
    editingCostCenter: {} as IsEditingStruct,
    editingDepartment: {} as IsEditingStruct,
    editingStateTax: {} as IsEditingStruct,
    updatingStateTax: {} as IsEditingStruct,
    showCreateModal: false,
    showCreditCardModal: false,
  }));
  const queryClient = useQueryClient();

  useEffect(() => {
    const debouncedUpdate = debounce((data: TransactionAdminSearchSchemeType) => {
      setState((prev) => {
        const changedFields = Object.keys(data).filter(
          (key) =>
            data[key as keyof TransactionAdminSearchSchemeType] !==
            prev.filters[key as keyof TransactionAdminSearchSchemeType],
        );
        if (
          changedFields.length === 0 ||
          (changedFields.length === 1 && changedFields[0] === 'removeInActiveFromDropDown')
        ) {
          return prev;
        }

        return {
          ...prev,
          filters: {
            ...prev.filters,
            ...data,
          },
          page: 0,
        };
      });
    }, FORM_AUTOMATIC_SEARCH_DEBOUNCE_TIME);

    const subscription = searchForm.watch(debouncedUpdate);

    return () => subscription.unsubscribe();
  }, [searchForm]);

  const handleSetState = useCallback(
    (arg: Partial<IState> | ((currentState: IState) => Partial<IState>)) => {
      setState((currentState) => {
        return produce(currentState, (draftState) => {
          Object.assign(draftState, typeof arg === 'function' ? arg(currentState) : arg);
        });
      });
    },
    [],
  );
  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: [queryKeys.transaction.root, queryKeys.transaction.list],
    });
  }, [state.filters, queryClient]);
  const makeLog = useCallback(
    async (description: string, id: number) => {
      const activity = TransactionActivity.create();
      activity.isActive = 1;
      activity.timestamp = timestamp();
      activity.userId = userID;
      activity.description = description;
      activity.transactionId = id;
      await TransactionActivityClientService.Create(activity);
    },
    [userID],
  );

  const makeUpdateStatus = useCallback(
    (id: number, statusID: number, description: string) => {
      return async (reason?: string) => {
        const txn = Transaction.create();
        txn.id = id;
        txn.statusId = statusID;
        txn.fieldMask = ['StatusId'];
        await TransactionClientService.Update(txn);

        await makeLog(`${description} ${reason || ''}`, id);
      };
    },
    [makeLog],
  );

  const [tableSortingState, setTableSortingState] = useState<SortingState>([]);
  const sort = useMemo<ISort>(() => {
    return {
      sortBy: (tableSortingState.at(0)?.id as sortString) || 'timestamp',
      sortDir: tableSortingState.at(0)?.desc ? 'DESC' : 'ASC',
    };
  }, [tableSortingState]);

  const [transactionsCount, setTransactionsCount] = useState<number>(0);
  const { refetch: refetchTxns, ...TxnsQuery } = useTransactionAdminQuery({
    isSU,
    page: state.page,

    filter: useMemo(
      () => ({ ...state.filters, sort, includeLineItems: true }),
      [sort, state.filters],
    ),
    departmentIDList,
  });

  useEffect(() => {
    if (TxnsQuery.isSuccess) {
      setTransactionsCount(TxnsQuery.data.totalCount || 0);
    }
  }, [TxnsQuery.data?.totalCount, TxnsQuery.isSuccess]);

  // search function

  const makeRecordTransaction = useCallback(
    (id: number) => {
      return async () => {
        await TransactionClientService.Update(
          Transaction.create({
            id: id,
            isRecorded: true,
            fieldMask: ['IsRecorded'],
          }),
        );
        await makeLog('Transaction recorded', id);
        await refetchTxns();
      };
    },
    [makeLog, refetchTxns],
  );

  // Toggle loading state
  const toggleLoading = useCallback(
    (cb?: VoidFunction) => {
      handleSetState((prev) => ({
        isLoading: !prev.isLoading,
      }));
      cb && cb();
    },
    [handleSetState],
  );

  const setLoading = useCallback(
    (value: boolean) => {
      handleSetState({
        isLoading: value,
      });
    },
    [handleSetState],
  );
  const [, copy] = useCopyToClipboard();
  const [successCopy, setSuccessCopy] = useState(false);

  // Copy page as CSV
  const copyPage = useCallback(async () => {
    if (!TxnsQuery.data) {
      alert('Transaction data is not yet loaded');
      return;
    }

    const dataStr = TxnsQuery.data.results.reduce((acc: string, curr: Transaction) => {
      return `${acc}\n${new Date(curr.timestamp.split(' ').join('T')).toLocaleDateString()},${
        curr.ownerName
      },${curr.costCenter ? `${curr.costCenter!.shortDescription} (${curr.costCenter!.id}),` : ''}${
        curr.department ? `${curr.department?.description},` : ''
      }${curr.jobId ? '#' + curr.jobId + ',' : ''} ${curr.artificialId},${prettyMoney(
        curr.amount,
      )},${curr.vendor ? curr.vendor + ',' : ''}${
        curr.stateTaxApplied != undefined
          ? `${curr.stateTaxApplied ? 'Applied' : 'Not Applied'}`
          : ''
      }, `;
    }, '');

    try {
      const isSuccess = await copy(dataStr);
      if (isSuccess) {
        setSuccessCopy(true);
        setTimeout(() => {
          setSuccessCopy(false);
        }, 3000);
      }
    } catch (err) {
      console.log(err);
      console.log('failed to copy to clipboard');
    }
  }, [TxnsQuery.data, copy]);

  // Record current page
  const handleRecordPage = useCallback(async () => {
    const ok = confirm('Are you sure want to mark the current page as recorded?');
    if (ok) {
      if (!TxnsQuery.data) {
        alert('Transaction data is not yet loaded');
        return;
      }
      const ids = TxnsQuery.data.results.map((t) => t.id);
      const req = RecordPageReq.create({
        transactionIds: ids,
        adminId: userID,
        requestData: applyFilters({
          obj: Transaction.create({ pageNumber: state.page, isActive: 1 }),
          filters: { ...state.filters, sort },
          isSU,
          departmentIDList,
          includeLineItems: true,
        }),
      });
      toggleLoading(async () => {
        await TransactionClientService.RecordPage(req);
        refetchTxns();
        handleSetState({
          isLoading: false,
        });
      });
    }
  }, [
    TxnsQuery.data,
    userID,
    state.page,
    state.filters,
    sort,
    isSU,
    departmentIDList,
    toggleLoading,
    refetchTxns,
    handleSetState,
  ]);

  const handleAuditPage = useCallback(async () => {
    const ok = confirm('Are you sure want to mark the current page as audited?');
    if (ok) {
      if (!TxnsQuery.data) {
        alert('Transaction data is not yet loaded');
        return;
      }
      const ids = TxnsQuery.data.results.map((t) => t.id);
      const req = AuditPageReq.create({
        transactionIds: ids,
        adminId: userID,
        requestData: applyFilters({
          obj: Transaction.create({ pageNumber: state.page, isActive: 1 }),
          filters: { ...state.filters, sort },
          isSU,
          departmentIDList,
          includeLineItems: true,
        }),
      });
      toggleLoading(async () => {
        await TransactionClientService.AuditPage(req);
        refetchTxns();
        handleSetState({
          isLoading: false,
        });
      });
    }
  }, [
    TxnsQuery.data,
    userID,
    state.page,
    state.filters,
    sort,
    isSU,
    departmentIDList,
    toggleLoading,
    refetchTxns,
    handleSetState,
  ]);

  // Submit current page
  const handleSubmitPage = useCallback(async () => {
    if (!TxnsQuery.data) {
      alert('Transaction data is not yet loaded');
      return;
    }
    const ok = confirm(
      `Are you sure you want to mark the current page as ${
        acceptOverride ? 'accepted' : 'recorded'
      }?`,
    );
    if (ok) {
      const fns = TxnsQuery.data.results
        .map((t) => {
          if (acceptOverride) {
            return makeUpdateStatus(t.id, 3, 'accepted');
          } else {
            return makeRecordTransaction(t.id);
          }
        })
        .map((f) => f());
      toggleLoading(async () => {
        try {
          await Promise.all(fns);
          await refetchTxns();
        } catch (err) {
          console.log(err);
          alert('An error has occurred');
        }
        handleSetState({ isLoading: false });
      });
    }
  }, [
    TxnsQuery.data,
    acceptOverride,
    toggleLoading,
    makeUpdateStatus,
    makeRecordTransaction,
    refetchTxns,
    handleSetState,
  ]);

  const handleChangePage = useCallback(
    (updater: number | ((prev: number) => number)) => {
      handleSetState((prev) => {
        const pageValue = typeof updater === 'function' ? updater(prev.page) : updater;
        return { ...prev, page: pageValue };
      });
    },
    [handleSetState],
  );

  const transactionAdminTableColumns = useMemo<ColumnDef<Transaction>[]>(() => {
    return [
      {
        accessorKey: 'timestamp',
        id: 'timestamp', // for BE sorting
        header: ({ column }) => (
          <DataTableColumnHeader hideVisibilityToggle title="Date" column={column} />
        ),
        meta: {
          className: 'w-16',
        },
        cell({ row, column }) {
          return (
            <div className={column.columnDef.meta?.className}>
              {parseISO(row.original.timestamp.split(' ').join('T')).toLocaleDateString()}
            </div>
          );
        },
      },
      {
        accessorKey: 'ownerName',
        id: 'owner_name', // for BE sorting
        header: ({ column }) => (
          <DataTableColumnHeader hideVisibilityToggle title="Purchaser" column={column} />
        ),
        meta: {
          className: 'w-20',
        },
        cell({ row, column }) {
          return <div className={column.columnDef.meta?.className}>{row.original.ownerName}</div>;
        },
      },
      {
        accessorKey: 'costCenter',
        id: 'cost_center_id', // for BE sorting
        header: ({ column }) => (
          <DataTableColumnHeader hideVisibilityToggle title="Account" column={column} />
        ),
        meta: {
          className: 'flex w-40 items-center gap-2',
        },
        cell({ row, column }) {
          return (
            <TooltipProvider delayDuration={150}>
              <EditableAccount
                transaction={row.original}
                className={column.columnDef.meta?.className}
              />
            </TooltipProvider>
          );
        },
      },
      {
        accessorKey: 'department',
        id: 'department_id', // for BE sorting
        header: ({ column }) => (
          <DataTableColumnHeader
            hideVisibilityToggle
            className="justify-center"
            title="Department"
            column={column}
          />
        ),
        meta: {
          className: 'flex w-40 items-center justify-end gap-2 text-right',
        },
        cell({ row, column }) {
          return (
            <TooltipProvider delayDuration={150}>
              <EditableDepartment
                transaction={row.original}
                disabled={!!row.original.jobId && !isUserAccountingAdmin}
                className={column.columnDef.meta?.className}
              />
            </TooltipProvider>
          );
        },
      },
      {
        id: 'job_id', // for BE sorting
        accessorKey: 'jobId',
        header: ({ column }) => (
          <DataTableColumnHeader
            hideVisibilityToggle
            column={column}
            className="mx-auto"
            title="Job #"
          />
        ),
        meta: {
          className: 'flex w-52 items-center gap-1',
        },
        cell: ({ row, column }) => (
          <TooltipProvider delayDuration={150}>
            <EditableJobId
              className={column.columnDef.meta?.className}
              transaction={row.original}
            />
          </TooltipProvider>
        ),
      },
      {
        accessorKey: 'artificialId',
        id: 'artificial_id', // for BE sorting
        header: ({ column }) => (
          <DataTableColumnHeader hideVisibilityToggle title="ID" column={column} />
        ),
        meta: {
          className: 'w-28 overflow-x-auto',
        },
        cell({ row, column }) {
          return (
            <div className={column.columnDef.meta?.className}>{row.original.artificialId}</div>
          );
        },
      },
      {
        accessorKey: 'amount',
        id: 'amount', // for BE sorting
        header: ({ column }) => (
          <DataTableColumnHeader hideVisibilityToggle title="Amount" column={column} />
        ),
        meta: {
          className: 'w-16',
        },
        cell({ row, column }) {
          return (
            <div className={column.columnDef.meta?.className}>
              ${prettyMoney(row.original.amount)}
            </div>
          );
        },
      },
      {
        accessorKey: 'vendor',
        id: 'vendor', // for BE sorting
        header: ({ column }) => (
          <DataTableColumnHeader hideVisibilityToggle title="Vendor" column={column} />
        ),
        meta: {
          className: 'w-20 overflow-x-auto',
        },
        cell({ row, column }) {
          return <div className={column.columnDef.meta?.className}>{row.original.vendor}</div>;
        },
      },
      {
        accessorKey: 'stateTaxApplied',
        id: 'state_tax_applied', // for BE sorting
        header: ({ column }) => (
          <DataTableColumnHeader hideVisibilityToggle title="State Tax?" column={column} />
        ),
        meta: {
          className: 'flex w-24 items-center justify-center gap-1 text-center',
        },
        cell({ row, column }) {
          return (
            <TooltipProvider skipDelayDuration={2} delayDuration={150}>
              <EditableStateTax
                transaction={row.original}
                className={column.columnDef.meta?.className}
              />
            </TooltipProvider>
          );
        },
      },
      {
        id: 'actions',
        enableSorting: false,
        header({ column }) {
          return (
            <DataTableColumnHeader
              hideVisibilityToggle
              className="flex items-center justify-center"
              title="Actions"
              column={column}
            />
          );
        },
        meta: {
          className: 'grid grid-cols-4 w-40 grid flex-wrap items-center justify-center gap-1.5',
        },
        cell({ row, column }) {
          return (
            <div className={column.columnDef.meta?.className}>
              <TooltipProvider delayDuration={150}>
                <TransactionRowAdminActions
                  statusFilter={Number(state.filters.statusID)}
                  transaction={row.original}
                  setLoading={setLoading}
                />
              </TooltipProvider>
            </div>
          );
        },
      },
    ];
  }, [isUserAccountingAdmin, state.filters.statusID, setLoading]);

  const skeletonRows = useMemo(
    () =>
      transactionAdminTableColumns.map((column) => ({
        ...column,
        cell: () => <Skeleton className={cn(column.meta?.className, 'bg-foreground/30 h-4')} />,
      })),
    [transactionAdminTableColumns],
  );

  const onTableSortChange: OnChangeFn<SortingState> = useCallback((updater) => {
    setTableSortingState((prev) => {
      const newState = typeof updater === 'function' ? updater(prev) : updater;
      return newState;
    });
  }, []);

  const isTableDataLoading = TxnsQuery.isPending || userQuery.isPending;
  const transactionTable = useReactTable({
    columns: isTableDataLoading ? skeletonRows : transactionAdminTableColumns,
    data: isTableDataLoading ? staticTransactions : TxnsQuery.data?.results ?? staticArray,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (original) => original.id.toString(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      pagination: {
        pageIndex: 0,
        pageSize: 50,
      },
      sorting: tableSortingState,
    },
    onSortingChange: onTableSortChange,
  });

  const [exportStatus, setExportStatus] = useState<'idle' | 'loading' | 'loaded'>('idle');

  const exportAllTransactionsQuery = useTransactionAdminQuery({
    isSU,
    page: 0,
    filter: useMemo(
      () => ({ ...state.filters, sort, withoutLimit: true, includeLineItems: false }),
      [sort, state.filters],
    ),
    departmentIDList,
    enabled: false,
  });

  const handleExport = useCallback(async () => {
    setExportStatus('loading');
    try {
      await exportAllTransactionsQuery.refetch();
      setExportStatus('loaded');
    } catch (error) {
      console.error('Failed to fetch all transactions:', error);
      alert('An error occurred while preparing the export. Please try again.');
      setExportStatus('idle');
    }
  }, [exportAllTransactionsQuery]);

  const handleExported = useCallback(() => setExportStatus('idle'), []);

  return !state.isLoading ? (
    <>
      <div className="flex p-4">
        <div className="flex w-full flex-wrap items-center justify-center gap-2 sm:justify-start">
          <h1 className="text-xl font-bold tracking-wide">Transactions</h1>
          <DataTablePagination
            disabled={state.page === 0 && TxnsQuery.isFetching}
            pageCount={Math.ceil(transactionsCount / 50)}
            currentPage={state.page}
            setPage={handleChangePage}
          />
          <p>Total entries: {transactionsCount}</p>

          <div className="flex flex-1 flex-wrap  items-center justify-center gap-2 sm:justify-end">
            {isUserAccountingAdmin && (
              <Button size="sm" asChild>
                <Link target="_blank" to="/creditCards">
                  Check Credit Cards
                </Link>
              </Button>
            )}

            <Button
              size="sm"
              disabled={!TxnsQuery.data?.results.length || successCopy}
              onClick={copyPage}
            >
              {successCopy ? <CheckIcon /> : 'Copy current page as CSV'}
            </Button>

            <Button size="sm" onClick={acceptOverride ? handleSubmitPage : handleRecordPage}>
              {`Mark current page as ${acceptOverride ? 'approved' : 'recorded'}`}
            </Button>

            {!acceptOverride && (
              <Button size="sm" onClick={handleAuditPage}>
                Mark current page audited
              </Button>
            )}

            <ExportJSON
              json={exportAllTransactionsQuery.data?.results.map(formatTransactionForExport) || []}
              filename={`Transactions_Export_${format(new Date(), 'yyyy-MM-dd_HH-mm-ss')}`}
              onExport={handleExport}
              onExported={handleExported}
              status={exportStatus}
            />
          </div>
        </div>
      </div>
      <Form {...searchForm}>
        <TransactionAdminSearchForm
          className="grid grid-cols-1 gap-4 p-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5"
          departmentID={departmentID}
          isSU={isSU}
          hideAuditWorkflowFilters={!showWorkflowFilters}
          showMultipleDepartments={!!showMultipleDepartments}
          departmentIDList={departmentIDList ?? ''}
          isUserAccountingAdmin={isUserAccountingAdmin}
        />
      </Form>

      <Card className="relative h-[65vh] max-w-[100vw] flex-1 overflow-auto rounded-none">
        <DataTable
          RowWrapper={RowTransactionLineItemContextWrapper}
          tableCellClassName="px-1 md:px-1.5"
          table={transactionTable}
        />
      </Card>
    </>
  ) : (
    <Loader />
  );
};

function timestamp() {
  const dateObj = new Date();
  let month = `${dateObj.getMonth() + 1}`;
  if (month.length === 1) {
    month = `0${month}`;
  }
  let day = `${dateObj.getDate()}`;
  if (day.length === 1) {
    day = `0${day}`;
  }
  let hour = `${dateObj.getHours()}`;
  if (hour.length === 1) {
    hour = `0${hour}`;
  }
  let minute = `${dateObj.getMinutes()}`;
  if (minute.length === 1) {
    minute = `0${minute}`;
  }

  return `${dateObj.getFullYear()}-${month}-${day} ${hour}:${minute}:00`;
}

export const applyFilters = ({
  obj,
  filters,
  isSU,
  departmentIDList,
}: {
  obj: Transaction;
  filters: AdminTransactionFilter;
  isSU: boolean;
  departmentIDList?: string;
  includeLineItems?: boolean;
}) => {
  obj.isActive = 1;
  if (departmentIDList && !isSU) {
    obj.departmentIdList = departmentIDList;
  }
  if (filters.userID && filters.userID != 0) {
    //here they want to see someone specific,
    //if transactions don't have departments, this conflicts
    obj.ownerId = filters.userID;
    obj.departmentIdList = '';
  }
  if (filters.costCenterID) {
    obj.costCenterId = filters.costCenterID;
  }
  if (filters.accountValue && Number(filters.accountValue)) {
    obj.artificialId = `%${filters.accountValue}-%`;
  }
  if (filters.departmentID && filters.departmentID !== 0) {
    obj.departmentId = filters.departmentID;
  }
  if (filters.includeLineItems) {
    obj.includeTransactionLineItems = true;
  }
  if (filters)
    if (filters.dateCreated) {
      obj.timestamp = `${filters.yearCreated}-${trailingZero(filters.dateCreated)}%`;
    } else if (filters.yearCreated) {
      obj.timestamp = `${filters.yearCreated}-%`;
    }
  if (filters.withoutLimit) {
    obj.withoutLimit = true;
  }
  if (filters.searchValue) {
    obj.vendor = `%${filters.searchValue}%`;
  }
  if (filters.statusID) {
    switch (Number(filters.statusID)) {
      case 5:
        obj.isRecorded = true;
        break;
      case 6:
        obj.isAudited = true;
        break;
      case 7:
        obj.isAudited = false;
        obj.fieldMask = ['IsAudited'];
        obj.statusId = 3;
        break;
      case 8:
        obj.isRecorded = false;
        obj.fieldMask = ['IsRecorded'];
        obj.statusId = 3;
        break;
      case 9:
        obj.isRecorded = false;
        obj.fieldMask = ['IsRecorded'];
        obj.notEquals = ['IsAudited'];
        obj.isAudited = true;
        obj.statusId = 3;
        break;
      case 10:
        obj.isRecorded = false;
        obj.fieldMask = ['IsRecorded'];
        obj.isAudited = true;
        break;
      case 11:
        obj.isRecorded = true;
        obj.isAudited = true;
        break;
      case 12:
        obj.isActive = 0;
        obj.fieldMask = obj.fieldMask.concat(['IsActive']);
        break;
      default:
        obj.statusId = Number(filters.statusID);
        break;
    }
  }

  obj.orderBy = filters.sort.sortBy;
  obj.orderDir = filters.sort.sortDir;
  return obj;
};

const EditableDepartment = ({
  transaction,
  className,
  disabled,
}: {
  transaction: Transaction;
  className?: string;
  disabled?: boolean;
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const transactionMutation = useTransactionUpdateMutation();
  const eventEditMutation = useEventUpdateMutation();
  const { makeLog } = useMakeTransactionActivityLog();
  const queryClient = useQueryClient();

  const makeUpdateDepartment = useCallback(
    async (newDepartment: TimesheetDepartment) => {
      console.log('higher update', newDepartment);
      try {
        await Promise.all([
          await transactionMutation.mutateAsync(
            Transaction.create({
              id: transaction.id,
              departmentId: newDepartment.id,
              fieldMask: ['DepartmentId'],
            }),
          ),
          await eventEditMutation.mutateAsync(
            Event.create({
              id: transaction.jobId,
              departmentId: newDepartment.id,
              fieldMask: ['DepartmentId'],
            }),
          ),
        ]);
        queryClient.refetchQueries({
          queryKey: eventQuery.getQueryKeys({
            id: transaction.jobId,
          }),
        });
        setIsEditing(false);
      } catch {
        alert('An error has occurred while updating department');
        return;
      }
      try {
        await makeLog(
          `User Updated Department from ${transaction.departmentId} to ${newDepartment.id}`,
          transaction.id,
        );
      } catch {
        console.error('An error has occurred while logging the transaction department update');
      }
    },
    [
      queryClient,
      makeLog,
      transaction.departmentId,
      transaction.id,
      transaction.jobId,
      transactionMutation,
      eventEditMutation,
    ],
  );

  const departmentDisplay =
    transaction.department && !!transaction.department.description
      ? transaction.department.description
      : '-';
  const toggleEdit = useCallback(() => setIsEditing((isEditing) => !isEditing), []);
  const renderDepartment = useCallback((dep: TimesheetDepartment) => dep.description, []);

  if (!transaction.isActive) return departmentDisplay;

  return (
    <div className={className}>
      {isEditing ? (
        <TimesheetDepartmentPickerV2
          renderItem={renderDepartment}
          queryArgs={{ filter: { isActive: 1, teamOnly: false, fieldMask: ['TeamOnly'] } }}
          loading={transactionMutation.isPending}
          onSelect={makeUpdateDepartment}
          selected={transaction.departmentId.toString() || '0'}
          placeholder="select department"
          triggerClassName="h-auto text-xs"
        />
      ) : (
        departmentDisplay
      )}
      <Tooltip>
        <TooltipContent>{isEditing ? 'Stop Editing' : 'Edit Department'}</TooltipContent>
        <TooltipTrigger asChild>
          <Button
            isLoading={transactionMutation.isPending}
            disabled={transactionMutation.isPending || disabled}
            size="icon"
            className="flex-shrink-0"
            onClick={toggleEdit}
            variant="outline"
          >
            {!isEditing ? (
              <>
                <span className="sr-only">Edit Department</span>
                <Pencil1Icon />
              </>
            ) : (
              <>
                <span className="sr-only">Stop editing Department</span>
                <Cross1Icon />
              </>
            )}
          </Button>
        </TooltipTrigger>
      </Tooltip>
    </div>
  );
};

const EditableAccount = ({
  transaction,
  className,
}: {
  transaction: Transaction;
  className?: string;
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const transactionMutation = useTransactionUpdateMutation();
  const { makeLog } = useMakeTransactionActivityLog();

  const makeUpdateCostCenter = useCallback(
    async (newCostCenter: TransactionAccount) => {
      try {
        await transactionMutation.mutateAsync(
          Transaction.create({
            id: transaction.id,
            costCenterId: newCostCenter.id,
            fieldMask: ['CostCenterId'],
          }),
        );
        setIsEditing(false);
      } catch {
        alert('An error has occurred while updating cost center');
        return;
      }
      try {
        await makeLog(
          `User Updated Cost Center from ${transaction.costCenterId} to ${newCostCenter.id}`,
          transaction.id,
        );
      } catch {
        console.error('An error has occurred while logging the transaction cost center update');
      }
    },
    [makeLog, transaction.id, transactionMutation, transaction.costCenterId],
  );

  const accountDisplay =
    transaction.costCenter && !!transaction.costCenterId
      ? `${transaction.costCenter.shortDescription} (${transaction.costCenter.id})`
      : '-';
  const toggleEdit = useCallback(() => setIsEditing((isEditing) => !isEditing), []);
  const renderAccount = useCallback(
    (acc: TransactionAccount) => `${acc.shortDescription} (${acc.id})`,
    [],
  );

  if (!transaction.isActive) return accountDisplay;

  return (
    <div className={className}>
      {isEditing ? (
        <TransactionAccountSelectPickerV2
          renderItem={renderAccount}
          loading={transactionMutation.isPending}
          onSelect={makeUpdateCostCenter}
          selected={transaction.costCenterId.toString() || '0'}
          placeholder="select account"
          triggerClassName="h-auto text-xs"
        />
      ) : (
        accountDisplay
      )}
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            isLoading={transactionMutation.isPending}
            disabled={transactionMutation.isPending}
            onClick={toggleEdit}
            size="icon"
            variant="outline"
            className="ml-auto flex-shrink-0
            "
          >
            {!isEditing ? (
              <>
                <span className="sr-only">Edit Account</span>
                <Pencil1Icon className="size-4" />
              </>
            ) : (
              <>
                <span className="sr-only">Stop editing Account</span>
                <Cross1Icon className="size-4" />
              </>
            )}
          </Button>
        </TooltipTrigger>
        <TooltipContent>{isEditing ? 'Stop Editing' : 'Edit Account'}</TooltipContent>
      </Tooltip>
    </div>
  );
};

const EditableStateTax = ({
  transaction,
  className,
}: {
  transaction: Transaction;
  className?: string;
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const transactionMutation = useTransactionUpdateMutation();
  const { makeLog } = useMakeTransactionActivityLog();

  const makeUpdateStateTax = useCallback(
    async (stateTax: boolean) => {
      try {
        await transactionMutation.mutateAsync(
          Transaction.create({
            id: transaction.id,
            stateTaxApplied: stateTax,
            fieldMask: ['StateTaxApplied'],
          }),
        );
        setIsEditing(false);
      } catch {
        alert('An error has occurred while updating state tax');
        return;
      }
      try {
        await makeLog(
          `User updated State Tax from ${transaction.stateTaxApplied} to ${stateTax}`,
          transaction.id,
        );
      } catch {
        console.error('An error has occurred while logging the transaction state tax update');
      }
    },
    [makeLog, transaction.id, transactionMutation, transaction.stateTaxApplied],
  );

  const stateTaxDisplay = transaction.stateTaxApplied ? 'Applied' : 'Not Applied';
  const toggleEdit = useCallback(() => setIsEditing((isEditing) => !isEditing), []);

  if (!transaction.isActive) return stateTaxDisplay;

  return (
    <div className={className}>
      {isEditing ? (
        <Checkbox
          name="stateTax"
          checked={transaction.stateTaxApplied}
          disabled={transactionMutation.isPending}
          onCheckedChange={(val) => makeUpdateStateTax(!!val)}
        />
      ) : (
        stateTaxDisplay
      )}

      <Tooltip>
        <TooltipContent>{isEditing ? 'Stop Editing' : 'Edit State Tax'}</TooltipContent>
        <TooltipTrigger asChild>
          <Button
            isLoading={transactionMutation.isPending}
            disabled={transactionMutation.isPending}
            className="ml-auto flex-shrink-0"
            size="icon"
            onClick={toggleEdit}
            variant="outline"
          >
            {!isEditing ? (
              <>
                <span className="sr-only">Edit State Tax</span>
                <Pencil1Icon className="size-4" />
              </>
            ) : (
              <>
                <span className="sr-only">Stop editing State Tax</span>
                <Cross1Icon className="size-4" />
              </>
            )}
          </Button>
        </TooltipTrigger>
      </Tooltip>
    </div>
  );
};

const EditableJobId = ({
  transaction,
  className,
  onChange,
}: {
  transaction: Transaction;
  className?: string;
  onChange?: () => void;
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const transactionMutation = useTransactionUpdateMutation();
  const { makeLog } = useMakeTransactionActivityLog();

  const eventQuery = useEventQueryInline();
  const [checkStatus, setCheckStatus] = useState<JobCheckStatus | null>(null);

  const toggleEdit = useCallback(() => setIsEditing((isEditing) => !isEditing), []);

  const updateJobNumber = useCallback(
    async (newJobId: number) => {
      console.log({ newJobId, curr: transaction.jobId });
      if (newJobId === transaction.jobId) return;
      try {
        const job = await eventQuery.fetchEvent({
          filter: {
            id: newJobId,
          },
        });

        await transactionMutation.mutateAsync(
          Transaction.create({
            id: transaction.id,
            jobId: newJobId,
            departmentId: job?.departmentId,
            fieldMask: ['JobId', 'DepartmentId'],
          }),
        );
        onChange?.();
        setIsEditing(false);
      } catch (err) {
        alert('Failed to update Job number');
        console.log(err);
      }
      try {
        await makeLog(
          `User updated JobId from ${transaction.jobId} to ${newJobId}`,
          transaction.id,
        );
      } catch {
        console.error('Failed to create jobId update log');
      }
    },
    [makeLog, transaction.id, transaction.jobId, transactionMutation, eventQuery, onChange],
  );

  return (
    <div className={className}>
      {!isEditing && transaction.jobId !== 0 && <JobPreview jobId={transaction.jobId} />}

      {!isEditing && transaction.jobId === 0 && <span>0</span>}

      {isEditing && (
        <JobSelector
          value={transaction.jobId}
          disabled={transactionMutation.isPending}
          onChange={updateJobNumber}
          onStatusChange={setCheckStatus}
          omitArchivedJobs={true}
        />
      )}

      <Tooltip>
        <TooltipContent>{isEditing ? 'Stop Editing' : 'Edit Job number'}</TooltipContent>
        <TooltipTrigger asChild>
          <Button
            isLoading={transactionMutation.isPending}
            disabled={transactionMutation.isPending || checkStatus === 'loading'}
            className="ml-auto flex-shrink-0"
            size="icon"
            onClick={toggleEdit}
            variant="outline"
          >
            {!isEditing ? (
              <>
                <span className="sr-only">Edit State Tax</span>
                <Pencil1Icon className="size-4" />
              </>
            ) : (
              <>
                <span className="sr-only">Stop editing State Tax</span>
                <Cross1Icon className="size-4" />
              </>
            )}
          </Button>
        </TooltipTrigger>
      </Tooltip>
    </div>
  );
};

const RenderTransactionRow = withTransactionLineItemsStoreContext(
  ({ children }: { children: React.ReactNode; transaction: Transaction }) => {
    return children;
  },
);

export const RowTransactionLineItemContextWrapper = ({
  row,
  children,
}: {
  row: Row<Transaction>;
  children: React.ReactNode;
}) => {
  return <RenderTransactionRow transaction={row.original}>{children}</RenderTransactionRow>;
};

export function prettyMoney(amount: number): string {
  const [dollars, cents] = amount.toString().split('.');
  if (!cents) {
    return `${dollars}.00`;
  } else if (cents.length === 1) {
    return `${dollars}.${cents}0`;
  } else {
    return `${dollars}.${cents}`;
  }
}
