import { zodResolver } from '@hookform/resolvers/zod';
import { type SpiffToolAdminAction } from '@kalos/kalos-rpc';
import {
  Button,
  DateInput,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  SimpleSelect,
} from '@kalos/ui';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { type SaveHandler } from '../../../../tools/helpers';
import { type ZodObjectWithModelKeys } from '../../../../tools/typeguargs';
import { SPIFF_TOOL_STATUSES } from './constants';

type schemaFields = Pick<SpiffToolAdminAction, 'decisionDate' | 'reviewedBy' | 'status' | 'reason'>;
const spiffToolAdminActionSchema = z.object({
  decisionDate: z.date(),
  reviewedBy: z.string(),
  status: z.coerce.number(),
  reason: z.string(),
}) satisfies ZodObjectWithModelKeys<schemaFields>;
export type SpiffToolAdminActionFormSchema = z.infer<typeof spiffToolAdminActionSchema>;

type SpiffToolAdminActionFormProps = {
  defaultValues: SpiffToolAdminActionFormSchema;
  onSubmit: SaveHandler<SpiffToolAdminActionFormSchema>;
  loading?: boolean;
  disabled?: boolean;
};

export const SpiffToolAdminActionForm = ({
  defaultValues,
  onSubmit,
  disabled,
  loading,
}: SpiffToolAdminActionFormProps) => {
  const form = useForm<SpiffToolAdminActionFormSchema>({
    resolver: zodResolver(spiffToolAdminActionSchema),
    defaultValues,
    disabled,
  });

  const formSubmit = form.handleSubmit((data) =>
    onSubmit({ data, dirtyFields: form.formState.dirtyFields }),
  );

  return (
    <div className="@container">
      <form className="grid grid-cols-1 gap-2" onSubmit={formSubmit}>
        <Form {...form}>
          <FormField
            control={form.control}
            name="decisionDate"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Decision date</FormLabel>
                <FormControl>
                  <DateInput {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="reviewedBy"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Reviewed by</FormLabel>
                <FormControl>
                  <Input {...field} disabled />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="status"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Status</FormLabel>
                <FormControl>
                  <SimpleSelect
                    values={SPIFF_TOOL_STATUSES}
                    selectedValue={field.value.toString()}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="reason"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Reason</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </Form>

        <Button type="submit" disabled={disabled || loading} isLoading={loading}>
          Save
        </Button>
      </form>
    </div>
  );
};
