import { BaseClient } from '../BaseClient';
import { CreditCard, CreditCardList } from '../compiled-protos/credit_card';
import { CreditCardServiceClient } from '../compiled-protos/credit_card.client';
class CreditCardClient extends BaseClient {
  self: CreditCardServiceClient;

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new CreditCardServiceClient(this.transport);
  }

  public async Create(req: CreditCard) {
    let res = CreditCard.create();
    try {
      res = await this.self.create(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Get(req: CreditCard) {
    let res = CreditCard.create();
    try {
      res = await this.self.get(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Update(req: CreditCard) {
    let res = CreditCard.create();
    try {
      res = await this.self.update(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Delete(req: CreditCard) {
    let res = CreditCard.create();
    try {
      res = await this.self.delete(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async BatchGet(req: CreditCard) {
    let res = CreditCardList.create();
    try {
      res = await this.self.batchGet(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }
}

export { CreditCard, CreditCardList, CreditCardClient };
