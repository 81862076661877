// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "dispatch.proto" (syntax proto3)
// tslint:disable
import { DispatchService } from "./dispatch";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service DispatchService
 */
export class DispatchServiceClient {
    constructor(_transport) {
        this._transport = _transport;
        this.typeName = DispatchService.typeName;
        this.methods = DispatchService.methods;
        this.options = DispatchService.options;
    }
    /**
     * @generated from protobuf rpc: GetDispatchableTechnicians(DispatchableTech) returns (DispatchableTechList);
     */
    getDispatchableTechnicians(input, options) {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetDispatchCalls(DispatchCall) returns (DispatchCallList);
     */
    getDispatchCalls(input, options) {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetTimeoffData(TimeoffRequest) returns (TimeoffRequestList);
     */
    getTimeoffData(input, options) {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetDispatchCallBacks(DateRange) returns (DispatchCallBacksList);
     */
    getDispatchCallBacks(input, options) {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetDispatchCallTimes(DateRange) returns (DispatchCallTimeList);
     */
    getDispatchCallTimes(input, options) {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetDispatchCallCount(DateRange) returns (DispatchCallCountList);
     */
    getDispatchCallCount(input, options) {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetDispatchFirstCall(Empty) returns (DispatchFirstCallList);
     */
    getDispatchFirstCall(input, options) {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DispatchMessage(Int32) returns (Bool);
     */
    dispatchMessage(input, options) {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DismissalMessage(DismissalMsg) returns (Empty);
     */
    dismissalMessage(input, options) {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateFirstCall(FirstCall) returns (FirstCall);
     */
    createFirstCall(input, options) {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetFirstCall(FirstCall) returns (FirstCall);
     */
    getFirstCall(input, options) {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetFirstCall(FirstCall) returns (FirstCallList);
     */
    batchGetFirstCall(input, options) {
        const method = this.methods[11], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateFirstCall(FirstCall) returns (FirstCall);
     */
    updateFirstCall(input, options) {
        const method = this.methods[12], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteFirstCall(FirstCall) returns (FirstCall);
     */
    deleteFirstCall(input, options) {
        const method = this.methods[13], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: FirstCallMessage(FirstCall) returns (Bool);
     */
    firstCallMessage(input, options) {
        const method = this.methods[14], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
}
