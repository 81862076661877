// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "property.proto" (syntax proto3)
// tslint:disable
import { PropertyService } from "./property";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service PropertyService
 */
export class PropertyServiceClient {
    constructor(_transport) {
        this._transport = _transport;
        this.typeName = PropertyService.typeName;
        this.methods = PropertyService.methods;
        this.options = PropertyService.options;
    }
    /**
     * @generated from protobuf rpc: Create(Property) returns (Property);
     */
    create(input, options) {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Get(Property) returns (Property);
     */
    get(input, options) {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGet(Property) returns (PropertyList);
     */
    batchGet(input, options) {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Update(Property) returns (Property);
     */
    update(input, options) {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Delete(Property) returns (Property);
     */
    delete(input, options) {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetPropertyCoordinates(Property) returns (PropertyCoordinates);
     */
    getPropertyCoordinates(input, options) {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetPropertiesbyContractID(Int32) returns (PropertyList);
     */
    batchGetPropertiesbyContractID(input, options) {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: FetchSubAccountProperties(SubAccountPropertiesRequest) returns (PropertyList);
     */
    fetchSubAccountProperties(input, options) {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: FixPropertyCoordinates(Empty) returns (Empty);
     */
    fixPropertyCoordinates(input, options) {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
}
