import './Confirm.module.less';

import Typography from '@mui/material/Typography';
import { type FC, type PropsWithChildren } from 'react';

import { Modal } from '../Modal';
import { SectionBar } from '../SectionBar';
export interface Props {
  title?: string;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  submitLabel?: string;
  submitDisabled?: boolean;
  cancelLabel?: string;
  maxWidth?: number | 'none';
  disabled?: boolean;
  className?: string;
  onlyCloseOnButton?: boolean;
}

export const Confirm: FC<Props & PropsWithChildren> = ({
  open,
  title,
  onClose,
  onConfirm,
  submitLabel = 'Confirm',
  submitDisabled = false,
  cancelLabel = 'Cancel',
  maxWidth = 370,
  children,
  disabled = false,
  className,
  onlyCloseOnButton = false,
}) => (
  <Modal open={open} onClose={onlyCloseOnButton ? () => {} : onClose} compact maxWidth={maxWidth}>
    <SectionBar
      title={title}
      className={className}
      actions={[
        {
          label: submitLabel,
          onClick: onConfirm,
          disabled: disabled || submitDisabled,
        },
        { label: cancelLabel, onClick: onClose, variant: 'outlined', disabled },
      ]}
      fixedActions
    />
    <Typography component="div" className="Confirm">
      {children}
    </Typography>
  </Modal>
);
