import { type Task } from '@kalos/kalos-rpc';

export type State = {
  page: number;
  count: number;
  entries: Task[];
  deleting: Task | undefined;
  editing: Task | undefined;
  status: number | undefined;
};

export enum ACTIONS {
  SET_PAGE = 'setPage',
  SET_COUNT = 'setCount',
  SET_ENTRIES = 'setEntries',
  SET_DELETING = 'setDeleting',
  SET_EDITING = 'setEditing',
  SET_STATUS = 'setStatus',
}

export type Action =
  | { type: ACTIONS.SET_PAGE; data: number }
  | { type: ACTIONS.SET_COUNT; data: number }
  | { type: ACTIONS.SET_ENTRIES; data: Task[] }
  | { type: ACTIONS.SET_DELETING; data: Task | undefined }
  | { type: ACTIONS.SET_EDITING; data: Task | undefined }
  | { type: ACTIONS.SET_STATUS; data: number | undefined };

export const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case ACTIONS.SET_PAGE: {
      return {
        ...state,
        page: action.data,
      };
    }
    case ACTIONS.SET_COUNT: {
      return {
        ...state,
        count: action.data,
      };
    }
    case ACTIONS.SET_ENTRIES: {
      return {
        ...state,
        entries: action.data,
      };
    }
    case ACTIONS.SET_EDITING: {
      return {
        ...state,
        editing: action.data,
      };
    }
    case ACTIONS.SET_DELETING: {
      return {
        ...state,
        deleting: action.data,
      };
    }
    case ACTIONS.SET_STATUS: {
      return {
        ...state,
        status: action.data,
      };
    }
    default:
      return state;
  }
};
