// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "employee_profile.proto" (syntax proto3)
// tslint:disable
import { EmployeeProfileService } from "./employee_profile";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service EmployeeProfileService
 */
export class EmployeeProfileServiceClient {
    constructor(_transport) {
        this._transport = _transport;
        this.typeName = EmployeeProfileService.typeName;
        this.methods = EmployeeProfileService.methods;
        this.options = EmployeeProfileService.options;
    }
    /**
     * @generated from protobuf rpc: GetEmployeeProfile(IDInt64) returns (EmployeeProfile);
     */
    getEmployeeProfile(input, options) {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
}
