// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "api_key.proto" (syntax proto3)
// tslint:disable
import { ApiKeyService } from "./api_key";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service ApiKeyService
 */
export class ApiKeyServiceClient {
    constructor(_transport) {
        this._transport = _transport;
        this.typeName = ApiKeyService.typeName;
        this.methods = ApiKeyService.methods;
        this.options = ApiKeyService.options;
    }
    /**
     * @generated from protobuf rpc: Create(ApiKey) returns (ApiKey);
     */
    create(input, options) {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Get(ApiKey) returns (ApiKey);
     */
    get(input, options) {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGet(ApiKey) returns (ApiKeyList);
     */
    batchGet(input, options) {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Update(ApiKey) returns (ApiKey);
     */
    update(input, options) {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Delete(ApiKey) returns (ApiKey);
     */
    delete(input, options) {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
}
