// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "lodging.proto" (syntax proto3)
// tslint:disable
// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "lodging.proto" (syntax proto3)
// tslint:disable
import { IDInt32 } from "./common";
import { Int32List } from "./common";
import { Empty } from "./common";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { WireType } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf enum PerDiemType
 */
export var PerDiemType;
(function (PerDiemType) {
    /**
     * @generated from protobuf enum value: Standard = 0;
     */
    PerDiemType[PerDiemType["Standard"] = 0] = "Standard";
    /**
     * @generated from protobuf enum value: WeekdaysOnly = 1;
     */
    PerDiemType[PerDiemType["WeekdaysOnly"] = 1] = "WeekdaysOnly";
    /**
     * @generated from protobuf enum value: None = 2;
     */
    PerDiemType[PerDiemType["None"] = 2] = "None";
})(PerDiemType || (PerDiemType = {}));
// @generated message type with reflection information, may provide speed optimized methods
class LodgingRequest$Type extends MessageType {
    constructor() {
        super("LodgingRequest", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "requestor_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "job_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "check_in_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "check_out_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "admin_notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "status_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "is_active", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "date_range", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "date_target", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "assignments", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => LodgingRequestAssignment },
            { no: 14, name: "documents", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => LodgingRequestDocument },
            { no: 15, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "only_request", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 18, name: "requestor_firstname", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 19, name: "requestor_lastname", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 20, name: "status", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 21, name: "department_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 22, name: "department_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 23, name: "job_city", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 24, name: "job_state", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 25, name: "job_zip", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 26, name: "job_address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 27, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 28, name: "without_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 29, name: "lodging_address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 30, name: "searchAssigned", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 31, name: "internal_notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 32, name: "per_diem_type", kind: "enum", T: () => ["PerDiemType", PerDiemType] }
        ]);
    }
    create(value) {
        const message = { id: 0, requestorId: 0, jobId: 0, checkInDate: "", checkOutDate: "", adminNotes: "", notes: "", statusId: 0, isActive: 0, dateRange: [], dateTarget: [], fieldMask: [], assignments: [], documents: [], orderBy: "", orderDir: "", onlyRequest: false, requestorFirstname: "", requestorLastname: "", status: "", departmentId: 0, departmentName: "", jobCity: "", jobState: "", jobZip: "", jobAddress: "", pageNumber: 0, withoutLimit: false, lodgingAddress: "", searchAssigned: 0, internalNotes: "", perDiemType: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 requestor_id */ 2:
                    message.requestorId = reader.int32();
                    break;
                case /* int32 job_id */ 3:
                    message.jobId = reader.int32();
                    break;
                case /* string check_in_date */ 4:
                    message.checkInDate = reader.string();
                    break;
                case /* string check_out_date */ 5:
                    message.checkOutDate = reader.string();
                    break;
                case /* string admin_notes */ 6:
                    message.adminNotes = reader.string();
                    break;
                case /* string notes */ 7:
                    message.notes = reader.string();
                    break;
                case /* int32 status_id */ 8:
                    message.statusId = reader.int32();
                    break;
                case /* int32 is_active */ 9:
                    message.isActive = reader.int32();
                    break;
                case /* repeated string date_range */ 10:
                    message.dateRange.push(reader.string());
                    break;
                case /* repeated string date_target */ 11:
                    message.dateTarget.push(reader.string());
                    break;
                case /* repeated string field_mask */ 12:
                    message.fieldMask.push(reader.string());
                    break;
                case /* repeated LodgingRequestAssignment assignments */ 13:
                    message.assignments.push(LodgingRequestAssignment.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated LodgingRequestDocument documents */ 14:
                    message.documents.push(LodgingRequestDocument.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string order_by */ 15:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 16:
                    message.orderDir = reader.string();
                    break;
                case /* bool only_request */ 17:
                    message.onlyRequest = reader.bool();
                    break;
                case /* string requestor_firstname */ 18:
                    message.requestorFirstname = reader.string();
                    break;
                case /* string requestor_lastname */ 19:
                    message.requestorLastname = reader.string();
                    break;
                case /* string status */ 20:
                    message.status = reader.string();
                    break;
                case /* int32 department_id */ 21:
                    message.departmentId = reader.int32();
                    break;
                case /* string department_name */ 22:
                    message.departmentName = reader.string();
                    break;
                case /* string job_city */ 23:
                    message.jobCity = reader.string();
                    break;
                case /* string job_state */ 24:
                    message.jobState = reader.string();
                    break;
                case /* string job_zip */ 25:
                    message.jobZip = reader.string();
                    break;
                case /* string job_address */ 26:
                    message.jobAddress = reader.string();
                    break;
                case /* int32 page_number */ 27:
                    message.pageNumber = reader.int32();
                    break;
                case /* bool without_limit */ 28:
                    message.withoutLimit = reader.bool();
                    break;
                case /* string lodging_address */ 29:
                    message.lodgingAddress = reader.string();
                    break;
                case /* int32 searchAssigned */ 30:
                    message.searchAssigned = reader.int32();
                    break;
                case /* string internal_notes */ 31:
                    message.internalNotes = reader.string();
                    break;
                case /* PerDiemType per_diem_type */ 32:
                    message.perDiemType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 requestor_id = 2; */
        if (message.requestorId !== 0)
            writer.tag(2, WireType.Varint).int32(message.requestorId);
        /* int32 job_id = 3; */
        if (message.jobId !== 0)
            writer.tag(3, WireType.Varint).int32(message.jobId);
        /* string check_in_date = 4; */
        if (message.checkInDate !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.checkInDate);
        /* string check_out_date = 5; */
        if (message.checkOutDate !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.checkOutDate);
        /* string admin_notes = 6; */
        if (message.adminNotes !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.adminNotes);
        /* string notes = 7; */
        if (message.notes !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.notes);
        /* int32 status_id = 8; */
        if (message.statusId !== 0)
            writer.tag(8, WireType.Varint).int32(message.statusId);
        /* int32 is_active = 9; */
        if (message.isActive !== 0)
            writer.tag(9, WireType.Varint).int32(message.isActive);
        /* repeated string date_range = 10; */
        for (let i = 0; i < message.dateRange.length; i++)
            writer.tag(10, WireType.LengthDelimited).string(message.dateRange[i]);
        /* repeated string date_target = 11; */
        for (let i = 0; i < message.dateTarget.length; i++)
            writer.tag(11, WireType.LengthDelimited).string(message.dateTarget[i]);
        /* repeated string field_mask = 12; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(12, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* repeated LodgingRequestAssignment assignments = 13; */
        for (let i = 0; i < message.assignments.length; i++)
            LodgingRequestAssignment.internalBinaryWrite(message.assignments[i], writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* repeated LodgingRequestDocument documents = 14; */
        for (let i = 0; i < message.documents.length; i++)
            LodgingRequestDocument.internalBinaryWrite(message.documents[i], writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* string order_by = 15; */
        if (message.orderBy !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 16; */
        if (message.orderDir !== "")
            writer.tag(16, WireType.LengthDelimited).string(message.orderDir);
        /* bool only_request = 17; */
        if (message.onlyRequest !== false)
            writer.tag(17, WireType.Varint).bool(message.onlyRequest);
        /* string requestor_firstname = 18; */
        if (message.requestorFirstname !== "")
            writer.tag(18, WireType.LengthDelimited).string(message.requestorFirstname);
        /* string requestor_lastname = 19; */
        if (message.requestorLastname !== "")
            writer.tag(19, WireType.LengthDelimited).string(message.requestorLastname);
        /* string status = 20; */
        if (message.status !== "")
            writer.tag(20, WireType.LengthDelimited).string(message.status);
        /* int32 department_id = 21; */
        if (message.departmentId !== 0)
            writer.tag(21, WireType.Varint).int32(message.departmentId);
        /* string department_name = 22; */
        if (message.departmentName !== "")
            writer.tag(22, WireType.LengthDelimited).string(message.departmentName);
        /* string job_city = 23; */
        if (message.jobCity !== "")
            writer.tag(23, WireType.LengthDelimited).string(message.jobCity);
        /* string job_state = 24; */
        if (message.jobState !== "")
            writer.tag(24, WireType.LengthDelimited).string(message.jobState);
        /* string job_zip = 25; */
        if (message.jobZip !== "")
            writer.tag(25, WireType.LengthDelimited).string(message.jobZip);
        /* string job_address = 26; */
        if (message.jobAddress !== "")
            writer.tag(26, WireType.LengthDelimited).string(message.jobAddress);
        /* int32 page_number = 27; */
        if (message.pageNumber !== 0)
            writer.tag(27, WireType.Varint).int32(message.pageNumber);
        /* bool without_limit = 28; */
        if (message.withoutLimit !== false)
            writer.tag(28, WireType.Varint).bool(message.withoutLimit);
        /* string lodging_address = 29; */
        if (message.lodgingAddress !== "")
            writer.tag(29, WireType.LengthDelimited).string(message.lodgingAddress);
        /* int32 searchAssigned = 30; */
        if (message.searchAssigned !== 0)
            writer.tag(30, WireType.Varint).int32(message.searchAssigned);
        /* string internal_notes = 31; */
        if (message.internalNotes !== "")
            writer.tag(31, WireType.LengthDelimited).string(message.internalNotes);
        /* PerDiemType per_diem_type = 32; */
        if (message.perDiemType !== 0)
            writer.tag(32, WireType.Varint).int32(message.perDiemType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message LodgingRequest
 */
export const LodgingRequest = new LodgingRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LodgingRequestList$Type extends MessageType {
    constructor() {
        super("LodgingRequestList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => LodgingRequest },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated LodgingRequest results */ 1:
                    message.results.push(LodgingRequest.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated LodgingRequest results = 1; */
        for (let i = 0; i < message.results.length; i++)
            LodgingRequest.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message LodgingRequestList
 */
export const LodgingRequestList = new LodgingRequestList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LodgingRequestStatus$Type extends MessageType {
    constructor() {
        super("LodgingRequestStatus", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, name: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message LodgingRequestStatus
 */
export const LodgingRequestStatus = new LodgingRequestStatus$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LodgingRequestStatusList$Type extends MessageType {
    constructor() {
        super("LodgingRequestStatusList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => LodgingRequestStatus },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated LodgingRequestStatus results */ 1:
                    message.results.push(LodgingRequestStatus.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated LodgingRequestStatus results = 1; */
        for (let i = 0; i < message.results.length; i++)
            LodgingRequestStatus.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message LodgingRequestStatusList
 */
export const LodgingRequestStatusList = new LodgingRequestStatusList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LodgingRequestAssignment$Type extends MessageType {
    constructor() {
        super("LodgingRequestAssignment", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "lodging_request_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "is_active", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "user_firstname", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "user_lastname", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "user_city", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "user_state", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "user_zip", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "user_address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "cost", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 14, name: "include_documents", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 15, name: "documents", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => LodgingRequestDocument },
            { no: 16, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, lodgingRequestId: 0, userId: 0, notes: "", isActive: 0, fieldMask: [], userFirstname: "", userLastname: "", userCity: "", userState: "", userZip: "", userAddress: "", cost: 0, includeDocuments: false, documents: [], orderBy: "", orderDir: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 lodging_request_id */ 2:
                    message.lodgingRequestId = reader.int32();
                    break;
                case /* int32 user_id */ 3:
                    message.userId = reader.int32();
                    break;
                case /* string notes */ 4:
                    message.notes = reader.string();
                    break;
                case /* int32 is_active */ 5:
                    message.isActive = reader.int32();
                    break;
                case /* repeated string field_mask */ 6:
                    message.fieldMask.push(reader.string());
                    break;
                case /* string user_firstname */ 7:
                    message.userFirstname = reader.string();
                    break;
                case /* string user_lastname */ 8:
                    message.userLastname = reader.string();
                    break;
                case /* string user_city */ 9:
                    message.userCity = reader.string();
                    break;
                case /* string user_state */ 10:
                    message.userState = reader.string();
                    break;
                case /* string user_zip */ 11:
                    message.userZip = reader.string();
                    break;
                case /* string user_address */ 12:
                    message.userAddress = reader.string();
                    break;
                case /* double cost */ 13:
                    message.cost = reader.double();
                    break;
                case /* bool include_documents */ 14:
                    message.includeDocuments = reader.bool();
                    break;
                case /* repeated LodgingRequestDocument documents */ 15:
                    message.documents.push(LodgingRequestDocument.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string order_by */ 16:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 17:
                    message.orderDir = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 lodging_request_id = 2; */
        if (message.lodgingRequestId !== 0)
            writer.tag(2, WireType.Varint).int32(message.lodgingRequestId);
        /* int32 user_id = 3; */
        if (message.userId !== 0)
            writer.tag(3, WireType.Varint).int32(message.userId);
        /* string notes = 4; */
        if (message.notes !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.notes);
        /* int32 is_active = 5; */
        if (message.isActive !== 0)
            writer.tag(5, WireType.Varint).int32(message.isActive);
        /* repeated string field_mask = 6; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(6, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* string user_firstname = 7; */
        if (message.userFirstname !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.userFirstname);
        /* string user_lastname = 8; */
        if (message.userLastname !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.userLastname);
        /* string user_city = 9; */
        if (message.userCity !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.userCity);
        /* string user_state = 10; */
        if (message.userState !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.userState);
        /* string user_zip = 11; */
        if (message.userZip !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.userZip);
        /* string user_address = 12; */
        if (message.userAddress !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.userAddress);
        /* double cost = 13; */
        if (message.cost !== 0)
            writer.tag(13, WireType.Bit64).double(message.cost);
        /* bool include_documents = 14; */
        if (message.includeDocuments !== false)
            writer.tag(14, WireType.Varint).bool(message.includeDocuments);
        /* repeated LodgingRequestDocument documents = 15; */
        for (let i = 0; i < message.documents.length; i++)
            LodgingRequestDocument.internalBinaryWrite(message.documents[i], writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        /* string order_by = 16; */
        if (message.orderBy !== "")
            writer.tag(16, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 17; */
        if (message.orderDir !== "")
            writer.tag(17, WireType.LengthDelimited).string(message.orderDir);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message LodgingRequestAssignment
 */
export const LodgingRequestAssignment = new LodgingRequestAssignment$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LodgingRequestAssignmentList$Type extends MessageType {
    constructor() {
        super("LodgingRequestAssignmentList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => LodgingRequestAssignment },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated LodgingRequestAssignment results */ 1:
                    message.results.push(LodgingRequestAssignment.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated LodgingRequestAssignment results = 1; */
        for (let i = 0; i < message.results.length; i++)
            LodgingRequestAssignment.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message LodgingRequestAssignmentList
 */
export const LodgingRequestAssignmentList = new LodgingRequestAssignmentList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LodgingRequestDocument$Type extends MessageType {
    constructor() {
        super("LodgingRequestDocument", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "lodging_request_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "file_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "file_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "file_bucket", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "mime_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "lodging_request_assignment_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, lodgingRequestId: 0, fileId: 0, fieldMask: [], fileName: "", fileBucket: "", mimeType: "", lodgingRequestAssignmentId: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 lodging_request_id */ 2:
                    message.lodgingRequestId = reader.int32();
                    break;
                case /* int32 file_id */ 3:
                    message.fileId = reader.int32();
                    break;
                case /* repeated string field_mask */ 4:
                    message.fieldMask.push(reader.string());
                    break;
                case /* string file_name */ 5:
                    message.fileName = reader.string();
                    break;
                case /* string file_bucket */ 6:
                    message.fileBucket = reader.string();
                    break;
                case /* string mime_type */ 7:
                    message.mimeType = reader.string();
                    break;
                case /* int32 lodging_request_assignment_id */ 8:
                    message.lodgingRequestAssignmentId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 lodging_request_id = 2; */
        if (message.lodgingRequestId !== 0)
            writer.tag(2, WireType.Varint).int32(message.lodgingRequestId);
        /* int32 file_id = 3; */
        if (message.fileId !== 0)
            writer.tag(3, WireType.Varint).int32(message.fileId);
        /* repeated string field_mask = 4; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* string file_name = 5; */
        if (message.fileName !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.fileName);
        /* string file_bucket = 6; */
        if (message.fileBucket !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.fileBucket);
        /* string mime_type = 7; */
        if (message.mimeType !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.mimeType);
        /* int32 lodging_request_assignment_id = 8; */
        if (message.lodgingRequestAssignmentId !== 0)
            writer.tag(8, WireType.Varint).int32(message.lodgingRequestAssignmentId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message LodgingRequestDocument
 */
export const LodgingRequestDocument = new LodgingRequestDocument$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LodgingRequestDocumentList$Type extends MessageType {
    constructor() {
        super("LodgingRequestDocumentList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => LodgingRequestDocument },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated LodgingRequestDocument results */ 1:
                    message.results.push(LodgingRequestDocument.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated LodgingRequestDocument results = 1; */
        for (let i = 0; i < message.results.length; i++)
            LodgingRequestDocument.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message LodgingRequestDocumentList
 */
export const LodgingRequestDocumentList = new LodgingRequestDocumentList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AssignmentDetails$Type extends MessageType {
    constructor() {
        super("AssignmentDetails", [
            { no: 1, name: "assignment_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "assignment_notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "user_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "user_firstname", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "user_lastname", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "user_city", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "user_state", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "user_zip", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "user_address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "lodging_request_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 11, name: "lodging_requestor_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 12, name: "job_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 13, name: "job_city", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "job_state", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "job_zip", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "job_address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "lodging_check_in_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 18, name: "lodging_check_out_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 19, name: "lodging_notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 20, name: "lodging_admin_notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 21, name: "lodging_status_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 22, name: "lodging_request_status", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 23, name: "date_range", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 24, name: "date_target", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 25, name: "order_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 26, name: "order_dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 27, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 28, name: "user_id_list", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 29, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 30, name: "without_limit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 31, name: "cost", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value) {
        const message = { assignmentId: 0, assignmentNotes: "", userId: 0, userFirstname: "", userLastname: "", userCity: "", userState: "", userZip: "", userAddress: "", lodgingRequestId: 0, lodgingRequestorId: 0, jobId: 0, jobCity: "", jobState: "", jobZip: "", jobAddress: "", lodgingCheckInDate: "", lodgingCheckOutDate: "", lodgingNotes: "", lodgingAdminNotes: "", lodgingStatusId: 0, lodgingRequestStatus: "", dateRange: [], dateTarget: [], orderBy: "", orderDir: "", fieldMask: [], userIdList: "", pageNumber: 0, withoutLimit: false, cost: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 assignment_id */ 1:
                    message.assignmentId = reader.int32();
                    break;
                case /* string assignment_notes */ 2:
                    message.assignmentNotes = reader.string();
                    break;
                case /* int32 user_id */ 3:
                    message.userId = reader.int32();
                    break;
                case /* string user_firstname */ 4:
                    message.userFirstname = reader.string();
                    break;
                case /* string user_lastname */ 5:
                    message.userLastname = reader.string();
                    break;
                case /* string user_city */ 6:
                    message.userCity = reader.string();
                    break;
                case /* string user_state */ 7:
                    message.userState = reader.string();
                    break;
                case /* string user_zip */ 8:
                    message.userZip = reader.string();
                    break;
                case /* string user_address */ 9:
                    message.userAddress = reader.string();
                    break;
                case /* int32 lodging_request_id */ 10:
                    message.lodgingRequestId = reader.int32();
                    break;
                case /* int32 lodging_requestor_id */ 11:
                    message.lodgingRequestorId = reader.int32();
                    break;
                case /* int32 job_id */ 12:
                    message.jobId = reader.int32();
                    break;
                case /* string job_city */ 13:
                    message.jobCity = reader.string();
                    break;
                case /* string job_state */ 14:
                    message.jobState = reader.string();
                    break;
                case /* string job_zip */ 15:
                    message.jobZip = reader.string();
                    break;
                case /* string job_address */ 16:
                    message.jobAddress = reader.string();
                    break;
                case /* string lodging_check_in_date */ 17:
                    message.lodgingCheckInDate = reader.string();
                    break;
                case /* string lodging_check_out_date */ 18:
                    message.lodgingCheckOutDate = reader.string();
                    break;
                case /* string lodging_notes */ 19:
                    message.lodgingNotes = reader.string();
                    break;
                case /* string lodging_admin_notes */ 20:
                    message.lodgingAdminNotes = reader.string();
                    break;
                case /* int32 lodging_status_id */ 21:
                    message.lodgingStatusId = reader.int32();
                    break;
                case /* string lodging_request_status */ 22:
                    message.lodgingRequestStatus = reader.string();
                    break;
                case /* repeated string date_range */ 23:
                    message.dateRange.push(reader.string());
                    break;
                case /* repeated string date_target */ 24:
                    message.dateTarget.push(reader.string());
                    break;
                case /* string order_by */ 25:
                    message.orderBy = reader.string();
                    break;
                case /* string order_dir */ 26:
                    message.orderDir = reader.string();
                    break;
                case /* repeated string field_mask */ 27:
                    message.fieldMask.push(reader.string());
                    break;
                case /* string user_id_list */ 28:
                    message.userIdList = reader.string();
                    break;
                case /* int32 page_number */ 29:
                    message.pageNumber = reader.int32();
                    break;
                case /* bool without_limit */ 30:
                    message.withoutLimit = reader.bool();
                    break;
                case /* double cost */ 31:
                    message.cost = reader.double();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 assignment_id = 1; */
        if (message.assignmentId !== 0)
            writer.tag(1, WireType.Varint).int32(message.assignmentId);
        /* string assignment_notes = 2; */
        if (message.assignmentNotes !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.assignmentNotes);
        /* int32 user_id = 3; */
        if (message.userId !== 0)
            writer.tag(3, WireType.Varint).int32(message.userId);
        /* string user_firstname = 4; */
        if (message.userFirstname !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.userFirstname);
        /* string user_lastname = 5; */
        if (message.userLastname !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.userLastname);
        /* string user_city = 6; */
        if (message.userCity !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.userCity);
        /* string user_state = 7; */
        if (message.userState !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.userState);
        /* string user_zip = 8; */
        if (message.userZip !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.userZip);
        /* string user_address = 9; */
        if (message.userAddress !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.userAddress);
        /* int32 lodging_request_id = 10; */
        if (message.lodgingRequestId !== 0)
            writer.tag(10, WireType.Varint).int32(message.lodgingRequestId);
        /* int32 lodging_requestor_id = 11; */
        if (message.lodgingRequestorId !== 0)
            writer.tag(11, WireType.Varint).int32(message.lodgingRequestorId);
        /* int32 job_id = 12; */
        if (message.jobId !== 0)
            writer.tag(12, WireType.Varint).int32(message.jobId);
        /* string job_city = 13; */
        if (message.jobCity !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.jobCity);
        /* string job_state = 14; */
        if (message.jobState !== "")
            writer.tag(14, WireType.LengthDelimited).string(message.jobState);
        /* string job_zip = 15; */
        if (message.jobZip !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.jobZip);
        /* string job_address = 16; */
        if (message.jobAddress !== "")
            writer.tag(16, WireType.LengthDelimited).string(message.jobAddress);
        /* string lodging_check_in_date = 17; */
        if (message.lodgingCheckInDate !== "")
            writer.tag(17, WireType.LengthDelimited).string(message.lodgingCheckInDate);
        /* string lodging_check_out_date = 18; */
        if (message.lodgingCheckOutDate !== "")
            writer.tag(18, WireType.LengthDelimited).string(message.lodgingCheckOutDate);
        /* string lodging_notes = 19; */
        if (message.lodgingNotes !== "")
            writer.tag(19, WireType.LengthDelimited).string(message.lodgingNotes);
        /* string lodging_admin_notes = 20; */
        if (message.lodgingAdminNotes !== "")
            writer.tag(20, WireType.LengthDelimited).string(message.lodgingAdminNotes);
        /* int32 lodging_status_id = 21; */
        if (message.lodgingStatusId !== 0)
            writer.tag(21, WireType.Varint).int32(message.lodgingStatusId);
        /* string lodging_request_status = 22; */
        if (message.lodgingRequestStatus !== "")
            writer.tag(22, WireType.LengthDelimited).string(message.lodgingRequestStatus);
        /* repeated string date_range = 23; */
        for (let i = 0; i < message.dateRange.length; i++)
            writer.tag(23, WireType.LengthDelimited).string(message.dateRange[i]);
        /* repeated string date_target = 24; */
        for (let i = 0; i < message.dateTarget.length; i++)
            writer.tag(24, WireType.LengthDelimited).string(message.dateTarget[i]);
        /* string order_by = 25; */
        if (message.orderBy !== "")
            writer.tag(25, WireType.LengthDelimited).string(message.orderBy);
        /* string order_dir = 26; */
        if (message.orderDir !== "")
            writer.tag(26, WireType.LengthDelimited).string(message.orderDir);
        /* repeated string field_mask = 27; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(27, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* string user_id_list = 28; */
        if (message.userIdList !== "")
            writer.tag(28, WireType.LengthDelimited).string(message.userIdList);
        /* int32 page_number = 29; */
        if (message.pageNumber !== 0)
            writer.tag(29, WireType.Varint).int32(message.pageNumber);
        /* bool without_limit = 30; */
        if (message.withoutLimit !== false)
            writer.tag(30, WireType.Varint).bool(message.withoutLimit);
        /* double cost = 31; */
        if (message.cost !== 0)
            writer.tag(31, WireType.Bit64).double(message.cost);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message AssignmentDetails
 */
export const AssignmentDetails = new AssignmentDetails$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AssignmentDetailsList$Type extends MessageType {
    constructor() {
        super("AssignmentDetailsList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AssignmentDetails },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated AssignmentDetails results */ 1:
                    message.results.push(AssignmentDetails.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated AssignmentDetails results = 1; */
        for (let i = 0; i < message.results.length; i++)
            AssignmentDetails.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message AssignmentDetailsList
 */
export const AssignmentDetailsList = new AssignmentDetailsList$Type();
/**
 * @generated ServiceType for protobuf service LodgingRequestService
 */
export const LodgingRequestService = new ServiceType("LodgingRequestService", [
    { name: "GetLodgingRequestStatus", options: {}, I: LodgingRequestStatus, O: LodgingRequestStatus },
    { name: "GetAllLodgingRequestStatus", options: {}, I: Empty, O: LodgingRequestStatusList },
    { name: "CreateLodgingRequest", options: {}, I: LodgingRequest, O: LodgingRequest },
    { name: "GetLodgingRequest", options: {}, I: LodgingRequest, O: LodgingRequest },
    { name: "BatchGetLodgingRequest", options: {}, I: LodgingRequest, O: LodgingRequestList },
    { name: "UpdateLodgingRequest", options: {}, I: LodgingRequest, O: LodgingRequest },
    { name: "DeleteLodgingRequest", options: {}, I: LodgingRequest, O: Empty },
    { name: "CreateLodgingAssignment", options: {}, I: LodgingRequestAssignment, O: LodgingRequestAssignment },
    { name: "GetLodgingAssignment", options: {}, I: LodgingRequestAssignment, O: LodgingRequestAssignment },
    { name: "BatchGetLodgingAssignment", options: {}, I: LodgingRequestAssignment, O: LodgingRequestAssignmentList },
    { name: "UpdateLodgingAssignment", options: {}, I: LodgingRequestAssignment, O: LodgingRequestAssignment },
    { name: "DeleteLodgingAssignment", options: {}, I: LodgingRequestAssignment, O: Empty },
    { name: "CreateLodgingDocument", options: {}, I: LodgingRequestDocument, O: LodgingRequestDocument },
    { name: "GetLodgingDocument", options: {}, I: LodgingRequestDocument, O: LodgingRequestDocument },
    { name: "BatchGetLodgingDocument", options: {}, I: LodgingRequestDocument, O: LodgingRequestDocumentList },
    { name: "UpdateLodgingDocument", options: {}, I: LodgingRequestDocument, O: LodgingRequestDocument },
    { name: "DeleteLodgingDocument", options: {}, I: LodgingRequestDocument, O: Empty },
    { name: "GetAssignmentDetails", options: {}, I: AssignmentDetails, O: AssignmentDetails },
    { name: "BatchGetAssignmentDetails", options: {}, I: AssignmentDetails, O: AssignmentDetailsList },
    { name: "GetOverlapAssignments", options: {}, I: LodgingRequest, O: AssignmentDetailsList },
    { name: "BatchGetLodgingRequestByUsersAssignment", options: {}, I: Int32List, O: LodgingRequestList },
    { name: "AddOrUpdatePerdiems", options: {}, I: IDInt32, O: Empty },
    { name: "AutoUpdateRequestStatus", options: {}, I: Empty, O: Empty }
]);
