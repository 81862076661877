import { type SpiffType, Task } from '@kalos/kalos-rpc';
import { DataTable, DataTableColumnHeader } from '@kalos/ui';
import { FlagCircleOutlined } from '@mui/icons-material';
import RejectIcon from '@mui/icons-material/Block';
import ApproveIcon from '@mui/icons-material/CheckCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RevokeIcon from '@mui/icons-material/History';
import IconButton from '@mui/material/IconButton';
import { type ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { type FC, useCallback, useMemo, useReducer } from 'react';

import { getLoadingColumns } from '../../../../components/utils';
import { ROWS_PER_PAGE } from '../../../../constants';
import { useAuth } from '../../../../context/AuthContext';
import {
  useDeleteTaskMutation,
  useSpiffTypeBatchGetQuery,
  useTaskBatchGetQuery,
} from '../../../../hooks/react-query/useTaskClientServiceQuery';
import { formatDate, usd } from '../../../../tools/helpers';
import { ConfirmDelete } from '../../ConfirmDelete';
import { Modal } from '../../Modal';
import { SectionBar } from '../../SectionBar';
import { getStatusFormInit, SpiffActionsList, SpiffToolLogEdit } from '../../SpiffToolLogEdit';
import { Tooltip } from '../../Tooltip';
import { type EventType } from '..';
import { ACTIONS, reducer } from './spiffReducer';
interface Props {
  serviceItem: EventType;
}

const staticTasks = Array.from({ length: 4 }, (_, i) => Task.create({ id: i }));

export const Spiffs: FC<Props> = ({ serviceItem }) => {
  const [state, dispatch] = useReducer(reducer, {
    entries: [],
    page: 0,
    count: 0,
    editing: undefined,
    deleting: undefined,
    status: 0,
  });

  const loggedUser = useAuth().user;

  const spiffTypesQuery = useSpiffTypeBatchGetQuery({
    filter: { isActive: true },
    select(data) {
      return data.results.reduce<Record<number, SpiffType>>((aggr, item) => {
        aggr[item.id] = item;
        return aggr;
      }, {});
    },
  });

  const tasksQuery = useTaskBatchGetQuery({
    filter: {
      pageNumber: state.page,
      billableType: 'Spiff',
      spiffJobNumber: serviceItem.id.toString(),
      orderBy: 'date_performed',
      orderDir: 'DESC',
    },
  });

  const handlePageChange = useCallback((page: number) => {
    dispatch({ type: ACTIONS.SET_PAGE, data: page });
  }, []);

  const handleSetEditing = useCallback(
    (editing?: Task, status?: number) => () => {
      dispatch({ type: ACTIONS.SET_EDITING, data: editing });
      dispatch({ type: ACTIONS.SET_STATUS, data: status });
    },
    [],
  );
  const handleToggleDeleting = useCallback(
    (entry?: Task) => () => dispatch({ type: ACTIONS.SET_DELETING, data: entry }),
    [],
  );

  const taskTableColumns = useMemo<ColumnDef<Task>[]>(
    () => [
      {
        header(props) {
          return <DataTableColumnHeader column={props.column} title="Claim Date" />;
        },
        accessorKey: 'datePerformed',
        meta: {
          className: 'text-xs w-20',
        },
        cell(props) {
          return (
            <div className={props.column.columnDef.meta?.className}>
              {formatDate(props.row.original.datePerformed)}
            </div>
          );
        },
      },
      {
        header(props) {
          return <DataTableColumnHeader column={props.column} title="Spiff ID" />;
        },
        accessorKey: 'spiffToolId',
        meta: {
          className: 'text-xs w-20',
        },
        cell(props) {
          return (
            <div className={props.column.columnDef.meta?.className}>
              {props.row.original.spiffToolId}
            </div>
          );
        },
      },
      {
        header(props) {
          return <DataTableColumnHeader column={props.column} title="Description" />;
        },
        accessorKey: 'briefDescription',
        meta: {
          className: 'text-xs w-20',
        },
        cell(props) {
          return (
            <div className={props.column.columnDef.meta?.className}>
              {props.row.original.briefDescription}
            </div>
          );
        },
      },
      {
        header(props) {
          return <DataTableColumnHeader column={props.column} title="Spiff Type" />;
        },
        accessorKey: 'spiffTypeId',
        meta: {
          className: 'text-xs w-20',
        },
        cell(props) {
          return (
            <div className={props.column.columnDef.meta?.className}>
              {spiffTypesQuery.data?.[props.row.original.spiffTypeId]?.ext}
            </div>
          );
        },
      },
      {
        header(props) {
          return <DataTableColumnHeader column={props.column} title="Claimed By" />;
        },
        accessorKey: 'ownerName',
        meta: {
          className: 'text-xs w-20',
        },
        cell(props) {
          return (
            <div className={props.column.columnDef.meta?.className}>
              {props.row.original.ownerName}
            </div>
          );
        },
      },
      {
        header(props) {
          return <DataTableColumnHeader column={props.column} title="Status" />;
        },
        accessorKey: 'actions',
        meta: {
          className: 'text-xs w-24',
        },
        cell(props) {
          return (
            <div className={props.column.columnDef.meta?.className}>
              <SpiffActionsList actions={props.row.original.actions} />
            </div>
          );
        },
      },
      {
        header(props) {
          return <DataTableColumnHeader column={props.column} title="Amount" />;
        },
        accessorKey: 'spiffAmount',
        meta: {
          className: 'text-xs w-20',
        },
        cell(props) {
          return (
            <div className={props.column.columnDef.meta?.className}>
              {usd(props.row.original.spiffAmount)}
            </div>
          );
        },
      },
      {
        id: 'flag',
        header: '',

        cell({ row }) {
          return row.original.duplicates.length ? (
            <Tooltip content="Duplicate property on object exists" placement="bottom">
              <FlagCircleOutlined
                sx={{
                  fill: 'red',
                }}
              />
            </Tooltip>
          ) : (
            ''
          );
        },
      },
      {
        id: 'actions',
        cell(props) {
          const lastStatus = props.row.original.actions[0]
            ? props.row.original.actions[0].status
            : 0;

          const disableActions = !loggedUser.permissionGroups.find((el) => el.name === 'Manager')
            ? true
            : props.row.original.externalId != loggedUser.id;
          return (
            <>
              {lastStatus != 0 ? (
                <>
                  <Tooltip content="Approve" placement="bottom">
                    <IconButton
                      size="small"
                      onClick={handleSetEditing(props.row.original, 1)}
                      disabled={disableActions}
                    >
                      <ApproveIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip content="Reject" placement="bottom">
                    <IconButton
                      size="small"
                      onClick={handleSetEditing(props.row.original, 2)}
                      disabled={disableActions}
                    >
                      <RejectIcon />
                    </IconButton>
                  </Tooltip>
                </>
              ) : (
                <Tooltip content="Revoke" placement="bottom">
                  <IconButton
                    size="small"
                    onClick={handleSetEditing(props.row.original, 3)}
                    disabled={disableActions}
                  >
                    <RevokeIcon />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip content="Delete Spiff" placement="bottom">
                <IconButton
                  size="small"
                  disabled={props.row.original.externalId != loggedUser.id}
                  onClick={handleToggleDeleting(props.row.original)}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
              <Tooltip content="Edit Spiff" placement="bottom">
                <IconButton size="small" onClick={handleSetEditing(props.row.original)}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    ],
    [
      handleSetEditing,
      handleToggleDeleting,
      loggedUser.id,
      loggedUser.permissionGroups,
      spiffTypesQuery.data,
    ],
  );

  const loadingColumns = useMemo(() => getLoadingColumns(taskTableColumns), [taskTableColumns]);

  const isLoading = tasksQuery.isPending || spiffTypesQuery.isPending;
  const table = useReactTable({
    getCoreRowModel: getCoreRowModel(),
    columns: isLoading ? loadingColumns : taskTableColumns,
    data: isLoading ? staticTasks : tasksQuery.data?.results || [],
    getRowId: (row) => row.id.toString(),
    enableSorting: false,
  });

  const deleteTaskMutation = useDeleteTaskMutation();

  const handleDelete = useCallback(async () => {
    if (state.deleting) {
      await deleteTaskMutation.mutateAsync(state.deleting);
      dispatch({ type: ACTIONS.SET_DELETING, data: undefined });
    }
  }, [deleteTaskMutation, state.deleting]);

  return (
    <>
      <SectionBar
        title="Spiffs"
        pagination={{
          count: state.count,
          page: state.page,
          rowsPerPage: ROWS_PER_PAGE,
          onPageChange: handlePageChange,
        }}
      />
      <div className="w-full overflow-auto">
        <DataTable table={table} />
      </div>
      {state.editing && (
        <Modal open onClose={handleSetEditing()} fullScreen>
          <SpiffToolLogEdit
            onClose={handleSetEditing()}
            data={
              tasksQuery.data?.results.find((task) => task.id === state.editing?.id) ||
              state.editing
            }
            onSave={() => {
              handleSetEditing()();
            }}
            onStatusChange={() => {}}
            type="Spiff"
            loading={isLoading}
            cancelLabel="Close"
            statusEditing={
              state.status
                ? getStatusFormInit(state.status, `${loggedUser.firstname} ${loggedUser.lastname}`)
                : undefined
            }
          />
        </Modal>
      )}
      {state.deleting && (
        <ConfirmDelete
          open
          onClose={handleToggleDeleting()}
          kind="Spiff"
          name={[
            `claimed by ${state.deleting.ownerName}`,
            ...(state.deleting.datePerformed
              ? [`at ${formatDate(state.deleting.datePerformed)}`]
              : []),
            `for amount ${usd(state.deleting.spiffAmount)}`,
          ].join(' ')}
          onConfirm={handleDelete}
        />
      )}
    </>
  );
};
