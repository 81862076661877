import './Modal.module.less';

import ModalComponent from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import { clsx } from 'clsx';
import { type CSSProperties, type ReactNode, useEffect } from 'react';

type Style = {
  compact?: boolean;
  maxWidth?: number | 'none';
  fullScreen?: boolean;
  fullHeight?: boolean;
};

interface Props extends Style {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
  onOpen?: () => void;
  className?: string;
  styles?: CSSProperties;
}

export const Modal = ({
  open,
  onClose,
  children,
  maxWidth = 'none',
  fullScreen = false,
  fullHeight = false,
  className = '',
  styles = {},
  onOpen,
}: Props) => {
  useEffect(() => {
    if (open && onOpen) {
      onOpen();
    }
  }, [open, onOpen]);
  return (
    <ModalComponent open={open} onClose={onClose} className={'Modal'}>
      <Paper
        className={clsx('ModalPaper', className)}
        style={{
          maxWidth,
          width: fullScreen ? '100%' : 'auto',
          height: fullScreen || fullHeight ? '100%' : 'auto',
          ...styles,
        }}
      >
        {children}
      </Paper>
    </ModalComponent>
  );
};
