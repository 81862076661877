// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "reimbursement.proto" (syntax proto3)
// tslint:disable
import { ReimbursementService } from "./reimbursement";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service ReimbursementService
 */
export class ReimbursementServiceClient {
    constructor(_transport) {
        this._transport = _transport;
        this.typeName = ReimbursementService.typeName;
        this.methods = ReimbursementService.methods;
        this.options = ReimbursementService.options;
    }
    /**
     * @generated from protobuf rpc: Create(Reimbursement) returns (Reimbursement);
     */
    create(input, options) {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Get(Reimbursement) returns (Reimbursement);
     */
    get(input, options) {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGet(Reimbursement) returns (ReimbursementList);
     */
    batchGet(input, options) {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Update(Reimbursement) returns (Reimbursement);
     */
    update(input, options) {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Delete(Reimbursement) returns (Reimbursement);
     */
    delete(input, options) {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateDocument(ReimbursementDocument) returns (ReimbursementDocument);
     */
    createDocument(input, options) {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetDocument(ReimbursementDocument) returns (ReimbursementDocument);
     */
    getDocument(input, options) {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetDocument(ReimbursementDocument) returns (ReimbursementDocumentList);
     */
    batchGetDocument(input, options) {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateDocument(ReimbursementDocument) returns (ReimbursementDocument);
     */
    updateDocument(input, options) {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteDocument(ReimbursementDocument) returns (ReimbursementDocument);
     */
    deleteDocument(input, options) {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
}
