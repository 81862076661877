import { BaseClient } from '../BaseClient';
import { ActivityLog, ActivityLogList } from '../compiled-protos/activity_log';
import { ActivityLogServiceClient } from '../compiled-protos/activity_log.client';

class ActivityLogClient extends BaseClient {
  self: ActivityLogServiceClient;
  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new ActivityLogServiceClient(this.transport);
  }

  public async Create(req: ActivityLog) {
    let res = ActivityLog.create();
    try {
      res = await this.self.create(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Get(req: ActivityLog) {
    let res = ActivityLog.create();
    try {
      res = await this.self.get(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Update(req: ActivityLog) {
    let res = ActivityLog.create();
    try {
      res = await this.self.update(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Delete(req: ActivityLog) {
    let res = ActivityLog.create();
    try {
      res = await this.self.delete(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async BatchGet(req: ActivityLog) {
    let res = ActivityLogList.create();
    try {
      res = await this.self.batchGet(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async BatchGetEventLogs(req: ActivityLog) {
    let res = ActivityLogList.create();
    try {
      res = await this.self.batchGetEventLogs(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }
}

export { ActivityLog, ActivityLogList, ActivityLogClient };
