// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "vendor.proto" (syntax proto3)
// tslint:disable
import { VendorService } from "./vendor";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service VendorService
 */
export class VendorServiceClient {
    constructor(_transport) {
        this._transport = _transport;
        this.typeName = VendorService.typeName;
        this.methods = VendorService.methods;
        this.options = VendorService.options;
    }
    /**
     * @generated from protobuf rpc: Create(Vendor) returns (Vendor);
     */
    create(input, options) {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Get(Vendor) returns (Vendor);
     */
    get(input, options) {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGet(Vendor) returns (VendorList);
     */
    batchGet(input, options) {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Update(Vendor) returns (Vendor);
     */
    update(input, options) {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Delete(Vendor) returns (Vendor);
     */
    delete(input, options) {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateVendorOrder(VendorOrder) returns (VendorOrder);
     */
    createVendorOrder(input, options) {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetVendorOrder(VendorOrder) returns (VendorOrder);
     */
    getVendorOrder(input, options) {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetVendorOrder(VendorOrder) returns (VendorOrderList);
     */
    batchGetVendorOrder(input, options) {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateVendorOrder(VendorOrder) returns (VendorOrder);
     */
    updateVendorOrder(input, options) {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteVendorOrder(VendorOrder) returns (VendorOrder);
     */
    deleteVendorOrder(input, options) {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
}
