import { BaseClient } from '../BaseClient';
import { QuotePhoto, QuotePhotoList } from '../compiled-protos/quote';
import { QuoteServiceClient as QuotePhotoServiceClient } from '../compiled-protos/quote.client';

class QuotePhotoClient extends BaseClient {
  self: QuotePhotoServiceClient;

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new QuotePhotoServiceClient(this.transport);
  }

  public async Create(req: QuotePhoto) {
    let res = QuotePhoto.create();
    try {
      res = await this.self.createQuotePhoto(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Get(req: QuotePhoto) {
    let res = QuotePhoto.create();
    try {
      res = await this.self.getQuotePhoto(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;

    }
    return res;
  }

  public async Update(req: QuotePhoto) {
    let res = QuotePhoto.create();
    try {
      res = await this.self.updateQuotePhoto(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
   
    }
    return res;
  }

  public async Delete(req: QuotePhoto) {
    let res = QuotePhoto.create();
    try {
      res = await this.self.deleteQuotePhoto(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
      return;
    }
    return res;
  }

  public async BatchGet(req: QuotePhoto) {
    const res = await this.self.batchGetQuotePhoto(req, this.getMetaData()).response;
    return res;
  }

  
}

export { QuotePhoto, QuotePhotoList, QuotePhotoClient };
