import { Transaction } from '@kalos/kalos-rpc';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import {
  type AdminTransactionFilter,
  applyFilters,
} from '../../modules/Transactions/components/TransactionAdmin';
import { TransactionClientService } from '../../tools/helpers';
import { queryKeys } from './constants';

export const useTransactionAdminQuery = ({
  filter,
  page,
  enabled = true,
  isSU,
  departmentIDList,
}: {
  filter: AdminTransactionFilter;
  page: number;
  enabled?: boolean;
  isSU: boolean;
  departmentIDList?: string;
}) => {
  const requestObject = useMemo(() => {
    let reqObj = Transaction.create();
    reqObj = applyFilters({
      obj: reqObj,
      filters: filter,
      isSU,
      departmentIDList,
    });
    reqObj.pageNumber = page;
    reqObj.notEquals = reqObj.notEquals.concat(['VendorCategory']);
    reqObj.vendorCategory = "'Receipt','PickTicket','Invoice'";
    return reqObj;
  }, [departmentIDList, filter, isSU, page]);

  return useQuery({
    queryKey: [
      queryKeys.transaction.root,
      queryKeys.transaction.list,
      queryKeys.transaction.getTransactionHash(requestObject),
    ],
    queryFn: () => {
      return TransactionClientService.BatchGet(requestObject);
    },
    enabled,
  });
};
