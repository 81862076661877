import { type LoadUsersByFilter, User, type UserList } from '@kalos/kalos-rpc';
import { useQuery } from '@tanstack/react-query';

import { S3ClientService, UserClientService } from '../../tools/helpers';
import { queryKeys } from './constants';

export const useEmployeesQuery = <TSelectData = UserList,>({
  criteria,
  enabled = true,
  select,
}: {
  criteria: LoadUsersByFilter;
  enabled?: boolean;
  select?: (data: UserList) => TSelectData;
}) => {
  return useQuery({
    queryKey: [queryKeys.employees.root, queryKeys.employees.getEmployeesHash(criteria)],
    queryFn: () => {
      const userReq = User.create();
      userReq.overrideLimit = true;
      userReq.isEmployee = 1;
      userReq.isActive = 1;
      userReq.orderBy = criteria.sort.orderBy;
      userReq.orderDir = criteria.sort.orderDir;
      return UserClientService.BatchGet(userReq);
    },
    enabled,
    select,
  });
};

export const getEmployeeImageQueryConfig = <TSelectData = string,>({
  imageUrl,
  select,
}: {
  imageUrl: string;
  select?: (data: string) => TSelectData;
}) => ({
  queryFn: () => {
    return S3ClientService.getFileS3BucketUrl(imageUrl, 'kalos-employee-images');
  },
  queryKey: ['employeeImage', imageUrl],
  select,
});
