import { Transaction, TransactionLineItem, TransactionList } from '@kalos/kalos-rpc';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { TransactionClientService } from '../../tools/helpers';
import { isTruthy } from '../../tools/typeguargs';
import { queryKeys } from './constants';
import { type EntityFilter } from './utils';

export type TransactionLineItemFilter = EntityFilter<TransactionLineItem>;
export const useTransactionLineItemBatchGetQuery = ({
  filter = {},
}: { filter?: TransactionLineItemFilter } = {}) => {
  return useQuery({
    queryKey: [
      queryKeys.transactionLineItem.root,
      queryKeys.transactionLineItem.list,
      queryKeys.transactionLineItem.getHash(filter),
    ],
    queryFn: async () => {
      return await TransactionClientService.BatchGetTransactionLineItems(
        TransactionLineItem.create(filter),
      );
    },
  });
};

export const useCreateTransactionLineItemMutation = ({
  updateCache = true,
}: { updateCache?: boolean } = {}) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (lineItem: TransactionLineItem) => {
      return await TransactionClientService.CreateTransactionLineItem(lineItem);
    },
    onSuccess: updateCache
      ? (newLineItem) => {
          queryClient.setQueriesData<Transaction | TransactionList>(
            { queryKey: [queryKeys.transaction.root] },
            (cache) => {
              if (cache) {
                if ('results' in cache) {
                  if (cache.results.find((txn) => txn.id === newLineItem.transactionId)) {
                    return TransactionList.create({
                      totalCount: cache.totalCount,
                      results: cache.results.map((txn) => {
                        if (txn.id !== newLineItem.transactionId) {
                          return txn;
                        }
                        return Transaction.create({
                          ...txn,
                          transactionLineItems: [...txn.transactionLineItems, newLineItem],
                        });
                      }),
                    });
                  }

                  return cache;
                } else {
                  if (cache.id === newLineItem.transactionId) {
                    return Transaction.create({
                      ...cache,
                      transactionLineItems: [...cache.transactionLineItems, newLineItem],
                    });
                  }
                  return cache;
                }
              }
            },
          );

          queryClient.invalidateQueries({ queryKey: [queryKeys.transactionLineItem.list] });
          queryClient.invalidateQueries({ queryKey: [queryKeys.transaction.list] });
        }
      : undefined,
  });
};

export const useUpdateTransactionLineItemMutation = () => {
  return useMutation({
    mutationFn: async (lineItem: TransactionLineItem) => {
      return await TransactionClientService.UpdateTransactionLineItem(lineItem);
    },
  });
};

export const useDeleteTransactionLineItemMutation = () => {
  return useMutation({
    mutationFn: async (lineItem: TransactionLineItem) => {
      return await TransactionClientService.DeleteTransactionLineItem(lineItem);
    },
  });
};

type LineItemsBatchMutationArg = {
  lineItemsToDelete: TransactionLineItem[];
  lineItemsToCreate: TransactionLineItem[];
  lineItemsToUpdate: TransactionLineItem[];
};

const modifyCacheTransaction = ({
  cacheTransaction: cache,
  updateResults,
  updateRequests,
}: {
  cacheTransaction: Transaction;
  updateResults: TransactionLineItem[];
  updateRequests: LineItemsBatchMutationArg;
}) => {
  const deletedLineItemsIds = updateRequests.lineItemsToDelete.map((lineItem) => lineItem.id);

  const updatedLineItemsWithoutDeleted = updateResults.filter(
    (lineItem) => !deletedLineItemsIds.includes(lineItem.id),
  );

  const updatedLineItemsIds = updatedLineItemsWithoutDeleted.map((lineItem) => lineItem.id);

  const cachedLineItemsIds = cache.transactionLineItems.map((li) => li.id);

  const createdLineItems = updatedLineItemsWithoutDeleted.filter(
    (lineItem) => !cachedLineItemsIds.includes(lineItem.id),
  );
  const updatedCache = Transaction.create({
    ...cache,
    transactionLineItems: [
      ...cache.transactionLineItems
        .map((li) => {
          if (updatedLineItemsIds.includes(li.id)) {
            return updatedLineItemsWithoutDeleted.find((uli) => uli.id === li.id);
          }
          if (deletedLineItemsIds.includes(li.id)) {
            return null;
          }
          return li;
        })
        .filter(isTruthy),
      ...createdLineItems,
    ],
  });

  return updatedCache;
};

export const useTransactionLineItemBatchMutation = () => {
  const deleteMutation = useDeleteTransactionLineItemMutation();
  const createMutation = useCreateTransactionLineItemMutation({
    updateCache: false,
  });
  const updateMutation = useUpdateTransactionLineItemMutation();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      lineItemsToCreate,
      lineItemsToDelete,
      lineItemsToUpdate,
    }: LineItemsBatchMutationArg) => {
      return await Promise.all([
        ...lineItemsToCreate.map((lineItem) => createMutation.mutateAsync(lineItem)),
        ...lineItemsToDelete.map((lineItem) => deleteMutation.mutateAsync(lineItem)),
        ...lineItemsToUpdate.map((lineItem) => updateMutation.mutateAsync(lineItem)),
      ]);
    },
    onSuccess: (updatedLineItems, variables) => {
      queryClient.setQueriesData<Transaction | TransactionList>(
        { queryKey: [queryKeys.transaction.root] },
        (cache) => {
          if (cache) {
            const updatedTransactionId = updatedLineItems.at(0)?.transactionId;

            if (
              !updatedTransactionId ||
              !updatedLineItems.every((li) => li.transactionId === updatedTransactionId)
            ) {
              throw new Error('All line items must belong to the same transaction');
            }

            if ('results' in cache) {
              if (cache.results.find((txn) => txn.id === updatedTransactionId)) {
                return TransactionList.create({
                  totalCount: cache.totalCount,
                  results: cache.results.map((txn) => {
                    if (txn.id !== updatedTransactionId) {
                      return txn;
                    }
                    const modifiedTransaction = modifyCacheTransaction({
                      cacheTransaction: txn,
                      updateResults: updatedLineItems,
                      updateRequests: variables,
                    });
                    return modifiedTransaction;
                  }),
                });
              }
              return cache;
            } else {
              if (cache.id === updatedTransactionId) {
                return modifyCacheTransaction({
                  cacheTransaction: cache,
                  updateResults: updatedLineItems,
                  updateRequests: variables,
                });
              }
              return cache;
            }
          }
        },
      );
      queryClient.invalidateQueries({ queryKey: [queryKeys.transaction.root] });
      queryClient.invalidateQueries({ queryKey: [queryKeys.transactionLineItem.list] });
    },
  });
};
