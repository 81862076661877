import { BaseClient } from '../BaseClient';
import {
  TransactionAccountsReceivable,
  TransactionAccountsReceivableDocument,
  TransactionAccountsReceivableDocumentList,
  TransactionAccountsReceivableLine,
  TransactionAccountsReceivableLineItem,
  TransactionAccountsReceivableLineItemList,
  TransactionAccountsReceivableLineList,
  TransactionAccountsReceivableList,
  TransactionAccountsReceivablePayment,
  TransactionAccountsReceivablePaymentList,
} from '../compiled-protos/transaction_accounts_receivable';
import { TransactionAccountsReceivableServiceClient } from '../compiled-protos/transaction_accounts_receivable.client';
class TransactionAccountsReceivableClient extends BaseClient {
  self: TransactionAccountsReceivableServiceClient;

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new TransactionAccountsReceivableServiceClient(this.transport);
  }

  public async Create(req: TransactionAccountsReceivable) {
    let res = TransactionAccountsReceivable.create();
    try {
      res = await this.self.create(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Get(req: TransactionAccountsReceivable) {
    let res = TransactionAccountsReceivable.create();
    try {
      res = await this.self.get(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Update(req: TransactionAccountsReceivable) {
    let res = TransactionAccountsReceivable.create();
    try {
      res = await this.self.update(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Delete(req: TransactionAccountsReceivable) {
    let res = TransactionAccountsReceivable.create();
    try {
      res = await this.self.delete(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async BatchGet(req: TransactionAccountsReceivable) {
    let res = TransactionAccountsReceivableList.create();
    try {
      res = await this.self.batchGet(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }
  public async CreateLine(req: TransactionAccountsReceivableLine) {
    let res = TransactionAccountsReceivableLine.create();
    try {
      res = await this.self.createLine(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async GetLine(req: TransactionAccountsReceivableLine) {
    let res = TransactionAccountsReceivableLine.create();
    try {
      res = await this.self.getLine(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async UpdateLine(req: TransactionAccountsReceivableLine) {
    let res = TransactionAccountsReceivableLine.create();
    try {
      res = await this.self.updateLine(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async DeleteLine(req: TransactionAccountsReceivableLine) {
    let res = TransactionAccountsReceivableLine.create();
    try {
      res = await this.self.deleteLine(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async BatchGetLine(req: TransactionAccountsReceivableLine) {
    let res = TransactionAccountsReceivableLineList.create();
    try {
      res = await this.self.batchGetLine(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async CreateLineItem(req: TransactionAccountsReceivableLineItem) {
    let res = TransactionAccountsReceivableLineItem.create();
    try {
      res = await this.self.createLineItem(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async GetLineItem(req: TransactionAccountsReceivableLineItem) {
    let res = TransactionAccountsReceivableLineItem.create();
    try {
      res = await this.self.getLineItem(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async UpdateLineItem(req: TransactionAccountsReceivableLineItem) {
    let res = TransactionAccountsReceivableLineItem.create();
    try {
      res = await this.self.updateLineItem(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async DeleteLineItem(req: TransactionAccountsReceivableLineItem) {
    let res = TransactionAccountsReceivableLineItem.create();
    try {
      res = await this.self.deleteLineItem(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async BatchGetLineItem(req: TransactionAccountsReceivableLineItem) {
    let res = TransactionAccountsReceivableLineItemList.create();
    try {
      res = await this.self.batchGetLineItem(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async CreatePayment(req: TransactionAccountsReceivablePayment) {
    let res = TransactionAccountsReceivablePayment.create();
    try {
      res = await this.self.createPayment(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async GetPayment(req: TransactionAccountsReceivablePayment) {
    let res = TransactionAccountsReceivablePayment.create();
    try {
      res = await this.self.getPayment(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async UpdatePayment(req: TransactionAccountsReceivablePayment) {
    let res = TransactionAccountsReceivablePayment.create();
    try {
      res = await this.self.updatePayment(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async DeletePayment(req: TransactionAccountsReceivablePayment) {
    let res = TransactionAccountsReceivablePayment.create();
    try {
      res = await this.self.deletePayment(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async BatchGetPayment(req: TransactionAccountsReceivablePayment) {
    let res = TransactionAccountsReceivablePaymentList.create();
    try {
      res = await this.self.batchGetPayment(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async CreateDocument(req: TransactionAccountsReceivableDocument) {
    let res = TransactionAccountsReceivableDocument.create();
    try {
      res = await this.self.createDocument(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async GetDocument(req: TransactionAccountsReceivableDocument) {
    let res = TransactionAccountsReceivableDocument.create();
    try {
      res = await this.self.getDocument(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async UpdateDocument(req: TransactionAccountsReceivableDocument) {
    let res = TransactionAccountsReceivableDocument.create();
    try {
      res = await this.self.updateDocument(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async DeleteDocument(req: TransactionAccountsReceivableDocument) {
    let res = TransactionAccountsReceivableDocument.create();
    try {
      res = await this.self.deleteDocument(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async BatchGetDocument(req: TransactionAccountsReceivableDocument) {
    let res = TransactionAccountsReceivableDocumentList.create();
    try {
      res = await this.self.batchGetDocument(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }
}

export {
  TransactionAccountsReceivableClient,
  TransactionAccountsReceivable,
  TransactionAccountsReceivableDocument,
  TransactionAccountsReceivableLine,
  TransactionAccountsReceivableLineItemList,
  TransactionAccountsReceivableDocumentList,
  TransactionAccountsReceivableLineItem,
  TransactionAccountsReceivableLineList,
  TransactionAccountsReceivableList,
  TransactionAccountsReceivablePayment,
  TransactionAccountsReceivablePaymentList,
};
