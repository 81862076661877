import { create, type StateCreator } from 'zustand';

// const hashStorage: PersistStorage<AdvancedSearchPaginationStore> = {
//   getItem: (key): string => {
//     const searchParams = new URLSearchParams(location.hash.slice(1))
//     const storedValue = searchParams.get(key) ?? ''
//     return (JSON.parse(storedValue) as StorageValue<AdvancedSearchPaginationStore>) || null
//   },
//   setItem: (key, newValue): void => {
//     const searchParams = new URLSearchParams(location.hash.slice(1))
//     searchParams.set(key, JSON.stringify(newValue))
//     location.hash = searchParams.toString()
//   },
//   removeItem: (key): void => {
//     const searchParams = new URLSearchParams(location.hash.slice(1))
//     searchParams.delete(key)
//     location.hash = searchParams.toString()
//   },
// }

export type AdvancedSearchPaginationStore = {
  page: number;
  setPage: (updater: number | ((currentPage: number) => number)) => void;
  totalEntries: number;
  setTotalEntriesCount: (updater: number | ((currentPage: number) => number)) => void;
};

// const getUrlSearch = () => {
//   return window.location.search.slice(1)
// }

// const persistentStorage: PersistStorage<AdvancedSearchPaginationStore> = {
//   getItem: (key): string => {
//     // Check URL first
//     if (getUrlSearch()) {
//       const searchParams = new URLSearchParams(getUrlSearch())
//       const storedValue = searchParams.get(key) || ''
//       return JSON.parse(storedValue)
//     } else {
//       // Otherwise, we should load from localstorage or alternative storage
//       return JSON.parse(localStorage.getItem(key) || '')
//     }
//   },
//   setItem: (key, newValue): void => {
//     // Check if query params exist at all, can remove check if always want to set URL
//     if (getUrlSearch()) {
//       const searchParams = new URLSearchParams(getUrlSearch())
//       searchParams.set(key, JSON.stringify(newValue))
//       window.history.replaceState(null, null, `?${searchParams.toString()}`)
//     }

//     localStorage.setItem(key, JSON.stringify(newValue))
//   },
//   removeItem: (key): void => {
//     const searchParams = new URLSearchParams(getUrlSearch())
//     searchParams.delete(key)
//     window.location.search = searchParams.toString()
//   },
// }

const paginationStore: StateCreator<AdvancedSearchPaginationStore, [], []> = (set) => ({
  page: 0,
  setPage: (updater: number | ((currentPage: number) => number)) => {
    set(
      typeof updater === 'number' ? { page: updater } : (state) => ({ page: updater(state.page) }),
    );
  },
  totalEntries: 0,
  setTotalEntriesCount: (updater) => {
    set(
      typeof updater === 'number'
        ? { totalEntries: updater }
        : (state) => ({ totalEntries: updater(state.totalEntries) }),
    );
  },
});

export const useAdvancedSearchPaginationStore =
  create<AdvancedSearchPaginationStore>()(paginationStore);
