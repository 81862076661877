import { zodResolver } from '@hookform/resolvers/zod';
import { type Reimbursement } from '@kalos/kalos-rpc';
import {
  Button,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  SimpleSelect,
} from '@kalos/ui';
import { useMemo } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import { z } from 'zod';

import { OPTION_ALL } from '../../../constants';
import { getWeekOptions } from '../../../tools/helpers';
import { type ZodObjectWithModelKeys } from '../../../tools/typeguargs';
import { REIMBURSEMENT_STATUSES, ReimbursementStatusLabel } from './ReimbursementStatusesLabel';

export type ReimbursementSearchFormFields = Pick<
  Reimbursement,
  'statusId' | 'description' | 'createdDate'
>;

const reimbursementSearchFormSchema = z.object({
  createdDate: z.string(),
  description: z.string(),
  statusId: z.coerce.number(),
}) satisfies ZodObjectWithModelKeys<ReimbursementSearchFormFields>;

export type ReimbursementSearchFormSchema = z.infer<typeof reimbursementSearchFormSchema>;

export const useReimbursementSearchForm = ({
  defaultValues,
}: {
  defaultValues: ReimbursementSearchFormSchema;
}) => {
  return useForm<ReimbursementSearchFormSchema>({
    defaultValues,
    resolver: zodResolver(reimbursementSearchFormSchema),
    mode: 'onChange',
  });
};

export const getDefaultReimbursementSearchFormValues = (
  data?: Partial<ReimbursementSearchFormSchema>,
): ReimbursementSearchFormSchema => {
  return {
    createdDate: OPTION_ALL,
    description: '',
    statusId: 0,
    ...data,
  };
};

const useReimbursementSearchFormContext = () => {
  return useFormContext<ReimbursementSearchFormSchema>();
};

const reimbursementStatusesSelectOptions: React.ComponentProps<typeof SimpleSelect>['values'] =
  REIMBURSEMENT_STATUSES.map((status) => ({
    label: <ReimbursementStatusLabel id={status.value} />,
    value: status.value.toString(),
  }));

export const ReimbursementSearchForm = () => {
  const form = useReimbursementSearchFormContext();

  const WEEK_OPTIONS = useMemo(() => {
    const weekOptions = getWeekOptions(52, 0, -1).map((week) => ({
      label: week.label,
      value: week.value.toString(),
    }));
    const allOption = { label: OPTION_ALL, value: OPTION_ALL };
    weekOptions.unshift(allOption);
    return weekOptions;
  }, []);

  const handleReset = () => {
    form.reset(getDefaultReimbursementSearchFormValues());
  };

  return (
    <div>
      <form className="grid grid-cols-1 gap-4 sm:grid-cols-3" onSubmit={console.log}>
        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Search Reimbursables</FormLabel>
              <FormMessage />
              <FormControl>
                <Input {...field} />
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="createdDate"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Week</FormLabel>
              <FormMessage />
              <FormControl>
                <SimpleSelect
                  values={WEEK_OPTIONS}
                  onChange={field.onChange}
                  placeholder={OPTION_ALL}
                  selectedValue={field.value}
                  disabled={field.disabled}
                />
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="statusId"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Status</FormLabel>
              <FormMessage />
              <FormControl>
                <SimpleSelect
                  values={reimbursementStatusesSelectOptions}
                  onChange={field.onChange}
                  placeholder={OPTION_ALL}
                  selectedValue={field.value.toString()}
                  disabled={field.disabled}
                />
              </FormControl>
            </FormItem>
          )}
        />
      </form>
      <div className="mt-4 flex justify-end">
        <Button onClick={handleReset}>Reset</Button>
      </div>
    </div>
  );
};
