import { zodResolver } from '@hookform/resolvers/zod';
import { type Task } from '@kalos/kalos-rpc';
import {
  DateInput,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  MoneyInput,
  Textarea,
} from '@kalos/ui';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { isMoneyValue, type SaveHandler } from '../../../tools/helpers';
import { type ZodObjectWithModelKeys } from '../../../tools/typeguargs';

type ToolExtendedFormFields = Pick<
  Task,
  'spiffToolId' | 'toolpurchaseDate' | 'toolpurchaseCost' | 'briefDescription'
>;

const toolExtendedFormSchema = z.object({
  spiffToolId: z.string(),
  toolpurchaseCost: z.coerce
    .number()
    .min(0.01, 'Please enter positive number (> 0.01)')
    .refine(isMoneyValue, 'Invalid money value'),
  toolpurchaseDate: z.date(),
  briefDescription: z.string().max(255, "This field can't contain more than 255 characters"),
}) satisfies ZodObjectWithModelKeys<ToolExtendedFormFields>;

type ToolExtendedFormSchema = z.infer<typeof toolExtendedFormSchema>;
type ToolExtendedFormProps = {
  defaultValues: ToolExtendedFormSchema;
  onSubmit: SaveHandler<ToolExtendedFormSchema>;
  disabled?: boolean;
  formId?: string;
};

export const ToolExtendedForm = ({
  defaultValues,
  onSubmit,
  disabled,
  formId,
}: ToolExtendedFormProps) => {
  const form = useForm<ToolExtendedFormSchema>({
    resolver: zodResolver(toolExtendedFormSchema),
    defaultValues,
    disabled,
  });

  const formSubmit = form.handleSubmit((data) =>
    onSubmit({ data, dirtyFields: form.formState.dirtyFields }),
  );
  return (
    <div className="@container">
      <form id={formId} className="@md:grid-cols-4 grid grid-cols-2 gap-2" onSubmit={formSubmit}>
        <Form {...form}>
          <FormField
            control={form.control}
            name="spiffToolId"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Spiff ID #</FormLabel>
                <FormControl>
                  <Input {...field} disabled />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="toolpurchaseCost"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Tool Cost</FormLabel>
                <FormControl>
                  <MoneyInput {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="toolpurchaseDate"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Purchase Date</FormLabel>
                <FormControl>
                  <DateInput {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="briefDescription"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Description</FormLabel>
                <FormControl>
                  <Textarea {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </Form>
      </form>
    </div>
  );
};
