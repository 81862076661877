import { type FC, type ReactNode } from 'react';

import { Confirm, type Props as ConfirmProps } from '../Confirm';

interface Props extends ConfirmProps {
  kind: string;
  name: string;
  children?: ReactNode;
  text?: string;
}

export const ConfirmDelete: FC<Props> = ({ name, kind, text, ...props }) => (
  <Confirm title="Confirm delete" {...props}>
    Are you sure you want to delete {kind}
    {name && <strong> {name}</strong>}?{text}
  </Confirm>
);
