import { type JobType } from '@kalos/kalos-rpc';
import { useQuery } from '@tanstack/react-query';

import { JobTypeClientService } from '../../tools/helpers';
import { queryKeys } from './constants';

export const useJobTypesQuery = <TSelectData = JobType[],>({
  enabled = true,
  select,
}: { enabled?: boolean; select?: (data: JobType[]) => TSelectData } = {}) => {
  return useQuery({
    queryKey: [queryKeys.jobTypes.root],
    queryFn: () => {
      return JobTypeClientService.loadJobTypes();
    },
    enabled,
    select,
  });
};
