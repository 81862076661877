// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "permit.proto" (syntax proto3)
// tslint:disable
import { PermitService } from "./permit";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service PermitService
 */
export class PermitServiceClient {
    constructor(_transport) {
        this._transport = _transport;
        this.typeName = PermitService.typeName;
        this.methods = PermitService.methods;
        this.options = PermitService.options;
    }
    /**
     * @generated from protobuf rpc: GetPermitStatus(PermitStatus) returns (PermitStatus);
     */
    getPermitStatus(input, options) {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetPermitStatus(Empty) returns (PermitStatusList);
     */
    batchGetPermitStatus(input, options) {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetInspectionStatus(InspectionStatus) returns (InspectionStatus);
     */
    getInspectionStatus(input, options) {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetInspectionStatus(Empty) returns (InspectionStatusList);
     */
    batchGetInspectionStatus(input, options) {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreatePermitGroup(PermitGroup) returns (PermitGroup);
     */
    createPermitGroup(input, options) {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetPermitGroup(PermitGroup) returns (PermitGroup);
     */
    getPermitGroup(input, options) {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetPermitGroup(PermitGroup) returns (PermitGroupList);
     */
    batchGetPermitGroup(input, options) {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdatePermitGroup(PermitGroup) returns (PermitGroup);
     */
    updatePermitGroup(input, options) {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeletePermitGroup(PermitGroup) returns (Empty);
     */
    deletePermitGroup(input, options) {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreatePermit(Permit) returns (Permit);
     */
    createPermit(input, options) {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetPermit(Permit) returns (Permit);
     */
    getPermit(input, options) {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetPermit(Permit) returns (PermitList);
     */
    batchGetPermit(input, options) {
        const method = this.methods[11], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdatePermit(Permit) returns (Permit);
     */
    updatePermit(input, options) {
        const method = this.methods[12], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeletePermit(Permit) returns (Empty);
     */
    deletePermit(input, options) {
        const method = this.methods[13], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateInspection(Inspection) returns (Inspection);
     */
    createInspection(input, options) {
        const method = this.methods[14], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetInspection(Inspection) returns (Inspection);
     */
    getInspection(input, options) {
        const method = this.methods[15], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetInspection(Inspection) returns (InspectionList);
     */
    batchGetInspection(input, options) {
        const method = this.methods[16], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateInspection(Inspection) returns (Inspection);
     */
    updateInspection(input, options) {
        const method = this.methods[17], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteInspection(Inspection) returns (Empty);
     */
    deleteInspection(input, options) {
        const method = this.methods[18], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetPermitTrade(PermitTrade) returns (PermitTrade);
     */
    getPermitTrade(input, options) {
        const method = this.methods[19], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetPermitTrade(PermitTrade) returns (PermitTradeList);
     */
    batchGetPermitTrade(input, options) {
        const method = this.methods[20], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreatePermitDocument(PermitDocument) returns (PermitDocument);
     */
    createPermitDocument(input, options) {
        const method = this.methods[21], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetPermitDocument(PermitDocument) returns (PermitDocument);
     */
    getPermitDocument(input, options) {
        const method = this.methods[22], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetPermitDocument(PermitDocument) returns (PermitDocumentList);
     */
    batchGetPermitDocument(input, options) {
        const method = this.methods[23], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdatePermitDocument(PermitDocument) returns (PermitDocument);
     */
    updatePermitDocument(input, options) {
        const method = this.methods[24], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeletePermitDocument(PermitDocument) returns (Empty);
     */
    deletePermitDocument(input, options) {
        const method = this.methods[25], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreatePermitGroupDocument(PermitGroupDocument) returns (PermitGroupDocument);
     */
    createPermitGroupDocument(input, options) {
        const method = this.methods[26], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetPermitGroupDocument(PermitGroupDocument) returns (PermitGroupDocument);
     */
    getPermitGroupDocument(input, options) {
        const method = this.methods[27], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetPermitGroupDocument(PermitGroupDocument) returns (PermitGroupDocumentList);
     */
    batchGetPermitGroupDocument(input, options) {
        const method = this.methods[28], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdatePermitGroupDocument(PermitGroupDocument) returns (PermitGroupDocument);
     */
    updatePermitGroupDocument(input, options) {
        const method = this.methods[29], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeletePermitGroupDocument(PermitGroupDocument) returns (Empty);
     */
    deletePermitGroupDocument(input, options) {
        const method = this.methods[30], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
}
