import './InfoTable.module.less';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import useTheme from '@mui/material/styles/useTheme';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { clsx } from 'clsx';
import { type CSSProperties, type ReactNode, useEffect, useReducer } from 'react';

import { useEvent } from '../../../hooks/useEvent';
import { type OrderDir } from '../../../tools/helpers';
import { Actions, type ActionsProps } from '../Actions';
import { type FieldType, type Options } from '../Field';
import { Link } from '../Link';
import { PlainForm, type Schema } from '../PlainForm';
import { ACTIONS, Reducer } from './reducer';

type Styles = {
  loading?: boolean;
  error?: boolean;
  compact?: boolean;
  hoverable?: boolean;
  ignoreImage?: boolean;
  showCreateOnly?: boolean;
};

export type Href = 'tel' | 'mailto';

export type Row = {
  label?: string;
  value: ReactNode;
  href?: Href;
  actions?: React.ReactNode[];
  onClick?: () => void;
  invisible?: boolean;
  align?: 'flex-start' | 'center' | 'flex-end';
  className?: string;
}[];

export type Data = Row[];

export type Columns = {
  name: ReactNode;
  width?: number;
  dir?: OrderDir;
  onClick?: () => void;
  actions?: ActionsProps;
  fixedActions?: boolean;
  align?: 'left' | 'center' | 'right';
  invisible?: boolean;
}[];

interface Props extends Styles {
  columns?: Columns;
  data?: Data;
  styles?: CSSProperties;
  className?: string;
  skipPreLine?: boolean;
  onEnter?: () => void;
  onNotify?: (notifyValue: boolean) => void;
  /** @deprecated */
  // eslint-disable-next-line @typescript-eslint/ban-types
  onSaveRowButton?: (results: {}) => any;
  // row button
  /** @deprecated */
  rowButton?: {
    // Type to use with row button (new Transaction(), new PerDiem(), etc.)
    type: any;
    // Information about the columns to use
    columnDefinition: {
      columnsToIgnore: string[];
      columnTypeOverrides: {
        columnName: string;
        columnType: FieldType;
        options?: Options;
        required?: boolean;
        onBlur?: (value: any) => void;
      }[];
    };
    onNotify?: (notifyValue: number) => void;
    externalButton?: boolean;
    externalButtonClicked?: boolean; // Was an external button clicked that triggers this? (While true, makes the row appear)
    onFileLoad?: (fileData: any) => any;
    disable?: boolean;
    maxFieldsPerRow?: number;
    resetData?: number;
  };
  maxHeight?: string | number | undefined;
  isSearched?: boolean;
}

// eslint-disable-next-line @typescript-eslint/ban-types
let temporaryResult: {}; // The result assigned when the onChange is fired.

export const InfoTable = ({
  columns = [],
  data,
  loading = false,
  error = false,
  compact = false,
  onEnter,
  hoverable = true,
  skipPreLine = false,
  className = '',
  styles,
  onSaveRowButton = () => {},
  rowButton,
  ignoreImage = false,
  showCreateOnly = false,
  maxHeight = undefined,
  isSearched = true,
}: Props) => {
  const onSaveRowButtonEvent = useEvent(onSaveRowButton);
  const [state, dispatch] = useReducer(Reducer, {
    isAddingRow: false,
  });
  if (rowButton !== undefined && columns.length === 0) {
    console.error(
      `addRowButton requires the columns to be defined. This is a no-op, but there will be no addRowButton. `,
    );
  }
  const theme = useTheme();

  const md = useMediaQuery(theme.breakpoints.down('sm'));
  // eslint-disable-next-line @typescript-eslint/ban-types
  const fields: {} = {};
  let schema: any[] = [];

  if (state.isAddingRow) {
    columns.forEach((col) => {
      if (
        !rowButton?.columnDefinition.columnsToIgnore.includes(col.name!.toString()) &&
        !col.invisible
      ) {
        rowButton?.columnDefinition.columnTypeOverrides.forEach((override) => {
          // Default values for fields
          if (override.columnType === 'multiselect') {
            (fields as any)[col.name as any] = [];
          } else {
            (fields as any)[col.name as any] = '';
          }
        });
      }
    });
    const fieldSchemas: Schema<typeof fields> = [].concat.apply(
      Object.keys(fields).map((field: any) => {
        const columnType = rowButton?.columnDefinition.columnTypeOverrides.filter(
          (type) => type.columnName === field,
        );
        return {
          label: field,
          name: field,
          required: columnType![0].required,
          onBlur:
            columnType?.length === 1
              ? columnType![0].onBlur
                ? columnType![0].onBlur
                : undefined
              : undefined,
          type: columnType?.length === 1 ? columnType![0].columnType : 'text',
          options:
            columnType?.length === 1
              ? columnType![0].options
                ? columnType![0].options
                : undefined
              : undefined,
        };
      }),
    );
    const buttonSchema = [
      {
        label: 'Add Image / Document',
        name: 'image',
        invisible: ignoreImage,
        type: 'file',
        onFileLoad: (data: string) => {
          if (rowButton) {
            if (rowButton.onFileLoad) rowButton.onFileLoad(data);
          }
        },
        actions: [
          {
            label: 'Create',
            onClick: () => {
              dispatch({
                type: ACTIONS.SET_IS_ADDING_ROW,
                payload: false,
              });
              onSaveRowButtonEvent?.(temporaryResult || (fields as typeof temporaryResult));
            },
          },
        ],
        disabled: rowButton?.disable,
      } as any,
      {
        label: 'Create',
        invisible: !showCreateOnly,
        actions: [
          {
            label: 'Create',
            onClick: () => {
              dispatch({
                type: ACTIONS.SET_IS_ADDING_ROW,
                payload: false,
              });
              onSaveRowButtonEvent?.(temporaryResult);
            },
          },
        ],
      },
    ];

    if (rowButton && rowButton.maxFieldsPerRow && fieldSchemas.length > rowButton.maxFieldsPerRow) {
      const rowCount = Math.ceil(fieldSchemas.length / rowButton.maxFieldsPerRow);
      const columnCount = Math.ceil(fieldSchemas.length / rowCount);
      console.log(columnCount);
      for (let i = 0; i < fieldSchemas.length; i += columnCount) {
        schema.push(fieldSchemas.slice(i, i + columnCount));
      }
      schema.push(buttonSchema);
    } else {
      schema = [fieldSchemas, buttonSchema];
    }
  }

  useEffect(() => {
    // @ts-ignore
    if (onSaveRowButtonEvent) temporaryResult = undefined;
  }, [rowButton?.resetData, onSaveRowButtonEvent]);

  return (
    <div className={clsx('InfoTable', className)} style={styles} key={state.toString()}>
      {columns.length > 0 && (
        <div className="InfoTableHeader">
          {columns.map(
            (
              { name, dir, onClick, actions, fixedActions, width, align = 'left', invisible },
              idx,
            ) => {
              if (invisible) return null;
              if (
                rowButton?.externalButton &&
                rowButton?.externalButtonClicked &&
                !state.isAddingRow
              ) {
                dispatch({
                  type: ACTIONS.SET_IS_ADDING_ROW,
                  payload: true,
                });
              }
              if (
                rowButton?.externalButton &&
                rowButton?.externalButtonClicked == false &&
                state.isAddingRow
              ) {
                dispatch({
                  type: ACTIONS.SET_IS_ADDING_ROW,
                  payload: false,
                });
              }
              if (
                rowButton !== undefined &&
                idx === columns.length - 1 &&
                !rowButton.externalButton
              ) {
                if (actions === undefined) actions = [];
                actions.push({
                  label: 'Add New Row',
                  onClick: () =>
                    dispatch({
                      type: ACTIONS.SET_IS_ADDING_ROW,
                      payload: true,
                    }),
                });
              }
              const ArrowIcon = dir === 'DESC' ? ArrowDropDownIcon : ArrowDropUpIcon;
              // console.log({ dir, name })
              return (
                <Typography
                  key={idx}
                  className="InfoTableColumn"
                  sx={{
                    width: md
                      ? '100%'
                      : width || `${100 / columns.filter((column) => !column.invisible).length}%`,
                    flexGrow: md || width === -1 ? 1 : 0,
                    flexShrink: width && width! > -1 ? 0 : 1,
                  }}
                  component="div"
                >
                  <span
                    onClick={onClick}
                    className="InfoTableDir"
                    style={{
                      cursor: onClick ? 'pointer' : 'default',
                      justifyContent:
                        md || align === 'left'
                          ? 'flex-start'
                          : align === 'right'
                            ? 'flex-end'
                            : 'center',
                    }}
                  >
                    {name} {dir && <ArrowIcon />}
                  </span>
                  {/* ! This action can be appended to above via the actions variable to add the "Add New" button in */}
                  {actions && <Actions actions={actions} fixed={fixedActions} />}
                </Typography>
              );
            },
          )}
        </div>
      )}
      {state.isAddingRow && (
        <PlainForm<typeof fields>
          onChange={(fieldOutput) => (temporaryResult = fieldOutput)}
          schema={schema}
          data={fields}
        />
      )}
      <div
        style={{ maxHeight: maxHeight, overflowY: maxHeight !== undefined ? 'scroll' : undefined }}
      >
        {data &&
          data.map((items, idx) => (
            <div
              key={idx}
              onKeyUp={(event) => {
                if (event.key === 'Enter' && onEnter) {
                  console.log('Info table enter');
                  event.stopPropagation();
                  event.preventDefault();
                  onEnter();
                }
              }}
              className={clsx('InfoTableRow', { compact, hoverable })}
            >
              {items.map(
                (
                  {
                    label,
                    value,
                    href,
                    actions = [],
                    onClick,
                    invisible,
                    align: propsAlign,
                    className,
                  },
                  idx2,
                ) => {
                  if (invisible) return null;
                  const align = columns && columns[idx2] ? columns[idx2].align || 'left' : 'left';

                  return (
                    <Typography
                      key={idx2}
                      className={clsx('InfoTableItem', { compact }, className)}
                      component="div"
                      sx={{
                        width: md
                          ? '100%'
                          : columns && columns[idx2] && columns[idx2].width
                            ? columns[idx2].width
                            : `${100 / items.filter((item) => !item.invisible).length}%`,
                        flexGrow:
                          md || (columns && columns[idx2] && columns[idx2].width === -1) ? 1 : 0,
                        flexShrink:
                          columns &&
                          columns[idx2] &&
                          columns[idx2].width &&
                          columns[idx2].width! > -1
                            ? 0
                            : 1,
                        cursor: onClick ? 'pointer' : 'default',
                        justifyContent:
                          md || align === 'left'
                            ? 'flex-start'
                            : align === 'right'
                              ? 'flex-end'
                              : 'center',
                      }}
                      onClick={loading || error ? undefined : onClick}
                    >
                      {label && <strong className="InfoTableLabel">{label}: </strong>}
                      {loading || error ? (
                        <span className="InfoTableFake" />
                      ) : (
                        <div
                          className="InfoTableValue"
                          style={{
                            whiteSpace: skipPreLine ? 'initial' : 'pre-line',
                            justifyContent: propsAlign || 'space-between',
                          }}
                        >
                          {href ? (
                            <Link href={`${href}:${value}`}>{value}</Link>
                          ) : (
                            <div
                              className="InfoTableValueContent"
                              style={{
                                textAlign: md
                                  ? 'left'
                                  : columns[idx2]
                                    ? columns[idx2].align || 'left'
                                    : 'left',
                              }}
                            >
                              {value}
                            </div>
                          )}
                          {actions && (
                            <span
                              className="Table-Actions flex flex-[0_1_auto] flex-wrap items-start justify-center"
                              onClick={(event) => event.stopPropagation()}
                            >
                              {actions}
                            </span>
                          )}
                        </div>
                      )}
                    </Typography>
                  );
                },
              )}
            </div>
          ))}
      </div>
      {!loading && !error && isSearched && !data?.length && (
        <div className={clsx('InfoTableRow', { compact, hoverable })}>
          <Typography className="InfoTableNoEntries">No entries found.</Typography>
        </div>
      )}
      {error && (
        <div className="InfoTableError">
          <Typography className="InfoTableErrorText">Error loading data</Typography>
        </div>
      )}
    </div>
  );
};
