import { type User } from '@kalos/kalos-rpc';
import { Button, ScrollArea, Sheet, SheetContent, SheetTrigger } from '@kalos/ui';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import {
  AccessibilityIcon,
  ArchiveIcon,
  BackpackIcon,
  BarChartIcon,
  BookmarkIcon,
  BoxModelIcon,
  CalendarIcon,
  CameraIcon,
  ChatBubbleIcon,
  ClipboardIcon,
  ClockIcon,
  CounterClockwiseClockIcon,
  CubeIcon,
  EnvelopeClosedIcon,
  ExitIcon,
  FileIcon,
  FileTextIcon,
  GroupIcon,
  HamburgerMenuIcon,
  HomeIcon,
  MagnifyingGlassIcon,
  PersonIcon,
  ReaderIcon,
  ReloadIcon,
  SewingPinFilledIcon,
  StackIcon,
  TransformIcon,
} from '@radix-ui/react-icons';
import { Bug, Car, CircleUser, HammerIcon, Headset, ShieldIcon, TentIcon } from 'lucide-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { useAuth } from '../../context/AuthContext';
import { employeesRoute, lodgingAdminUrl, permitAdminRoute } from '../../Router';
import { type LocalStorage } from '../../tools/storageManager';
import { Modal } from '../ComponentsLibrary/Modal';
import { UploadPhoto } from '../ComponentsLibrary/UploadPhoto';
import { UploadPhotoOCR } from '../ComponentsLibrary/UploadPhotoOCR';
import customTheme from '../Theme/main';
import KalosMenuItem from './components/KalosMenuItem';
export type SideMenuProps = {
  imgURL?: string;
};

export type MenuItem = NavOption | SubMenu;

export type SubMenu = {
  heading: React.ReactNode;
  localStorageKey: keyof LocalStorage['sideMenuDropdownState'];
  items: NavOption[];
};

export type NavOption = {
  title: string;
  icon?: JSX.Element;
} & (
  | {
      route: string;
      onClick?: () => void;
    }
  | {
      href: string;
      target: React.HTMLAttributeAnchorTarget;
      onClick?: () => void;
    }
  | {
      button: true;
      onClick: () => void;
    }
);

export const lodgingRequestsnUrl = '/lodgingRequests';
export const projectResourcesURL = '/projectResources';

const SideMenu = ({
  imgURL = 'https://kalos-web-assets.s3.amazonaws.com/kalos-logo-new.png',
}: SideMenuProps) => {
  const { user } = useAuth();
  const { pathname } = useLocation();

  const isLoggedIn = user.id !== 0;

  const isManager = useMemo(() => {
    return user.id ? managerCheck(user) : false;
  }, [user]);
  const isMemo = useMemo(() => {
    return user.id ? memoCheck(user) : false;
  }, [user]);

  const isFleetAdmin = useMemo(() => {
    return user.id ? fleetAdminCheck(user) : false;
  }, [user]);
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [openUploadReceipt, setOpenUploadReceipt] = useState<boolean>(false);
  const [openUploadReceiptOCR, setOpenUploadReceiptOCR] = useState<boolean>(false);

  useEffect(() => {
    setIsOpened(false);
  }, [pathname]);

  const toggleOpenUploadReceipt = useCallback(() => {
    setIsOpened(false);
    setOpenUploadReceipt(!openUploadReceipt);
  }, [setOpenUploadReceipt, openUploadReceipt]);
  const toggleOpenUploadReceiptOCR = useCallback(() => {
    setIsOpened(false);
    setOpenUploadReceiptOCR(!openUploadReceiptOCR);
  }, [setOpenUploadReceiptOCR, openUploadReceiptOCR]);

  const logo = <img src={imgURL} alt="no img" className="h-10" />;

  const isProjectEstimator = useMemo(
    () => user.permissionGroups.find((p) => p.name === 'ProjectEstimator'),
    [user.permissionGroups],
  );
  const isDispatch = useMemo(
    () => user.permissionGroups.find((p) => p.name === 'Dispatch'),
    [user.permissionGroups],
  );
  const isLodgingAdmin = useMemo(
    () => user.permissionGroups.find((p) => p.name === 'LodgingAdmin'),
    [user.permissionGroups],
  );
  const isPermitAdmin = useMemo(() => {
    return user.permissionGroups.find((p) => p.name === 'PermitAdmin');
  }, [user.permissionGroups]);

  const new_menuItems = useMemo<MenuItem[]>(() => {
    const adminSectionItems: MenuItem[] = [
      {
        items: [
          ...(user.isAdmin === 1
            ? ([
                {
                  title: 'Reports',
                  route: '/reports',
                  icon: <BookmarkIcon />,
                },
                {
                  title: 'Payroll Dashboard',
                  route: '/payroll',
                  icon: <BarChartIcon />,
                },
                {
                  title: 'Service Billing',
                  route: '/callsPending',
                  icon: <EnvelopeClosedIcon />,
                },

                ...(isManager
                  ? [
                      {
                        title: 'Receipt Review',
                        route: '/transactionAdmin',
                        icon: <ReaderIcon />,
                      },
                    ]
                  : []),
                ...(isLodgingAdmin
                  ? [
                      {
                        icon: <TentIcon size={15} />,
                        title: 'Lodging Admin',
                        route: lodgingAdminUrl,
                      },
                    ]
                  : []),
                ...(isPermitAdmin
                  ? [
                      {
                        icon: <BoxModelIcon />,
                        title: 'Permit Admin',
                        route: permitAdminRoute,
                      },
                    ]
                  : []),
                ...(isFleetAdmin
                  ? [
                      {
                        icon: <Car className="size-4" />,
                        title: 'Fleet Admin',
                        route: '/vehicles',
                      },
                    ]
                  : []),
              ] satisfies NavOption[])
            : []),
        ],
        heading: (
          <div className="flex items-center gap-2">
            <ShieldIcon className="size-4" />
            <span>Admin Resources</span>
          </div>
        ),
        localStorageKey: 'admin',
      },
    ];
    const resourcesSectionItems: MenuItem[] = [
      {
        items: [
          {
            title: 'HR Dashboard',
            route: '/hrDashboard',
            icon: <ArchiveIcon />,
          },
          ...(isMemo ? [{ title: 'Notes', route: '/notes', icon: <ArchiveIcon /> }] : []),

          {
            title: 'Accounts Payable',
            route: '/accountsPayable',
            icon: <BackpackIcon />,
          },
          {
            title: 'Employee Directory',
            route: employeesRoute,
            icon: <PersonIcon />,
          },
          {
            title: 'Organization Chart',
            href: 'https://docs.google.com/presentation/d/18npkNMXy7xlLw8n8MtN7eWpGW4r_8oHf6FP35Bd69FE/edit?usp=drive_link',
            target: '_blank',
            icon: <GroupIcon />,
          },
          {
            title: 'Kalos Documents',
            route: '/documents',
            icon: <StackIcon />,
          },
          {
            title: 'Project Resources',
            route: projectResourcesURL,
            icon: <SewingPinFilledIcon />,
          },
        ],
        heading: (
          <div className="flex items-center gap-2">
            <ArchiveIcon className="size-4" />
            <span>Resources</span>
          </div>
        ),
        localStorageKey: 'resources',
      },
    ];
    const toolsSectionItems: MenuItem[] = [
      {
        items: [
          ...(isProjectEstimator
            ? [
                {
                  icon: <TransformIcon />,
                  title: 'Project Estimator',
                  route: '/projects/estimates',
                },
              ]
            : []),
          {
            title: 'ThoughtBox',
            href: 'https://www.kalosflorida.com/thoughtbox',
            target: '_self',
            icon: <ChatBubbleIcon />,
          },
        ],
        heading: (
          <div className="flex items-center gap-2">
            <HammerIcon className="size-4" />
            <span>Tools</span>
          </div>
        ),
        localStorageKey: 'tools',
      },
    ];
    const dispatchSectionItems: MenuItem[] = [
      {
        items: [
          {
            title: 'Dispatch',
            route: '/dispatch',
            icon: <ClipboardIcon />,
          },
          {
            title: 'First Call',
            route: '/firstCalls',
            icon: <AccessibilityIcon />,
          },
        ],
        heading: (
          <div className="flex items-center gap-2">
            <Headset className="size-4" />
            <span>Dispatch/First Call</span>
          </div>
        ),
        localStorageKey: 'dispatch',
      },
    ];
    const profileSectionItems: MenuItem[] = [
      {
        items: [
          {
            title: 'Timesheet',
            route: `/timesheet/${user.id}`,
            icon: <ClockIcon />,
          },
          {
            title: 'Spiff Log',
            route: '/spiffLog',
            icon: <FileIcon />,
          },
          {
            title: 'Tool Log',
            route: '/toolLog',
            icon: <FileTextIcon />,
          },
          {
            title: 'Reimbursements',
            route: '/reimbursements',
            icon: <ReloadIcon />,
          },
          {
            title: 'Upload Photo OCR',
            icon: <CameraIcon />,
            onClick: toggleOpenUploadReceiptOCR,
            button: true,
          },

          {
            title: 'Receipts',
            route: '/transactions',
            icon: <ReaderIcon />,
          },
          {
            title: 'Per Diem',
            route: '/perdiem',
            icon: <CounterClockwiseClockIcon />,
          },
        ],
        heading: (
          <div className="flex items-center gap-2">
            <CircleUser className="size-4" />
            <span>Profile</span>
          </div>
        ),
        localStorageKey: 'profile',
      },
    ];

    const searchItem: MenuItem = {
      localStorageKey: 'search',
      heading: (
        <div className="flex items-center gap-2">
          <MagnifyingGlassIcon className="size-4" />
          <span>Search</span>
        </div>
      ),
      items: [
        {
          title: 'Job Search',
          route: '/jobs',
          icon: <CubeIcon />,
        },
        {
          title: 'Customer Directory',
          route: '/search',
          icon: <MagnifyingGlassIcon />,
        },
      ],
    };

    const canShowAdminSection =
      user.isAdmin === 1 || isLodgingAdmin || isPermitAdmin || isFleetAdmin;

    const result: MenuItem[] = [
      ...(user.isEmployee === 1
        ? ([
            {
              title: 'Dashboard',
              route: '/home',
              icon: <HomeIcon />,
            },
            ...profileSectionItems,
            {
              title: 'Service Calendar',
              route: '/calendar',
              icon: <CalendarIcon />,
            },

            searchItem,
          ] satisfies MenuItem[])
        : []),

      ...(isDispatch ? dispatchSectionItems : []),

      ...(canShowAdminSection ? adminSectionItems : []),
      ...resourcesSectionItems,

      ...toolsSectionItems,

      {
        title: 'Report a bug',
        href: 'https://mail.google.com/chat/u/0/#chat/space/AAAAdKGgEU4',
        target: '_blank',
        icon: <Bug size={15} />,
      } satisfies MenuItem,

      {
        title: 'Logout',
        route: '/logout',
        icon: <ExitIcon />,
      },
    ];

    return result;
  }, [
    isDispatch,
    isFleetAdmin,
    isLodgingAdmin,
    isManager,
    isMemo,
    isPermitAdmin,
    isProjectEstimator,
    toggleOpenUploadReceiptOCR,
    user.id,
    user.isAdmin,
    user.isEmployee,
  ]);

  return (
    <ThemeProvider theme={customTheme.lightTheme}>
      <div className="my-auto flex items-center gap-4  pl-4">
        {isLoggedIn && (
          <Sheet open={isOpened} onOpenChange={setIsOpened}>
            <SheetTrigger asChild>
              <Button className="flex items-center gap-2" variant="secondary">
                <span>Menu</span> <HamburgerMenuIcon />
              </Button>
            </SheetTrigger>
            <SheetContent side="left" className="w-[15rem] p-0 md:p-0">
              <ScrollArea className="h-full">
                <ul className="space-y-2 p-4 pr-6 pt-8">
                  {new_menuItems.map((item, idx) => (
                    <KalosMenuItem key={idx} item={item} />
                  ))}
                </ul>
              </ScrollArea>
            </SheetContent>
          </Sheet>
        )}
        {isLoggedIn ? <Link to="/home">{logo}</Link> : logo}

        {openUploadReceipt && (
          <Modal open onClose={toggleOpenUploadReceipt}>
            <UploadPhoto
              title="Upload Photo"
              bucket="kalos-pre-transactions"
              onClose={toggleOpenUploadReceipt}
              loggedUserId={user.id}
            />
          </Modal>
        )}
        {openUploadReceiptOCR && (
          <Modal open onClose={toggleOpenUploadReceiptOCR}>
            <UploadPhotoOCR title="Upload Photo OCR" onClose={toggleOpenUploadReceiptOCR} />
          </Modal>
        )}
      </div>
    </ThemeProvider>
  );
};

function managerCheck(user: User) {
  return (
    user.permissionGroups.map((p) => p.name).includes('Manager') ||
    user.isSU === 1 ||
    user.isAdmin == 1
  );
}
function memoCheck(user: User) {
  return (
    user.permissionGroups.map((p) => p.name).includes('MemoAdmin') ||
    user.permissionGroups.map((p) => p.name).includes('CommunicationTeam')
  );
}

function fleetAdminCheck(user: User) {
  return user.permissionGroups.map((p) => p.name).includes('FleetAdmin');
}
export default SideMenu;
