import { BaseClient } from '../BaseClient';
import {
  TransactionAccount,
  TransactionAccountList,
} from '../compiled-protos/transaction';
import { TransactionServiceClient } from '../compiled-protos/transaction.client';

class TransactionAccountClient extends BaseClient {
  self: TransactionServiceClient;
  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new TransactionServiceClient(this.transport);
  }

  public async Create(req: TransactionAccount) {
    let res = TransactionAccount.create();
    try {
      res = await this.self.createTransactionAccount(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Get(req: TransactionAccount) {
    return await this.self.getTransactionAccount(req, this.getMetaData())
      .response;
  }

  public async Update(req: TransactionAccount) {
    let res = TransactionAccount.create();
    try {
      res = await this.self.updateTransactionAccount(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Delete(req: TransactionAccount) {
    let res = TransactionAccount.create();
    try {
      res = await this.self.deleteTransactionAccount(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async BatchGet(req: TransactionAccount) {
    return await this.self.batchGetTransactionAccount(req, this.getMetaData())
      .response;
  }
}

export { TransactionAccount, TransactionAccountList, TransactionAccountClient };
