import { BaseClient } from '../BaseClient';
import {
  Property,
  PropertyCoordinates,
  PropertyList,
  SubAccountPropertiesRequest,
} from '../compiled-protos/property';
import { PropertyServiceClient } from '../compiled-protos/property.client';

class PropertyClient extends BaseClient {
  self: PropertyServiceClient;

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new PropertyServiceClient(this.transport);
  }

  public async Create(req: Property) {
    let res = Property.create();
    try {
      res = await this.self.create(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async GetResidentialPropertyCoordinates() {
    const req = Property.create();
    req.state = 'FL';
    req.groupBy = 'user_id';
    req.isActive = 1;
    req.isResidential = 1;
    req.withoutLimit = true;
    let res = PropertyCoordinates.create();
    try {
      res = await this.self.getPropertyCoordinates(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  /**
   * Returns a list of properties by sub account id
   * @param id the sub account id
   * @returns
   */
  public async GetSubAccountProperties(id: number) {
    const req = SubAccountPropertiesRequest.create({ subAccountId: id });
    let res = PropertyList.create();
    try {
      res = await this.self.fetchSubAccountProperties(SubAccountPropertiesRequest.create({subAccountId:id}), this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Get(req: Property) {
    let res = Property.create();
    try {
      res = await this.self.get(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Customer_Get({ request, authToken }: { request: Property, authToken: string }) {
    return await this.self.get(request, this.getMetaData(authToken)).response;
  }
  public async Customer_BatchGet({ request, authToken }: { request: Property, authToken: string }) {
    return await this.self.batchGet(request, this.getMetaData(authToken)).response;
  }


  public async Update(req: Property) {
    let res = Property.create();
    try {
      res = await this.self.update(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Delete(req: Property) {
    let res = Property.create();
    try {
      res = await this.self.delete(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async BatchGet(req: Property) {
    let res = PropertyList.create();
    try {
      res = await this.self.batchGet(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  /**
   * Returns loaded Property by its ids
   * @param id: property id
   * @returns Property
   */
  public async loadPropertyByID(id: number) {
    const req = Property.create();
    req.id = id;
    req.isActive = 1;
    return await this.Get(req);
  }

  public deletePropertyById = async (id: number) => {
    const req = Property.create();
    req.id = id;
    await this.Delete(req);
  };

  public saveProperty = async (
    data: Property,
    userId: number,
    propertyId?: number
  ) => {
    data.userId = userId;
    if (propertyId) {
      data.id = propertyId;
    }
    if (propertyId !== 0 && data.fieldMask.length === 0) {
      throw new Error(
        'Attempting to update entity without providing a field mask will result in a no op'
      );
    }
    return await this[propertyId ? 'Update' : 'Create'](data);
  };

  public Customer_getSubAccountProperties = async ({ req, authToken }: { req: SubAccountPropertiesRequest, authToken: string }) => {
    return await this.self.fetchSubAccountProperties(req, this.getMetaData(authToken)).response;
  }
}

const getPropertyAddress = (p?: Property): string =>
  p ? `${p.address}, ${p.city}, ${p.state} ${p.zip}` : '';

export { Property, PropertyList, PropertyClient, getPropertyAddress };
