import { BaseClient } from '../BaseClient';
import { QuoteLinePart, QuoteLinePartList } from '../compiled-protos/quote';
import { QuoteServiceClient as QuoteLinePartServiceClient } from '../compiled-protos/quote.client';

class QuoteLinePartClient extends BaseClient {
  self: QuoteLinePartServiceClient;

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new QuoteLinePartServiceClient(this.transport);
  }

  public async Create(req: QuoteLinePart) {
    let res = QuoteLinePart.create();
    try {
      res = await this.self.createQuoteLinePart(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return
    }
    return res;
  }

  public async Get(req: QuoteLinePart) {
    let res = QuoteLinePart.create();
    try {
      res = await this.self.getQuoteLinePart(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return
    }
    return res;
  }

  public async Update(req: QuoteLinePart) {
    let res = QuoteLinePart.create();
    try {
      res = await this.self.updateQuoteLinePart(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return
    }
    return res;
  }

  public async Delete(req: QuoteLinePart) {
    let res = QuoteLinePart.create();
    try {
      res = await this.self.deleteQuoteLinePart(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return
    }
    return res;
  }

  public async BatchGet(req: QuoteLinePart) {
    let res = QuoteLinePartList.create();
    try {
      res = await this.self.batchGetQuoteLinePart(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return
    }
    return res;
  }

  /**
   * Returns loaded QuoteLineParts
   * @returns QuoteLinePart[]
   */
  public async loadQuoteLineParts() {
    const results: QuoteLinePart[] = [];
    const req = QuoteLinePart.create();
    req.pageNumber = 0;
    const res = await this.BatchGet(req);
    if (res) {
      const resultsList = res.results;
      const totalCount = res.totalCount;
      const len = resultsList.length;
      results.concat(resultsList);
      if (totalCount > len) {
        const batchesAmount = Math.ceil((totalCount - len) / len);
        const batchResults = await Promise.all(
          Array.from(Array(batchesAmount)).map(async (_, idx) => {
            req.pageNumber = idx + 1;
            return (await this.self.batchGetQuoteLinePart(req)).response
              .results;
          })
        );
        results.push(
          ...batchResults.reduce((aggr, item) => [...aggr, ...item], [])
        );
      }
      return results;
    }
    return undefined
  }
}

export { QuoteLinePart, QuoteLinePartList, QuoteLinePartClient };
