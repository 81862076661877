import { type PTO } from '@kalos/kalos-rpc';
import { useQuery } from '@tanstack/react-query';

import { TimeoffRequestClientService } from '../../tools/helpers';
import { queryKeys } from './constants';

export const usePTOQuery = ({ enabled = true }: { enabled?: boolean } = {}) => {
  return useQuery({
    queryKey: [queryKeys.pto.root, queryKeys.pto.inquiry, queryKeys.pto.list],
    queryFn: () => TimeoffRequestClientService.BatchGetPTOInquiry(),
    enabled,
  });
};

export const usePTOInquiryQuery = <TSelectData = PTO,>({
  select,
  userId,
  enabled = true,
}: {
  userId: number;
  enabled?: boolean;
  select?: (arg: PTO) => TSelectData;
}) => {
  return useQuery({
    queryKey: [queryKeys.pto.root, queryKeys.pto.inquiry, userId],
    queryFn: () => TimeoffRequestClientService.PTOInquiry(userId),
    enabled,
    select,
  });
};
