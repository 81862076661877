import './PrintList.module.less';

import { clsx } from 'clsx';
import { type FC, type ReactNode } from 'react';

export interface Props {
  items: ReactNode[];
  className?: string;
}

export const PrintList: FC<Props> = ({ items, className }) => (
  <ul className={clsx('PrintList', className)}>
    {items.map((item, idx) => (
      <li key={idx} className="PrintList_item">
        {item}
      </li>
    ))}
  </ul>
);
