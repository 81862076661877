import { PendingBilling, type PendingBillingList } from '@kalos/kalos-rpc';
import { useQuery } from '@tanstack/react-query';

import { PendingBillingClientService } from '../../tools/helpers';
import { queryKeys } from './constants';
import { type EntityFilter } from './utils';

export type PendingBillingFilter = EntityFilter<PendingBilling>;

export const usePendingBillingBatchGetQuery = <TSelectData = PendingBillingList,>({
  filter = {},
  enabled,
  select,
}: {
  filter?: PendingBillingFilter;
  enabled?: boolean;
  select?: (data?: PendingBillingList) => TSelectData;
} = {}) => {
  return useQuery({
    queryFn: () => {
      const req = PendingBilling.create(filter);
      return PendingBillingClientService.BatchGet(req);
    },
    queryKey: [
      queryKeys.pendingBilling.root,
      queryKeys.pendingBilling.list,
      queryKeys.pendingBilling.getHash(filter),
    ],
    enabled,
    select,
  });
};
