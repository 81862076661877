// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "google.proto" (syntax proto3)
// tslint:disable
import { GoogleService } from "./google";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service GoogleService
 */
export class GoogleServiceClient {
    constructor(_transport) {
        this._transport = _transport;
        this.typeName = GoogleService.typeName;
        this.methods = GoogleService.methods;
        this.options = GoogleService.options;
    }
    /**
     * @generated from protobuf rpc: CreateGoogleUser(GoogleUser) returns (GoogleUser);
     */
    createGoogleUser(input, options) {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetGoogleUser(GoogleUser) returns (GoogleUser);
     */
    getGoogleUser(input, options) {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetGoogleUser(GoogleUser) returns (GoogleUserList);
     */
    batchGetGoogleUser(input, options) {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateGoogleUser(GoogleUser) returns (GoogleUser);
     */
    updateGoogleUser(input, options) {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteGoogleUser(GoogleUser) returns (Empty);
     */
    deleteGoogleUser(input, options) {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UploadCSVToGoogleSheets(UploadCsvRequest) returns (UploadCsvResponse);
     */
    uploadCSVToGoogleSheets(input, options) {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateChatMessage(CreateMessageRequest) returns (Bool);
     */
    createChatMessage(input, options) {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetOAUTHURL(OAUTHRequest) returns (String);
     */
    getOAUTHURL(input, options) {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetOAUTHToken(OAUTHRequest) returns (String);
     */
    getOAUTHToken(input, options) {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: AuthenticateToken(Int32) returns (Bool);
     */
    authenticateToken(input, options) {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: RefreshCredentialsWithUserId(Int32) returns (String);
     */
    refreshCredentialsWithUserId(input, options) {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: RefreshToken(Empty) returns (String);
     */
    refreshToken(input, options) {
        const method = this.methods[11], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: HandleDirectMessage(DirectMessageRequest) returns (Empty);
     */
    handleDirectMessage(input, options) {
        const method = this.methods[12], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetChatMessage(ChatMessageRequest) returns (ChatMessageList);
     */
    batchGetChatMessage(input, options) {
        const method = this.methods[13], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateGoogleSpace(CreateGoogleSpaceRequest) returns (Empty);
     */
    createGoogleSpace(input, options) {
        const method = this.methods[14], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
}
