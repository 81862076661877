// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "customeracc.proto" (syntax proto3)
// tslint:disable
// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "customeracc.proto" (syntax proto3)
// tslint:disable
import { EmailAddress } from "./common";
import { IDInt64 } from "./common";
import { Empty } from "./common";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { WireType } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
// @generated message type with reflection information, may provide speed optimized methods
class RefreshTokenResponse$Type extends MessageType {
    constructor() {
        super("RefreshTokenResponse", [
            { no: 1, name: "access_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "refresh_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { accessToken: "", refreshToken: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string access_token */ 1:
                    message.accessToken = reader.string();
                    break;
                case /* string refresh_token */ 2:
                    message.refreshToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string access_token = 1; */
        if (message.accessToken !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.accessToken);
        /* string refresh_token = 2; */
        if (message.refreshToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.refreshToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message RefreshTokenResponse
 */
export const RefreshTokenResponse = new RefreshTokenResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CustomerAccountCredential$Type extends MessageType {
    constructor() {
        super("CustomerAccountCredential", [
            { no: 1, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "password", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { email: "", password: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string email */ 1:
                    message.email = reader.string();
                    break;
                case /* string password */ 2:
                    message.password = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string email = 1; */
        if (message.email !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.email);
        /* string password = 2; */
        if (message.password !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.password);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CustomerAccountCredential
 */
export const CustomerAccountCredential = new CustomerAccountCredential$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CustomerAccountSession$Type extends MessageType {
    constructor() {
        super("CustomerAccountSession", [
            { no: 1, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "access_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "refresh_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { email: "", accessToken: "", refreshToken: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string email */ 1:
                    message.email = reader.string();
                    break;
                case /* string access_token */ 2:
                    message.accessToken = reader.string();
                    break;
                case /* string refresh_token */ 3:
                    message.refreshToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string email = 1; */
        if (message.email !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.email);
        /* string access_token = 2; */
        if (message.accessToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.accessToken);
        /* string refresh_token = 3; */
        if (message.refreshToken !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.refreshToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CustomerAccountSession
 */
export const CustomerAccountSession = new CustomerAccountSession$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CustomerAccountVerifyRequest$Type extends MessageType {
    constructor() {
        super("CustomerAccountVerifyRequest", [
            { no: 1, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "code", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { email: "", code: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string email */ 1:
                    message.email = reader.string();
                    break;
                case /* string code */ 2:
                    message.code = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string email = 1; */
        if (message.email !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.email);
        /* string code = 2; */
        if (message.code !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.code);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CustomerAccountVerifyRequest
 */
export const CustomerAccountVerifyRequest = new CustomerAccountVerifyRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CustomerAccountList$Type extends MessageType {
    constructor() {
        super("CustomerAccountList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CustomerAccountResponse },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated CustomerAccountResponse results */ 1:
                    message.results.push(CustomerAccountResponse.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated CustomerAccountResponse results = 1; */
        for (let i = 0; i < message.results.length; i++)
            CustomerAccountResponse.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CustomerAccountList
 */
export const CustomerAccountList = new CustomerAccountList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CustomerAccountSearchRequest$Type extends MessageType {
    constructor() {
        super("CustomerAccountSearchRequest", [
            { no: 1, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "firstname", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "lastname", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "business_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "city", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "state", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "zip", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "phone", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "cellphone", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "fax", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "verified", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 13, name: "verified_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 30, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 31, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 32, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { email: "", firstname: "", lastname: "", businessName: "", city: "", state: "", zip: "", address: "", phone: "", cellphone: "", fax: "", verified: false, verifiedDate: "", fieldMask: [], pageNumber: 0, pageSize: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string email */ 1:
                    message.email = reader.string();
                    break;
                case /* string firstname */ 2:
                    message.firstname = reader.string();
                    break;
                case /* string lastname */ 3:
                    message.lastname = reader.string();
                    break;
                case /* string business_name */ 4:
                    message.businessName = reader.string();
                    break;
                case /* string city */ 5:
                    message.city = reader.string();
                    break;
                case /* string state */ 6:
                    message.state = reader.string();
                    break;
                case /* string zip */ 7:
                    message.zip = reader.string();
                    break;
                case /* string address */ 8:
                    message.address = reader.string();
                    break;
                case /* string phone */ 9:
                    message.phone = reader.string();
                    break;
                case /* string cellphone */ 10:
                    message.cellphone = reader.string();
                    break;
                case /* string fax */ 11:
                    message.fax = reader.string();
                    break;
                case /* bool verified */ 12:
                    message.verified = reader.bool();
                    break;
                case /* string verified_date */ 13:
                    message.verifiedDate = reader.string();
                    break;
                case /* repeated string field_mask */ 30:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 31:
                    message.pageNumber = reader.int32();
                    break;
                case /* int32 page_size */ 32:
                    message.pageSize = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string email = 1; */
        if (message.email !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.email);
        /* string firstname = 2; */
        if (message.firstname !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.firstname);
        /* string lastname = 3; */
        if (message.lastname !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.lastname);
        /* string business_name = 4; */
        if (message.businessName !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.businessName);
        /* string city = 5; */
        if (message.city !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.city);
        /* string state = 6; */
        if (message.state !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.state);
        /* string zip = 7; */
        if (message.zip !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.zip);
        /* string address = 8; */
        if (message.address !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.address);
        /* string phone = 9; */
        if (message.phone !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.phone);
        /* string cellphone = 10; */
        if (message.cellphone !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.cellphone);
        /* string fax = 11; */
        if (message.fax !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.fax);
        /* bool verified = 12; */
        if (message.verified !== false)
            writer.tag(12, WireType.Varint).bool(message.verified);
        /* string verified_date = 13; */
        if (message.verifiedDate !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.verifiedDate);
        /* repeated string field_mask = 30; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(30, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 31; */
        if (message.pageNumber !== 0)
            writer.tag(31, WireType.Varint).int32(message.pageNumber);
        /* int32 page_size = 32; */
        if (message.pageSize !== 0)
            writer.tag(32, WireType.Varint).int32(message.pageSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CustomerAccountSearchRequest
 */
export const CustomerAccountSearchRequest = new CustomerAccountSearchRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CustomerAccountResponse$Type extends MessageType {
    constructor() {
        super("CustomerAccountResponse", [
            { no: 1, name: "id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "firstname", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "lastname", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "business_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "city", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "state", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "zip", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "phone", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "cellphone", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "fax", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 14, name: "verified", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 15, name: "verified_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "created_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "updated_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 18, name: "sub_accounts", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SubAccount }
        ]);
    }
    create(value) {
        const message = { id: 0n, email: "", firstname: "", lastname: "", businessName: "", city: "", state: "", zip: "", address: "", phone: "", cellphone: "", fax: "", isActive: false, verified: false, verifiedDate: "", createdDate: "", updatedDate: "", subAccounts: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 id */ 1:
                    message.id = reader.int64().toBigInt();
                    break;
                case /* string email */ 2:
                    message.email = reader.string();
                    break;
                case /* string firstname */ 3:
                    message.firstname = reader.string();
                    break;
                case /* string lastname */ 4:
                    message.lastname = reader.string();
                    break;
                case /* string business_name */ 5:
                    message.businessName = reader.string();
                    break;
                case /* string city */ 6:
                    message.city = reader.string();
                    break;
                case /* string state */ 7:
                    message.state = reader.string();
                    break;
                case /* string zip */ 8:
                    message.zip = reader.string();
                    break;
                case /* string address */ 9:
                    message.address = reader.string();
                    break;
                case /* string phone */ 10:
                    message.phone = reader.string();
                    break;
                case /* string cellphone */ 11:
                    message.cellphone = reader.string();
                    break;
                case /* string fax */ 12:
                    message.fax = reader.string();
                    break;
                case /* bool is_active */ 13:
                    message.isActive = reader.bool();
                    break;
                case /* bool verified */ 14:
                    message.verified = reader.bool();
                    break;
                case /* string verified_date */ 15:
                    message.verifiedDate = reader.string();
                    break;
                case /* string created_date */ 16:
                    message.createdDate = reader.string();
                    break;
                case /* string updated_date */ 17:
                    message.updatedDate = reader.string();
                    break;
                case /* repeated SubAccount sub_accounts */ 18:
                    message.subAccounts.push(SubAccount.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int64 id = 1; */
        if (message.id !== 0n)
            writer.tag(1, WireType.Varint).int64(message.id);
        /* string email = 2; */
        if (message.email !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.email);
        /* string firstname = 3; */
        if (message.firstname !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.firstname);
        /* string lastname = 4; */
        if (message.lastname !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.lastname);
        /* string business_name = 5; */
        if (message.businessName !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.businessName);
        /* string city = 6; */
        if (message.city !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.city);
        /* string state = 7; */
        if (message.state !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.state);
        /* string zip = 8; */
        if (message.zip !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.zip);
        /* string address = 9; */
        if (message.address !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.address);
        /* string phone = 10; */
        if (message.phone !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.phone);
        /* string cellphone = 11; */
        if (message.cellphone !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.cellphone);
        /* string fax = 12; */
        if (message.fax !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.fax);
        /* bool is_active = 13; */
        if (message.isActive !== false)
            writer.tag(13, WireType.Varint).bool(message.isActive);
        /* bool verified = 14; */
        if (message.verified !== false)
            writer.tag(14, WireType.Varint).bool(message.verified);
        /* string verified_date = 15; */
        if (message.verifiedDate !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.verifiedDate);
        /* string created_date = 16; */
        if (message.createdDate !== "")
            writer.tag(16, WireType.LengthDelimited).string(message.createdDate);
        /* string updated_date = 17; */
        if (message.updatedDate !== "")
            writer.tag(17, WireType.LengthDelimited).string(message.updatedDate);
        /* repeated SubAccount sub_accounts = 18; */
        for (let i = 0; i < message.subAccounts.length; i++)
            SubAccount.internalBinaryWrite(message.subAccounts[i], writer.tag(18, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CustomerAccountResponse
 */
export const CustomerAccountResponse = new CustomerAccountResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CustomerAccount$Type extends MessageType {
    constructor() {
        super("CustomerAccount", [
            { no: 1, name: "id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "firstname", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "lastname", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "business_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "city", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "state", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "zip", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "phone", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "cellphone", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "fax", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 14, name: "verified", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 15, name: "verified_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "created_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "updated_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 18, name: "verification_link_sent_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 19, name: "verification_code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 20, name: "password", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 21, name: "salt", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 22, name: "legacy_account_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 50, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 51, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 52, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { id: 0n, email: "", firstname: "", lastname: "", businessName: "", city: "", state: "", zip: "", address: "", phone: "", cellphone: "", fax: "", isActive: false, verified: false, verifiedDate: "", createdDate: "", updatedDate: "", verificationLinkSentDate: "", verificationCode: "", password: "", salt: "", legacyAccountId: 0, fieldMask: [], pageNumber: 0, pageSize: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 id */ 1:
                    message.id = reader.int64().toBigInt();
                    break;
                case /* string email */ 2:
                    message.email = reader.string();
                    break;
                case /* string firstname */ 3:
                    message.firstname = reader.string();
                    break;
                case /* string lastname */ 4:
                    message.lastname = reader.string();
                    break;
                case /* string business_name */ 5:
                    message.businessName = reader.string();
                    break;
                case /* string city */ 6:
                    message.city = reader.string();
                    break;
                case /* string state */ 7:
                    message.state = reader.string();
                    break;
                case /* string zip */ 8:
                    message.zip = reader.string();
                    break;
                case /* string address */ 9:
                    message.address = reader.string();
                    break;
                case /* string phone */ 10:
                    message.phone = reader.string();
                    break;
                case /* string cellphone */ 11:
                    message.cellphone = reader.string();
                    break;
                case /* string fax */ 12:
                    message.fax = reader.string();
                    break;
                case /* bool is_active */ 13:
                    message.isActive = reader.bool();
                    break;
                case /* bool verified */ 14:
                    message.verified = reader.bool();
                    break;
                case /* string verified_date */ 15:
                    message.verifiedDate = reader.string();
                    break;
                case /* string created_date */ 16:
                    message.createdDate = reader.string();
                    break;
                case /* string updated_date */ 17:
                    message.updatedDate = reader.string();
                    break;
                case /* string verification_link_sent_date */ 18:
                    message.verificationLinkSentDate = reader.string();
                    break;
                case /* string verification_code */ 19:
                    message.verificationCode = reader.string();
                    break;
                case /* string password */ 20:
                    message.password = reader.string();
                    break;
                case /* string salt */ 21:
                    message.salt = reader.string();
                    break;
                case /* int32 legacy_account_id */ 22:
                    message.legacyAccountId = reader.int32();
                    break;
                case /* repeated string field_mask */ 50:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 51:
                    message.pageNumber = reader.int32();
                    break;
                case /* int32 page_size */ 52:
                    message.pageSize = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int64 id = 1; */
        if (message.id !== 0n)
            writer.tag(1, WireType.Varint).int64(message.id);
        /* string email = 2; */
        if (message.email !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.email);
        /* string firstname = 3; */
        if (message.firstname !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.firstname);
        /* string lastname = 4; */
        if (message.lastname !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.lastname);
        /* string business_name = 5; */
        if (message.businessName !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.businessName);
        /* string city = 6; */
        if (message.city !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.city);
        /* string state = 7; */
        if (message.state !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.state);
        /* string zip = 8; */
        if (message.zip !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.zip);
        /* string address = 9; */
        if (message.address !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.address);
        /* string phone = 10; */
        if (message.phone !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.phone);
        /* string cellphone = 11; */
        if (message.cellphone !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.cellphone);
        /* string fax = 12; */
        if (message.fax !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.fax);
        /* bool is_active = 13; */
        if (message.isActive !== false)
            writer.tag(13, WireType.Varint).bool(message.isActive);
        /* bool verified = 14; */
        if (message.verified !== false)
            writer.tag(14, WireType.Varint).bool(message.verified);
        /* string verified_date = 15; */
        if (message.verifiedDate !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.verifiedDate);
        /* string created_date = 16; */
        if (message.createdDate !== "")
            writer.tag(16, WireType.LengthDelimited).string(message.createdDate);
        /* string updated_date = 17; */
        if (message.updatedDate !== "")
            writer.tag(17, WireType.LengthDelimited).string(message.updatedDate);
        /* string verification_link_sent_date = 18; */
        if (message.verificationLinkSentDate !== "")
            writer.tag(18, WireType.LengthDelimited).string(message.verificationLinkSentDate);
        /* string verification_code = 19; */
        if (message.verificationCode !== "")
            writer.tag(19, WireType.LengthDelimited).string(message.verificationCode);
        /* string password = 20; */
        if (message.password !== "")
            writer.tag(20, WireType.LengthDelimited).string(message.password);
        /* string salt = 21; */
        if (message.salt !== "")
            writer.tag(21, WireType.LengthDelimited).string(message.salt);
        /* int32 legacy_account_id = 22; */
        if (message.legacyAccountId !== 0)
            writer.tag(22, WireType.Varint).int32(message.legacyAccountId);
        /* repeated string field_mask = 50; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(50, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 51; */
        if (message.pageNumber !== 0)
            writer.tag(51, WireType.Varint).int32(message.pageNumber);
        /* int32 page_size = 52; */
        if (message.pageSize !== 0)
            writer.tag(52, WireType.Varint).int32(message.pageSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CustomerAccount
 */
export const CustomerAccount = new CustomerAccount$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SubAccount$Type extends MessageType {
    constructor() {
        super("SubAccount", [
            { no: 1, name: "id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "main_account_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 3, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "firstname", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "lastname", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "business_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "username", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "password", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "created_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "updated_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "sub_account_privileges", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SubAccountPrivilege },
            { no: 7, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0n, mainAccountId: 0n, email: "", firstname: "", lastname: "", businessName: "", address: "", username: "", password: "", createdDate: "", updatedDate: "", subAccountPrivileges: [], fieldMask: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 id */ 1:
                    message.id = reader.int64().toBigInt();
                    break;
                case /* int64 main_account_id */ 2:
                    message.mainAccountId = reader.int64().toBigInt();
                    break;
                case /* string email */ 3:
                    message.email = reader.string();
                    break;
                case /* string firstname */ 4:
                    message.firstname = reader.string();
                    break;
                case /* string lastname */ 5:
                    message.lastname = reader.string();
                    break;
                case /* string business_name */ 8:
                    message.businessName = reader.string();
                    break;
                case /* string address */ 9:
                    message.address = reader.string();
                    break;
                case /* string username */ 10:
                    message.username = reader.string();
                    break;
                case /* string password */ 11:
                    message.password = reader.string();
                    break;
                case /* string created_date */ 12:
                    message.createdDate = reader.string();
                    break;
                case /* string updated_date */ 13:
                    message.updatedDate = reader.string();
                    break;
                case /* repeated SubAccountPrivilege sub_account_privileges */ 6:
                    message.subAccountPrivileges.push(SubAccountPrivilege.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated string field_mask */ 7:
                    message.fieldMask.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int64 id = 1; */
        if (message.id !== 0n)
            writer.tag(1, WireType.Varint).int64(message.id);
        /* int64 main_account_id = 2; */
        if (message.mainAccountId !== 0n)
            writer.tag(2, WireType.Varint).int64(message.mainAccountId);
        /* string email = 3; */
        if (message.email !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.email);
        /* string firstname = 4; */
        if (message.firstname !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.firstname);
        /* string lastname = 5; */
        if (message.lastname !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.lastname);
        /* string business_name = 8; */
        if (message.businessName !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.businessName);
        /* string address = 9; */
        if (message.address !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.address);
        /* string username = 10; */
        if (message.username !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.username);
        /* string password = 11; */
        if (message.password !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.password);
        /* string created_date = 12; */
        if (message.createdDate !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.createdDate);
        /* string updated_date = 13; */
        if (message.updatedDate !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.updatedDate);
        /* repeated SubAccountPrivilege sub_account_privileges = 6; */
        for (let i = 0; i < message.subAccountPrivileges.length; i++)
            SubAccountPrivilege.internalBinaryWrite(message.subAccountPrivileges[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* repeated string field_mask = 7; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(7, WireType.LengthDelimited).string(message.fieldMask[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SubAccount
 */
export const SubAccount = new SubAccount$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SubAccountList$Type extends MessageType {
    constructor() {
        super("SubAccountList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SubAccount },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated SubAccount results */ 1:
                    message.results.push(SubAccount.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated SubAccount results = 1; */
        for (let i = 0; i < message.results.length; i++)
            SubAccount.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SubAccountList
 */
export const SubAccountList = new SubAccountList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SubAccountPrivilege$Type extends MessageType {
    constructor() {
        super("SubAccountPrivilege", [
            { no: 1, name: "id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "sub_account_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 3, name: "property_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0n, subAccountId: 0n, propertyId: 0, fieldMask: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 id */ 1:
                    message.id = reader.int64().toBigInt();
                    break;
                case /* int64 sub_account_id */ 2:
                    message.subAccountId = reader.int64().toBigInt();
                    break;
                case /* int32 property_id */ 3:
                    message.propertyId = reader.int32();
                    break;
                case /* repeated string field_mask */ 4:
                    message.fieldMask.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int64 id = 1; */
        if (message.id !== 0n)
            writer.tag(1, WireType.Varint).int64(message.id);
        /* int64 sub_account_id = 2; */
        if (message.subAccountId !== 0n)
            writer.tag(2, WireType.Varint).int64(message.subAccountId);
        /* int32 property_id = 3; */
        if (message.propertyId !== 0)
            writer.tag(3, WireType.Varint).int32(message.propertyId);
        /* repeated string field_mask = 4; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.fieldMask[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SubAccountPrivilege
 */
export const SubAccountPrivilege = new SubAccountPrivilege$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SubAccountPrivilegeList$Type extends MessageType {
    constructor() {
        super("SubAccountPrivilegeList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SubAccountPrivilege },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated SubAccountPrivilege results */ 1:
                    message.results.push(SubAccountPrivilege.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated SubAccountPrivilege results = 1; */
        for (let i = 0; i < message.results.length; i++)
            SubAccountPrivilege.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SubAccountPrivilegeList
 */
export const SubAccountPrivilegeList = new SubAccountPrivilegeList$Type();
/**
 * @generated ServiceType for protobuf service CustomerAccountService
 */
export const CustomerAccountService = new ServiceType("CustomerAccountService", [
    { name: "SignUp", options: {}, I: CustomerAccountCredential, O: Empty },
    { name: "SignIn", options: {}, I: CustomerAccountCredential, O: CustomerAccountSession },
    { name: "Verify", options: {}, I: CustomerAccountVerifyRequest, O: Empty },
    { name: "Update", options: {}, I: CustomerAccount, O: Empty },
    { name: "Delete", options: {}, I: IDInt64, O: Empty },
    { name: "Get", options: {}, I: IDInt64, O: CustomerAccountResponse },
    { name: "GetByEmail", options: {}, I: EmailAddress, O: CustomerAccountResponse },
    { name: "BatchGet", options: {}, I: CustomerAccountSearchRequest, O: CustomerAccountList },
    { name: "RefreshToken", options: {}, I: Empty, O: RefreshTokenResponse },
    { name: "CreateSubAccountPrivilege", options: {}, I: SubAccountPrivilege, O: SubAccountPrivilege },
    { name: "UpdateSubAccountPrivilege", options: {}, I: SubAccountPrivilege, O: Empty },
    { name: "DeleteSubAccountPrivilege", options: {}, I: IDInt64, O: Empty },
    { name: "GetSubAccountPrivilege", options: {}, I: IDInt64, O: SubAccountPrivilege },
    { name: "BatchGetSubAccountPrivileges", options: {}, I: SubAccountPrivilege, O: SubAccountPrivilegeList },
    { name: "CreateSubAccount", options: {}, I: SubAccount, O: SubAccount },
    { name: "UpdateSubAccount", options: {}, I: SubAccount, O: Empty },
    { name: "DeleteSubAccount", options: {}, I: SubAccount, O: Empty },
    { name: "GetSubAccount", options: {}, I: IDInt64, O: SubAccount },
    { name: "GetSubAccountByEmail", options: {}, I: EmailAddress, O: SubAccount },
    { name: "BatchGetSubAccounts", options: {}, I: SubAccount, O: SubAccountList }
]);
