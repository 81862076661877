import './Equipment.module.less';

import { type Event } from '@kalos/kalos-rpc';
import { type FC } from 'react';

import { ServiceItems } from '../../ServiceItems';

interface Props {
  propertyId: number;
  event: Event;
  onSelectedChange?: (selected: string) => void;
}

export const Equipment: FC<Props> = ({ event, propertyId, onSelectedChange }) => {
  return (
    <div>
      <ServiceItems
        eventId={event.id}
        propertyId={propertyId}
        onSelectedUpdate={onSelectedChange}
        selectForInvoice
      />
    </div>
  );
};
