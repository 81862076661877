import { ActivityLog, Property } from '@kalos/kalos-rpc';
import { Alert } from '@mui/material';
import format from 'date-fns/esm/format';
import { type FC, useCallback, useEffect, useState } from 'react';

import { PROP_LEVEL, RESIDENTIAL_OPTIONS, USA_STATES_OPTIONS } from '../../../constants';
import { useAuth } from '../../../context/AuthContext';
import {
  ActivityLogClientService,
  MapClientService,
  PropertyClientService,
  zipCodeValidation,
} from '../../../tools/helpers';
import { Form, type Schema } from '../Form';

interface Props {
  onSave?: (data: Property) => void;
  onClose: () => void;
  userId: number;
  propertyId?: number;
  property?: Property;
  viewedAsCustomer?: boolean;
}

export const PropertyEdit: FC<Props> = ({
  onClose,
  onSave,
  userId,
  propertyId: _propertyId = 0,
  property: _property,
  viewedAsCustomer = false,
}) => {
  const [propertyId, setPropertyId] = useState<number>(_propertyId);
  const [saving, setSaving] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [formKey, setFormKey] = useState<number>(0);
  const [addressStatus, setAddressStatus] = useState<'success' | 'error' | null>(null);
  const [entry, setEntry] = useState<Property>(_property || Property.create());

  const loggedUserId = useAuth().user.id;

  const load = useCallback(async () => {
    if (propertyId) {
      if (!_property) {
        setLoading(true);
        const entry = await PropertyClientService.loadPropertyByID(propertyId);
        setEntry(entry!);
        setFormKey(formKey + 1);
      }
    }
    setLoading(false);
  }, [propertyId, setLoading, setFormKey, formKey, _property]);
  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      load();
    }
  }, [loaded, setLoaded, load]);
  const handleCheckLocation = useCallback(async () => {
    setLoading(true);
    const address = entry.address;
    const city = entry.city;
    const addressState = entry.state;
    const zip = entry.zip;
    const geo = await MapClientService.loadGeoLocationByAddress(
      `${address}, ${city}, ${addressState} ${zip}`,
    );

    if (geo) {
      entry.geolocationLat = geo.geolocationLat;
      entry.geolocationLng = geo.geolocationLng;
      setEntry(entry);
      setFormKey(formKey + 1);
      setAddressStatus('success');
    } else {
      setAddressStatus('error');
    }
    setTimeout(() => {
      setAddressStatus(null);
    }, 5000);
    setLoading(false);
  }, [entry, setEntry, formKey, setFormKey]);
  const handleSave = useCallback(
    async (temp: Property) => {
      setSaving(true);
      temp = Property.clone(temp);
      try {
        const entry = await PropertyClientService.saveProperty(temp, userId, propertyId);
        if (entry) {
          const actLog = ActivityLog.create();
          actLog.userId = loggedUserId;
          actLog.propertyId = entry.id;
          actLog.activityDate = format(new Date(), 'yyyy-MM-dd HH:mm:ss');
          actLog.activityName = `${propertyId != 0 ? 'Edited' : 'Added'} Property : ${
            entry.address
          }`;
          if (entry.geolocationLat && entry.geolocationLng) {
            actLog.geolocationLat = entry.geolocationLat;
            actLog.geolocationLng = entry.geolocationLng;
          }
          await ActivityLogClientService.Create(actLog);

          setEntry(entry);
          setPropertyId(entry.id);
          if (onSave) {
            onSave(entry);
          }
        } else {
          throw new Error('no entry recovered');
        }
      } catch (err) {
        console.error(err);
      }
      setSaving(false);
    },
    [userId, propertyId, loggedUserId, onSave],
  );
  const SCHEMA_PROPERTY_INFORMATION: Schema<Property> = [
    ...(viewedAsCustomer
      ? []
      : ([
          [
            {
              label: 'Personal Details',
              headline: true,
              description: PROP_LEVEL,
            },
          ],
          [
            { label: 'First Name', name: 'firstname' },
            { label: 'Last Name', name: 'lastname' },
            { label: 'Business Name', name: 'businessname' },
          ],
          [
            {
              label: 'Contact Details',
              headline: true,
              description: PROP_LEVEL,
            },
          ],
          [
            { label: 'Primary Phone', name: 'phone' },
            { label: 'Alternate Phone', name: 'altphone' },
            { label: 'Email', name: 'email' },
          ],
        ] as Schema<Property>)),
    [{ label: 'Address Details', headline: true }],
    [
      { label: 'Address', name: 'address', required: true, multiline: true },
      { label: 'City', name: 'city', required: true },
      {
        label: 'State',
        name: 'state',
        options: USA_STATES_OPTIONS,
        required: true,
      },
      {
        label: 'Zip Code',
        name: 'zip',
        required: true,
        validationOnSave: zipCodeValidation,
      },
    ],
    [
      {
        label: 'Location Details',
        headline: true,
        actions: viewedAsCustomer
          ? undefined
          : [
              {
                label: 'Check Location',
                compact: true,
                onClick: handleCheckLocation,
                disabled: saving || !entry.address,
                variant: 'outlined',
                size: 'xsmall',
              },
            ],
      },
    ],
    [
      { label: 'Directions', name: 'directions', multiline: true },
      { label: 'Subdivision', name: 'subdivision' },
    ],
    ...(viewedAsCustomer
      ? []
      : ([
          [
            {
              label: 'Zoning',
              name: 'isResidential',
              options: RESIDENTIAL_OPTIONS,
            },
            { label: 'Latitude', name: 'geolocationLat', type: 'number' },
            { label: 'Longitude', name: 'geolocationLng', type: 'number' },
          ],
        ] as Schema<Property>)),
    [{ label: 'Notes', headline: true }],
    [{ label: 'Notes', name: 'notes', multiline: true }],
  ];
  return (
    <Form<Property>
      key={formKey}
      title={propertyId ? 'Edit Property Information' : 'Add Property'}
      schema={SCHEMA_PROPERTY_INFORMATION}
      data={entry}
      onChange={setEntry}
      onSave={handleSave}
      onClose={onClose}
      intro={
        addressStatus && (
          <Alert severity={addressStatus} className="-m-4">
            <big>
              {addressStatus === 'success'
                ? 'Location checked successfully'
                : 'Your entered address is incorrect'}{' '}
            </big>
          </Alert>
        )
      }
      disabled={saving || loading}
    />
  );
};
