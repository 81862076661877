import { Box, Tabs } from '@mui/material';
import Tab from '@mui/material/Tab';
import { type FC, type ReactNode, useCallback, useState } from 'react';

interface Props {
  tabs: {
    label: string;
    content: ReactNode;
    disabled?: boolean;
  }[];
  tabStyle?: React.CSSProperties;
  defaultOpenIdx?: number;
  onChange?: (idx: number) => void;
}

const a11yProps = (idx: number) => ({
  id: `simple-tab-${idx}`,
  'aria-controls': `simple-tabpanel-${idx}`,
});
export const VerticalTabs: FC<Props> = ({ tabs, defaultOpenIdx = 0, onChange }) => {
  const [tabIdx, setTabIdx] = useState<number>(defaultOpenIdx);
  const handleChange = useCallback(
    (_: any, newValue: number) => {
      setTabIdx(newValue);
      if (onChange) {
        onChange(newValue);
      }
    },
    [setTabIdx, onChange],
  );

  // Usage within your component
  return (
    <Box sx={{ display: 'flex', borderColor: 'divider' }}>
      <Tabs
        value={tabIdx}
        onChange={handleChange}
        sx={{ width: '200px', flexShrink: 0, height: '100%', flexGrow: 0 }}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        orientation={'vertical'}
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
      >
        {tabs.map((props, idx) => (
          <Tab key={idx} disabled={props.disabled} label={props.label} {...a11yProps(idx)} />
        ))}
      </Tabs>
      {tabs[tabIdx].content}
    </Box>
  );
};
