'use client';
import { flexRender, type Row, type Table as TableType } from '@tanstack/react-table';
import { useCallback, useMemo } from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../../components/table';
import { cn } from '../../utils';

type DataTableProps<TData> = {
  table: TableType<TData>;
  showHeader?: boolean;
} & Omit<TableRowComponentProps<TData>, 'row'>;

export const DataTable = <TData,>({
  table,
  showHeader = true,
  ...rowProps
}: DataTableProps<TData>) => {
  return (
    <Table className="relative">
      {showHeader && (
        <TableHeader className="bg-background sticky top-0 z-10 shadow">
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
      )}
      <TableBody>
        {table.getRowModel().rows?.length ? (
          table
            .getRowModel()
            .rows.map((row) => <TableRowComponent key={row.id} row={row} {...rowProps} />)
        ) : (
          <TableRow>
            <TableCell colSpan={table.getAllColumns().length} className="h-24 text-center">
              No results.
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

type TableRowComponentProps<TData> = {
  row: Row<TData>;
  onRowClick?: (entry: TData, nativeEvent?: React.MouseEvent<HTMLTableRowElement>) => void;
  shouldApplyOnRowClick?: (entry: TData) => boolean;
  tableCellClassName?: string;
  RowWrapper?: React.ComponentType<{ row: Row<TData>; children: React.ReactNode }>;
};

const TableRowComponent = <TData,>({
  row,
  onRowClick,
  shouldApplyOnRowClick,
  tableCellClassName,
  RowWrapper,
}: TableRowComponentProps<TData>) => {
  const isOnRowClickApplicable = useMemo(() => {
    return shouldApplyOnRowClick ? shouldApplyOnRowClick(row.original) : true;
  }, [row.original, shouldApplyOnRowClick]);

  const onRowClickHandler = useCallback<React.MouseEventHandler<HTMLTableRowElement>>(
    (e) => {
      if (onRowClick && isOnRowClickApplicable) {
        onRowClick(row.original, e);
      }
    },
    [isOnRowClickApplicable, onRowClick, row.original],
  );

  const markup = (
    <TableRow
      onClick={onRowClickHandler}
      className={cn(isOnRowClickApplicable && onRowClick && 'cursor-pointer')}
      key={row.id}
      data-state={row.getIsSelected() && 'selected'}
    >
      {row.getVisibleCells().map((cell) => (
        <TableCell className={tableCellClassName} key={cell.id}>
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </TableCell>
      ))}
    </TableRow>
  );

  return RowWrapper ? <RowWrapper row={row}>{markup}</RowWrapper> : markup;
};
