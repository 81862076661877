import { JobSubtype, type JobSubtypeList } from '@kalos/kalos-rpc';
import { useQuery } from '@tanstack/react-query';

import { JobSubtypeClientService } from '../../tools/helpers';
import { queryKeys } from './constants';
import { type EntityFilter } from './utils';

export type JobSubTypesFilter = EntityFilter<JobSubtype>;

export const useJobSubTypesQuery = <TSelectData = JobSubtypeList,>({
  enabled = true,
  filters = {},
  select,
}: {
  enabled?: boolean;
  filters?: JobSubTypesFilter;
  select?: (data?: JobSubtypeList) => TSelectData;
} = {}) => {
  return useQuery({
    queryKey: [queryKeys.jobSubTypes.root, queryKeys.jobSubTypes.getJobSubTypesHash(filters)],
    queryFn: () => {
      const res = JobSubtype.create(filters);
      return JobSubtypeClientService.BatchGet(res);
    },
    select,
    enabled,
  });
};
