import { ActivityLog, type ActivityLogList, type OrderDir } from '@kalos/kalos-rpc';
import { type FC, useCallback, useEffect, useState } from 'react';

import { ActivityLogClientService, makeFakeRows } from '../../../tools/helpers';
import { type Columns, type Data, InfoTable } from '../../ComponentsLibrary/InfoTable';
import { SectionBar } from '../../ComponentsLibrary/SectionBar';

interface PropertyActivityProps {
  propertyId?: number;
  onClose?: () => void;
}

export const PropertyActivity: FC<PropertyActivityProps> = ({ propertyId, onClose }) => {
  const [activityData, setActivityData] = useState<ActivityLogList>();
  const [activityPage, setActivityPage] = useState<number>(0);
  const [activityLoading, setActivityLoading] = useState<boolean>(false);
  const [activityOrderDir, setActivityOrderDir] = useState<OrderDir>();

  const handleGetActivity = useCallback(
    async (page?: number, orderDir?: OrderDir) => {
      setActivityLoading(true);
      try {
        const req = ActivityLog.create({
          withUser: true,
        });
        req.propertyId = propertyId || 0;
        req.pageNumber = page || 0;
        req.orderBy = 'activity_date';
        req.orderDir = orderDir || activityOrderDir || '';
        const res = await ActivityLogClientService.BatchGet(req);
        console.log({ res });

        setActivityData(res);
      } catch (error) {
        console.log(`Error: ${error}`);
      }
      setActivityLoading(false);
    },
    [activityOrderDir, propertyId],
  );

  const activityTableColumns: Columns = [
    {
      name: 'Name',
    },
    {
      name: 'User',
    },
    {
      name: 'Date',
      dir: activityOrderDir,
      onClick() {
        const orderDir = activityOrderDir === 'ASC' ? 'DESC' : 'ASC';
        setActivityOrderDir(orderDir);
        handleGetActivity(activityPage, orderDir);
      },
    },
  ];

  const activityTableData: Data = activityLoading
    ? makeFakeRows(3, 5)
    : (activityData?.results || []).map((activity) => [
        {
          value: activity.activityName,
        },
        {
          value: activity.user?.firstname + ' ' + activity.user?.lastname,
        },
        { value: activity.activityDate },
      ]);

  useEffect(() => {
    handleGetActivity();
  }, [handleGetActivity]);

  return (
    <SectionBar
      title="Activity"
      actions={[
        {
          label: 'Close',
          onClick() {
            setActivityPage(0);
            onClose?.();
          },
        },
      ]}
      pagination={{
        page: activityPage,
        count: activityData?.totalCount || 0,
        rowsPerPage: 25,
        onPageChange(page) {
          setActivityPage(page);
          handleGetActivity(page);
        },
      }}
    >
      <InfoTable
        data={activityTableData}
        columns={activityTableColumns}
        loading={activityLoading}
      />
    </SectionBar>
  );
};
