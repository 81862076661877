// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "predict.proto" (syntax proto3)
// tslint:disable
// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "predict.proto" (syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { WireType } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
// @generated message type with reflection information, may provide speed optimized methods
class TransactionData$Type extends MessageType {
    constructor() {
        super("TransactionData", [
            { no: 1, name: "vendor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "amount", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 3, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "owner_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { vendor: "", amount: 0, notes: "", ownerId: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string vendor */ 1:
                    message.vendor = reader.string();
                    break;
                case /* double amount */ 2:
                    message.amount = reader.double();
                    break;
                case /* string notes */ 3:
                    message.notes = reader.string();
                    break;
                case /* int32 owner_id */ 4:
                    message.ownerId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string vendor = 1; */
        if (message.vendor !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.vendor);
        /* double amount = 2; */
        if (message.amount !== 0)
            writer.tag(2, WireType.Bit64).double(message.amount);
        /* string notes = 3; */
        if (message.notes !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.notes);
        /* int32 owner_id = 4; */
        if (message.ownerId !== 0)
            writer.tag(4, WireType.Varint).int32(message.ownerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TransactionData
 */
export const TransactionData = new TransactionData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Prediction$Type extends MessageType {
    constructor() {
        super("Prediction", [
            { no: 1, name: "predicated_label", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "predicted_scores", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ } }
        ]);
    }
    create(value) {
        const message = { predicatedLabel: "", predictedScores: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string predicated_label */ 1:
                    message.predicatedLabel = reader.string();
                    break;
                case /* map<string, double> predicted_scores */ 2:
                    this.binaryReadMap2(message.predictedScores, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    binaryReadMap2(map, reader, options) {
        let len = reader.uint32(), end = reader.pos + len, key, val;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.double();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field Prediction.predicted_scores");
            }
        }
        map[key ?? ""] = val ?? 0;
    }
    internalBinaryWrite(message, writer, options) {
        /* string predicated_label = 1; */
        if (message.predicatedLabel !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.predicatedLabel);
        /* map<string, double> predicted_scores = 2; */
        for (let k of Object.keys(message.predictedScores))
            writer.tag(2, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.Bit64).double(message.predictedScores[k]).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Prediction
 */
export const Prediction = new Prediction$Type();
/**
 * @generated ServiceType for protobuf service PredictService
 */
export const PredictService = new ServiceType("PredictService", [
    { name: "PredictCostCenter", options: {}, I: TransactionData, O: Prediction }
]);
