// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "performance_review.proto" (syntax proto3)
// tslint:disable
import { PerformanceReviewService } from "./performance_review";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service PerformanceReviewService
 */
export class PerformanceReviewServiceClient {
    constructor(_transport) {
        this._transport = _transport;
        this.typeName = PerformanceReviewService.typeName;
        this.methods = PerformanceReviewService.methods;
        this.options = PerformanceReviewService.options;
    }
    /**
     * @generated from protobuf rpc: Create(PerformanceReview) returns (PerformanceReview);
     */
    create(input, options) {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Get(PerformanceReview) returns (PerformanceReview);
     */
    get(input, options) {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGet(PerformanceReview) returns (PerformanceReviewList);
     */
    batchGet(input, options) {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Update(PerformanceReview) returns (PerformanceReview);
     */
    update(input, options) {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Delete(PerformanceReview) returns (PerformanceReview);
     */
    delete(input, options) {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateReviewForm(ReviewForm) returns (ReviewForm);
     */
    createReviewForm(input, options) {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetReviewForm(ReviewForm) returns (ReviewForm);
     */
    getReviewForm(input, options) {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetReviewForm(ReviewForm) returns (ReviewFormList);
     */
    batchGetReviewForm(input, options) {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateReviewForm(ReviewForm) returns (ReviewForm);
     */
    updateReviewForm(input, options) {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteReviewForm(ReviewForm) returns (ReviewForm);
     */
    deleteReviewForm(input, options) {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreatePayChange(PayChangeRequest) returns (PayChangeRequest);
     */
    createPayChange(input, options) {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetPayChange(PayChangeRequest) returns (PayChangeRequest);
     */
    getPayChange(input, options) {
        const method = this.methods[11], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetPayChange(PayChangeRequest) returns (PayChangeRequestList);
     */
    batchGetPayChange(input, options) {
        const method = this.methods[12], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdatePayChange(PayChangeRequest) returns (PayChangeRequest);
     */
    updatePayChange(input, options) {
        const method = this.methods[13], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetAllPRStatuses(Empty) returns (StatusList);
     */
    getAllPRStatuses(input, options) {
        const method = this.methods[14], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateInternalLink(InternalLink) returns (InternalLink);
     */
    createInternalLink(input, options) {
        const method = this.methods[15], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetInternalLink(InternalLink) returns (InternalLink);
     */
    getInternalLink(input, options) {
        const method = this.methods[16], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetInternalLink(InternalLink) returns (InternalLinkList);
     */
    batchGetInternalLink(input, options) {
        const method = this.methods[17], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateInternalLink(InternalLink) returns (InternalLink);
     */
    updateInternalLink(input, options) {
        const method = this.methods[18], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteInternalLink(InternalLink) returns (InternalLink);
     */
    deleteInternalLink(input, options) {
        const method = this.methods[19], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
}
