import { BaseClient } from "../BaseClient";
import {
  IDInt64
} from '../compiled-protos/common';
import {
  EmployeeProfile,
} from '../compiled-protos/employee_profile';
import { EmployeeProfileServiceClient } from '../compiled-protos/employee_profile.client';

class EmployeeProfileClient extends BaseClient {
  self: EmployeeProfileServiceClient;

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new EmployeeProfileServiceClient(this.transport);
  }

  public async GetEmployeeProfile(id: bigint)  {
    const req = IDInt64.create({ id })
    let res = EmployeeProfile.create();
    try {
      res = await this.self.getEmployeeProfile(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }
}

export {EmployeeProfile, EmployeeProfileClient }