import { BaseClient } from '../BaseClient';
import {
  QuickBooksTrackingCode,
  QuickBooksTrackingCodeList,
} from '../compiled-protos/event';
import { EventServiceClient as QuickBooksTrackingCodeServiceClient } from '../compiled-protos/event.client';
class QuickBooksTrackingCodeClient extends BaseClient {
  self: QuickBooksTrackingCodeServiceClient;

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new QuickBooksTrackingCodeServiceClient(this.transport);
  }

  public async Create(req: QuickBooksTrackingCode) {
    let res = QuickBooksTrackingCode.create();
    try {
      res = await this.self.createQuickBooksTrackingCode(
        req,
        this.getMetaData()
      ).response;
    } catch (err) {
      console.log(err);
      return;
      return;
    }
    return res;
  }

  public async Get(req: QuickBooksTrackingCode) {
    let res = QuickBooksTrackingCode.create();
    try {
      res = await this.self.getQuickBooksTrackingCode(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
      return;
    }
    return res;
  }

  public async Update(req: QuickBooksTrackingCode) {
    let res = QuickBooksTrackingCode.create();
    try {
      res = await this.self.updateQuickBooksTrackingCode(
        req,
        this.getMetaData()
      ).response;
    } catch (err) {
      console.log(err);
      return;
      return;
    }
    return res;
  }

  public async Delete(req: QuickBooksTrackingCode) {
    let res = QuickBooksTrackingCode.create();
    try {
      res = await this.self.deleteQuickBooksTrackingCode(
        req,
        this.getMetaData()
      ).response;
    } catch (err) {
      console.log(err);
      return;
      return;
    }
    return res;
  }

  public async BatchGet(req: QuickBooksTrackingCode) {
    let res = QuickBooksTrackingCodeList.create();
    try {
      res = await this.self.batchGetQuickBooksTrackingCode(
        req,
        this.getMetaData()
      ).response;
    } catch (err) {
      console.log(err);
      return;
      return;
    }
    return res;
  }
}

export {
  QuickBooksTrackingCode,
  QuickBooksTrackingCodeList,
  QuickBooksTrackingCodeClient,
};
