import './ServiceCalls.module.less';

import {
  ActivityLog,
  Event,
  EventList,
  getPropertyAddress,
  Property,
  ServicesRendered,
  User,
} from '@kalos/kalos-rpc';
import DeleteIcon from '@mui/icons-material/Delete';
import RateReviewOutlined from '@mui/icons-material/RateReviewOutlined';
import ReceiptIcon from '@mui/icons-material/Receipt';
import RedoIcon from '@mui/icons-material/Redo';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import { addHours, format } from 'date-fns';
import { produce } from 'immer';
import { type FC, useCallback, useEffect, useState } from 'react';

import { OPTION_BLANK, ROWS_PER_PAGE } from '../../../constants';
import {
  ActivityLogClientService,
  EventClientService,
  formatDate,
  formatTime,
  loadPropertiesByFilter,
  makeFakeRows,
  navigateNewTab,
  type OrderDir,
  ServicesRenderedClientService,
  timestamp,
  trailingZero,
  usd,
  UserClientService,
} from '../../../tools/helpers';
import { AddServiceCall } from '../../AddServiceCallGeneral/components/AddServiceCall';
import { PropertiesSearch } from '../../ComponentsLibrary/AdvancedSearch/PropertiesSearch/PropertiesSearch';
import { ConfirmDelete } from '../../ComponentsLibrary/ConfirmDelete';
import { Form, type Schema } from '../../ComponentsLibrary/Form';
import { type Columns, type Data, InfoTable } from '../../ComponentsLibrary/InfoTable';
import { Link } from '../../ComponentsLibrary/Link';
import { Modal } from '../../ComponentsLibrary/Modal';
import { PlainForm } from '../../ComponentsLibrary/PlainForm';
import { SectionBar } from '../../ComponentsLibrary/SectionBar';
import { SellSheet } from '../../ComponentsLibrary/SellSheet';
import { Proposal } from '../../ComponentsLibrary/ServiceCall/components/Proposal';
import { ServiceRequest } from '../../ComponentsLibrary/ServiceCall/requestIndex';
import { Tooltip } from '../../ComponentsLibrary/Tooltip';

interface Props {
  className?: string;
  userID: number;
  propertyId?: number;
  viewedAsCustomer?: boolean;
  loggedUser?: User;
  loggedUserId: number;
  onCreateDoc?: () => void;
}

type ServiceCallFilter = {
  name: string;
  briefDescription: string;
};
interface State {
  entries: Event[];
  loading: boolean;
  error: boolean;
  deletingEntry?: Event;
  movingEntry?: Event;
  viewingEntry?: Event;
  addingCustomerEntry?: Event;
  addingServiceCall?: boolean;
  editingServiceCall: boolean;
  serviceCallId: number;
  loggedUser: User | undefined;
  loggedUserId: number;
  orderByFields: (keyof Event)[];
  orderByDBField: string;
  dir: OrderDir;
  sellSheetEntry: Event | undefined;
  count: number;
  page: number;
  customerProperties: Property[];
  saving: boolean;
  confirmingAdded: boolean;
  showText?: string;
  serviceCallFilter: ServiceCallFilter;
  proposalEvent: Event | undefined;
  proposalEventPropData: ServicesRendered[];
  refresh: boolean;
  isLoaded: boolean;
}

const sort = (a: Event, b: Event, state: State) => {
  const { orderByFields, dir } = state;
  const A = orderByFields
    .map((field) => a[field]?.toString() as string)
    .join(' ')
    .trim()
    .toUpperCase();
  const B = orderByFields
    .map((field) => b[field]?.toString() as string)
    .join(' ')
    .trim()
    .toUpperCase();
  if (A > B) return dir === 'ASC' ? 1 : -1;
  if (A < B) return dir === 'ASC' ? -1 : 1;
  return 0;
};

export const ServiceCalls: FC<Props> = ({
  className = '',
  userID,
  propertyId = 0,
  viewedAsCustomer,
  loggedUser = User.create(),
  loggedUserId,
  onCreateDoc,
}) => {
  const [state, setState] = useState<State>({
    entries: [],
    loading: true,
    error: false,
    deletingEntry: undefined,
    movingEntry: undefined,
    sellSheetEntry: undefined,
    viewingEntry: undefined,
    addingCustomerEntry: undefined,
    addingServiceCall: false,
    editingServiceCall: false,
    serviceCallId: 0,
    loggedUser: loggedUser,
    loggedUserId: loggedUserId,
    orderByFields: ['dateStarted'],
    orderByDBField: 'date_started',
    dir: 'DESC',
    count: 0,
    page: 0,
    customerProperties: [],
    saving: false,
    confirmingAdded: false,
    serviceCallFilter: {
      name: '',
      briefDescription: '',
    },
    proposalEvent: undefined,
    proposalEventPropData: [],
    refresh: false,
    isLoaded: false,
  });

  const handleSetState = useCallback(function handleSetState(partialState: Partial<State>) {
    setState((currState) =>
      produce(currState, (draft) => {
        return { ...draft, ...partialState };
      }),
    );
  }, []);

  const setDeleting = (deletingEntry?: Event) => () => handleSetState({ deletingEntry });

  const setMoving = (movingEntry?: Event) => () => handleSetState({ movingEntry });

  const setSellSheetEntry = (sellSheetEntry?: Event) => () => handleSetState({ sellSheetEntry });

  const setViewing = (viewingEntry?: Event) => () => handleSetState({ viewingEntry });

  const setAddingCustomerEntry = (addingCustomerEntry?: Event) => () =>
    handleSetState({ addingCustomerEntry });

  const toggleConfirmingAdded = () => handleSetState({ confirmingAdded: !state.confirmingAdded });

  const handleChangePage = (page: number) => {
    handleSetState({ page, isLoaded: false });
    //refresh
  };

  const handleServiceCallAddToggle = () =>
    handleSetState({ addingServiceCall: !state.addingServiceCall });

  const handleRowClick = (id: number, newEdit?: boolean) => () => {
    handleSetState({ serviceCallId: id });
    if (newEdit) {
      handleSetState({ editingServiceCall: !state.editingServiceCall });
    } else {
      navigateNewTab(`/jobs/${id}`);
    }
  };

  const handleCustomerAddEvent = async (formData: Event) => {
    const [, timeEnded] = format(
      addHours(new Date(`${formData.dateStarted} ${formData.timeStarted}`), 1),
      'yyyy-MM-dd HH:mm',
    ).split(' ');
    const data = Event.create();
    data.timeEnded = timeEnded;
    data.name = 'Online Service Request';
    data.logJobStatus = 'Requested';
    data.color = 'efc281';
    data.description = [formData.description, formData.notes].join(', ');
    data.notes = '';
    data.dateEnded = formData.dateEnded;
    console.log(data);
    handleSetState({ saving: true });
    await EventClientService.upsertEvent(data);
    handleSetState({
      saving: false,
      addingCustomerEntry: undefined,
      confirmingAdded: !state.confirmingAdded,
      isLoaded: false,
    });
    // toggleConfirmingAdded();
    // refresh
    // load();
  };

  const makeCustomerEntry = () => {
    const req = Event.create();
    if (propertyId) {
      req.propertyId = propertyId;
    }
    req.description = '0';
    req.dateStarted = timestamp(true);
    const [valHour, valMinutes] = timestamp().substr(11, 5).split(':');
    let minutes = +valMinutes;
    if (minutes >= 45) {
      minutes = 45;
    } else if (minutes >= 30) {
      minutes = 30;
    } else if (minutes >= 15) {
      minutes = 15;
    } else {
      minutes = 0;
    }
    req.timeStarted = `${valHour}:${trailingZero(minutes)}`;
    return req;
  };

  const load = useCallback(async () => {
    handleSetState({ loading: true, isLoaded: true });
    let setUser = false;
    let loggedUserInfo = User.create();
    let eventBatchGet = EventList.create();
    let customerResults: Property[] = [];

    if (loggedUser == undefined) {
      const user = User.create();
      user.id = state.loggedUserId;
      try {
        const result = await UserClientService.Get(user);
        setUser = true;
        loggedUserInfo = result;
      } catch (err) {
        console.error('Error getting logged user', err);
        handleSetState({ error: true, loading: false });
      }
      // handleSetState({ loggedUser: result });
    }
    // const { propertyId, userID, viewedAsCustomer } = props;
    const entry = Event.create();
    const reqCust = User.create();
    reqCust.id = userID;
    if (propertyId) {
      entry.propertyId = propertyId;
    } else {
      entry.customer = reqCust;
    }
    console.log('propertyID', propertyId, 'customerID', userID);
    entry.orderBy = state.orderByDBField;
    entry.orderDir = state.dir;
    entry.pageNumber = state.page;
    entry.isActive = 1;
    if (state.serviceCallFilter.name !== '') {
      entry.name = `%${state.serviceCallFilter.name}%`;
    }
    if (state.serviceCallFilter.briefDescription !== '') {
      entry.description = `%${state.serviceCallFilter.briefDescription}%`;
    }
    try {
      const response = await EventClientService.BatchGet(entry);
      eventBatchGet = response!;
    } catch (e) {
      handleSetState({ error: true, loading: false });
    }
    const req = Property.create();
    if (viewedAsCustomer) {
      try {
        const { results } = await loadPropertiesByFilter({
          page: 0,
          filter: { userId: userID },
          sort: {
            orderBy: 'address',
            orderByField: 'dateCreated',
            orderDir: 'ASC',
          },
          req: req, // TODO Is this order-by field correct?
        });
        customerResults = results;
      } catch (err) {
        console.error('Error getting customer properties', err);
        handleSetState({ error: true, loading: false });
      }
    }
    handleSetState({
      loggedUser: setUser ? loggedUserInfo : state.loggedUser,
      entries: eventBatchGet.results.sort((a, b) => sort(a, b, state)),
      count: eventBatchGet.totalCount,
      loading: false,
      customerProperties: viewedAsCustomer ? customerResults : state.customerProperties,
      isLoaded: true,
    });
  }, [handleSetState, loggedUser, userID, propertyId, state, viewedAsCustomer]);

  const handleOrder = (orderByDBField: string, orderByFields: (keyof Event)[]) => () => {
    handleSetState({
      page: 0,
      orderByFields,
      orderByDBField,
      dir: orderByDBField !== state.orderByDBField ? 'ASC' : state.dir === 'ASC' ? 'DESC' : 'ASC',
      isLoaded: false,
    });
    // refresh
  };

  const handleDelete = async () => {
    // FIXME: Job is not actually deleted for some reason
    const { deletingEntry } = state;
    // setDeleting();
    if (deletingEntry) {
      handleSetState({ loading: true });
      const entry = Event.create();
      entry.id = deletingEntry.id;
      await EventClientService.Delete(entry);
      handleSetState({
        deletingEntry: undefined,
        loading: false,
        isLoaded: false,
      });
      // await load(); //Refresh
    }
  };
  const handleMoveJobToProperty = async (property: Property) => {
    const { movingEntry } = state;
    if (movingEntry) {
      handleSetState({ loading: true });
      const entry = Event.create();
      entry.id = movingEntry.id;
      entry.propertyId = property.id;
      entry.fieldMask = ['PropertyId'];
      await EventClientService.Update(entry);
      await ActivityLogClientService.Create(
        ActivityLog.create({
          propertyId: propertyId,
          userId: state.loggedUserId,
          activityName: `Job ${entry.id} moved to ${property.address}`,
        }),
      );
      await ActivityLogClientService.Create(
        ActivityLog.create({
          propertyId: property.id,
          userId: state.loggedUserId,
          activityName: `Job ${entry.id} moved from ${property.address}`,
        }),
      );

      handleSetState({
        movingEntry: undefined,
        loading: false,
        isLoaded: false,
      });
    }
  };
  const handleSetProposalEvent = async (proposalEvent?: Event) => {
    if (proposalEvent === undefined) {
      handleSetState({ proposalEvent: undefined, proposalEventPropData: [] });
    } else {
      const srReq = ServicesRendered.create();
      srReq.eventId = proposalEvent.id;
      srReq.isActive = 1;
      try {
        const results = await ServicesRenderedClientService.BatchGet(srReq);
        {
          if (results) {
            handleSetState({
              proposalEventPropData: results.results,
              proposalEvent: proposalEvent,
            });
          } else {
            handleSetState({ proposalEvent: proposalEvent });
          }
        }
      } catch (err) {
        console.error('Error getting proposal event', err);
        handleSetState({ error: true });
      }
    }
  };

  const columns: Columns = viewedAsCustomer
    ? [
        { name: 'Date / Time' },
        { name: 'Address' },
        { name: 'Bried Description' },
        { name: 'Status' },
      ]
    : [
        {
          name: 'Date / Time',
          dir: state.orderByDBField === 'date_started' ? state.dir : undefined,
          onClick: handleOrder('date_started', ['dateStarted', 'timeStarted']),
        },
        {
          name: 'Job Status',
          dir: state.orderByDBField === 'log_jobStatus' ? state.dir : undefined,
          onClick: handleOrder('log_jobStatus', ['logJobStatus']),
        },
        {
          name: 'Job Type / Subtype',
          dir: state.orderByDBField === 'job_type_id, job_subtype_id' ? state.dir : undefined,
          onClick: handleOrder('job_type_id, job_subtype_id', ['jobType', 'jobSubtype']),
        },
        {
          name: 'Job Number',
          dir: state.orderByDBField === 'log_jobNumber' ? state.dir : undefined,
          onClick: handleOrder('log_jobNumber', ['logJobNumber']),
        },
        {
          name: 'Name',
          dir: state.orderByDBField === 'name' ? state.dir : undefined,
          onClick: handleOrder('name', ['name']),
        },
        {
          name: 'Description',
          dir: state.orderByDBField === 'description' ? state.dir : undefined,
          onClick: handleOrder('description', ['description']),
        },
        {
          name: 'Contract Number',
          dir: state.orderByDBField === 'contract_number' ? state.dir : undefined,
          onClick: handleOrder('contract_number', ['contractNumber']),
        },
      ];
  const data: Data = state.loading
    ? makeFakeRows(viewedAsCustomer ? 4 : 7)
    : state.entries.map((entry) => {
        const dateValue =
          formatDate(entry.dateStarted) +
          ' ' +
          formatTime(entry.timeStarted) +
          ' - ' +
          formatTime(entry.timeEnded);
        const statusValue = (
          <>
            <span
              style={{
                display: 'inline-block',
                width: 10,
                height: 10,
                backgroundColor: '#' + entry.color,
                marginRight: 6,
                borderRadius: '50%',
              }}
            />
            {entry.logJobStatus}
          </>
        );
        if (viewedAsCustomer)
          return [
            {
              value: dateValue,
              onClick: () => setViewing(entry),
            },
            {
              value: getPropertyAddress(entry.property),
              onClick: () => setViewing(entry),
            },
            {
              value: entry.name,
              onClick: () => setViewing(entry),
            },
            {
              value: statusValue,
              onClick: () => setViewing(entry),
              actions: [
                <IconButton key={2} size="small" onClick={() => setViewing(entry)}>
                  <SearchIcon />
                </IconButton>,
              ],
            },
          ];
        return [
          {
            value: dateValue,
            onClick: handleRowClick(entry.id),
          },
          {
            value: statusValue,
            onClick: handleRowClick(entry.id),
          },
          {
            value: entry.jobType + (entry.jobSubtype ? ' / ' + entry.jobSubtype : ''),
            onClick: handleRowClick(entry.id),
          },
          {
            value: entry.logJobNumber,
            onClick: handleRowClick(entry.id),
          },
          {
            value: entry.name.length > 100 ? entry.name.substr(0, 100) + '...' : entry.name,
            onClick: handleRowClick(entry.id),
          },
          {
            value:
              entry.description.length > 100
                ? entry.description.substr(0, 100) + '...'
                : entry.description,
            onClick: handleRowClick(entry.id),
          },
          {
            value: entry.contractNumber,
            actions: [
              <Tooltip key="proposalModalButtonReview" content="Review" placement="top">
                <IconButton key={'newEdit'} size="small" onClick={handleRowClick(entry.id, true)}>
                  <RateReviewOutlined />
                </IconButton>
              </Tooltip>,
              ...(state.loggedUser &&
              state.loggedUser.permissionGroups.find((permission) => permission.name === 'Proposal')
                ? [
                    <Tooltip
                      key="proposalModalButton"
                      content="Create/Edit Proposal"
                      placement="top"
                    >
                      <IconButton
                        key="proposal"
                        size="small"
                        onClick={() => handleSetProposalEvent(entry)}
                      >
                        <ReceiptIcon />
                      </IconButton>
                    </Tooltip>,
                  ]
                : []),
              <Tooltip key="movingServiceCall" content="Move" placement="top">
                <IconButton
                  key={2}
                  style={{ marginLeft: 4 }}
                  size="small"
                  onClick={setMoving(entry)}
                >
                  <RedoIcon />
                </IconButton>
              </Tooltip>,
              <Tooltip key="proposalModalDelete" content="Delete" placement="top">
                <IconButton
                  key={2}
                  style={{ marginLeft: 4 }}
                  size="small"
                  onClick={setDeleting(entry)}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>,

              <Tooltip key="sellSheetButton" content="Create Sell sheet" placement="top">
                <IconButton
                  key={2}
                  style={{ marginLeft: 4 }}
                  size="small"
                  onClick={setSellSheetEntry(entry)}
                >
                  <RequestQuoteIcon />
                </IconButton>
              </Tooltip>,
            ],
            onClick: handleRowClick(entry.id),
          },
        ];
      });
  const SCHEMA_CUSTOMER_ENTRY: Schema<Event> = [
    [
      {
        name: 'propertyId',
        label: 'Property',
        required: true,
        options: [
          { value: '0', label: OPTION_BLANK },
          ...state.customerProperties.map((p) => ({
            value: p.id,
            label: getPropertyAddress(p),
          })),
        ],
      },
    ],
    [
      {
        name: 'description',
        label: 'Service Requested',
        options: [
          { value: '0', label: OPTION_BLANK },
          ...[
            'A/C Repair',
            'A/C Maintenance',
            'Electrical Repair',
            'Free Replacement/Upgrade Estimate',
            'Pool Heater Repair',
            'Construction',
          ].map((value) => ({ value, label: value })),
        ],
        required: true,
      },
    ],
    [
      {
        name: 'dateStarted',
        label: 'Date Requested',
        type: 'date',
        required: true,
      },
      {
        name: 'timeStarted',
        label: 'Time Requested',
        type: 'time',
        required: true,
      },
    ],
    [
      {
        name: 'notes',
        label: 'Notes',
        multiline: true,
      },
    ],
  ];

  useEffect(() => {
    if (!state.isLoaded) {
      load();
    }
  }, [state.isLoaded, load]);

  return (
    <div className={className}>
      <SectionBar
        uncollapsable
        title={`${viewedAsCustomer ? 'Active ' : ''}Jobs`}
        actions={
          viewedAsCustomer
            ? [
                {
                  label: 'Add Job',
                  onClick: setAddingCustomerEntry(makeCustomerEntry()),
                },
              ]
            : [
                {
                  label: 'Add Job',
                  onClick: handleServiceCallAddToggle,
                },
                // {
                //   label: 'Add Job',
                //   url: [
                //     '/index.cfm?action=admin:service.addserviceCall',
                //     `user_id=${userID}`,
                //     `property_id=${propertyId}`,
                //     'unique=207D906B-05C0-B58E-B451566171C79356', // FIXME set proper unique
                //   ].join('&'),
                // }
              ]
        }
        pagination={{
          count: state.count,
          page: state.page,
          rowsPerPage: ROWS_PER_PAGE,
          onPageChange: handleChangePage,
        }}
        fixedActions
      >
        <PlainForm<ServiceCallFilter>
          schema={[
            [
              {
                label: 'Name',
                name: 'name',
                type: 'search',
                onChange(value) {
                  if (!value) handleSetState({ isLoaded: false });
                },
              },
              {
                label: 'Description',
                name: 'briefDescription',
                type: 'search',
                onChange(value) {
                  if (!value) handleSetState({ isLoaded: false });
                },
                actions: [
                  {
                    label: 'Search',
                    onClick: () => handleSetState({ isLoaded: false }),
                  },
                ],
              },
            ],
          ]}
          onChange={(serviceCallFilter) => handleSetState({ serviceCallFilter })}
          data={state.serviceCallFilter}
          className="ServiceCallsFilter"
        />
        <InfoTable
          columns={columns}
          className="fixed-actions"
          data={data}
          loading={state.loading}
          error={state.error}
        />
      </SectionBar>
      {state.deletingEntry && (
        <ConfirmDelete
          open
          onClose={setDeleting()}
          onConfirm={handleDelete}
          kind="Job"
          name={
            state.deletingEntry.jobType +
            (state.deletingEntry.jobSubtype ? ' / ' + state.deletingEntry.jobSubtype : '') +
            ' ' +
            formatDate(state.deletingEntry.dateStarted) +
            ' ' +
            formatTime(state.deletingEntry.timeStarted) +
            ' - ' +
            formatTime(state.deletingEntry.timeEnded)
          }
        />
      )}
      {state.viewingEntry && (
        <Modal open onClose={setViewing()} fullScreen>
          <SectionBar
            title="Job Details"
            actions={[{ label: 'Close', onClick: setViewing() }]}
            fixedActions
          />
          <InfoTable
            data={[
              [
                {
                  label: 'Address',
                  value: getPropertyAddress(state.viewingEntry.property),
                },
              ],
              [
                {
                  label: 'Date/Time',
                  value: `${formatDate(state.viewingEntry.dateStarted)} ${formatTime(
                    state.viewingEntry.timeStarted,
                  )} - ${formatTime(state.viewingEntry.timeEnded)}`,
                },
              ],
              [
                {
                  label: 'Employee(s) Assigned',
                  value: state.viewingEntry.logTechnicianAssigned === '0' ? 'Unnassigned' : '...', // TODO
                },
              ],
              [
                {
                  label: 'Invoice Number',
                  value: state.viewingEntry.logJobNumber,
                },
              ],
              [
                {
                  label: 'PO',
                  value: state.viewingEntry.logPo,
                },
              ],
              [
                {
                  label: 'Description of Service Needed',
                  value: state.viewingEntry.description,
                },
              ],
              [
                {
                  label: 'Services Rendered',
                  value: state.viewingEntry.logServiceRendered, // TODO
                },
              ],
              ...(state.viewingEntry.notes
                ? [
                    [
                      {
                        label: 'Invoice Notes',
                        value: state.viewingEntry.notes,
                      },
                    ],
                  ]
                : []),
              ...(!!state.viewingEntry.servicesperformedrow1 || !!state.viewingEntry.totalamountrow1
                ? [
                    [
                      {
                        label: 'Services Performed (1)',
                        value: state.viewingEntry.servicesperformedrow1,
                      },
                      {
                        label: 'Total Amount (1)',
                        value: state.viewingEntry.totalamountrow1,
                      },
                    ],
                  ]
                : []),
              ...(!!state.viewingEntry.servicesperformedrow2 || !!state.viewingEntry.totalamountrow2
                ? [
                    [
                      {
                        label: 'Services Performed (2)',
                        value: state.viewingEntry.servicesperformedrow2,
                      },
                      {
                        label: 'Total Amount (2)',
                        value: state.viewingEntry.totalamountrow2,
                      },
                    ],
                  ]
                : []),
              ...(!!state.viewingEntry.servicesperformedrow3 || !!state.viewingEntry.totalamountrow3
                ? [
                    [
                      {
                        label: 'Services Performed (3)',
                        value: state.viewingEntry.servicesperformedrow3,
                      },
                      {
                        label: 'Total Amount (3)',
                        value: state.viewingEntry.totalamountrow3,
                      },
                    ],
                  ]
                : []),
              ...(!!state.viewingEntry.servicesperformedrow4 || !!state.viewingEntry.totalamountrow4
                ? [
                    [
                      {
                        label: 'Services Performed (4)',
                        value: state.viewingEntry.servicesperformedrow4,
                      },
                      {
                        label: 'Total Amount (4)',
                        value: state.viewingEntry.totalamountrow4,
                      },
                    ],
                  ]
                : []),
              ...(state.viewingEntry.materialUsed
                ? [
                    [
                      {
                        label: 'Material Used',
                        value: state.viewingEntry.materialUsed,
                      },
                    ],
                  ]
                : []),
              ...(+state.viewingEntry.discountcost !== 0
                ? [
                    [
                      {
                        label: 'Invoice Discount',
                        value: usd(+state.viewingEntry.discountcost),
                      },
                    ],
                  ]
                : []),
              ...(+state.viewingEntry.logAmountCharged !== 0
                ? [
                    [
                      {
                        label: 'Total Amount',
                        value: usd(+state.viewingEntry.logAmountCharged),
                      },
                    ],
                  ]
                : []),
            ]}
          />
        </Modal>
      )}
      {state.addingCustomerEntry && (
        <Modal open onClose={() => setAddingCustomerEntry(undefined)}>
          <Form
            title="Online Service Request"
            data={state.addingCustomerEntry}
            schema={SCHEMA_CUSTOMER_ENTRY}
            onClose={() => setAddingCustomerEntry(undefined)}
            onSave={handleCustomerAddEvent}
            intro={
              <div className="ServiceCallsCustomerIntro">
                The contact information from your online account will be used for this service
                request.
              </div>
            }
            disabled={state.saving}
          />
        </Modal>
      )}
      {state.confirmingAdded && (
        <Modal open onClose={toggleConfirmingAdded}>
          <SectionBar
            title="Thank you!"
            actions={[{ label: 'Close', onClick: toggleConfirmingAdded }]}
          />
          <div className="ServiceCallsCustomerThank">
            <p className="ServiceCallsCustomerThankIntro">
              Your service request is now active on our schedule.
            </p>
            <p>
              A Kalos Services representative will contact you shortly during regular business hours
              to confirm scheduling.
            </p>
            <p>
              For emergency requests please additionally call{' '}
              <Link href="tel:3522437088">352-243-7088</Link>.
            </p>
          </div>
        </Modal>
      )}
      {state.showText && (
        <Modal open onClose={() => handleSetState({ showText: undefined })} compact>
          <SectionBar
            actions={[
              {
                label: 'Close',
                onClick: () => handleSetState({ showText: undefined }),
              },
            ]}
          />
          <div style={{ padding: '1rem', maxWidth: 320 }}>{state.showText}</div>
        </Modal>
      )}
      {state.proposalEvent &&
        state.proposalEvent.customer != undefined &&
        state.proposalEvent.property != undefined && (
          <Modal
            open
            styles={{ minWidth: '60%' }}
            onClose={() => handleSetProposalEvent(undefined)}
          >
            <SectionBar
              title="Proposal"
              subtitle={`Proposal will be sent to ${state.proposalEvent.customer?.email}`}
              actions={[
                {
                  label: 'Close',
                  onClick: () => handleSetProposalEvent(undefined),
                },
              ]}
            >
              <Proposal
                className="mt-4"
                serviceItem={state.proposalEvent}
                property={state.proposalEvent.property!}
                customer={state.proposalEvent.customer!}
                servicesRendered={state.proposalEventPropData}
                reload={() => handleSetProposalEvent(undefined)}
              />
            </SectionBar>
          </Modal>
        )}
      {state.addingServiceCall && (
        <AddServiceCall
          propertyId={propertyId}
          userId={userID}
          openServiceCall={true}
          onClose={() => {
            handleSetState({ addingServiceCall: false, isLoaded: false });
            // load();
            // handleServiceCallAddToggle;
          }}
        />
      )}
      {state.sellSheetEntry && (
        <Modal
          open
          onClose={() => {
            handleSetState({ sellSheetEntry: undefined });
          }}
        >
          <SectionBar
            title="Sell Sheet/ AC Quote"
            actions={[
              {
                label: 'Close',
                onClick: () => handleSetState({ sellSheetEntry: undefined }),
              },
            ]}
          >
            <SellSheet
              onCreateDoc={onCreateDoc}
              propertyId={state.sellSheetEntry.propertyId!}
              jobNumber={state.sellSheetEntry.id}
            />
          </SectionBar>
        </Modal>
      )}
      {state.movingEntry && (
        <Modal
          open={state.movingEntry != undefined}
          onClose={() => {
            handleSetState({ movingEntry: undefined, isLoaded: false });
          }}
        >
          <SectionBar
            title="Select Property To Move"
            actions={[
              {
                label: 'Close',
                onClick: () => handleSetState({ movingEntry: undefined }),
              },
            ]}
          >
            <PropertiesSearch
              exceptionId={propertyId}
              onSelectProperty={(data) => {
                handleSetState({ movingEntry: undefined });
                handleMoveJobToProperty(data);
              }}
            />
          </SectionBar>
        </Modal>
      )}
      {state.editingServiceCall && (
        <Modal
          open
          onClose={() => {
            handleSetState({ editingServiceCall: false, isLoaded: false });
          }}
          fullScreen
        >
          <ServiceRequest
            propertyId={propertyId!}
            serviceCallId={state.serviceCallId}
            onClose={() => {
              handleSetState({ editingServiceCall: false, isLoaded: false });
            }}
          />
        </Modal>
      )}
    </div>
  );
};
