// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "timesheet_line.proto" (syntax proto3)
// tslint:disable
import { TimesheetLineService } from "./timesheet_line";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service TimesheetLineService
 */
export class TimesheetLineServiceClient {
    constructor(_transport) {
        this._transport = _transport;
        this.typeName = TimesheetLineService.typeName;
        this.methods = TimesheetLineService.methods;
        this.options = TimesheetLineService.options;
    }
    /**
     * @generated from protobuf rpc: Create(TimesheetLine) returns (TimesheetLine);
     */
    create(input, options) {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Get(TimesheetLine) returns (TimesheetLine);
     */
    get(input, options) {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetTimesheet(TimesheetReq) returns (Timesheet);
     */
    getTimesheet(input, options) {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGet(TimesheetLine) returns (TimesheetLineList);
     */
    batchGet(input, options) {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetPaylocityImport(DateRange) returns (PaylocityImportLineList);
     */
    batchGetPaylocityImport(input, options) {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetPaylocity(TimesheetLinePaylocity) returns (TimesheetLinePaylocityList);
     */
    batchGetPaylocity(input, options) {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetPayroll(TimesheetLine) returns (TimesheetLineList);
     */
    batchGetPayroll(input, options) {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetManager(TimesheetLine) returns (TimesheetLineList);
     */
    batchGetManager(input, options) {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Update(TimesheetLine) returns (TimesheetLine);
     */
    update(input, options) {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Delete(TimesheetLine) returns (TimesheetLine);
     */
    delete(input, options) {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Submit(SubmitApproveReq) returns (Empty);
     */
    submit(input, options) {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Approve(SubmitApproveReq) returns (Empty);
     */
    approve(input, options) {
        const method = this.methods[11], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Process(SubmitApproveReq) returns (Empty);
     */
    process(input, options) {
        const method = this.methods[12], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Reject(SubmitApproveReq) returns (Empty);
     */
    reject(input, options) {
        const method = this.methods[13], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Deny(SubmitApproveReq) returns (Empty);
     */
    deny(input, options) {
        const method = this.methods[14], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetIDsForPayroll(DateRange) returns (Int32List);
     */
    getIDsForPayroll(input, options) {
        const method = this.methods[15], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetReferenceURL(Int32) returns (String);
     */
    getReferenceURL(input, options) {
        const method = this.methods[16], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreatePaidTimeOff(PaidTimeOff) returns (PaidTimeOff);
     */
    createPaidTimeOff(input, options) {
        const method = this.methods[17], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetPaidTimeOff(PaidTimeOff) returns (PaidTimeOff);
     */
    getPaidTimeOff(input, options) {
        const method = this.methods[18], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetPaidTimeOff(PaidTimeOff) returns (PaidTimeOffList);
     */
    batchGetPaidTimeOff(input, options) {
        const method = this.methods[19], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdatePaidTimeOff(PaidTimeOff) returns (PaidTimeOff);
     */
    updatePaidTimeOff(input, options) {
        const method = this.methods[20], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeletePaidTimeOff(PaidTimeOff) returns (PaidTimeOff);
     */
    deletePaidTimeOff(input, options) {
        const method = this.methods[21], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateTimeoffRequest(TimeoffRequest) returns (TimeoffRequest);
     */
    createTimeoffRequest(input, options) {
        const method = this.methods[22], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetTimeoffRequest(TimeoffRequest) returns (TimeoffRequest);
     */
    getTimeoffRequest(input, options) {
        const method = this.methods[23], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetTimeoffRequest(TimeoffRequest) returns (TimeoffRequestList);
     */
    batchGetTimeoffRequest(input, options) {
        const method = this.methods[24], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateTimeoffRequest(TimeoffRequest) returns (TimeoffRequest);
     */
    updateTimeoffRequest(input, options) {
        const method = this.methods[25], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteTimeoffRequest(TimeoffRequest) returns (TimeoffRequest);
     */
    deleteTimeoffRequest(input, options) {
        const method = this.methods[26], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: PTOInquiry(PTO) returns (PTO);
     */
    pTOInquiry(input, options) {
        const method = this.methods[27], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetPTOInquiry(Empty) returns (PTOList);
     */
    batchGetPTOInquiry(input, options) {
        const method = this.methods[28], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetTimeoffRequestTypes(TimeoffRequestType) returns (TimeoffRequestTypeList);
     */
    getTimeoffRequestTypes(input, options) {
        const method = this.methods[29], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CreateTimeClockEntry(TimeClockEntry) returns (TimeClockEntry);
     */
    createTimeClockEntry(input, options) {
        const method = this.methods[30], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetTimeClockEntry(TimeClockEntry) returns (TimeClockEntry);
     */
    getTimeClockEntry(input, options) {
        const method = this.methods[31], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetTimeClockEntry(TimeClockEntry) returns (TimeClockEntryList);
     */
    batchGetTimeClockEntry(input, options) {
        const method = this.methods[32], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateTimeClockEntry(TimeClockEntry) returns (TimeClockEntry);
     */
    updateTimeClockEntry(input, options) {
        const method = this.methods[33], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteTimeClockEntry(TimeClockEntry) returns (TimeClockEntry);
     */
    deleteTimeClockEntry(input, options) {
        const method = this.methods[34], opt = this._transport.mergeOptions(options);
        return stackIntercept("unary", this._transport, method, opt, input);
    }
}
