// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "timesheet_department.proto" (syntax proto3)
// tslint:disable
// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "timesheet_department.proto" (syntax proto3)
// tslint:disable
import { String$ } from "./common";
import { Int32 } from "./common";
import { Empty } from "./common";
import { IntArray } from "./common";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { WireType } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
// @generated message type with reflection information, may provide speed optimized methods
class TimesheetDepartment$Type extends MessageType {
    constructor() {
        super("TimesheetDepartment", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "value", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "manager_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "is_active", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "sector_group", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "team_only", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 10, name: "parent_department_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 11, name: "default_class_code", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, value: "", description: "", managerId: 0, isActive: 0, sectorGroup: 0, fieldMask: [], pageNumber: 0, teamOnly: false, parentDepartmentId: 0, defaultClassCode: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string value */ 2:
                    message.value = reader.string();
                    break;
                case /* string description */ 3:
                    message.description = reader.string();
                    break;
                case /* int32 manager_id */ 4:
                    message.managerId = reader.int32();
                    break;
                case /* int32 is_active */ 5:
                    message.isActive = reader.int32();
                    break;
                case /* int32 sector_group */ 6:
                    message.sectorGroup = reader.int32();
                    break;
                case /* repeated string field_mask */ 7:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 8:
                    message.pageNumber = reader.int32();
                    break;
                case /* bool team_only */ 9:
                    message.teamOnly = reader.bool();
                    break;
                case /* int32 parent_department_id */ 10:
                    message.parentDepartmentId = reader.int32();
                    break;
                case /* int32 default_class_code */ 11:
                    message.defaultClassCode = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string value = 2; */
        if (message.value !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.value);
        /* string description = 3; */
        if (message.description !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.description);
        /* int32 manager_id = 4; */
        if (message.managerId !== 0)
            writer.tag(4, WireType.Varint).int32(message.managerId);
        /* int32 is_active = 5; */
        if (message.isActive !== 0)
            writer.tag(5, WireType.Varint).int32(message.isActive);
        /* int32 sector_group = 6; */
        if (message.sectorGroup !== 0)
            writer.tag(6, WireType.Varint).int32(message.sectorGroup);
        /* repeated string field_mask = 7; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(7, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 8; */
        if (message.pageNumber !== 0)
            writer.tag(8, WireType.Varint).int32(message.pageNumber);
        /* bool team_only = 9; */
        if (message.teamOnly !== false)
            writer.tag(9, WireType.Varint).bool(message.teamOnly);
        /* int32 parent_department_id = 10; */
        if (message.parentDepartmentId !== 0)
            writer.tag(10, WireType.Varint).int32(message.parentDepartmentId);
        /* int32 default_class_code = 11; */
        if (message.defaultClassCode !== 0)
            writer.tag(11, WireType.Varint).int32(message.defaultClassCode);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TimesheetDepartment
 */
export const TimesheetDepartment = new TimesheetDepartment$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TimesheetDepartmentList$Type extends MessageType {
    constructor() {
        super("TimesheetDepartmentList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TimesheetDepartment },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated TimesheetDepartment results */ 1:
                    message.results.push(TimesheetDepartment.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated TimesheetDepartment results = 1; */
        for (let i = 0; i < message.results.length; i++)
            TimesheetDepartment.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TimesheetDepartmentList
 */
export const TimesheetDepartmentList = new TimesheetDepartmentList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TimesheetDepartmentGroup$Type extends MessageType {
    constructor() {
        super("TimesheetDepartmentGroup", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "sector", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "slack_dispatch_channel_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "department_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, sector: "", slackDispatchChannelId: "", fieldMask: [], pageNumber: 0, departmentId: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string sector */ 2:
                    message.sector = reader.string();
                    break;
                case /* string slack_dispatch_channel_id */ 3:
                    message.slackDispatchChannelId = reader.string();
                    break;
                case /* repeated string field_mask */ 4:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 5:
                    message.pageNumber = reader.int32();
                    break;
                case /* int32 department_id */ 6:
                    message.departmentId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string sector = 2; */
        if (message.sector !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.sector);
        /* string slack_dispatch_channel_id = 3; */
        if (message.slackDispatchChannelId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.slackDispatchChannelId);
        /* repeated string field_mask = 4; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 5; */
        if (message.pageNumber !== 0)
            writer.tag(5, WireType.Varint).int32(message.pageNumber);
        /* int32 department_id = 6; */
        if (message.departmentId !== 0)
            writer.tag(6, WireType.Varint).int32(message.departmentId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TimesheetDepartmentGroup
 */
export const TimesheetDepartmentGroup = new TimesheetDepartmentGroup$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TimesheetDepartmentGroupList$Type extends MessageType {
    constructor() {
        super("TimesheetDepartmentGroupList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TimesheetDepartmentGroup },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated TimesheetDepartmentGroup results */ 1:
                    message.results.push(TimesheetDepartmentGroup.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated TimesheetDepartmentGroup results = 1; */
        for (let i = 0; i < message.results.length; i++)
            TimesheetDepartmentGroup.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TimesheetDepartmentGroupList
 */
export const TimesheetDepartmentGroupList = new TimesheetDepartmentGroupList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TimesheetClassCode$Type extends MessageType {
    constructor() {
        super("TimesheetClassCode", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "billable", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "classcode_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "classcode_qb_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "classcode_qb_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, description: "", billable: false, classcodeId: 0, classcodeQbId: 0, classcodeQbName: "", fieldMask: [], pageNumber: 0, isActive: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string description */ 2:
                    message.description = reader.string();
                    break;
                case /* bool billable */ 3:
                    message.billable = reader.bool();
                    break;
                case /* int32 classcode_id */ 4:
                    message.classcodeId = reader.int32();
                    break;
                case /* int32 classcode_qb_id */ 5:
                    message.classcodeQbId = reader.int32();
                    break;
                case /* string classcode_qb_name */ 6:
                    message.classcodeQbName = reader.string();
                    break;
                case /* repeated string field_mask */ 7:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 8:
                    message.pageNumber = reader.int32();
                    break;
                case /* bool is_active */ 9:
                    message.isActive = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string description = 2; */
        if (message.description !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.description);
        /* bool billable = 3; */
        if (message.billable !== false)
            writer.tag(3, WireType.Varint).bool(message.billable);
        /* int32 classcode_id = 4; */
        if (message.classcodeId !== 0)
            writer.tag(4, WireType.Varint).int32(message.classcodeId);
        /* int32 classcode_qb_id = 5; */
        if (message.classcodeQbId !== 0)
            writer.tag(5, WireType.Varint).int32(message.classcodeQbId);
        /* string classcode_qb_name = 6; */
        if (message.classcodeQbName !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.classcodeQbName);
        /* repeated string field_mask = 7; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(7, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 8; */
        if (message.pageNumber !== 0)
            writer.tag(8, WireType.Varint).int32(message.pageNumber);
        /* bool is_active = 9; */
        if (message.isActive !== false)
            writer.tag(9, WireType.Varint).bool(message.isActive);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TimesheetClassCode
 */
export const TimesheetClassCode = new TimesheetClassCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TimesheetClassCodeList$Type extends MessageType {
    constructor() {
        super("TimesheetClassCodeList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TimesheetClassCode },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated TimesheetClassCode results */ 1:
                    message.results.push(TimesheetClassCode.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated TimesheetClassCode results = 1; */
        for (let i = 0; i < message.results.length; i++)
            TimesheetClassCode.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message TimesheetClassCodeList
 */
export const TimesheetClassCodeList = new TimesheetClassCodeList$Type();
/**
 * @generated ServiceType for protobuf service TimesheetDepartmentService
 */
export const TimesheetDepartmentService = new ServiceType("TimesheetDepartmentService", [
    { name: "Create", options: {}, I: TimesheetDepartment, O: TimesheetDepartment },
    { name: "Get", options: {}, I: TimesheetDepartment, O: TimesheetDepartment },
    { name: "BatchGet", options: {}, I: TimesheetDepartment, O: TimesheetDepartmentList },
    { name: "Update", options: {}, I: TimesheetDepartment, O: TimesheetDepartment },
    { name: "Delete", options: {}, I: TimesheetDepartment, O: TimesheetDepartment },
    { name: "BatchGetDepartmentsByIds", options: {}, I: IntArray, O: TimesheetDepartmentList },
    { name: "CreateTimesheetDepartmentGroup", options: {}, I: TimesheetDepartmentGroup, O: TimesheetDepartmentGroup },
    { name: "GetTimesheetDepartmentGroup", options: {}, I: TimesheetDepartmentGroup, O: TimesheetDepartmentGroup },
    { name: "BatchGetTimesheetDepartmentGroup", options: {}, I: TimesheetDepartmentGroup, O: TimesheetDepartmentGroupList },
    { name: "UpdateTimesheetDepartmentGroup", options: {}, I: TimesheetDepartmentGroup, O: TimesheetDepartmentGroup },
    { name: "DeleteTimesheetDepartmentGroup", options: {}, I: TimesheetDepartmentGroup, O: Empty },
    { name: "CreateTimesheetClassCode", options: {}, I: TimesheetClassCode, O: TimesheetClassCode },
    { name: "GetTimesheetClassCode", options: {}, I: TimesheetClassCode, O: TimesheetClassCode },
    { name: "BatchGetTimesheetClassCode", options: {}, I: TimesheetClassCode, O: TimesheetClassCodeList },
    { name: "UpdateTimesheetClassCode", options: {}, I: TimesheetClassCode, O: TimesheetClassCode },
    { name: "DeleteTimesheetClassCode", options: {}, I: TimesheetClassCode, O: Empty },
    { name: "GetDepartmentUserIDList", options: {}, I: Int32, O: String$ }
]);
