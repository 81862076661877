import { Vendor, type VendorList } from '@kalos/kalos-rpc';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { VendorClientService } from '../../tools/helpers';
import { queryKeys } from './constants';
import { type EntityFilter } from './utils';

export type VendorQueryFilters = EntityFilter<Vendor>;

export const useVendorGetQuery = ({
  enabled,
  filters,
}: {
  enabled?: boolean;
  filters: VendorQueryFilters;
}) => {
  return useQuery({
    queryKey: [queryKeys.vendor.root, queryKeys.vendor.getVendorHash(filters)],
    queryFn: async () => {
      const req = Vendor.create(filters);
      return await VendorClientService.Get(req);
    },
    enabled,
  });
};

export const useVendorBatchQuery = <TSelectData = VendorList,>({
  select,
  filter = {},
}: {
  select?: (data?: VendorList) => TSelectData;
  filter?: VendorQueryFilters;
} = {}) => {
  return useQuery({
    queryKey: [
      queryKeys.vendor.root,
      queryKeys.vendor.list,
      queryKeys.vendor.getVendorHash(filter),
    ],
    queryFn: async () => {
      const req = Vendor.create(filter);
      return await VendorClientService.BatchGet(req);
    },
    select,
  });
};

export const useImmediateVendorGetQuery = () => {
  const queryClient = useQueryClient();
  const fetchData = useCallback(
    async (filters: VendorQueryFilters) => {
      const cache = queryClient.getQueryData<Vendor | undefined>([
        queryKeys.vendor.root,
        queryKeys.vendor.getVendorHash(filters),
      ]);
      if (cache) {
        return cache;
      }
      const req = Vendor.create(filters);
      const data = await VendorClientService.Get(req);
      queryClient.setQueryData(
        [queryKeys.vendor.root, queryKeys.vendor.getVendorHash(filters)],
        data,
      );
      return data;
    },
    [queryClient],
  );
  return fetchData;
};
