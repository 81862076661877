import { zodResolver } from '@hookform/resolvers/zod';
import { format, parse } from 'date-fns';
import { useEffect, useMemo, useRef, useState } from 'react';
import { type Path, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { z, ZodEffects, ZodNumber, ZodOptional, ZodString } from 'zod';

import { FORM_SEARCH_PARAMS_SYNC_DEBOUNCE_TIME } from '../constants';
import { debounce } from '../tools/helpers';

type BaseZodType = z.ZodString | z.ZodNumber | z.ZodDate | z.ZodBoolean;
type SchemaEntryValidator =
  | BaseZodType
  | z.ZodOptional<BaseZodType>
  | z.ZodEffects<BaseZodType>
  | z.ZodEffects<z.ZodOptional<BaseZodType>, any, unknown>;

const narrowSchema = (
  schema: SchemaEntryValidator,
): { schema: BaseZodType; wasOptional: boolean; wasEffect: boolean } => {
  if (schema instanceof ZodOptional) {
    return { schema: schema._def.innerType, wasEffect: false, wasOptional: true };
  }

  if (schema instanceof ZodEffects) {
    const tempSchema = schema._def.schema;
    return {
      schema: tempSchema instanceof ZodOptional ? tempSchema._def.innerType : tempSchema,
      wasEffect: true,
      wasOptional: tempSchema instanceof ZodOptional ? true : false,
    };
  }

  return {
    schema,
    wasOptional: false,
    wasEffect: false,
  };
};

const dateFormat = 'yyyy-MM-dd';

class SearchParamsFormCoder {
  static encodeIntoURLParam = (value: z.infer<BaseZodType>) => {
    if (value instanceof Date) {
      return format(value, dateFormat);
    }
    switch (typeof value) {
      case 'boolean':
        return value ? 'true' : 'false';
      case 'number':
        return value.toString();
      case 'string':
        return value;
    }
  };

  static decodeFromURLParam = (value: string, schema: BaseZodType) => {
    if (schema instanceof z.ZodDate) {
      return parse(value, dateFormat, new Date());
    }
    if (schema instanceof z.ZodBoolean) {
      return value === 'true';
    }
    if (schema instanceof z.ZodNumber) {
      return Number(value);
    }
    return value;
  };
}

/**
 *
 * @param schema - will be used to parse the search params and generate default values form it
 * @param defaultValues - will be used as a base object. Please make sure to memoize it properly!!!
 */
export const useGetDefaultSearchFormValues = <
  SchemaType extends z.ZodObject<
    { [x: string]: SchemaEntryValidator },
    any,
    any,
    {
      [x: string]: any;
    },
    {
      [x: string]: any;
    }
  >,
>(
  schema: SchemaType,
  defaultValues?: z.infer<SchemaType>,
) => {
  const [searchParams] = useSearchParams();
  const [defaultValueFromSearchParams] = useState(() => {
    const parsedSchema: Partial<z.infer<SchemaType>> = {};
    Object.entries(schema.shape).forEach(([key, schema]) => {
      const value = searchParams.get(key);
      if (!value) return;
      const { schema: zodSchema } = narrowSchema(schema);
      const parsedValue = SearchParamsFormCoder.decodeFromURLParam(value, zodSchema);
      Object.assign(parsedSchema, { [key]: parsedValue });
    });

    return parsedSchema;
  });

  return useMemo(
    () => ({ ...defaultValues, ...defaultValueFromSearchParams }),
    [defaultValueFromSearchParams, defaultValues],
  );
};

export const useSearchParamsForm = <
  SchemaType extends z.ZodObject<
    { [x: string]: SchemaEntryValidator },
    any,
    any,
    {
      [x: string]: any;
    },
    {
      [x: string]: any;
    }
  >,
>({
  schema,
  disableParamsSync = false,
  defaultResetValues,
  defaultValues,
  ...params
}: { schema: SchemaType; defaultResetValues?: Partial<z.infer<SchemaType>> } & Parameters<
  typeof useForm<z.infer<SchemaType>>
>['0'] & {
    disableParamsSync?: boolean;
  }) => {
  type SchemaObject = z.infer<SchemaType>;
  const form = useForm<SchemaObject>({
    resolver: zodResolver(schema),
    defaultValues,
    mode: 'onChange',
    ...params,
  });

  const initializationRef = useRef(false);

  const [searchParams, setSearchParams] = useSearchParams();

  // sync form with search params
  useEffect(() => {
    const currentFormValues = form.getValues();
    const currentSearchParamsValues = Object.fromEntries(searchParams.entries());
    Object.entries(currentFormValues).forEach(([key, value]) => {
      const searchParamValue = currentSearchParamsValues[key];
      const { schema: zodSchema } = narrowSchema(schema.shape[key]);
      if (searchParamValue === undefined) {
        if (typeof defaultValues === 'object') {
          if (
            (zodSchema instanceof ZodString && value !== '') ||
            (zodSchema instanceof ZodNumber && value !== 0)
          ) {
            const newValue = defaultValues?.[key] as SchemaObject[keyof SchemaObject];
            if (value === newValue) return;
            form.setValue(key as Path<SchemaObject>, newValue);
          }
        }

        return;
      }

      const parsedValue = SearchParamsFormCoder.decodeFromURLParam(searchParamValue, zodSchema);
      if (parsedValue === value) return;
      form.setValue(key as Path<SchemaObject>, parsedValue as SchemaObject[keyof SchemaObject]);
    });
  }, [defaultValues, form, schema.shape, searchParams]);

  // sync search params with form
  useEffect(() => {
    if (disableParamsSync) return;

    const subscription = form.watch(
      debounce((data: SchemaObject) => {
        const searchParams = new URLSearchParams();
        Object.entries(data).forEach(([key, value]) => {
          if (typeof value !== 'boolean' && !value) {
            searchParams.delete(key);
            return;
          }
          const encodedValue = SearchParamsFormCoder.encodeIntoURLParam(value);
          searchParams.set(key, encodedValue);
        });
        setSearchParams((prev) => {
          Object.entries(data).forEach(([key, value]) => {
            if (!value) {
              prev.delete(key);
              return;
            }
          });
          // combine prev and new search params
          const newSearchParams = new URLSearchParams(prev);
          searchParams.forEach((value, key) => {
            newSearchParams.set(key, value);
          });
          return newSearchParams;
        });
      }, FORM_SEARCH_PARAMS_SYNC_DEBOUNCE_TIME),
    );

    return () => {
      subscription.unsubscribe();
    };
  }, [disableParamsSync, form, setSearchParams]);

  // initialize form with search params
  useEffect(() => {
    if (!initializationRef.current && !disableParamsSync) {
      const parsedSchema: Partial<z.infer<SchemaType>> = {};
      Object.entries(schema.shape).forEach(([key, schema]) => {
        const value = searchParams.get(key);
        if (!value) return;
        const { schema: zodSchema } = narrowSchema(schema);
        const parsedValue = SearchParamsFormCoder.decodeFromURLParam(value, zodSchema);
        Object.assign(parsedSchema, { [key]: parsedValue });
      });
      form.reset(parsedSchema);
      initializationRef.current = true;
    }
  }, [disableParamsSync, form, schema.shape, searchParams]);

  const _reset = useMemo(() => {
    if (!defaultResetValues) return form.reset;

    return (
      values?: Parameters<typeof form.reset>['0'],
      keepStateOptions?: Parameters<typeof form.reset>['1'],
    ) => {
      form.reset({ ...defaultResetValues, ...values }, keepStateOptions);
    };
  }, [defaultResetValues, form]);

  return useMemo(() => ({ ...form, reset: _reset }), [_reset, form]);
};
