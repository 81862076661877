import { Property, PropertyClient, User, UserClient } from '@kalos/kalos-rpc';
import { DataTablePagination } from '@kalos/ui';
import { type FC, useCallback, useState } from 'react';

import { ENDPOINT } from '../../../constants';
import { makeFakeRows } from '../../../tools/helpers';
import { Form, type Options, type Schema } from '../Form';
import { type Columns, type Data, InfoTable } from '../InfoTable';
import { Modal } from '../Modal';
import { PlainForm } from '../PlainForm';

const UserClientService = new UserClient(ENDPOINT);
const PropertyClientService = new PropertyClient(ENDPOINT);

const USER_SCHEMA: Schema<User> = [
  [
    { label: 'First Name', name: 'firstname', type: 'search' },
    { label: 'Last Name', name: 'lastname', type: 'search' },
    { label: 'Business Name', name: 'businessname', type: 'search' },
    { label: 'Primary Phone', name: 'phone', type: 'search' },
    { label: 'Email', name: 'email', type: 'search' },
  ],
];

const PROPERTY_SCHEMA: Schema<Property> = [
  [{ label: 'Filter', headline: true }],
  [
    { label: 'Address', name: 'address', type: 'search' },
    { label: 'Subdivision', name: 'subdivision', type: 'search' },
    { label: 'City', name: 'city', type: 'search' },
    { label: 'Zip Code', name: 'zip', type: 'search' },
  ],
  [
    { label: 'Owner First Name', name: 'firstname', type: 'search' },
    { label: 'Owner Last Name', name: 'lastname', type: 'search' },
    { label: 'Owner Business Name', name: 'businessname', type: 'search' },
    { label: 'Owner Primary Phone', name: 'phone', type: 'search' },
    { label: 'Owner Email', name: 'email', type: 'search' },
  ],
  [{ label: 'Results', headline: true }],
];

export type Kind = 'Customers' | 'Properties';

interface Props {
  kinds: Kind[];
  kind?: Kind;
  open: boolean;
  onClose: () => void;
  onSelect: (entry: User | Property) => void;
  excludeId?: number;
}

const kindsByName: { [key in Kind]: number } = {
  Customers: 1,
  Properties: 2,
} as const;

const columnsByName: { [key in Kind]: Columns } = {
  Customers: [
    { name: 'Name' },
    { name: 'Business Name' },
    { name: 'Primary Phone' },
    { name: 'Email' },
  ],
  Properties: [{ name: 'Address' }, { name: 'Subdivision' }, { name: 'Owner' }],
};

const PER_PAGE = 25;

export const Search: FC<Props> = ({
  kinds,
  open,
  onClose,
  onSelect,
  excludeId,
  kind: propKind,
}: Props) => {
  const searchOptions: Options = kinds.map((kind) => ({
    label: kind,
    value: kindsByName[kind],
  }));
  const [loading, setLoading] = useState<boolean>(false);
  const [propertySearch, setPropertySearch] = useState<Property>(Property.create());
  const [userSearch, setUserSearch] = useState<User>(User.create());
  const [kind, setKind] = useState<Kind>(propKind || 'Customers');
  const [entries, setEntries] = useState<User[] | Property[]>([]);
  const [isSearched, setIsSearched] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState<number>(0);

  const isNumber = (value: string | number) => typeof value === 'number';

  const load = useCallback(
    async (searchInput: User | Property) => {
      setLoading(true);
      if (kind === 'Customers') {
        const req = User.create();
        if (searchInput.firstname) req.firstname = searchInput.firstname;
        if (searchInput.lastname) req.lastname = searchInput.lastname;
        if (searchInput.businessname) req.businessname = searchInput.businessname;
        if (searchInput.phone) req.phone = searchInput.phone;
        if (searchInput.email) req.email = searchInput.email;
        req.isActive = 1;
        req.pageNumber = page;
        const res = await UserClientService.BatchGet(req);
        setTotalCount(res?.totalCount || 0);
        setEntries(res.results);
      } else if (kind === 'Properties') {
        const req = {
          ...searchInput,
        } as Property;
        if (req.directions) {
          req.fieldMask.push('Directions');
        }
        if (req.address) {
          req.address = `%${req.address}%`;
        }
        if (req.subdivision) {
          req.subdivision = `%${req.subdivision}%`;
        }
        if (req.city) {
          req.city = `%${req.city}%`;
        }
        req.fieldMask = [];
        req.pageNumber = page;
        const res = await PropertyClientService.BatchGet(Property.clone(req));
        const propertyEntries = res!.results.filter((e) => e.id !== excludeId);
        setTotalCount(res?.totalCount || 0);
        setEntries(propertyEntries);
      }
      setLoading(false);
    },
    [kind, excludeId, page],
  );

  const handleSetKind = useCallback(
    (newKind: Kind) => {
      setKind(newKind);
    },
    [setKind],
  );

  const handlePropertySearch = useCallback(
    (search: Property) => {
      setPropertySearch(search);
      load(search);
    },
    [setPropertySearch, load],
  );

  const handleUserSearch = useCallback(
    (search: User) => {
      setUserSearch(search);
      load(search as User);
    },
    [load],
  );

  const handleSetUserSearch = useCallback(
    (newUserSearch: User) => {
      setUserSearch(newUserSearch);
    },
    [setUserSearch],
  );

  const handleSetPropertySearch = useCallback(
    (newPropertySearch: Property) => {
      setPropertySearch(newPropertySearch);
    },
    [setPropertySearch],
  );

  const handleChangeKind = useCallback(
    (newKind: Kind) => {
      if (newKind === 'Customers') handleUserSearch(userSearch);
      if (newKind === 'Properties') handlePropertySearch(propertySearch);
    },
    [handlePropertySearch, handleUserSearch, propertySearch, userSearch],
  );

  const handleSelect = useCallback(
    (entry: User | Property) => {
      onSelect(entry);
    },
    [onSelect],
  );

  const data: Data = loading
    ? makeFakeRows()
    : entries!.map((entry) => {
        if (kind === 'Customers') {
          return [
            {
              value: `${entry.firstname} ${entry.lastname}`,
              onClick: () => handleSelect(entry),
            },
            {
              value: entry.businessname,
              onClick: () => handleSelect(entry),
            },
            {
              value: entry.phone,
              onClick: () => handleSelect(entry),
            },
            {
              value: entry.email,
              onClick: () => handleSelect(entry),
            },
          ];
        }
        if (kind === 'Properties') {
          return [
            {
              value: `${entry.address}, ${entry.city}, ${entry.state} ${entry.zip}`,
              onClick: () => handleSelect(entry),
            },
            {
              value: (entry as Property).subdivision,
              onClick: () => handleSelect(entry),
            },
            {
              value: (
                <>
                  {entry.firstname} {entry.lastname}
                  {entry.businessname ? `, ${entry.businessname}` : ''}
                  {(entry.phone || entry.email) && <br />}
                  {entry.phone}
                  {entry.phone && entry.email && ', '}
                  {entry.email}
                </>
              ),
              onClick: () => handleSelect(entry),
            },
          ];
        }
        return [];
      });

  const FILTER_SCHEMA: Schema<{ kind: Kind }> = [
    [
      {
        label: 'Search',
        name: 'kind',
        options: searchOptions,
        onChange: (newKind) => handleChangeKind(newKind === 1 ? 'Customers' : 'Properties'), // TODO extend this to work with more options in the future
      },
    ],
  ];

  const handleClose = () => {
    setPropertySearch(Property.create());
    setUserSearch(User.create());
    setEntries([]);
    onClose();
    setIsSearched(false);
    !propKind && setKind('Customers');
    setPage(0);
    setTotalCount(0);
  };

  const pagesCount = Math.ceil(totalCount / PER_PAGE);

  const newSetPage: React.Dispatch<React.SetStateAction<number>> = (updater) => {
    setPage((prev) => {
      const newValue = typeof updater === 'function' ? updater(prev) : updater;
      handleChangePage(newValue);
      return newValue;
    });
  };

  const handleChangePage = useCallback(
    (page: number) => {
      setPage(page);
      if (kind === 'Properties') {
        handleSetPropertySearch(propertySearch);
        handlePropertySearch(propertySearch);
      } else {
        handleSetUserSearch(userSearch);
        handleUserSearch(userSearch);
      }
      setIsSearched(true);
    },
    [
      kind,
      handleSetPropertySearch,
      handlePropertySearch,
      handleSetUserSearch,
      handleUserSearch,
      propertySearch,
      userSearch,
    ],
  );

  return (
    <div key={kind}>
      <Modal open={open} onClose={onClose} fullScreen>
        {!propKind && (
          <PlainForm
            schema={FILTER_SCHEMA}
            data={{
              kind: (isNumber(kind) ? kind : kind === 'Customers' ? 1 : 2) as unknown as Kind,
            }}
            key={kind}
            onChange={(changed) =>
              handleSetKind(Number(changed.kind) == 1 ? 'Customers' : 'Properties')
            }
          />
        )}
        {kind === 'Customers' ? (
          <Form
            title="Search"
            submitLabel="Search"
            cancelLabel="Close"
            schema={USER_SCHEMA}
            data={userSearch}
            onClose={handleClose}
            onClear={(name) => {
              const temp: User = { ...userSearch, [name]: '' };
              handleSetUserSearch(temp);
              handleUserSearch(temp);
              setIsSearched(false);
            }}
            onSave={(search: User | Property) => {
              setPage(0);
              handleSetUserSearch(search as User);
              handleUserSearch(search as User);
              setIsSearched(true);
            }}
          ></Form>
        ) : (
          <Form
            title="Search"
            submitLabel="Search"
            cancelLabel="Close"
            schema={PROPERTY_SCHEMA}
            data={propertySearch}
            onClose={handleClose}
            onClear={(name) => {
              const temp: Property = { ...propertySearch, [name]: '' };
              handleSetPropertySearch(temp);
              handlePropertySearch(temp);
              setIsSearched(false);
            }}
            onSave={(search: User | Property) => {
              setPage(0);
              handleSetPropertySearch(search as Property);
              handlePropertySearch(search as Property);
              setIsSearched(true);
            }}
          ></Form>
        )}
        <div className="flex flex-col px-8">
          <div className="-ml-2 flex items-center gap-3 pb-3">
            <DataTablePagination
              pageCount={pagesCount}
              currentPage={page}
              setPage={newSetPage}
              disabled={loading}
            />
            <span>
              Total {kind} count: {totalCount}
            </span>
          </div>
          <InfoTable
            columns={columnsByName[kind]}
            data={data}
            loading={loading}
            hoverable
            isSearched={isSearched}
          />
        </div>
      </Modal>
    </div>
  );
};
