import './CustomerInformation.module.less';

import { User } from '@kalos/kalos-rpc';
import { Button } from '@kalos/ui';
import { useQueryClient } from '@tanstack/react-query';
import { type FC, useCallback, useState } from 'react';
import { z } from 'zod';

import { NULL_TIME } from '../../../constants';
import { queryKeys } from '../../../hooks/react-query/constants';
import { usePendingBillingBatchGetQuery } from '../../../hooks/react-query/usePendingBillingQuery';
import { usePropertiesGetQuery } from '../../../hooks/react-query/usePropertiesQuery';
import { useUserGroupLinksQuery } from '../../../hooks/react-query/useUserGroupLinksQuery';
import { useUserQuery } from '../../../hooks/react-query/useUserQuery';
import { useStrictParams } from '../../../hooks/useStrictParams';
import { useNavigate } from '../../../react-router';
import { formatDateTime, UserClientService } from '../../../tools/helpers';
import { Alert } from '../Alert';
import { ConfirmDelete } from '../ConfirmDelete';
import { CustomerEdit } from '../CustomerEdit';
import { type Data, InfoTable } from '../InfoTable';
import { Modal } from '../Modal';
import { SectionBar } from '../SectionBar';
import { CustomerNotificationDialog } from './NotificationDialog';

interface Props {
  userID: number;
  viewedAsCustomer?: boolean;
  propertyId: number;
  children?: React.ReactNode;
  onClose?: () => void;
  onDelete?: () => void;
}

const paramsSchema = z.object({
  propertyId: z.coerce.number(),
});

export const CustomerInformationWithPropertyPage = () => {
  const { propertyId } = useStrictParams(paramsSchema);
  const propertyQuery = usePropertiesGetQuery({
    filter: {
      id: propertyId,
    },
  });
  return <CustomerInformation propertyId={propertyId} userID={propertyQuery.data?.userId ?? 0} />;
};

export const CustomerInformation: FC<Props> = ({
  userID,
  children,
  onClose,
  propertyId,
  onDelete,
}) => {
  const queryClient = useQueryClient();

  interface State {
    editing: boolean;
    saving: boolean;
    deleting: boolean;
    isLoading: boolean;
    deletingSuccess: boolean;
  }

  const initialState: State = {
    editing: false,
    saving: false,
    deleting: false,
    isLoading: false,
    deletingSuccess: false,
  };

  const [state, setState] = useState<State>(initialState);
  const navigate = useNavigate();

  const handleSetState = useCallback(function handleSetState(partialState: Partial<State>) {
    setState((prevState) => {
      return { ...prevState, ...partialState };
    });
  }, []);

  const handleToggleEditing = useCallback(() => {
    setState((prev) => ({ ...prev, editing: !prev.editing }));
  }, []);

  const customerQuery = useUserQuery({
    enabled: !!userID,
    filters: {
      id: userID,
    },
  });

  const groupLinksQuery = useUserGroupLinksQuery({
    filter: {
      userId: userID,
    },
    select(data) {
      if (data)
        return {
          results: data.results,
          userGroupIds: data.results.map((el) => el.groupId),
        };
      return {
        results: [],
        userGroupIds: [],
      };
    },
  });

  const pendingBillingQuery = usePendingBillingBatchGetQuery({
    filter: {
      userId: userID,
    },
    select(data) {
      return data?.totalCount ?? 0;
    },
  });

  const isPendingBilling = pendingBillingQuery.isSuccess ? pendingBillingQuery.data > 0 : false;

  const handleResetGroups = useCallback(async () => {
    queryClient.invalidateQueries({
      queryKey: [queryKeys.userGroupLinks.root],
    });
  }, [queryClient]);

  const handleSetDeleting = useCallback(
    (deleting: boolean) => {
      handleSetState({ deleting });
    },
    [handleSetState],
  );

  const handleDelete = useCallback(async () => {
    // TODO: delete customer related data?
    queryClient.invalidateQueries({
      queryKey: [queryKeys.user.root],
    });
    const entry = User.create();
    if (customerQuery.data?.id) {
      entry.id = customerQuery.data?.id;
    }
    await UserClientService.Delete(entry);
    handleSetState({ deleting: false, isLoading: false, deletingSuccess: true });
    onDelete?.();
  }, [queryClient, customerQuery.data?.id, handleSetState, onDelete]);

  const data: Data = [
    [
      {
        label: 'Name',
        value: `${customerQuery.data?.firstname ?? ''} ${customerQuery.data?.lastname ?? ''}`,
      },
      { label: 'Business Name', value: customerQuery.data?.businessname ?? '' },
    ],
    [
      { label: 'Primary Phone', value: customerQuery.data?.phone, href: 'tel' },
      { label: 'Cell Phone', value: customerQuery.data?.cellphone, href: 'tel' },
    ],
    [
      { label: 'Alternate Phone', value: customerQuery.data?.altphone, href: 'tel' },
      { label: 'Fax', value: customerQuery.data?.fax },
    ],
    [
      {
        label: 'Billing Address',
        value: `${customerQuery.data?.address ?? ''}, ${customerQuery.data?.city ?? ''}, ${
          customerQuery.data?.state ?? ''
        } ${customerQuery.data?.zip ?? ''}`,
      },
      { label: 'Email', value: customerQuery.data?.email, href: 'mailto' },
    ],
  ];

  const systemData: Data = [
    [
      {
        label: 'Created',
        value: customerQuery.data?.dateCreated
          ? formatDateTime(customerQuery.data.dateCreated)
          : '',
      },
    ],
    [
      {
        label: 'Last Login',
        value:
          !customerQuery.data?.lastLogin ||
          customerQuery.data?.lastLogin === null ||
          customerQuery.data?.lastLogin === NULL_TIME
            ? 'No Login Info'
            : formatDateTime(customerQuery.data.lastLogin),
      },
    ],
    [
      {
        label: 'Login ID',
        value: customerQuery.data?.login,
      },
    ],
    [
      {
        label: 'Wishes to receive promotional emails',
        value: customerQuery.data?.receiveemail ? 'Yes' : 'No',
      },
    ],
  ];
  return (
    <>
      <div className="flex flex-col lg:flex-row lg:gap-4">
        <div className="mb-4 flex-1 rounded-md border border-gray-300 lg:mb-0">
          <div className="flex items-center justify-between bg-gray-200 p-2 ">
            <h2 className="text-2xl">Customer Information</h2>
            <div className="flex flex-wrap gap-2">
              <CustomerNotificationDialog
                trigger={
                  <Button
                    disabled={!customerQuery.data?.isActive || customerQuery.isFetching}
                    size="sm"
                    className="flex-shrink-0"
                  >
                    {customerQuery.isFetching
                      ? 'Loading...'
                      : customerQuery.data?.notification && customerQuery.data?.notification != ''
                        ? 'Notification'
                        : 'Add Notification'}
                  </Button>
                }
                customerId={userID}
                showOnMount
              />

              <Button
                disabled={!customerQuery.data?.isActive}
                onClick={handleToggleEditing}
                size="sm"
                className="flex-shrink-0"
              >
                Edit
              </Button>
              <Button
                disabled={!customerQuery.data?.isActive}
                onClick={() => handleSetDeleting(true)}
                size="sm"
                className="flex-shrink-0"
              >
                Delete
              </Button>
              {onClose && (
                <Button onClick={onClose} size="sm" className="flex-shrink-0">
                  Close
                </Button>
              )}
            </div>
          </div>

          {customerQuery.data?.isActive ? (
            <InfoTable data={data} loading={customerQuery.data?.id === 0} />
          ) : null}
        </div>
        <div className="w-full rounded-md border border-gray-300 lg:w-[470px]">
          <SectionBar title="System Information" uncollapsable>
            {customerQuery.data?.isActive ? (
              <InfoTable data={systemData} loading={customerQuery.data?.id === 0} />
            ) : (
              ''
            )}
          </SectionBar>
          <SectionBar
            title="Pending Billing"
            uncollapsable
            className="mb-2"
            disabled={isPendingBilling}
            actions={[
              {
                label: isPendingBilling ? 'View' : 'None',
                disabled: !isPendingBilling,
                url: !isPendingBilling
                  ? propertyId
                    ? `/properties/${propertyId}/${userID}`
                    : ''
                  : '',
              },
            ]}
          >
            <InfoTable
              data={[
                [
                  {
                    label: 'Record Count',
                    value: pendingBillingQuery.data ?? 0,
                  },
                ],
              ]}
              loading={pendingBillingQuery.isPending}
            />
          </SectionBar>
        </div>
      </div>
      {children}
      <Modal open={state.editing} onClose={handleToggleEditing}>
        <CustomerEdit
          userId={customerQuery.data?.id}
          onSave={(customer) => {
            queryClient.invalidateQueries({
              queryKey: [queryKeys.user.root],
            });
            queryClient.invalidateQueries({
              queryKey: [queryKeys.userGroupLinks.root],
            });
            handleSetState({ editing: false });
            handleResetGroups();
          }}
          onClose={handleToggleEditing}
          customer={customerQuery.data}
          groupLinks={groupLinksQuery.data?.results ?? []}
        />
      </Modal>
      <ConfirmDelete
        open={state.deleting}
        disabled={customerQuery.isPending}
        onClose={() => handleSetDeleting(false)}
        onConfirm={handleDelete}
        kind="Customer"
        name={`${customerQuery.data?.firstname} ${customerQuery.data?.lastname}`}
        text=" Pending jobs, invoices and documents will not longer be accessible"
      />
      <Alert
        open={state.deletingSuccess}
        onClose={() => {
          handleSetState({ deletingSuccess: false });
          navigate('/customers');
        }}
        title="Successful Deleting"
      >
        Customer deletion was successful!
      </Alert>
    </>
  );
};
