import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from '@kalos/ui';
import { useState } from 'react';

import { FileForm } from '../../../../../../components/FileForm';
import { useCreatePermitDocumentMutation } from '../../../../../../hooks/react-query/usePermits';

export const PermitDocumentCreateDialog = ({
  trigger,
  permitId,
}: {
  trigger: React.ReactNode;
  permitId: number;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const createDocumentMutation = useCreatePermitDocumentMutation();

  const createFile: React.ComponentProps<typeof FileForm>['onSave'] = async ({
    fileData,
    filename,
  }) => {
    try {
      await createDocumentMutation.mutateAsync({
        fileData: fileData,
        fileName: filename,
        permitId,
      });
      setIsOpen(false);
    } catch (err) {
      alert('File could not be uploaded');
      console.error(err);
    }
  };

  return (
    <Dialog onOpenChange={setIsOpen} open={isOpen}>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Create Document</DialogTitle>
        </DialogHeader>
        <FileForm
          onSave={createFile}
          disabled={createDocumentMutation.isPending}
          isLoading={createDocumentMutation.isPending}
        />
      </DialogContent>
    </Dialog>
  );
};
