import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useEffect, useState } from 'react';

import { type LocalStorage, localStorageManager } from '../tools/storageManager';
import useEventCallback from './useEventCallback';
import useEventListener from './useEventListener';

declare global {
  interface WindowEventMap {
    'local-storage': CustomEvent;
  }
}

type UseLocalStorageOptions<T extends keyof LocalStorage> = {
  serializer?: (value: LocalStorage[T]) => string;
  deserializer?: (value: string) => LocalStorage[T];
  initializeWithValue?: boolean;
};

const IS_SERVER = typeof window === 'undefined';

export function useLocalStorage<K extends keyof LocalStorage>(
  key: K,
  initialValue: LocalStorage[K] | (() => LocalStorage[K]),
  options: UseLocalStorageOptions<K> = {},
): [LocalStorage[K], Dispatch<SetStateAction<LocalStorage[K]>>, () => void] {
  const { initializeWithValue = true, serializer, deserializer } = options;

  const readValue = useCallback((): LocalStorage[K] => {
    const initialValueToUse = initialValue instanceof Function ? initialValue() : initialValue;

    if (IS_SERVER) {
      return initialValueToUse;
    }

    try {
      const value = localStorageManager.get(key, deserializer);
      return value !== undefined ? value : initialValueToUse;
    } catch (error) {
      console.warn(`Error reading localStorage key “${key}”:`, error);
      return initialValueToUse;
    }
  }, [initialValue, key, deserializer]);

  const [storedValue, setStoredValue] = useState<LocalStorage[K]>(() => {
    return initializeWithValue
      ? readValue()
      : initialValue instanceof Function
        ? initialValue()
        : initialValue;
  });

  const setValue: Dispatch<SetStateAction<LocalStorage[K]>> = useEventCallback((value) => {
    if (IS_SERVER) {
      console.warn(
        `Tried setting localStorage key “${key}” even though environment is not a client`,
      );
      return;
    }

    try {
      const newValue = value instanceof Function ? value(storedValue) : value;
      localStorageManager.set(key, newValue, serializer);
      setStoredValue(newValue);
      window.dispatchEvent(new StorageEvent('local-storage', { key: key as string }));
    } catch (error) {
      console.warn(`Error setting localStorage key “${key}”:`, error);
    }
  });

  const removeValue = useEventCallback(() => {
    if (IS_SERVER) {
      console.warn(
        `Tried removing localStorage key “${key}” even though environment is not a client`,
      );
    }

    const defaultValue = initialValue instanceof Function ? initialValue() : initialValue;
    localStorageManager.remove(key as string);
    setStoredValue(defaultValue);
    window.dispatchEvent(new StorageEvent('local-storage', { key: key as string }));
  });

  useEffect(() => {
    setStoredValue(readValue());
  }, [key, readValue]);

  const handleStorageChange = useCallback(
    (event: StorageEvent | CustomEvent) => {
      if ((event as StorageEvent).key && (event as StorageEvent).key !== key) {
        return;
      }
      setStoredValue(readValue());
    },
    [key, readValue],
  );

  useEventListener('storage', handleStorageChange);
  useEventListener('local-storage', handleStorageChange);

  return [storedValue, setValue, removeValue];
}
