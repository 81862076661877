import { RpcError } from '@protobuf-ts/runtime-rpc';
import { QueryCache, QueryClient } from '@tanstack/react-query';

import { outsideAuthSignout, outsideAuthUpdateToken } from '../App';

const handleAuthIssue = async () => {
  try {
    await outsideAuthUpdateToken?.();
    return true;
  } catch (err) {
    outsideAuthSignout?.();
    // then in RequireAuth component user will be redirected to login page
  }
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      retry(failureCount) {
        const shouldRetry = failureCount <= 1;
        return shouldRetry;
      },
    },
    mutations: {
      async onError(error) {
        if (error instanceof RpcError && error.code === 'UNAUTHENTICATED') {
          console.log('Handling unauthenticated error - signing off', error);
          await handleAuthIssue();
        } else {
          console.log('Unexpected Error from mutation', { error });
        }
      },
    },
  },
  queryCache: new QueryCache({
    async onError(error, query) {
      console.log('onError queryCache', { error, query });
      if (error instanceof RpcError && error.code === 'UNAUTHENTICATED') {
        if (await handleAuthIssue()) {
          query.fetch().catch(console.log);
        }
      } else {
        console.log('Unexpected Error from query', { error, query });
      }
    },
  }),
});
