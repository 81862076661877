import { BaseClient } from '../BaseClient';
import { EventAssignment, EventAssignmentList } from '../compiled-protos/event';
import { EventServiceClient as EventAssignmentServiceClient } from '../compiled-protos/event.client';

// Unsure if using correct property value for "self: here"
class EventAssignmentClient extends BaseClient {
  self: EventAssignmentServiceClient;

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new EventAssignmentServiceClient(this.transport);
  }

  public async Create(req: EventAssignment) {
    let res = EventAssignment.create();
    try {
      res = await this.self.createEventAssignment(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Get(req: EventAssignment) {
    let res = EventAssignment.create();
    try {
      res = await this.self.getEventAssignment(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Update(req: EventAssignment) {
    let res = EventAssignment.create();
    try {
      res = await this.self.updateEventAssignment(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Delete(req: EventAssignment) {
    let res = EventAssignment.create();
    try {
      res = await this.self.deleteEventAssignment(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async BatchGet(req: EventAssignment) {
    let res = EventAssignmentList.create();
    try {
      res = await this.self.batchGetEventAssignment(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }
}

export { EventAssignment, EventAssignmentList, EventAssignmentClient };
