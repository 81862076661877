// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "phone_call_log.proto" (syntax proto3)
// tslint:disable
// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "phone_call_log.proto" (syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { WireType } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
// @generated message type with reflection information, may provide speed optimized methods
class PhoneCallLog$Type extends MessageType {
    constructor() {
        super("PhoneCallLog", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "jive_call_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "call_timestamp", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "dialed_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "caller_id_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "caller_id_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "phone_call_recording_link", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "page_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, jiveCallId: "", callTimestamp: "", dialedNumber: "", callerIdName: "", callerIdNumber: "", phoneCallRecordingLink: "", fieldMask: [], pageNumber: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string jive_call_id */ 2:
                    message.jiveCallId = reader.string();
                    break;
                case /* string call_timestamp */ 3:
                    message.callTimestamp = reader.string();
                    break;
                case /* string dialed_number */ 4:
                    message.dialedNumber = reader.string();
                    break;
                case /* string caller_id_name */ 5:
                    message.callerIdName = reader.string();
                    break;
                case /* string caller_id_number */ 6:
                    message.callerIdNumber = reader.string();
                    break;
                case /* string phone_call_recording_link */ 7:
                    message.phoneCallRecordingLink = reader.string();
                    break;
                case /* repeated string field_mask */ 8:
                    message.fieldMask.push(reader.string());
                    break;
                case /* int32 page_number */ 9:
                    message.pageNumber = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string jive_call_id = 2; */
        if (message.jiveCallId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.jiveCallId);
        /* string call_timestamp = 3; */
        if (message.callTimestamp !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.callTimestamp);
        /* string dialed_number = 4; */
        if (message.dialedNumber !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.dialedNumber);
        /* string caller_id_name = 5; */
        if (message.callerIdName !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.callerIdName);
        /* string caller_id_number = 6; */
        if (message.callerIdNumber !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.callerIdNumber);
        /* string phone_call_recording_link = 7; */
        if (message.phoneCallRecordingLink !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.phoneCallRecordingLink);
        /* repeated string field_mask = 8; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(8, WireType.LengthDelimited).string(message.fieldMask[i]);
        /* int32 page_number = 9; */
        if (message.pageNumber !== 0)
            writer.tag(9, WireType.Varint).int32(message.pageNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PhoneCallLog
 */
export const PhoneCallLog = new PhoneCallLog$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PhoneCallLogList$Type extends MessageType {
    constructor() {
        super("PhoneCallLogList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PhoneCallLog },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated PhoneCallLog results */ 1:
                    message.results.push(PhoneCallLog.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated PhoneCallLog results = 1; */
        for (let i = 0; i < message.results.length; i++)
            PhoneCallLog.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PhoneCallLogList
 */
export const PhoneCallLogList = new PhoneCallLogList$Type();
/**
 * @generated ServiceType for protobuf service PhoneCallLogService
 */
export const PhoneCallLogService = new ServiceType("PhoneCallLogService", [
    { name: "Create", options: {}, I: PhoneCallLog, O: PhoneCallLog },
    { name: "Get", options: {}, I: PhoneCallLog, O: PhoneCallLog },
    { name: "BatchGet", options: {}, I: PhoneCallLog, O: PhoneCallLogList },
    { name: "Update", options: {}, I: PhoneCallLog, O: PhoneCallLog },
    { name: "Delete", options: {}, I: PhoneCallLog, O: PhoneCallLog }
]);
