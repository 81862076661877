import { type Event, SpiffOption } from '@kalos/kalos-rpc';
import {
  Button,
  DataTable,
  DataTableColumnHeader,
  DataTablePagination,
  Form,
  toast,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  useConfirm,
} from '@kalos/ui';
import { Pencil1Icon, TrashIcon } from '@radix-ui/react-icons';
import { type ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { getLoadingColumns } from '../../../../../components/utils';
import { FORM_AUTOMATIC_SEARCH_DEBOUNCE_TIME, ROWS_PER_PAGE } from '../../../../../constants';
import {
  type SpiffOptionFilter,
  useSpiffOptionBatchGetQuery,
  useSpiffOptionDeleteMutation,
} from '../../../../../hooks/react-query/useTaskClientServiceQuery';
import { debounce } from '../../../../../tools/helpers';
import { CreateSpiffOptionDialog } from './CreateSpiffOptionDialog';
import { EditSpiffOptionDialog } from './EditSpiffOptionDialog';
import {
  getDefaultPieceWorkSearchFormValues,
  PieceWorkOptionsSearchForm,
  type PieceWorkSearchScheme,
  usePieceWorkSearchForm,
} from './PieceWorkOptionsSearchForm';

const staticSpiffOptions = Array.from({ length: 3 }, (_, i) => SpiffOption.create());

const DeleteAction = ({ id }: { id: SpiffOption['id'] }) => {
  const confirm = useConfirm();

  const useDeletePieceWorkMutation = useSpiffOptionDeleteMutation();

  const onDeleteClick = useCallback(async () => {
    try {
      if (
        await confirm({
          title: 'Are you sure you want to delete this piece work?',
          actionButton: 'Delete',
          cancelButton: 'Cancel',
        })
      ) {
        await useDeletePieceWorkMutation.mutateAsync(SpiffOption.create({ id }));
        toast({
          variant: 'success',
          title: 'Success',
          description: 'Piece Work Deleted',
        });
      }
    } catch (err) {
      console.error(err);
      toast({
        variant: 'destructive',
        title: 'Error',
        description: 'Something went wrong during deletion',
      });
    }
  }, [confirm, id, useDeletePieceWorkMutation]);

  return (
    <Tooltip>
      <TooltipContent>Delete</TooltipContent>
      <TooltipTrigger asChild>
        <Button onClick={onDeleteClick} variant="secondary" size="icon">
          <TrashIcon />
        </Button>
      </TooltipTrigger>
    </Tooltip>
  );
};

const transformPieceWorkSearchIntoFilter = (search: PieceWorkSearchScheme): SpiffOptionFilter => {
  return {
    name: search.name ? `%${search.name}%` : undefined,
    code: search.code ? `%${search.code}%` : undefined,
    amount: Number(search.amount),
  };
};

export const PieceWorkOptionsTable = ({ eventId }: { eventId: Event['id'] }) => {
  const [page, setPage] = useState(0);
  const [totalEntries, setTotalEntries] = useState(0);
  const searchForm = usePieceWorkSearchForm({
    defaultValues: useMemo(() => getDefaultPieceWorkSearchFormValues(), []),
  });
  const [filter, setFilter] = useState<SpiffOptionFilter>(
    transformPieceWorkSearchIntoFilter(searchForm.getValues()),
  );

  const pieceWorkOptionsQuery = useSpiffOptionBatchGetQuery({
    filter: {
      isActive: true,
      eventId,
      pageNumber: page,
      ...filter,
    },
  });

  useEffect(() => {
    if (pieceWorkOptionsQuery.isSuccess) {
      setTotalEntries(pieceWorkOptionsQuery.data?.totalCount ?? 0);
    }
  }, [pieceWorkOptionsQuery.data?.totalCount, pieceWorkOptionsQuery.isSuccess]);

  useEffect(() => {
    const debouncedUpdate = debounce((data: PieceWorkSearchScheme) => {
      setPage(0);
      setTotalEntries(0);
      setFilter(transformPieceWorkSearchIntoFilter(data));
    }, FORM_AUTOMATIC_SEARCH_DEBOUNCE_TIME);
    const updateSubscription = searchForm.watch(debouncedUpdate);
    return () => updateSubscription.unsubscribe();
  }, [setPage, searchForm]);

  const tableColumns = useMemo<ColumnDef<SpiffOption>[]>(() => {
    return [
      {
        accessorKey: 'name',
        header(props) {
          return <DataTableColumnHeader title="Name" column={props.column} />;
        },
        meta: {
          className: 'w-48 text-sm',
        },
        cell(props) {
          return (
            <div className={props.column.columnDef.meta?.className}>{props.row.original.name}</div>
          );
        },
      },
      {
        accessorKey: 'amount',
        header(props) {
          return <DataTableColumnHeader title="Amount ($)" column={props.column} />;
        },
        meta: {
          className: 'w-32 text-sm',
        },
        cell(props) {
          return (
            <div className={props.column.columnDef.meta?.className}>
              {props.row.original.amount}
            </div>
          );
        },
      },
      {
        accessorKey: 'code',
        header(props) {
          return <DataTableColumnHeader title="Code" column={props.column} />;
        },
        meta: {
          className: 'w-32 text-sm whitespace-normal break-words',
        },
        cell(props) {
          return (
            <div className={props.column.columnDef.meta?.className}>{props.row.original.code}</div>
          );
        },
      },
      {
        id: 'actions',
        meta: {
          className: 'w-32 ml-auto flex gap-1 items-center justify-center flex-wrap',
        },
        cell(props) {
          return (
            <div className={props.column.columnDef.meta?.className}>
              <TooltipProvider>
                <Tooltip>
                  <TooltipContent>Edit</TooltipContent>
                  <EditSpiffOptionDialog
                    entry={props.row.original}
                    trigger={
                      <TooltipTrigger asChild>
                        <Button variant="secondary" size="icon">
                          <Pencil1Icon />
                        </Button>
                      </TooltipTrigger>
                    }
                  />
                </Tooltip>

                <DeleteAction id={props.row.original.id} />
              </TooltipProvider>
            </div>
          );
        },
      },
    ];
  }, []);

  const loadingColumns = useMemo(() => getLoadingColumns(tableColumns), [tableColumns]);

  const table = useReactTable({
    getCoreRowModel: getCoreRowModel(),
    columns: pieceWorkOptionsQuery.isPending ? loadingColumns : tableColumns,
    data: pieceWorkOptionsQuery.isPending
      ? staticSpiffOptions
      : pieceWorkOptionsQuery.data?.results ?? [],
    enableSorting: false,
  });

  const pageCount = Math.ceil(totalEntries / ROWS_PER_PAGE);

  return (
    <div className="@container max-w-full">
      <div className="@md:mx-4 @md:mb-4 mx-2 mb-2 flex flex-col gap-2">
        <div className="flex gap-2">
          <CreateSpiffOptionDialog
            eventId={eventId ?? 0}
            trigger={<Button size="sm">Create Piece Work Option</Button>}
          />
          {pageCount > 1 && (
            <DataTablePagination
              currentPage={page}
              setPage={setPage}
              className="max-w-max"
              pageCount={pageCount}
            />
          )}
        </div>
        <Form {...searchForm}>
          <PieceWorkOptionsSearchForm />
        </Form>
      </div>
      <div className="overflow-auto">
        <DataTable table={table} />
      </div>
    </div>
  );
};
