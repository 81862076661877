// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "openai.proto" (syntax proto3)
// tslint:disable
// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "openai.proto" (syntax proto3)
// tslint:disable
import { FileObjects } from "./s3";
import { Empty } from "./common";
import { String$ } from "./common";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { WireType } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Any } from "./common";
// @generated message type with reflection information, may provide speed optimized methods
class ThreadMessageList$Type extends MessageType {
    constructor() {
        super("ThreadMessageList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ThreadMessage },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated ThreadMessage results */ 1:
                    message.results.push(ThreadMessage.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated ThreadMessage results = 1; */
        for (let i = 0; i < message.results.length; i++)
            ThreadMessage.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ThreadMessageList
 */
export const ThreadMessageList = new ThreadMessageList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ThreadMessage$Type extends MessageType {
    constructor() {
        super("ThreadMessage", [
            { no: 1, name: "message", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { message: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string message */ 1:
                    message.message = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string message = 1; */
        if (message.message !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.message);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ThreadMessage
 */
export const ThreadMessage = new ThreadMessage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StreamMessageRequest$Type extends MessageType {
    constructor() {
        super("StreamMessageRequest", [
            { no: 1, name: "user", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "assistant_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "thread_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "message", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { user: "", assistantId: "", threadId: "", message: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string user */ 1:
                    message.user = reader.string();
                    break;
                case /* string assistant_id */ 2:
                    message.assistantId = reader.string();
                    break;
                case /* string thread_id */ 3:
                    message.threadId = reader.string();
                    break;
                case /* string message */ 4:
                    message.message = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string user = 1; */
        if (message.user !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.user);
        /* string assistant_id = 2; */
        if (message.assistantId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.assistantId);
        /* string thread_id = 3; */
        if (message.threadId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.threadId);
        /* string message = 4; */
        if (message.message !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.message);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message StreamMessageRequest
 */
export const StreamMessageRequest = new StreamMessageRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StreamMessage$Type extends MessageType {
    constructor() {
        super("StreamMessage", [
            { no: 1, name: "message", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { message: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string message */ 1:
                    message.message = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string message = 1; */
        if (message.message !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.message);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message StreamMessage
 */
export const StreamMessage = new StreamMessage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CheckThreadRequest$Type extends MessageType {
    constructor() {
        super("CheckThreadRequest", [
            { no: 1, name: "assistant_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "thread_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "thread_run_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { assistantId: "", threadId: "", threadRunId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string assistant_id */ 1:
                    message.assistantId = reader.string();
                    break;
                case /* string thread_id */ 2:
                    message.threadId = reader.string();
                    break;
                case /* string thread_run_id */ 3:
                    message.threadRunId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string assistant_id = 1; */
        if (message.assistantId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.assistantId);
        /* string thread_id = 2; */
        if (message.threadId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.threadId);
        /* string thread_run_id = 3; */
        if (message.threadRunId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.threadRunId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CheckThreadRequest
 */
export const CheckThreadRequest = new CheckThreadRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddingMessageRequest$Type extends MessageType {
    constructor() {
        super("AddingMessageRequest", [
            { no: 1, name: "assistant_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "thread_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "message", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { assistantId: "", threadId: "", message: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string assistant_id */ 1:
                    message.assistantId = reader.string();
                    break;
                case /* string thread_id */ 2:
                    message.threadId = reader.string();
                    break;
                case /* string message */ 3:
                    message.message = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string assistant_id = 1; */
        if (message.assistantId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.assistantId);
        /* string thread_id = 2; */
        if (message.threadId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.threadId);
        /* string message = 3; */
        if (message.message !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.message);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message AddingMessageRequest
 */
export const AddingMessageRequest = new AddingMessageRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddingMessageResponse$Type extends MessageType {
    constructor() {
        super("AddingMessageResponse", [
            { no: 1, name: "thread_run_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { threadRunId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string thread_run_id */ 1:
                    message.threadRunId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string thread_run_id = 1; */
        if (message.threadRunId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.threadRunId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message AddingMessageResponse
 */
export const AddingMessageResponse = new AddingMessageResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StartThreadRequest$Type extends MessageType {
    constructor() {
        super("StartThreadRequest", [
            { no: 1, name: "assistant_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "tool_resources", kind: "message", T: () => ToolResources }
        ]);
    }
    create(value) {
        const message = { assistantId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string assistant_id */ 1:
                    message.assistantId = reader.string();
                    break;
                case /* ToolResources tool_resources */ 2:
                    message.toolResources = ToolResources.internalBinaryRead(reader, reader.uint32(), options, message.toolResources);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string assistant_id = 1; */
        if (message.assistantId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.assistantId);
        /* ToolResources tool_resources = 2; */
        if (message.toolResources)
            ToolResources.internalBinaryWrite(message.toolResources, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message StartThreadRequest
 */
export const StartThreadRequest = new StartThreadRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StartThreadResponse$Type extends MessageType {
    constructor() {
        super("StartThreadResponse", [
            { no: 1, name: "thread_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { threadId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string thread_id */ 1:
                    message.threadId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string thread_id = 1; */
        if (message.threadId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.threadId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message StartThreadResponse
 */
export const StartThreadResponse = new StartThreadResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Assistant$Type extends MessageType {
    constructor() {
        super("Assistant", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "model", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "instructions", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "metadata", kind: "message", T: () => Any },
            { no: 7, name: "tools", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Tool },
            { no: 8, name: "tool_resources", kind: "message", T: () => ToolResources },
            { no: 9, name: "temperature", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 10, name: "top_p", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ }
        ]);
    }
    create(value) {
        const message = { id: "", model: "", name: "", description: "", instructions: "", tools: [], temperature: 0, topP: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string model */ 2:
                    message.model = reader.string();
                    break;
                case /* string name */ 3:
                    message.name = reader.string();
                    break;
                case /* string description */ 4:
                    message.description = reader.string();
                    break;
                case /* string instructions */ 5:
                    message.instructions = reader.string();
                    break;
                case /* Any metadata */ 6:
                    message.metadata = Any.internalBinaryRead(reader, reader.uint32(), options, message.metadata);
                    break;
                case /* repeated Tool tools */ 7:
                    message.tools.push(Tool.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* ToolResources tool_resources */ 8:
                    message.toolResources = ToolResources.internalBinaryRead(reader, reader.uint32(), options, message.toolResources);
                    break;
                case /* float temperature */ 9:
                    message.temperature = reader.float();
                    break;
                case /* float top_p */ 10:
                    message.topP = reader.float();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string model = 2; */
        if (message.model !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.model);
        /* string name = 3; */
        if (message.name !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.name);
        /* string description = 4; */
        if (message.description !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.description);
        /* string instructions = 5; */
        if (message.instructions !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.instructions);
        /* Any metadata = 6; */
        if (message.metadata)
            Any.internalBinaryWrite(message.metadata, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* repeated Tool tools = 7; */
        for (let i = 0; i < message.tools.length; i++)
            Tool.internalBinaryWrite(message.tools[i], writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* ToolResources tool_resources = 8; */
        if (message.toolResources)
            ToolResources.internalBinaryWrite(message.toolResources, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* float temperature = 9; */
        if (message.temperature !== 0)
            writer.tag(9, WireType.Bit32).float(message.temperature);
        /* float top_p = 10; */
        if (message.topP !== 0)
            writer.tag(10, WireType.Bit32).float(message.topP);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Assistant
 */
export const Assistant = new Assistant$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AssistantList$Type extends MessageType {
    constructor() {
        super("AssistantList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Assistant },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Assistant results */ 1:
                    message.results.push(Assistant.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated Assistant results = 1; */
        for (let i = 0; i < message.results.length; i++)
            Assistant.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message AssistantList
 */
export const AssistantList = new AssistantList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AssistantThread$Type extends MessageType {
    constructor() {
        super("AssistantThread", [
            { no: 1, name: "Id", kind: "scalar", jsonName: "Id", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "AssistantId", kind: "scalar", jsonName: "AssistantId", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "ThreadId", kind: "scalar", jsonName: "ThreadId", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "UserId", kind: "scalar", jsonName: "UserId", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "IsActive", kind: "scalar", jsonName: "IsActive", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "DateCreated", kind: "scalar", jsonName: "DateCreated", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "field_mask", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, assistantId: "", threadId: "", userId: 0, isActive: false, dateCreated: "", fieldMask: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 Id = 1 [json_name = "Id"];*/ 1:
                    message.id = reader.int32();
                    break;
                case /* string AssistantId = 2 [json_name = "AssistantId"];*/ 2:
                    message.assistantId = reader.string();
                    break;
                case /* string ThreadId = 3 [json_name = "ThreadId"];*/ 3:
                    message.threadId = reader.string();
                    break;
                case /* int32 UserId = 4 [json_name = "UserId"];*/ 4:
                    message.userId = reader.int32();
                    break;
                case /* bool IsActive = 5 [json_name = "IsActive"];*/ 5:
                    message.isActive = reader.bool();
                    break;
                case /* string DateCreated = 6 [json_name = "DateCreated"];*/ 6:
                    message.dateCreated = reader.string();
                    break;
                case /* repeated string field_mask */ 7:
                    message.fieldMask.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 Id = 1 [json_name = "Id"]; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string AssistantId = 2 [json_name = "AssistantId"]; */
        if (message.assistantId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.assistantId);
        /* string ThreadId = 3 [json_name = "ThreadId"]; */
        if (message.threadId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.threadId);
        /* int32 UserId = 4 [json_name = "UserId"]; */
        if (message.userId !== 0)
            writer.tag(4, WireType.Varint).int32(message.userId);
        /* bool IsActive = 5 [json_name = "IsActive"]; */
        if (message.isActive !== false)
            writer.tag(5, WireType.Varint).bool(message.isActive);
        /* string DateCreated = 6 [json_name = "DateCreated"]; */
        if (message.dateCreated !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.dateCreated);
        /* repeated string field_mask = 7; */
        for (let i = 0; i < message.fieldMask.length; i++)
            writer.tag(7, WireType.LengthDelimited).string(message.fieldMask[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message AssistantThread
 */
export const AssistantThread = new AssistantThread$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UploadOpenAiFile$Type extends MessageType {
    constructor() {
        super("UploadOpenAiFile", [
            { no: 1, name: "path", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "purpose", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { path: "", purpose: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string path */ 1:
                    message.path = reader.string();
                    break;
                case /* string purpose */ 2:
                    message.purpose = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string path = 1; */
        if (message.path !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.path);
        /* string purpose = 2; */
        if (message.purpose !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.purpose);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message UploadOpenAiFile
 */
export const UploadOpenAiFile = new UploadOpenAiFile$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OpenAiFile$Type extends MessageType {
    constructor() {
        super("OpenAiFile", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "created_at", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "file_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "purpose", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: "", createdAt: "", fileName: "", purpose: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string created_at */ 2:
                    message.createdAt = reader.string();
                    break;
                case /* string file_name */ 3:
                    message.fileName = reader.string();
                    break;
                case /* string purpose */ 4:
                    message.purpose = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string created_at = 2; */
        if (message.createdAt !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.createdAt);
        /* string file_name = 3; */
        if (message.fileName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.fileName);
        /* string purpose = 4; */
        if (message.purpose !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.purpose);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message OpenAiFile
 */
export const OpenAiFile = new OpenAiFile$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OpenAiFileList$Type extends MessageType {
    constructor() {
        super("OpenAiFileList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => OpenAiFile },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated OpenAiFile results */ 1:
                    message.results.push(OpenAiFile.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated OpenAiFile results = 1; */
        for (let i = 0; i < message.results.length; i++)
            OpenAiFile.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message OpenAiFileList
 */
export const OpenAiFileList = new OpenAiFileList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VectorStoreRequest$Type extends MessageType {
    constructor() {
        super("VectorStoreRequest", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "file_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { name: "", fileIds: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* repeated string file_ids */ 2:
                    message.fileIds.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* repeated string file_ids = 2; */
        for (let i = 0; i < message.fileIds.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.fileIds[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message VectorStoreRequest
 */
export const VectorStoreRequest = new VectorStoreRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VectorStore$Type extends MessageType {
    constructor() {
        super("VectorStore", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: "", name: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message VectorStore
 */
export const VectorStore = new VectorStore$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VectorStoreList$Type extends MessageType {
    constructor() {
        super("VectorStoreList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => VectorStore },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated VectorStore results */ 1:
                    message.results.push(VectorStore.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated VectorStore results = 1; */
        for (let i = 0; i < message.results.length; i++)
            VectorStore.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message VectorStoreList
 */
export const VectorStoreList = new VectorStoreList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VectorStoreFilesRequest$Type extends MessageType {
    constructor() {
        super("VectorStoreFilesRequest", [
            { no: 1, name: "vector_store_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "file_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { vectorStoreId: "", fileIds: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string vector_store_id */ 1:
                    message.vectorStoreId = reader.string();
                    break;
                case /* repeated string file_ids */ 2:
                    message.fileIds.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string vector_store_id = 1; */
        if (message.vectorStoreId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.vectorStoreId);
        /* repeated string file_ids = 2; */
        for (let i = 0; i < message.fileIds.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.fileIds[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message VectorStoreFilesRequest
 */
export const VectorStoreFilesRequest = new VectorStoreFilesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VectorStoreFile$Type extends MessageType {
    constructor() {
        super("VectorStoreFile", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "vector_store_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "status", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: "", vectorStoreId: "", status: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string vector_store_id */ 2:
                    message.vectorStoreId = reader.string();
                    break;
                case /* string status */ 3:
                    message.status = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string vector_store_id = 2; */
        if (message.vectorStoreId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.vectorStoreId);
        /* string status = 3; */
        if (message.status !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.status);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message VectorStoreFile
 */
export const VectorStoreFile = new VectorStoreFile$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VectorStoreFileList$Type extends MessageType {
    constructor() {
        super("VectorStoreFileList", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => VectorStoreFile },
            { no: 2, name: "total_count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { results: [], totalCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated VectorStoreFile results */ 1:
                    message.results.push(VectorStoreFile.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total_count */ 2:
                    message.totalCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated VectorStoreFile results = 1; */
        for (let i = 0; i < message.results.length; i++)
            VectorStoreFile.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 total_count = 2; */
        if (message.totalCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message VectorStoreFileList
 */
export const VectorStoreFileList = new VectorStoreFileList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ToolResources$Type extends MessageType {
    constructor() {
        super("ToolResources", [
            { no: 1, name: "code_interpreter", kind: "message", T: () => CodeInterpreter },
            { no: 2, name: "file_search", kind: "message", T: () => FileSearch }
        ]);
    }
    create(value) {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* CodeInterpreter code_interpreter */ 1:
                    message.codeInterpreter = CodeInterpreter.internalBinaryRead(reader, reader.uint32(), options, message.codeInterpreter);
                    break;
                case /* FileSearch file_search */ 2:
                    message.fileSearch = FileSearch.internalBinaryRead(reader, reader.uint32(), options, message.fileSearch);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* CodeInterpreter code_interpreter = 1; */
        if (message.codeInterpreter)
            CodeInterpreter.internalBinaryWrite(message.codeInterpreter, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* FileSearch file_search = 2; */
        if (message.fileSearch)
            FileSearch.internalBinaryWrite(message.fileSearch, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ToolResources
 */
export const ToolResources = new ToolResources$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CodeInterpreter$Type extends MessageType {
    constructor() {
        super("CodeInterpreter", [
            { no: 1, name: "file_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { fileIds: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string file_ids */ 1:
                    message.fileIds.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated string file_ids = 1; */
        for (let i = 0; i < message.fileIds.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.fileIds[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CodeInterpreter
 */
export const CodeInterpreter = new CodeInterpreter$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FileSearch$Type extends MessageType {
    constructor() {
        super("FileSearch", [
            { no: 1, name: "vector_store_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { vectorStoreIds: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string vector_store_ids */ 1:
                    message.vectorStoreIds.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated string vector_store_ids = 1; */
        for (let i = 0; i < message.vectorStoreIds.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.vectorStoreIds[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message FileSearch
 */
export const FileSearch = new FileSearch$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VectorStores$Type extends MessageType {
    constructor() {
        super("VectorStores", [
            { no: 1, name: "file_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { fileIds: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string file_ids */ 1:
                    message.fileIds.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated string file_ids = 1; */
        for (let i = 0; i < message.fileIds.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.fileIds[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message VectorStores
 */
export const VectorStores = new VectorStores$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Tool$Type extends MessageType {
    constructor() {
        super("Tool", [
            { no: 1, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { type: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string type */ 1:
                    message.type = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string type = 1; */
        if (message.type !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.type);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Tool
 */
export const Tool = new Tool$Type();
/**
 * @generated ServiceType for protobuf service OpenAiService
 */
export const OpenAiService = new ServiceType("OpenAiService", [
    { name: "CreateOpenAiAssistant", options: {}, I: Assistant, O: Assistant },
    { name: "UpdateOpenAiAssistant", options: {}, I: Assistant, O: Assistant },
    { name: "DeleteOpenAiAssistant", options: {}, I: String$, O: Empty },
    { name: "ListAssistants", options: {}, I: Empty, O: AssistantList },
    { name: "StartThread", options: {}, I: StartThreadRequest, O: StartThreadResponse },
    { name: "DeleteThread", options: {}, I: String$, O: Empty },
    { name: "MakeChatCompletionRequest", options: {}, I: String$, O: String$ },
    { name: "CreateAssistantThread", options: {}, I: AssistantThread, O: AssistantThread },
    { name: "UpdateAssistantThread", options: {}, I: AssistantThread, O: Empty },
    { name: "DeleteAssistantThread", options: {}, I: AssistantThread, O: Empty },
    { name: "GetAssistantThread", options: {}, I: AssistantThread, O: AssistantThread },
    { name: "AssistantThreadChat", serverStreaming: true, options: {}, I: StreamMessageRequest, O: StreamMessage },
    { name: "UploadFile", options: {}, I: UploadOpenAiFile, O: OpenAiFile },
    { name: "ListFiles", options: {}, I: Empty, O: OpenAiFileList },
    { name: "DeleteFile", options: {}, I: String$, O: Empty },
    { name: "CreateVectorStore", options: {}, I: VectorStoreRequest, O: VectorStore },
    { name: "ListVectorStores", options: {}, I: Empty, O: VectorStoreList },
    { name: "CreateVectorStoreFiles", options: {}, I: VectorStoreFilesRequest, O: Empty },
    { name: "ListVectorStoreFiles", options: {}, I: VectorStore, O: VectorStoreFileList },
    { name: "DeleteVectorStoreFile", options: {}, I: VectorStoreFile, O: Empty },
    { name: "GetSummaries", options: {}, I: Empty, O: Empty },
    { name: "UploadS3FileList", options: {}, I: FileObjects, O: OpenAiFileList }
]);
