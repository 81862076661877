// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "samsara.proto" (syntax proto3)
// tslint:disable
// @generated by protobuf-ts 2.8.2 with parameter output_javascript
// @generated from protobuf file "samsara.proto" (syntax proto3)
// tslint:disable
import { String$ } from "./common";
import { Empty } from "./common";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { WireType } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
// @generated message type with reflection information, may provide speed optimized methods
class SamsaraDriversResponse$Type extends MessageType {
    constructor() {
        super("SamsaraDriversResponse", [
            { no: 1, name: "data", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SamsaraDriver },
            { no: 2, name: "pagination", kind: "message", T: () => SamsaraPagination }
        ]);
    }
    create(value) {
        const message = { data: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated SamsaraDriver data */ 1:
                    message.data.push(SamsaraDriver.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* SamsaraPagination pagination */ 2:
                    message.pagination = SamsaraPagination.internalBinaryRead(reader, reader.uint32(), options, message.pagination);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated SamsaraDriver data = 1; */
        for (let i = 0; i < message.data.length; i++)
            SamsaraDriver.internalBinaryWrite(message.data[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* SamsaraPagination pagination = 2; */
        if (message.pagination)
            SamsaraPagination.internalBinaryWrite(message.pagination, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SamsaraDriversResponse
 */
export const SamsaraDriversResponse = new SamsaraDriversResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SamsaraDriverResponseSingular$Type extends MessageType {
    constructor() {
        super("SamsaraDriverResponseSingular", [
            { no: 1, name: "data", kind: "message", T: () => SamsaraDriver }
        ]);
    }
    create(value) {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* SamsaraDriver data */ 1:
                    message.data = SamsaraDriver.internalBinaryRead(reader, reader.uint32(), options, message.data);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* SamsaraDriver data = 1; */
        if (message.data)
            SamsaraDriver.internalBinaryWrite(message.data, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SamsaraDriverResponseSingular
 */
export const SamsaraDriverResponseSingular = new SamsaraDriverResponseSingular$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SamsaraDriverReq$Type extends MessageType {
    constructor() {
        super("SamsaraDriverReq", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "username", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "password", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "phone", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "static_assigned_vehicle_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "tag_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "vehicle_group_tag_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { name: "", username: "", password: "", phone: "", staticAssignedVehicleId: "", tagIds: [], vehicleGroupTagId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string username */ 2:
                    message.username = reader.string();
                    break;
                case /* string password */ 3:
                    message.password = reader.string();
                    break;
                case /* string phone */ 4:
                    message.phone = reader.string();
                    break;
                case /* string static_assigned_vehicle_id */ 5:
                    message.staticAssignedVehicleId = reader.string();
                    break;
                case /* repeated string tag_ids */ 6:
                    message.tagIds.push(reader.string());
                    break;
                case /* string vehicle_group_tag_id */ 7:
                    message.vehicleGroupTagId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string username = 2; */
        if (message.username !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.username);
        /* string password = 3; */
        if (message.password !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.password);
        /* string phone = 4; */
        if (message.phone !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.phone);
        /* string static_assigned_vehicle_id = 5; */
        if (message.staticAssignedVehicleId !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.staticAssignedVehicleId);
        /* repeated string tag_ids = 6; */
        for (let i = 0; i < message.tagIds.length; i++)
            writer.tag(6, WireType.LengthDelimited).string(message.tagIds[i]);
        /* string vehicle_group_tag_id = 7; */
        if (message.vehicleGroupTagId !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.vehicleGroupTagId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SamsaraDriverReq
 */
export const SamsaraDriverReq = new SamsaraDriverReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SamsaraDriver$Type extends MessageType {
    constructor() {
        super("SamsaraDriver", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "username", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "eld_settings", kind: "message", T: () => ELDSettings },
            { no: 6, name: "timezone", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "updated_at_time", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "created_at_time", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "carrier_settings", kind: "message", T: () => CarrierSettings },
            { no: 10, name: "driver_activation_status", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: "", name: "", username: "", notes: "", timezone: "", updatedAtTime: "", createdAtTime: "", driverActivationStatus: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string username */ 3:
                    message.username = reader.string();
                    break;
                case /* string notes */ 4:
                    message.notes = reader.string();
                    break;
                case /* ELDSettings eld_settings */ 5:
                    message.eldSettings = ELDSettings.internalBinaryRead(reader, reader.uint32(), options, message.eldSettings);
                    break;
                case /* string timezone */ 6:
                    message.timezone = reader.string();
                    break;
                case /* string updated_at_time */ 7:
                    message.updatedAtTime = reader.string();
                    break;
                case /* string created_at_time */ 8:
                    message.createdAtTime = reader.string();
                    break;
                case /* CarrierSettings carrier_settings */ 9:
                    message.carrierSettings = CarrierSettings.internalBinaryRead(reader, reader.uint32(), options, message.carrierSettings);
                    break;
                case /* string driver_activation_status */ 10:
                    message.driverActivationStatus = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string username = 3; */
        if (message.username !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.username);
        /* string notes = 4; */
        if (message.notes !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.notes);
        /* ELDSettings eld_settings = 5; */
        if (message.eldSettings)
            ELDSettings.internalBinaryWrite(message.eldSettings, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* string timezone = 6; */
        if (message.timezone !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.timezone);
        /* string updated_at_time = 7; */
        if (message.updatedAtTime !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.updatedAtTime);
        /* string created_at_time = 8; */
        if (message.createdAtTime !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.createdAtTime);
        /* CarrierSettings carrier_settings = 9; */
        if (message.carrierSettings)
            CarrierSettings.internalBinaryWrite(message.carrierSettings, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* string driver_activation_status = 10; */
        if (message.driverActivationStatus !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.driverActivationStatus);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SamsaraDriver
 */
export const SamsaraDriver = new SamsaraDriver$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SamsaraDriverOLD$Type extends MessageType {
    constructor() {
        super("SamsaraDriverOLD", [
            { no: 1, name: "attributes", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SamsaraAttribute },
            { no: 2, name: "carrier_settings", kind: "message", T: () => CarrierSettings },
            { no: 3, name: "created_at_time", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "current_id_card_code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "driver_activation_status", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "eld_adverse_weather_exemption_enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "eld_big_day_exemption_enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 8, name: "eld_day_start_hour", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "eld_exempt", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 10, name: "eld_exempt_reason", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "eld_pc_enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 12, name: "eld_settings", kind: "message", T: () => ELDSettings },
            { no: 13, name: "eld_ym_enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 14, name: "external_ids", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 15, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "is_deactivated", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 17, name: "license_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 18, name: "license_state", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 19, name: "locale", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 20, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 21, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 22, name: "phone", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 23, name: "static_assigned_vehicle", kind: "message", T: () => SamsaraStaticVehicle },
            { no: 24, name: "tags", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SamsaraTag },
            { no: 25, name: "timezone", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 26, name: "updated_at_time", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 27, name: "us_driver_ruleset_override", kind: "message", T: () => SamsaraDriverRuleset },
            { no: 28, name: "username", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 29, name: "vehicle_group_tag", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SamsaraTag },
            { no: 30, name: "peer_group_tag", kind: "message", T: () => PeerGroupTag },
            { no: 31, name: "tachograph_card_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { attributes: [], createdAtTime: "", currentIdCardCode: "", driverActivationStatus: "", eldAdverseWeatherExemptionEnabled: false, eldBigDayExemptionEnabled: false, eldDayStartHour: 0, eldExempt: false, eldExemptReason: "", eldPcEnabled: false, eldYmEnabled: false, externalIds: {}, id: "", isDeactivated: false, licenseNumber: "", licenseState: "", locale: "", name: "", notes: "", phone: "", tags: [], timezone: "", updatedAtTime: "", username: "", vehicleGroupTag: [], tachographCardNumber: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated SamsaraAttribute attributes */ 1:
                    message.attributes.push(SamsaraAttribute.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* CarrierSettings carrier_settings */ 2:
                    message.carrierSettings = CarrierSettings.internalBinaryRead(reader, reader.uint32(), options, message.carrierSettings);
                    break;
                case /* string created_at_time */ 3:
                    message.createdAtTime = reader.string();
                    break;
                case /* string current_id_card_code */ 4:
                    message.currentIdCardCode = reader.string();
                    break;
                case /* string driver_activation_status */ 5:
                    message.driverActivationStatus = reader.string();
                    break;
                case /* bool eld_adverse_weather_exemption_enabled */ 6:
                    message.eldAdverseWeatherExemptionEnabled = reader.bool();
                    break;
                case /* bool eld_big_day_exemption_enabled */ 7:
                    message.eldBigDayExemptionEnabled = reader.bool();
                    break;
                case /* int32 eld_day_start_hour */ 8:
                    message.eldDayStartHour = reader.int32();
                    break;
                case /* bool eld_exempt */ 9:
                    message.eldExempt = reader.bool();
                    break;
                case /* string eld_exempt_reason */ 10:
                    message.eldExemptReason = reader.string();
                    break;
                case /* bool eld_pc_enabled */ 11:
                    message.eldPcEnabled = reader.bool();
                    break;
                case /* ELDSettings eld_settings */ 12:
                    message.eldSettings = ELDSettings.internalBinaryRead(reader, reader.uint32(), options, message.eldSettings);
                    break;
                case /* bool eld_ym_enabled */ 13:
                    message.eldYmEnabled = reader.bool();
                    break;
                case /* map<string, string> external_ids */ 14:
                    this.binaryReadMap14(message.externalIds, reader, options);
                    break;
                case /* string id */ 15:
                    message.id = reader.string();
                    break;
                case /* bool is_deactivated */ 16:
                    message.isDeactivated = reader.bool();
                    break;
                case /* string license_number */ 17:
                    message.licenseNumber = reader.string();
                    break;
                case /* string license_state */ 18:
                    message.licenseState = reader.string();
                    break;
                case /* string locale */ 19:
                    message.locale = reader.string();
                    break;
                case /* string name */ 20:
                    message.name = reader.string();
                    break;
                case /* string notes */ 21:
                    message.notes = reader.string();
                    break;
                case /* string phone */ 22:
                    message.phone = reader.string();
                    break;
                case /* SamsaraStaticVehicle static_assigned_vehicle */ 23:
                    message.staticAssignedVehicle = SamsaraStaticVehicle.internalBinaryRead(reader, reader.uint32(), options, message.staticAssignedVehicle);
                    break;
                case /* repeated SamsaraTag tags */ 24:
                    message.tags.push(SamsaraTag.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string timezone */ 25:
                    message.timezone = reader.string();
                    break;
                case /* string updated_at_time */ 26:
                    message.updatedAtTime = reader.string();
                    break;
                case /* SamsaraDriverRuleset us_driver_ruleset_override */ 27:
                    message.usDriverRulesetOverride = SamsaraDriverRuleset.internalBinaryRead(reader, reader.uint32(), options, message.usDriverRulesetOverride);
                    break;
                case /* string username */ 28:
                    message.username = reader.string();
                    break;
                case /* repeated SamsaraTag vehicle_group_tag */ 29:
                    message.vehicleGroupTag.push(SamsaraTag.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* PeerGroupTag peer_group_tag */ 30:
                    message.peerGroupTag = PeerGroupTag.internalBinaryRead(reader, reader.uint32(), options, message.peerGroupTag);
                    break;
                case /* string tachograph_card_number */ 31:
                    message.tachographCardNumber = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    binaryReadMap14(map, reader, options) {
        let len = reader.uint32(), end = reader.pos + len, key, val;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field SamsaraDriverOLD.external_ids");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated SamsaraAttribute attributes = 1; */
        for (let i = 0; i < message.attributes.length; i++)
            SamsaraAttribute.internalBinaryWrite(message.attributes[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* CarrierSettings carrier_settings = 2; */
        if (message.carrierSettings)
            CarrierSettings.internalBinaryWrite(message.carrierSettings, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string created_at_time = 3; */
        if (message.createdAtTime !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.createdAtTime);
        /* string current_id_card_code = 4; */
        if (message.currentIdCardCode !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.currentIdCardCode);
        /* string driver_activation_status = 5; */
        if (message.driverActivationStatus !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.driverActivationStatus);
        /* bool eld_adverse_weather_exemption_enabled = 6; */
        if (message.eldAdverseWeatherExemptionEnabled !== false)
            writer.tag(6, WireType.Varint).bool(message.eldAdverseWeatherExemptionEnabled);
        /* bool eld_big_day_exemption_enabled = 7; */
        if (message.eldBigDayExemptionEnabled !== false)
            writer.tag(7, WireType.Varint).bool(message.eldBigDayExemptionEnabled);
        /* int32 eld_day_start_hour = 8; */
        if (message.eldDayStartHour !== 0)
            writer.tag(8, WireType.Varint).int32(message.eldDayStartHour);
        /* bool eld_exempt = 9; */
        if (message.eldExempt !== false)
            writer.tag(9, WireType.Varint).bool(message.eldExempt);
        /* string eld_exempt_reason = 10; */
        if (message.eldExemptReason !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.eldExemptReason);
        /* bool eld_pc_enabled = 11; */
        if (message.eldPcEnabled !== false)
            writer.tag(11, WireType.Varint).bool(message.eldPcEnabled);
        /* ELDSettings eld_settings = 12; */
        if (message.eldSettings)
            ELDSettings.internalBinaryWrite(message.eldSettings, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* bool eld_ym_enabled = 13; */
        if (message.eldYmEnabled !== false)
            writer.tag(13, WireType.Varint).bool(message.eldYmEnabled);
        /* map<string, string> external_ids = 14; */
        for (let k of Object.keys(message.externalIds))
            writer.tag(14, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.externalIds[k]).join();
        /* string id = 15; */
        if (message.id !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.id);
        /* bool is_deactivated = 16; */
        if (message.isDeactivated !== false)
            writer.tag(16, WireType.Varint).bool(message.isDeactivated);
        /* string license_number = 17; */
        if (message.licenseNumber !== "")
            writer.tag(17, WireType.LengthDelimited).string(message.licenseNumber);
        /* string license_state = 18; */
        if (message.licenseState !== "")
            writer.tag(18, WireType.LengthDelimited).string(message.licenseState);
        /* string locale = 19; */
        if (message.locale !== "")
            writer.tag(19, WireType.LengthDelimited).string(message.locale);
        /* string name = 20; */
        if (message.name !== "")
            writer.tag(20, WireType.LengthDelimited).string(message.name);
        /* string notes = 21; */
        if (message.notes !== "")
            writer.tag(21, WireType.LengthDelimited).string(message.notes);
        /* string phone = 22; */
        if (message.phone !== "")
            writer.tag(22, WireType.LengthDelimited).string(message.phone);
        /* SamsaraStaticVehicle static_assigned_vehicle = 23; */
        if (message.staticAssignedVehicle)
            SamsaraStaticVehicle.internalBinaryWrite(message.staticAssignedVehicle, writer.tag(23, WireType.LengthDelimited).fork(), options).join();
        /* repeated SamsaraTag tags = 24; */
        for (let i = 0; i < message.tags.length; i++)
            SamsaraTag.internalBinaryWrite(message.tags[i], writer.tag(24, WireType.LengthDelimited).fork(), options).join();
        /* string timezone = 25; */
        if (message.timezone !== "")
            writer.tag(25, WireType.LengthDelimited).string(message.timezone);
        /* string updated_at_time = 26; */
        if (message.updatedAtTime !== "")
            writer.tag(26, WireType.LengthDelimited).string(message.updatedAtTime);
        /* SamsaraDriverRuleset us_driver_ruleset_override = 27; */
        if (message.usDriverRulesetOverride)
            SamsaraDriverRuleset.internalBinaryWrite(message.usDriverRulesetOverride, writer.tag(27, WireType.LengthDelimited).fork(), options).join();
        /* string username = 28; */
        if (message.username !== "")
            writer.tag(28, WireType.LengthDelimited).string(message.username);
        /* repeated SamsaraTag vehicle_group_tag = 29; */
        for (let i = 0; i < message.vehicleGroupTag.length; i++)
            SamsaraTag.internalBinaryWrite(message.vehicleGroupTag[i], writer.tag(29, WireType.LengthDelimited).fork(), options).join();
        /* PeerGroupTag peer_group_tag = 30; */
        if (message.peerGroupTag)
            PeerGroupTag.internalBinaryWrite(message.peerGroupTag, writer.tag(30, WireType.LengthDelimited).fork(), options).join();
        /* string tachograph_card_number = 31; */
        if (message.tachographCardNumber !== "")
            writer.tag(31, WireType.LengthDelimited).string(message.tachographCardNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SamsaraDriverOLD
 */
export const SamsaraDriverOLD = new SamsaraDriverOLD$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PeerGroupTag$Type extends MessageType {
    constructor() {
        super("PeerGroupTag", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "parentTagId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: "", name: "", parentTagId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string parentTagId */ 3:
                    message.parentTagId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string parentTagId = 3; */
        if (message.parentTagId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.parentTagId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PeerGroupTag
 */
export const PeerGroupTag = new PeerGroupTag$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SamsaraLocationResponse$Type extends MessageType {
    constructor() {
        super("SamsaraLocationResponse", [
            { no: 1, name: "data", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SamsaraVehicleLocation },
            { no: 2, name: "pagination", kind: "message", T: () => SamsaraPagination }
        ]);
    }
    create(value) {
        const message = { data: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated SamsaraVehicleLocation data */ 1:
                    message.data.push(SamsaraVehicleLocation.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* SamsaraPagination pagination */ 2:
                    message.pagination = SamsaraPagination.internalBinaryRead(reader, reader.uint32(), options, message.pagination);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated SamsaraVehicleLocation data = 1; */
        for (let i = 0; i < message.data.length; i++)
            SamsaraVehicleLocation.internalBinaryWrite(message.data[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* SamsaraPagination pagination = 2; */
        if (message.pagination)
            SamsaraPagination.internalBinaryWrite(message.pagination, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SamsaraLocationResponse
 */
export const SamsaraLocationResponse = new SamsaraLocationResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SamsaraVehicleLocation$Type extends MessageType {
    constructor() {
        super("SamsaraVehicleLocation", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "location", kind: "message", T: () => SamsaraLocationEvent },
            { no: 3, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: "", name: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* SamsaraLocationEvent location */ 2:
                    message.location = SamsaraLocationEvent.internalBinaryRead(reader, reader.uint32(), options, message.location);
                    break;
                case /* string name */ 3:
                    message.name = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* SamsaraLocationEvent location = 2; */
        if (message.location)
            SamsaraLocationEvent.internalBinaryWrite(message.location, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string name = 3; */
        if (message.name !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.name);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SamsaraVehicleLocation
 */
export const SamsaraVehicleLocation = new SamsaraVehicleLocation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SamsaraLocationEvent$Type extends MessageType {
    constructor() {
        super("SamsaraLocationEvent", [
            { no: 1, name: "heading", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 2, name: "latitude", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 3, name: "longitude", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 4, name: "reverse_geo", kind: "message", T: () => SamsaraReverseGeo },
            { no: 5, name: "speed", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 6, name: "time", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { heading: 0, latitude: 0, longitude: 0, speed: 0, time: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* double heading */ 1:
                    message.heading = reader.double();
                    break;
                case /* double latitude */ 2:
                    message.latitude = reader.double();
                    break;
                case /* double longitude */ 3:
                    message.longitude = reader.double();
                    break;
                case /* SamsaraReverseGeo reverse_geo */ 4:
                    message.reverseGeo = SamsaraReverseGeo.internalBinaryRead(reader, reader.uint32(), options, message.reverseGeo);
                    break;
                case /* double speed */ 5:
                    message.speed = reader.double();
                    break;
                case /* string time */ 6:
                    message.time = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* double heading = 1; */
        if (message.heading !== 0)
            writer.tag(1, WireType.Bit64).double(message.heading);
        /* double latitude = 2; */
        if (message.latitude !== 0)
            writer.tag(2, WireType.Bit64).double(message.latitude);
        /* double longitude = 3; */
        if (message.longitude !== 0)
            writer.tag(3, WireType.Bit64).double(message.longitude);
        /* SamsaraReverseGeo reverse_geo = 4; */
        if (message.reverseGeo)
            SamsaraReverseGeo.internalBinaryWrite(message.reverseGeo, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* double speed = 5; */
        if (message.speed !== 0)
            writer.tag(5, WireType.Bit64).double(message.speed);
        /* string time = 6; */
        if (message.time !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.time);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SamsaraLocationEvent
 */
export const SamsaraLocationEvent = new SamsaraLocationEvent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SamsaraReverseGeo$Type extends MessageType {
    constructor() {
        super("SamsaraReverseGeo", [
            { no: 1, name: "formatted_location", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { formattedLocation: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string formatted_location */ 1:
                    message.formattedLocation = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string formatted_location = 1; */
        if (message.formattedLocation !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.formattedLocation);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SamsaraReverseGeo
 */
export const SamsaraReverseGeo = new SamsaraReverseGeo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SamsaraAddressResponse$Type extends MessageType {
    constructor() {
        super("SamsaraAddressResponse", [
            { no: 1, name: "data", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SamsaraAddress },
            { no: 2, name: "pagination", kind: "message", T: () => SamsaraPagination }
        ]);
    }
    create(value) {
        const message = { data: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated SamsaraAddress data */ 1:
                    message.data.push(SamsaraAddress.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* SamsaraPagination pagination */ 2:
                    message.pagination = SamsaraPagination.internalBinaryRead(reader, reader.uint32(), options, message.pagination);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated SamsaraAddress data = 1; */
        for (let i = 0; i < message.data.length; i++)
            SamsaraAddress.internalBinaryWrite(message.data[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* SamsaraPagination pagination = 2; */
        if (message.pagination)
            SamsaraPagination.internalBinaryWrite(message.pagination, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SamsaraAddressResponse
 */
export const SamsaraAddressResponse = new SamsaraAddressResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SamsaraAddressResponseSingular$Type extends MessageType {
    constructor() {
        super("SamsaraAddressResponseSingular", [
            { no: 1, name: "data", kind: "message", T: () => SamsaraAddress }
        ]);
    }
    create(value) {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* SamsaraAddress data */ 1:
                    message.data = SamsaraAddress.internalBinaryRead(reader, reader.uint32(), options, message.data);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* SamsaraAddress data = 1; */
        if (message.data)
            SamsaraAddress.internalBinaryWrite(message.data, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SamsaraAddressResponseSingular
 */
export const SamsaraAddressResponseSingular = new SamsaraAddressResponseSingular$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SamsaraAddress$Type extends MessageType {
    constructor() {
        super("SamsaraAddress", [
            { no: 1, name: "address_types", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "contacts", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SamsaraContact },
            { no: 3, name: "contact_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "created_at_time", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "formatted_address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "external_ids", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 7, name: "geofence", kind: "message", T: () => SamsaraGeofence },
            { no: 8, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "tags", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SamsaraTag },
            { no: 14, name: "tag_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "latitude", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 12, name: "longitude", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 13, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { addressTypes: [], contacts: [], contactIds: [], createdAtTime: "", formattedAddress: "", externalIds: {}, id: "", notes: "", tags: [], tagIds: [], latitude: 0, longitude: 0, name: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string address_types */ 1:
                    message.addressTypes.push(reader.string());
                    break;
                case /* repeated SamsaraContact contacts */ 2:
                    message.contacts.push(SamsaraContact.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated string contact_ids */ 3:
                    message.contactIds.push(reader.string());
                    break;
                case /* string created_at_time */ 4:
                    message.createdAtTime = reader.string();
                    break;
                case /* string formatted_address */ 5:
                    message.formattedAddress = reader.string();
                    break;
                case /* map<string, string> external_ids */ 6:
                    this.binaryReadMap6(message.externalIds, reader, options);
                    break;
                case /* SamsaraGeofence geofence */ 7:
                    message.geofence = SamsaraGeofence.internalBinaryRead(reader, reader.uint32(), options, message.geofence);
                    break;
                case /* string id */ 8:
                    message.id = reader.string();
                    break;
                case /* string notes */ 9:
                    message.notes = reader.string();
                    break;
                case /* repeated SamsaraTag tags */ 10:
                    message.tags.push(SamsaraTag.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated string tag_ids */ 14:
                    message.tagIds.push(reader.string());
                    break;
                case /* double latitude */ 11:
                    message.latitude = reader.double();
                    break;
                case /* double longitude */ 12:
                    message.longitude = reader.double();
                    break;
                case /* string name */ 13:
                    message.name = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    binaryReadMap6(map, reader, options) {
        let len = reader.uint32(), end = reader.pos + len, key, val;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field SamsaraAddress.external_ids");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated string address_types = 1; */
        for (let i = 0; i < message.addressTypes.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.addressTypes[i]);
        /* repeated SamsaraContact contacts = 2; */
        for (let i = 0; i < message.contacts.length; i++)
            SamsaraContact.internalBinaryWrite(message.contacts[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated string contact_ids = 3; */
        for (let i = 0; i < message.contactIds.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.contactIds[i]);
        /* string created_at_time = 4; */
        if (message.createdAtTime !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.createdAtTime);
        /* string formatted_address = 5; */
        if (message.formattedAddress !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.formattedAddress);
        /* map<string, string> external_ids = 6; */
        for (let k of Object.keys(message.externalIds))
            writer.tag(6, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.externalIds[k]).join();
        /* SamsaraGeofence geofence = 7; */
        if (message.geofence)
            SamsaraGeofence.internalBinaryWrite(message.geofence, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* string id = 8; */
        if (message.id !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.id);
        /* string notes = 9; */
        if (message.notes !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.notes);
        /* repeated SamsaraTag tags = 10; */
        for (let i = 0; i < message.tags.length; i++)
            SamsaraTag.internalBinaryWrite(message.tags[i], writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* repeated string tag_ids = 14; */
        for (let i = 0; i < message.tagIds.length; i++)
            writer.tag(14, WireType.LengthDelimited).string(message.tagIds[i]);
        /* double latitude = 11; */
        if (message.latitude !== 0)
            writer.tag(11, WireType.Bit64).double(message.latitude);
        /* double longitude = 12; */
        if (message.longitude !== 0)
            writer.tag(12, WireType.Bit64).double(message.longitude);
        /* string name = 13; */
        if (message.name !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.name);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SamsaraAddress
 */
export const SamsaraAddress = new SamsaraAddress$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SamsaraGeofence$Type extends MessageType {
    constructor() {
        super("SamsaraGeofence", [
            { no: 1, name: "circle", kind: "message", T: () => SamsaraCircularFence }
        ]);
    }
    create(value) {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* SamsaraCircularFence circle */ 1:
                    message.circle = SamsaraCircularFence.internalBinaryRead(reader, reader.uint32(), options, message.circle);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* SamsaraCircularFence circle = 1; */
        if (message.circle)
            SamsaraCircularFence.internalBinaryWrite(message.circle, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SamsaraGeofence
 */
export const SamsaraGeofence = new SamsaraGeofence$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SamsaraCircularFence$Type extends MessageType {
    constructor() {
        super("SamsaraCircularFence", [
            { no: 1, name: "latitude", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 2, name: "longitude", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 3, name: "radius_meters", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value) {
        const message = { latitude: 0, longitude: 0, radiusMeters: 0n };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* double latitude */ 1:
                    message.latitude = reader.double();
                    break;
                case /* double longitude */ 2:
                    message.longitude = reader.double();
                    break;
                case /* int64 radius_meters */ 3:
                    message.radiusMeters = reader.int64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* double latitude = 1; */
        if (message.latitude !== 0)
            writer.tag(1, WireType.Bit64).double(message.latitude);
        /* double longitude = 2; */
        if (message.longitude !== 0)
            writer.tag(2, WireType.Bit64).double(message.longitude);
        /* int64 radius_meters = 3; */
        if (message.radiusMeters !== 0n)
            writer.tag(3, WireType.Varint).int64(message.radiusMeters);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SamsaraCircularFence
 */
export const SamsaraCircularFence = new SamsaraCircularFence$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SamsaraContact$Type extends MessageType {
    constructor() {
        super("SamsaraContact", [
            { no: 1, name: "first_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "last_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { firstName: "", id: "", lastName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string first_name */ 1:
                    message.firstName = reader.string();
                    break;
                case /* string id */ 2:
                    message.id = reader.string();
                    break;
                case /* string last_name */ 3:
                    message.lastName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string first_name = 1; */
        if (message.firstName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.firstName);
        /* string id = 2; */
        if (message.id !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.id);
        /* string last_name = 3; */
        if (message.lastName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.lastName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SamsaraContact
 */
export const SamsaraContact = new SamsaraContact$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SamsaraPagination$Type extends MessageType {
    constructor() {
        super("SamsaraPagination", [
            { no: 1, name: "end_cursor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "has_next_page", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value) {
        const message = { endCursor: "", hasNextPage: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string end_cursor */ 1:
                    message.endCursor = reader.string();
                    break;
                case /* bool has_next_page */ 2:
                    message.hasNextPage = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string end_cursor = 1; */
        if (message.endCursor !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.endCursor);
        /* bool has_next_page = 2; */
        if (message.hasNextPage !== false)
            writer.tag(2, WireType.Varint).bool(message.hasNextPage);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SamsaraPagination
 */
export const SamsaraPagination = new SamsaraPagination$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SamsaraDriverRuleset$Type extends MessageType {
    constructor() {
        super("SamsaraDriverRuleset", [
            { no: 1, name: "cycle", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "restart", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "restbreak", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "us_state_to_override", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { cycle: "", restart: "", restbreak: "", usStateToOverride: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string cycle */ 1:
                    message.cycle = reader.string();
                    break;
                case /* string restart */ 2:
                    message.restart = reader.string();
                    break;
                case /* string restbreak */ 3:
                    message.restbreak = reader.string();
                    break;
                case /* string us_state_to_override */ 4:
                    message.usStateToOverride = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string cycle = 1; */
        if (message.cycle !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.cycle);
        /* string restart = 2; */
        if (message.restart !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.restart);
        /* string restbreak = 3; */
        if (message.restbreak !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.restbreak);
        /* string us_state_to_override = 4; */
        if (message.usStateToOverride !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.usStateToOverride);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SamsaraDriverRuleset
 */
export const SamsaraDriverRuleset = new SamsaraDriverRuleset$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SamsaraTag$Type extends MessageType {
    constructor() {
        super("SamsaraTag", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "parent_tag_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: "", name: "", parentTagId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string parent_tag_id */ 3:
                    message.parentTagId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string parent_tag_id = 3; */
        if (message.parentTagId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.parentTagId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SamsaraTag
 */
export const SamsaraTag = new SamsaraTag$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SamsaraVehicle$Type extends MessageType {
    constructor() {
        super("SamsaraVehicle", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "static_assigned_driver", kind: "message", T: () => SamsaraStaticDriver },
            { no: 4, name: "vin", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "serial", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "camera_serial", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "make", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "model", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "year", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "harsh_acceleration_setting_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "license_plate", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "external_ids", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 14, name: "gateway", kind: "message", T: () => SamsaraGateway },
            { no: 15, name: "vehicle_regulation_mode", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: "", name: "", vin: "", serial: "", cameraSerial: "", make: "", model: "", year: "", harshAccelerationSettingType: "", notes: "", licensePlate: "", externalIds: {}, vehicleRegulationMode: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* SamsaraStaticDriver static_assigned_driver */ 3:
                    message.staticAssignedDriver = SamsaraStaticDriver.internalBinaryRead(reader, reader.uint32(), options, message.staticAssignedDriver);
                    break;
                case /* string vin */ 4:
                    message.vin = reader.string();
                    break;
                case /* string serial */ 5:
                    message.serial = reader.string();
                    break;
                case /* string camera_serial */ 6:
                    message.cameraSerial = reader.string();
                    break;
                case /* string make */ 7:
                    message.make = reader.string();
                    break;
                case /* string model */ 8:
                    message.model = reader.string();
                    break;
                case /* string year */ 9:
                    message.year = reader.string();
                    break;
                case /* string harsh_acceleration_setting_type */ 10:
                    message.harshAccelerationSettingType = reader.string();
                    break;
                case /* string notes */ 11:
                    message.notes = reader.string();
                    break;
                case /* string license_plate */ 12:
                    message.licensePlate = reader.string();
                    break;
                case /* map<string, string> external_ids */ 13:
                    this.binaryReadMap13(message.externalIds, reader, options);
                    break;
                case /* SamsaraGateway gateway */ 14:
                    message.gateway = SamsaraGateway.internalBinaryRead(reader, reader.uint32(), options, message.gateway);
                    break;
                case /* string vehicle_regulation_mode */ 15:
                    message.vehicleRegulationMode = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    binaryReadMap13(map, reader, options) {
        let len = reader.uint32(), end = reader.pos + len, key, val;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field SamsaraVehicle.external_ids");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message, writer, options) {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* SamsaraStaticDriver static_assigned_driver = 3; */
        if (message.staticAssignedDriver)
            SamsaraStaticDriver.internalBinaryWrite(message.staticAssignedDriver, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string vin = 4; */
        if (message.vin !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.vin);
        /* string serial = 5; */
        if (message.serial !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.serial);
        /* string camera_serial = 6; */
        if (message.cameraSerial !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.cameraSerial);
        /* string make = 7; */
        if (message.make !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.make);
        /* string model = 8; */
        if (message.model !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.model);
        /* string year = 9; */
        if (message.year !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.year);
        /* string harsh_acceleration_setting_type = 10; */
        if (message.harshAccelerationSettingType !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.harshAccelerationSettingType);
        /* string notes = 11; */
        if (message.notes !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.notes);
        /* string license_plate = 12; */
        if (message.licensePlate !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.licensePlate);
        /* map<string, string> external_ids = 13; */
        for (let k of Object.keys(message.externalIds))
            writer.tag(13, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.externalIds[k]).join();
        /* SamsaraGateway gateway = 14; */
        if (message.gateway)
            SamsaraGateway.internalBinaryWrite(message.gateway, writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* string vehicle_regulation_mode = 15; */
        if (message.vehicleRegulationMode !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.vehicleRegulationMode);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SamsaraVehicle
 */
export const SamsaraVehicle = new SamsaraVehicle$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SamsaraVehicleResponse$Type extends MessageType {
    constructor() {
        super("SamsaraVehicleResponse", [
            { no: 1, name: "data", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SamsaraVehicle },
            { no: 2, name: "pagination", kind: "message", T: () => SamsaraPagination }
        ]);
    }
    create(value) {
        const message = { data: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated SamsaraVehicle data */ 1:
                    message.data.push(SamsaraVehicle.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* SamsaraPagination pagination */ 2:
                    message.pagination = SamsaraPagination.internalBinaryRead(reader, reader.uint32(), options, message.pagination);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated SamsaraVehicle data = 1; */
        for (let i = 0; i < message.data.length; i++)
            SamsaraVehicle.internalBinaryWrite(message.data[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* SamsaraPagination pagination = 2; */
        if (message.pagination)
            SamsaraPagination.internalBinaryWrite(message.pagination, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SamsaraVehicleResponse
 */
export const SamsaraVehicleResponse = new SamsaraVehicleResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SamsaraVehicleResponseSingular$Type extends MessageType {
    constructor() {
        super("SamsaraVehicleResponseSingular", [
            { no: 1, name: "data", kind: "message", T: () => SamsaraVehicle }
        ]);
    }
    create(value) {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* SamsaraVehicle data */ 1:
                    message.data = SamsaraVehicle.internalBinaryRead(reader, reader.uint32(), options, message.data);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* SamsaraVehicle data = 1; */
        if (message.data)
            SamsaraVehicle.internalBinaryWrite(message.data, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SamsaraVehicleResponseSingular
 */
export const SamsaraVehicleResponseSingular = new SamsaraVehicleResponseSingular$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SamsaraGateway$Type extends MessageType {
    constructor() {
        super("SamsaraGateway", [
            { no: 1, name: "serial", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "model", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { serial: "", model: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string serial */ 1:
                    message.serial = reader.string();
                    break;
                case /* string model */ 2:
                    message.model = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string serial = 1; */
        if (message.serial !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.serial);
        /* string model = 2; */
        if (message.model !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.model);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SamsaraGateway
 */
export const SamsaraGateway = new SamsaraGateway$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SamsaraStaticDriver$Type extends MessageType {
    constructor() {
        super("SamsaraStaticDriver", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: "", name: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SamsaraStaticDriver
 */
export const SamsaraStaticDriver = new SamsaraStaticDriver$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SamsaraStaticVehicle$Type extends MessageType {
    constructor() {
        super("SamsaraStaticVehicle", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: "", name: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SamsaraStaticVehicle
 */
export const SamsaraStaticVehicle = new SamsaraStaticVehicle$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SamsaraAttribute$Type extends MessageType {
    constructor() {
        super("SamsaraAttribute", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "number_values", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "string_values", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { id: "", name: "", numberValues: [], stringValues: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* repeated int32 number_values */ 3:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.numberValues.push(reader.int32());
                    else
                        message.numberValues.push(reader.int32());
                    break;
                case /* repeated string string_values */ 4:
                    message.stringValues.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* repeated int32 number_values = 3; */
        if (message.numberValues.length) {
            writer.tag(3, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.numberValues.length; i++)
                writer.int32(message.numberValues[i]);
            writer.join();
        }
        /* repeated string string_values = 4; */
        for (let i = 0; i < message.stringValues.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.stringValues[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SamsaraAttribute
 */
export const SamsaraAttribute = new SamsaraAttribute$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ELDSettings$Type extends MessageType {
    constructor() {
        super("ELDSettings", [
            { no: 1, name: "rulesets", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Ruleset }
        ]);
    }
    create(value) {
        const message = { rulesets: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Ruleset rulesets */ 1:
                    message.rulesets.push(Ruleset.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated Ruleset rulesets = 1; */
        for (let i = 0; i < message.rulesets.length; i++)
            Ruleset.internalBinaryWrite(message.rulesets[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ELDSettings
 */
export const ELDSettings = new ELDSettings$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Ruleset$Type extends MessageType {
    constructor() {
        super("Ruleset", [
            { no: 1, name: "cycle", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "shift", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "restart", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "break", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "jurisdiction", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { cycle: "", shift: "", restart: "", break: "", jurisdiction: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string cycle */ 1:
                    message.cycle = reader.string();
                    break;
                case /* string shift */ 2:
                    message.shift = reader.string();
                    break;
                case /* string restart */ 3:
                    message.restart = reader.string();
                    break;
                case /* string break */ 4:
                    message.break = reader.string();
                    break;
                case /* string jurisdiction */ 5:
                    message.jurisdiction = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string cycle = 1; */
        if (message.cycle !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.cycle);
        /* string shift = 2; */
        if (message.shift !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.shift);
        /* string restart = 3; */
        if (message.restart !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.restart);
        /* string break = 4; */
        if (message.break !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.break);
        /* string jurisdiction = 5; */
        if (message.jurisdiction !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.jurisdiction);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message Ruleset
 */
export const Ruleset = new Ruleset$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CarrierSettings$Type extends MessageType {
    constructor() {
        super("CarrierSettings", [
            { no: 1, name: "carrier_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "main_office_address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "dot_number", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "home_terminal_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "home_terminal_address", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = { carrierName: "", mainOfficeAddress: "", dotNumber: 0, homeTerminalName: "", homeTerminalAddress: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string carrier_name */ 1:
                    message.carrierName = reader.string();
                    break;
                case /* string main_office_address */ 2:
                    message.mainOfficeAddress = reader.string();
                    break;
                case /* int32 dot_number */ 3:
                    message.dotNumber = reader.int32();
                    break;
                case /* string home_terminal_name */ 4:
                    message.homeTerminalName = reader.string();
                    break;
                case /* string home_terminal_address */ 5:
                    message.homeTerminalAddress = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string carrier_name = 1; */
        if (message.carrierName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.carrierName);
        /* string main_office_address = 2; */
        if (message.mainOfficeAddress !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.mainOfficeAddress);
        /* int32 dot_number = 3; */
        if (message.dotNumber !== 0)
            writer.tag(3, WireType.Varint).int32(message.dotNumber);
        /* string home_terminal_name = 4; */
        if (message.homeTerminalName !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.homeTerminalName);
        /* string home_terminal_address = 5; */
        if (message.homeTerminalAddress !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.homeTerminalAddress);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CarrierSettings
 */
export const CarrierSettings = new CarrierSettings$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LocalVehicleList$Type extends MessageType {
    constructor() {
        super("LocalVehicleList", [
            { no: 1, name: "data", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => LocalVehicle }
        ]);
    }
    create(value) {
        const message = { data: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated LocalVehicle data */ 1:
                    message.data.push(LocalVehicle.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* repeated LocalVehicle data = 1; */
        for (let i = 0; i < message.data.length; i++)
            LocalVehicle.internalBinaryWrite(message.data[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message LocalVehicleList
 */
export const LocalVehicleList = new LocalVehicleList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LocalVehicle$Type extends MessageType {
    constructor() {
        super("LocalVehicle", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "owner_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "department_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "make", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "model", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "year", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "engine", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "vehicle_identification_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "photo_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "gps_tracking_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "is_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 12, name: "third_party_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "driver_id", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value) {
        const message = { id: 0, ownerId: 0, departmentId: 0, make: "", model: "", year: "", engine: "", vehicleIdentificationNumber: "", photoName: "", gpsTrackingId: "", isActive: false, thirdPartyId: "", driverId: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* int32 owner_id */ 2:
                    message.ownerId = reader.int32();
                    break;
                case /* int32 department_id */ 3:
                    message.departmentId = reader.int32();
                    break;
                case /* string make */ 4:
                    message.make = reader.string();
                    break;
                case /* string model */ 5:
                    message.model = reader.string();
                    break;
                case /* string year */ 6:
                    message.year = reader.string();
                    break;
                case /* string engine */ 7:
                    message.engine = reader.string();
                    break;
                case /* string vehicle_identification_number */ 8:
                    message.vehicleIdentificationNumber = reader.string();
                    break;
                case /* string photo_name */ 9:
                    message.photoName = reader.string();
                    break;
                case /* string gps_tracking_id */ 10:
                    message.gpsTrackingId = reader.string();
                    break;
                case /* bool is_active */ 11:
                    message.isActive = reader.bool();
                    break;
                case /* string third_party_id */ 12:
                    message.thirdPartyId = reader.string();
                    break;
                case /* int32 driver_id */ 13:
                    message.driverId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* int32 owner_id = 2; */
        if (message.ownerId !== 0)
            writer.tag(2, WireType.Varint).int32(message.ownerId);
        /* int32 department_id = 3; */
        if (message.departmentId !== 0)
            writer.tag(3, WireType.Varint).int32(message.departmentId);
        /* string make = 4; */
        if (message.make !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.make);
        /* string model = 5; */
        if (message.model !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.model);
        /* string year = 6; */
        if (message.year !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.year);
        /* string engine = 7; */
        if (message.engine !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.engine);
        /* string vehicle_identification_number = 8; */
        if (message.vehicleIdentificationNumber !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.vehicleIdentificationNumber);
        /* string photo_name = 9; */
        if (message.photoName !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.photoName);
        /* string gps_tracking_id = 10; */
        if (message.gpsTrackingId !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.gpsTrackingId);
        /* bool is_active = 11; */
        if (message.isActive !== false)
            writer.tag(11, WireType.Varint).bool(message.isActive);
        /* string third_party_id = 12; */
        if (message.thirdPartyId !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.thirdPartyId);
        /* int32 driver_id = 13; */
        if (message.driverId !== 0)
            writer.tag(13, WireType.Varint).int32(message.driverId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message LocalVehicle
 */
export const LocalVehicle = new LocalVehicle$Type();
/**
 * @generated ServiceType for protobuf service SamsaraService
 */
export const SamsaraService = new ServiceType("SamsaraService", [
    { name: "BatchGetDrivers", options: {}, I: Empty, O: SamsaraDriversResponse },
    { name: "BatchGetLocations", options: {}, I: Empty, O: SamsaraLocationResponse },
    { name: "CreateAddress", options: {}, I: SamsaraAddress, O: String$ },
    { name: "BatchGetVehicles", options: {}, I: Empty, O: SamsaraVehicleResponse },
    { name: "UpdateVehicle", options: {}, I: SamsaraVehicle, O: SamsaraVehicleResponseSingular },
    { name: "CreateDriver", options: {}, I: SamsaraDriverReq, O: SamsaraDriverResponseSingular },
    { name: "ValidateVehicleList", options: {}, I: Empty, O: Empty }
]);
