import { type FC } from 'react';

import { Documents } from '../../ComponentsLibrary/Documents';

interface Props {
  className?: string;
  userID?: number;
  propertyId: number;
  loggedUserId?: number;
  viewedAsCustomer?: boolean;
  docsRefresh?: number;
}

export const PropertyDocuments: FC<Props> = ({
  className,
  userID,
  propertyId,
  loggedUserId,
  viewedAsCustomer = false,
  docsRefresh,
}) => (
  <Documents
    className={className}
    loggedUserId={loggedUserId || 0}
    title="Property Documents"
    smallTitle
    propertyId={propertyId}
    deletable={true}
    userId={userID || 0}
    ignoreUserId
    docsRefresh={docsRefresh}
    addUrl={
      viewedAsCustomer
        ? undefined //TODO: add modal for this
        : [
            '/index.cfm?action=admin:properties.docaddS3',
            `user_id=${userID}`,
            `property_id=${propertyId}`,
          ].join('&')
    }
  />
);
