import { type MaintenanceQuestion } from '@kalos/kalos-rpc';
import { Button, Form, FormField, FormItem, FormLabel, Input, SimpleSelect } from '@kalos/ui';
import { useCallback, useMemo } from 'react';

import {
  maintenanceQuestionFormDefaultValues,
  type MaintenanceQuestionSchemeType,
  useMaintenanceQuestionForm,
} from './utils';

const MAINTENANCE_CONDITIONS = ['New', 'Good', 'Fair', 'Poor', 'Inoperative'];
const THERMOSTAT_OPTIONS = [
  { label: 'Good', value: '1' },
  { label: 'Physical Damage', value: '2' },
  { label: 'Miscalibrated', value: '3' },
  { label: 'Mercury', value: '4' },
];
const PLATEFORM_OPTIONS = [
  { label: 'Solid', value: '1' },
  { label: 'Mild Damage', value: '2' },
  { label: 'Disruptive Damage', value: '3' },
  { label: 'Replace', value: '4' },
];

const FLOAT_SWITCH_OPTIONS = [
  { label: 'Working', value: '1' },
  { label: 'Not Working', value: '2' },
  { label: 'Not Installed', value: '3' },
];

const COIL_OPTIONS = [
  { label: 'Clean', value: '1' },
  { label: 'Mild Damage', value: '2' },
  { label: 'Dirty', value: '3' },
  { label: 'Blocked', value: '4' },
];

const HURRICANE_PAD_OPTIONS = [
  { label: 'New', value: '1' },
  { label: 'Sinking', value: '2' },
  { label: 'Crumbling', value: '3' },
  { label: 'Replace', value: '4' },
];

const LINESET_OPTIONS = [
  { label: 'New', value: '1' },
  { label: 'Insulation Damaged', value: '2' },
  { label: 'Significant Patina', value: '3' },
  { label: 'Replace', value: '4' },
];

const DRAIN_LINE_OPTIONS = [
  { label: 'Clean', value: '1' },
  { label: 'Mildly Blocked', value: '2' },
  { label: 'Blocked', value: '3' },
  { label: 'Broken', value: '4' },
];

const GAS_TYPE_OPTIONS = [
  { label: 'LP', value: '1' },
  { label: 'Natural gas', value: '2' },
];

const BURNER_OPTIONS = [
  { label: 'Clean', value: '1' },
  { label: 'Mild Damage', value: '2' },
  { label: 'Carbon Buildup', value: '3' },
  { label: 'Major Corrosion', value: '4' },
];

const HEAT_EXCHANGE_OPTIONS = [
  { label: 'Clean', value: '1' },
  { label: 'Mild Damage', value: '2' },
  { label: 'Dirty', value: '3' },
  { label: 'Major Corrosion', value: '4' },
];

const MaintenanceQuestionForm = ({
  data,
  onSave,
  onClose,
}: {
  data: MaintenanceQuestion;
  onClose?: () => void;
  onSave?: (data: MaintenanceQuestionSchemeType) => Promise<any> | void;
}) => {
  const _defaultValue = useMemo(() => data, [data]);

  const form = useMaintenanceQuestionForm(_defaultValue);

  const onFormSave = useCallback(
    async (data: MaintenanceQuestionSchemeType) => {
      try {
        await onSave?.(data);
      } finally {
        form.reset(maintenanceQuestionFormDefaultValues());
      }
    },
    [form, onSave],
  );

  const handleClose = useCallback(() => {
    onClose?.();
  }, [onClose]);

  return (
    <Form {...form}>
      <div className="p-4">
        <h1 className="w-full pb-2 text-xl font-semibold tracking-tight">
          {data?.id ? 'Edit' : 'Add'} Maintenance
        </h1>
        <div className="h-[90vh]">
          <form
            className="grid h-full grid-cols-2 gap-x-2 gap-y-1 overflow-auto"
            onSubmit={form.handleSubmit(onFormSave)}
          >
            <h1 className="col-span-2 bg-[#e0e0e0] px-2 py-1 font-semibold">#1</h1>
            <FormField
              control={form.control}
              name="conditionRating1"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Condition</FormLabel>
                  <SimpleSelect
                    selectedValue={field.value.toString()}
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                    values={MAINTENANCE_CONDITIONS}
                  />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="conditionNotes1"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Notes</FormLabel>
                  <Input {...field} />
                </FormItem>
              )}
            />
            <h1 className="col-span-2 mt-2 bg-[#e0e0e0] px-2 py-1 font-semibold">#2</h1>
            <FormField
              control={form.control}
              name="conditionRating2"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Condition</FormLabel>
                  <SimpleSelect
                    selectedValue={field.value.toString()}
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                    values={MAINTENANCE_CONDITIONS}
                  />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="conditionNotes2"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Notes</FormLabel>
                  <Input {...field} />
                </FormItem>
              )}
            />
            <h1 className="col-span-2 mt-2 bg-[#e0e0e0] px-2 py-1 font-semibold">#3</h1>
            <FormField
              control={form.control}
              name="conditionRating3"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Condition</FormLabel>
                  <SimpleSelect
                    selectedValue={field.value.toString()}
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                    values={MAINTENANCE_CONDITIONS}
                  />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="conditionNotes3"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Notes</FormLabel>
                  <Input {...field} />
                </FormItem>
              )}
            />
            <h1 className="col-span-2 mt-2 bg-[#e0e0e0] px-2 py-1 font-semibold">Refrigerant</h1>
            <FormField
              control={form.control}
              name="thermostat"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Thermostat</FormLabel>
                  <SimpleSelect
                    selectedValue={field.value.toString()}
                    onChange={(value) => {
                      field.onChange(Number(value));
                    }}
                    values={THERMOSTAT_OPTIONS}
                  />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="plateform"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Plateform</FormLabel>
                  <SimpleSelect
                    selectedValue={field.value.toString()}
                    onChange={(value) => {
                      field.onChange(Number(value));
                    }}
                    values={PLATEFORM_OPTIONS}
                  />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="floatSwitch"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Float Switch</FormLabel>
                  <SimpleSelect
                    selectedValue={field.value.toString()}
                    onChange={(value) => {
                      field.onChange(Number(value));
                    }}
                    values={FLOAT_SWITCH_OPTIONS}
                  />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="evaporatorCoil"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Evaporator Coil</FormLabel>
                  <SimpleSelect
                    selectedValue={field.value.toString()}
                    onChange={(value) => {
                      field.onChange(Number(value));
                    }}
                    values={COIL_OPTIONS}
                  />
                </FormItem>
              )}
            />
            <h1 className="col-span-2 mt-2 bg-[#e0e0e0] px-2 py-1 font-semibold">Compressor</h1>
            <FormField
              control={form.control}
              name="condenserCoil"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Condenser Coil</FormLabel>
                  <SimpleSelect
                    selectedValue={field.value.toString()}
                    onChange={(value) => {
                      field.onChange(Number(value));
                    }}
                    values={COIL_OPTIONS}
                  />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="hurricanePad"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Hurricane Pad</FormLabel>
                  <SimpleSelect
                    selectedValue={field.value.toString()}
                    onChange={(value) => {
                      field.onChange(Number(value));
                    }}
                    values={HURRICANE_PAD_OPTIONS}
                  />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="lineset"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Lineset</FormLabel>
                  <SimpleSelect
                    selectedValue={field.value.toString()}
                    onChange={(value) => {
                      field.onChange(Number(value));
                    }}
                    values={LINESET_OPTIONS}
                  />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="drainLine"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Drain Line</FormLabel>
                  <SimpleSelect
                    selectedValue={field.value.toString()}
                    onChange={(value) => {
                      field.onChange(Number(value));
                    }}
                    values={DRAIN_LINE_OPTIONS}
                  />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="gasType"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Gas Type</FormLabel>
                  <SimpleSelect
                    selectedValue={field.value.toString()}
                    onChange={(value) => {
                      field.onChange(Number(value));
                    }}
                    values={GAS_TYPE_OPTIONS}
                  />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="burner"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Burner</FormLabel>
                  <SimpleSelect
                    selectedValue={field.value.toString()}
                    onChange={(value) => {
                      field.onChange(Number(value));
                    }}
                    values={BURNER_OPTIONS}
                  />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="heatExchanger"
              render={({ field }) => (
                <FormItem className="col-span-2">
                  <FormLabel>Heat Exchanger</FormLabel>
                  <SimpleSelect
                    selectedValue={field.value.toString()}
                    onChange={(value) => {
                      field.onChange(Number(value));
                    }}
                    values={HEAT_EXCHANGE_OPTIONS}
                  />
                </FormItem>
              )}
            />
            <div className="col-start-2 mt-2 flex gap-2 justify-self-end">
              <Button onClick={handleClose}>Close</Button>
              <Button type="submit">Save</Button>
            </div>
          </form>
        </div>
      </div>
    </Form>
  );
};

export default MaintenanceQuestionForm;
