import { BaseClient } from '../BaseClient';
import {
  DateRange,
  Empty,
  Int32,
  Int32List,
  String$,
} from '../compiled-protos/common';
import {
  PaylocityImportLineList,
  SubmitApproveReq,
  TimeClockEntry,
  TimeClockEntryList,
  Timesheet,
  TimesheetDay,
  TimesheetLine,
  TimesheetLineList,
  type TimesheetLinePaylocity
  ,
  TimesheetLinePaylocityList,
  TimesheetReq,
} from '../compiled-protos/timesheet_line';
import { TimesheetLineServiceClient } from '../compiled-protos/timesheet_line.client';
import { NULL_TIME } from '../constants';

class TimesheetLineClient extends BaseClient {
  req: TimesheetLine;
  self: TimesheetLineServiceClient;
  constructor(host: string, userID?: number) {
    super(host, userID);
    this.req = TimesheetLine.create();
    this.self = new TimesheetLineServiceClient(this.transport);
  }

  public async Create(req: TimesheetLine) {
    let res = TimesheetLine.create();
    try {
      res = await this.self.create(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async CreateTimeClockEntry(req: TimeClockEntry) {
    let res = TimeClockEntry.create();
    try {
      res = await this.self.createTimeClockEntry(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Get(req: TimesheetLine) {
    let res = TimesheetLine.create();
    try {
      res = await this.self.get(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async GetTimeClockEntry(req: TimeClockEntry) {
    let res = TimeClockEntry.create();
    try {
      res = await this.self.getTimeClockEntry(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Update(req: TimesheetLine) {
    let res = TimesheetLine.create();
    try {
      res = await this.self.update(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async UpdateTimeClockEntry(req: TimeClockEntry) {
    let res = TimeClockEntry.create();
    try {
      res = await this.self.updateTimeClockEntry(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Delete(req: TimesheetLine) {
    const res = await this.self.delete(req, this.getMetaData()).response;
    return res;
  }

  public async DeleteTimeClockEntry(req: TimeClockEntry) {
    const res = await this.self.deleteTimeClockEntry(req, this.getMetaData()).response;
    return res;
  }

  public async BatchGet(req: TimesheetLine) {
    let res = TimesheetLineList.create();
    try {
      res = await this.self.batchGet(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async BatchGetTimeClockEntry(req: TimeClockEntry) {
    let res = TimeClockEntryList.create();
    try {
      res = await this.self.batchGetTimeClockEntry(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async BatchGetManager(req: TimesheetLine) {
    let res = TimesheetLineList.create();
    try {
      res = await this.self.batchGetManager(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async BatchGetPayroll(req: TimesheetLine) {
    let res = TimesheetLineList.create();
    try {
      res = await this.self.batchGetPayroll(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async BatchGetPaylocityImport(req: DateRange) {
    let res = PaylocityImportLineList.create();
    try {
      res = await this.self.batchGetPaylocityImport(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }
  public async BatchGetPaylocity(req: TimesheetLinePaylocity) {
    let res = TimesheetLinePaylocityList.create();
    try {
      res = await this.self.batchGetPaylocity(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }
  public async Approve(timesheetLineIDs: number[], adminApprovalID: number) {
    const req = SubmitApproveReq.create({
      ids: timesheetLineIDs,
      userId: adminApprovalID,
    });
    let res = Empty.create();
    try {
      res = await this.self.approve(req, this.getMetaData());
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Submit(timesheetLineIDs: number[], userId: number) {
    const req = SubmitApproveReq.create({
      ids: timesheetLineIDs,
      userId,
    });
    let res = Empty.create();
    try {
      res = await this.self.submit(req, this.getMetaData());
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Process(timesheetLineIDs: number[], adminID: number) {
    const req = SubmitApproveReq.create({
      ids: timesheetLineIDs,
      userId: adminID,
    });
    let res = Empty.create();
    try {
      res = await this.self.process(req, this.getMetaData());
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Reject(timesheetLineIDs: number[], adminID: number) {
    const req = SubmitApproveReq.create({
      ids: timesheetLineIDs,
      userId: adminID,
    });
    let res = Empty.create();
    try {
      res = await this.self.reject(req, this.getMetaData());
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Deny(timesheetLineIDs: number[], adminID: number) {
    const req = SubmitApproveReq.create({
      ids: timesheetLineIDs,
      userId: adminID,
    });
    let res = Empty.create();
    try {
      res = await this.self.deny(req, this.getMetaData());
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async GetTimesheets(config: GetTimesheetConfig) {
    const req = TimesheetLine.create();
    if (config.startDate && config.endDate) {
      req.dateRange = ['>=', config.startDate, '<=', config.endDate];
    }
    if (config.departmentID) {
      req.departmentCode = config.departmentID;
    }
    if (config.technicianUserID) {
      req.technicianUserId = config.technicianUserID;
    }

    req.pageNumber = config.page || 0;
    req.orderBy = 'time_started';
    req.groupBy = 'technician_user_id';
    req.fieldMask = ['AdminApprovalUserId'];
    req.notEquals = ['UserApprovalDatetime'];
    req.userApprovalDatetime = NULL_TIME;
    req.isActive = 1;

    return this.BatchGet(req);
  }

  public async GetIDsForPayroll(start: string, end: string) {
    let res = Int32List.create();
    const req = DateRange.create({ start, end });
    try {
      res = await this.self.getIDsForPayroll(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async getReferenceURL(eventID: number) {
    const req = Int32.create({ value: eventID });
    let res = String$.create();
    try {
      res = await this.self.getReferenceURL(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async GetTimesheet(
    req: TimesheetReq,
    startDate: string,
    endDate: string
  ) {
    let res = Timesheet.create();
    const tsLine = req.timesheetLine;
    if (tsLine) {
      tsLine.dateRange = ['>=', startDate, '<=', endDate];
    }

    const sr = req.servicesRendered;
    if (sr) {
      sr.dateRange = ['>=', startDate, '<=', endDate];
    }
    req.timesheetLine = tsLine;
    req.servicesRendered = sr;

    res = await this.self.getTimesheet(req, this.getMetaDataWithoutCache()).response;
    return res;
  }

  loadTimesheets = async ({
    page,
    departmentId,
    employeeId,
    startDate,
    endDate,
  }: {
    page: number;
    departmentId?: number;
    employeeId?: number;
    startDate?: string;
    endDate?: string;
  }) =>
    await this.GetTimesheets({
      page,
      departmentID: departmentId,
      technicianUserID: employeeId,
      startDate,
      endDate,
      type: 'Payroll', // Added payroll type to get rid of error
    });

  loadTimesheetLine = async ({
    page,
    departmentId,
    technicianUserId,
  }: {
    page: number;
    departmentId: number;
    technicianUserId: number;
  }) => {
    const req = TimesheetLine.create({ pageNumber: page });
    if (departmentId) {
      req.departmentCode = departmentId;
    }
    if (technicianUserId) {
      req.technicianUserId = technicianUserId;
    }
    return await this.BatchGet(req);
  };

  public async getLaborHoursByEventID(eventID: number) {
    console.log('implement me?');
    /*
    return new Promise((resolve, reject) => {
      const req = new Int32();
      req.Value = eventID;
      const opts: UnaryRpcOptions<Int32, Double> = {
        request: req,
        host: this.host,
        metadata: this.getMetaData(),
        onEnd: (result: UnaryOutput<Double>) => {
          if (result.status !== grpc.Code.OK) {
            reject(result.statusMessage);
          } else {
            resolve(result.message?.getValue());
          }
        },
      };
    });
    */
  }
}

export {
  TimesheetLine,
  TimesheetLineList,
  TimesheetLineClient,
  SubmitApproveReq,
  Timesheet,
  TimesheetDay,
  TimeClockEntry,
  TimeClockEntryList,
  TimesheetReq,
};

interface GetTimesheetConfig {
  page?: number;
  departmentID?: number;
  technicianUserID?: number;
  startDate?: string;
  endDate?: string;
  type: 'Payroll' | 'Manager';
}
