import { type TransactionLineItem } from '@kalos/kalos-rpc';
import { Skeleton } from '@kalos/ui';
import { CheckCircledIcon, CrossCircledIcon } from '@radix-ui/react-icons';
import { useMemo } from 'react';
import { useStore } from 'zustand';

import {
  LineItemValidator,
  useTransactionLineItemsStore,
} from '../../../../../context/TransactionLIneItems';
import { useTimesheetDepartmentListQuery } from '../../../../../hooks/react-query/useTimesheetDepartmentQuery';
import { TimesheetDepartmentPickerV2 } from '../../../../ComponentsLibrary/Pickers/newPickers/QueryPickerV2';

export const DepartmentField = ({
  transactionLineItem,
}: {
  transactionLineItem: TransactionLineItem;
}) => {
  const store = useTransactionLineItemsStore();
  const isEditing = useStore(store, (s) => s.isEditing);
  const lineItem = useStore(store, (s) =>
    s.lineItems.find((li) => li.id === transactionLineItem.id),
  );
  const value = lineItem?.departmentId;
  const isDisabled = !!lineItem?.disableDepartmentPicker || !!lineItem?.jobId;

  const departmentQuery = useTimesheetDepartmentListQuery({
    filter: {
      isActive: 1,
    },
    select(data) {
      return data.results.find((el) => el.id === value);
    },
  });

  if (!isEditing)
    return departmentQuery.isLoading ? (
      <Skeleton className="w-12" />
    ) : (
      departmentQuery.data?.description || <span className="text-red-500">N/A</span>
    );

  return (
    <EditableDepartmentField disabled={isDisabled} transactionLineItem={transactionLineItem} />
  );
};

const EditableDepartmentField = ({
  transactionLineItem,
  disabled,
}: {
  disabled?: boolean;
  transactionLineItem: TransactionLineItem;
}) => {
  const store = useTransactionLineItemsStore();
  const setLineItems = useStore(store, (s) => s.setLineItems);
  const value = useStore(store, (s) =>
    s.lineItems.find((li) => li.id === transactionLineItem.id),
  )?.departmentId;
  const isValid = useMemo(() => LineItemValidator.isDepartmentValid(value ?? 0), [value]);

  const onChange = (newDepartmentId: number) => {
    setLineItems((prevLineItems) => {
      return prevLineItems.map((li) => {
        if (li.id === transactionLineItem.id) {
          return { ...li, departmentId: newDepartmentId };
        }
        return li;
      });
    });
  };

  return (
    <div className="flex flex-col gap-0.5">
      {!isValid && <div className="text-xs text-red-500">Please select a department!</div>}

      <TimesheetDepartmentPickerV2
        disabled={disabled}
        selected={value?.toString() ?? '0'}
        onSelect={(department) => onChange(department?.id ?? 0)}
        renderItem={(department) => department.description}
        emptyOption="Select Department"
        placeholder="Select Department"
      />
    </div>
  );
};

export const DepartmentEditHeader = () => {
  const store = useTransactionLineItemsStore();
  const isDepartmentValid = useStore(store, (s) => s.isDepartmentsValid());

  return isDepartmentValid ? (
    <CheckCircledIcon className="size-6 text-emerald-500" />
  ) : (
    <CrossCircledIcon className="size-6 text-red-500" />
  );
};
