import { type Invoice, type User } from '@kalos/kalos-rpc';
import { Button } from '@kalos/ui';

import { InvoicePaymentCreateCreateDialog } from './components/InvoicePaymentForm/InvoicePaymentCreateDialog';
import { InvoicePaymentTable } from './InvoicePaymentTable';

export const InvoicePaymentModule = ({
  customerId,
  invoiceId,
}: {
  customerId: User['id'];
  invoiceId: Invoice['id'];
}) => {
  console.log({ invoiceId });
  return (
    <div className="flex flex-col gap-2">
      <div className="ml-auto flex items-end gap-2 px-4">
        <InvoicePaymentCreateCreateDialog
          customerId={customerId}
          trigger={<Button size="sm">Create invoice payment</Button>}
        />
      </div>
      <div className="min-h-80 max-w-full overflow-auto">
        <InvoicePaymentTable invoiceId={invoiceId} customerId={customerId} />
      </div>
    </div>
  );
};
