import { BaseClient } from '../BaseClient';
import {
  ServicesRendered,
  ServicesRenderedList,
} from '../compiled-protos/event';
import { EventServiceClient as ServicesRenderedServiceClient } from '../compiled-protos/event.client';

class ServicesRenderedClient extends BaseClient {
  self: ServicesRenderedServiceClient;

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new ServicesRenderedServiceClient(this.transport);
  }

  public async Create(req: ServicesRendered) {
    let res = ServicesRendered.create();
    try {
      res = await this.self.createServicesRendered(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
      return;
    }
    return res;
  }

  public async Get(req: ServicesRendered) {
    let res = ServicesRendered.create();
    try {
      res = await this.self.getServicesRendered(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
      return;
    }
    return res;
  }

  public async Update(req: ServicesRendered) {
    let res = ServicesRendered.create();
    try {
      res = await this.self.updateServicesRendered(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
      return;
    }
    return res;
  }

  public async Delete(req: ServicesRendered) {
    let res = ServicesRendered.create();
    try {
      res = await this.self.deleteServicesRendered(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
      return;
    }
    return res;
  }

  public async BatchGet(req: ServicesRendered) {
    let res = ServicesRenderedList.create();
    try {
      res = await this.self.batchGetServicesRendered(req, this.getMetaData())
        .response;
    } catch (err) {
      console.log(err);
      return;
      return;
    }
    return res;
  }

  public async loadServicesRenderedByEventID(eventId: number) {
    let results: ServicesRendered[] = [];
    const req = ServicesRendered.create();
    req.eventId = eventId;
    req.isActive = 1;
    for (let page = 0; ; page += 1) {
      req.pageNumber = page;
      const res = await this.BatchGet(req);
      const resultsList = res!.results;
      const totalCount = res!.totalCount;
      results = results.concat(resultsList);
      if (results.length === totalCount) break;
    }
    return results.sort((a, b) => {
      if (a.id > b.id) return -1;
      if (a.id < b.id) return 1;
      return 0;
    });
  }
}

export { ServicesRendered, ServicesRenderedList, ServicesRenderedClient };
