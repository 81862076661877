import { BaseClient } from '../BaseClient';
import {
  AvgTicket,
  AvgTicketList,
  Billable,
  BillableList,
  Callbacks,
  MertricReportDataRequest,
  MetricReportDataList,
  Revenue,
} from '../compiled-protos/metrics';
import { MetricsServiceClient } from '../compiled-protos/metrics.client';

class MetricsClient extends BaseClient {
  self: MetricsServiceClient

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new MetricsServiceClient(this.transport)
  }

  public async GetBillable(userID: number) {
    const req = Billable.create();
    req.id = userID;
    let res = Billable.create()
    try {
      res = await this.self.getBillable(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }

  public async BatchGetBillable(departmentID: number) {
    const req = Billable.create();
    req.departmentId = departmentID
    let res = BillableList.create()
    try {
      res = await this.self.batchGetBillable(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }

  public async GetAvgTicket(userID: number) {
    const req = AvgTicket.create();
    req.id = userID;
    let res = AvgTicket.create()
    try {
      res = await this.self.getAvgTicket(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }

  public async BatchGetAvgTicket(userID: number) {
    const req = AvgTicket.create();
    req.id = userID;
    let res = AvgTicketList.create()
    try {
      res = await this.self.batchGetAvgTicket(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }

  public async GetCallbacks(userID: number) {
    const req = Callbacks.create();
    req.id = userID;
    let res = Callbacks.create()
    try {
      res = await this.self.getCallbacks(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }

  public async BatchGetCallbacks(derpartmentID: number) {
    const req = Callbacks.create()
    req.departmentId = derpartmentID
    let res = AvgTicketList.create()
    try {
      res = await this.self.batchGetAvgTicket(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }

  public async GetRevenue(userID: number) {
    const req = Revenue.create();
    req.id = userID;
    let res = Revenue.create()
    try {
      res = await this.self.getRevenue(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }

  /**
   * Returns loaded Metric by user id and metricType
   * @param userId: number
   * @param metricType: MetricType
   * @returns metric
   */
  public async loadMetricByUserId(userId: number, metricType: MetricType) {
    if (metricType == "Billable") {
      try {
        return await this.GetBillable(userId)
      } catch (e) {
        return Billable.create()
      }
    } else if (metricType == "Callbacks") {
      try {
        return await this.GetCallbacks(userId)
      } catch (e) {
        return Callbacks.create()
      }
    } else if (metricType == "Revenue") {
      try {
        return await this.GetRevenue(userId)
      } catch (e) {
        return Revenue.create()
      }
    } else {
      try {
        return await this.GetAvgTicket(userId)
      } catch (e) {
        return AvgTicket.create()
      }
    }
  }

  /**
   * Returns loaded Metric by user id in provided list and metricType
   * @param userIds: number array
   * @param metricType: MetricType
   * @returns metric
   */
  public async loadMetricByUserIds(userIds: number[], metricType: MetricType) {
    return await Promise.all(
      userIds.map(
        async userId => await this.loadMetricByUserId(userId, metricType)
      )
    );
  }
  /**
   * Returns loaded Metric report data for all users based on a date range, returns metricReportData type
   * @param userIds: number array
   * @param metricType: MetricType
   * @returns metric
   */
  public async loadMetricsReportData(req: MertricReportDataRequest) {
    let res = MetricReportDataList.create()
    try {
      res = await this.self.batchGetMetricReportData(req, this.getMetaData()).response
    } catch (err) {
      console.error(err)
    }
    return res
  }
}

type MetricType = 'Billable' | 'Callbacks' | 'Revenue' | 'AvgTicket';

export type { MetricType }
export { MetricsClient, Billable, AvgTicket, Callbacks, Revenue, MertricReportDataRequest };
