import { BaseClient } from '../BaseClient';
import { ProjectEvent, ProjectEventList } from '../compiled-protos/event';
import { EventServiceClient as ProjectsServiceClient } from '../compiled-protos/event.client';

class ProjectEventClient extends BaseClient {
  self: ProjectsServiceClient;

  constructor(host: string, userID?: number) {
    super(host, userID);
    this.self = new ProjectsServiceClient(this.transport);
  }

  public async Get(req: ProjectEvent) {
    let res = ProjectEvent.create();
    console.log('we got a request', req);
    try {
      console.log({ req });

      res = await this.self.getProjectEvent(req, this.getMetaData()).response;
      console.log({ res });
    } catch (err) {
      console.log(err);
      return res;
    }
    console.log('we got a response', res);
    return res;
  }

  public async BatchGet(req: ProjectEvent) {
    let res = ProjectEventList.create();
    try {
      res = await this.self.batchGetProjectEvent(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }
    return res;
  }

  public async Create(req: ProjectEvent) {
    let res = ProjectEvent.create();
    try {
      res = await this.self.createProjectEvent(req, this.getMetaData()).response;
    } catch (err) {
      console.log(err);
      return;
    }

    return res;
  }
}

export { ProjectEventClient, ProjectEvent };
